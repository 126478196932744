import React, { Component, Fragment } from "react";
import Dropzone from "react-dropzone";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import _isEmpty from "lodash/isEmpty";
import _extend from "lodash/extend";
import {
  getMakeModelList,
  submitEnquiryDetails,
  deviceListLoadingProgress, savePayload
} from "../../actions/Dashboard/doFindAgreements";

import CONSTANTS from "../../utils/Constants";
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import { getFormattedPopularName } from "../../utils/utils";
import reasonsIcon from "../../images/ais-images/icon-other-reasons.png";
import vectorIcon from "../../images/ais-images/vector.png";
import cancelIcon from "../../images/ais-images/cancel.png";
import UploadFileLoader from "../UploadFileLoader/UploadFileLoader";
import { UPDATED_DEVICE_DATA } from "../../actions/Dashboard/actionTypes";
class EnrolledDeviceInquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      brandTitle: this.props.selectedBrand
        ? this.props.selectedBrand
        : MESSAGE_CONSTANTS.SELECT_BRAND,
      modelTitle: MESSAGE_CONSTANTS.SELECT_MODEL,
      catalogId: null,
      imei: "",
      contactNo: "",
      deviceCapacity: "",
      deviceColor: "",
      makeDisable: false,
      modelDisable: true,
      files: [],
      isError: false,
      touched: {}
    };
  }

  componentDidMount() {
    this.props.getMakeModelList();
  }

  _renderBrandMenuOptions(option, i) {
    return (
      <Dropdown.Item
        active={this.state.brandTitle === option.Make}
        onClick={() => this.onBrandSelection(option.Make)}
      >
        {option.Make}
      </Dropdown.Item>
    );
  }

  _renderModelMenuOptions(option, i) {
    let formattedPopularName = getFormattedPopularName(option.AssetCatalogName);
    let deviceName = "";

    if (formattedPopularName.modelName) {
      deviceName = `${formattedPopularName.modelName}`;
    } else {
      deviceName = option.AssetCatalogName;
    }

    return (
      <Dropdown.Item
        active={this.state.modelTitle === deviceName}
        onClick={() =>
          this.onModelSelection(deviceName, option.CatalogId)
        }
      >
        {deviceName}
      </Dropdown.Item>
    );
  }

  onBrandSelection(eventKey) {
    this.props.getMakeModelList(eventKey);
    this.setState({
      brandTitle: eventKey,
      modelDisable: false,
      modelTitle: null
    });
  }

  onModelSelection(eventKey, catalogId) {
    this.setState({
      modelTitle: eventKey,
      catalogId: catalogId,
      disable: false,
    });
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    let _value = value;

    if (name === "imei" || name === "contactNo") {
      _value = value.replace(/[^0-9]/g, "");
      const touched = { [name]: true }
      this.setState(oldState => ({
        touched: { ...oldState.touched, ...touched }
      }))
    }

    this.setState({
      [name]: _value,
    });
  };

  handleDropFiles = (files) => {
    // If files size exceeds given size it returns empty files array
    if (!files.length) {
      this.setState({
        isError: true,
      });

      return;
    }

    this.setState({
      files,
      isError: false,
    });
  };

  removeAttachedFile = (e, file) => {
    e.stopPropagation();
    if (this.state.disable) {
      return;
    } else {
      var allFiles = [...this.state.files];
      var indexToRemove = allFiles.indexOf(file);
      allFiles.splice(indexToRemove, 1);
      this.setState({
        files: allFiles,
      });
    }
  };

  _renderFiles = (file, i) => {
    return (
      <div key={i} className="enrolled-selected-files">
        <div>
          <div>{file.name}</div>
          <div className="enrolled-loading-progress">
            <UploadFileLoader progressValue={100} />
          </div>
        </div>
        <div>
          <img
            alt="cancel"
            src={cancelIcon}
            onClick={(e) => this.removeAttachedFile(e, file)}
          />
        </div>
      </div>
    );
  };

  isValid = () => {
    if (!this.state.imei || this.state.imei.length !== 15) {
      return false;
    }

    if (!this.state.contactNo || this.state.contactNo.length < 8) {
      return false;
    }

    if (!this.state.brandTitle) {
      return false;
    }

    if (!this.state.modelTitle) {
      return false;
    }

    if (!this.state.deviceCapacity) {
      return false;
    }

    if (!this.state.deviceColor) {
      return false;
    }

    if (this.state.files.length <= 0) {
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    let fileList = [];
    const {
      imei,
      contactNo,
      brandTitle,
      modelTitle,
      deviceColor,
      deviceCapacity,
    } = this.state;

    const { selectedType } = this.props.data;

    this.state.files.map((file, i) => {
      var reader = new FileReader();
      var fileName = file.name.replace(/\s/g, "");
      reader.onload = (upload) => {
        fileList.push({
          fileId: "file-" + i,
          name: fileName,
          fileType: file.type,
          fileSize: file.size.toString(),
          data: upload.target.result,
        });

        if (fileList.length === this.state.files.length) {
          const enquiryDesc = `Make: ${brandTitle}, Model: ${modelTitle}, imei: ${imei}, Color: ${deviceColor}, Capacity: ${deviceCapacity}, Contact: ${contactNo}`;
          this.props.savePayload(UPDATED_DEVICE_DATA, enquiryDesc)

          this.setState({
            disable: true,
          });
          this.props.submitEnquiryDetails(
            enquiryDesc,
            selectedType,
            "",
            CONSTANTS.ENQUIRY_SUB_REASON.UPDATE_ENROLLED_DEVICE,
            "",
            null,
            null,
            fileList
          );
        }
      };
      reader.readAsDataURL(file);
    });
  };

  handleCancel = () => {
    this.props.updateJourneyList(null, MESSAGE_CONSTANTS.CANCEL);
    setTimeout(() => window.location.reload(), 1000);
  };

  backToMainMenu = () => {
    this.setState({ disable: true })
    this.props.deviceListLoadingProgress(true)
    this.props.updateJourneyList(CONSTANTS.DEVICE_LIST, MESSAGE_CONSTANTS.BackToMainMenu);
  };

  render() {
    const { assetDetails, makeAndModelList } = this.props;
    const { imei, contactNo, touched } = this.state

    const isDataValid = this.isValid();
    const disableClass = this.state.disable && "enrolled-form-disable";

    return (
      <Fragment>
        <div className={`enrolled-device-inquiry ${disableClass}`}>
          <div>
            <div>
              <img alt="reasons-icon" src={reasonsIcon} />
            </div>
            <div className="enrolled-device-info-text">
              <p>{MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.title}</p>
            </div>
            <div className="enrolled-device-items">
              <div>
                <input
                  name="imei"
                  type="text"
                  className="enrolled-input"
                  onChange={this.handleChange}
                  maxLength={15}
                  minLength={15}
                  placeholder={
                    MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.placeholder1
                  }
                  pattern="\d{15}"
                  value={this.state.imei}
                  autoComplete="off"
                />
                {touched.imei && imei?.length !== 15 && <div className="enrollment-input-error">{MESSAGE_CONSTANTS.INPUT_VALIDATION_IMEI}</div>}
              </div>
              <div className="enrolled-input-lg">
                <input
                  name="contactNo"
                  type="text"
                  className="enrolled-input"
                  onChange={this.handleChange}
                  maxLength={10}
                  minLength={8}
                  pattern="\d{8,10}"
                  placeholder={
                    MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.placeholder2
                  }
                  value={this.state.contactNo}
                  autoComplete="off"
                />
                {touched.contactNo && contactNo?.length < 8 && <div className="enrollment-input-error">{MESSAGE_CONSTANTS.INPUT_VALIDATION_CONTACT_NO}</div>}
              </div>
              <div>
                <DropdownButton
                  title={this.state.brandTitle}
                  id={`dropdown-size-medium`}
                  className="enrolled-input"
                  disabled={this.state.makeDisable}
                >
                  {assetDetails &&
                    assetDetails.map((e, i) => {
                      return this._renderBrandMenuOptions(e, i);
                    })}
                </DropdownButton>
              </div>
              <div>
                <DropdownButton
                  title={this.state.modelTitle}
                  id={`dropdown-size-medium`}
                  className="enrolled-input"
                  disabled={this.state.modelDisable}
                >
                  {makeAndModelList &&
                    makeAndModelList.map((e, i) => {
                      return this._renderModelMenuOptions(e, i);
                    })}
                </DropdownButton>
              </div>
              <div>
                <input
                  name="deviceCapacity"
                  type="text"
                  className="enrolled-input"
                  onChange={this.handleChange}
                  maxLength={10}
                  placeholder={
                    MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.placeholder5
                  }
                  value={this.state.deviceCapacity}
                  autoComplete="off"
                />
              </div>
              <div>
                <input
                  name="deviceColor"
                  type="text"
                  className="enrolled-input"
                  onChange={this.handleChange}
                  maxLength={10}
                  placeholder={
                    MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.placeholder6
                  }
                  value={this.state.deviceColor}
                  autoComplete="off"
                />
              </div>
              <div className="enrolled-input-sm">
                <input
                  name="contactNo"
                  type="text"
                  className="enrolled-input"
                  onChange={this.handleChange}
                  maxLength={10}
                  minLength={8}
                  pattern="\d{8,10}"
                  placeholder={
                    MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.placeholder2
                  }
                  value={this.state.contactNo}
                  autoComplete="off"
                />
                {touched.contactNo && contactNo?.length < 8 && <div className="enrollment-input-error">{MESSAGE_CONSTANTS.INPUT_VALIDATION_CONTACT_NO}</div>}
              </div>
            </div>
            <div className="enrolled-device-documents">
              <p>{MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.content_header}</p>
              <div
              // style={{ display: this.state.showFileUpload ? "block" : "none" }}
              >
                <section>
                  <Dropzone
                    className="ais_dashed-placeholder"
                    accept=".jpg, .jpeg, .pdf, .png"
                    multiple={false}
                    maxSize={5000000}
                    onClick={(e) => e.preventDefault()}
                    onDrop={this.handleDropFiles}
                  >
                    <div className="enrolled-dropzone">
                      {this.state.files.length ? (
                        <div className="enrolled-files-container">
                          {this.state.files.map((e, i) =>
                            this._renderFiles(e, i)
                          )}
                        </div>
                      ) : (
                        <div>
                          <img alt="vector-icon" src={vectorIcon} />
                          <span className="enrolled-dropzone-lg">
                            {
                              MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM
                                .content_placeholder
                            }
                          </span>
                          <span className="enrolled-dropzone-sm">
                            {
                              MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM
                                .content_header
                            }
                          </span>
                        </div>
                      )}
                    </div>
                  </Dropzone>
                </section>
              </div>
              <div className="enrolled-device-notes">
                {MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.content_footer.map(
                  (text) => (
                    <p key={text}>{text}</p>
                  )
                )}
                {this.state.isError && (
                  <p className="ais_cc-text-error">{MESSAGE_CONSTANTS.MAX_FILE_SIZE_EXCEEDED}</p>
                )}
              </div>
              <div className="enrolled-device-buttons">
                <button onClick={this.handleCancel}>
                  {MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.Cancel}
                </button>
                <div class="ais_device-divider"></div>
                <button
                  className={`${!isDataValid && "disabled-btn"}`}
                  disabled={!isDataValid}
                  onClick={this.handleSubmit}
                >
                  {MESSAGE_CONSTANTS.UPDATE_ENROLLED_FORM.Confirm}
                </button>
              </div>
              <div class="backToMainMenu">
                <a
                  className="backToMainMenu"
                  disabled=""
                  onClick={this.backToMainMenu}
                >
                  {MESSAGE_CONSTANTS.BackToMainMenu}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMakeModelList,
      submitEnquiryDetails,
      deviceListLoadingProgress, savePayload
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  const {
    selectedDefectOption,
    clientData,
    customerCase,
    resumeCanceled,
    caseType,
    selectedBrand,
    makeAndModelList,
    assetDetails,
  } = state.customerData;
  return {
    cacheId: clientData.CacheId,
    selectedDefectOption,
    customerCase,
    resumeCanceled,
    caseType,
    selectedBrand,
    makeAndModelList,
    assetDetails,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnrolledDeviceInquiry);
