import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CONSTANTS from '../../utils/Constants';
import { endInteractionInit, getSurvey, setJourneyCmpltd } from '../../actions/Dashboard/doFindAgreements';

class AskFeedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
            deviceName: "",
            deviceSpec: ""
        }
        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleYesClick = this.handleYesClick.bind(this);
    }

    componentWillMount() {
       this.props.setJourneyCmpltd(true, CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED);
    }

    handleNoClick() {
        // console.log("No button clicked.");
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.source, "No");
        this.props.endInteractionInit(this.props.cacheId);
    }

    handleYesClick() {

        let { cacheId, clientId } = this.props;

        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });
        //this.props.updateJourneyList(this.props.source, "Yes");
        let getSurveyParams = {
            SessionId: cacheId,
            ClientId: clientId,
            TriggerPoint: CONSTANTS.SURVEY_TRIGGER_POINT,
            SurveyType: CONSTANTS.SURVEY_TYPE,
            ServiceRequestId: this.props.serviceRequestId || ""
        }

        this.props.getSurvey(getSurveyParams);
        // this.props.submitOrderInit(this.props.cacheId);
    }

    render() {

        return (
            <div className="ais_current-form-device-div">
                <div className="ais_current-form-device-header-div">
                    <div className="ais_container-div">
                        <label className="ais_pincode_txt_content">{this.props.data.CONTENT1}</label>
                    </div>
                    <div className="ais_container-div">
                        <label className="ais_pincode_txt_content">{this.props.data.CONTENT2}</label>
                    </div>
                    <div className="ais_outer-confirm-delivery-align-button">
                        <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={this.handleNoClick} >{this.props.data.BUTTONS[0]}</button>
                        <div className="ais_device-divider" />
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={ this.handleYesClick} >{this.props.data.BUTTONS[1]}</button>
                    </div>
                </div>


            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        endInteractionInit,
        getSurvey,
        setJourneyCmpltd
    }, dispatch)
};


const mapStateToProps = state => {
    return {
        clientId: state.customerData.clientData.ClientId,
        cacheId: state.customerData.clientData.CacheId,
        serviceRequestId: state.customerData.serviceRequestId
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AskFeedback);

