import gql from 'graphql-tag';

export default gql`
mutation updateEncryptedChatRequest($input:UpdateChatRequestInput!){
    updateEncryptedChatRequest(input:$input){
        requestId
        taskId
        requestStatus
        endTimestamp
  }
}`;