import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Img from 'react-image';
import _ from 'lodash';

import {
    createNewServiceRequestInit,
    processIncidentInit,
    setProgressBarValue,
    savePayload,
    saveSelectedReplacementDevice
} from '../../actions/Dashboard/doFindAgreements';

import { SAVE_PROCESSINCIDENT_PARAMS,SAVE_SELECTED_ASSET_INSTANCE } from '../../actions/Dashboard/actionTypes';


import CONSTANTS from '../../utils/Constants';

import { getFormattedPopularName } from '../../utils/utils';
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import noImage from "../../images/ais-images/icon-enrolled-device.png";

class SelectCurrentDevice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button margin-bottom10",
            noButtonClassName: "ais_align-popup-button",
            deviceName: "",
            deviceSpec: ""
        }
        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleYesClick = this.handleYesClick.bind(this);
    }

    handleNoClick() {
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.dataprops.source, "No");
    }

    handleYesClick() {
        let {
            dataprops: { source },
            cacheId,
            selectedDefectOption,
            resumeCanceled,
            caseType,
            deviceMake,
            serviceRequestType,
            updateJourneyList,
            createNewServiceRequestInit,
            processIncidentInit,
            customerCaseNumber,
            isPreviousClaimCompleted,
        } = this.props;
        this.props.disableSelection();
        let { AssetCatalog, IMEI,AssetInstance } = this.props.deviceDetails;
        this.props.saveSelectedReplacementDevice(this.props.deviceDetails);
        let deviceDetails = MESSAGE_CONSTANTS.SELECTED_DEVICE + " " + `${AssetCatalog.AssetCatalogName ? AssetCatalog.AssetCatalogName : ""}`;
        updateJourneyList(source, deviceDetails);
        let ProcessIncidentParameters = {
            SessionId: cacheId,
            Incident: {
                FailureDescriptiveText: selectedDefectOption,
                IncidentType: caseType
            },
            AssetDetails: {
                AssetCatalogId: AssetCatalog.AssetCatalogId,
                SerialNumber: IMEI,
            }
        }

        if (caseType === CONSTANTS.MALFUNCTION) {
            let Triage = Object.assign({}, {
                Triage: {
                    TriageAccepted: true,
                    TriageRefusalReason: "Triage refused by customer",
                    Notes: ""
                }
            });
            _.extend(ProcessIncidentParameters.Incident, Triage);
        }

        this.props.savePayload(SAVE_PROCESSINCIDENT_PARAMS, ProcessIncidentParameters);
        this.props.savePayload(SAVE_SELECTED_ASSET_INSTANCE, AssetInstance);
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected margin-bottom10"
        });


        if ((!serviceRequestType || serviceRequestType === "StartServiceRequest")
        && (!customerCaseNumber || isPreviousClaimCompleted)) {
            createNewServiceRequestInit(cacheId, ProcessIncidentParameters);
        } else {
            if (resumeCanceled) {
                this.canceledPreviousSRCase(ProcessIncidentParameters);
            } else {
                if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
                    let { AssetCatalog } = this.props.deviceDetails;
                    let deviceDetails = MESSAGE_CONSTANTS.SELECTED_DEVICE + " " + `${AssetCatalog.AssetCatalogName ? AssetCatalog.AssetCatalogName : ""}`;
                    updateJourneyList(CONSTANTS.SUBMIT_IMEI_NUMBER, deviceDetails);

                    // updateJourneyList(CONSTANTS.SUBMIT_IMEI_NUMBER, MESSAGE_CONSTANTS.SELECT_TEXT);
                } else {
                    processIncidentInit(ProcessIncidentParameters, cacheId);
                }
            }
        }
    }

    canceledPreviousSRCase(ProcessIncidentParameters) {
        let {
            cacheId,
            checkForDeviceMismatch,
            caseType,
            deviceMake,
            updateJourneyList,
            processIncidentInit,
            setProgressBarValue
        } = this.props;

        if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
            if (checkForDeviceMismatch) {
                //   updateJourneyList(CONSTANTS.IMEI_VERFIED, MESSAGE_CONSTANTS.SUB_STAGES.VERIFIED_IMEI);
                if (caseType === CONSTANTS.MALFUNCTION) {
                    let Triage = Object.assign({}, {
                        Triage: {
                            TriageAccepted: true,
                            TriageRefusalReason: "Triage refused by customer",
                            Notes: ""
                        }
                    });
                    _.extend(ProcessIncidentParameters.Incident, Triage);
                }
                this.props.savePayload(SAVE_PROCESSINCIDENT_PARAMS, ProcessIncidentParameters);
                processIncidentInit(ProcessIncidentParameters, cacheId);
            } else {
                   let { AssetCatalog } = this.props.deviceDetails;
                let deviceDetails = MESSAGE_CONSTANTS.SELECTED_DEVICE + " " + `${AssetCatalog.AssetCatalogName ? AssetCatalog.AssetCatalogName : ""}`;
                updateJourneyList(CONSTANTS.SUBMIT_IMEI_NUMBER, deviceDetails);
                // updateJourneyList(CONSTANTS.SUBMIT_IMEI_NUMBER, MESSAGE_CONSTANTS.SELECT_TEXT);
            }
            setProgressBarValue(100);
        } else {
            processIncidentInit(ProcessIncidentParameters, cacheId)
        }
    }

    render() {

        let { AssetCatalog, Make, Model } = this.props.deviceDetails;
        let capsMake = Make.Name.toUpperCase();
        let capsModel = Model.Name.toUpperCase();
        let deviceImgName = capsMake + '_' + capsModel + '_S';
        let deviceSpecs = getFormattedPopularName(AssetCatalog.AssetCatalogName);
        let deviceImageJPG = this.props.deviceURL + deviceImgName + '.jpg';
        let deviceImagePNG = this.props.deviceURL + deviceImgName + '.png';
        let deviceImage = <Img src={[
            deviceImageJPG,
            deviceImagePNG,
            noImage
        ]}
            className="ais_current-form-device-icn"
        />;
        return (
            <div className="ais_current-form-device-div list">
                <div className="ais_current-form-device-header-div">
                    <label className="ais_current-mobile-device-label">
                        {this.props.data}
                    </label>
                    {/*<div className="ais_current-mobile-device-content">
                        {this.props.data.CONTENT}
                    </div>*/}
                </div>
                <div className="ais_current-popup-center-div">
                    <img alt="" className="ais_current-form-device-icn" src={deviceImageJPG} onError={(e) => { e.target.src = noImage }} />

                    <div className="ais_current-form-your-device-name-div">
                        <div className="ais_current-form-device-main-name">{deviceSpecs.modelName}</div>
                        <div className="ais_current-form-device-desc">{deviceSpecs.specs} {deviceSpecs.color}</div>
                    </div>
                </div>
                <button disabled={this.props.disable} className={this.state.yesButtonClassName} onClick={(e) => this.handleYesClick(e)} >{MESSAGE_CONSTANTS.SELECT_TEXT}</button>
                <div className="ais_device-divider"></div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createNewServiceRequestInit,
        processIncidentInit,
        setProgressBarValue,
        savePayload,
        saveSelectedReplacementDevice
    }, dispatch)
};


const mapStateToProps = state => {
    let { clientData, inputState,customerCaseNumber, caseType, serviceRequestType, holds, makeAndModelList, selectedBrand, deviceDetails, replacedAsset, resumeCanceled, selectedDefectOption,isPreviousClaimCompleted } = state.customerData;
    return {
        cacheId: clientData.CacheId,
        userInputState: inputState,
        caseType,
        deviceURL: clientData.imageBaseUrl,
        serviceRequestType,
        isHoldApplied: _.find(holds, { 'HoldType': 'DOCUMENT' }),
        checkForDeviceMismatch: makeAndModelList,
        resumeCanceled,
        selectedDefectOption,
        deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
        customerCaseNumber,
        isPreviousClaimCompleted,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SelectCurrentDevice);
