import React from "react";
import { Mutation } from "react-apollo";
import UpdateEndRequestMutation from "./../../../graphql/mutation/UpdateChatRequest";
import GetEndChatRequest from "./CallEndChatRequest";

const EndChatRequest = ({
  selfTerminatedChat,
  setUserClosedJourneyDuringAssistance,
  chatRequest,
  actions
}) => (
  <Mutation
    mutation={UpdateEndRequestMutation}
    setUserClosedJourneyDuringAssistance={setUserClosedJourneyDuringAssistance}
    onCompleted={data => {
      if (selfTerminatedChat) {
        setUserClosedJourneyDuringAssistance();
      }
    }}
  >
    {updateChatRequest => {
      return (
        <GetEndChatRequest
          updateChatRequest={updateChatRequest}
          chatRequest={chatRequest}
          actions={actions}
        />
      );
    }}
  </Mutation>
);

export default EndChatRequest;
