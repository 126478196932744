import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from 'classnames';

import {doGetFulfillmentInit} from "../../actions/Dashboard/doFindAgreements";

import "../Feedback/feedback.css";

class TextAreaWithSubmit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disable: true,
      disabledTextArea: false,
      highlightedButton: false,
      remarks: ""
    };
  }

  handleTextareaChange = event => {
    this.setState({
      remarks: event.target.value,
      disable: !event.target.value
    });
  };

  submitHandler = () => {
    this.setState({
      disable: true,
      disabledTextArea: true,
      highlightedButton: true
    });
    this.props.updateJourneyList(null, this.state.remarks);
    this.props.doGetFulfillmentInit(this.state.remarks);
  };

  render() {
    let { data } = this.props;
    let { remarks, disabledTextArea, disable, highlightedButton } = this.state;

    let submitButtonClass = classNames({
        'ais_outer-align-button': true,
        'ais_survey-question': true
    })

    let buttonClass = classNames({
      'ais_device-select-button': true,
      'ais_survey-question': true,
      'ais_device-select-button-highlighted': highlightedButton,
  })

    return <div className="ais_feedback-container">
        <div className='ais_feedback-parent-div'>
          <div className="ais_second-question">
            <label className="ais_question-label">
              {data.LABEL}
            </label>
            <textarea maxLength= "100" value={remarks} className="ais_feedback-reason ais_text-color" placeholder={data.PLACEHOLDER} disabled={disabledTextArea} onChange={this.handleTextareaChange} />
          </div>
          <div className={submitButtonClass}>
              <button disabled={disable} onClick={this.submitHandler} className={buttonClass}>
                  {data.BUTTON}
              </button>
          </div>
        </div>
      </div>;
  }
}

/* const mapStateToProps = state => {
  const {
  } = state.customerData;
  return { };
}; */

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      doGetFulfillmentInit
    }, dispatch);
};

export default connect(null, mapDispatchToProps)(TextAreaWithSubmit);
