import CONSTANTS from '../utils/Constants';

const MESSAGE_CONSTANTS_THAI = {
  TECHNICAL_ERROR: 'พบข้อผิดพลาดจากระบบ',
  VOID_MSG: 'ไม่เข้าเงื่อนไขกฎการทำSR',
  SERVICE_FEE_ENQUIRY: 'ค่าบริการ',
  NO_DATA_AVAILABLE: 'ไม่พบข้อมูลที่ใช้ได้',
  VOID_SCRIPT:
    'ขออภัยค่ะ ระบบไม่สามารถดำเนินการตามคำขอของคุณได้ในขณะนี<br/>เนื่องจากคุณอาจใช้สิทธิ์คำขอบริการครบแล้ว หรือมีคำขอบริการที่อยู่ระหว่างดำเนินการ/ ทำรายการไม่สำเร็จในระบบ <br/> หรือแชทกับพนักงานของเรา',
  FAQ: 'คำถามที่พบบ่อย',
  CHAT_RESOLVED_SERVICE_UNAVAILABLE: 'ขอบคุณที่ใช้บริการ AIS Mobile Care',
  TRACK_NO_SR_MSG: 'ไม่พบข้อมูลการทำรายการ',
  CASE_GRATH_INITIATED: 'กรุณารอสักครู่',
  CASE_GRAPH_SUCCESS: 'Devices loaded successfully',
  VERIFY_AGREEMENT_SUCCESS: 'Service requests loaded successfully',
  BackToMainMenu: 'กลับไปยังเมนูหลัก',
  BACK_TO_MAIN_MENU: 'กลับไปยังหน้าหลัก',
  SCREEN_REPAIR_IN_PROGRESS_HARD_STOP_SCRIPT:
    'พบการทำรายการที่ไม่สำเร็จหรืออยู่ระหว่างการดำเนินการในระบบ และจะไม่สามารถทำการขอรับบริการครั้งนี้',
  AIS_LEAVING_REASON_CONTENT1: 'รายละเอียดของคุณได้รับการยืนยันเป็นที่เรียบร้อยแล้ว',
  AIS_LEAVING_REASON_CONTENT2: 'เราเสียใจที่ทราบว่าคุณต้องการยกเลิก AIS Mobile Care',
  AIS_LEAVING_REASON_CONTENT3:
    'อะไรที่ช่วยให้คุณรู้สึกดีขึ้น โปรดเลือกสาเหตุที่คุณต้องการยกเลิก AIS Mobile Care',
  ACKNOWLEDGE_MSG: 'ฉันรับทราบสำหรับข้อมูลข้างต้น   โปรดดำเนินการยกเลิก  AIS Mobile Care',
  NOTHELPFULL_NEWDEVICE_OTHERREASON_HEADER: 'อะไรที่ทำให้ AIS Mobile Care แตกต่างจากบริการอื่นๆ',
  COST_TOO_MUCH_HEADER: 'คุณจะได้ความคุ้มค่ามากกว่าที่คุณจ่าย',
  NO_NEED_PACKAGE_AUTO_RENEWAL_HEADER: 'คุณจะพลาดสิ่งเหล่านี้หากยกเลิกการต่อแพ็คเกจแบบอัตโนมัติ',
  NOT_USING_IT_OR_NOT_ENOUGH_AT_ALL_HEADER:
    'คุณอาจจะยังไม่ทราบถึงผลประโยชน์ทั้งหมดที่คุณสามารถได้รับจาก AIS Mobile Care',
  NO_NEED_PACKAGE_RENEWAL_SUB_HEADER1: 'แลกเปลี่ยนเครื่อง หรือรับเครื่องทดแทนได้โดยง่าย',
  NO_NEED_PACKAGE_RENEWAL_SUB_HEADER2: 'คุณจะไม่รู้สึกเสียใจหากตัดสินใจรับบริการด้วยเหตุผลดังนี้',
  YOU_PAY_ONLY: 'จ่ายเพียง',
  FOR_SWAP: 'สำหรับการแลกเปลี่ยนเครื่อง',
  FOR_REPLACEMENT: 'สำหรับการรับเครื่องทดแทน',
  ARE_YOU_SURE_WANT_TO_REMOVE: 'คุณต้องการยกเลิกโปรแกรมนี้ใช่ หรือไม่',
  STAY_WITH_AIS_MOBILE_CARE: 'ใช้บริการ AIS Mobile Care',
  STAY_WITH_AIS_MOBILE_CARE_TEXT: 'ดำเนินการร้องขอการยกเลิกบริการ',
  PAY_LESS_SAVE_MORE:
    'ด้วยบริการ AIS Mobile Care คุณจะจ่ายเพียงราคาด้านล่างเพื่อแลกเปลี่ยนเครื่อง หรือรับเครื่องทดแทน',
  YOU_SAVE: 'คุณจ่ายน้อยลงถึง',
  SWAP_FEE: 'ค่าบริการแลกเปลี่ยนเครื่อง',
  REPLACEMENT_FEE: 'ค่าบริการรับเครื่องทดแทน',
  MODAL_HEADER:
    'เมื่อ AIS Mobile Care ถูกยกเลิก คุณจะไม่สามารถใช้อุปกรณ์สื่อสารเครื่องนี้ในการสมัครรับบริการได้อีก',
  MODAL_TEXT: 'คุณต้องการอุปกรณ์สื่อสารเครื่องใหม่ในการขอรับบริการ',
  THANK_YOU_TEXT_REFUND:
    'การยกเลิกจะมีผลทันทีหลังจากการทำรายการครั้งนี้สิ้นสุดลง และคุณจะได้รับข้อความยืนยันการยกเลิกภายใน 7 วัน  <br/><br/><b class="GoodByeText"> ขอบคุณที่ใช้บริการ </b>',
  THANK_YOU_LABEL: 'ขอบคุณที่ใช้บริการ AIS Mobile Care',
  THANK_YOU_TEXT: 'อุปกรณ์สื่อสารของคุณยังคงอยู่ในบริการ',
  THANK_YOU_BTN_CLOSE: 'ปิด',
  LEAVE_FEEDBACK: 'โปรดแสดงความคิดเห็น',
  REQUEST_SENT_SUCCESSFULLY: 'โปรดเลือกเวลาโทรกลับที่ต้องการ',
  LEAVE_FEEDBACK_MSG:
    'เราเสียใจที่ทราบว่าคุณต้องการยกเลิกบริการ ทีมงานของเราจะติดต่อกลับไปตามวันและเวลาดังกล่าว.',
  FEEDBACK_COMMENT: 'เราอยากได้ยินข้อเสนอแนะจากคุณ',
  ROUTE_TO_SAVEDESK:
    'เราเสียใจที่ทราบว่าคุณออกจากการใช้บริการ ทีมงานของเราจะติดต่อกลับในวันทำการถัดไป ระหว่างเวลา 9:00 - 18:00น.',
  ROUTE_TO_SAVEDESK_TEXT: 'เราอยากได้ยินข้อเสนอแนะจากคุณ',
  PRORATED_REFUND:
    'เราสามารถช่วยเหลือในการยกเลิกบริการได้  <a  class="termeAndConditions" target="_blank" href="#/terms-conditions-thai">ข้อตกลงและเงื่อนไข</a>ทางเราไม่สามารถคืนเงินให้กับคุณได้<br/><br/>หากคุณยืนยันที่จะยกเลิกบริการ เราจะเสนอการคืนเงินบางส่วนให้เป็นกรณีพิเศษ',
  FULL_REFUND:
    'เราสามารถช่วยเหลือในการยกเลิกบริการได้ตาม<a  class="termeAndConditions"target="_blank" href="#/terms-conditions-thai">ทางเราไม่สามารถคืนเงินให้กับคุณได้<br/><br/>หากคุณยืนยันที่จะยกเลิกบริการ เราจะเสนอการคืนเงินเต็มจำนวนให้เป็นกรณีพิเศษ',
  NO_REFUND:
    'เราสามารถช่วยเหลือในการยกเลิกบริการได้ และคุณจะถูกเรียกเก็บค่าธรรมเนียมรายเดือนรอบบิลนี้เป็นรอบบิลสุดท้ายโดยการยกเลิกบริการจะมีผลทันทีหลังจากการทำรายการครั้งนี้สิ้นสุดลง และคุณจะได้รับข้อความยืนยันการยกเลิกภายใน 7 วัน',
  NOT_ELIGIBLE_FOR_REFUND:
    'เราสามารถช่วยเหลือในการยกเลิกบริการได้ และขอสงวนสิทธ์ในการคืนเงินตาม <a  class= "termeAndConditions" target="_blank" href="#/terms-conditions-thai">ข้อกำหนดและเงื่อนไข</a><br/><br/>โดยการยกเลิกจะมีผลทันทีหลังจากการทำรายการครั้งนี้สิ้นสุดลง และคุณจะได้รับข้อความยืนยันการยกเลิกภายใน 7 วัน',
  TEXT_AREA_PLACEHOLDER: 'บอกความคิดเห็นของคุณเพื่อใช้ในการปรับปรุงคุณภาพและบริการของเรา',
  SELECT_TIME: 'เวลาที่สะดวก',
  SELECT_DATE: 'เลือกวันที่ ',
  COST_TOO_MUCH_FOOTER_TEXT: 'อุ่นใจเมื่อมีบริการของเราดูแล!',
  ALTERNATE_CONTACT_NUMBER: 'เบอร์ติดต่อสำรอง',
  CALLBACK_WINDOW_MORNING: '09.00 น. - 12.00 น.',
  CALLBACK_WINDOW_EVENING: '13.00 น. - 18.00 น.',
  SAVEDESK_THANK_YOU_MSG1: 'ทีมงานของเราจะติดต่อกลับไปในช่วงวันและเวลาดังกล่าว <br/><br/>',
  SAVEDESK_THANK_YOU_MSG2: 'ขอบคุณที่ใช้บริการ AIS Mobile Care ค่ะ',
  CHURN_HEADER: 'ยินดีต้อนรับสู่บริการ AIS Mobile Care',
  ENTER_OTHER_REASON: 'กรุณาระบุเหตุผล',
  SELECTED_CALLBACK_DATE: 'เลือกวันให้โทรกลับ',
  SELECTED_CALLBACK_TIME: 'เลือกเวลาให้โทรกลับ',
  SELECTED_ALTERNATE_CONTACT_NUMBER: 'เบอร์ติดต่อกลับ',
  ENTERED_COMMENT: 'ข้อความถึงเรา',
  TRACK_THE_DEVICE: 'กดเพื่อเช็คสถานะการจัดส่ง',
  //WBKO
  WBKO_TRACK_MY_DELIVERY: 'WBKO_TRACK_MY_DELIVERY',
  WBKO_TRACK_MY_DELIVERY_NEW: 'WBKO_TRACK_MY_DELIVERY_NEW',
  WBKO_DELIVERY_DATE_CHANGE:
    'ระบบได้ส่งคำขอเปลี่ยนแปลงวันที่ในการจัดส่งเป็น {selectedDeliveryDate} ไปยังหน่วยงานที่เกี่ยวข้องเรียบร้อยแล้ว พร้อมส่งอีเมลยืนยันคำขอนี้ไปที่อีเมล {emailAddress}',
  WBKO_DELIVERY_DATE_NOCHANGE:
    'การจัดส่งตัวเครื่องในวันที่ {selectedDeliveryDate}  ไม่มีการเปลี่ยนแปลง',
  WBKO_DELIVERY_ADDRESS_CHANGE:
    'คำขอของคุณในการเปลี่ยนที่อยู่การจัดส่งเป็น {changedAddress} และ วันในการจัดส่ง {selectedDeliveryDate} ได้ถูกอัปเดตเรียบร้อยแล้ว. และเราได้ส่งอีเมลยืนยันไปยังอีเมลของคุณ {emailAddress}',
  WBKO_DELIVERY_ADDRESS_NOCHANGE: 'ระบบไม่พบการเปลี่ยนแปลงที่อยู่ในการจัดส่ง',
  WBKO_CANCEL_DELIVERY_MSG1:
    'ระบบได้ส่งคำขอยกเลิกการจัดส่งและการทำรายการของSR#{srNumber} {deviceDetails} ไปยังหน่วยงานที่เกี่ยวข้องเรียบร้อยแล้ว พร้อมส่งอีเมลยืนยันคำขอนี้ไปที่อีเมล {emailAddress} ',
  WBKO_CANCEL_DELIVERY_MSG2:
    'สำหรับการชำระเงินสด จะไม่มีการเรียกเก็บเงินแต่อย่างใด <br/> <br/> สำหรับการชำระด้วยบัตรเครดิต เราจะมีการคืนเงินไปยังบัตรเครดิตที่คุณใช้ในการชำระค่าบริการ โดยอาจต้องใช้เวลาถึง 3 สัปดาห์ในการคืนเงินที่มีการเรียกเก็บจากบัญชีบัตรของคุณ <br/>ทั้งนี้ขึ้นอยู่กับธนาคารผู้ออกบัตรและโปรดติดต่อธนาคารผู้ออกบัตรของคุณเพื่อขอทราบระยะเวลาที่แน่นอน',
  WBKO_RESCHEDULE_DELIVERY_ENQUIRY_EMAIL_MSG:
    'Please note that your delivery arrangement has been submitted successfully and we have sent email confirmation to your email {emailAddress} ',
  WBKO_RESCHEDULE_DELIVERY_ENQUIRY_SMS_MSG:
    'เจ้าหน้าที่จัดส่งของเราจะติดต่อคุณก่อนการจัดส่งตัวเครื่องที่หมายเลขโทรศัทพ์ {phoneNumber}.',
  WBKO_UPDATE_CONTACT_DETAILS_MSG1:
    'ระบบได้ส่งคำขอเปลี่ยนแปลงข้อมูลผู้รับตัวเครื่องเป็นคุณ {fullName} และเบอร์ติดต่อคือ {phoneNumber} ไปยังหน่วยงานที่เกี่ยวข้องเรียบร้อยแล้วพร้อมส่งอีเมลยืนยันคำขอนี้ไปที่อีเมล {emailAddress}',
  WBKO_UPDATE_CONTACT_DETAILS_MSG2:
    'เจ้าหน้าที่จัดส่งของเราจะติดต่อคุณก่อนการจัดส่งตัวเครื่องที่หมายเลขโทรศัทพ์ {phoneNumber}.',
  WBKO_UPDATE_CONTACT_DETAILS_MSG3:
    'If 3PP is receiving the device on customer behalf, this person must <br/> 1) show the signed  copied of registered owner ID <br/> 2) show the 3PP ID card to the delivery man.',
  WBKO_FAQ_MSG:
    "ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่,<strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> คำถามที่พบบ่อย ๆ - FAQ</a></strong>",

  WBKO_THANK_YOU_TEXT: 'ขอบคุณที่ใช้บริการ <br>AIS Mobile Care',
  WBKO_CONFIRM_CHANGE_DELIVERY:
    'ในการรับตัวเครื่องผู้ลงทะเบียนจะต้องแสดงบัตรประจำตัวประชาชนตัวจริงกับเจ้าหน้าที่จัดส่ง <br/><br/>หรือผู้ลงทะเบียนสามารถเปลี่ยนแปลงวันจัดส่งในกรณีที่ไม่สะดวกรับในวันดังกล่าวได้',
  WBKO_CONFIRM_CHANGE_DELIVERY_HEADER: 'คุณต้องการรับตัวเครื่องในวันที่',
  WBKO_REASON_FOR_CANCEL_HEADER: 'กรุณาระบุเหตุผลในการยกเลิก',
  WBKO_REASON_FOR_CANCEL_PLACEHOLDER: 'เหตุผลในการยกเลิกคือ ... ',
  WBKO_UPDATE_CONTACT_DETAILS: 'Update Contact Details',
  WBKO_PREFFERED_ADDRESS: 'กรุณาใส่ที่อยู่ในการจัดส่งที่ต้องการ',
  WBKO_PREFFERED_DATE: 'กรุณาเลือกวันในการจัดส่งตัวเครื่อง',
  WBKO_SERVICE_DETAILS: 'รายละเอียดการขอใช้บริการ',
  WBKO_SR_NO: 'หมายเลขคำขอรับบริการ',
  WBKO_SR_TYPE: 'ประเภทการขอรับบริการ',
  WBKO_DEVICE_DETAILS: 'ตัวเครื่องที่ได้รับ',
  WBKO_SR_FEE: 'ค่าบริการสุทธิ',
  WBKO_MODE_OF_PAYMENT: 'วิธีการชำระเงิน',
  WBKO_SUBMISSION_DATE: 'วันที่ทำรายการ',
  WBKO_EXPECTED_DELIVERY_DATE: 'วันที่จัดส่ง',
  WBKO_CREDIT_CARD: 'Credit Card',
  WBKO_CONFIRMED: 'ยืนยันคำขอรับบริการ',
  WBKO_PROCESSING_ORDER: 'กำลังจัดเตรียมตัวเครื่อง',
  WBKO_SHIPPED: 'ตัวเครื่องออกจากคลังเรียบร้อยแล้ว',
  WBKO_DELIVERY_ADDRESS: 'ที่อยู่ในการจัดส่ง',
  WBKO_DELIVERY_STATUS_AFTER_SHIPPING: 'กดเพื่อเช็คสถานะการจัดส่ง',
  WBKO_TRACK_OR_END: {
    title: 'ต้องการให้เราช่วยเหลือเพิ่มเติมหรือไม่?',
    buttons: [
      { name: 'TRACK', text: 'กดเพื่อเช็คสถานะการจัดส่ง' }, //กดเพื่อทำรายการต่อ
      { name: 'END', text: 'จบการทำรายการ' },
    ],
  },
  WBKO_CANCELLATION_REASON: {
    title: 'กรุณาเลือกเหตุผลในการยกเลิก',
    buttons: [
      { name: 'DEVICEISWORKING', text: 'ตัวเครื่องใช้ได้แล้ว', Thai: 'Device is working' },
      { name: 'NEWDEVICE', text: 'ซื้อเครื่องใหม่', Thai: 'Bought a new device' },
      { name: 'ORIGINALDEVICE', text: 'พบเครื่องเดิมที่สูญหาย', Thai: 'Found original device' },
      { name: 'EXPENSIVE', text: 'ค่าบริการในการทำรายการแพงเกินไป', Thai: 'SR Fee is expensive' },
      {
        name: 'FIXITELSEWHERE',
        text: 'ต้องการนำเครื่องไปซ่อมเอง',
        Thai: 'Want to fix it elsewhere',
      },
      { name: 'OTHER', text: 'อื่น ๆ โปรดระบุ', Thai: 'Other' },
    ],
  },
  WBKO_INQ_DESC: 'ลูกค้าต้องการยกเลิกการทำรายการเหตุผลในการยกเลิก:',
  WBKO_UPDATE_CONTACT_HEADER: 'กรุณาใส่ข้อมูลของผู้รับตัวเครื่อง',
  WBKO_UPDATE_CONTACT_FIRSTNAME_PLACEHOLDER: 'ชื่อและนามสุกลตามบัตรประชาชน',
  WBKO_UPDATE_CONTACT_PHONENUMBER_PLACEHOLDER: 'เบอร์โทรศัพท์เพื่อให้เจ้าหน้าที่จัดส่งติดต่อ',
  WBKO_UPDATE_CONTACT_IDNUMBER_PLACEHOLDER: 'หมายเลขบัตรประชาชน 6 ตัวสุดท้าย',
  WBKO_UPDATE_CONTACT_FIRSTNAME: 'ชื่อ-นามสกุลผู้รับ :',
  WBKO_UPDATE_CONTACT_PHONENUMBER: 'เบอร์โทรศัพท์ที่ติดต่อได้ :',
  WBKO_UPDATE_CONTACT_IDNUMBER: 'หมายเลขบัตรประชาชนหกตัวสุดท้าย : ',
  WBKO_ADDRESS_STRING: 'วันที่จัดส่งคือ: ',
  WBKO_UPDATE_CONTACT_DETAILS_TEXT:
    'หากผู้ลงทะเบียนไม่สะดวกที่จะรับตัวเครื่องด้วยตัวเอง <br> <br>1. กรุณาเซ็นยินยอมให้ผู้อื่นรับแทน บนสำเนาบัตรประจำตัวประชาชนของคุณและมอบเอกสารให้กับผู้รับมอบอำนาจ<br> <br>2. ผู้รับมอบอำนาจจะต้องแสดงบัตรประจำตัวประชาชนตัวจริง และสำเนาบัตรประชาชนพร้อมลายเซ็นของผู้ลงทะเบียนยินยอมให้รับตัวเครื่องแทนกับเจ้าหน้าที่จัดส่งตัวเครื่อง',
  WBKO_ADDRESS_TEXT1:
    'การเปลี่ยนแปลงที่อยู่ในการจัดส่งจะส่งผลและเปลี่ยนแปลงวันที่ในการจัดส่งตัวเครื่อง\n \n ต้องการเปลี่ยนแปลงหรือยืนยันที่อยู่ในการจัดส่งเดิม?',

  WELCOME_TO_ONLINE_SUPPORT: `เรียน คุณ {Username} AIS Mobile Care ยินดีให้บริการ`,
  HOW_MAY_I_HELP_YOU: 'วันนี้ต้องการให้เราดูแลในเรื่องใด',
  PLACEHOLDERS: {
    TYPE_YOUR_TEXT: 'Type your message here',
    TYPE_YOUR_ENQUIRY: 'Type your enquiry here',
    TYPE_YOUR_MOBILE_NUMBER: 'Type your mobile number here',
    TYPE_YOUR_NRIC: 'Type your NRIC number',
    TYPE_YOUR_ENROLLED_NAME: 'Type your enrolled name here',
    TYPE_YOUR_QUERY: 'Type your query here',
    TYPE_YOUR_QUERY_FOR_SERVICE_FEE: 'Please type your query to get clarification on service fee',
  },
  ENQUIRY_OPTIONS: {
    title: 'ท่านพบปัญหาด้านใด',
    buttons: [
      { name: 'REPLACEMENT', text: 'ตัวเครื่องเกิดการสูญหาย' },
      { name: 'SWAP', text: 'ตัวเครื่องมีการตก แตกร้าว หรือ ตกน้ำ' },
      { name: 'MALFUNCTION', text: 'ตัวเครื่องทำงานผิดปกติภายใน' },
    ],
  },
  SWOP_OPTIONS: {
    title: 'กรุณาแจ้งปัญหาของท่าน',
    buttons: [
      { name: 'Liquid Exposure', text: 'ตัวเครื่องตกน้ำ' },
      { name: 'Device is scratched or dented', text: 'ตัวเครื่องมีรอยหรือบุบ' },
      { name: 'My screen is damaged', text: 'ตัวเครื่องหน้าจอแตก' },
      { name: 'Other issue', text: 'ปัญหาอื่น ๆ' },
    ],
  },
  MALFUNCTION_OPTIONS: {
    title: 'กรุณาแจ้งปัญหาที่ท่านพบ',
    buttons: [
      { name: 'Network error', text: 'สัญญาณมีปัญหา' },
      { name: 'Battery drains quickly', text: 'แบตเตอรี่หมดเร็ว' },
      { name: 'Unable to "power off" handset', text: 'ไม่ได้ยินเสียงปลายสาย' },
      { name: 'Other issue', text: 'ปัญหาอื่น ๆ' },
    ],
  },

  UPLOAD_SR: {
    HEADER: 'กรุณาอัพโหลดเอกสารการเปลี่ยนเครื่อง',
    CONTENT: "วางไฟล์ที่นี่ หรือคลิก <a href=''> ค้นหา </a>",
    ATTACHED_CONTENT: `วางไฟล์ที่นี่ หรือคลิก "ค้นหา"`,
    DISCLAIMER:
      'หมายเหตุ: รองรับได้มากสุด 3 ไฟล์ และรูปแบบไฟล์ที่รองรับ pdf jpg jpeg png<br>ที่มีขนาดสูงสุด ไม่เกิน 1 MB',
    // ALLOWED_EXT: "เมกะไบต์ ประเภทไฟล์ที่รองรับ PDF PNG JPG JPEG",
    BUTTONS: ['ยกเลิก', 'ยืนยัน'],
    UPLOAD: 'คลิกเพื่ออัพโหลดเอกสาร',
  },

  EXCHANGE_OPTIONS: {
    title: 'เลือก 1/3 ตัวเลือก',
    buttons: [
      { name: 'AIS Exchange', text: 'เคยแลกเปลี่ยนเครื่องกับทาง AIS' },
      {
        name: 'Manufacturer Exchange',
        text: 'เคยแลกเปลี่ยนเครื่องกับแบรนด์ผู้ผลิต',
      },
      { name: 'No Exchange', text: 'ไม่เคยมีการแลกเปลี่ยนตัวเครื่อง' },
    ],
  },

  OEM_EXCHANGE_SELECTED: 'เคยแลกเปลี่ยนเครื่องกับแบรนด์ผู้ผลิต',
  NO_EXCHANGE_SELECTED: 'ไม่เคยมีการแลกเปลี่ยนตัวเครื่อง',
  AIS_EXCHANGE_SELECTED: 'เคยแลกเปลี่ยนเครื่องกับทาง AIS',
  // MDN Related messages
  //New MDN UI related messages
  ASK_FOR_MDN: {
    HEADER: 'กรุณาใส่หมายเลขโทรศัพท์ที่สมัครบริการ AIS Mobile Care',
    WBKO_HEADER: 'กรุณาใส่หมายเลขโทรศัพท์ที่สมัครบริการ AIS Mobile Care 10 หลัก',
    CONTENT:
      'ขอแสดงความเสียใจกับเหตุการณ์ที่เกิดขึ้น ไม่ต้องเป็นกังวลใจเราจะช่วยดูแลท่านเอง \n กรุณาใส่หมายเลขโทรศัพท์ที่สมัครบริการ AIS Mobile Care',
    CONTENT1:
      'ขอแสดงความเสียใจกับเหตุการณ์ที่เกิดขึ้น ไม่ต้องเป็นกังวลใจเราจะช่วยดูแลท่านเอง \n กรุณาใส่หมายเลขโทรศัพท์ที่สมัครบริการ AIS Mobile Care',
    PLACEHOLDER: 'หมายเลขโทรศัพท์',
    WBKO_PLACEHOLDER: 'ใส่หมายเลขโทรศัพท์',
    BUTTON: 'ยืนยัน',
  },
  RESUME_CASE_ASK_FOR_MDN: {
    HEADER: 'กรุณาใส่หมายเลขโทรศัพท์ที่สมัครบริการ AIS Mobile Care',
    WBKO_HEADER: 'กรุณาใส่หมายเลขโทรศัพท์ที่สมัครบริการ AIS Mobile Care 10 หลัก',
    CONTENT: 'กรุณากรอกหมายเลขที่ลงทะเบียนไว้กับ AIS Mobile Care',
    PLACEHOLDER: 'หมายเลขโทรศัพท์',
    WBKO_PLACEHOLDER: 'ใส่หมายเลขโทรศัพท์',
    BUTTON: 'ยืนยัน',
  },
  ABOUT_ENQUIRY_CASE_ASK_FOR_MDN: {
    HEADER: 'กรุณาใส่หมายเลขโทรศัพท์ที่สมัครบริการ AIS Mobile Care',
    CONTENT: 'กรุณากรอกหมายเลขที่ลงทะเบียนไว้กับ AIS Mobile Care เพื่อตรวจสอบข้อมูลการจัดส่ง',
    PLACEHOLDER: 'ลูกค้ากรอกหมายเลขลงทะเบียน',
    BUTTON: 'ยืนยัน',
  },
  INCORRECT_MDN_FIRST_ATTEMPT: {
    HEADER: 'กรุณาใส่หมายเลขโทรศัพท์ที่ลงทะเบียน10หลัก',
    WBKO_HEADER: 'กรุณาใส่หมายเลขโทรศัพท์ที่สมัครบริการ AIS Mobile Care 10 หลัก',
    CONTENT:
      'ขออภัยในความไม่สะดวกเนื่องจากเราไม่พบการสมัครบริการกับหมายเลขนี้ ขอความกรุณาท่านใส่หมายเลขโทรศัพท์ที่ท่านได้สมัครบริการไว้กับเราอีกครั้ง',
    CONTENT1:
      'ขออภัยในความไม่สะดวกเนื่องจากเราไม่พบการสมัครบริการกับหมายเลขนี้ ขอความกรุณาท่านใส่หมายเลขโทรศัพท์ที่ท่านได้สมัครบริการไว้กับเราอีกครั้ง',
    PLACEHOLDER: 'หมายเลขโทรศัพท์',
    WBKO_PLACEHOLDER: 'ใส่หมายเลขโทรศัพท์',
    BUTTON: 'ยืนยัน',
  },

  //ASK_MDN: "I'm sorry to hear, let me help you to process your request.<br><br> May I ask your registered mobile number?",
  //RESUME_CASE_ASK_MDN: "May I ask your registered mobile number?",
  //ABOUT_ENQUIRY_CASE_ASK_MDN: "May I ask your registered mobile number?",
  //INCORRECT_MDN: "We regret to inform you that the mobile number you've provided doesn't match the details on our record. <br><br> So could you provide your mobile number again?",
  MDN_ERROR: {
    HEADER: `ขออภัยเป็นอย่างยิ่ง คุณ {Username}`,
    CONTENT:
      'ระบบไม่สามารถดำเนินการตามคำขอของท่านต่อไปได้ และเจ้าหน้าที่จะติดต่อท่านกลับเมื่อข้อมูลการลงทะเบียนของหมายเลขนี้ได้รับการแก้ไขเรียบร้อยแล้ว',
  },
  INCORRECT_MDN_SECOND_ATTEMPT: {
    HEADER: 'ค้นหาข้อมูลเกี่ยวกับ AIS Mobile Care เพิ่มเติมได้ที่',
    BUTTON: 'คำถามที่พบบ่อยๆ',
  },
  MOBILE_EXAMPLE: 'วิธีใส่: 0123456789 ',

  // Name related
  INCORRECT_NAME_FIRST_ATTEMPT: {
    HEADER: 'กรุณาใส่ชื่อและนามสกุล\nที่ใช้บริการอีกครั้ง',
    CONTENT:
      'เนื่องจากชื่อและนามสกุลที่ท่านแจ้งมาไม่ตรงกับข้อมูลในระบบ ขอความกรุณาให้ท่านใส่ชื่อและนามสกุลที่สมัครใช้บริการ AIS Mobile Care อีกครั้ง',
    PLACEHOLDER: 'ชื่อและนามสกุล',
    FIRST_NAME: 'ชื่อ',
    LAST_NAME: 'นามสกุล',
    BUTTON: 'ยืนยัน',
  },

  //INCORRECT_NAME: "We regret to inform you that the name you've provided doesn't match the details on our record.<br><br> Can you provide your full registered name again?",
  INCORRECT_NAME_SECOND_ATTEMPT: {
    HEADER: 'กรุณาใส่ชื่อและนามสกุล\nที่ใช้บริการอีกครั้ง',
    CONTENT:
      'เนื่องจากชื่อและนามสกุลที่ท่านแจ้งมาไม่ตรงกับข้อมูลในระบบ ขอความกรุณาให้ท่านใส่ชื่อและนามสกุลที่สมัครใช้บริการ AIS Mobile Care อีกครั้ง',
    PLACEHOLDER: 'ชื่อและนามสกุล',
    FIRST_NAME: 'ชื่อ',
    LAST_NAME: 'นามสกุล',
    BUTTON: 'ยืนยัน',
  },
  // NRIC
  //New NRIC UI related messages
  ASK_FOR_NRIC: {
    HEADER: 'กรุณาใส่หมายเลขบัตรประจำตัวประชาชน หรือหนังสือเดินทาง 4 หลักสุดท้าย',
    PLACEHOLDER: 'กรุณาใส่หมายเลขบัตร 4 หลักสุดท้าย',
    WBKO_HEADER: 'กรุณาใส่หมายเลขบัตรประจำตัวประชาชน หรือหนังสือเดินทาง 4 หลักสุดท้าย',
    WBKO_PLACEHOLDER: 'กรุณาใส่หมายเลขบัตร 4 หลักสุดท้าย',
    BUTTON: 'ยืนยัน',
  },
  WBKO: 'WBKO',
  INCORRECT_NRIC_FIRST_ATTEMPT: {
    HEADER: 'กรุณาใส่หมายเลขบัตรประจำตัวประชาชน หรือหนังสือเดินทาง 4 หลักสุดท้าย',
    WBKO_HEADER: 'กรุณาใส่หมายเลขบัตรประจำตัวประชาชน หรือหนังสือเดินทาง 4 หลักสุดท้าย',
    WBKO_PLACEHOLDER: 'กรุณาใส่หมายเลขบัตร 4 หลักสุดท้าย',
    CONTENT:
      'ขออภัยในความไม่สะดวกเนื่องจากการตรวจสอบหมายเลขบัตรประชาชนไม่ตรงกับข้อมูลของ AIS Mobile Care ขอความกรุณากรอกหมายเลขบัตรประชาชนอีกครั้ง',
    // CONTENT1: "Can you provide your national identification card number again?",
    PLACEHOLDER: 'กรุณาใส่หมายเลขบัตร 4 หลักสุดท้าย',
    BUTTON: 'ยืนยัน',
  },

  //ASK_NRIC: "Thanks {Username}!<br><br> Could you please also provide your NRIC/FIN number for further verifications?",
  //INCORRECT_NRIC: "We regret to inform you that the identification number you've provided doesn't match the details on our record.<br><br> Can you provide your NRIC/FIN again?",
  INCORRECT_NRIC_SECOND_ATTEMPT: {
    HEADER: 'หมายเลขบัตรประชาชนไม่ถูกต้อง',
    CONTENT: 'ขออภัยในความไม่สะดวกเนื่องจากเราไม่พบข้อมูลบัตรประชาชนขอท่านในระบบ',
  },
  NRIC_ERROR: {
    HEADER: 'ขออภัยเป็นอย่างยิ่ง คุณ {Username}',
    CONTENT:
      'ขออภัยในความไม่สะดวกเนื่องจากทาง AIS Mobile Care ยังไม่สามารถทำรายการให้ท่านได้ และจะมีเจ้าหน้าที่ทำการติดต่อกลับท่านโดยเร็วที่สุด',
  },
  ELIGIBLE_FOR_SERVICE: `ขอบพระคุณ คุณ {Username} สำหรับข้อมูล <br><br> จากการตรวจสอบข้อมูลของท่านตรงกับในระบบและโปรดรอสักครู่ระบบกำลังดำเนินการในขั้นตอนถัดไป`,
  USER_CURRENT_DELIVERY_DETAILS: `รายละเอียดการจัดส่งตัวเครื่องของท่าน`,
  CONTINUE_PREVIOUS_SR: 'ทำรายการต่อจากที่ค้างไว้',
  // NO_EXISTING_SR: "You do not have any existing Service request. Please file a new one!",
  NO_EXISTING_SR: {
    HEADER: 'การทำรายการไม่ถูกต้อง',
    CONTENT: 'หมายเลขไม่ถูกต้อง \n\n ขอบพระคุณที่ใช้บริการ AIS Mobile Care',
    BUTTON: 'เริ่มทำรายการใหม่',
  },
  PAYMENT_INVALID_LABEL: {
    NAME: 'กรุณาใส่ชื่อนามสกุลที่ถูกต้อง',
    CARD_NUMBER: 'กรุณาใส่หมายเลขบัครเครดิตที่ถูกต้อง',
    EXPIRY: 'กรุณาใส่วันหมดอายุบัตรที่ถูกต้อง',
    CVC: 'กรุณาใส่ cvc ที่ถูกต้อง',
    NAME_MANDATORY: 'ต้องระบุชื่อฟิลด์',
  },
  NATIONAL_ID_REQUEST: 'เรียน คุณ {Username} กรุณาใส่หมายเลขบัตรประจำตัวประชาชนเพื่อทำรายการต่อ',
  NO_CURRENT_DELIVERY: {
    CONTENT: 'ไม่พบข้อมูลการทำรายการกับ AIS Mobile Care',
    BUTTONS: ['ปิด'],
  },
  CLOSE_REQUEST: {
    CONTENT: 'ขอบพระคุณที่ใช้บริการ AIS Mobile Care',
    BUTTONS: ['ปิด'],
  },
  TRANSFERRING_TO_AGENT: 'เรากำลังส่งต่อข้อมูลการสนทนาของท่านไปยังเจ้าหน้าที่กรุณารอซักครู่',
  TRANSFERRED_TO_AGENT: 'ทาง AIS Mobile Care กำลังติดต่อเจ้าหน้าที่สำหรับการช่วยเหลือเพิ่มเติม',
  CHAT_SUPPORT_ENDED: 'การสนทนากับเจ้าหน้าที่ได้สิ้นสุดลงแล้ว',
  CHAT_ENDED_BY_USER: 'The user has ended the chat.',
  CHECK_DEVICES_LOADER_START: 'กำลังตรวจสอบตัวเครื่องในคลังสินค้า',
  STANDARDISE_ADDRESS_LOADER_START: 'กำลังดำเนินการตรวจสอบที่อยู่',
  STANDARDISE_ADDRESS_LOADER_END: 'ที่อยู่ได้รับการตรวจสอบเรียบร้อยแล้ว',
  STANDARDISE_ADDRESS_LOADER_FAILURE: 'WRONG ADDRESS!',
  CC_DETAILS_PROGRESS_START: 'กำลังดำเนินการตรวจสอบข้อมูลบัตรเครดิตของท่าน',
  CC_DETAILS_PROGRESS_END: 'บัตรเครดิตของท่านได้รับการตรวจสอบเรียบร้อยแล้ว',
  CC_DETAILS_PROGRESS_FAILED: 'ข้อมูลบัตรเครดิตของท่านไม่ถูกต้อง',
  SHOW_DELIVERY_TIME_DATE:
    'เวลาในการนำส่งตัวเครื่องคือ วันที่ <strong> {Date} {Time Slot} </strong> <br><br>กรุณารอรับตัวเครื่องด้วยตนเองพร้อมแสดงบัตรประชาชนตัวจริงให้กับทางเจ้าหน้าที่ก่อนรับสินค้า',
  IMEI_FIRST_INCORRECT_ATTEMPT:
    'ขออภัยในความไม่สะดวก เนื่องจากหมายเลขประจำตัวเครื่องที่ท่านแจ้งไม่ถูกต้อง <br><br>กรุณาใส่หมายเลขประจำตัวเครื่อง 6 หลักสุดท้ายอีกครั้ง',
  IMEI_SECOND_INCORRECT_ATTEMPT:
    'เนื่องจากหมายเลขประจำตัวเครื่องที่ท่านแจ้งไม่ตรงกับข้อมูลในระบบ ท่านเคยมีการแลกเปลี่ยนเครื่องกับ AIS หรือ ทางศูนย์บริการมาก่อนหรือไม่',
  WAIT_FOR_ENROLLED_DEVICE:
    'เราจะดำเนินการสั่งจองตัวเครื่องให้กับท่านและจะมีการติดต่อกลับไปอีกครั้งเมื่อมีเครื่องอยู่ในสต๊อก',
  NOTIFY_ON_AVAILABILITY:
    'ขอบพระคุณที่ทำรายการกับ AIS Mobile Care ทางเราจะส่งข้อความแจ้งเมื่อมีตัวเครื่องอยู่ในสต๊อกเรียบร้อยแล้ว<br><br>หากมีข้อมูลส่วนใดสอบถามเพิ่มเติมสามารถแจ้ง AIS Mobile Care ได้โดยตรง',
  THANK_YOU_FOR_CONNECTING:
    "ขอบพระคุณที่ทำรายการกับ AIS Mobile Care <br><br>  ทาง AIS Mobile Care จะทำการส่งข้อมูลการทำรายการไปยังหมายเลขโทรศัพท์และอีเมล์ของท่าน และท่านสามารถดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <strong><a target='_blank' rel='noopener noreferrer nofollow' class='ais_support-hotline' title='FAQ' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong>",
  BE_PRESENT_AT_ADDRESS:
    'กรุณารอรับตัวเครื่องด้วยตนเองพร้อมแสดงบัตรประชาชนตัวจริงให้กับทางเจ้าหน้าที่ก่อนรับสินค้า',
  THANKS_FOR_CONNECTING_ORDER_PLACED:
    "ขอบพระคุณที่ทำรายการกับ AIS Mobile Care, ทาง AIS Mobile Care จะทำการส่งข้อมูลการทำรายการไปยังหมายเลขโทรศัพท์และอีเมล์ของท่าน และท่านสามารถดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <strong><a target='_blank' rel='noopener noreferrer nofollow' class='ais_support-hotline' title='FAQ' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong>",
  SWAP_MAL_BACKORDER_CASE_RETURN_DEVICE:
    'กรุณานำเครื่องลงทะเบียนส่งมอบให้กับเจ้าหน้าที่จัดส่ง โดยส่งมอบเฉพาะตัวเครื่องเปล่าไม่รวมอุปกรณ์เสริม (แบตเตอรี่ การ์ดความจำ ซิมการ์ด เป็นต้น)',
  SWAP_MAL_BACKORDER_CASE_RETURN_DEVICE1:
    'เจ้าหน้าที่จัดส่งจะอยู่ด้วยเพียง 10 นาที แล้วขอตัวกลับ และขอให้ท่านทำการสำรองข้อมูลให้เรียบร้อยก่อนเวลานัดหมาย <br> <br>ในกรณีที่ไม่มีผู้รับหรือมีผู้รับแต่ไม่สามารถพิสูจน์ตัวตนผู้ใช้บริการได้ หรือผู้ใช้บริการปฏิเสธการจัดส่งเครื่อง กรุณาติดต่อเราเพื่อทำการนัดหมายวันจัดส่งในครั้งถัดไปซึ่งอาจจะมีค่าใช้จ่ายในการจัดส่งเพิ่มเติม',
  REPLACEMENT_BACKORDER_RETURN_DEVICE:
    'เจ้าหน้าที่จะอยู่ด้วยประมาณ 10 นาที กรุณาสำรองข้อมูลก่อนให้มีการจัดส่ง และจะขออนุญาตกลับเนื่องจากต้องมีการนำส่งตัวเครื่องให้กับลูกค้าท่านอื่น <br><br>หากมีการนำส่งไม่สำเร็จทาง AIS Mobile Care ขออนุญาตสงวนสิทธิ์ในการนำส่ง และรบกวนติดต่อกลับเพื่อทำการนัดหมายในการจัดส่งอีกครั้ง',
  NO_EXCHANGE:
    'ขออภัยในความไม่สะดวกเนื่องจากทาง AIS Mobile Care ยังไม่สามารถทำรายการให้ท่านได้ <br><br>กรุณาเตรียมเอกสารที่เกี่ยวข้องและทำการติดต่อกลับเข้ามาทาง Online Support.<br><br>ขอบพระคุณที่เลือกใช้บริการ AIS Mobile Care',
  OEM_EXCHANGE:
    '	กรูณาเตรียมเอกสารการแลกเปลี่ยนตัวเครื่องกับทางแบรนด์ผู้ผลิต เพื่อแสดงกับพนักงานจัดส่งเมื่อมีการนำส่งตัวเครื่อง มิเช่นนั้นทาง AIS Mobile Care ขออนุญาตสงวนสิทธิ์ในการนำส่งตัวเครื่อง',
  AIS_EXCHANGE:
    'ข้อมูลส่วนบุคคลใดๆ ที่ใช้หรือปรากฎในระหว่างการทำรายการในครั้งนี้ มีจุดประสงค์เพื่อใช้ในการขอรับบริการแลกเปลี่ยนเครื่องและรับเครื่องทดแทน ภายใต้นโยบายความเป็นส่วนตัวของ AIS และเงื่อนไขการให้บริการของ AIS Mobile Care',
  VALID_ADDRESS_NOTE:
    'เราสามารถจัดส่งตัวเครื่องของท่านได้ทั่วประเทศไทยทั้งที่บ้านหรือที่ทำงานแต่เราขอสงวนสิทธิ์ในการส่งตัวเครื่องไปยังที่อยู่แบบตู้ไปรษณีย์เช่า (ตู้ ป.ณ.)<br> <br> หมายเหตุ ที่อยู่การจัดส่งจะได้รับการเปลี่ยนแปลงโดยที่อยู่ลงทะเบียนในระบบยังคงเดิม',
  WBKO_VALID_ADDRESS_NOTE:
    'เราสามารถจัดส่งตัวเครื่องของท่านได้ทั่วประเทศไทยทั้งที่บ้านหรือที่ทำงาน เราขอสงวนสิทธิ์ในการส่งตัวเครื่องไปยังที่อยู่แบบตู้ไปรษณีย์เช่า (ตู้ ป.ณ.)<br><br>หมายเหตุ: การเปลี่ยนแปลงที่อยู่นี้มีผลเฉพาะการจัดส่งตัวเครื่องในครั้งนี้ และไม่ส่งผลต่อที่อยู่ที่ใช้ในการสมัครบริการ',
  ENQUIRY_DESCRIPTION_CALLBACK:
    'ท่านต้องการให้ติดต่อ ที่เบอร์มื อถือ {MobileNumber} ภายใน {DateTimeOfRequest}',
  LIVE_CHAT_ASSISTANCE_UNAVAILABLE: 'ขออภัยขณะนี้อยู่นอกเวลาทำการ(เวลาทำการ 08.00 - 22.00 น.)',
  ALL_AGENT_OFFLINE_ENQUIRY: 'เจ้าหน้าที่แชทออฟไลน์',
  ALL_AGENT_UNAVAILABLE_ENQUIRY: 'ขออภัยขณะนี้อยู่นอกเวลาทำการ(เวลาทำการ 08.00 - 22.00 น.)',
  TRIAGE_QUESTION: [
    {
      HEADER: 'ท่านได้ลองปิดและเปิดเครื่องใหม่แล้วหรือไม่',
      ICON: 'deviceOffIcon',
      BUTTONS: ['ไม่ใช่', 'ใช่'],
      YES_TEXT: ' ใช่',
      NO_TEXT: 'ไม่ใช่',
    },
    {
      HEADER: 'ท่านได้ลองอัพเดทระบบปฎิบัติการแล้วหรือไม่',
      ICON: 'deviceOffIcon',
      BUTTONS: ['ไม่ใช่', 'ใช่'],
      YES_TEXT: ' ใช่',
      NO_TEXT: 'ไม่ใช่',
    },
    {
      HEADER: 'ท่านได้ลองรีเซ็ตเป็นค่าเริ่มต้นจากโรงงานแล้วหรือไม่',
      ICON: 'helpIcon',
      BUTTONS: ['ไม่ใช่', 'ใช่'],
      YES_TEXT: ' ใช่',
      NO_TEXT: 'ไม่ใช่',
    },
  ],
  TRIAGE_MESSAGE: [
    'เราขอแนะนำให้ท่านทำการรีสตาร์ทตัวเครื่อง',
    'เราขอแนะนำให้ท่านทำการอัพเดทระบบปฎิบัติการตัวเครื่อง',
    'เราขอแนะนำให้ท่านทำการรีเซ็ตเป็นค่าเริ่มต้นจากโรงงาน',
  ],
  TRIAGE_MESSAGE_NEW: [
    {
      HEADER: '<strong>BEFORE YOU RESTART YOUR DEVICE</strong>',
      CONTENT: `<strong>If you are using your mobile phones, </strong> you can resume back to this service request by simple logging in select the <strong>Resume Existing Request</strong> to continue with the process`,
      BUTTON: 'Confirm Device Restart',
    },
    {
      HEADER: '<strong>BEFORE YOU UPDATE YOUR OPERATING SYSTEM</strong>',
      CONTENT: `<strong>If you are using your mobile phones, </strong> you can resume back to this service request by simple logging in select the <strong>Resume Existing Request</strong> to continue with the process`,
      BUTTON: 'Confirm Device Restart',
    },
    {
      HEADER: '<strong>BEFORE YOU RESET TO FACTORY DEFAULT</strong>',
      CONTENT: `<strong>If you are using your mobile phones, </strong> you can resume back to this service request by simple logging in select the <strong>Resume Existing Request</strong> to continue with the process`,
      BUTTON: 'Confirm Device Restart',
    },
  ],
  ISSUE_RESOLVED: {
    HEADER: 'สามารถแก้ปัญหาได้หรือไม่',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
    YES_TEXT: 'ใช่',
    NO_TEXT: 'ไม่ใช่',
  },
  CURRENT_DEVICE_UNAVAILABLE:
    'ต้องขออภัยด้วยเนื่องจากตัวเครื่องรุ่น {UnavailableDevice} สินค้าหมดชั่วคราว',
  INCORRECT_IMEI: 'กรุณากรอกหมายเลขประจำตัวเครื่องที่ถูกต้อง',

  NAME_ERROR: {
    HEADER: `ขออภัยคุณ {Username}`,
    CONTENT:
      'เนื่องจากข้อมูลชื่อและนามสกุลของท่านไม่ตรงกับข้อมูลในระบบ เราจะส่งต่อข้อมูลการสนทนาของท่านไปยังเจ้าหน้าที่',
  },
  AGENT_BUSY: {
    HEADER: 'เจ้าหน้าที่ให้บริการเต็มทุกคู่สาย',
    CONTENT:
      'กรุณารอสักครู่เนื่องจากท่านอยู่ในระหว่างรอรับบริการ เจ้าหน้าที่จะรับสายท่านภายใน 2 นาที',
  },
  ALL_AGENT_BUSY: {
    PRIMARY_HEADER: 'พนักงานให้บริการลูกค้าท่านอื่นอยู่',
    SECONDARY_HEADER:
      'ขออภัยในความไม่สะดวกเนื่องจากเจ้าหน้าที่ออนไลน้ให้บริการลูกค้าท่านอื่นอยู่ <br> กรุณารอสักครู่หรือลองใหม่อีกครั้งในภายหลัง',
    PRIMARY_CONTENT:
      "​ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <br><strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong>",
    SECONDARY_CONTENT:
      "​ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <br><strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong>",
    INPUT_PLACEHOLDER: 'กรอกหมายเลข',
    INPUT_LABEL: 'Mobile Number',
    BUTTON: ['ยืนยัน'],
  },

  ALL_AGENT_BUSY_MODAL: {
    PRIMARY_HEADER: 'พนักงานให้บริการลูกค้าท่านอื่นอยู่',
    SECONDARY_HEADER:
      'ขออภัยในความไม่สะดวกเนื่องจากเจ้าหน้าที่ออนไลน้ให้บริการลูกค้าท่านอื่นอยู่ <br> กรุณารอสักครู่หรือลองใหม่อีกครั้งในภายหลัง',
    PRIMARY_CONTENT:
      "​ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <br><strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong>",
    SECONDARY_CONTENT:
      "​ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <br><strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong>",
    INPUT_PLACEHOLDER: 'กรอกหมายเลข',
    INPUT_LABEL: 'Mobile Number',
    BUTTON: ['ยืนยัน'],
  },

  ALL_AGENT_OFFLINE: {
    PRIMARY_HEADER: 'ไม่สามารถให้บริการได้ในขณะนี้',
    SECONDARY_HEADER:
      'ขออภัยในความไม่สะดวก <br> เนื่องจากเจ้าหน้าที่ออนไลน์ไม่สามารถให้บริการได้ในขณะนี้',
    PRIMARY_CONTENT:
      "ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> <br><br>ขอบพระคุณที่ใช้บริการ AIS Mobile Care",
    SECONDARY_CONTENT:
      "ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> <br><br>ขอบพระคุณที่ใช้บริการ AIS Mobile Care",
    INPUT_PLACEHOLDER: 'Enter your mobile number',
    INPUT_LABEL: 'Mobile Number',
    BUTTON: ['ยืนยัน'],
  },

  ALL_AGENT_OFFLINE_MODAL: {
    PRIMARY_HEADER: 'ไม่สามารถให้บริการได้ในขณะนี้',
    SECONDARY_HEADER:
      'ขออภัยในความไม่สะดวก <br> เนื่องจากเจ้าหน้าที่ออนไลน์ไม่สามารถให้บริการได้ในขณะนี้',
    PRIMARY_CONTENT:
      "ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> <br><br>ขอบพระคุณที่ใช้บริการ AIS Mobile Care",
    SECONDARY_CONTENT:
      "ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่ <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> <br><br>ขอบพระคุณที่ใช้บริการ AIS Mobile Care",
    INPUT_PLACEHOLDER: 'Enter your mobile number',
    INPUT_LABEL: 'Mobile Number',
    BUTTON: ['ยืนยัน'],
  },
  // Progressbar messages for account verification
  ACCOUNT_BEING_VERIFIED: 'กำลังดำเนินการตรวจสอบข้อมูลของท่าน',
  ACCOUNT_VERIFIED: 'ข้อมูลของท่านได้รับการตรวจสอบเรียบร้อยแล้ว',
  ACCOUNT_UNVERIFIED: 'ขออภัยในความไม่สะดวก จากการตรวจสอบข้อมูลไม่พบข้อมูลของท่านในระบบ',

  CC_ATTEMPT_ERROR: {
    HEADER: `ขออภัยเป็นอย่างยิ่ง คุณ {Username}`,
    CONTENT:
      'การกรอกข้อมูลของท่านไม่ถูกต้องตามที่กำหนด ทางเราขอส่งต่อข้อมูลของท่านไปยังเจ้าหน้าที่เพื่อช่วยเหลือเพิ่มเติม',
  },
  ADDRESS_ATTEMPT_ERROR: {
    HEADER: `ขออภัยเป็นอย่างยิ่ง คุณ {Username}`,
    CONTENT:
      'การกรอกข้อมูลของท่านไม่ถูกต้องตามที่กำหนด ทางเราขอส่งต่อข้อมูลของท่านไปยังเจ้าหน้าที่เพื่อช่วยเหลือเพิ่มเติม',
  },
  ADDRESS: {
    ADDRESS_LINE1: 'ที่อยู่บรรทัดที่1:',
    ADDRESS_LINE2: 'ที่อยู่บรรทัดที่2:',
    ADDRESS_LINE3: 'ที่อยู่บรรทัดที่3:',
    PIN_CODE: 'รหัสไปรษณีย์',
    CITY: 'เขต/อำเภอ:',
    STATE: 'จังหวัด:',
    PROCEED: 'ยืนยันการทำรายการต่อ',
  },
  PROCEED: 'ยืนยันการทำรายการต่อ',
  CANCEL: 'ยกเลิก',
  CONTINUE: 'กลับไปเมนูหลัก',
  TERMS_ACCEPTED: 'ยอมรับเงื่อนไข',
  AGREEE_WITH_TERMS: 'ท่านยอมรับเงื่อนไขดังต่อไปนี้ หรือไม่',
  BACKORDER_MESSAGE: 'ตัวเครื่องลงทะเบียนหมดสต๊อกและอยู่ในระหว่างการสั่งจอง',
  CANT_ACCEPT_DEVICE_WITHOUT_FMIP_OFF:
    'ก่อนการดำเนินรายการต่อรบกวนท่านทำการสำรองข้อมูลก่อนทำการปิดแอปพลิเคชั่น FMIP และทำการปิดไว้จนกว่าการแลกเปลี่ยนเครื่องในครั้งนี้จะเสร็จสมบูรณ์ มิฉะนั้นอาจจะส่งผลให้เกิดความล่าช้าในการจัดส่งตัวเครื่องได้',
  TURN_OFF_FMIP: `ในขั้นตอนถัดไป กรุณาทำการสำรองข้อมูลให้เรียบร้อยและทำการปิด <strong> "ค้นหา iPhone ของฉัน" (Find my iPhone) </strong>`,
  TURN_OFF_FMIP_EXTENDED:
    'ไว้จนกว่าการแลกเปลี่ยนเครื่องในครั้งนี้สำเร็จ มิฉะนั้นอาจจะส่งผลให้เกิดความล่าช้าในการจัดส่งตัวเครื่อง และหากไม่สามารถปิดเชื่อมโยงกับ Appleได้จะมีค่าใช้จ่ายในการทำรายการครั้งนี้เสมือนการทำรายการรับเครื่องทดแทน',
  AGREE_WITH_TERMS: 'ตรวจสอบ',
  WARRANTY_VOID_DISCLAIMER:
    'เราขอแจ้งให้ท่านทราบว่า การรับประกันกับแบรนด์ผู้ผลิตจะสิ้นสุดลงทันทีหลังการแลกเปลี่ยนเครื่องสำเร็จ โดยทาง AIS Mobile Care จะเป็นผู้ดูแลตัวเครื่องให้กับท่านต่ออีก 180 วันเงื่อนไขเดียวกันกับประกันศูนย์ <br><br>ท่านยอมรับเงื่อนไขหรือไม่',
  STILL_IN_WARRANTY_DISCLAIMER:
    'เราพบว่าตัวเครื่องของท่านยังคงอยู่ในระยะเวลาการรับประกัน 1 ปีกับทางแบรนด์ผู้ผลิต ซึ่งท่านสามารถติดต่อกับแบรนด์ผู้ผลิตเพื่อสอบถามถึงการรับประกันตัวเครื่องได้โดยตรง<br><br> หรือหากท่านประสงค์จะทำรายการต่อทางเราก็ยินดี และการทำรายการในครั้งนี้จะมีค่าบริการในการแลกเปลี่ยนเครื่องหรือรับเครื่องทดแทน',
  IN_STOCK_DEVICE_MESSAGE: 'อุปกรณ์พร้อมจัดส่ง',
  OUT_OF_STOCK_DEVICE_MESSAGE: 'หากท่านต้องการตัวเครื่องนี้การจัดส่งจะใช้เวลาประมาณ 5 - 7 วัน',
  CHECK_ALTERNATE_DEVICE: 'ตรวจสอบตัวเครื่องที่มีคุณสมบัติใกล้เคียงกัน',
  WAIT_FOR_DEVICE: 'ต้องการรอตัวเครื่อง',
  CONFIRM_CURRENT_DEVICE: {
    HEADER: 'ตัวเครื่องลงทะเบียน',
    CONTENT: 'กรุณายืนยันตัวเครื่องที่ลงทะเบียนไว้',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
    NO_TEXT: 'ไม่ใช่',
    YES_TEXT่: 'ใช่',
  },
  SELECT_YOUR_DEVICE: ['ตัวเครื่องลงทะเบียน', 'ตัวเครื่องที่ได้จากการแลกเปลี่ยน/รับเครื่องทดแทน'],
  SELECT_DEVICE_CLAIM: 'โปรดเลือกตัวเครื่องที่ถืออยู่',
  SELECT_OTHER_DEVICE: 'เลือกตัวเครื่องอื่น ๆ',
  // Download Messages
  DOWNLOAD_HEADER: 'กรุณาอัพโหลดเอกสาร คำขอ-การขอรับบริการครื่องทดแทน เพื่อทำรายการต่อ',
  ELIGIBLE_FOR_RESHIP: `กรุณาติดต่อเราที่ <a class='ais_support-hotline' href='tel://0980981175'>0980981175</a> เพื่อทำรายการอีกครั้ง
    ขอบพระคุณที่ใช้บริการ AIS Mobile Care`,
  PAYMENT_ORDER_PENDING:
    'ระบบได้รับรายการของท่านเรียบร้อยแล้วและอยู่ในขั้นตอนการพิจารณา โดยเราจะยังคงมิได้ดำเนินการเรียกเก็บค่าบริการจนกว่ารายการของท่านจะได้รับการอนุมัติ ซึ่งท่านจะได้รับการแจ้งเตือนผ่านทางข้อความ และอีเมลหลังรายการของท่านได้รับการอนุมัติเรียบร้อยแล้ว',
  PAYMENT_ORDER_PENDING_COD:
    'ระบบได้รับรายการของท่านเรียบร้อยแล้วและอยู่ในขั้นตอนการพิจารณา ซึ่งท่านจะได้รับการแจ้งเตือนผ่านทางข้อความ และอีเมลหลังรายการของท่านได้รับการอนุมัติเรียบร้อยแล้ว',
  ORDER_CONFIRMED:
    'ขอบคุณสำหรับการยืนยันการทำรายการ<br><br>กรุณารอสักครู่ระบบกำลังดำเนินการทำรายการ',
  ORDER_APPROVED:
    'ขอเรียนแจ้งให้ทราบว่ารายการของท่านได้รับการอนุมัติเรียบร้อยแล้ว<br>ทาง AIS Mobile Care จะทำการส่งข้อมูลการทำรายการไปยังหมายเลขโทรศัพท์และอีเมล์ของท่าน',
  NO_PAYMENT_REVIEW: {
    HEADER:
      'ขออภัยในความไม่สะดวก เนื่องจากการทำรายการก่อนหนี้เป็นการทำผ่านทางช่องทาง 0980981175 ดังนั้นระบบจะไม่สามารถทำรายการต่อให้กับคำขอเดิมได้ กรุณายืนยันในการเริ่มต้นทำรายการใหม่อีกครั้ง',
    BUTTONS: ['ยืนยันและเริ่มทำรายการใหม่ตั้งแต่ต้น'],
  },
  SELECT_DOWNLOAD_DOCUMENT: {
    HEADER: 'เลือกเอกสาร',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT:
    'ขออภัยค่ะ ระบบไม่พบข้อมูลการสมัครหรือสถานะบริการ AIS Mobile Care โดนระงับชั่วคราว กรุณาตรวจสอบข้อมูลบริการอีกครั้งในวันถัดไปหากการสมัครบริการหรือการชำระค่าบริการล่าสุดน้อยกว่า 24 ชั่วโมง',
  NOT_PROCEED_FURTHER:
    "ขออภัยในความไม่สะดวกเนื่องจากทาง AIS Mobile Care ยังไม่สามารถทำรายการให้ท่านได้  ท่านสามารถติดต่อเจ้าหน้าที่ได้ที่หมายเลข <a class='support-hotline' href='tel://0980981175'>0980981175</a> เพื่อขอความช่วยเหลือเพิ่มเติม <br><br> ขอบพระคุณที่เลือกใช้บริการ AIS Mobile Care",
  NO_ACCESSORIES_PACKAGE:
    'ในการทำรายการขอเรียนแจ้งให้ทราบว่าตัวเครื่องจะถูกจัดส่งเฉพาะเครื่องเปล่าไม่รวมอุปกรณ์เสริมอยู่ในกล่อง AIS Mobile Care',
  S_PEN_INFO: `กรณีรุ่นที่ไม่เหมือนเครื่องปัจจุบัน ทางเราจะทำการจัดส่งS-Penให้`,
  DEVICE_BACKUP: 'ทางเราแนะนำให้ท่านมีการสำรองข้อมูลก่อนมีการจัดส่งตัวเครื่อง',
  // DEVICE_IN_STOCK: `{Username}, I’m happy to inform you that we have an iPhone X 64GB Silver in stock. `,
  DEVICE_IN_STOCK:
    'ขอแสดงความยินดีด้วยตัวเครื่อง {UnavailableDevice} มีอยู่ในคลังสินค้าพร้อมจัดส่ง',
  CURRENT_DEVICE_OPTIONS: {
    HEADER: 'โปรดเลือก\nตัวเครื่องลงทะเบียนที่ถูกต้อง',
    BRAND_DD_HEADER: 'ยี่ห้อ',
    MODEL_DD_HEADER: 'รุ่น',
    BUTTON: 'เลือก',
  },
  FAQ_PAGE: {
    CONTENT: 'ต้องการความช่วยเหลือเพิ่มเติมหรือไม่',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  CONFIRM_EXISTING_CUSTOMER: {
    CONTENT: 'คุณเป็นลูกค้า AIS Mobile Care ปัจจุบันหรือไม่?',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  GE_INQUIRY_REASON: {
    CONTENT: 'กรุณาให้รายละเอียดในสิ่งที่คุณต้องการสอบถาม',
    HEADER: 'กรุณาให้รายละเอียดในสิ่งที่คุณต้องการสอบถาม',
    PLACEHOLDER: 'พิมพ์คำถามของคุณที่นี่',
    BUTTON: 'ยืนยัน',
  },
  GENERAL_ENQUIRY: {
    HEADER: 'เลือกเรื่องที่ท่านต้องการสอบถาม?',
    ENQUIRY_TYPE_HEADER: 'หัวข้อเรื่อง',
    ENQUIRY_SUBTYPE_HEADER: 'เลือกหัวข้อเรื่องหลัก',
    PLACEHOLDER: 'ระบุรายละเอียด',
    BUTTON: 'ยืนยันการสอบถาม',
  },
  AVAIL_DEVICE: {
    HEADER: 'ต้องการตัวเครื่องที่มีอยู่ในคลังสินค้าหรือไม่',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  PROCEED_SERVICE_FEE: {
    HEADER: 'ค่าบริการแลกเปลี่ยนเครื่อง/รับเครื่องทดแทน',
    CONTENT:
      'ค่าบริการสุทธิในการทำรายการครั้งนี้คือ<strong> {TotalAmount} บาท </strong>\nรวมภาษีมูลค่าเพิ่มเรียบร้อยแล้ว',
    BUTTON: 'ดำเนินการต่อ',
  },
  COD_CONFIRMATION: {
    HEADER:
      'กรุณาเตรียมเงินในการชำระค่าบริการให้พอดี\nเนื่องจากเจ้าหน้าที่จัดส่งจะไม่ได้สำรองเงินทอนไปด้วย',
    CONTENT: 'ค่าใช้บริการ <br/><br/> <strong> THB {TotalAmount}</strong><br/>',
    BUTTONS: ['ยกเลิก', 'ตกลง'],
  },
  TURN_OFF_MY_IPHONE: {
    HEADER: `ขั้นตอนการปิด "ค้นหา iPhone ของฉันหรือFind my iPhone`,
    CONTENT1: 'Open Settings.',
    CONTENT2: 'Tap iCloud.',
    CONTENT3: 'Choose Find My iPhone.',
    CONTENT4: 'Set Find My iPhone to Off.',
    CONTENT5: 'Enter the Apple ID password for the account listed and tap Turn Off.',
    BUTTON: 'Tap here when FMIP is turned off',
    TURN_OFF_FMIP_MESSAGE:
      "Find My iPhone will now be disabled for that iOS device, and you can continue to reset the device to its factory settings (if you're planning to sell it.) Do this using Settings > General > Reset and Erase All Content and Settings.",
  },

  CONFIRM_FMIP_IS_OFF: {
    HEADER: 'กรุณายืนยันว่าท่านได้ทำการปิดการใช้งาน แอปพลิเคชัน FMIP เรียบร้อยแล้ว',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
    YES_TEXT: 'ใช่',
    NO_TEXT: 'ไม่ใช่',
  },
  TURN_OFF_FMIP_INSTRUCTIONS: {
    HEADER: 'ขั้นตอนง่าย ๆ ในการปิด FMIP นั้นสามารถทำได้ตามขั้นตอนดังนี้',
    CONTENT1: `1. ท่านสามารถปิด "ค้นหา iPhone ของฉัน"  โดยไปที่เมนู การตั้งค่า`,
    CONTENT2:
      '2. แตะไปที่บัญชีของท่านซึ่งจะมีชื่อที่ท่านตั้งไว้ปรากฎอยู่ หลังจากนั้นเลือกเมนู iCloud',
    CONTENT3: `3. เลื่อนลงมาทางด้านล่างจนท่านพบ "ค้นหา iPhone ของฉัน" แล้วเลื่อนจากแถบสีเขียวให้เป็นสีเทา`,
    CONTENT4: '4. เลื่อนแทบสีเขียวเป็นสีเทา และใส่ รหัส Apple ID เพื่อทำการปิด Find my iPhone',
    CONTENT5: '<strong> หมายเหตุ: โปรดปิด Find my iPhone ไว้จนกว่าการทำรายการจะสำเร็จ</strong>',
    BUTTON: 'กรุณาคลิกทีนี่เพื่อยืนยันว่า FMIP ได้ปิดแล้วเรียบร้อย',
  },
  SHIPPING_ADDRESS_HOLD_CONFIRM_ENROLLED_ADDRESS:
    'หากต้องการทำรายการต่อ ขออนุญาตเรียนแจ้งให้ทราบว่า ทาง AIS Mobile Care สามารถจัดส่งตัวเครื่องไปยังที่อยู่ลงทะเบียน ณ หมายเลขไปรษณีย์{Postal Code}',
  CONFIRM_ENROLLED_ADDRESS: {
    HEADER:
      'กรุณายืนยันสถานที่ที่ต้องการให้\nAIS Mobile Care นำส่งตัวเครื่อง\nไปยังที่อยู่ลงทะเบียนด้านล่างหรือไม่',
    WBKO_HEADER: 'ต้องการให้จัดส่งไปยังที่อยู่ทางด้านล่างใช่หรือไม่',
    BUTTONS: ['ไม่ใช่', 'ใช่', 'เปลี่ยนที่อยู่', 'ยกเลิก', 'เปลี่ยนแปลง', 'ยืนยัน'],
  },
  //ashwin
  FINAL_CONFIRM_ENROLLED_ADDRESS: {
    HEADER:
      'หากต้องการทำรายการต่อ ขออนุญาตเรียนแจ้งให้ทราบว่า ทาง AIS Mobile Care สามารถจัดส่งตัวเครื่องไปยังที่อยู่ลงทะเบียน ณ หมายเลขไปรษณีย์',
    BUTTONS: ['ยืนยันการทำรายการต่อ'],
  },
  CONFIRM_EMAIL_ADDRESS: {
    HEADER: 'ท่านต้องการให้ส่งอีเมลแจ้งเตือนไปยังอีเมลนี้ใช่หรือไม่',
    BUTTONS: ['ไม่ใช่', ' ใช่'],
  },
  CONFIRM_MOB_NO: {
    HEADER: 'ท่านต้องการให้ส่งการแจ้งเตือนไปยังหมายเลขลงทะเบียนนี้ใช่หรือไม่',
    BUTTONS: ['ไม่ใช่', ' ใช่'],
  },
  ASK_CONTACT_FOR_ENQUIRY_CALLBACK: {
    HEADER: 'กรุณาแจ้งหมายเลขโทรศัพท์',
    PLACEHOLDER: 'กรุณาใส่หมายเลขโทรศัพท์10หลัก',
    BUTTONS: ['ยืนยัน'],
  },
  CONFIRM_DELIVERY_ADDRESS:
    'In our records, is this still the address that you want us to ship your replacement device?',
  PAYMENT_FEE: {
    HEADER: 'ช่องทางการชำระค่าบริการ',
    CONTENT:
      'ค่าบริการสุทธิในการทำรายการครั้งนี้คือ <strong>{TotalAmount}</strong> บาท\nรวมภาษีมูลค่าเพิ่มเรียบร้อยแล้ว<br><br>กรุณาเลือกช่องทางในการชำระค่าบริการในครั้งนี้',
    BUTTON: 'ยืนยันการทำรายการต่อ',
    BUTTON1: 'จ่ายบัตรเครดิต',
    BUTTON2: 'จ่ายเงินสด',
    DISCOUNT: '*1% Discount',
    DISCOUNT1: 'for Credit Card Payments',
    NOTE: '<strong>Note:</strong>You will receive 1% discount on this service fee with credit card payment method is selected)',
  },
  READY_CREDIT_CARD: 'กรุณาเตรียมข้อมูลบัตรเครดิต',
  WEB_CREDITCARD_FORM: {
    HEADER: 'กรุณาใส่ข้อมูลบัตรเครดิตของท่าน',
    LABELS: {
      NAME: 'ชื่อและนามสกุลภาษาอังกฤษตามหน้าบัตร',
      CARD_NO: 'หมายเลขหน้าบัตรเครติด 16 หลัก',
      EXPIRY: 'วันหมดอายุบัตร',
      CVC: 'CVC',
    },
    PLACEHOLDERS: {
      NAME: 'ชื่อและนามสกุลภาษาอังกฤษตามหน้าบัตร',
      CARD_NO: 'หมายเลขหน้าบัตรเครติด 16 หลัก',
      EXPIRY: 'วันหมดอายุบัตรs MM-YYYY',
      CVC: 'CVC',
    },
    BUTTONS: ['ยกเลิก', 'ล้างข้อมูล', 'ยืนยัน'],
  },
  PAYMENT_BEING_VERIFIED: 'กำลังตรวจสอบการชำระเงิน',
  PAYMENT_VERIFIED: 'การชำระค่าบริการของท่านได้รับการยืนยันเรียบร้อยแล้ว',
  ORDER_BEING_SUBMITTED: 'กำลังดำเนินการยืนยันการทำรายการ',
  ORDER_SUBMITTED: 'การทำรายการได้รับการยืนยันเรียบร้อยแล้ว',
  DEVICE_CREDITCARD_FORM: {
    HEADER: 'Enter Your Credit Card Details',
    PLACEHOLDERS: {
      NAME: 'Name printed on Credit Card',
      CARD_NO: '16 digit credit card number',
      EXPIRY: 'วันหมดอายุบัตร MM-YYYY',
      CVC: 'CVC',
    },
    BUTTONS: ['Submit Payment'],
  },
  THANKS_FOR_CC_DETAILS:
    'ขอบคุณสำหรับข้อมูลบัตรเครดิต<br><br>กรุณารอสักครู่ระบบกำลังดำเนินการชำระค่าบริการ',
  THANKS_FOR_PAYMENT: `ขอบคุณ คุณ {Username} ขอเรียนแจ้งให้ทราบว่าการชำระค่าบริการการทำรายการสำเร็จเรียบร้อยแล้ว`,
  WE_WILL_DELIVER:
    'ขอแสดงความยินดีด้วยทางเราขอเรียนแจ้งให้ทราบว่า ทางเราจะนำส่งตัวเครื่องให้ท่านในวันนี้',
  WEB_SCHEDULE_FORM: {
    HEADER: 'เปลี่ยนแปลงวันและเวลาในการจัดส่ง',
    PLACEHOLDERS: {
      DATE: 'เลือกวันที่',
      SLOT: 'เลือกวันที่',
    },
    SLOT_OPTIONS: ['ตอนบ่าย (ภายใน 20.00 น.)', 'ตอนเย็น (ภายใน 17.00 น.)'],
    SLOT_OPTIONS_VALUES: ['6H', '14H', '17H'],
    BUTTONS: ['ยืนยัน'],
  },
  CONFIRM_DELIVERY_SCHEDULE: {
    HEADER: 'กรุณายืนยัน\nวันและเวลาในการจัดส่ง',
    SLOTS: ['ภายใน 20.00 น.', 'ภายใน 17.00 น.'],
    BUTTONS: ['เปลี่ยน\nวันจัดส่ง', 'ยืนยัน\nวันจัดส่ง', 'ยืนยัน\nวันจัดส่ง', 'ยกเลิก'],
    WBKO_BUTTONS: ['เปลี่ยนแปลง', 'ยืนยัน', 'ยืนยัน', 'ยกเลิก'],
  },

  SLOTS_MAPPING: [
    {
      RESP_VALUE: 'Same day Evening',
      LABEL: 'SLOT1',
      VALUE: '6H',
      DISPLAY_OPTION: 'ภายใน 17.00 น.',
    },
    {
      RESP_VALUE: 'Next Day Afternoon',
      LABEL: 'SLOT2',
      VALUE: '14H',
      DISPLAY_OPTION: 'ภายใน 20.00 น.',
    },
    {
      RESP_VALUE: 'Next Day Evening',
      LABEL: 'SLOT3',
      VALUE: '17H',
      DISPLAY_OPTION: 'ภายใน 17.00 น.',
    },
  ],
  DELIVERY_SLOT_MAPPING: [
    {
      RESP_VALUE: 'Next Day',
      LABEL: 'SLOT1',
      VALUE: '12H',
      START_TIME: '13:45PM',
      END_TIME: '23:59PM',
      DISPLAY_OPTION: '08:00 - 12:00',
    },
    {
      RESP_VALUE: 'Next Day',
      LABEL: 'SLOT2',
      VALUE: '18H',
      DISPLAY_OPTION: '20:00 - 18:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT3',
      VALUE: '12H',
      START_TIME: '00:00AM',
      END_TIME: '07:45AM',
      DISPLAY_OPTION: '08:00 - 12:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT4',
      VALUE: '4H',
      START_TIME: '07:45AM',
      END_TIME: '09:45AM',
      DISPLAY_OPTION: '10:00 - 20:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT5',
      VALUE: '4H',
      START_TIME: '09:45AM',
      END_TIME: '10:45AM',
      DISPLAY_OPTION: '11:00 - 15:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT6',
      VALUE: '4H',
      START_TIME: '10:45AM',
      END_TIME: '11:45AM',
      DISPLAY_OPTION: '12:00 - 16:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT7',
      VALUE: '4H',
      START_TIME: '11:45AM',
      END_TIME: '12:45PM',
      DISPLAY_OPTION: '13:00 - 17:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT8',
      VALUE: '4H',
      START_TIME: '12:45PM',
      END_TIME: '13:45PM',
      DISPLAY_OPTION: '20:00 - 18:00',
    },
  ],

  CONFIRM_SIM_CARD: {
    HEADER: 'Do You Need A SIM To Be Delivered Along With The Device?',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  CONFIRM_CHAT_SUPPORT: {
    HEADER: 'ต้องการสนทนากับเจ้าหน้าที่หรือไม่',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  SIM_REQUIRED: 'กรุณานำบัตรประชาชนติดต่อ AIS Shop ใกล้บ้านเพื่อรับซิมการ์ดใหม่',
  THANKS_FOR_ADDRESS: 'ขอบคุณสำหรับการยืนยันที่อยู่ โปรดรอสักครู่ระบบกำลังดำเนินการในขั้นตอนถัดไป',
  RETURN_DEVICE:
    'เมื่อผู้ใช้บริการขอใช้บริการเปลี่ยนเครื่องหรือรับเครื่องทดแทน ผู้ใช้บริการตกลงโอนกรรมสิทธิ์ในเครื่องลงทะเบียนเครื่องเดิมนั้นให้แก่ผู้ให้บริการทันทีที่ผู้ให้บริการยอมรับคำขอใช้บริการ และในกรณีหากพบตัวเครื่องที่ศูนย์หายกรุณาส่งตัวเครื่อง และ/หรือ ติดต่อกลับมาที่ AIS Mobile Care ทันที',
  CONFIRM_SR_BELOW: `กรุณาตรวจสอบคำขอบริการของท่านก่อนยืนยันการทำรายการในครั้งนี้`,
  CASH_ON_DELIVERY_SCRIPT: `กรุณาเตรียมเงินให้พอดี เนื่องจากเจ้าหน้าที่จัดส่งจะไม่ได้สำรองเงินทอนไปด้วย`,
  THANKS_FOR_CONTACTING: 'ขอบพระคุณที่ใช้บริการ AIS Mobile Care',
  CHECK_COSMETIC_DAMAGES:
    'ในการทำรายการครั้งนี้ถือว่าตัวเครื่องลงทะเบียนได้รับการโอนกรรมสิทธิ์ให้แก่ AIS Mobile Care ทันที <br><br>กรุณาทำการตรวจสอบคุณภาพตัวเครื่องก่อนเซ็นรับ และ AIS Mobile Care ทำการดูแลเครื่องต่อให้อีก 180 วัน ตามเงื่อนไขเดียวกับประกันศูนย์ ครอบคลุมถึงอุปกรณ์เสริม',
  REPLACEMENT_CHECK_COSMETIC_DAMAGES:
    'กรุณาทำการตรวจสอบคุณภาพตัวเครื่องก่อนเซ็นรับ และ AIS Mobile Care ทำการดูแลเครื่องต่อให้อีก 180 วัน ตามเงื่อนไขเดียวกับประกันศูนย์ ครอบคลุมถึงอุปกรณ์เสริม ',
  CONFIRM_DETAILS_SCRIPT: 'กรุณาตรวจสอบรายละเอียดทางด้านล่างก่อนยืนยันการทำรายการ',
  RETURN_LOST_DEVICE_IF_FOUND:
    'เมื่อผู้ใช้บริการขอใช้บริการเปลี่ยนเครื่องหรือรับเครื่องทดแทน ผู้ใช้บริการตกลงโอนกรรมสิทธิ์ในเครื่องลงทะเบียนเครื่องเดิมนั้นให้แก่ผู้ให้บริการทันทีที่ผู้ให้บริการยอมรับคำขอใช้บริการ และในกรณีหากพบตัวเครื่องที่สูญหายกรุณาติดต่อ AIS Mobile Care เพื่อส่งตัวเครื่องคืนทันที',
  SUBMIT_IMEI_NUMBER: {
    PRIMARY_HEADER: 'กรุณากรอกหมายเลขประจำตัวเครื่อง(IMEI) 6 ตัวสุดท้าย',
    SECONDARY_HEADER: 'วิธีการตรวจสอบหมายเลขประจำตัวเครื่อง (IMEI)',
    CONTENT:
      'ท่านสามารถตรวจสอบได้ง่าย ๆ โดยการกด *#06# <br> หรือดูที่ด้านหลังตัวเครื่อง,ถาดซิมสำหรับตัวเครื่องบางรุ่น รวมถึงสติกเกอร์ข้างกล่องและใบเสร็จรับเงิน/ใบกำกับภาษี\n หมายเลขประจำตัวเครื่อง (IMEI) จะมีด้วยกัน 15 หลักและขึ้นต้นด้วยเลข 3',
    PLACEHOLDER: 'ใส่หมายเลขประจำตัวเครื่อง 6 ตัวสุดท้าย ',
    BUTTON: 'ยืนยัน IMEI',
    INVALID_IMEI: 'กรุณาใส่หมายเลขประจำตัวเครื่อง 6 ตัวสุดท้าย',
    APPLE_NOTE: 'กรุณาใช้หมายเลขประจำตัวเครื่อง IMEI 1',
  },
  ALL_DIGIT_IMEI_PLACEHOLDER: 'ใสหมายเลขประจำตัวเครื่อง/IMEI 15หลัก',
  ERROR_INCORRECT_IMEI: 'หมายเลขประจำเครื่อง (IMEI) ไม่ถูกต้อง',
  CASE_NO: 'หมายเลขอ้างอิงคำขอใช้บริการ',
  CAPTURE_IW_REMARKS: {
    LABEL: 'กรุณากรอกอาการที่เกิดขึ้นกับตัวเครื่องของท่าน',
    PLACEHOLDER: 'กรุณาใส่คำอธิบายสั้น ๆ ถึงอาการที่เกิดขึ้นกับตัวเครื่อง',
    BUTTON: 'ยืนยัน',
  },
  CONFIRM_DETAILS: {
    HEADER: 'ยืนยันรายละเอียดคำขอบริการของท่าน',
    SR_NO: 'หมายเลขคำขอใช้บริการ',
    TYPE: 'ประเภทการทำรายการ',
    DEVICE: 'ตัวเครื่องที่จะได้รับ',
    SHIPPING_TO: 'สถานทีจัดส่ง',
    DELIVERY_SCHEDULE: 'วันที่ทำการจัดส่ง',
    PAID_WITH: 'ประเภทการชำระค่าบริการ',
    FEE: 'ค่าบริการสุทธิ',
    PIN_CODE: 'รหัสไปรษณีย์',
    BUTTON: 'ยืนยันการทำรายการ',
    CASH_ON_DELIVERY: 'ชำระด้วยเงินสด',
  },
  SR_CONFIRMATION: 'ขอบคุณสำหรับการยืนยันการทำรายการ <br>กรุณารอสักครู่ระบบกำลังดำเนินการทำรายการ',
  ASK_FOR_FEEDBACK: {
    HEADER: ' ความคิดเห็นของคุณสำคัญกับเราอย่างยิ่ง',
    CONTENT: 'AIS Mobile Care ขอความกรุณาท่านสละเวลาสักครู่เพื่อให้คะแนนการบริการของเรา',
    BUTTON: 'เริ่มให้คะแนน',
  },
  RESUME_SERVICE_REQUEST: {
    HEADER:
      'ขณะนี้ท่านมีคำขอใช้บริการที่ทำค้างไว้หมายเลข {Claim ID} ท่านต้องการทำต่อให้สำเร็จหรือไม่ ',
    SR_NO: 'หมายเลขคำขอใช้บริการ',
    TYPE: 'ประเภทการทำรายการ',
    DEVICE: 'ตัวเครื่อง',
    SHIPPING_TO: 'สถานทีจัดส่ง',
    DELIVERY_SCHEDULE: 'วันที่ทำการจัดส่ง',
    PAID_WITH: 'ประเภทการชำระค่าบริการ',
    FEE: 'ค่าบริการสุทธิ',
    PIN_CODE: 'รหัสไปรษณีย์',
    BUTTONS: ['เริ่มทำรายการใหม่', 'ทำรายการต่อ'],
  },
  NEW_SERVICE_REQ: 'เริ่มทำรายการใหม่',
  PROCEED_SERVICE_REQUEST: 'ยืนยันการทำรายการต่อ',
  CHOOSE_TO_CONTINUE_BOT: {
    HEADER: 'Choose An Option Below',
    BUTTONS: ['Have Query', 'Resume Flow'],
  },
  SURVEY_CONFIRMATION: {
    CONTENT1: 'กรุณาทำการประเมิณความพึงพอใจในการใช้บริการ Online Support ในครั้งนี้',
    CONTENT2: 'Would you like to take part in 1 minute survey?',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  SURVEY_QUESTION_RATING: {
    BUTTON: 'ดำเนินการต่อ',
  },
  SURVEY_QUESTION_COMMENTS: {
    BUTTON: 'ดำเนินการต่อ',
  },
  SURVEY_QUESTION_EXPERIENCE: {
    BUTTON: 'ยืนยัน',
  },
  ADDRESS_LABELS: {
    ADDRESS1: 'ที่อยู่บรรทัดที่1:',
    ADDRESS2: 'ที่อยู่บรรทัดที่2:',
    ADDRESS3: 'ที่อยู่บรรทัดที่3:',
    POSTALCODE: 'รหัสไปรษณีย์:',
    CITY: 'เขต/อำเภอ:',
    STATE: 'จังหวัด:',
  },
  WEB_CHANGE_DELIVERY_ADDRESS: {
    HEADER: 'โปรดระบุที่อยู่ในการจัดส่งที่ท่านต้องการ',
    HEADER1: 'กรุณากรอกที่อยู่ที่ต้องการให้ AIS Mobile Care ทำการจัดส่งตัวเครื่อง',
    WBKO_HEADER: 'กรุณาใส่ที่อยู่ในการจัดส่งที่ต้องการ',
    LABELS: {
      ADDRESS1: 'ที่อยู่บรรทัดที่1',
      ADDRESS2: 'ที่อยู่บรรทัดที่2',
      ADDRESS3: 'ที่อยู่บรรทัดที่3',
      POSTALCODE: 'รหัสไปรษณีย์',
      CITY: 'เขต/อำเภอ',
      STATE: 'จังหวัด',
    },
    PLACEHOLDERS: {
      FLOOR: 'ชั้นที่ บ้านเลขที่',
      BLOCK_NO: 'หมายเลขห้อง',
      STREET_NAME: 'ถนน',
      ZIP_CODE: 'รหัสไปรษณีย์',
      CITY: 'City/Suburbs',
      STATE: 'State',
      STATE_PLACEHOLDER: 'เลือกจังหวัด',
      CITY_PLACEHOLDER: 'เลือกอำเภอ',
    },
    WBKOPLACEHOLDERS: {
      FLOOR: 'ชื่อบริษัท, คอนโด, หมู่บ้าน, บ้านเลขที่, ชั้นที่',
      BLOCK_NO: 'หมู่ที่, ถนน, ซอย',
      STREET_NAME: 'แขวง/ตำบล',
      ZIP_CODE: ' รหัสไปรษณีย์',
      CITY: ' เขต/อำเภอ',
      STATE: 'จังหวัด',
      STATE_PLACEHOLDER: 'Select Province',
      CITY_PLACEHOLDER: 'Select District',
    },
    BUTTONS: ['ลบทั้งหมด', 'ยืนยันที่อยู่', 'ยกเลิก'],
    WBKO_BUTTONS: ['ลบข้อมูลทั้งหมด', 'ยืนยัน', 'ยกเลิก'],
    DEFAULT_CITY: 'Thailand',
    ERROR_MSG: 'กรุณากรอกข้อมูลให้ครบทุกช่อง',
  },
  DEVICE_CHANGE_DELIVERY_ADDRESS: {
    HEADER: 'โปรดระบุที่อยู่ในการจัดส่งที่ท่านต้องการ',
    PLACEHOLDERS: {
      FLOOR: 'ชั้นที่ บ้านเลขที่',
      BLOCK_NO: 'หมายเลขห้อง',
      STREET_NAME: 'ถนน',
      ZIP_CODE: 'รหัสไปรษณีย์',
    },
    DEFAULT_CITY: 'Bangkok',
    BUTTONS: ['ยืนยันที่อยู่การจัดส่งที่ต้องการเปลี่ยนแปลง'],
  },
  PLEASE_SELECT_ALT_DEVICE: 'กรุณาเลือกตัวเครื่องที่ท่านต้องการ',
  CHECK_ALTERNATIVE_DEVICES: {
    HEADER: 'กรุณาเลือก',
    CONTENT1:
      'ต้องการรอเครื่องนานสูงสุด 5-7 วัน หรือเลือกรุ่นที่ได้รับความนิยมอื่นๆ ที่พร้อมจัดส่งให้คุณทันทีถึงหน้าบ้าน',
    CONTENT2: 'ต้องการรอ <strong>5-7 วัน </strong>',
    BUTTONS: ['รุ่นที่ได้รับความนิยมอื่นๆ พร้อมจัดส่ง', 'ต้องการรอตัวเครื่อง'],
  },
  ALTERNATE_DEVICE_NOTE: {
    CONTENT:
      'เราขอเรียนให้ท่านทราบว่าหากท่านเลือกรับเครื่องที่มีคุณสมบัติใกล้เคียงในครั้งนี้ ในการทำรายการครั้งต่อไปท่านจะได้เครื่องรุ่นเดิม สีเดิม สเปคเดิม',
    BUTTONS: ['ยกเลิก', 'ยืนยันการทำรายการต่อ'],
  },
  FEEDBACK: {
    QUESTIONS: [
      ' จากการทำรายการในครั้งนี้ท่านจะทำการแนะนำบริการ AIS Mobile Care ให้กับเพื่อนหรือเพื่อนร่วมงานหรือไม่',
      'กรุณาแชร์ถึงสิ่งที่คุณให้ความสำคัญมากที่สุดและมีผลต่อการให้คะแนนในครั้งนี้',
      'กรุณาประเมินความพึงพอในการทำรายการกับ AIS Mobile Care ในครั้งนี้',
    ],
    NPS_MAX: 10,
    NPS_MIN: 1,
    NPS_STEP: 1,
    NPS_DEFAULT_SELECTED: 10,
    BUTTONS: ['เรียบร้อย'],
    NPS_LABEL: {
      LIKELY: 'พึงพอใจปานกลาง',
      NOT_LIKELYNOT_LIKELY: 'ไม่พึงพอใจ',
      EXTREMELY_LIKELY: 'พึงพอใจมาก',
    },
    CSAT: {
      POOR: { TEXT: 'แย่มาก', VALUE: 1 },
      SAD: { TEXT: 'แย่', VALUE: 2 },
      NEUTRAL: { TEXT: 'เฉยๆ', VALUE: 3 },
      HAPPY: { TEXT: 'ดี', VALUE: 4 },
      EXCELLENT: { TEXT: 'ดีมาก', VALUE: 5 },
    },
    EXTREMELY_LIKELY_VALUE: 8,
    LIKELY_VALUE: 7,
  },
  INSTRUCTION_PROVIDE_MOB_NO:
    'กรุณาใส่หมายเลขโทรศัพท์เพื่อแจ้งสถานะการทำรายการของคุณหลังจากทำรายการเรียบร้อยแล้ว',
  SUBMIT_CONTACT_NUMBER: {
    HEADER: 'หมายเลขที่ต้องการให้ติดต่อกลับ',
    PLACEHOLDER: 'กรุณาใส่หมายเลขโทรศัพท์10หลัก',
    BUTTONS: ['ยืนยัน'],
  },
  INSTRUCTION_PROVIDE_EMAIL:
    'กรุณาใส่อีเมลเพื่อแจ้งสถานะการทำรายการของคุณหลังจากทำรายการเรียบร้อยแล้ว',
  SUBMIT_EMAIL: {
    HEADER: 'อีเมลที่ต้องการให้ส่ง\nข้อมูลการทำรายการ',
    PLACEHOLDER: 'กรุณาใส่อีเมลของท่าน',
    BUTTONS: ['ยืนยัน'],
  },
  WEB_GRID_VIEW_OPTIONS: {
    title: 'กรุณาเลือกการทำรายการที่ต้องการ',
    PLACEHOLDERS: {
      TRACK: 'เช็คสถานะการจัดส่ง',
      RESCHEDULE: 'เปลี่ยนแปลงวันจัดส่ง', //"เปลี่ยนแปลงวันและการจัดส่ง",
      ADDRESS_CHANGE: 'เปลี่ยนแปลงที่อยู่ในการจัดส่ง', //"เปลี่ยนแปลงที่อยู่การจัดส่ง",
      ENQUIRY: 'ยกเลิกการจัดส่งและการทำรายการ', //"ยกเลิกการทำรายการ",
      UPDATE_CONTACT_DETAILS: 'เปลี่ยนแปลงชื่อและเบอร์ผู้รับ', //"เปลี่ยนแปลงข้อมูลผู้รับและเบอร์ติดต่อ"
    },
  },
  WBKO_WEB_GRID_VIEW_OPTIONS: {
    title: 'กรุณาเลือกการทำรายการที่ต้องการ',
    PLACEHOLDERS: {
      TRACK: 'เช็คสถานะการจัดส่ง',
      RESCHEDULE: 'เปลี่ยนแปลงวันจัดส่ง', //"เปลี่ยนแปลงวันและการจัดส่ง",
      ADDRESS_CHANGE: 'เปลี่ยนแปลงที่อยู่ในการจัดส่ง',
      ENQUIRY: 'ยกเลิกการจัดส่งและการทำรายการ',
      UPDATE_CONTACT_DETAILS: 'เปลี่ยนแปลงชื่อและเบอร์ผู้รับ', //"เปลี่ยนแปลงข้อมูลผู้รับและเบอร์ติดต่อ"
    },
  },
  WEB_TRACK_MY_DELIVERY: {
    HEADER: 'ติดตามการส่งมอบของคุณ',
    PLACEHOLDERS: {
      CONFIRMED: 'ยืนยันคำขอรับบริการ',
      PROCESSING: 'กำลังจัดเตรียมตัวเครื่อง',
      QUALITY_CHECK: 'กำลังตรวจสอบคุณภาพตัวเครื่อง',
      DISPATCHED: 'กำลังจัดส่งตัวเครื่อง',
      DELIVERED: 'จัดส่งตัวเครื่องเรียบร้อยแล้ว',
    },
  },
  ASK_FOR_ENQUIRY: {
    HEADER: 'มีข้อมูลส่วนใดสอบถามเพิ่มเติมหรือไม่',
    BUTTONS: ['ไม่มี', 'มี'],
  },
  SERVICE_UNAVAILABLE: {
    PRIMARY_HEADER: '503 บริการไม่พร้อมใช้งาน',
    CONTENT: 'ขออภัยในความไม่สะดวกเนื่องจากทาง AIS Mobile Care ',
    CONTENT1: 'ไม่สามารถทำรายการต่อได้เนืองจากความผิดพลาดทางเทคนิค',
    SECONDARY_HEADER: 'ดูข้อมูลเพิ่มเติมเกี่ยวกับบริการ AIS Mobile Care ได้ที่',
    CONTINUED_HEADER: '',
    CONTENT3: 'ขอบพระคุณที่เลือกใช้บริการ AIS Mobile Care',
  },
  SHOW_PAYMENTFEE_WITH_DISCOUNT: {
    HEADER: 'กรุณาใช้บัตรเครดิตของผู้ลงทะเบียนในการดำเนินการชำระค่าบริการการทำรายการในครั้งนี้',
    CONTENT:
      'ค่าบริการสุทธิในการทำรายการครั้งนี้คือ <strong> {TotalAmount} บาท\nรวมภาษีมูลค่าเพิ่มเรียบร้อยแล้ว<strong>',
    BUTTON: 'ทำรายการต่อไป',
  },
  AGREE_WITH_TERMS: 'สำคัญมาก',
  DOWNLOAD_FILE: 'กรุณาดาวน์โหลดเอกสารกรอกเอกสารให้ครบถ้วนและทำการอัพโหลด',
  INVALID_CC_DETAILS:
    'ระบบไม่สามารถทำรายการจากข้อมูลบัตรเครดิตที่ท่านให้ได้ <br><br> กรุณาใส่ข้อมูลบัตรเครดิตที่ถูกต้องอีกครั้ง',
  INVALID_CC_DETAILS_SECOND_ATTEMPT:
    'ระบบยังคงไม่สามารถทำรายการจากข้อมูลบัตรเครดิตที่ท่านให้ได้ กรุณาใส่ข้อมูลบัตรเครดิตที่ถูกต้องอีกครั้ง',
  EXCEEDED_CC_VALIDTION_ATTEMPTS:
    'เนื่องจากท่านได้ทำการกรอกข้อมูลบัตรเครดิตของท่านเกินกำหนดแล้ว กรุณาทำรายการอีกครั้งในภายหลัง',
  INVALID_ADDRESS_ERROR:
    'We regret to inform that the provided address is invalid based on our address verification. <br><br> So please make sure to provide the correct *#Floor-Unit* No, *Block No*, *Street Name* and *Postal Code* again.',
  INVALID_ADDRESS_ERROR_SECOND_ATTEMPT:
    'Again, We regret to inform that the provided address is invalid based on our address verification. <br><br> So please make sure to provide the correct *#Floor-Unit* No, *Block No*, *Street Name* and *Postal Code* again.',
  STAGE_NAME: {
    ENQUIRY: {
      request: {
        label: 'คำขอใช้บริการ', // 5%
        percentage: 1,
      },
      peril: {
        label: 'คำขอใช้บริการ', // 5%
        percentage: 3,
      },
    },
    VERIFICATION: {
      mdn: {
        label: 'ขั้นตอนตรวจสอบสิทธิ์ในการใช้บริการ', // 10%
        percentage: 5,
      },
      name: {
        label: 'ขั้นตอนตรวจสอบสิทธิ์ในการใช้บริการ', // 10%
        percentage: 8,
      },
      nric: {
        label: 'ขั้นตอนตรวจสอบสิทธิ์ในการใช้บริการ', // 10%
        percentage: 10,
      },
    },
    DETERMINE_INCIDENT: {
      advance_peril: {
        label: ' ขั้นตอนการหาสาเหตุ', // 30%
        percentage: 10,
      },
    },
    OFFER: {
      confirm_enrolled_device: {
        label: 'ขั้นตอนยืนยันตัวเครื่องลงทะเบียน', // 30%
        percentage: 15,
      },
      eligibility: {
        label: 'สิทธิ์และการนำเสนอตัวเครื่อง', // 30%
        percentage: 30,
      },
    },
    FULLFILLMENT: {
      label: 'หน้าสต๊อกสินค้า', // 50%
      percentage: 50,
    },
    PAYMENT: {
      label: 'ขั้นตอนการชำระเงิน', // 70%
      percentage: 70,
    },
    SHIPPING: {
      label: 'หน้าการจัดส่ง', // 90%
      percentage: 90,
    },
    ORDER_REVIEW: {
      label: 'หน้าสรุปรายการ', // 98%
      percentage: 98,
    },
    JOURNEY_ENDED: {
      label: 'สิ้นสุดกระบวนการ', // 100%
      percentage: 100,
    },
    GENERAL_ENQUIRY: {
      label: 'สอบถามข้อมูลทั่วไป', // 100%
      percentage: 50,
    },
  },
  WEB_HEADERS: {
    WELCOME_TO_MOBILESWOP: 'ยินดีต้อนรับสู่บริการ AIS Mobile Care',
    FEEDBACK: 'Rate Our Services',
  },
  SUB_STAGES: {
    WELCOME: 'ยินดีต้อนรับสู่บริการ AIS Mobile Care',
    MDN_PROVIDED: 'กรอกหมายเลขลงทะเบียนเรียบร้อยแล้ว',
    NRIC_PROVIDED: 'ได้',
    CONFIRM_ENROLLED_DEVICE: 'ยืนยันตัวเครื่องลงทะเบียน',
    DEVICE_CONFIRMED: 'ยืนยันตัวเครื่องลงทะเบียนสำเร็จ',
    SERVICE_REQUEST_FEE_DISPLAYED: 'แสดงค่าบริการ',
    CHECK_DEVICE_STOCK: 'ยืนยันพบตัวเครื่องในสต๊อก',
    DEVICE_NOT_IN_STOCK: 'ตัวเครื่องที่เลือกไม่มีในสต๊อก',
    DISPLAY_ALTERNATE_DEVICES: 'แสดงตัวเครื่องที่มีคุณสมบัติใกล้เคียง',
    VERIFY_ENROLLED_NUMBER_FOR_SMS: 'ตรวจสอบหมายเลขโทรศัทพ์สำหรับการส่งข้อความ',
    MOBILE_NO_VERIFIED: 'ยืนยันหมายเลขโทรศัพท์ในการติดต่อเป็นหมายเลขลงทะเบียน',
    VERIFY_ENROLLED_EMAIL: 'ยืนยันอีเมลตามข้อมูลในระบบ',
    EMAIL_VERIFIED: 'ยืนยันอีเมลสำหรับแจ้งสถานะการทำรายการ',
    DOCUMENT_DOWNLOAD: 'ดาวน์โหลด คำขอ - การขอรับบริการครื่องทดแทน',
    DOCUMENT_UPLOAD: 'ยืนยันการอัพโหลดเอกสารสำเร็จ',
    EMAIL_SUBMITTED: 'ยืนยันอีเมลใหม่สำเร็จ',
    SIM_DELIVERY_CONFIRMATION: 'Sim delivery confirmation',
    SIM_DELIVERY_NEEDED: 'Sim delivery needed',
    SIM_DELIVERY_NOT_NEEDED: 'Sim delivery not needed',
    ENROLLED_ADDRESS_CONFIRMATION: 'การยืนยันที่อยู่การจัดส่ง',
    ENROLLED_ADDRESS_CONFIRMED: 'ยืนยันการจัดส่งไปยังที่อยู่ที่ใช้ในการลงทะเบียน/อยู่ในระบบ',
    PROVIDE_NEW_ADDRESS: 'เปลี่ยนแปลงที่อยู่ในการจัดส่งสินค้า',
    CASH_ON_DEL: 'เลือกการชำระเงินแบบจ่ายเงินสดกับผู้จัดส่ง',
    CREDIT_CARD_SEL: 'เลือกการชำระเงินผ่านบัตรเครดิต',
    CONFIRM_DELIVERY_SCHEDULE: 'การยืนยันวันเวลาในการจัดส่ง',
    DELIVERY_SCHEDULE_CONFIRMED: 'ยืนยันวันเวลาในการจัดส่ง',
    NEW_DELIVERY_SCHEDULE: 'กรอกข้อมูลวันเวลาในการจัดส่งที่ต้องการเปลี่ยนแปลง',
    NEW_SCHEDULE_SUBMITTED: 'ยืนยันวันเวลาในการจัดส่งใหม่คือ',
    PAYMENT_FEE_DISPLAYED: 'แสดงค่าบริการการทำรายการ',
    PAYMENT_MODE: 'ประเภทการชำระค่าบริการ',
    ENTER_CC_DETAILS: 'กรอกข้อมูลบัตรเครดิต',
    ORDER_CONFIRMATION: 'ยืนยันหน้าสรุปข้อมูลการทำรายการ',
    ORDER_CONFIRMED: 'ยืนยันการทำรายการ',
    SURVEY: 'หน้าประเมิณความพึงพอใจ',
    NEW_CONTACT: 'เปลี่ยนแปลงหมายเลขโทรศัพท์ในการติดต่อ',
    NEW_EMAIL: 'เปลี่ยนแปลงอีเมล์ในการส่งสถานะการทำรายการ',
    VALIDATE_IMEI: 'แจ้งหมายเลขประจำตัวเครื่อง',
    FMIP: `ขั้นตอนการปิด "ค้นหา iPhone ของฉันหรือFind my iPhone"`,
    TRIAGE_QUESTION: 'ขั้นตอนในการแก้ปัญหาตัวเครื่องเบื้องต้น',
    TRY_TURN_OFF: 'ลองทำการรีสตาร์ทตัวเครื่อง',
    UPDATE_OS: 'ลองอัพเดทระบบปฏิบัติการ',
    RESET_FACTORY_DEFAULT: 'ลองทำการตั้งค่าข้อมูลจากโรงงาน',
    TRIAGE_RESOLVED: 'ปัญหาได้รับการแก้ไขแล้ว',
    TURN_OFF_DIDNT_WORK: 'รีสตาร์ทเครื่องแล้วแต่ปัญหายังไม่ได้รับการแก้ไข',
    UPDATE_OS_DIDNT_WORK: 'อัพเดทระบบปฏิบัติการแล้วปัญหายังไม่ได้รับการแก้ไข',
    RESET_DIDNT_WORK: 'ท่านได้ลองทำการตั้งค่าข้อมูลจากโรงงานแล้วหรือไม่',
    VERIFIED_IMEI: 'ยืนยัน IMEI แล้ว',
    DEVICE_STILL_IN_WARRANTY: 'ตัวเครื่องของท่านยังอยู่ในระยะเวลาการรับประกันกับแบรนด์ผู้ผลิต',
    WARRANTY_VOID: 'การรับประกันของผู้ผลิตที่เลื',
  },
  TRY_LATER: 'Our support staff is not available. Please try after some time.',
  EPISODIC_FEEDBACK: {
    HEADER_TEXT: 'เริ่มให้คะแนน',
    QUESTIONS: [
      {
        QID: 'Q1',
        QUESTION_TXT:
          '1. Based on your experience today, how likely are you to recommend AIS Mobile Care to a friend or colleague?',
      },
      {
        QID: 'Q2',
        QUESTION_TXT:
          '2. Please rate your satisfaction with the length of time it took to receive your device replacement?',
      },
      {
        QID: 'Q3',
        QUESTION_TXT:
          '3. Please rate your satisfaction with the quality of the device replacement.',
      },
      {
        QID: 'Q4',
        QUESTION_TXT: '4. Please rate your satisfaction with the Courier service.',
      },
      {
        QID: 'Q5',
        QUESTION_TXT: '5. Overall, how easy was it to get your replacement device?',
      },
      {
        QID: 'Q6',
        QUESTION_TXT: '6. Do you have any other comments you would like to share?',
      },
    ],
    NPS_MAX: 10,
    NPS_MIN: 0,
    NPS_STEP: 1,
    NPS_DEFAULT_SELECTED: 8,
    BUTTONS: ['เรียบร้อย'],
    NPS_LABEL: {
      LIKELY: 'LIKELY',
      NOT_LIKELY: 'NOT AT ALL LIKELY',
      EXTREMELY_LIKELY: 'EXTREMELY LIKELY',
    },
    CSAT: {
      POOR: 'Poor',
      SAD: 'Sad',
      NEUTRAL: 'Neutral',
      HAPPY: 'Happy',
      EXCELLENT: 'Excellent',
    },
    EXTREMELY_LIKELY_VALUE: 8,
  },
  NOT_RESOLVED: {
    MDN_NAME_NRIC:
      'ระบบไม่สามารถดำเนินการตามคำขอของท่านได้ เจ้าหน้าที่จะติดต่อท่านกลับเมื่อข้อมูล การลงทะเบียนของหมายเลขนี้ได้รับการแก้ไขเรียบร้อยแล้ว <br>ขอบพระคุณที่ติดต่อ AIS Mobile Care และขออภัยในความไม่สะดวกมา ณ ที่นี้',
    GENERIC:
      'ขออภัยในความไม่สะดวกเนื่องจากทาง AIS Mobile Care ยังไม่สามารถทำรายการได้ ขอบพระคุณที่เลือกใช้บริการ  AIS Mobile Care',
  },
  // USER_DISCONNECTED: "We need to end this chat due to inactivity. Thank you for connecting with us.",
  USER_DISCONNECTED: {
    HEADER: 'ไม่มีการตอบรับ',
    CONTENT:
      'ระบบจะทำการปิดหน้าต่างนี้อัตโนมัติเนื่องจากไม่มีการตอบกลับเกินระยะเวลา 10 นาที \n\n ขอบพระคุณที่ใช้บริการ AIS Mobile Care',
  },
  // AGENT_DISCONNECTED: "We need to end this chat due to some technical issues. Thank you for connecting with us.",
  AGENT_DISCONNECTED: {
    PRIMARY_HEADER: 'เหตุขัดข้องทางเทคนิค',
    SECONDARY_HEADER: 'ขออภัยในความไม่สะดวกเนื่องจากเหตุขัดข้องทางเทคนิคทำให้การสนทนาไม่ต่อเนื่อง',
    PRIMARY_CONTENT:
      'หากต้องการความช่วยเหลือเพิ่มเติม ท่านสามารถติดต่อเราได้ที่ {0980981175} ระหว่างเวลา 8.00-22.00 น. ทุกวันไม่เว้นวันหยุดราชการ',
    SECONDARY_CONTENT: 'ขอบพระคุณที่ใช้บริการ AIS Mobile Care',
    BUTTONS: ['ยกเลิก', 'ดำเนิน'],
  },
  CURRENT_REQUEST_DETAILS: {
    HEADER: 'รายละเอียดการจัดส่งตัวเครื่องมีดังต่อไปนี้ ',
    SR_NO: 'หมายเลขการทำรายการ',
    TYPE: 'ประเภทการทำรายการ',
    DEVICE: 'ตัวเครื่องที่จะได้รับ',
    SHIPPING_TO: 'สถานทีจัดส่ง',
    DELIVERY_SCHEDULE: 'วันที่ทำการจัดส่ง',
    PAID_WITH: 'ค่าบริการในการทำรายการ',
    FEE: 'ค่าบริการสุทธิ',
    PIN_CODE: 'รหัสไปรษณีย์',
    BUTTONS: [],
  },
  CANCEL_REQUEST: {
    HEADER: 'ยกเลิกการทำรายการปัจจุบัน',
    CONTENT: 'กรุณายืนยันการยกเลิกการทำรายการในครั้งนี้',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  CANCEL_CURRENT_SR: {
    HEADER: 'ยกเลิกคำขอใช้บริการ',
    CONTENT: 'ท่านต้องการยกเลิกคำขอใช้บริการนี้ใช่หรือไม่',
    BUTTONS: ['ไม่ใช่', 'ใช่'],
  },
  NO_DEVICE_IN_STOCK: 'No Device available in stock.',
  UPLOAD_YOUR_SUPPORTING_DOCS: {
    HEADER: 'Upload Your Supporting Documents',
    SELECT_FROM_PHONE: 'Select file from your phone',
    SELECT_FROM_COMPUTER: 'Select file from your computer',
    BUTTONS: ['คลิกเพื่ออัพโหลดเอกสาร'],
  },
  UPLOAD_DOCUMENTS: {
    HEADER: 'กรุณาทำการอัพโหลดเอกสารที่กรอกโดยสมบูรณ์แล้วเพื่อทำรายการต่อ',
    CONTENT: "วางไฟล์ที่นี่ หรือคลิก <a href=''> ค้นหา </a>",
    ATTACHED_CONTENT: '',
    DISCLAIMER:
      'หมายเหตุ: รองรับได้มากสุด 3 ไฟล์ และรูปแบบไฟล์ที่รองรับ pdf jpg jpeg png<br>ที่มีขนาดสูงสุด ไม่เกิน 1 MB',
    // ALLOWED_EXT: "เมกะไบต์ ประเภทไฟล์ที่รองรับ PDF PNG JPG JPEG ",
    BUTTONS: ['ยกเลิก', 'คลิกเพื่ออัพโหลดเอกสาร'],
  },

  MAX_SIZE_ALERT: {
    ALERT_HEADER: 'แจ้งเตือน',
    ALERT_MESSAGE: 'รูปแบบไฟล์ที่รองรับ pdf jpg jpeg png ที่มีขนาดสูงสุด ไม่เกิน 1 MB',
    BUTTON: 'ตกลง',
  },
  DOWNLOAD_ALERT: 'ต้องการดาวน์โหลดเอกสาร คำขอ - การขอรับบริการครื่องทดแทน หรือไม่',
  NO_ALTERNATE_DEVICE_IN_STOCK: 'No alternate device available in stock.',
  DEVICE_NOT_ELIGIBLE: {
    CONTENT:
      'ขออภัยค่ะ ระบบไม่พบข้อมูลการสมัครหรือสถานะบริการ AIS Mobile Care โดนระงับชั่วคราว กรุณาตรวจสอบข้อมูลบริการอีกครั้งในวันถัดไปหากการสมัครบริการหรือการชำระค่าบริการล่าสุดน้อยกว่า 24 ชั่วโมง',
    BUTTONS: ['ปิด'],
  },
  DOWNLOAD_CONFIRMATION_FORM: {
    HEADER: `ขั้นตอนในการดาวน์โหลด  "คำขอ - การขอรับบริการครื่องทดแทน"`,
    INSTRUCTION_1: 'ทำการ <strong>Print</strong> เอกสารฉบับนี้ โดยคลิกที่ปุ่ม ดาวน์โหลดแบบฟอร์ม',
    INSTRUCTION_2: 'ใส่ข้อมูลข้อมูลทุก ๆ ช่องด้วยปากกาสีน้ำเงินเท่านั้น',
    INSTRUCTION_3:
      'กรอก <strong>ยี่ห้อและรุ่น</strong> (เช่น ยี่ห้อโทรศัพท์ รุ่น สี และ ความจุ ) หมายเลขเครื่อง (IMEI) และหมายเลขโทรศัพท์ที่ทำการลงทะเบียนไว้กับ AIS Mobile Care',
    INSTRUCTION_4:
      'เซ็นเอกสารให้ครบทุกช่อง โดยทางเราขออนุญาตสงวนสิทธิ์ในการปฎิเสธเอกสารที่ลงชื่อด้วยลายเซ็นอิเล็กทรอนิกส์',
    INSTRUCTION_5:
      'อัพโหลดเอกสารที่กรอกอย่างสมบูรณ์แล้วในรูปแบบไฟล์ <strong> PDF PNG JPG JPEG <strong> เท่านั้น',
    BUTTONS: ['ข้าม', 'ดาวน์โหลดแบบฟอร์ม'],
    CAVEAT: 'กรุณาเลือก <strong>ข้าม</strong> หากท่านได้ทำการดาวน์โหลดแบบฟอร์มเรียบร้อยแล้ว',
  },
  DOWNLOAD_SCRIPT: `อ้างอิงจากข้อมูลที่ท่านแจ้งมานั้น ทางเราขอให้ท่านทำการกรอกแบบฟอร์ม\n "คำขอ - การขอรับบริการครื่องทดแทน" ก่อนที่เราจะสามารถทำรายการในครั้งนี้ได้ <br><br>ในการทำรายการครั้งนี้ เราถือว่าลูกค้าตกลงโอนกรรมสิทธิ์ในเครื่องลงทะเบียนเครื่องเดิมนั้นให้แก่ AIS Mobile Care ทันที และเราไม่อนุญาตให้มีการใช้ ซื้อขาย ส่งต่อ หรือทำรายการใด ๆ กับตัวเครื่องนี้ และหากพบเครื่องลงทะเบียนกรุณาติดต่อกลับ AIS Mobile Care เพื่อส่งคืนเครื่องลงทะเบียน <br><br>หลังจากท่านได้ทำการอัพโหลด "คำขอ - การขอรับบริการครื่องทดแทน" เรียบร้อยแล้ว เราจะถือว่าท่านรับทราบและยอมรับเงื่อนไขการขอใช้บริการในครั้งนี้`,
  CONVERSATION_END: {
    THANK_YOU: 'ขอบพระคุณที่เลือกใช้บริการ AIS Mobile Care',
    CLOSE: 'X Close window',
  },
  CANCEL_ENQUIRY: {
    HEADER: 'ท่านต้องการยกเลิกการจัดส่งและ \n การทำรายการในครั้งนี้ใช่หรือไม่',
    BUTTONS: ['ยืนยันการยกเลิก', 'ยืนยัน'],
  },
  COD_FOR_CREDIT_CARD: {
    CONTENT:
      'ขออภัยในความไม่สะดวก \n บัตรเครดิตของท่านไม่สามารถทำรายการได้กรุณาติดต่อธนาคารเจ้าของบัตร \n ท่านต้องการเลือกการชำระเงินเป็นแบบเก็บเงินปลายทางหรือไม่',
    BUTTONS: ['ยกเลิก', 'ยืนยันการทำรายการต่อ'],
  },
  // ALTERNATE_DEVICE_NOTE: {
  //     CONTENT: "Please note that once you have accepted the comparable model offered, you will not be able to request for the enrolled model for future service request",
  //     BUTTONS: ["Proceed"]
  // },
  CLOSE_SR: {
    CONTENT:
      'ระบบได้รับรายการของท่านเรียบร้อยแล้วและอยู่ในขั้นตอนการพิจารณา โดยเราจะยังคงมิได้ดำเนินการเรียกเก็บค่าบริการจนกว่ารายการของท่านจะได้รับการอนุมัติ ซึ่งท่านจะได้รับการแจ้งเตือนผ่านทางข้อความ และ/หรือ ทางอีเมลหลังรายการของท่านได้รับการอนุมัติเรียบร้อยแล้ว',
    BUTTONS: ['ปิด'],
  },
  NO_ALTERNATIVE_DEV_INSTOCK: {
    CONTENT: 'No alternate device available in stock',
    BUTTONS: ['ปิด'],
  },
  GENERIC_NOT_RESOLVED: {
    CONTENT:
      'ขออภัยในความไม่สะดวกเนื่องจากทาง AIS Mobile Care ยังไม่สามารถทำรายการได้ ขอบพระคุณที่เลือกใช้บริการ  AIS Mobile Care',
    BUTTONS: ['ปิด'],
  },
  MDN_NRIC_CLOSE: {
    CONTENT:
      'ระบบไม่สามารถดำเนินการตามคำขอของท่านต่อไปได้ และเจ้าหน้าที่จะติดต่อท่านกลับเมื่อข้อมูลการลงทะเบียนของหมายเลขนี้ได้รับการแก้ไขเรียบร้อยแล้ว <br> <br>ขอบพระคุณที่ติดต่อ AIS Mobile Care และขออภัยในความไม่สะดวกมา ณ ที่นี้',
    BUTTONS: ['ปิด'],
  },
  CLOSE_JOURNEY_AFTER_ADDRESS_FAILURE:
    "ขออภัยที่ต้องเรียนให้ท่านทราบว่าเราไม่สามารถดำเนินการต่อได้เนื่องจากข้อมูลที่อยู่ไม่ถูกต้อง<br><br>กรุณาติดต่อ AIS Mobile Care ที่หมายเลข <strong><a class='ais_support-hotline' href='tel://0980981175'>0980981175</a></strong> ในเวลา 8.00-22.00 น. ทุกวัน",
  RESCHEDULE_DELIVERY_ENQUIRY:
    'ทาง AIS Mobile Care ได้ทำการเปลี่ยนแปลงวันและเวลาในการจัดส่งครั้งนี้เรียบร้อยแล้ว ทางเราจะแจ้งให้ทราบอีกครั้งเมื่อการเปลี่ยนแปลงวันและเวลาในการจัดส่งสำเร็จเรียบร้อยแล้ว',
  RESCHEDULE_DELIVERY_ADDRESS_ENQUIRY:
    'ทาง AIS Mobile Care ได้ทำการเปลี่ยนแปลงที่อยู่ในการทำรายการครั้งนี้เรียบร้อยแล้ว ทางเราจะแจ้งให้ทราบอีกครั้งเมื่อการเปลี่ยนแปลงที่อยู่สำเร็จเรียบร้อยแล้ว',
  CANCEL_MY_DELIVERY_ENQUIRY:
    'ทาง AIS Mobile Care ได้ส่งเรื่องยกเลิกการทำรายการในครั้งนี้เรียบร้อยแล้ว ทางเราจะแจ้งให้ทราบอีกครั้งเมื่อการยกเลิกเสร็จสมบูรณ์',
  CANCEL_MY_DELIVERY_ENQUIRY_THANKS: 'ขอบพระคุณที่เลือกใช้บริการ AIS Mobile Care',
  CHANGE_DELIVERY_ADDRESS_ENQUIRY:
    'ลูกค้าต้องการทำการเปลี่ยนแปลงที่อยู่การจัดส่งโดยที่อยู่การจัดส่งใหม่คือ:',
  CREATE_GENERAL_ENQUIRY:
    'ลูกค้าสอบถามข้อมูลทั่วไปเมื่อ {DateTimeOfRequest}, หมายเลขที่ต้องการให้ติดต่อกลับคือ: {MobileNumber}',
  GENERAL_ENQUIRY_SUBMITTED:
    'ทาง AIS Mobile Care จะทำการติดต่อกลับที่หมายเลขลงทะเบียนของท่าน ขอบพระคุณที่เลือกใช้บริการ AIS Mobile Care!',
  CHANGE_DELIVERY_SCHEDULE_ENQUIRY: 'ลูกค้าต้องการเปลี่ยนแปลงวันเวลาการจัดส่งเป็นวันที่:',
  CANCEL_DELIVERY_ENQUIRY: 'ลูกค้าต้องการยกเลิกการทำรายการครั้งนี้',
  TRACK_DELIVERY: 'Track my Delivery',
  RESCHEDULE_MY_DELIVERY: 'Reschedule my Delivery',
  CHANGE_MY_DELIVERY_ADDRESS: 'Change my Delivery Address',
  CANCEL_MY_DELIVERY: 'Cancel my delivery',
  FILES_UPLOADED_SUCCESSFULLY:
    'ขอบคุณที่ส่งเอกสาร และคำขอใช้บริการนี้อยู่ในขั้นตอนการพิจารณาเพิ่มเติม โดยเราจะแจ้งความคืบหน้าให้ทราบทางอีเมล์และ/หรือข้อความโดยเร็วที่สุด',
  INCORRECT_POSTAL_CODE: 'รหัสไปรษณีย์ไม่ถูกต้อง',
  ENTERED_ID: 'ใส่หมายเลขบัตรประชาชน:',
  ENTERED_NAME: ' ใส่ชื่อและนามสกุล:',
  ENTERED_MDN: 'ใส่หมายเลขโทรศัทพ์ที่ลงทะเบียน:',
  CHANGE_EMAIL_ID: 'เปลี่ยนแปลงอีเมล์ในการส่งแจ้งสถานะการทำรายการ',
  CHANGE_MOBILE_NO: 'เปลี่ยนแปลงหมายเลขโทรศัพท์ในการติดต่อ',
  ERROR_MSG_INVALID_EMAIL_ID: 'กรุณาใส่อีเมลที่ต้องการ',
  ERROR_MSG_INVALID_CONTACT_NO: 'กรุณาใส่เบอร์โทรศํพท์ที่ต้องการ',
  ENQUIRY_DETAILS_SAVED: 'บันทึกเรื่องที่ต้องการสอบถาม',
  ENQUIRY_SUBMITTED: 'ดำเนินการส่งคำถาม',
  PAYMENT_SUBMITTED: 'ยืนยันข้อมูลบัตรเครดิต',
  SERVICE_FEE: 'ค่าบริการ:',
  PAYMENT_NEW: 'ช่องทางการชำระค่าบริการ',
  CANCELLED: 'ยกเลิก',
  OEM_WARRENTY_SELECTED: 'ดำเนินการต่อด้วยการรับประกันของผู้ผลิตแบรนด์',

  // CENTRALIZED JOURNEY CONST
  WBKO_KEEP: 'ยืนยัน',
  WBKO_CHANGE: 'เปลี่ยนแปลง',
  YES_TEXT: 'ใช่',
  NO_TEXT: 'ไม่ใช่',
  CLOSE_TEXT: 'ปิด',
  CHAT_WITH_US: 'พูดคุยกับพนักงาน',
  SERVICE_UNAVAILABLE_TEXT:
    'ขออภัยในความไม่สะดวก<br />เราไม่สามารถดำเนินการตามคำขอใช้บริการของคุณได้<br />เนื่องจากปัญหาทางเทคนิค<br /><br />ขอบคุณที่ใช้บริการ AIS Mobile Care',
  YES_PROCEED: 'ยืนยันและเริ่มทำรายการใหม่ตั้งแต่ต้น',
  SELECT_TEXT: 'เลือก',
  RETRY_BUTTON: 'ลองใหม่อีกครั้ง',
  COD_TEXT: 'ชำระด้วยเงินสด',
  CREDIT_CARD_TEXT: 'ชำระผ่านบัตรเครดิต',
  CANCEL_SR: 'ยกเลิกคำขอใช้บริการ',
  BACK_TO_PREV_STEP: 'กลับไปสู่ขั้นตอนก่อนหน้านี้',
  PAYMENT_CANCELLED_PROCEED: 'ยกเลิกการชำระเงิน, เริ่มใหม่อีกครั้ง',
  COMMENT_HERE_PLACEHOLDER: 'กรุณากรอกความคิดเห็นของท่าน',
  SELECT_ENQUIRY_TYPE: 'กรุณาเลือกหัวข้อเรื่อง',
  SELECT_ENQUIRY_SUBTYPE: 'เลือกหัวข้อเรื่องย่อย',
  CREATE_NEW_SR: "ลูกค้าเลือก 'ขอรับบริการเปลี่ยนเครื่องหรือรับเครื่องทดแทน'",
  CREATE_NEW_SR_END: 'เริ่มทำรายการใหม่',
  REASON_FOR_CANCEL: 'กรุณาระบุเหตุผลในการยกเลิก',
  SELECTED_DEVICE: 'ตัวเครื่องที่เลือก',
  INSERT_VALID_NAME: 'กรุณาใส่ชื่อนามสกุลที่ถูกต้อง',
  INSERT_VALID_NUMBER: 'กรุณาใส่หมายเลขบัครเครดิตที่ถูกต้อง',
  INSERT_EXPIRY_FORMAT: 'กรุณาใส่วันหมดอายุบัตรที่ถูกต้อง',
  INSERT_VALID_CVC: 'กรุณาใส่ CVC/3ตัวทางด้านหลังบัตรที่ถูกต้อง',
  MANDATE_EXPIRY_DATE: 'ต้องระบุวันหมดอายุ',
  MANDATE_CVC: 'cvc เป็นสิ่งจำเป็น',
  MANDATE_CREDIT_CARD: 'กรุณาใส่หมายเลขบัตรเครดิต',
  EXPIRED_DATE: 'บัตรของท่านหมดอายุแล้ว',
  REASON_FOR_CHANGE: 'เหตุผลในการเปลี่ยนแปลงที่อยู่',
  EDIT_DELIVERY_SCHEDULE: 'เปลี่ยนแปลงวันและเวลาในการจัดส่ง',
  EDIT_DELIVERY_ADDRESS: 'เปลี่ยนแปลงที่อยู่ในจัดส่ง',
  ENTER_YOUR_MESSAGE: 'เริ่มแชทกับพนักงานโดยการพิมพ์ข้อความของคุณที่นี่',
  AGREE_TERMS_CONDITION: 'ท่านยอมรับเงื่อนไขหรือไม่',
  ACCEPT: 'ยอมรับ',

  GET_FEE_DETAILS: 'คลิกเพื่อแสดงรายละเอียดค่าบริการการทำรายการ',
  PURCHASE_FEE: 'ราคาเครื่องลงทะเบียน ณ วันที่ซื้อ',
  FEE_CHARGES: 'อัตราค่าบริการการทำรายการ',
  TAX_CHARGES: 'อัตราค่าบริการภาษีมูลค่าเพิ่ม',
  TAX_AMMOUNT: 'ค่าบริการภาษีมูลค่าเพิ่ม',
  TOTAL_AMOUNT: 'ค่าบริการสุทธิ',
  FEE_AMOUNT: 'ค่าบริการการทำรายการ',

  WBKO_VERIFYING_MDN: 'ระบบกำลังตรวจสอบหมายเลขโทรศัพท์ในระบบ',
  WBKO_VERIFIED_MDN: 'ระบบมีการตรวจสอบหมายเลขโทรศัพท์เรียบร้อยแล้ว',
  WBKO_VERIFIED_NRIC: 'ระบบตรวจสอบหมายเลขบัตรประชาชนเรียบร้อยแล้ว',
  WBKO_VERIFYING_NRIC: 'ระบบกำลังตรวจสอบหมายเลขบัตรประชาชนในระบบ',

  // progress bar
  CHECK_ADDRESS: 'กำลังตรวจสอบสถานะการจัดส่ง',
  SHIPPING_DETAILS_VERIFY: 'กำลังตรวจสอบข้อมูลการจัดส่ง',
  CREATING_NEW_REQUEST: 'เริ่มทำรายการเปลี่ยนเครื่องหรือรับเครื่องทดแทน',
  VERIFIED_DEVICE_IN_STOCK: 'มีสินค้าในสต๊อก',
  VERIFYING_DEVICE_IN_STOCK: 'กำลังตรวจสอบสินค้าในสต๊อก',
  INVALID_NRIC: 'หมายเลขบัตรประจำตัวประชาชนไม่ถูกต้อง',
  VERIFYING_IMEI: 'กำลังตรวจสอบหมายเลขประจำเครื่อง',
  UPLOAD_CONFIRMATION_FORM: 'กำลังอัพโหลดแบบฟอร์ม',
  INVALID_IMEI: 'หมายเลขประจำตัวเครื่องไม่ถูกต้อง',
  INVALID_MDN: 'หมายเลขลงทะเบียนไม่ถูกต้อง',
  VERIFIED_MDN: 'หมายเลขลงทะเบียนถูกต้อง',
  VERIFIED_NAME: 'ชื่อและนามสกุลถูกต้อง',
  INVALID_NAME: 'ชื่อและนามสกุลไม่ถูกต้อง',
  VERIFYING_PAYMENT: 'กำลังตรวจสอบการชำระเงิน',
  VERIFIED_REQUEST: 'ยอมรับคำขอของท่าน',
  VERIFIED_PAYMENT: 'ตรวจสอบการชำระเงินเรียบร้อยแล้ว',
  VERIFIED_IMEI: 'หมายเลขหมายเลขประจำตัวเครื่องได้รับการตรวจสอบเรียบร้อยแล้ว',
  VERIFIED_NRIC: 'หมายเลขประจำตัวบัตรประชาชนได้รับกาตรวจสอบเรียบร้อยแล้ว',
  VERIFYING_NRIC: 'กำลังดำเนินการตรวจสอบหมายเลขบัตรประชาชน',
  VERIFYING_REQUEST: 'ระบบกำลังตรวจสอบข้อมูลคำขอของท่าน',
  VERIFYING_MDN: 'กำลังตรวจสอบข้อมูลในระบบ',
  VERIFYING_NAME: 'กำลังตรวจสอบชื่อในระบบ',
  RESUME_EXISTING_REQUEST: 'ทำรายการแลกเปลี่ยนเครื่องหรือรับเครื่องทดแทนต่อให้เสร็จ',
  SELECT_BRAND: 'ยี่ห้อ',
  SELECT_MODEL: 'รุ่น',
  SELECT_DEVICE: 'เลือกตัวเครื่อง',
  SELECT_ADDRESS: 'เลือกที่อยู่',
  GENERAL_ENQUIRY_OPTIONS: [
    'ข้อมูลแพคเกจที่ใช้งานอยู่',
    'เงื่อนไขการเปลี่ยนเครื่อง',
    'เงื่อนไขการจัดส่ง',
    'ไม่พบข้อมูลการสมัคร',
    'เงื่อนไขการรับประกัน180/อุปกรณ์เสริม',
    'ตรวจสอบค่าธรรมเนียมการเปลี่ยนเครื่อง',
    'อื่น ๆ',
  ],
  GE_THANK_YOU_MESSAGE: 'ขอบคุณที่ติดต่อบริการออนไลน์ AIS Mobile Care',
  NO_EXISTING_CUSTOMER: 'ขออภัยบริการนี้ใช้ได้สำหรับลูกค้า AIS Mobile Care ที่ลงทะเบียนเท่านั้น',
  NO_EXISTING_CUSTOMER_NOTE:
    'Please note that it will take up to 24 hrs for your AIS Mobile Care subscription to reflect in our system. If you have just enrolled today, please try again later!',
  CHECKING_SERVICE_REQUEST_FEE: 'CHECKING SERVICE REQUEST FEE',
  GET_SERVICE_FEE: {
    HEADER: 'ค่าบริการแลกเปลี่ยนเครื่อง/รับเครื่องทดแทน',
    CONTENT1:
      'ค่าบริการสุทธิในการ <strong> รับเครื่องทดแทน </strong> ครั้งนี้คือ {TotalAmount} บาท',
    CONTENT2:
      'ค่าบริการสุทธิในการ <strong> แลกเปลี่ยนตัวเครื่อง </strong> ครั้งนี้คือ {TotalAmount} บาท',
    BUTTON: 'กลับไปเมนูหลัก',
  },
  TRACK_DEVICE_DELIVERY: 'Track my device delivery',
  BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED:
    'As your selected device {MakeModel} is currently not available. We will send you an SMS once the stock is available for delivery arrangement.',

  LANDING_PAGE: {
    WELCOME_HEADING: 'ยินดีต้อนรับสู่บริการ AIS Mobile Care',
    WELCOME_SUBHEADING: 'ดูแลครบจบในแพ็คเกจเดียว',
    HOW_WORKS_HEADING: 'บริการของเราดีอย่างไร',
    HOW_WORKS_SUBHEADING:
      'เมื่อคุณซื้อมือถือเครื่องใหม่ สามารถเปลี่ยนเครื่องได้สูงสุด 2 ครั้ง ใน 12 เดือนเพียงแค่คุณสมัครแพ็คเกจ AIS Mobile Care',
    HOW_WORKS: [
      // {
      //   HEADING: "รับบริการแลกเปลี่ยนเครื่องหรือรับบริการเครื่องทดแทน",
      //   SUBHEADING:
      //     "สามารถรับบริการแลกเปลี่ยนเครื่องได้ 2 ครั้งใน 12 เดือน หรือรับบริการเครื่องทดแทนได้ 1 ครั้งและรับบริการแลกเปลี่ยนเครื่องอีก 1 ครั้งใน 12 เดือน",
      //   icon: "SWAP"
      // },
      {
        HEADING: 'จัดส่งฟรี รวดเร็วทันใจ',
        SUBHEADING: 'จัดส่งฟรีถึงประตูบ้านของคุณภายใน 6ชม. สำหรับพื้นที่กรุงเทพฯและปริมณทล',
        icon: 'GROUP',
      },
    ],
    HOW_WORKS_NEW: [
      {
        HEADING: 'รับบริการแลกเปลี่ยนเครื่องหรือรับบริการเครื่องทดแทน',
      },
      {
        HEADING: 'AIS Mobile Care Swap Unlimited',
        SUBHEADING: 'สามารถแลกเปลี่ยนเครื่องได้แบบไม่จำกัด ​',
        hasSeal: true,
        sealText: 'ใหม่',
      },
      {
        HEADING: 'AIS Mobile Care​',
        SUBHEADING:
          'สามารถแลกเปลี่ยนเครื่องได้ 2 ครั้ง  หรือ รับเครื่องทดแทน 1 ครั้งและแลกเปลี่ยนเครื่องอีก 1 ครั้งในรอบ 12 เดือน ​',
      },
    ],
    FOOTER_LINKS: [
      {
        TEXT: 'AIS Mobile Care คืออะไร?',
        HREF: '/faq',
      },
      {
        TEXT: 'นโยบายความเป็นส่วนตัว',
        HREF: '/privacy-policy-thai',
      },
      {
        //     TEXT: 'เงื่อนไขของเว็บไซต์',
        //     HREF: '',
        // }, {
        TEXT: 'ข้อกำหนดและเงื่อนไข',
        HREF: '/terms-conditions-thai',
      },
    ],
    FOOTER_LINKS_NEW: {
      leftCol: [
        {
          TEXT: 'AIS Mobile Care คืออะไร?',
          HREF: '/faq',
        },
        {
          TEXT: 'AIS Mobile Care Swap Unlimited คืออะไร?',
          HREF: '/faq-swap-unlimited',
        },
      ],
      rightCol: [
        {
          TEXT: 'AIS Mobile Care ข้อกำหนดและเงื่อนไข',
          HREF: '/terms-conditions-thai',
        },
        {
          TEXT: 'AIS Mobile Care Swap Unlimited ข้อกำหนดและเงื่อนไข',
          HREF: '/terms-conditions-swap-unlimited-thai',
        },
        {
          TEXT: 'นโยบายความเป็นส่วนตัว',
          HREF: '/privacy-policy-thai',
        },
      ],
    },
  },
  REQUEST_OPTIONS: {
    title: 'เลือกหัวขัอที่ต้องการทำรายการ',
    buttons: [
      {
        name: CONSTANTS.CREATE_A_NEW_REQUEST,
        subText: 'ทำรายการแลกเปลี่ยนเครื่องหรือรับเครื่องทดแทน',
        text: 'เริ่มทำรายการ',
        icon: 'START_REQ',
      },
      {
        name: CONSTANTS.CHECK_SERVICE_FEE,
        subText: 'ค่าบริการการแลกเปลี่ยนเครื่องหรือรับเครื่องทดแทน',
        text: 'เช็คราคา',
        icon: 'CHECK_FEE',
      },
      {
        name: CONSTANTS.WBKO,
        subText: 'กำลังรอรับตัวเครื่อง?',
        text: 'เช็คสถานะการจัดส่ง',
        icon: 'CHECK_STATUS',
      },
      {
        name: CONSTANTS.GET_HELP_MORE_INFO,
        subText: 'ตรวจสอบข้อมูลบริการที่ใช้งานและข้อมูลด้านอื่น ๆ',
        text: 'ดูรายละเอียดเพิ่มเติม',
        icon: 'GET_HELP',
      },
    ],
  },
  GET_MOREINFO: {
    title: 'กรุุณาเลือกตัวเลือก',
    buttons: [
      {
        text: 'สอบถามข้อมูลทั่วไป',
        icon: 'GE',
        name: CONSTANTS.GENERAL_ENQUIRY,
      },
      {
        text: 'ดูข้อมูลบริการที่ใช้งานอยู่',
        icon: 'SUBSCRIPTION',
        name: 'SUBSCRIPTION',
      },
      {
        text: 'ฉันอยากจะยกเลิก AIS Mobile Care',
        icon: 'LEAVE_AIS',
        name: CONSTANTS.LEAVE_AIS,
      },
    ],
  },
  GET_STARREQ: {
    title: 'กรุุณาเลือกตัวเลือก',
    buttons: [
      {
        name: CONSTANTS.CREATE_A_NEW_REQUEST,
        text: 'เริ่มทำรายการแลกเปลี่ยนตัวเครื่องหรือรับเครื่องทดแทน',
      },
      {
        name: CONSTANTS.RESUME_EXISTING_REQUEST,
        text: 'ทำรายการแลกเปลี่ยนเครื่องหรือรับเครื่องทดแทนต่อให้สำเร็จ',
      },
    ],
  },
  SUBSCRIPTION_OPTION: {
    SwipeToSeeAllDevices: 'ปัดหน้าจอ เพื่อดูตัวเครื่องทั้งหมด',
    Sub1: 'ชื่อ',
    Sub2: 'ค่าบริการรายเดือน',
    Sub3: 'ค่าใช้บริการ (รวมภาษีมูลค่าเพิ่ม)',
    Sub4: 'วันที่เริ่มต้นและสถานะ',
    Sub5: 'หมายเลขเครื่อง IMEI (3 ตัวสุดท้าย) ',
    SrNo: 'หมายเลขคำขอรับบริการ',
    SrType: 'ประเภทการขอรับบริการ',
    Status: 'สถานะการทำรายการ',
    SectionTitle: 'กรุณายืนยันโทรศัพท์ของคุณเพื่อดำเนินการต่อ',
    EnroledDevice: 'โทรศัพท์ที่ลงทะเบียนของคุณ',
    ServiceRequests: 'คำขอรับบริการของคุณ',
    CantFindDevice: 'ไม่พบตัวเครื่องที่ลงทะเบียนไว้',
    ClickToUpdate: 'คลิก"ที่นี่"เพื่ออัปเดตตัวเครื่อง',
    TITLE: 'รายละเอียดคำขอรับบริการ',
    Proceed: 'เลือก',
    EnrolledDevice: 'อุปกรณ์ที่ลงทะเบียน',
    MobileDeviceNumber: 'เบอร์โทรศัพท์ที่ได้ลงทะเบียนไว้',
    ProgramName: 'ชื่อโปรแกรม',
    AgreementStartDate: 'วันที่เริ่มใช้บริการและสถานะปัจจุบัน',
    Subscription: 'ค่าบริการแพคเกจ (รวมภาษีมูลค่าเพิ่มเรียบร้อยแล้ว)',
    SWAP: 'ค่าบริการแลกเปลี่ยนเครื่อง (รวมภาษีมูลค่าเพิ่มเรียบร้อยแล้ว)',
    REPLACE: 'ค่าบริการรับเครื่องทดแทน (รวมภาษีมูลค่าเพิ่มเรียบร้อยแล้ว)',
  },
  CONFIRM_EXISTING_CUST: 'ยืนยันลูกค้าปัจจุบัน',
  CHANGE_DEVICE_SELECTION: 'เปลี่ยนการเลือกอุปกรณ์',
  AGENT_CHAT_THANKYOU_MSG: {
    HEADING: 'ขอบคุณที่ติดต่อ AIS Mobile Care',
    SUB_HEADING: 'เราจะติดต่อคุณกลับไปเพื่อช่วยเหลือโดยเร็วที่สุด',
  },
  USER_ONLINE: 'ผู้ใช้ออนไลน์',
  USER_OFFLINE: 'ผู้ใช้ออฟไลน์',
  UPDATE_ENROLLED_DEVICE: 'ตัวเครื่องที่ลงทะเบียน', // 'กลับไปยังเมนูหลัก',
  UPDATE_ENROLLMENT: {
    title: 'กรุณายืนยันว่าคุณได้ใช้บริการการแลกเปลี่ยนตัวเครื่องต่อไปนี้แล้ว',
    buttons: [
      {
        name: CONSTANTS.AIS_OEM_EXCHANGE,
        value: 'แลกเปลี่ยนเครื่องกับผู้ผลิต (OEM) หรือศูนย์บริการที่ได้รับอนุญาต',
        text: 'เปลี่ยนเครื่องกับแบรนด์ผู้ผลิต-OEM',
      },
      {
        name: CONSTANTS.AIS_STOP_EXCHANGE,
        value: 'แลกเปลี่ยนเครื่องจากทางร้าน',
        text: 'เปลี่ยนเครื่องกับหน้าร้าน-Shop',
      },
      {
        name: CONSTANTS.AIS_NO_EXCHANGE,
        value: 'ไม่ ฉันไม่ได้มีการแลกเปลียนเครื่อง',
        text: 'ไม่ได้มีการเปลี่ยนเครื่อง-NoExchange',
      },
    ],
  },
  UPDATE_ENROLLED_FORM: {
    title: 'กรุณาระบุข้อมูลต่อไปนี้พร้อมหลักฐานการแลกเปลี่ยนตัวเครื่อง',
    content_header: 'อัปโหลดเอกสารหรือหลักฐาน',
    content_placeholder: 'อัปโหลดหลักฐานในการแลกเปลี่ยนเครื่อง',
    placeholder1: 'ใส่หมายเลขเครื่อง(IMEI) 15 หลัก',
    placeholder2: 'เบอร์โทรศัพท์ที่ต้องการให้ติดต่อ',
    placeholder3: 'เลือกยี่ห้อ',
    placeholder4: 'เลือกรุ่น',
    placeholder5: 'ใส่ความจุ',
    placeholder6: 'ใส่สี',
    Cancel: 'ยกเลิก',
    Confirm: 'ยืนยัน',
    content_footer: ['เอกสารประกอบที่ระบุ IMEI (เช่น รายงานบริการ ใบแลกเปลี่ยน ฯลฯ)'],
  },
  SUBMIT_ENROLLED_ENQUIRY:
    'ขอบคุณสำหรับข้อมูล ระบบจะทำการตรวจสอบและแจ้งผลการพิจารณาให้ท่านทราบภายใน 2 วันทำการผ่านทางอีเมล์และ/หรือข้อความ',
  PROCEED_SELECTED_DEVICE: '<strong>ตัวเครื่องที่ลงทะเบียน</strong><br />{selectedDevice}',
  SELECTED_DEVICE: '<strong>ตัวเครื่องที่ลงทะเบียน</strong><br />{selectedDevice}',
  MAX_FILE_SIZE_EXCEEDED: 'ไฟล์เกินขนาด',
  INPUT_VALIDATION_CONTACT_NO: 'กรุณาใส่หมายเลขโทรศัพท์ที่ถูกต้อง',
  INPUT_VALIDATION_IMEI: 'กรุณาใส่หมายเลขอีมี่(IMEI) 15 หลัก',
  SMART_STOCK: {
    WARNING:
      '<span style="color:#DF070E;">เราขอเรียนให้ท่านทราบว่า ในการทำรายการครั้งต่อไป ระบบจะอ้างอิงจากตัวเครื่องที่คุณทำรายการและได้รับไปล่าสุด</span>',
  },
  DEVICE_STATUS_IN_STOCK: {
    header: "ข่าวดี!",
    subHeader: "เรายินดีที่จะแจ้งให้คุณทราบว่าเรามี {DeviceName} พร้อมในสต็อกสินค้า"
  },
  DEVICE_STATUS_OUT_STOCK: {
    header: "อยู่ระหว่างการจัดหาตัวเครื่อง",
    subHeader: "เราจะแจ้งให้คุณทราบว่าขณะนี้ {DeviceName} อยู่ในระหว่างการจัดหาตัวเครื่อง ",
    subHeaderNoRpm: "กรุณาเลือก <strong>\"ต้องการรอตัวเครื่อง 5-7 วันทำการ\"</strong> และระบุข้อมูลที่จำเป็นให้ครบถ้วนเพื่อให้เราทำการรีบจัดหาตัวเครื่องให้คุณ. กรุณาส่งคำขอใช้บริการให้เสร็จสมบูรณ์ แล้วทางเราจะรีบติดต่อคุณกลับภายใน 5-7 วันทำการ. <br/><br/>หรือคุณอยากจะยกเลิกคำขอใช้บริการนี้ แล้วกลับไปที่เมนูหลัก.",
    subHeaderWithRpm: "กรุณาเลือก <strong>\"รุ่นที่ได้รับความนิยมอื่นๆ\"</strong> พร้อมจัดส่ง. <br/><br/>หรือคุณอยากจะยกเลิกคำขอใช้บริการนี้ แล้วกลับไปที่เมนูหลัก.",
    buttonSelectAlternate: "รุ่นที่ได้รับความนิยมอื่นๆ",
    buttonWait: "ต้องการรอตัวเครื่อง 5-7 วันทำการ",
    buttonCancel: "ยกเลิกคำขอใช้บริการ"
  },
  DEVICE_STATUS_NLP: {
    header: "ตัวเครื่องถูกยกเลิกในการวางจำหน่าย",
    subHeader: "เราเสียใจที่ต้องแจ้งให้คุณทราบว่า {DeviceName} ถูกยกเลิกในการวางจำหน่ายแล้ว",
    subHeaderNoRpm: "กรุณาเลือก <strong>\"ต้องการรอตัวเครื่อง 5-7 วันทำการ\"</strong> และระบุข้อมูลที่จำเป็นให้ครบถ้วนเพื่อการนำเสนอตัวเครื่องอื่นๆที่น่าสนใจให้กับคุณ. กรุณาส่งคำขอใช้บริการให้เสร็จสมบูรณ์ แล้วทางเราจะรีบติดต่อคุณกลับภายใน 5-7 วันทำการ. <br/><br/>หรือคุณอยากจะยกเลิกคำขอใช้บริการนี้ แล้วกลับไปที่เมนูหลัก.",
    subHeaderWithRpm: "กรุณาเลือก <strong>\"รุ่นที่ได้รับความนิยมอื่นๆ\"</strong> พร้อมจัดส่ง. <br/><br/>หรือคุณอยากจะยกเลิกคำขอใช้บริการนี้ แล้วกลับไปที่เมนูหลัก.",
    buttonSelectAlternate: "รุ่นที่ได้รับความนิยมอื่นๆ",
    buttonWait: "ต้องการรอตัวเครื่อง 5-7 วันทำการ",
    buttonCancel: "ยกเลิกคำขอใช้บริการ"
  },
  DEVICE_STATUS_NCA: {
    header: "ขณะนี้ไม่สามารถจัดหาตัวเครื่องได้",
    subHeader: "เราขอแจ้งให้คุณทราบว่าขณะนี้ เราไม่สามารถจัดหาตัวเครื่อง {DeviceName} ได้",
    subHeaderNoRpm: "กรุณาเลือก <strong>\"ต้องการรอตัวเครื่อง 5-7 วันทำการ\"</strong> และระบุข้อมูลที่จำเป็นให้ครบถ้วนเพื่อการนำเสนอตัวเครื่องอื่นๆที่น่าสนใจให้กับคุณ. กรุณาส่งคำขอใช้บริการให้เสร็จสมบูรณ์ แล้วทางเราจะรีบติดต่อคุณกลับภายใน 5-7 วันทำการ. <br/><br/>หรือคุณอยากจะยกเลิกคำขอใช้บริการนี้ แล้วกลับไปที่เมนูหลัก.",
    subHeaderWithRpm: "กรุณาเลือก <strong>\"รุ่นที่ได้รับความนิยมอื่นๆ\"</strong> พร้อมจัดส่ง. <br/><br/>หรือคุณอยากจะยกเลิกคำขอใช้บริการนี้ แล้วกลับไปที่เมนูหลัก.",
    buttonSelectAlternate: "รุ่นที่ได้รับความนิยมอื่นๆ",
    buttonWait: "ต้องการรอตัวเครื่อง 5-7 วันทำการ",
    buttonCancel: "ยกเลิกคำขอใช้บริการ"
  },
  WBKO_RESCHEDULE: {
    greetings: "Hi, {CustomerName}",
    OOSTitle: "เราเสียใจที่ต้องแจ้งให้คุณทราบว่าตัวเครื่องของคุณยังไม่มีในสต็อก โปรดยืนยันรายละเอียดคำขอใช้บริการของคุณ",
    LFLTitle: "เรายินดีที่จะแจ้งให้คุณทราบว่า ตัวเครื่อง {DeviceName} มีของพร้อมจัดส่ง กรุณายืนยันรายละเอียดคำขอใช้บริการของคุณ",
    NLFLTitle: "เรายินดีที่จะนำเสนอตัวเครื่อง {DeviceName} เพื่อทดแทนเครื่องเดิมเนื่องจากไม่มีของพร้อมจัดส่ง. กรุณายืนยันรายละเอียดคำขอใช้บริการของคุณ.",
    NLFLNote: "โปรดทราบว่า เมื่อคุณยอมรับตัวเครื่องทดแทนแล้ว คุณจะไม่สามารถกลับมาขอรับบริการเป็นเครื่องลงทะเบียนเดิมในอนาคตได้",
    CancelReasonsTitle: "กรุณาบอกเหตุผลในการยกเลิกคำขอใช้บริการของคุณ (คุณสามารพเลือกเหตุผลได้มากกว่า 1 ข้อ)",
    CancelReasons: [
      {
        DisplayText: "รอตัวเครื่องนานเกินไป",
        Value: "Waited too long to get swap / replacement device"
      },
      {
        DisplayText: "มีความกังวลเกี่ยวกับคุณภาพของตัวเครื่อง",
        Value: "Concerned about swap / replacement device quality"
      },
      {
        DisplayText: "ไม่พอใจกับตัวเครื่องที่นำเสนอ",
        Value: "Do not like the offered device model"
      },
      {
        DisplayText: "ตัดสินใจซื้อเครื่องใหม่",
        Value: "Decided to purchase new device"
      },
      {
        DisplayText: "ตัดสินใจที่จะนำเครื่องไปซ่อมที่อื่น",
        Value: "Decided to repair device elsewhere"
      },
      {
        DisplayText: "ค่าบริการแพงเกินไป",
        Value: "Fee is too high"
      }
    ],
    CancelConfirm1: "คุณแน่ใจหรือไม่ที่ยกเลิกคำขอใช้บริการ",
    CancelConfirm2: "โปรดทราบว่าหากยกเลิกคำขอใช้บริการนี้แล้ว จะไม่สามารถนำกลับมาดำเนินการใหม่ได้อีก.",
    NewScheduleReminder1: "โปรดแสดงตัวพร้อมบัตรประจำตัวประชาชนเพื่อลงนามรับตัวเครื่อง",
    NewScheduleReminder2: "หากคุณไม่สามารแสดงตัวในวันดังกล่าวได้ กรุณาเปลี่ยนกำหนดการจัดส่งในวันและเวลาที่คุณสะดวก",
    RescheduleImportantNote1: "ขอบคุณสำหรับการยืนยันรายละเอียด",
    RescheduleImportantNote2: "คุณจะได้รับข้อความและอีเมลยืนยันในไม่ช้า",
    RescheduleImportantNote3: "โปรดทราบว่าคุณจะต้องแสดงตัวตามที่อยู่จัดส่งที่ระบุเท่านั้น",
    RescheduleImportantNote4: "กรุณาตรวจสอบให้แน่ใจว่าคุณมีบัตรประจำตัวประชาชนสำหรับใช้ในการยืนยันตัวตนในการรับตัวเครื่อง",
    RescheduleImportantNote5: "หากมีข้อสงสัยหรือคำถามใดๆ สามารถติดต่อเราได้โดยตรงด้วย sms หรือ อีเมลนี้",
    RescheduleImportantNote6: "ขอบคุณที่ใช้บริการ",
    ProceedButton: "ยืนยันและดำเนินการจัดส่ง",
    CancelSRButton: "ยกเลิกคำขอใช้บริการ",
    BackButton: "ย้อนกลับ",
    SubmitButton: "ยืนยัน"
  },
  PaymentDisclaimer: "การชำระเงินใดๆ ที่ทำกับบริษัท Asurion (Thailand) Limited ผ่านทางเว็บไซต์นี้ จะทำธุรกรรมผ่าน NEW Asurion Singapore Pte Ltd."
};

export default MESSAGE_CONSTANTS_THAI;
