import React, { Component } from "react";

import { isDeviceOrWeb } from '../../utils/utils';

import instruct1 from '../../images/mobile/img-fmip-inst_1.png';
import instruct2 from '../../images/mobile/img-fmip-inst_2.png';
import instruct3 from '../../images/mobile/img-fmip-inst_3.png';
import fmipIcon from '../../images/mobile/img_fmip_icon.png';
import DOMPurify from "dompurify";

class FMIPInstructionSlider extends Component {

  _renderSliderImages(image, i) {

    return (
      <div className='ais_slide' key={i}>
        {isDeviceOrWeb() === 'mobile' ?
          <div className="ais_fmip-slide-wrapper">
            <p className="ais_fmip-instruction" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data[`CONTENT${i + 1}`]) }}></p>
          </div> : ""}
        <img alt="fmip-instruction" key={i} className="ais_fmip-inst-img" src={image} />
      </div>
    )
  }

  render() {

    let imageList = [instruct1, instruct2, instruct3];

    if (isDeviceOrWeb() === "mobile") {
      return (
        <div className="ais_fmip-instructions-div">
          <div className="ais_header-terms-div">
            <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
            <div className="ais_fmip-header-icon">
              <img alt="fmip-header-icon" className="ais_fmip-icon" src={fmipIcon} />
            </div>
            <div className="ais_fmip-inst-wrapper">
              <div className="ais_illustration-wrapper">
                <div className='ais_fmip-scrolling-wrapper flex'>
                  {imageList && imageList.map((e, i) => this._renderSliderImages(e, i))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="ais_fmip-instructions-div">
        <div className="ais_header-terms-div">
          <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
          <div className="ais_fmip-header-icon">
            <img alt="fmip-header-icon" className="ais_fmip-icon" src={fmipIcon} />
          </div>
          <div className="ais_fmip-inst-wrapper">
            <div className="ais_instruction-wrapper">
              <div className="ais_fmip-instruction" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.CONTENT1) }}></div>
              <div className="ais_fmip-instruction" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.CONTENT2) }}></div>
              <div className="ais_fmip-instruction" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.CONTENT3) }}></div>
            </div>
            <div className="ais_illustration-wrapper">
              <div className="ais_fmip-illustration">
                {imageList && imageList.map((e, i) => this._renderSliderImages(e, i))}
              </div>
            </div>
          </div>
          <div className="ais_fmip-instruction" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.CONTENT4) }}></div>
          <div className="ais_fmip-instruction" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.CONTENT5) }}></div>
        </div>
      </div>
    )

  }
}
export default FMIPInstructionSlider;