import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  savePayload,
  updateTransitionPanel
} from "../../actions/Dashboard/doFindAgreements";
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import CONSTANTS from '../../utils/Constants';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import { LOAD_THANK_YOU,STAY_WITH_AIS } from '../../actions/Dashboard/actionTypes';

class ChurnPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminateAISMobileCare: null,
      disabled : false
    };
    this.stayWithAIS = this.stayWithAIS.bind(this);
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.createInquiryResponse){
      let flag = this.state && this.state.Leave_Stay_AIS_Flag;
      setTimeout(() => {
        this.setState({terminateAISMobileCare:false});
        this.props.savePayload(LOAD_THANK_YOU, {showThankYou:true, stayWithAIS : flag});
      }, 3500);
    }
  }

  handleSubmitModal(){
    MIXPANEL.track(EVENTLIST.THANK_YOU,{'Load Thank You':''});
    this.props.savePayload(STAY_WITH_AIS, {stayWithAIS:false});
    this.setState({Leave_Stay_AIS_Flag : false});
    this.props.updateJourneyList(CONSTANTS.LOAD_THANK_YOU,{stayWithAIS:false});
    this.setState({disabled : true})
  }
  stayWithAIS(){
    MIXPANEL.track(EVENTLIST.THANK_YOU,{'Load Thank You':''});
    this.props.savePayload(STAY_WITH_AIS, {stayWithAIS:true});
    this.setState({Leave_Stay_AIS_Flag : true});
    this.props.updateJourneyList(CONSTANTS.LOAD_THANK_YOU,{stayWithAIS:true});
    this.setState({disabled : true})
  }
  render(){
    let {disabled} = this.state;
    let mobileHeader=MESSAGE_CONSTANTS && MESSAGE_CONSTANTS.MODAL_HEADER && MESSAGE_CONSTANTS.MODAL_HEADER;

    return(
      <div>
        <div className="ais_current-form-device-div">
          <div className="ais_header-txt-div">
             <div>
                <div className="center">
                        <span className=" center" dangerouslySetInnerHTML={ {__html: mobileHeader }}></span>
                            <br/> <br/>{MESSAGE_CONSTANTS.MODAL_TEXT}
                        <div className="center">
                            <button className="stay-with-ais-button" disabled = {disabled}  onClick={this.stayWithAIS}>{MESSAGE_CONSTANTS.STAY_WITH_AIS_MOBILE_CARE}</button>
                        </div>
                        <div className={`acknowledge ${disabled ? ' disabled' : ' ' }`}  onClick={this.handleSubmitModal} dangerouslySetInnerHTML={ {__html: MESSAGE_CONSTANTS.ACKNOWLEDGE_MSG }}>
                        </div>
                </div>
                </div>
        </div>
        </div>
      </div>
      )
  }
}
const mapStateToProps = state => {
  let {createInquiryResponse,stayWithAIS}= state.customerData
  return {
   createInquiryResponse,stayWithAIS
  }
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePayload,
      updateTransitionPanel
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChurnPopup);

