import React, { Component, Fragment } from "react";

class CallCreateChatRequest extends Component {
    componentWillMount() {
        this.props.createEncryptedChatRequest({ variables: this.props.chatRequest });
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.chatRequest) !== JSON.stringify(nextProps.chatRequest)) {
            this.props.createEncryptedChatRequest({ variables: nextProps.chatRequest });
        }
    }

    render() {
        return (
            <Fragment>                
            </Fragment>
        )
    }
} 

export default CallCreateChatRequest;