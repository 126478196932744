import React, { Fragment, useEffect, useState } from "react";
import { Subscription } from "react-apollo";
import SubscribeToNewConversation from './../../../graphql/subscription/SubscribeToNewConversation';
import SubscribeToNewMessage from '../../../graphql/subscription/SubscribeToNewMessage';
import InactiveTimer from './InactiveTimer';
import AjaxLoader from './../../../ais_components/AjaxLoader/AjaxLoader';
import MESSAGE_CONSTANTS from "../../../config/journey-messages";
import CONSTANTS from '../../../utils/Constants';
import { isDeviceOrWeb } from "../../../utils/utils";

const GetConversationMessages = ({
  requestId,
  showLoader,
  transferChat,
  chatInactiveTimeOut,
  actions,
  messageList
}) => {
  const [conversationData, setConversationData] = useState(null);
  const [conversationId, setConversationId] = useState(null);

  useEffect(() => {
    if (conversationData && conversationData.conversationId) {
      actions.getNewConversation(conversationData);
      actions.updateJourneyList(CONSTANTS.TRANSFERRED_TO_AGENT, null);
    }
  }, [conversationData])

  return (
    <>
      <Subscription
        subscription={SubscribeToNewConversation}
        variables={{ requestId }}
        onSubscriptionData={(options) => {
          setConversationId(options.subscriptionData.data.onCreateConversation.conversationId);
          setConversationData(options.subscriptionData.data.onCreateConversation);
        }}
        skip={conversationData && conversationData.conversationId ? true : false}
      >
        {({ loading }) => {
          let msg = MESSAGE_CONSTANTS.TRANSFERRING_TO_AGENT;
          if (transferChat) {
            msg = MESSAGE_CONSTANTS.TRANSFERRING_TO_ANOTHER_AGENT;
          }

          return (
            <Fragment>
              <div className={`${isDeviceOrWeb() === 'mobile' ? 'ais_addLoaderDiv' : ''}`}>
                {loading && showLoader &&
                  <AjaxLoader
                    startLabel={MESSAGE_CONSTANTS.TRANSFERRING_TO_AGENT}
                    completeLabel={MESSAGE_CONSTANTS.TRANSFERRED_TO_AGENT}
                    trickle={true}
                  />
                }
              </div>
            </Fragment>
          )
        }}
      </Subscription>

      {conversationId &&
        <Subscription
          subscription={SubscribeToNewMessage}
          variables={{ conversationId }}
          onSubscriptionData={(options) => {
            console.log("SubscribeToNewMessage DATA:", options.subscriptionData)
          }}
        >
          {({ data }) => {
            let agentSource = false;
            let lastTimestamp = null;

            if (data) {
              let message = data.onCreateMessage;
              let text = message.content;
              const newMessage = {
                id: message.messageId,
                messageId: message.messageId,
                author: message.sender,
                type: 'text',
                data: { text },
                time: message.createdAt,
                source: message.source
              };

              if (messageList.filter((e) => e.id === newMessage.id).length === 0
                && newMessage.source !== CONSTANTS.SOURCE_ENUM.INTERNAL) {
                messageList.push(newMessage);
                actions.addMessage(newMessage);
              }

              if (message && CONSTANTS.SOURCE_ENUM.AGENT === message.source) {
                agentSource = true;
                lastTimestamp = data.onCreateMessage.createdAt;
              }
            }

            return (
              <Fragment>
                {!chatInactiveTimeOut && data &&
                  <InactiveTimer
                    requestId={requestId}
                    actions={actions} agentSource={agentSource}
                    lastTimestamp={lastTimestamp}
                  />
                }
              </Fragment>
            )
          }}
        </Subscription>
      }
    </>

  )
};

export default GetConversationMessages;