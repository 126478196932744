import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setJourneyCmpltd } from '../../actions/Dashboard/doFindAgreements';
import config from '../../config/config';
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import CONSTANTS from '../../utils/Constants';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import UpdateVisitor from '../../components/common/Chat/UpdateVisitor';

import endImage from "../../images/web/img-agent_email@2x.png";
// import logo from "../../images/mobile/img-logo_white.png";
import logo from '../../images/ais-images/ais-mobile-care-logo@2x.png';

import './DesktopConversationEnd.css';

class DesktopConversationEnd extends Component {

  componentWillMount() {
    MIXPANEL.track(EVENTLIST.JOURNEY_COMPLETED, { "End Reason": CONSTANTS.ENDREASON_LIST.HOME_PAGE });
  }

  closewindow = () => {
    window.location.replace(config.ApplicationURL);
  }

  startNewRequest = () => {
    window.location.reload();
  }

  render() {
    return (
      <Fragment>
        <UpdateVisitor visitorId={this.props.visitorId} journeyCmpltd={true} journeyStatus={CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED} />
        <div className="ais_endHeader">
          <div className="ais_end-logo-cus"><img alt="mobileswop-whit-logo" src={logo} /> </div>
          <div className="ais_end-wrapper">
            <div id="endConvPagelogo" className="ais_endConvPagelogo">
              {/* <img alt="mobileswop-white-logo" src={require('../../../images/mobile/img-logo_white.png')} /> */}
              <p dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.CONVERSATION_END.THANK_YOU }}></p>
            </div>
            {this.props.cancelSR ? <div style={{ marginTop: '40px' }}>
              <button className='ais-submit-button-cus' onClick={this.closewindow} > {MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
              <div className="ais_device-divider end"></div>
              <button className='ais-submit-button-cus' onClick={this.startNewRequest} >{MESSAGE_CONSTANTS.CREATE_NEW_SR_END}</button>
            </div>
              : <div style={{ marginTop: '40px' }}>
                <button className='ais-submit-button-cus' onClick={this.closewindow} > {MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
              </div>}

          </div>
        </div>
        {/* <div className="ais_secondRow"> */}
        {/* <img alt="signingOff" src={require("../../../images/web/img-CS_end@3x.png")} /> */}
        {/* <img alt="Signing Off" src={endImage} /> */}
        {/* </div> */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { visitorId, cancelSR } = state.customerData;
  return {
    visitorId,
    cancelSR
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setJourneyCmpltd
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(DesktopConversationEnd);