import gql from 'graphql-tag';

export default gql`
mutation updateVisitor($input:UpdateVisitorInput!){
   updateVisitor(input:$input){
      mdn
      visitorCognitoId
      visitorId
      userName
      clientId
      languageCode
      browserAgent
      ipAddress
      journeyStatus
      interactionType
      interactionId
      carrierName
      lastUpdatedTime
      chatAssisted
      lastActivity
      caseNumber
      perilType
      chatReason
  }
}`;