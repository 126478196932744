import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import _ from 'lodash';

import { evaluateIMEIInit, savePayload } from '../../actions/Dashboard/doFindAgreements';
import { SAVE_SIX_DIGITS_IMEI } from '../../actions/Dashboard/actionTypes';
import CONSTANTS from "../../utils/Constants";
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';

import emiIcon from "../../images/mobile/img-imei_50.png";
import dialEmiIcon from "../../images/ais-images/icon-imei.png";
import MESSAGE_CONSTANTS from "./../../config/journey-messages";

class SubmitIMEICode extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            lastSixDigitsIMEI: "",
            textboxDisable: false,
            buttonDisable: true,
            buttonClass: "ais_device-select-button ais_emi",
            displayError: false,
            disableBckButton: false
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.setIMEI = this.setIMEI.bind(this);
        // this.backButtonClicked = this.backButtonClicked.bind(this);
    }

    handleKey(event) {
        if (event.keyCode === 13 && !event.shiftKey && event.target.value) {
            this.handleClick();
        }
    }

    setIMEI(value) {
        value = value.replace(/[^0-9]/g, '');
        if (value === "") {
            this.setState({
                lastSixDigitsIMEI: ""
            });
        } else {
            this.setState({
                lastSixDigitsIMEI: value,
                buttonDisable: false,
                displayError: false
            })
        }
    }

    // backButtonClicked() {
    //     this.setState({
    //         buttonDisable: true,
    //         textboxDisable: true,
    //         disableBckButton: true
    //     })
    //     this.props.updateJourneyList(CONSTANTS.DEVICE_LIST, "");
    // }
    handleClick() {

        if (!this.state.lastSixDigitsIMEI) {
            return false;
        }

        let { cacheId, selectedDefectOption, caseType, mismatchedDeviceDetails, assetInstance } = this.props;

        if (this.state.lastSixDigitsIMEI.length < 6) {
            this.setState({
                displayError: true
            });
        } else {
            this.setState({
                buttonDisable: true,
                disableBckButton: true,
                textboxDisable: true,
                buttonClass: "ais_device-select-button ais_emi ais_selected"
            })

            let ProcessIncidentParameters = this.props.processIncidentData ?
                this.props.processIncidentData : {
                    SessionId: cacheId,
                    Incident: {
                        FailureDescriptiveText: selectedDefectOption,
                        IncidentType: caseType
                    }
                }

            if (!_.isEmpty(mismatchedDeviceDetails)) {
                ProcessIncidentParameters = {
                    ...ProcessIncidentParameters,
                    AssetDetails: mismatchedDeviceDetails
                }
            }

            if (this.props.caseType === CONSTANTS.MALFUNCTION) {
                ProcessIncidentParameters = this.props.processIncidentData ?
                    this.props.processIncidentData : {
                        SessionId: cacheId,
                        Incident: {
                            FailureDescriptiveText: selectedDefectOption,
                            IncidentType: caseType,
                            Triage: {
                                TriageAccepted: true,
                                TriageRefusalReason: "Triage refused by customer",
                                Notes: ""
                            }
                        }
                    }
            }

            MIXPANEL.track(EVENTLIST.IMEI_INPUT);
            this.props.updateJourneyList(null, this.state.lastSixDigitsIMEI);

            if (this.state.lastSixDigitsIMEI) {
                let { chatReason } = this.props;
                let IsClearSession = chatReason === 'Invalid IMEI' ? true : false;
                this.props.savePayload(SAVE_SIX_DIGITS_IMEI, this.state.lastSixDigitsIMEI);
                this.props.evaluateIMEIInit(this.state.lastSixDigitsIMEI, this.props.cacheId, this.props.caseType, ProcessIncidentParameters, assetInstance, this.props.imeiVerificationAttempt, IsClearSession);
            }
        }
    }

    componentDidMount() {
        this.refs['imei'].focus();
    }

    render() {
        return (
            <div className="ais_current-terms-div-cus">
                <div className="ais_header-terms-div">
                    <label className="ais_current-mobile-device-label">{this.props.data.PRIMARY_HEADER}</label>
                </div>

                <div className="ais_container-terms-div">
                    <div>  <img alt="dial-imei-icon" className="ais_confirm-delivery-icn" src={dialEmiIcon} />
                        <div className="ais_current-form-emi-div">
                            <p className="ais_current-form-device-name">{this.props.data.SECONDARY_HEADER}</p>
                            <p className="ais_prefer-device-desc" dangerouslySetInnerHTML={{ __html: this.props.data.CONTENT }}></p>
                            <p className="ais_current-form-device-name"> {this.props.data.APPLE_NOTE} </p>
                        </div>
                    </div>
                    {this.state.displayError && <div className="ais_error-div imei"><label>{this.props.data.INVALID_IMEI}</label></div>}
                    <input ref="imei" type="number" disabled={this.state.textboxDisable} className="ais_deliver-address-input ais_emi" value={this.state.lastSixDigitsIMEI} type="text" pattern="[0-9]{6}" onKeyDown={this.handleKey} maxLength="6" minLength="6" onChange={(e) => this.setIMEI(e.target.value)} placeholder={this.props.data.PLACEHOLDER} />
                    <div className="ais_device-divider ais_emi" />
                    <button disabled={this.state.buttonDisable} className={this.state.buttonClass} onClick={this.handleClick} >{this.props.data.BUTTON}</button>
                </div>
                {/* {this.props.backEnabled ? <button className="ais_go_back" disabled={this.state.disableBckButton} onClick={this.backButtonClicked}>  {MESSAGE_CONSTANTS.BACK_TO_PREV_STEP} </button> : ""} */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { clientData, mismatchedDeviceDetails, processIncidentData, assetInstance, backEnabled, selectedDefectOption, caseType, selectedBrand, deviceDetails, imeiVerificationAttempt, chatReason } = state.customerData;
    return {
        chatReason,
        cacheId: clientData.CacheId,
        mismatchedDeviceDetails: mismatchedDeviceDetails,
        selectedDefectOption: selectedDefectOption,
        caseType: caseType,
        backEnabled,
        processIncidentData,
        deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
        assetInstance,
        imeiVerificationAttempt
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        evaluateIMEIInit,
        savePayload
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitIMEICode);