import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import browser from 'browser-detect';
import { v4 as uuid } from 'uuid';

import CreateVisitor from './../../common/Chat/CreateVisitor';
import UpdateVisitor from './../../common/Chat/UpdateVisitor';
import CreateChatRequest from './../../common/Chat/CreateChatRequest';
import ChatInput from './../../common/Chat/ChatInput';
//import GetMyConversation from './../../common/Chat/GetMyConversation';
//import GetMessages from './../../common/Chat/GetMessages';
import GetConversationMessages from './../../common/Chat/GetConversationMessages';
import SendMessage from './../../common/Chat/SendMessage';
import JourneyList from './../../common/reusableComponents/JourneyMessages/JourneyList';
import TransitionPanel from '../../../ais_components/Transitions/TransitionPanel';
//import InsertMessages from './../../common/Chat/InsertMessages';
import GetEndChat from './../../common/Chat/GetEndChat';
import MESSAGE_CONSTANTS from './../../../config/journey-messages';
import CONSTANTS from './../../../utils/Constants';
import maskString from './../../../utils/mask-string';
// import LexObj from './../../../config/lexExport';
// import UserInput from "./UserInput";
import {
  isDeviceOrWeb,
  messageTime,
  getFormattedPopularName,
  getMaskedstr,
  todaysDate,
  multipleReplacePlaceHolders,
  getFormattedDeliveryDate,
  checkIfDeviceMakeModelExist,
  isServiceOrderPreviouslyCalled,
  getFormattedTotalFee,
  isIOS,
} from '../../../utils/utils';

import {
  setCaseType,
  setRetryPaymentAttempt,
  setRetryAddressAttempt,
  setPaymentChargeAttempt,
  savePayload,
  setVisitorId,
  cancelServiceRequestInit,
  setJourneyCmpltd,
  doverifyNRICInit,
  updateTransitionPanel,
  getPCITokenInit,
  createChatEnquiry,
  submitEnquiryDetails,
  getServiceFee,
  handleSmartStockSelection,
} from '../../../actions/Dashboard/doFindAgreements';
import {
  SAVE_CONVERSATION,
  SET_CHAT_REQUEST_ID,
  SET_TRIAGE_SUCCESSFUL_FLAG,
  SAVE_DEFECT_OPTION,
  SAVE_CUSTOMERNAME,
  SET_CHAT_REASON,
} from '../../../actions/Dashboard/actionTypes';
import { createTask, fetchTask } from './../../../twilio/services/twilio';
import config from './../../../config/config';
import { getRealtimeStatistics } from './../../../twilio/services/twilio';
//import BotUserInput from './../../../ais_components/BotUserInput';
import EndChatRequest from './../../common/Chat/EndChatRequest';
import { MIXPANEL } from './../../../mixpanel/Mixpanel';
import EVENTLIST from './../../../mixpanel/Eventlist';
//AppSync implementation
import SendMsgToAppSync from '../../../ais_components/SendMsgToAppSync/SendMsgToAppSync';
import CreateChatAudit from './../../common/Chat/CreateChatAudit';
import CallUpdateChatRequest from '../../common/Chat/CallUpdateChatRequest';
import moment from 'moment';
import {
  formatCapacityFromDevice,
  formatColor,
  formatPhoneName,
} from '../../../ais_components/SmartStock/functions';

class webChatWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatAuditRequest: {},
      chatAbandoned: false,
      showLoader: true,
      chatInactiveTimeOut: false,
      messageList: [],
      requestedChatAssistance: false,
      listenUpdateChat: false,
      journeyMessageList: [],
      messageInsertCount: 0,
      requestType: null,
      source: CONSTANTS.SOURCE_USER_INPUT.REQUEST,
      // lexruntime: LexObj,
      showGetHelp: false,
      interactionType: 'Online',
      currentPage: null,
      chatReason: null,
      currentAction: null,
      actionMade: null,
      showServiceUnavailable: false,
      apiError: null,
      ccValidationError: null,
      visitor: null,
      showSRUserInput: false,
      showEnquiryUserInput: false,
      showMDNUserInput: false,
      showNAMEUserInput: false,
      showNRICUserInput: false,
      paymentRetryAttempt: false,
      // lexUserId: config.Lex.lexUserId + Date.now(),
      showBotInput: false,
      botCalledFrom: null,
      updateChatRequest: null,
      userDisconnectedMessage: null,
      userMDN: null,
      chatSupportEnded: false,
      wrapUpCode: null,
      lastActivity: EVENTLIST.MAIN_MENU_OPTIONS_DISPLAYED,
      lastUpdatedTime: new Date(),
    };

    //this._onGetHelpClick = this._onGetHelpClick.bind(this);
    this._getNewConversation = this._getNewConversation.bind(this);
    this._endChat = this._endChat.bind(this);
    this._updateMessageInsertCount = this._updateMessageInsertCount.bind(this);
    this._addMessagesInJourneyList = this._addMessagesInJourneyList.bind(this);
    this._farewellMessages = this._farewellMessages.bind(this);
    // this._selectSteppedMessages = this._selectSteppedMessages.bind(this);
    this._stepCheckBackorderCase = this._stepCheckBackorderCase.bind(this);
    this._stepCheckRequestType = this._stepCheckRequestType.bind(this);
    this._stepCheckCosmeticDamages = this._stepCheckCosmeticDamages.bind(this);
    this._showLine = this._showLine.bind(this);
    this.redirectToLandingPage = this.redirectToLandingPage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let visitorUuId = uuid();
    let buffervisitorId = new Buffer(visitorUuId.replace(/-/g, ''), 'hex');
    let visitorId = buffervisitorId.toString('hex').toUpperCase();

    let lang = this.props.language === 'th' ? 'th-TH' : 'en-US';
    const userAgent = browser();
    if (
      !this.state.visitor &&
      nextProps.supportedCulture &&
      nextProps.IpAddress &&
      nextProps.customerName
    ) {
      let visitor = {
        input: {
          visitorCognitoId: nextProps.visitorCognitoId,
          visitorId: visitorId,
          userName: nextProps.customerName.lastName
            ? nextProps.customerName.fullName
            : nextProps.customerName.firstName,
          clientId: nextProps.clientId,
          languageCode: lang,
          browserAgent: JSON.stringify(userAgent),
          ipAddress: nextProps.IpAddress,
          startTimestamp: new Date(),
          journeyStatus: CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          source: this.props.visitSource || null,
        },
      };
      console.log('Created Visitor ID: ', visitorId);
      MIXPANEL.register({ 'Visitor ID': visitorId });
      //let obj = {};
      //obj[PROPERTIES.VISITOR_ID] = visitorId;
      MIXPANEL.track(EVENTLIST.JOURNEY_STARTED);
      this.props.setVisitorId(visitorId);
      this.setState({
        visitor: visitor,
      });
    }
  }

  _showLine(that, i, messageArr, max, callback, delay = CONSTANTS.DEFAULT_MSG_DELAY) {
    setTimeout(function () {
      let msg = messageArr[i];

      // console.log(msg);

      if (msg) {
        msg.time = messageTime();
        if (msg.type === CONSTANTS.TEXT) {
          if (msg.data.text.length > CONSTANTS.BIG_MSG_CHARS) {
            delay = CONSTANTS.BIG_MSG_DELAY;
          } else if (msg.data.text.length > CONSTANTS.MEDIUM_MSG_CHARS) {
            delay = CONSTANTS.MEDIUM_MSG_DELAY;
          } else {
            delay = CONSTANTS.DEFAULT_MSG_DELAY;
          }
        }
      }
      if (i < max) {
        that.addMessageInJourneyList([msg]);
        /* that.setState({
          journeyMessageList: [...that.state.journeyMessageList,
            msg
          ]
        }); */
        that._showLine(that, i + 1, messageArr, max, callback, delay);
      }
      if (i === max - 1 && callback) {
        if (msg.type === CONSTANTS.REQUEST_OPTIONS) {
          callback();
        } else {
          setTimeout(function () {
            callback();
          }, delay);
        }
      }
    }, delay);
  }

  _stepCheckBackorderCase() {
    let { backOrderCase } = this.props;

    if (backOrderCase) {
      let msgArr = [
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.WAIT_FOR_ENROLLED_DEVICE,
          false
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, this._stepCheckRequestType, 100);
    } else {
      let { selectedDeliveryDate, selectedDeliverySlot } = this.props;
      let filteredArr = MESSAGE_CONSTANTS.SLOTS_MAPPING.filter(
        (item) => item.LABEL === selectedDeliverySlot
      );
      let content = multipleReplacePlaceHolders(
        MESSAGE_CONSTANTS.SHOW_DELIVERY_TIME_DATE,
        ['{Date}', '{Time Slot}'],
        [selectedDeliveryDate, selectedDeliverySlot]
      );
      let msgArr = [this.createMessage(CONSTANTS.TEXT, `system`, content, false)];
      this._showLine(this, 0, msgArr, msgArr.length, this._stepCheckRequestType, 100);
    }
  }

  _stepCheckRequestType() {
    let { caseType, isSimRequired } = this.props;
    if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
      let msgArr = [
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.SWAP_MAL_BACKORDER_CASE_RETURN_DEVICE,
          false
        ),
        this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.S_PEN_INFO, false),
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.SWAP_MAL_BACKORDER_CASE_RETURN_DEVICE1,
          false
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, this._stepCheckCosmeticDamages, 100);
    } else {
      if (isSimRequired) {
        let msgArr = [
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.SIM_REQUIRED, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.RETURN_LOST_DEVICE_IF_FOUND,
            false
          ),
        ];
        this._showLine(this, 0, msgArr, msgArr.length, this._stepCheckCosmeticDamages, 100);
      } else {
        let msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.REPLACEMENT_BACKORDER_RETURN_DEVICE,
            false
          ),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.RETURN_LOST_DEVICE_IF_FOUND,
            false
          ),
        ];
        this._showLine(this, 0, msgArr, msgArr.length, this._stepCheckCosmeticDamages, 100);
      }
    }
  }

  _stepCheckCosmeticDamages() {
    if (this.props.caseType === CONSTANTS.MALFUNCTION || this.props.caseType === CONSTANTS.SWAP) {
      let msgArr = [
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.CHECK_COSMETIC_DAMAGES,
          false
        ),
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_DETAILS_SCRIPT,
          false
        ),
        this.createMessage(
          CONSTANTS.CONFIRM_DETAILS,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_DETAILS,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.CONFIRMED_DETAILS }
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
    } else {
      let msgArr = [
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.REPLACEMENT_CHECK_COSMETIC_DAMAGES,
          false
        ),
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_DETAILS_SCRIPT,
          false
        ),
        this.createMessage(
          CONSTANTS.CONFIRM_DETAILS,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_DETAILS,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.CONFIRMED_DETAILS }
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
    }
  }

  redirectToLandingPage(timeOut = 5000) {
    setTimeout(() => {
      window.location.replace(config.ApplicationURL);
    }, timeOut);
  }

  _setChatInactiveTimeOut = () => {
    this.setState({
      chatInactiveTimeOut: true,
      conversation: null,
      requestedChatAssistance: false,
    });
  };

  updateChatTask = (task) => {
    console.log('calling updateChat Task', task);
    let fetchTaskStatusInterval = setInterval(() => {
      this.fetchTaskStatus(task.sid);
    }, 21000);

    let updateChatTask = {
      input: {
        requestId: this.state.chatRequest.input.requestId,
        taskId: task.sid,
      },
    };

    console.log('updateChatTask ===>', updateChatTask);

    this.setState({
      updateChatTask,
      intervalName: fetchTaskStatusInterval,
    });
  };

  twilioTaskHandler = () => {
    let lang = this.props.language === 'th' ? 'th-TH' : 'en-US';
    if (this.props.workspace) {
      createTask(
        this.props.workspace,
        CONSTANTS.SKILLID,
        lang,
        this.state.visitor.input.visitorId,
        this.state.chatRequest.input.requestId,
        this.updateChatTask
        // this.createChatRequest
      );
    }
  };

  // createChatRequest = (task, chatRequestId) => {
  createChatRequest = (visitorId) => {
    let { customerName, interactionLineId } = this.props;
    let { userName } = this.state.visitor.input;
    let latestCustomerName =
      customerName.fullName && userName !== customerName.fullName
        ? customerName.fullName
        : userName;
    let lang = this.props.language === 'th' ? 'th-TH' : 'en-US';

    const chatRequestIdUuId = uuid();
    const bufferChatRequestId = new Buffer(chatRequestIdUuId.replace(/-/g, ''), 'hex');
    const chatRequestId = bufferChatRequestId.toString('hex').toUpperCase();
    let requestStatus = this.state.wrapUpCode
      ? CONSTANTS.CHAT_REQUEST_STATUS.ABANDONED
      : CONSTANTS.CHAT_REQUEST_STATUS.INITIATED;
    let chatRequest = {
      input: {
        requestId: chatRequestId,
        visitorId,
        // taskId: task.sid,
        visitorName: latestCustomerName,
        interactionId: interactionLineId,
        customerId: 'customerId',
        //"caseNumber:"
        skillId: CONSTANTS.SKILLID,
        languageCode: lang,
        clientName: CONSTANTS.CLIENT_KEY.toUpperCase(),
        requestStatus: requestStatus,
        chatReason: this.state.chatReason,
        requestType: this.state.requestType,
        engagementType: 'DP',
        startTimestamp: new Date(),
        caseNumber: this.props.customerCaseNumber,
        browserAgent: JSON.stringify(browser()),
        wrapUpCode: this.state.wrapUpCode,
        requestChannel:
          isDeviceOrWeb() === 'web'
            ? CONSTANTS.REQUEST_CHANNELS.WEB
            : CONSTANTS.REQUEST_CHANNELS.MOBILE_WEB,
      },
    };

    let chatAuditRequest = {
      input: {
        requestId: chatRequestId,
        visitorId: visitorId,
        createdAt: new Date(),
        auditLog: `Chat ${CONSTANTS.CHAT_REQUEST_STATUS.INITIATED}`,
        // expertName: userName
      },
    };

    if (this.props.customerContact) chatRequest.input.mdn = this.props.customerContact;

    console.log('Created ChatRequest: ', chatRequest);

    this.setState({
      // intervalName: fetchTaskStatusInterval,
      chatRequest,
      chatAuditRequest,
    });
    this.props.savePayload(SET_CHAT_REQUEST_ID, chatRequest.input.requestId);
  };

  fetchTaskStatus = (taskSid) => {
    fetchTask(this.props.workspace, taskSid, this._setTaskStatus);
  };

  _setTaskStatus = (currentStatus) => {
    if (CONSTANTS.TWILIO_TASKSTATUS.CANCELED === currentStatus) {
      let chatAuditRequest = {
        input: {
          requestId: this.state.chatRequest.input.requestId,
          visitorId: this.state.chatRequest.input.visitorId,
          createdAt: new Date(),
          auditLog: 'Chat Abandoned',
          // expertName: userName
        },
      };

      let chatRequest = {
        input: {
          requestId: this.state.chatRequest.input.requestId,
          requestStatus: CONSTANTS.CHAT_REQUEST_STATUS.ABANDONED,
          endTimestamp: new Date(),
        },
      };

      this.setState({
        lastActivity:
          EVENTLIST.CHAT_DISCONNECTED + EVENTLIST.ALL_AGENTS_BUSY + this.state.chatReason,
        lastUpdatedTime: new Date(),
      });

      if (this.state.generalEnquiryCase) {
        this.addMessageInJourneyList([
          /* this.createMessage(CONSTANTS.ALL_AGENT_BUSY, `system`, MESSAGE_CONSTANTS.ALL_AGENT_BUSY, true, { source: CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_BUSY }), */
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.GENERAL_ENQUIRY_SUBMITTED,
            false
          ),
          this.createMessage(
            CONSTANTS.JOURNEY_ENDED,
            `system`,
            { endReason: CONSTANTS.ENDREASON_LIST.AGENTS_BUSY },
            true
          ),
        ]);
        // this.props.updateTransitionPanel(100, MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED, ``, ``)
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.label,
          MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED
        );
        this.redirectToLandingPage(); // redirect to main page
      } else {
        if (this.props.selectedDefectOption === CONSTANTS.GENERAL_ENQUIRY) {
          this.addMessageInJourneyList([
            this.createMessage(
              CONSTANTS.ALL_AGENT_BUSY,
              `system`,
              MESSAGE_CONSTANTS.ALL_AGENT_BUSY,
              true,
              { source: CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_BUSY }
            ),
            this.createMessage(
              CONSTANTS.JOURNEY_ENDED,
              `system`,
              { endReason: CONSTANTS.ENDREASON_LIST.AGENTS_BUSY },
              true
            ),
            this.createMessage(
              CONSTANTS.REQUEST_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.REQUEST_OPTIONS,
              true,
              { enquiryType: 'request' }
            ),
          ]);
        } else {
          this.props.createChatEnquiry(
            CONSTANTS.STAFF_BUSY,
            CONSTANTS.STAFF_BUSY_INQUIRY,
            CONSTANTS.ENQUIRY_SUB_REASON.STAFF_BUSY,
            this.props.customerContact
          );
          this.addMessageInJourneyList([
            this.createMessage(
              CONSTANTS.ALL_AGENT_BUSY,
              `system`,
              MESSAGE_CONSTANTS.ALL_AGENT_BUSY,
              true,
              { source: CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_BUSY }
            ),
            this.createMessage(
              CONSTANTS.JOURNEY_ENDED,
              `system`,
              { endReason: CONSTANTS.ENDREASON_LIST.AGENTS_BUSY },
              true
            ),
          ]);
        }
      }

      this.setState({
        updateChatRequest: chatRequest,
        generalEnquiryCase: false,
        showLoader: false,
        chatAuditRequest: chatAuditRequest,
        chatAbandoned: true,
      });

      clearInterval(this.state.intervalName);
    } else {
      if (CONSTANTS.TWILIO_TASKSTATUS.ASSIGNED === currentStatus) {
        clearInterval(this.state.intervalName);
      }
    }
  };

  _getNewConversation(conversation) {
    let chatAuditRequest = {
      input: {
        requestId: this.state.chatRequest.input.requestId,
        visitorId: this.state.chatRequest.input.visitorId,
        createdAt: new Date(),
        auditLog: 'Chat Acknowledged',
      },
    };
    MIXPANEL.track(EVENTLIST.CHAT_CONNECTED);
    this.setState({
      conversation: conversation,
      chatAuditRequest: chatAuditRequest,
      lastActivity: EVENTLIST.CHAT_CONNECTED + this.state.chatReason,
      lastUpdatedTime: new Date(),
    });
    this.props.savePayload(SAVE_CONVERSATION, conversation);
  }

  _updateMessageInsertCount(updatedCount) {
    // console.log("_updateMessageInsertCount: ", updatedCount);
    this.setState({
      messageInsertCount: updatedCount,
    });
  }

  _addMessagesInJourneyList(newMessage) {
    this.setState({
      journeyMessageList: [...this.state.journeyMessageList, newMessage],
    });
  }

  /* Function to create message objects
 Params -
  messageType - String,
  author - String,
  data - String / Component,
  isComponent - boolean,
*/
  createMessage = (type, author, data, isComponent, dataprops) => {
    let authorName = '';
    let sourceName = '';

    if (_.lowerCase(author) === `user`) {
      authorName = `${this.props.customerName.fullName}`;
      sourceName = CONSTANTS.SOURCE_ENUM.USER;
    } else if (author === CONSTANTS.SOURCE_ENUM.INTERNAL) {
      authorName = CONSTANTS.SYSTEM;
      sourceName = CONSTANTS.SOURCE_ENUM.INTERNAL;
    } else {
      authorName = CONSTANTS.SYSTEM;
      sourceName = CONSTANTS.SOURCE_ENUM.SYSTEM;
    }

    if (isComponent || type !== CONSTANTS.TEXT) {
      return _.pickBy(
        {
          messageId: uuid(),
          type,
          author: authorName,
          source: sourceName,
          dataprops: dataprops,
          data,
          time: messageTime(),
        },
        _.identity
      );
    } else {
      return _.pickBy(
        {
          messageId: uuid(),
          type,
          author: authorName,
          source: sourceName,
          data: _.isObject(data) ? data : { text: data },
          time: messageTime(),
        },
        _.identity
      );
    }
  };

  addMessageInJourneyList = (messageList) => {
    this.setState({
      journeyMessageList: [...this.state.journeyMessageList, ...messageList],
    });
  };
  _endChat = (source, typedText, updatedChatRequest) => {
    if (CONSTANTS.CHAT_REQUEST_STATUS.TRANSFER_ABANDONED === updatedChatRequest.requestStatus) {
      let chatAuditRequest = {
        input: {
          requestId: this.state.parentConversation.requestId,
          visitorId: this.state.visitor.input.visitorId,
          createdAt: new Date(),
        },
      };
      if (
        this.state.parentChatRequest.input.requestId === updatedChatRequest.transferredRequestId &&
        this.state.parentConversation.requestId === updatedChatRequest.transferredRequestId
      ) {
        chatAuditRequest.input[
          'auditLog'
        ] = `Chat ${CONSTANTS.CHAT_REQUEST_STATUS.TRANSFER_ABANDONED}`;
        this.setState({
          //conversation: null,
          chatRequest: this.state.parentChatRequest,
          conversation: this.state.parentConversation,
          pendingMsgsLoaded: false,
          callChatRequestStatus: false,
          //chatRequest.input.requestId = updatedChatRequest.transferredRequestId
          requestedChatAssistance: true,
          //  showLoginForm: false,
          showLoader: false,
          transferChat: true,
          chatAuditRequest,
        });
      }
    } else if (
      updatedChatRequest.isTransferred &&
      updatedChatRequest.requestStatus === CONSTANTS.CHAT_REQUEST_STATUS.TRANSFERRING
    ) {
      this.setState({
        parentChatRequest: this.state.chatRequest,
        parentConversation: this.state.conversation,
        conversation: null,
        chatRequest: {
          input: {
            requestId: updatedChatRequest.transferredRequestId,
          },
        },
        requestedChatAssistance: true,
        showLoader: true,
        transferChat: true,
        listenUpdateChat: true,
      });
    } else if (
      updatedChatRequest.isTransferred &&
      updatedChatRequest.requestStatus === CONSTANTS.CHAT_REQUEST_STATUS.NOTRESOLVED
    ) {
      const that = this;
      let chatAuditRequest = {
        input: {
          requestId: this.state.parentConversation.requestId,
          visitorId: that.state.visitor.input.visitorId,
          createdAt: new Date(),
          auditLog: CONSTANTS.CHAT_REQUEST_STATUS.NOTRESOLVED,
          // expertName: userName
        },
      };
      this.setState({
        conversation: null,
        callChatRequestStatus: false,
        pendingMsgsLoaded: false,
        chatRequest: {
          input: {
            requestId: updatedChatRequest.transferredRequestId,
          },
        },
        requestedChatAssistance: true,
        //  showLoginForm: false,
        showLoader: false,
        transferChat: true,
        chatAuditRequest: chatAuditRequest,
      });
    } else if (updatedChatRequest.endTimestamp || (
      updatedChatRequest.requestStatus === CONSTANTS.CHAT_REQUEST_STATUS.ENDED || 
      updatedChatRequest.requestStatus === CONSTANTS.CHAT_REQUEST_STATUS.AGENT_ENDED
    )) {
      if (updatedChatRequest.endTimestamp && updatedChatRequest.requestStatus === 'Interacting') {
        return
      }
      let wrapUpCode = updatedChatRequest.wrapUpCode;
      this.setState({
        conversation: null,
        chatRequest: null,
        requestedChatAssistance: false,
        showGetHelp: true,
        callChatRequestStatus: false,
        transferChat: false,
        listenUpdateChat: false,
      });
      this.props.savePayload(SAVE_CONVERSATION, null);
      // mark end of the chat support persisting original source
      this._setChatSupportEnded(source, typedText, wrapUpCode);
    }
  };

  _setChatSupportEnded = (source, typedText, resolutionCode) => {
    this._resumeFlowAsPerResolutionStatus(source, typedText, resolutionCode);
  };

  _resumeFlowAsPerResolutionStatus = (source, typedText, resolutionCode) => {
    //Scenarios - Agent marked Resolved
    if (resolutionCode === CONSTANTS.CHAT_REQUEST_STATUS.RESOLVED) {
      MIXPANEL.track(EVENTLIST.CHAT_DISCONNECTED, { Status: 'Resolved' });
      // console.log("Chat Ended with Status: ", resolutionCode);
      // Resume online journey
      this.setState({
        lastActivity: EVENTLIST.CHAT_RESOLVED + this.state.chatReason,
        lastUpdatedTime: new Date(),
      });
      if (this.state.chatReason === CONSTANTS.CHAT_REASON.SERVICE_UNAVAILABLE) {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.CHAT_RESOLVED_SERVICE_UNAVAILABLE,
            false
          ),
        ]);
        return true;
      }
      if (this.state.chatReason === 'Invalid IMEI') {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.SUBMIT_IMEI_NUMBER,
              `system`,
              MESSAGE_CONSTANTS.SUBMIT_IMEI_NUMBER,
              true
            ),
          ],
          lastActivity: EVENTLIST.IMEI_INPUT,
          lastUpdatedTime: new Date(),
        });
        return true;
      }
      if (this.state.chatReason === 'Invalid NRIC') {
        this.setState({
          showMDNUserInput: false,
          showNRICUserInput: true,
          lastActivity: EVENTLIST.NRIC_INPUT,
          lastUpdatedTime: new Date(),
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.TEXT,
              `system`,
              MESSAGE_CONSTANTS.NATIONAL_ID_REQUEST,
              false
            ),
            this.createMessage(
              CONSTANTS.ASK_FOR_NRIC,
              `system`,
              MESSAGE_CONSTANTS.ASK_FOR_NRIC,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.NRIC,
                inputState: CONSTANTS.ENTERED_NRIC,
              }
            ),
          ],
        });
        return true;
      }
      if (source === CONSTANTS.SOURCE_USER_INPUT.WEB_CREDITCARD_FORM_REATTEMPT) {
        this.props.setRetryPaymentAttempt(true);
        this._updateJourneyList(source);
      } else if (source === CONSTANTS.SOURCE_USER_INPUT.PAYMENT_CHARGE_FORM_REATTEMPT) {
        this.props.setPaymentChargeAttempt(true);
        this._updateJourneyList(source);
      } else if (source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION) {
        this.props.setRetryAddressAttempt(true);
        this._updateJourneyList(source, typedText);
      } else if (CONSTANTS.GENERAL_ENQUIRY_CREATED_THANKS_FOR_CONTACTING === source) {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.THANKS_FOR_CONTACTING,
            false
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.label,
          '',
          ''
        );
        // this.props.updateTransitionPanel(100, MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED, ``, ``);
        if (CONSTANTS.GENERAL_ENQUIRY_CREATED_THANKS_FOR_CONTACTING === source) {
          this.addMessageInJourneyList([
            this.createMessage(
              CONSTANTS.REQUEST_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.REQUEST_OPTIONS,
              true,
              { enquiryType: 'request' }
            ),
          ]);
        } else {
          this.redirectToLandingPage(3000);
        }
      } else if (source === CONSTANTS.SOURCE_USER_INPUT.NAME) {
        this._updateJourneyList(CONSTANTS.MDN_VERIFIED);
        this.props.savePayload(SAVE_CUSTOMERNAME, this.props.enrolledCustomerName);
      } else if (source === CONSTANTS.SOURCE_USER_INPUT.MDN) {
        this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.NOT_RESOLVED.MDN_NAME_NRIC);
      } /*else if (source === CONSTANTS.SOURCE_USER_INPUT.NRIC) {
        this.props.doverifyNRICInit({
          VerifyAgreementsParameters: {
            MobileDeviceNumber: this.props.customerContact || '',
            PassportNumber: 'AGENT_VERIFIED',
            FailedAttempt: this.props.verificationFailedAttempt,
            CustomerVerificationMethod: 'PASSNBR',
            SessionId: this.props.cacheId,
            isAgentVerified: true,
          },
          userInput: {
            handlePrevSubmit: 1,
            userInput: CONSTANTS.ENTERED_NRIC,
            source: CONSTANTS.SOURCE_USER_INPUT.NRIC,
            value: '',
          },
          DetermineIncidentPaths: {
            SessionId: this.props.cacheId,
            caseType: this.props.caseType,
          },
        });
        // this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.NOT_RESOLVED.MDN_NAME_NRIC);
      }*/ else if (source === CONSTANTS.SOURCE_USER_INPUT.REQUEST) {
        this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESUME_REQUEST_OPTIONS);
      } else if (source === CONSTANTS.SOURCE_USER_INPUT.ENQUIRY) {
        this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESUME_ENQUIRY_OPTIONS);
      } else if (source === CONSTANTS.SOURCE_USER_INPUT.BOT_INPUT_SERVICE_FEE) {
        this._updateJourneyList(this.state.botCalledFrom.source, this.state.botCalledFrom.action);
      } else if (source === CONSTANTS.SOURCE_USER_INPUT.FMIP) {
        this._updateJourneyList(CONSTANTS.MERGE_MALFUNCTION_FLOW);
      } else if (
        source === CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY ||
        source === CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS ||
        source === CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY
      ) {
        this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.ASK_FOR_ENQUIRY_CONFIRMATION);
      } else {
        this._updateJourneyList(source, typedText);
      }
    } //Scenarios - Agent marked Unresolved
    else if (resolutionCode === CONSTANTS.CHAT_REQUEST_STATUS.USER_DISCONNECTED) {
      this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.USER_DISCONNECTED);
      this.setState({
        lastActivity: EVENTLIST.USER_DISCONNECTED,
        lastUpdatedTime: new Date(),
      });
    } else if (resolutionCode === CONSTANTS.CHAT_REQUEST_STATUS.AGENT_DISCONNECTED) {
      this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.AGENT_DISCONNECTED);
      this.setState({
        lastActivity: EVENTLIST.AGENT_DISCONNECTED,
        lastUpdatedTime: new Date(),
      });
    } else {
      MIXPANEL.track(EVENTLIST.CHAT_DISCONNECTED, { Status: 'Unresolved' });
      this.setState({
        lastActivity: EVENTLIST.CHAT_NOT_RESOLVED + this.state.chatReason,
        lastUpdatedTime: new Date(),
        journeyStatus: CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
      });
      // console.log("Chat Ended with Status: ", resolutionCode);
      if (source === CONSTANTS.SOURCE_USER_INPUT.MDN) {
        this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.NOT_RESOLVED.MDN_NAME_NRIC);
      } else if (source === CONSTANTS.SOURCE_USER_INPUT.NRIC) {
        this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.NOT_RESOLVED.MDN_NAME_NRIC);
      } else {
        this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.NOT_RESOLVED.GENERIC);
      }
    }
  };

  _farewellMessages() {
    if (this.props.holds && this.props.holds.length && !this.props.backOrderCase) {
      let msgArr = [
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.NOTIFY_ON_AVAILABILITY,
          false
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
    } else if (this.props.backOrderCase) {
      let msgArr = [
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.NOTIFY_ON_AVAILABILITY,
          false
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
    } else {
      let msgArr = [
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.BE_PRESENT_AT_ADDRESS,
          false
        ),
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.THANK_YOU_FOR_CONNECTING,
          false
        ),
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.SURVEY_CONFIRMATION.CONTENT1,
          false
        ),
        this.createMessage(
          CONSTANTS.ASK_FOR_FEEDBACK,
          `system`,
          MESSAGE_CONSTANTS.ASK_FOR_FEEDBACK,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION }
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
    }
  }

  _cancelPreviousRequestNCreateNewOne(caseType, defect) {
    if (this.props.resumeCanceled) {
      let ProcessIncidentParameters = {
        SessionId: this.props.cacheId,
        Incident: {
          FailureDescriptiveText: defect,
          IncidentType: caseType,
        },
      };
      this.props.cancelServiceRequestInit(this.props.cacheId, ProcessIncidentParameters);
    }
  }

  _capturePerilFromUserInResumeCase(caseType, defect) {
    if (this.props.resumePoint && this.props.resumePoint === 'CaptureIncidentDetails') {
      if (caseType === CONSTANTS.REPLACEMENT) {
        let content = checkIfDeviceMakeModelExist(this.props.deviceDetails)
          ? this.createMessage(
              CONSTANTS.CURRENT_DEVICE_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
              true,
              { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
            )
          : this.createMessage(
              CONSTANTS.CONFIRM_CURRENT_DEVICE,
              `system`,
              MESSAGE_CONSTANTS.CONFIRM_CURRENT_DEVICE,
              true,
              { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_SELECTION }
            );

        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, defect, false),
          content,
        ]);
      } else {
        if (caseType === CONSTANTS.MALFUNCTION) {
          this.addMessageInJourneyList([
            this.createMessage(CONSTANTS.TEXT, 'user', defect, false),
            this.createMessage(
              CONSTANTS.DEVICE_NOT_WORKING,
              `system`,
              MESSAGE_CONSTANTS.MALFUNCTION_OPTIONS,
              true,
              { enquiryType: 'malfunction' }
            ),
          ]);
        } else if (caseType === CONSTANTS.SWAP) {
          this.addMessageInJourneyList([
            this.createMessage(CONSTANTS.TEXT, 'user', defect, false),
            this.createMessage(
              CONSTANTS.DEVICE_IS_BROKEN,
              `system`,
              MESSAGE_CONSTANTS.SWOP_OPTIONS,
              true,
              { enquiryType: 'swap' }
            ),
          ]);
        }
      }
    }
  }

  _capturePerilFromUserInResumeCaseSR(caseType, defect) {
    if (caseType === CONSTANTS.REPLACEMENT) {
      let content = checkIfDeviceMakeModelExist(this.props.deviceDetails)
        ? this.createMessage(
            CONSTANTS.CURRENT_DEVICE_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
          )
        : this.createMessage(
            CONSTANTS.CONFIRM_CURRENT_DEVICE,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_CURRENT_DEVICE,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_SELECTION }
          );

      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, defect, false),
        content,
      ]);
    } else {
      if (caseType === CONSTANTS.MALFUNCTION) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, 'user', defect, false),
          this.createMessage(
            CONSTANTS.DEVICE_NOT_WORKING,
            `system`,
            MESSAGE_CONSTANTS.MALFUNCTION_OPTIONS,
            true,
            { enquiryType: 'malfunction' }
          ),
        ]);
      } else if (caseType === CONSTANTS.SWAP) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, 'user', defect, false),
          this.createMessage(
            CONSTANTS.DEVICE_IS_BROKEN,
            `system`,
            MESSAGE_CONSTANTS.SWOP_OPTIONS,
            true,
            { enquiryType: 'swap' }
          ),
        ]);
      }
    }
  }

  handleIOSOffLine = () => {
    let msg = '';
    if (document.hidden) {
      // the page has been hidden
      msg = this.createMessage(
        CONSTANTS.TEXT,
        CONSTANTS.SOURCE_ENUM.INTERNAL,
        `${MESSAGE_CONSTANTS.USER_OFFLINE} ${moment().format('YYYY-MM-DD HH:mm:ss')}`
      );
    } else {
      // the page has become visible
      msg = this.createMessage(
        CONSTANTS.TEXT,
        CONSTANTS.SOURCE_ENUM.INTERNAL,
        `${MESSAGE_CONSTANTS.USER_ONLINE}  ${moment().format('YYYY-MM-DD HH:mm:ss')}`
      );
    }
    this.addMessageInJourneyList([msg]);
  };

  componentDidMount() {
    this._updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.REQUEST, this.props.sourceAction);

    if (isIOS()) {
      document.addEventListener('visibilitychange', this.handleIOSOffLine);
    }
  }

  componentWillUnmount() {
    if (isIOS()) document.removeEventListener('visibilitychange', this.handleIOSOffLine);
  }

  _updateJourneyList = (source = null, typedText, author = 'user', error) => {
    console.log('Source received: ', source);

    this.setState({
      currentPage: source,
      currentAction: typedText,
    });

    if (!source && typedText) {
      this.addMessageInJourneyList([this.createMessage(CONSTANTS.TEXT, author, typedText, false)]);
    }

    if (CONSTANTS.SOURCE_USER_INPUT.ENQUIRY === source) {
      let reqType = null;

      let elementFound = _.find(MESSAGE_CONSTANTS.ENQUIRY_OPTIONS.buttons, {
        text: typedText,
      });
      reqType = elementFound && elementFound.name;
      if (reqType === CONSTANTS.REPLACEMENT) {
        MIXPANEL.track(EVENTLIST.PERIL_SELECTION, {
          'Peril Type': 'Replacement',
        });
        this.setState({
          lastActivity: EVENTLIST.PERIL_SELECTION + '-Replacement',
          lastUpdatedTime: new Date(),
        });
      } else if (reqType === CONSTANTS.SWAP) {
        MIXPANEL.track(EVENTLIST.PERIL_SELECTION, { 'Peril Type': 'Swap' });
        this.setState({
          lastActivity: EVENTLIST.PERIL_SELECTION + '-Replacement',
          lastUpdatedTime: new Date(),
        });
      } else if (reqType === CONSTANTS.MALFUNCTION) {
        MIXPANEL.track(EVENTLIST.PERIL_SELECTION, {
          'Peril Type': 'Malfunction',
        });
        this.setState({
          lastActivity: EVENTLIST.PERIL_SELECTION + '- Malfunction',
          lastUpdatedTime: new Date(),
        });
      }

      this.props.setCaseType(reqType);

      this.props.savePayload(SAVE_DEFECT_OPTION, typedText);

      this._cancelPreviousRequestNCreateNewOne(reqType, typedText);

      if (
        !this.props.serviceRequestType ||
        this.props.serviceRequestType === 'StartServiceRequest'
      ) {
        MIXPANEL.time_event(EVENTLIST.MDN_INPUT);
        this.setState({
          showEnquiryUserInput: false,
          showMDNUserInput: true,
          requestType: reqType,
          lastActivity: EVENTLIST.MDN_INPUT,
          lastUpdatedTime: new Date(),
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.ASK_FOR_MDN,
              `system`,
              MESSAGE_CONSTANTS.ASK_FOR_MDN,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.MDN,
                inputState: CONSTANTS.ENTERED_MDN,
              }
            ),
          ],
        });
      } else {
        this.setState({
          showEnquiryUserInput: false,
          requestType: reqType,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          ],
        });
      }

      if (this.props.resumePoint && this.props.resumePoint === 'CaptureIncidentDetails') {
        this._capturePerilFromUserInResumeCase(reqType, typedText);
        // return false;
      }

      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.ENQUIRY.peril.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.ENQUIRY.peril.label,
        '',
        typedText
      ); // 3%
    } else if (CONSTANTS.SOURCE_USER_INPUT.GET_STARREQ === source) {
      let elementFound = _.find(MESSAGE_CONSTANTS.GET_STARREQ.buttons, {
        text: typedText,
      });
      console.log('elementFound', elementFound);
      if (elementFound) {
        const newMessages = this.state.journeyMessageList.slice();
        this.props.savePayload(SAVE_DEFECT_OPTION, elementFound.name);
        if (elementFound.name === CONSTANTS.CREATE_A_NEW_REQUEST) {
          MIXPANEL.track(EVENTLIST.CREATE_NEW_SERVICE_REQUEST);
          this.setState({
            showSRUserInput: false,
            journeyMessageList: [
              ...newMessages,
              this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
              this.createMessage(
                CONSTANTS.ENQUIRY_OPTIONS,
                `system`,
                MESSAGE_CONSTANTS.ENQUIRY_OPTIONS,
                true,
                { enquiryType: 'replacement' }
              ),
            ],
            showEnquiryUserInput: true,
          });
          this.props.updateTransitionPanel(
            MESSAGE_CONSTANTS.STAGE_NAME.ENQUIRY.request.percentage,
            MESSAGE_CONSTANTS.STAGE_NAME.ENQUIRY.request.label,
            '',
            typedText
          );
          return false;
        } else if (elementFound.name === CONSTANTS.RESUME_EXISTING_REQUEST) {
          MIXPANEL.track(EVENTLIST.RESUME_EXISTING_SERVICE_REQUEST);
          this.setState({
            lastActivity: EVENTLIST.RESUME_EXISTING_SERVICE_REQUEST,
            lastUpdatedTime: new Date(),
          });
        }
        this.setState({
          showSRUserInput: false,
          showMDNUserInput: true,
          journeyMessageList: [
            ...newMessages,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.ASK_FOR_MDN,
              `system`,
              MESSAGE_CONSTANTS.RESUME_CASE_ASK_FOR_MDN,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.MDN,
                inputState: CONSTANTS.ENTERED_MDN,
              }
            ),
          ],
        });
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.GET_MOREINFO === source) {
      let elementFound = _.find(MESSAGE_CONSTANTS.GET_MOREINFO.buttons, {
        text: typedText,
      });
      console.log('elementFound', elementFound);
      if (elementFound) {
        const newMessages = this.state.journeyMessageList.slice();
        this.props.savePayload(SAVE_DEFECT_OPTION, elementFound.name);
        if (elementFound.name === CONSTANTS.GENERAL_ENQUIRY) {
          this.props.createChatEnquiry(
            CONSTANTS.GE_INQUIRY_TYPE,
            CONSTANTS.GE_INQUIRY_FAQ,
            CONSTANTS.ENQUIRY_SUB_REASON.FAQ,
            config.EnquiryMDN
          );
          MIXPANEL.track(EVENTLIST.GENERAL_ENQUIRY);
          this.setState({
            showSRUserInput: false,
            showMDNUserInput: true,
            showEnquiryUserInput: false,
            lastActivity: EVENTLIST.GENERAL_ENQUIRY,
            lastUpdatedTime: new Date(),
            journeyMessageList: [
              ...newMessages,
              this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
              this.createMessage(CONSTANTS.FAQ_PAGE, `system`, MESSAGE_CONSTANTS.FAQ_PAGE, true, {
                source: CONSTANTS.FAQ_PAGE,
              }),
            ],
          });
          return;
        } else if (elementFound.name === CONSTANTS.SUBSCRIPTION) {
          MIXPANEL.track(EVENTLIST.SUBSCRIPTION);
        }
        this.setState({
          showSRUserInput: false,
          showMDNUserInput: true,
          journeyMessageList: [
            ...newMessages,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.ASK_FOR_MDN,
              `system`,
              MESSAGE_CONSTANTS.RESUME_CASE_ASK_FOR_MDN,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.MDN,
                inputState: CONSTANTS.ENTERED_MDN,
              }
            ),
          ],
        });
      }
    } else if (source === CONSTANTS.SOURCE_USER_INPUT.ENQUIRY_NOEXISTING_RESUMECASE) {
      let reqType = null;
      let elementFound = _.find(MESSAGE_CONSTANTS.ENQUIRY_OPTIONS.buttons, {
        text: typedText,
      });
      reqType = elementFound.name;
      if (reqType === CONSTANTS.REPLACEMENT) {
        MIXPANEL.track(EVENTLIST.PERIL_SELECTION, {
          'Peril Type': 'Replacement',
        });
        this.setState({
          lastActivity: EVENTLIST.PERIL_SELECTION + '-Replacement',
          lastUpdatedTime: new Date(),
        });
      } else if (reqType === CONSTANTS.SWAP) {
        MIXPANEL.track(EVENTLIST.PERIL_SELECTION, { 'Peril Type': 'Swap' });
        this.setState({
          lastActivity: EVENTLIST.PERIL_SELECTION + '-Swap',
          lastUpdatedTime: new Date(),
        });
      } else if (reqType === CONSTANTS.MALFUNCTION) {
        MIXPANEL.track(EVENTLIST.PERIL_SELECTION, {
          'Peril Type': 'Malfunction',
        });
        this.setState({
          lastActivity: EVENTLIST.PERIL_SELECTION + '-Malfunction',
          lastUpdatedTime: new Date(),
        });
      }

      this.props.setCaseType(reqType);

      this.props.savePayload(SAVE_DEFECT_OPTION, typedText);
      this._capturePerilFromUserInResumeCaseSR(reqType, typedText);
      // this._cancelPreviousRequestNCreateNewOne(reqType, typedText, CONSTANTS.SOURCE_USER_INPUT.ENQUIRY_NOEXISTING_RESUMECASE)
    } else if (CONSTANTS.SOURCE_USER_INPUT.REQUEST === source) {
      let reqType = null;

      let elementFound = _.find(MESSAGE_CONSTANTS.REQUEST_OPTIONS.buttons, {
        text: typedText,
      });
      console.log('elementFound', elementFound);
      reqType = elementFound && elementFound.name;

      if (elementFound) {
        const newMessages = this.state.journeyMessageList.slice(); //copy the array
        if (reqType === CONSTANTS.CREATE_A_NEW_REQUEST) {
          this.setState({
            showSRUserInput: false,
            journeyMessageList: [
              ...newMessages,
              this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
              this.createMessage(
                CONSTANTS.GET_STARREQ,
                `system`,
                MESSAGE_CONSTANTS.GET_STARREQ,
                true,
                { enquiryType: 'replacement' }
              ),
            ],
            showEnquiryUserInput: true,
            lastActivity: EVENTLIST.CREATE_NEW_SERVICE_REQUEST,
          });
          return false;
        } else if (elementFound.text === CONSTANTS.RESUME_EXISTING_REQUEST) {
          MIXPANEL.track(EVENTLIST.RESUME_EXISTING_SERVICE_REQUEST);
          this.setState({
            lastActivity: EVENTLIST.RESUME_EXISTING_SERVICE_REQUEST,
          });
        } else if (elementFound.text === CONSTANTS.ABOUT_MY_CURRENT_DELIVERY) {
          MIXPANEL.track(EVENTLIST.ABOUT_MY_CURRENT_DELIVERY);
          this.setState({
            lastActivity: EVENTLIST.ABOUT_MY_CURRENT_DELIVERY,
          });
        } else if (elementFound.text === CONSTANTS.LEAVE_AIS) {
          MIXPANEL.track(EVENTLIST.LEAVE_AIS);
          this.setState({
            lastActivity: EVENTLIST.LEAVE_AIS,
          });
        } else if (reqType === CONSTANTS.GET_HELP_MORE_INFO) {
          this.setState({
            showSRUserInput: false,
            journeyMessageList: [
              ...newMessages,
              this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
              this.createMessage(
                CONSTANTS.GET_MOREINFO,
                `system`,
                MESSAGE_CONSTANTS.GET_MOREINFO,
                true,
                { enquiryType: 'replacement' }
              ),
            ],
            showEnquiryUserInput: true,
          });
          return false;
        }
        this.props.savePayload(SAVE_DEFECT_OPTION, elementFound.name);
        if (elementFound.name === CONSTANTS.GENERAL_ENQUIRY) {
          this.props.createChatEnquiry(
            CONSTANTS.GE_INQUIRY_TYPE,
            CONSTANTS.GE_INQUIRY_FAQ,
            CONSTANTS.ENQUIRY_SUB_REASON.FAQ,
            config.EnquiryMDN
          );
          MIXPANEL.track(EVENTLIST.GENERAL_ENQUIRY);
          this.setState({
            showSRUserInput: false,
            showMDNUserInput: true,
            showEnquiryUserInput: false,
            lastActivity: EVENTLIST.GENERAL_ENQUIRY,
            journeyMessageList: [
              ...newMessages,
              this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
              this.createMessage(CONSTANTS.FAQ_PAGE, `system`, MESSAGE_CONSTANTS.FAQ_PAGE, true, {
                source: CONSTANTS.FAQ_PAGE,
              }),
            ],
          });
        } else {
          this.setState({
            showSRUserInput: false,
            showMDNUserInput: true,
            journeyMessageList: [
              ...newMessages,
              this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
              this.createMessage(
                CONSTANTS.ASK_FOR_MDN,
                `system`,
                MESSAGE_CONSTANTS.RESUME_CASE_ASK_FOR_MDN,
                true,
                {
                  source: CONSTANTS.SOURCE_USER_INPUT.MDN,
                  inputState: CONSTANTS.ENTERED_MDN,
                }
              ),
            ],
          });
        }
      } else {
        const newMessages = this.state.journeyMessageList.slice(); //copy the array
        if (typedText === 'WBKO') {
          this.setState({
            showSRUserInput: false,
            showMDNUserInput: false,
            journeyMessageList: [
              ...newMessages,
              this.createMessage(
                CONSTANTS.ASK_FOR_MDN,
                `system`,
                MESSAGE_CONSTANTS.RESUME_CASE_ASK_FOR_MDN,
                true,
                {
                  source: CONSTANTS.SOURCE_USER_INPUT.MDN,
                  inputState: CONSTANTS.ENTERED_MDN,
                }
              ),
            ],
          });
        }
        if (typedText === 'WBKO_RESCHEDULE') {
          this.setState({
            showSRUserInput: false,
            showMDNUserInput: false,
            journeyMessageList: [
              ...newMessages,
              this.createMessage(
                CONSTANTS.ASK_FOR_MDN,
                `system`,
                MESSAGE_CONSTANTS.RESUME_CASE_ASK_FOR_MDN,
                true,
                {
                  source: CONSTANTS.SOURCE_USER_INPUT.MDN,
                  inputState: CONSTANTS.ENTERED_MDN,
                }
              ),
            ],
          });
        }
      }
    } else if (CONSTANTS.WBKO === source) {
      const newMessages = this.state.journeyMessageList.slice(); //copy the array

      this.setState({
        showSRUserInput: false,
        showMDNUserInput: true,
        journeyMessageList: [
          ...newMessages,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.ASK_FOR_MDN,
            `system`,
            MESSAGE_CONSTANTS.RESUME_CASE_ASK_FOR_MDN,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.MDN,
              inputState: CONSTANTS.ENTERED_MDN,
            }
          ),
        ],
      });
    } else if (CONSTANTS.WBKO_RESCHEDULE === source) {
      const newMessages = this.state.journeyMessageList.slice(); //copy the array

      this.setState({
        showSRUserInput: false,
        showMDNUserInput: true,
        journeyMessageList: [
          ...newMessages,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.ASK_FOR_MDN,
            `system`,
            MESSAGE_CONSTANTS.RESUME_CASE_ASK_FOR_MDN,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.MDN,
              inputState: CONSTANTS.ENTERED_MDN,
            }
          ),
        ],
      });
    } else if (CONSTANTS.FAQ_PAGE === source) {
      if (typedText === 'Yes') {
        MIXPANEL.track(EVENTLIST.NEED_MORE_HELP, {
          'Need more help': 'Yes',
        });
        this.setState({
          lastActivity: EVENTLIST.NEED_MORE_HELP,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
          this.createMessage(
            CONSTANTS.CONFIRM_EXISTING_CUSTOMER,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_EXISTING_CUSTOMER,
            true,
            {
              source: CONSTANTS.CONFIRM_EXISTING_CUSTOMER,
            }
          ),
        ]);
      } else if (typedText === MESSAGE_CONSTANTS.CONFIRM_EXISTING_CUST) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.CONFIRM_EXISTING_CUSTOMER,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_EXISTING_CUSTOMER,
            true,
            {
              source: CONSTANTS.CONFIRM_EXISTING_CUSTOMER,
            }
          ),
        ]);
      } else {
        MIXPANEL.track(EVENTLIST.NEED_MORE_HELP, {
          'Need more help': 'No',
        });

        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.GE_THANK_YOU_MESSAGE,
            false
          ),
          this.createMessage(
            CONSTANTS.REQUEST_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.REQUEST_OPTIONS,
            true,
            { enquiryType: 'request' }
          ),
        ]);
      }
    } else if (CONSTANTS.CONFIRM_EXISTING_CUSTOMER === source) {
      if (typedText === 'Yes') {
        MIXPANEL.track(EVENTLIST.EXISTING_CUSTOMER_SELECTION, {
          'Existing customer': 'Yes',
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
          this.createMessage(
            CONSTANTS.ASK_FOR_MDN,
            `system`,
            MESSAGE_CONSTANTS.RESUME_CASE_ASK_FOR_MDN,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.MDN,
              inputState: CONSTANTS.ENTERED_MDN,
              generalEnquiryCase: true,
            }
          ),
        ]);
      } else {
        MIXPANEL.track(EVENTLIST.EXISTING_CUSTOMER_SELECTION, {
          'Existing customer': 'No',
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.NO_EXISTING_CUSTOMER,
            false
          ),
          this.createMessage(
            CONSTANTS.REQUEST_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.REQUEST_OPTIONS,
            true,
            { enquiryType: 'request' }
          ),
        ]);
      }
    } else if (CONSTANTS.GE_INQUIRY_REASON === source) {
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.GE_INQUIRY_REASON.CONTENT,
          false
        ),
        this.createMessage(
          CONSTANTS.GE_INQUIRY_REASON,
          `system`,
          MESSAGE_CONSTANTS.GE_INQUIRY_REASON,
          true,
          {
            source: CONSTANTS.GE_INQUIRY_REASON_SUBMITTED,
          }
        ),
      ]);
    } else if (CONSTANTS.GE_INQUIRY_REASON_SUBMITTED === source) {
      MIXPANEL.track(EVENTLIST.GE_INQUIRY_REASON, {
        GE_INQUIRY_REASON: typedText,
      });
      this.addMessageInJourneyList([this.createMessage(CONSTANTS.TEXT, `user`, typedText, false)]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.RESUME_REQUEST_OPTIONS === source) {
      let messages = [
        this.createMessage(
          CONSTANTS.REQUEST_OPTIONS,
          `system`,
          MESSAGE_CONSTANTS.REQUEST_OPTIONS,
          true,
          { enquiryType: 'request' }
        ),
      ];

      if (typedText) {
        messages.unshift(this.createMessage(CONSTANTS.TEXT, `user`, typedText, false));
      }

      this.addMessageInJourneyList([...messages]);
    } else if (CONSTANTS.BACK_TO_PREV_STEP === source) {
      const message = typedText || CONSTANTS.BACK_TO_PREV_STEP;
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, message, false),
        this.createMessage(
          CONSTANTS.REQUEST_OPTIONS,
          `system`,
          MESSAGE_CONSTANTS.REQUEST_OPTIONS,
          true,
          { enquiryType: 'request' }
        ),
      ]);
    } else if (CONSTANTS.GENERAL_ENQUIRY === source) {
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.ELIGIBLE_FOR_SERVICE, false),
        this.createMessage(
          CONSTANTS.GENERAL_ENQUIRY,
          `system`,
          MESSAGE_CONSTANTS.GENERAL_ENQUIRY,
          true
        ),
      ]);
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.GENERAL_ENQUIRY.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.GENERAL_ENQUIRY.label,
        MESSAGE_CONSTANTS.GENERAL_ENQUIRY.PLACEHOLDER,
        ''
      );
    } else if (CONSTANTS.SUBSCRIPTION == source) {
      this.props.createChatEnquiry(
        CONSTANTS.GE_INQUIRY_TYPE,
        CONSTANTS.SUBSCRIPTION_INQUIRY,
        CONSTANTS.ENQUIRY_SUB_REASON.SUBSCRIPTION,
        this.props.customerContact
      );
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.ELIGIBLE_FOR_SERVICE, false),
        this.createMessage(
          CONSTANTS.SUBSCRIPTION,
          `system`,
          MESSAGE_CONSTANTS.ELIGIBLE_FOR_SERVICE,
          true
        ),
      ]);
    } else if (CONSTANTS.LEAVE_AIS === source) {
      //MIXPANEL.time_event(EVENTLIST.LEAVING_AIS_REASONN);
      const messages = [
        this.createMessage(CONSTANTS.ASK_FOR_LEAVING_AIS_REASON, `system`, {
          source: CONSTANTS.SOURCE_USER_INPUT.ASK_FOR_LEAVING_AIS_REASON,
          inputState: CONSTANTS.LEAVING_AIS_REASON_STATE,
        }),
      ];

      if (typedText) {
        messages.unshift(this.createMessage(CONSTANTS.TEXT, `user`, typedText, false));
      }

      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: [...this.state.journeyMessageList, ...messages],
      });
    } else if (CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK === source) {
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.SUBMIT_CONTACT_NUMBER,
          `system`,
          MESSAGE_CONSTANTS.ASK_CONTACT_FOR_ENQUIRY_CALLBACK,
          true,
          { source }
        ),
      ]);
    } else if (CONSTANTS.IMEI_VERFIED === source) {
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `system`, typedText, false),
      ]);
    } else if (CONSTANTS.GENERIC_LOADER === source) {
      this.setState({
        journeyMessageList: [...this.state.journeyMessageList],
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.RESUME_ENQUIRY_OPTIONS === source) {
      let showEnquiryUserInput = true;
      if (typedText) {
        this.setState({
          showEnquiryUserInput: showEnquiryUserInput,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.ENQUIRY_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.ENQUIRY_OPTIONS,
              true,
              { enquiryType: 'replacement' }
            ),
          ],
        });
      } else {
        this.setState({
          showEnquiryUserInput: showEnquiryUserInput,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.ENQUIRY_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.ENQUIRY_OPTIONS,
              true,
              { enquiryType: 'replacement' }
            ),
          ],
        });
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.RESUME_NEW_SR_ENQUIRY_OPTIONS === source) {
      if (typedText) {
        this.setState({
          showEnquiryUserInput: true,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.ENQUIRY_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.ENQUIRY_OPTIONS,
              true,
              { enquiryType: 'replacement' }
            ),
          ],
        });
      } else {
        this.setState({
          showEnquiryUserInput: true,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.ENQUIRY_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.ENQUIRY_OPTIONS,
              true,
              { enquiryType: 'replacement' }
            ),
          ],
        });
      }
    } else if (CONSTANTS.ENQUIRY_OPTIONS === source) {
      this.setState({
        showEnquiryUserInput: true,
        noExistingSR: true,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NEW_SERVICE_REQ, false),
          this.createMessage(
            CONSTANTS.ENQUIRY_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.ENQUIRY_OPTIONS,
            true,
            { enquiryType: 'replacement', skipvalidation: true }
          ),
        ],
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.MDN === source) {
      //Not getting trigger, needs to remove afterwards
      MIXPANEL.track(EVENTLIST.MDN_INPUT);
      MIXPANEL.register({ MDN: typedText });
      this.setState({
        userMDN: typedText,
        lastActivity: EVENTLIST.MDN_INPUT,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.TEXT,
          `user`,
          // getMaskedstr(typedText),
          typedText,
          false
        ),
      ]);
    } else if (CONSTANTS.MDN_VERIFIED === source) {
      if (this.props.selectedDefectOption === CONSTANTS.GENERAL_ENQUIRY) {
        this.props.createChatEnquiry(
          CONSTANTS.GE_INQUIRY_TYPE,
          CONSTANTS.GE_INQUIRY_CHAT,
          CONSTANTS.ENQUIRY_SUB_REASON.CHAT,
          this.props.customerContact
        );
        this.setState({
          chatReason: CONSTANTS.CHAT_REASON.GENERAL_ENQUIRY,
          source: CONSTANTS.GENERAL_ENQUIRY_CREATED_THANKS_FOR_CONTACTING,
          actionMade: this.state.currentAction,
        });
        this.assignToAgentIfAvailable();
      } else {
        /* else if (this.props.selectedDefectOption === CONSTANTS.CHECK_SERVICE_FEE) {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            {
              messageId: uuid(),
              type: CONSTANTS.CHECKING_SERVICE_REQUEST_FEE,
              author: CONSTANTS.SYSTEM,
              source: CONSTANTS.SOURCE_ENUM.SYSTEM,
              data: MESSAGE_CONSTANTS.CHECKING_SERVICE_REQUEST_FEE,
              time: messageTime(),
            }
          ],
          lastActivity: EVENTLIST.GET_SR_FEE,
          lastUpdatedTime: new Date(),
        });
        MIXPANEL.track(EVENTLIST.GET_SR_FEE);
        this.props.getServiceFee(this.props.customerContact)
      } */
        MIXPANEL.time_event(EVENTLIST.NRIC_INPUT);
        this.setState({
          showMDNUserInput: false,
          showNRICUserInput: true,
          lastActivity: EVENTLIST.NRIC_INPUT,
          lastUpdatedTime: new Date(),
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.TEXT,
              `system`,
              MESSAGE_CONSTANTS.NATIONAL_ID_REQUEST,
              false
            ),
            this.createMessage(
              CONSTANTS.ASK_FOR_NRIC,
              `system`,
              MESSAGE_CONSTANTS.ASK_FOR_NRIC,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.NRIC,
                inputState: CONSTANTS.ENTERED_NRIC,
              }
            ),
          ],
        });
      }
    } else if (CONSTANTS.DOWNLOAD_DOCUMENTS === source) {
      MIXPANEL.track(EVENTLIST.CONFIRMATION_FORM_REQUEST, null, function () {
        MIXPANEL.time_event(EVENTLIST.CONFIRMATION_FORM_UPLOADED);
      });
      this.setState({
        lastActivity: EVENTLIST.CONFIRMATION_FORM_UPLOADED,
        lastUpdatedTime: new Date(),
      });
      let msgArr = [
        this.createMessage(
          CONSTANTS.HOLD_SCRIPT,
          `system`,
          MESSAGE_CONSTANTS.SELECT_DOWNLOAD_DOCUMENT,
          true
        ),
        this.createMessage(
          CONSTANTS.DOWNLOAD_DOCUMENTS,
          `system`,
          MESSAGE_CONSTANTS.SELECT_DOWNLOAD_DOCUMENT,
          true
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
    } else if (CONSTANTS.DOWNLOAD_DOCUMENTS_RESUME === source) {
      MIXPANEL.track(EVENTLIST.CONFIRMATION_FORM_REQUEST, null, function () {
        MIXPANEL.time_event(EVENTLIST.CONFIRMATION_FORM_UPLOADED);
      });
      this.setState({
        lastActivity: EVENTLIST.CONFIRMATION_FORM_UPLOADED,
        lastUpdatedTime: new Date(),
      });
      let msgArr = [
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.HOLD_SCRIPT,
          `system`,
          MESSAGE_CONSTANTS.SELECT_DOWNLOAD_DOCUMENT,
          true
        ),
        this.createMessage(
          CONSTANTS.DOWNLOAD_DOCUMENTS,
          `system`,
          MESSAGE_CONSTANTS.SELECT_DOWNLOAD_DOCUMENT,
          true
        ),
      ];
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
        MESSAGE_CONSTANTS.SUB_STAGES.DOCUMENT_DOWNLOAD
      );
    } else if (CONSTANTS.UPLOAD_SUCCESS === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.FILES_UPLOADED_SUCCESSFULLY,
            false
          ),
        ],
      });
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
        MESSAGE_CONSTANTS.SUB_STAGES.DOCUMENT_UPLOAD
      );
    } else if (CONSTANTS.ELIGIBILITY_VOID === source) {
      MIXPANEL.track(EVENTLIST.VOID);
      this.setState({
        lastActivity: EVENTLIST.VOID,
        lastUpdatedTime: new Date(),
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.ELIGIBILITY_VOID,
            `system`,
            MESSAGE_CONSTANTS.SELECT_DOWNLOAD_DOCUMENT,
            true
          ),
        ],
      });
    } else if (CONSTANTS.ELIGIBLE_FOR_RESHIP === source) {
      MIXPANEL.track(EVENTLIST.RESHIP);
      this.setState({
        lastActivity: EVENTLIST.RESHIP,
        lastUpdatedTime: new Date(),
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.ELIGIBLE_FOR_RESHIP,
            false
          ),
          {
            messageId: uuid(),
            type: CONSTANTS.JOURNEY_ENDED,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: { endReason: CONSTANTS.ENDREASON_LIST.RESHIP },
            time: messageTime(),
          },
        ],
      });
    } else if (CONSTANTS.PAYMENT_REVIEW_PENDING === source) {
      MIXPANEL.track(EVENTLIST.ORDER_UNDER_REVIEW);
      this.setState({
        lastActivity: EVENTLIST.ORDER_UNDER_REVIEW,
        lastUpdatedTime: new Date(),
        journeyMessageList: [
          ...this.state.journeyMessageList,
          // this.createMessage(CONSTANTS.RESUME_FLOW_FOR_HOLD, `system`, MESSAGE_CONSTANTS., true),
          this.createMessage(
            CONSTANTS.CONTINUE_PREVIOUS_SR,
            `system`,
            MESSAGE_CONSTANTS.CURRENT_REQUEST_DETAILS,
            true,
            { source: null }
          ),
          // this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.PAYMENT_ORDER_PENDING, false),
          this.createMessage(CONSTANTS.CLOSE_SR, `system`, MESSAGE_CONSTANTS.CLOSE_SR, true, {
            source: CONSTANTS.CLOSE_SR,
          }),
          // { messageId: uuid(), type: CONSTANTS.JOURNEY_ENDED, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: { endReason: CONSTANTS.ENDREASON_LIST.INTERNAL_REVIEW }, time: messageTime() }
        ],
      });
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.label
      );
    } else if (CONSTANTS.NO_PAYMENT_REVIEW === source) {
      MIXPANEL.track(EVENTLIST.RESUME_VOICE_TO_ONLINE_FAILURE);
      this.setState({
        lastUpdatedTime: new Date(),
        lastActivity: EVENTLIST.RESUME_VOICE_TO_ONLINE_FAILURE,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.ELIGIBLE_FOR_SERVICE,
            false
          ),
          this.createMessage(
            CONSTANTS.NO_PAYMENT_REVIEW,
            `system`,
            MESSAGE_CONSTANTS.NO_PAYMENT_REVIEW,
            true,
            { source: CONSTANTS.NO_PAYMENT_REVIEW }
          ),
        ],
      });
    } else if (CONSTANTS.CANCEL_UPLOAD === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.SURVEY_CONFIRMATION.CONTENT1,
            false
          ),
          this.createMessage(
            CONSTANTS.ASK_FOR_FEEDBACK,
            `system`,
            MESSAGE_CONSTANTS.ASK_FOR_FEEDBACK,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION }
          ),
        ],
      });
    } else if (CONSTANTS.UPLOAD_DOCUMENTS === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.UPLOAD_DOCUMENTS,
            `system`,
            MESSAGE_CONSTANTS.SELECT_DOWNLOAD_DOCUMENT,
            true
          ),
        ],
      });
    } else if (CONSTANTS.NO_EXISTING_SR === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.NO_EXISTING_SR,
            `system`,
            MESSAGE_CONSTANTS.NO_EXISTING_SR,
            true,
            { source: CONSTANTS.NO_EXISTING_SR }
          ),
          {
            messageId: uuid(),
            type: CONSTANTS.JOURNEY_ENDED,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: { endReason: CONSTANTS.ENDREASON_LIST.NO_EXISTING_SR },
            time: messageTime(),
          },
        ],
      });
    } else if (CONSTANTS.NO_CURRENT_DELIVERY === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          // this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.NO_CURRENT_DELIVERY, false),
          this.createMessage(
            CONSTANTS.CLOSE_SR,
            `system`,
            MESSAGE_CONSTANTS.NO_CURRENT_DELIVERY,
            true,
            { source: CONSTANTS.CLOSE_SR }
          ),
        ],
      });
    } else if (CONSTANTS.CONFIRM_UPLOAD_DOCUMENTS === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.CONFIRM_UPLOAD_DOCUMENTS,
            `system`,
            MESSAGE_CONSTANTS.SELECT_DOWNLOAD_DOCUMENT,
            true
          ),
        ],
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.MDN_WRONG_ATTEMPT === source) {
      MIXPANEL.track(EVENTLIST.MDN_INPUT_FAILURE);
      this.setState({
        lastActivity: EVENTLIST.MDN_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.ASK_FOR_MDN,
          `system`,
          MESSAGE_CONSTANTS.INCORRECT_MDN_FIRST_ATTEMPT,
          true,
          {
            source: CONSTANTS.SOURCE_USER_INPUT.MDN,
            inputState: CONSTANTS.ENTERED_MDN,
          }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.NAME_WRONG_ATTEMPT === source) {
      MIXPANEL.track(EVENTLIST.NAME_INPUT_FAILURE);
      this.setState({
        showMDNUserInput: false,
        showNAMEUserInput: true,
        lastActivity: EVENTLIST.NAME_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.ASK_FOR_NAME,
            `system`,
            MESSAGE_CONSTANTS.INCORRECT_NAME_FIRST_ATTEMPT,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.NAME,
              inputState: CONSTANTS.ENTERED_NAME,
            }
          ),
        ],
      });
    } else if (CONSTANTS.INCORRECT_NAME_SECOND_ATTEMPT === source) {
      MIXPANEL.track(EVENTLIST.NAME_INPUT_FAILURE);
      this.setState({
        showMDNUserInput: false,
        showNAMEUserInput: true,
        lastActivity: EVENTLIST.NAME_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.INCORRECT_NAME_SECOND_ATTEMPT,
            `system`,
            MESSAGE_CONSTANTS.INCORRECT_NAME_SECOND_ATTEMPT,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.NAME,
              inputState: CONSTANTS.ENTERED_NAME,
            }
          ),
        ],
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.ASSIGNED_AGENT_BUSY === source) {
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.ASSIGNED_AGENT_BUSY,
          `system`,
          MESSAGE_CONSTANTS.AGENT_BUSY,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.ASSIGNED_AGENT_BUSY }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_BUSY === source) {
      this.props.createChatEnquiry(
        CONSTANTS.STAFF_BUSY,
        CONSTANTS.STAFF_BUSY_INQUIRY,
        CONSTANTS.ENQUIRY_SUB_REASON.STAFF_BUSY,
        this.props.customerContact
      );
      MIXPANEL.time_event(EVENTLIST.AGENTS_BUSY);
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.ALL_AGENT_BUSY,
          `system`,
          MESSAGE_CONSTANTS.ALL_AGENT_BUSY,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_BUSY }
        ),
        this.createMessage(
          CONSTANTS.JOURNEY_ENDED,
          `system`,
          { endReason: CONSTANTS.ENDREASON_LIST.AGENTS_BUSY },
          true
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_OFFLINE === source) {
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.ALL_AGENT_OFFLINE,
          `system`,
          MESSAGE_CONSTANTS.ALL_AGENT_OFFLINE,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_OFFLINE }
        ),
        this.createMessage(
          CONSTANTS.JOURNEY_ENDED,
          `system`,
          { endReason: CONSTANTS.ENDREASON_LIST.AGENTS_OFFLINE },
          true
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.NAME_VERIFICAIION_ERROR_DISPLAY === source) {
      MIXPANEL.time_event(EVENTLIST.NAME_INPUT);
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.NAME_VERIFICAIION_ERROR,
            `system`,
            MESSAGE_CONSTANTS.NAME_ERROR,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.NAME }
          ),
        ],
        chatReason: CONSTANTS.CHAT_REASON.NAME,
        source: CONSTANTS.SOURCE_USER_INPUT.NAME,
        actionMade: this.state.currentAction,
        // showEnquiryUserInput: true,
        showNAMEUserInput: false,
      });
      this.assignToAgentIfAvailable();
    } else if (CONSTANTS.SOURCE_USER_INPUT.NO_CONFIRMED_FOR_TURNOFF_FEATURES === source) {
      this.setState({
        chatReason: CONSTANTS.CHAT_REASON.FMIP,
        source: CONSTANTS.SOURCE_USER_INPUT.FMIP,
        actionMade: this.state.currentAction,
      });
      this.assignToAgentIfAvailable();
    } else if (CONSTANTS.SOURCE_USER_INPUT.MDN_VERIFICAIION_ERROR_DISPLAY === source) {
      MIXPANEL.track(EVENTLIST.MDN_INPUT_FAILURE);
      this.props.savePayload(
        SET_CHAT_REASON,
        CONSTANTS.GENERAL_ENQUIRY
          ? CONSTANTS.CHAT_REASON.GENERAL_ENQUIRY
          : CONSTANTS.CHAT_REASON.MDN
      );
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.NO_EXISTING_CUSTOMER,
            false
          ),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.NO_EXISTING_CUSTOMER_NOTE,
            false
          ),
          this.createMessage(
            CONSTANTS.INCORRECT_MDN_SECOND_ATTEMPT,
            `system`,
            MESSAGE_CONSTANTS.INCORRECT_MDN_SECOND_ATTEMPT,
            true
          ),
        ],
        chatReason:
          this.props.selectedDefectOption === CONSTANTS.GENERAL_ENQUIRY
            ? CONSTANTS.CHAT_REASON.GENERAL_ENQUIRY
            : CONSTANTS.CHAT_REASON.MDN,
        source: CONSTANTS.SOURCE_USER_INPUT.MDN,
        actionMade: this.state.currentAction,
        // showEnquiryUserInput: true,
        showMDNUserInput: false,
        showNAMEUserInput: false,
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.NRIC === source) {
      //not getting triggered, need to be removed
      MIXPANEL.track(EVENTLIST.NRIC_INPUT);
      // let userNric = getMaskedstr(typedText);
      this.setState({
        showGetHelp: true,
        showNAMEUserInput: false,
        showNRICUserInput: false,
        lastActivity: EVENTLIST.NRIC_INPUT,
        lastUpdatedTime: new Date(),
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, 'user', { text: typedText, id: 'NRIC' }, false),
        ],
      });
    } else if (CONSTANTS.RESUME_FLOW_FOR_HOLD === source) {
      if (typedText === 'RESUME_FLOW') {
        this.setState({
          showGetHelp: true,
          showNRICUserInput: false,
          journeyMessageList: [
            ...this.state.journeyMessageList.filter(
              (ele) => ele.type !== CONSTANTS.NRIC_USER_INPUT
            ),
            this.createMessage(
              CONSTANTS.RESUME_FLOW_FOR_HOLD,
              `system`,
              MESSAGE_CONSTANTS.RESUME_SERVICE_REQUEST,
              true
            ),
          ],
        });
      }
    } else if (CONSTANTS.ACCOUNT_VERIFICATION_COMPLETED === source) {
      console.log('this.state.journeyMessageList in ', this.state.journeyMessageList);
      if (typedText === 'RESUME_FLOW') {
        this.setState({
          showGetHelp: true,
          showNRICUserInput: false,
          lastActivity: EVENTLIST.EXISTING_SR_DISPLAYED,
          lastUpdatedTime: new Date(),
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.CONTINUE_PREVIOUS_SR,
              `system`,
              MESSAGE_CONSTANTS.RESUME_SERVICE_REQUEST,
              true
            ),
          ],
        });
      } else {
        // confirm enrolled device resume point
        // let content = checkIfDeviceMakeModelExist(this.props.deviceDetails)
        //   ? this.createMessage(
        //     CONSTANTS.CURRENT_DEVICE_OPTIONS,
        //     `system`,
        //     MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
        //     true,
        //     { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
        //   )
        //   : this.createMessage(
        //     CONSTANTS.CONFIRM_CURRENT_DEVICE,
        //     `system`,
        //     MESSAGE_CONSTANTS.CONFIRM_CURRENT_DEVICE,
        //     true,
        //     { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_SELECTION }
        //   );
        this.setState({
          showGetHelp: true,
          showNRICUserInput: false,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.SOURCE_USER_INPUT.DEVICE_LIST_SUBSCRIPTION,
              `system`,
              true
            ),
          ],
        });
        // this.props.updateTransitionPanel(MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage, MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label, typedText);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          MESSAGE_CONSTANTS.SUB_STAGES.CONFIRM_ENROLLED_DEVICE
        );
        // this.props.updateTransitionPanel(13, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.CONFIRM_ENROLLED_DEVICE, '');
      }
    } else if (CONSTANTS.DEVICE_LIST === source) {
      let messages = [
        this.createMessage(CONSTANTS.SOURCE_USER_INPUT.DEVICE_LIST_SUBSCRIPTION, `system`, true),
      ];

      if (typedText) {
        messages.unshift(this.createMessage(CONSTANTS.TEXT, `user`, typedText, false));
      }

      this.setState({
        showGetHelp: true,
        showNRICUserInput: false,
        lastUpdatedTime: new Date(),
        journeyMessageList: [...this.state.journeyMessageList, ...messages],
      });
    } else if (CONSTANTS.VERIFIED_AGREEMENT_DEVICE_LIST === source) {
      let messages = [
        this.createMessage(
          CONSTANTS.SOURCE_USER_INPUT.VERIFIED_AGREEMENT_DEVICE_LIST,
          `system`,
          true
        ),
      ];

      if (typedText) {
        messages.unshift(this.createMessage(CONSTANTS.TEXT, `user`, typedText, false));
      }

      this.setState({
        showGetHelp: true,
        showNRICUserInput: false,
        lastUpdatedTime: new Date(),
        journeyMessageList: [...this.state.journeyMessageList, ...messages],
      });
    } else if (CONSTANTS.CONFIRM_CURRENT_DEVICE === source) {
      let content = checkIfDeviceMakeModelExist(this.props.deviceDetails)
        ? this.createMessage(
            CONSTANTS.CURRENT_DEVICE_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
          )
        : this.createMessage(
            CONSTANTS.CONFIRM_CURRENT_DEVICE,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_CURRENT_DEVICE,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_SELECTION }
          );

      if (typedText) {
        this.setState({
          showEnquiryUserInput: false,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            content,
          ],
        });
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          MESSAGE_CONSTANTS.SUB_STAGES.CONFIRM_ENROLLED_DEVICE
        );
      } else {
        this.setState({
          showEnquiryUserInput: false,
          journeyMessageList: [...this.state.journeyMessageList, content],
        });
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          MESSAGE_CONSTANTS.SUB_STAGES.CONFIRM_ENROLLED_DEVICE
        );
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.NRIC_WRONG_ATTEMPT === source) {
      MIXPANEL.track(EVENTLIST.NRIC_INPUT_FAILURE);
      this.setState({
        lastActivity: EVENTLIST.NRIC_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.ASK_FOR_NRIC,
          `system`,
          MESSAGE_CONSTANTS.INCORRECT_NRIC_FIRST_ATTEMPT,
          true,
          {
            source: CONSTANTS.SOURCE_USER_INPUT.NRIC,
            inputState: CONSTANTS.ENTERED_NRIC,
          }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.NRIC_VERIFICAIION_ERROR_DISPLAY === source) {
      MIXPANEL.track(EVENTLIST.NRIC_INPUT_FAILURE);
      this.props.savePayload(SET_CHAT_REASON, CONSTANTS.CHAT_REASON.NRIC);
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.INCORRECT_NRIC_FIRST_ATTEMPT.CONTENT,
            false
          ),
        ],
        chatReason: CONSTANTS.CHAT_REASON.NRIC,
        source: CONSTANTS.SOURCE_USER_INPUT.NRIC,
        actionMade: this.state.currentAction,
        lastActivity: EVENTLIST.NRIC_INPUT_FAILURE,
      });
      this.assignToAgentIfAvailable();
    } else if (CONSTANTS.ASSIGN_TO_AGENT === source) {
      this.setState({
        showLoader: true,
      });
      this.assignToAgentIfAvailable();
    } else if (CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_SELECTION === source) {
      this.setState({
        lastActivity: EVENTLIST.CONFIRM_ENROLLED_DEVICE,
        lastUpdatedTime: new Date(),
      });
      if (typedText === 'Yes') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
        ]);

        let { deviceDetails } = this.props;
        let deviceSpecs = getFormattedPopularName(deviceDetails.AssetCatalog.AssetCatalogName);
        let selectedDevice =
          deviceSpecs.modelName + ' ' + deviceSpecs.specs + ' ' + deviceSpecs.color;
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          '',
          selectedDevice
        );
        // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, '', selectedDevice);
      } else if (typedText === 'Select') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.SELECT_TEXT, false),
        ]);

        let { deviceDetails } = this.props;
        let deviceSpecs = getFormattedPopularName(deviceDetails.AssetCatalog.AssetCatalogName);
        let selectedDevice =
          deviceSpecs.modelName + ' ' + deviceSpecs.specs + ' ' + deviceSpecs.color;
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          '',
          selectedDevice
        );
        // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, '', selectedDevice);
      } else if (typedText === 'No') {
        MIXPANEL.track(EVENTLIST.DEVICE_MISMATCHED);
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.CURRENT_DEVICE_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          ''
        );
      } else if (typedText === MESSAGE_CONSTANTS.SELECT_DEVICE) {
        MIXPANEL.track(EVENTLIST.DEVICE_MISMATCHED);
        this.setState({
          lastActivity: EVENTLIST.DEVICE_MISMATCHED,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.CURRENT_DEVICE_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          ''
        );
      } else if (!typedText) {
        if (
          this.props.caseType === CONSTANTS.MALFUNCTION ||
          this.props.caseType === CONSTANTS.SWAP
        ) {
          let content = checkIfDeviceMakeModelExist(this.props.deviceDetails)
            ? this.createMessage(
                CONSTANTS.CURRENT_DEVICE_OPTIONS,
                `system`,
                MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
                true,
                { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
              )
            : this.createMessage(
                CONSTANTS.CONFIRM_CURRENT_DEVICE,
                `system`,
                MESSAGE_CONSTANTS.CONFIRM_CURRENT_DEVICE,
                true,
                { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_SELECTION }
              );

          this.setState({
            journeyMessageList: [...this.state.journeyMessageList, content],
          });
        } else {
          this.setState({
            journeyMessageList: [
              ...this.state.journeyMessageList,
              this.createMessage(
                CONSTANTS.TEXT,
                `system`,
                MESSAGE_CONSTANTS.NO_ACCESSORIES_PACKAGE,
                false
              ),
              // this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.DEVICE_BACKUP, false),
              this.createMessage(
                CONSTANTS.PROCEED_SERVICE_FEE,
                `system`,
                MESSAGE_CONSTANTS.PROCEED_SERVICE_FEE,
                true,
                { source: CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED }
              ),
            ],
            showBotInput: true,
            botCalledFrom: {
              source: source,
              action: typedText,
            },
            lastActivity: EVENTLIST.SERVICE_FEE_CONFIRMATION,
            lastUpdatedTime: new Date(),
          });
          let srvFee =
            this.props.serviceFee.CurrencyCode +
            ' ' +
            getFormattedTotalFee(this.props.serviceFee.TotalAmount);

          // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, '', "Service Fee: " + srvFee);
          this.props.updateTransitionPanel(
            MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
            MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
            '',
            MESSAGE_CONSTANTS.SERVICE_FEE + srvFee
          );
          //  }
        }
      } else {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        ]);
        let { deviceDetails } = this.props;
        let deviceSpecs = getFormattedPopularName(deviceDetails.AssetCatalog.AssetCatalogName);
        let selectedDevice =
          deviceSpecs.modelName + ' ' + deviceSpecs.specs + ' ' + deviceSpecs.color;
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          '',
          selectedDevice
        );
      }
    } else if (source === CONSTANTS.ALTERNATE_DEVICE_SELECTED) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.CHECK_ALTERNATIVE_DEVICES,
            `system`,
            MESSAGE_CONSTANTS.CHECK_ALTERNATIVE_DEVICES,
            true
          ),
        ],
      });
    } else if (source === CONSTANTS.CHECK_DEVICE_STOCK) {
      if (!this.props.currentDeviceInStock) {
        MIXPANEL.track(EVENTLIST.DEVICE_OUT_OF_STOCK);
        // this.setState({
        //   journeyMessageList: [
        //     ...this.state.journeyMessageList,
        //     this.createMessage(
        //       CONSTANTS.TEXT,
        //       `system`,
        //       MESSAGE_CONSTANTS.CURRENT_DEVICE_UNAVAILABLE,
        //       false
        //     ),
        //     this.createMessage(
        //       CONSTANTS.CHECK_ALTERNATIVE_DEVICES,
        //       `system`,
        //       MESSAGE_CONSTANTS.CHECK_ALTERNATIVE_DEVICES,
        //       true
        //     ),
        //   ],
        //   showBotInput: false,
        //   lastActivity: EVENTLIST.DEVICE_OUT_OF_STOCK,
        //   lastUpdatedTime: new Date(),
        // });

        if (this.props.caseType !== CONSTANTS.SWAP) {
          let LFLDevice = !_.isEmpty(this.props.deviceList) ? _.find(this.props.deviceList, { IsLikeForLike: true }) : {};

          if (LFLDevice.IsNoLongerProduced) {
            this.addMessageInJourneyList([
              this.createMessage(
                CONSTANTS.DEVICE_NLP,
                `system`,
                {
                  devices: this.props.deviceList,
                  scripts: MESSAGE_CONSTANTS.DEVICE_STATUS_NLP
                },
                true,
                {}
              )
            ])
          } else if (LFLDevice.IsNotCurrentlyAvailableForOrder) {
            this.addMessageInJourneyList([
              this.createMessage(
                CONSTANTS.DEVICE_NCA, 
                `system`, 
                { 
                  devices: this.props.deviceList,
                  scripts: MESSAGE_CONSTANTS.DEVICE_STATUS_NCA
                 }, 
                true, 
                {}
                ),
            ])
          } else {
            this.addMessageInJourneyList([
              this.createMessage(
                CONSTANTS.DEVICE_OUT_STOCK, 
                `system`, 
                { 
                  devices: this.props.deviceList,
                  scripts: MESSAGE_CONSTANTS.DEVICE_STATUS_OUT_STOCK
                }, 
                true, 
                {}
                ),
            ])
          }
        } else { //SWAP
          this._updateJourneyList(CONSTANTS.ALTERNATE_DEVICE_LIST, CONSTANTS.WAIT_FOR_ENROLLED_DEVICE);
        }

        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          MESSAGE_CONSTANTS.SUB_STAGES.DEVICE_NOT_IN_STOCK
        );
        // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.DEVICE_NOT_IN_STOCK, '');
      } else {
        let content = this.createMessage(
          CONSTANTS.CONFIRM_MOB_NO,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_MOB_NO,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION }
        );
        if (this.props.phoneNumber === CONSTANTS.PHONE_NOT_AVAILABLE) {
          content = this.createMessage(
            CONSTANTS.SUBMIT_CONTACT_NUMBER,
            `system`,
            MESSAGE_CONSTANTS.SUBMIT_CONTACT_NUMBER,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO }
          );
        }
        MIXPANEL.track(EVENTLIST.DEVICE_IN_STOCK);
        // this.setState({
        //   journeyMessageList: [
        //     ...this.state.journeyMessageList,
        //     {
        //       messageId: uuid(),
        //       type: CONSTANTS.TEXT,
        //       author: this.props.customerName.fullName,
        //       source: CONSTANTS.SOURCE_ENUM.SYSTEM,
        //       data: { text: MESSAGE_CONSTANTS.DEVICE_IN_STOCK },
        //       time: messageTime(),
        //     },
        //     content,
        //   ],
        //   showBotInput: false,
        //   lastActivity: EVENTLIST.DEVICE_IN_STOCK,
        //   lastUpdatedTime: new Date(),
        // });
        if (this.props.caseType !== CONSTANTS.SWAP) {
          this.setState({
            journeyMessageList: [
              ...this.state.journeyMessageList,
              this.createMessage(
                CONSTANTS.DEVICE_IN_STOCK,
                `system`,
                {
                  scripts: MESSAGE_CONSTANTS.DEVICE_STATUS_IN_STOCK
                },
                true
              ),
              content,
            ],
            showBotInput: false,
            lastActivity: EVENTLIST.DEVICE_IN_STOCK,
            lastUpdatedTime: new Date(),
          });
        } else {
          this.setState({
            journeyMessageList: [
              ...this.state.journeyMessageList,
              content,
            ],
            showBotInput: false,
            lastActivity: EVENTLIST.DEVICE_IN_STOCK,
            lastUpdatedTime: new Date(),
          });
        }
      }
    } else if (CONSTANTS.SUBMIT_IMEI_NUMBER === source) {
      MIXPANEL.time_event(EVENTLIST.IMEI_INPUT);
      if (!typedText) {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.SUBMIT_IMEI_NUMBER,
              `system`,
              MESSAGE_CONSTANTS.SUBMIT_IMEI_NUMBER,
              true
            ),
          ],
          lastActivity: EVENTLIST.IMEI_INPUT,
          lastUpdatedTime: new Date(),
        });
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          MESSAGE_CONSTANTS.SUB_STAGES.VALIDATE_IMEI
        );
        // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.VALIDATE_IMEI, '');
      } else {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.SUBMIT_IMEI_NUMBER,
              `system`,
              MESSAGE_CONSTANTS.SUBMIT_IMEI_NUMBER,
              true
            ),
          ],
        });
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.MALFUNCTION_SELECTED === source) {
      let content = this.createMessage(
        CONSTANTS.SUBMIT_IMEI_NUMBER,
        `system`,
        MESSAGE_CONSTANTS.SUBMIT_IMEI_NUMBER,
        true
      );
      this.addMessageInJourneyList([
        //this.createMessage(CONSTANTS.TEXT, "user", typedText, false),
        content,
      ]);

      /* let content = checkIfDeviceMakeModelExist(this.props.deviceDetails)
        ? this.createMessage(
          CONSTANTS.CURRENT_DEVICE_OPTIONS,
          `system`,
          MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
        )
        : this.createMessage(
          CONSTANTS.CONFIRM_CURRENT_DEVICE,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_CURRENT_DEVICE,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_SELECTION }
        );
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, "user", typedText, false),
        content
      ]);
      // this.props.updateTransitionPanel(12, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.CONFIRM_ENROLLED_DEVICE, "Issue with device: " + typedText);
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.DETERMINE_INCIDENT.advance_peril
          .percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.DETERMINE_INCIDENT.advance_peril.label,
        MESSAGE_CONSTANTS.SUB_STAGES.CONFIRM_ENROLLED_DEVICE
      ); */
    } else if (CONSTANTS.CHECKING_DEVICES_LOADER === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          // AJAX loader for replacement devices
          // { messageId: uuid(), type: CONSTANTS.CHECKING_DEVICES_LOADER, author: this.props.customerName, data: CONSTANTS.CHECKING_DEVICES_LOADER, time: messageTime() },
        ],
      });
    } else if (CONSTANTS.SERVICE_UNAVAILABLE === source) {
      let { serviceUnavailableCount } = this.props;
      if (serviceUnavailableCount === 1) {
        return true;
      }
      MIXPANEL.track(EVENTLIST.SERVICE_UNAVAILABLE);
      this.props.setJourneyCmpltd(true, CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.FAILURE);
      this.setState({
        chatReason: CONSTANTS.CHAT_REASON.SERVICE_UNAVAILABLE,
        actionMade: this.state.currentAction,
        showMDNUserInput: false,
        showNAMEUserInput: false,
        lastActivity: EVENTLIST.SERVICE_UNAVAILABLE_API + typedText,
        lastUpdatedTime: new Date(),
        apiError: {
          input: {
            conversationId: null,
            visitorId: this.state.visitor.input.visitorId,
            messageId: uuid(),
            sender: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            content: JSON.stringify(error),
            recipient: this.state.visitor.input.userName,
            isSent: false,
            createdAt: messageTime(),
            messageType: 'text',
            interactionType: 'Online',
            isActive: true,
            serialNo: 99999, // highest possible value since this should be last message
          },
        },
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.SOURCE_USER_INPUT.SERVICE_UNAVAILABLE, `system`),
        ],
      });
      /* this.props.savePayload(SET_CHAT_REASON, CONSTANTS.CHAT_REASON.SERVICE_UNAVAILABLE);
      this.assignToAgentIfAvailable(); */
      return;
    } else if (CONSTANTS.FAST_PAYMENT_SDK_ERROR === source) {
      MIXPANEL.track(EVENTLIST.BGV3_CREDIT_CARD_FAILURE);

      this.setState({
        ccValidationError: {
          input: {
            conversationId: null,
            visitorId: this.state.visitor.input.visitorId,
            messageId: uuid(),
            sender: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            content: error,
            recipient: this.state.visitor.input.userName,
            isSent: false,
            createdAt: messageTime(),
            messageType: 'text',
            interactionType: 'Online',
            isActive: true,
            serialNo: 99999, // highest possible value since this should be last message
          },
        },
      });
    } else if (CONSTANTS.FAST_PAYMENT_SDK_ERROR_RESET === source) {
      this.setState({
        ccValidationError: null,
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.ASSIGN_TO_AGENT_FOR_CHAT === source) {
      this.setState({
        chatReason: CONSTANTS.CHAT_REASON.VOID_SCRIPT,
        //source: CONSTANTS.GENERAL_ENQUIRY_CREATED_THANKS_FOR_CONTACTING,
        actionMade: this.state.currentAction,
        showMDNUserInput: false,
        showNAMEUserInput: false,
      });
      this.props.savePayload(SET_CHAT_REASON, CONSTANTS.CHAT_REASON.VOID_SCRIPT);
      this.assignToAgentIfAvailable();
    } else if (CONSTANTS.REPLACE_DEVICE_NOT_AVAILABLE === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `system`, typedText, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.SURVEY_CONFIRMATION.CONTENT1,
            false
          ),
          this.createMessage(
            CONSTANTS.ASK_FOR_FEEDBACK,
            `system`,
            MESSAGE_CONSTANTS.ASK_FOR_FEEDBACK,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION }
          ),
        ],
      });
    } else if (CONSTANTS.COD_CONFIRMATION === source) {
      if (typedText) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.COD_TEXT, false),
          this.createMessage(
            CONSTANTS.COD_CONFIRMATION,
            `system`,
            MESSAGE_CONSTANTS.COD_CONFIRMATION,
            true,
            { source: CONSTANTS.PAYMENT_VERIFIED }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.ORDER_CONFIRMATION
        );
      } else {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.COD_CONFIRMATION,
            `system`,
            MESSAGE_CONSTANTS.COD_CONFIRMATION,
            true,
            { source: CONSTANTS.PAYMENT_VERIFIED }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.CASH_ON_DEL
        );
      }
    } else if (CONSTANTS.REPLACE_DEVICE_SELECTED === source) {
      if (this.props.caseType === CONSTANTS.MALFUNCTION || this.props.caseType === CONSTANTS.SWAP) {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            //     this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.ALTERNATE_DEVICE_NOTE, false)
            this.createMessage(
              CONSTANTS.ALTERNATE_DEVICE_NOTE,
              `system`,
              MESSAGE_CONSTANTS.ALTERNATE_DEVICE_NOTE,
              true,
              { source: CONSTANTS.ALTERNATE_DEVICE_NOTE }
            ),
            // content
          ],
        });
      } else {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.ALTERNATE_DEVICE_NOTE,
              `system`,
              MESSAGE_CONSTANTS.ALTERNATE_DEVICE_NOTE,
              true,
              { source: CONSTANTS.ALTERNATE_DEVICE_NOTE }
            ),
            // content
          ],
        });
      }
    } else if (CONSTANTS.ALTERNATE_DEVICE_NOTE === source) {
      let content = this.createMessage(
        CONSTANTS.CONFIRM_MOB_NO,
        `system`,
        MESSAGE_CONSTANTS.CONFIRM_MOB_NO,
        true,
        { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION }
      );
      if (this.props.phoneNumber === CONSTANTS.PHONE_NOT_AVAILABLE) {
        content = this.createMessage(
          CONSTANTS.SUBMIT_CONTACT_NUMBER,
          `system`,
          MESSAGE_CONSTANTS.SUBMIT_CONTACT_NUMBER,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO }
        );
      }
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          content,
        ],
      });
      // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.VERIFY_ENROLLED_NUMBER_FOR_SMS, typedText);
    } else if (CONSTANTS.CLOSE_SR === source) {
      let content = this.createMessage(
        CONSTANTS.CLOSE_SR,
        `system`,
        MESSAGE_CONSTANTS.CLOSE_SR,
        true,
        { source: CONSTANTS.CLOSE_SR }
      );
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          content,
        ],
      });
    } else if (CONSTANTS.NO_EXISTING_CUSTOMER === source) {
      let content = this.createMessage(
        CONSTANTS.NO_EXISTING_CUSTOMER,
        `system`,
        MESSAGE_CONSTANTS.NO_EXISTING_CUSTOMER,
        true,
        { source: CONSTANTS.NO_EXISTING_CUSTOMER }
      );
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          content,
        ],
      });
    } else if (CONSTANTS.CLOSE_REQUEST === source) {
      let content = this.createMessage(
        CONSTANTS.CLOSE_REQUEST,
        `system`,
        MESSAGE_CONSTANTS.CLOSE_REQUEST,
        true,
        { source: CONSTANTS.CLOSE_REQUEST }
      );
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          content,
        ],
      });
    } else if (CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK === source) {
      let content = this.createMessage(
        CONSTANTS.CLOSE_SR,
        `system`,
        MESSAGE_CONSTANTS.CLOSE_SR,
        true,
        { source: CONSTANTS.CLOSE_SR }
      );
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          content,
        ],
      });
    } else if (CONSTANTS.MDN_NRIC_CLOSE === source) {
      let content = this.createMessage(
        CONSTANTS.MDN_NRIC_CLOSE,
        `system`,
        MESSAGE_CONSTANTS.MDN_NRIC_CLOSE,
        true,
        { source: CONSTANTS.MDN_NRIC_CLOSE }
      );
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          content,
        ],
      });
    } else if (CONSTANTS.GENERIC_NOT_RESOLVED === source) {
      let content = this.createMessage(
        CONSTANTS.GENERIC_NOT_RESOLVED,
        `system`,
        MESSAGE_CONSTANTS.GENERIC_NOT_RESOLVED,
        true,
        { source: CONSTANTS.GENERIC_NOT_RESOLVED }
      );
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          content,
        ],
      });
    } else if (CONSTANTS.MERGE_MALFUNCTION_FLOW === source) {
      if (typedText) {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.TEXT,
              `system`,
              MESSAGE_CONSTANTS.NO_ACCESSORIES_PACKAGE,
              false
            ),
            this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.S_PEN_INFO, false),
            this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.DEVICE_BACKUP, false),
            this.createMessage(
              CONSTANTS.PROCEED_SERVICE_FEE,
              `system`,
              MESSAGE_CONSTANTS.PROCEED_SERVICE_FEE,
              true,
              { source: CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED }
            ),
          ],
          showBotInput: true,
          botCalledFrom: {
            source: source,
            action: typedText,
          },
          lastActivity: EVENTLIST.SERVICE_FEE_CONFIRMATION,
          lastUpdatedTime: new Date(),
        });
      } else {
        let msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.NO_ACCESSORIES_PACKAGE,
            false
          ),
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.S_PEN_INFO, false),
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.DEVICE_BACKUP, false),
          this.createMessage(
            CONSTANTS.PROCEED_SERVICE_FEE,
            `system`,
            MESSAGE_CONSTANTS.PROCEED_SERVICE_FEE,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED }
          ),
        ];
        this._showLine(this, 0, msgArr, msgArr.length, null, 100);

        this.setState({
          showBotInput: true,
          botCalledFrom: {
            source: source,
            action: typedText,
          },
        });
      }
      let srvFee =
        this.props.serviceFee.CurrencyCode +
        ' ' +
        getFormattedTotalFee(this.props.serviceFee.TotalAmount);

      // this.props.updateTransitionPanel(18, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, '', "Service Fee: " + srvFee);
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
        '',
        MESSAGE_CONSTANTS.SERVICE_FEE + srvFee
      );
    } else if (CONSTANTS.TRIAGE_QUESTION === source) {
      if (typedText === 'Yes') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
          this.createMessage(
            CONSTANTS.TRIAGE_ISSUE_RESOLVED,
            `system`,
            MESSAGE_CONSTANTS.ISSUE_RESOLVED,
            true,
            {
              triageCounter: this.props.triageQuestionsDisplayed,
              source: CONSTANTS.TRIAGE_ISSUE_RESOLVED,
            }
          ),
        ]);
      } else if (typedText === 'No') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.TRIAGE_MESSAGE[this.props.triageQuestionsDisplayed],
            false
          ),
          this.createMessage(
            CONSTANTS.TRIAGE_QUESTION,
            `system`,
            MESSAGE_CONSTANTS.TRIAGE_QUESTION[this.props.triageQuestionsDisplayed + 1],
            true,
            {
              triageCounter: this.props.triageQuestionsDisplayed,
              source: CONSTANTS.TRIAGE_QUESTION,
            }
          ),
          // this.createMessage(CONSTANTS.TRIAGE_ISSUE_RESOLVED, `system`, MESSAGE_CONSTANTS.ISSUE_RESOLVED, true, { triageCounter: this.props.triageQuestionsDisplayed, source: CONSTANTS.TRIAGE_ISSUE_RESOLVED })
        ]);
      } else {
        if (typedText) {
          this.addMessageInJourneyList([
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.TRIAGE_QUESTION,
              `system`,
              MESSAGE_CONSTANTS.TRIAGE_QUESTION[this.props.triageQuestionsDisplayed],
              true,
              {
                triageCounter: this.props.triageQuestionsDisplayed,
                source: CONSTANTS.TRIAGE_QUESTION,
              }
            ),
          ]);
        } else {
          this.addMessageInJourneyList([
            this.createMessage(
              CONSTANTS.TRIAGE_QUESTION,
              `system`,
              MESSAGE_CONSTANTS.TRIAGE_QUESTION[this.props.triageQuestionsDisplayed],
              true,
              {
                triageCounter: this.props.triageQuestionsDisplayed,
                source: CONSTANTS.TRIAGE_QUESTION,
              }
            ),
          ]);
        }
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          MESSAGE_CONSTANTS.SUB_STAGES.TRIAGE_QUESTION
        );
        // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.TRIAGE_QUESTION, '');
      }
    } else if (CONSTANTS.TRIAGE_ISSUE_RESOLVED === source) {
      if (typedText === 'Yes') {
        MIXPANEL.track(EVENTLIST.TRIAGE, { Status: 'Resolved' }, function () {
          MIXPANEL.track(EVENTLIST.JOURNEY_COMPLETED, {
            'End Reason': CONSTANTS.ENDREASON_LIST.TRIAGE_SUCCESSFUL,
          });
        });
        this.setState({
          lastActivity: CONSTANTS.ENDREASON_LIST.TRIAGE_SUCCESSFUL,
          lastUpdatedTime: new Date(),
        });
        this.props.savePayload(SET_TRIAGE_SUCCESSFUL_FLAG, true);
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
            this.createMessage(
              CONSTANTS.TEXT,
              `system`,
              MESSAGE_CONSTANTS.THANKS_FOR_CONTACTING,
              false
            ),
          ],
        });
        // this.props.updateTransitionPanel(100, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.SURVEY, '');
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          MESSAGE_CONSTANTS.SUB_STAGES.SURVEY
        );
      } else if (typedText === 'No') {
        if (this.props.triageQuestionsDisplayed > 2) {
          return false;
        }
        this.setState({
          lastActivity: EVENTLIST.TRIAGE_ISSUE_NOT_RESOLVED,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.TRIAGE_QUESTION,
            `system`,
            MESSAGE_CONSTANTS.TRIAGE_QUESTION[this.props.triageQuestionsDisplayed],
            true,
            {
              triageCounter: this.props.triageQuestionsDisplayed,
              source: CONSTANTS.TRIAGE_QUESTION,
            }
          ),
        ]);
      }
    } else if (CONSTANTS.TRIAGE_FAILED === source) {
      if (typedText == MESSAGE_CONSTANTS.SUB_STAGES.DEVICE_STILL_IN_WARRANTY) {
        MIXPANEL.track(EVENTLIST.OEM_IN_WARRANTY);
        this.setState({
          lastActivity: EVENTLIST.OEM_IN_WARRANTY,
          lastUpdatedTime: new Date(),
        });
        if (this.props.reverseOrderWarranty) {
          this.setState({
            journeyMessageList: [
              ...this.state.journeyMessageList,
              {
                messageId: uuid(),
                type: CONSTANTS.TRIAGE_FAILED,
                author: CONSTANTS.SYSTEM,
                source: CONSTANTS.SOURCE_ENUM.SYSTEM,
                data: MESSAGE_CONSTANTS.STILL_IN_WARRANTY_DISCLAIMER,
                time: messageTime(),
              },
            ],
          });
          // }
        } else {
          this.setState({
            journeyMessageList: [
              ...this.state.journeyMessageList,
              this.createMessage(CONSTANTS.TEXT, `system`, typedText, false),
              {
                messageId: uuid(),
                type: CONSTANTS.TRIAGE_FAILED,
                author: CONSTANTS.SYSTEM,
                source: CONSTANTS.SOURCE_ENUM.SYSTEM,
                data: MESSAGE_CONSTANTS.STILL_IN_WARRANTY_DISCLAIMER,
                time: messageTime(),
              },
            ],
          });
        }
        // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.DEVICE_STILL_IN_WARRANTY, '');
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          MESSAGE_CONSTANTS.SUB_STAGES.DEVICE_STILL_IN_WARRANTY
        );
      } else if (typedText === 'EXTENDED_WARRANTY') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.PROCEED, false),
          this.createMessage(
            CONSTANTS.ACCEPT_TERMS,
            `system`,
            MESSAGE_CONSTANTS.WARRANTY_VOID_DISCLAIMER,
            true,
            { source: CONSTANTS.TERMS_ACCEPTED }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          MESSAGE_CONSTANTS.SUB_STAGES.WARRANTY_VOID
        );
        // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.WARRANTY_VOID, '');
      }
    } else if (CONSTANTS.TURN_OFF_FMIP === source) {
      MIXPANEL.time_event(EVENTLIST.FMIP);
      this.setState({
        lastActivity: EVENTLIST.FMIP,
        lastUpdatedTime: new Date(),
      });
      if (typedText) {
        let msgArr = [
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.TURN_OFF_FMIP, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.TURN_OFF_FMIP_EXTENDED,
            false
          ),
          this.createMessage(
            CONSTANTS.TURN_OFF_FMIP_INSTRUCTIONS,
            `system`,
            MESSAGE_CONSTANTS.TURN_OFF_FMIP_INSTRUCTIONS,
            true
          ),
          this.createMessage(
            CONSTANTS.TURNOFF_FEATURES,
            `system`,
            MESSAGE_CONSTANTS.TURN_OFF_FEATURES,
            true
          ),
        ];
        this._showLine(this, 0, msgArr, msgArr.length, null, 100);
      } else {
        let msgArr = [
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.TURN_OFF_FMIP, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.TURN_OFF_FMIP_EXTENDED,
            false
          ),
          this.createMessage(
            CONSTANTS.TURN_OFF_FMIP_INSTRUCTIONS,
            `system`,
            MESSAGE_CONSTANTS.TURN_OFF_FMIP_INSTRUCTIONS,
            true
          ),
          this.createMessage(
            CONSTANTS.TURNOFF_FEATURES,
            `system`,
            MESSAGE_CONSTANTS.TURN_OFF_FEATURES,
            true
          ),
        ];
        this._showLine(this, 0, msgArr, msgArr.length, null, 100);
      }
      // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.FMIP, '');
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
        MESSAGE_CONSTANTS.SUB_STAGES.FMIP
      );
    } else if (CONSTANTS.DEVICE_IS_BROKEN === source) {
      if (typedText) {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.DEVICE_IS_BROKEN,
              `system`,
              MESSAGE_CONSTANTS.SWOP_OPTIONS,
              true,
              { enquiryType: 'swap' }
            ),
          ],
        });
      } else {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.DEVICE_IS_BROKEN,
              `system`,
              MESSAGE_CONSTANTS.SWOP_OPTIONS,
              true,
              { enquiryType: 'swap' }
            ),
          ],
        });
      }
    } else if (CONSTANTS.TERMS_ACCEPTED === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.TERMS_ACCEPTED, false),
        ],
        lastActivity: EVENTLIST.TERMS_ACCEPTED,
        lastUpdatedTime: new Date(),
      });
    } else if (CONSTANTS.IMEI_INCORRECT_ATTEMPT === source) {
      MIXPANEL.track(EVENTLIST.IMEI_INPUT_FAILURE);
      this.setState({
        lastActivity: EVENTLIST.IMEI_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
      });
      if (typedText === 'FIRST') {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.IMEI_FIRST_INCORRECT_ATTEMPT,
            false
          ),
          this.createMessage(
            CONSTANTS.SUBMIT_IMEI_NUMBER,
            `system`,
            MESSAGE_CONSTANTS.SUBMIT_IMEI_NUMBER,
            true
          ),
        ]);
      } else if (typedText === 'SECOND') {
        let { chatReason } = this.state;
        this.props.savePayload(SET_CHAT_REASON, 'Invalid IMEI');
        let { verifyIMEIResults, selectedDefectOption, imeiVerificationAttempt } = this.props;
        let VerificationOutcome =
          verifyIMEIResults &&
          verifyIMEIResults.VerifyIMEIResults &&
          verifyIMEIResults.VerifyIMEIResults.VerificationOutcome;
        if (chatReason === 'Invalid IMEI') {
          if (
            imeiVerificationAttempt >= 3 &&
            !VerificationOutcome &&
            (selectedDefectOption === 'Manufacturer Exchange' ||
              selectedDefectOption === 'AIS Exchange')
          ) {
            this.setState({
              journeyMessageList: [
                ...this.state.journeyMessageList,
                this.createMessage(
                  CONSTANTS.DEVICE_NOT_ELIGIBLE,
                  `system`,
                  MESSAGE_CONSTANTS.DEVICE_NOT_ELIGIBLE,
                  true,
                  { source: CONSTANTS.DEVICE_NOT_ELIGIBLE }
                ),
              ],
              lastActivity: EVENTLIST.NO_EXCHANGE,
              lastUpdatedTime: new Date(),
            });
            return;
          }
          MIXPANEL.track(EVENTLIST.NO_EXCHANGE);
          this.setState({
            chatReason: 'Invalid IMEI',
            actionMade: this.state.currentAction,
            showMDNUserInput: false,
            showNAMEUserInput: false,
          });
          this.assignToAgentIfAvailable();
          return;
        }
        this.setState({
          lastActivity: EVENTLIST.EXCHANGE_OPTIONS_DISPLAYED,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.IMEI_SECOND_INCORRECT_ATTEMPT,
            false
          ),
          this.createMessage(
            CONSTANTS.IMEI_ATTEMPTS_EXCEEDED,
            `system`,
            MESSAGE_CONSTANTS.EXCHANGE_OPTIONS,
            true,
            { enquiryType: 'OEM-Exchange' }
          ),
        ]);
      }
    } else if (CONSTANTS.OEM_EXCHANGE_OPTIONS === source) {
      if (typedText === MESSAGE_CONSTANTS.NO_EXCHANGE_SELECTED) {
        this.props.savePayload(SET_CHAT_REASON, 'Invalid IMEI');
        MIXPANEL.track(EVENTLIST.NO_EXCHANGE);
        this.setState({
          chatReason: 'Invalid IMEI',
          actionMade: this.state.currentAction,
          showMDNUserInput: false,
          showNAMEUserInput: false,
        });
        this.assignToAgentIfAvailable();
        //this.props.cancelServiceRequestInit(this.props.cacheId);
      } else if (
        typedText === MESSAGE_CONSTANTS.OEM_EXCHANGE_SELECTED ||
        typedText === MESSAGE_CONSTANTS.AIS_EXCHANGE_SELECTED
      ) {
        let { imeiVerificationAttempt } = this.props;
        if (typedText === MESSAGE_CONSTANTS.OEM_EXCHANGE_SELECTED) {
          MIXPANEL.track(EVENTLIST.MANUFACTURE_EXCHANGE);
          this.setState({
            lastActivity: EVENTLIST.MANUFACTURE_EXCHANGE,
            lastUpdatedTime: new Date(),
          });
        } else {
          MIXPANEL.track(EVENTLIST.AIS_EXCHANGE);
          this.setState({
            lastActivity: EVENTLIST.FONECARE_EXCHANGE,
            lastUpdatedTime: new Date(),
          });
        }

        if (imeiVerificationAttempt >= 4) {
          this.addMessageInJourneyList([
            this.createMessage(CONSTANTS.TEXT, `user`, 'hard stop', false),
          ]);
        } else {
          this.props.savePayload(SET_CHAT_REASON, 'Invalid IMEI');
          this.setState({
            chatReason: 'Invalid IMEI',
            actionMade: this.state.currentAction,
            showMDNUserInput: false,
            showNAMEUserInput: false,
          });
          this.assignToAgentIfAvailable();
        }
      } else if (typedText === MESSAGE_CONSTANTS.AIS_EXCHANGE_SELECTED) {
        MIXPANEL.track(EVENTLIST.AIS_EXCHANGE);
        this.setState({
          lastActivity: EVENTLIST.FONECARE_EXCHANGE,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        ]);
      }
    } else if (CONSTANTS.DEVICE_NOT_WORKING === source) {
      if (typedText) {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.DEVICE_NOT_WORKING,
              `system`,
              MESSAGE_CONSTANTS.MALFUNCTION_OPTIONS,
              true,
              { enquiryType: 'malfunction' }
            ),
          ],
        });
      } else {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.DEVICE_NOT_WORKING,
              `system`,
              MESSAGE_CONSTANTS.MALFUNCTION_OPTIONS,
              true,
              { enquiryType: 'malfunction' }
            ),
          ],
        });
      }
    } else if (CONSTANTS.THANKS_FOR_CONTACTING === source) {
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.THANKS_FOR_CONTACTING,
          false
        ),
      ]);
      if (typedText === 'No') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.JOURNEY_ENDED,
            `system`,
            { endReason: CONSTANTS.ENDREASON_LIST.OEM_INWARRANTY_CONFIRMED },
            false
          ),
        ]);
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.AVAIL_DEVICE_SELECTION === source) {
      if (typedText === 'Yes') {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
            this.createMessage(
              CONSTANTS.PROCEED_SERVICE_FEE,
              `system`,
              MESSAGE_CONSTANTS.PROCEED_SERVICE_FEE,
              true,
              { source: CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED }
            ),
          ],
          showBotInput: true,
          botCalledFrom: {
            source: source,
            action: typedText,
          },
        });
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED === source) {
      let content = this.createMessage(
        CONSTANTS.CONFIRM_MOB_NO,
        `system`,
        MESSAGE_CONSTANTS.CONFIRM_MOB_NO,
        true,
        { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION }
      );
      if (this.props.phoneNumber === CONSTANTS.PHONE_NOT_AVAILABLE) {
        content = this.createMessage(
          CONSTANTS.SUBMIT_CONTACT_NUMBER,
          `system`,
          MESSAGE_CONSTANTS.SUBMIT_CONTACT_NUMBER,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO }
        );
      }
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.DEVICE_IN_STOCK, false),
          content,
        ],
      });
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
        MESSAGE_CONSTANTS.SUB_STAGES.VERIFY_ENROLLED_NUMBER_FOR_SMS,
        MESSAGE_CONSTANTS.SUB_STAGES.CHECK_DEVICE_STOCK
      );
      // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.VERIFY_ENROLLED_NUMBER_FOR_SMS, MESSAGE_CONSTANTS.SUB_STAGES.CHECK_DEVICE_STOCK);
    } else if (CONSTANTS.SOURCE_USER_INPUT.SELECT_ADDRESS_CONFIRMATION === source) {
      this.setState({
        lastActivity: EVENTLIST.CONFIRM_PAYMENT_FEE,
        lastUpdatedTime: new Date(),
      });
      if (this.props.backOrderCase) {
        if (!typedText) {
          this.props.isWbkoSelected
            ? this.props.isWbkoSelected &&
              this.addMessageInJourneyList([
                this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
              ])
            : this.addMessageInJourneyList([
                this.createMessage(
                  CONSTANTS.TEXT,
                  `system`,
                  MESSAGE_CONSTANTS.THANKS_FOR_ADDRESS,
                  false
                ),
                this.createMessage(
                  CONSTANTS.PAYMENT_FEE,
                  `system`,
                  MESSAGE_CONSTANTS.PAYMENT_FEE,
                  true,
                  { source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION }
                ),
              ]);
          !this.props.isWbkoSelected &&
            this.props.updateTransitionPanel(
              MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
              MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
              ''
            );
        } else {
          this.props.isWbkoSelected &&
            this.addMessageInJourneyList([
              this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            ]);
        }
      } else {
        if (!typedText) {
          this.props.isWbkoSelected
            ? this.props.isWbkoSelected &&
              this.addMessageInJourneyList([
                this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.WBKO_KEEP, false),
              ])
            : this.addMessageInJourneyList([
                this.createMessage(
                  CONSTANTS.TEXT,
                  `system`,
                  MESSAGE_CONSTANTS.THANKS_FOR_ADDRESS,
                  false
                ),
                this.createMessage(
                  CONSTANTS.PAYMENT_FEE,
                  `system`,
                  MESSAGE_CONSTANTS.PAYMENT_FEE,
                  true,
                  { source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION }
                ),
              ]);
          !this.props.isWbkoSelected &&
            this.props.updateTransitionPanel(
              MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
              MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
              ''
            );
        } else {
          this.props.isWbkoSelected &&
            this.addMessageInJourneyList([
              this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
              this.createMessage(
                CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
                `system`,
                MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
                true,
                {
                  source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION,
                }
              ),
            ]);
        }
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION === source) {
      if (typedText === 'Yes') {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
          ],
        });
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.ENROLLED_ADDRESS_CONFIRMED
        );
        // this.props.updateTransitionPanel(25, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.ENROLLED_ADDRESS_CONFIRMED, '');
      } else if (typedText === 'No') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS,
            `system`,
            MESSAGE_CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CHANGED_DELIVERY_ADDRESS }
          ),
        ]);
        // this.props.updateTransitionPanel(25, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.PROVIDE_NEW_ADDRESS, '');
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.PROVIDE_NEW_ADDRESS
        );
      } else if (typedText === 'Change') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.WBKO_CHANGE, false),
          this.createMessage(
            CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS,
            `system`,
            MESSAGE_CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS,
              selected_option: 'ChangeMyDelivery',
            }
          ),
        ]);
        // this.props.updateTransitionPanel(25, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.PROVIDE_NEW_ADDRESS, '');
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.PROVIDE_NEW_ADDRESS
        );
      }
      if (!typedText) {
        if (!this.props.addressValidationAttemptCount) {
          MIXPANEL.track(EVENTLIST.SHIPPING_ADDRESS_INPUT_FAILURE);
          this.setState({
            journeyMessageList: [
              ...this.state.journeyMessageList,
              this.createMessage(
                CONSTANTS.TEXT,
                `system`,
                MESSAGE_CONSTANTS.INVALID_ADDRESS_ERROR,
                false
              ),
              {
                messageId: uuid(),
                type: CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS,
                author: CONSTANTS.SYSTEM,
                source: CONSTANTS.SOURCE_ENUM.SYSTEM,
                data: MESSAGE_CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS,
                time: messageTime(),
              },
            ],
          });
          this.props.updateTransitionPanel(
            MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
            MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
            MESSAGE_CONSTANTS.SUB_STAGES.PROVIDE_NEW_ADDRESS
          );
          // this.props.updateTransitionPanel(25, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.PROVIDE_NEW_ADDRESS, '');
        } else {
          if (CONSTANTS.MAX_INVALID_ADDRESS_ATTEMPTS > this.props.addressValidationAttemptCount) {
            MIXPANEL.track(EVENTLIST.SHIPPING_ADDRESS_INPUT_FAILURE);
            this.setState({
              journeyMessageList: [
                ...this.state.journeyMessageList,
                this.createMessage(
                  CONSTANTS.TEXT,
                  `system`,
                  MESSAGE_CONSTANTS.INVALID_ADDRESS_ERROR,
                  false
                ),
                {
                  messageId: uuid(),
                  type: CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS,
                  author: CONSTANTS.SYSTEM,
                  source: CONSTANTS.SOURCE_ENUM.SYSTEM,
                  data: MESSAGE_CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS,
                  time: messageTime(),
                },
              ],
              lastActivity: EVENTLIST.SHIPPING_ADDRESS_INPUT_FAILURE,
              lastUpdatedTime: new Date(),
            });
          } else {
            if (this.props.addressRetryAttempt === false) {
              this.addMessageInJourneyList([
                this.createMessage(
                  CONSTANTS.FINAL_CONFIRM_ENROLLED_ADDRESS,
                  `system`,
                  MESSAGE_CONSTANTS.FINAL_CONFIRM_ENROLLED_ADDRESS,
                  true,
                  {
                    source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
                    isFinalConfirmedEnrolledAddr: true,
                  }
                ),
              ]);
            } else {
              MIXPANEL.track(EVENTLIST.SHIPPING_ADDRESS_INPUT_FAILURE);
              this.setState({
                journeyMessageList: [
                  ...this.state.journeyMessageList,
                  this.createMessage(
                    CONSTANTS.ADDRESS_ATTEMPT_ERROR,
                    `system`,
                    MESSAGE_CONSTANTS.ADDRESS_ATTEMPT_ERROR,
                    true,
                    {
                      source: CONSTANTS.SOURCE_USER_INPUT.ADDRESS_ATTEMPT_ERROR,
                    }
                  ),
                ],
                chatReason: CONSTANTS.CHAT_REASON.ADDRESS,
                source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
                actionMade: 'SubmitNewAddress',
                lastActivity: EVENTLIST.SHIPPING_ADDRESS_INPUT_FAILURE,
                lastUpdatedTime: new Date(),
              });
              this.assignToAgentIfAvailable();
            }
          }
        }
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.QAS_API_FAILURE === source) {
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.FINAL_CONFIRM_ENROLLED_ADDRESS,
          `system`,
          MESSAGE_CONSTANTS.FINAL_CONFIRM_ENROLLED_ADDRESS,
          true,
          {
            source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
            isFinalConfirmedEnrolledAddr: true,
          }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION === source) {
      if (typedText === 'Yes') {
        this.setState({
          lastActivity: EVENTLIST.CONFIRM_ENROLLED_ADDRESS,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
          this.createMessage(
            CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
            }
          ),
          // this.createMessage(CONSTANTS.CONFIRM_SIM_CARD, `system`, MESSAGE_CONSTANTS.CONFIRM_SIM_CARD, true, { source: CONSTANTS.SOURCE_USER_INPUT.SIM_CARD_CONFIRMATION }),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_VERIFIED
        );
        // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.SIM_DELIVERY_CONFIRMATION, MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_VERIFIED);
      } else if (typedText === 'SkipToPostal') {
        // MIXPANEL.track(EVENTLIST.SHIPPING);
        this.setState({
          lastActivity: EVENTLIST.CONFIRM_ENROLLED_ADDRESS,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
          this.createMessage(
            CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
            }
          ),
        ]);
      } else if (typedText === 'No') {
        this.setState({
          lastActivity: EVENTLIST.EMAIL_ADDRESS_INPUT,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.INSTRUCTION_PROVIDE_EMAIL,
            false
          ),
          this.createMessage(
            CONSTANTS.SUBMIT_EMAIL,
            `system`,
            MESSAGE_CONSTANTS.SUBMIT_EMAIL,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.NEW_EMAIL
        );
        // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.NEW_EMAIL, '');
      } else {
        this.setState({
          lastActivity: EVENTLIST.CONFIRM_ENROLLED_ADDRESS,
          lastUpdatedTime: new Date(),
        });

        let JourneyList = [...this.state.journeyMessageList];
        if (typedText)
          JourneyList.push(this.createMessage(CONSTANTS.TEXT, `user`, typedText, false));

        JourneyList.push(
          this.createMessage(
            CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
            }
          )
        );
        this.setState({
          showMDNUserInput: false,
          showNRICUserInput: false,
          journeyMessageList: JourneyList,
        });
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION === source) {
      if (typedText === 'Yes') {
        this.setState({
          lastActivity: EVENTLIST.CONFIRM_EMAIL_ADDRESS,
          lastUpdatedTime: new Date(),
        });
        //let content = { messageId: uuid(), type: CONSTANTS.CONFIRM_EMAIL_ADDRESS, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.CONFIRM_EMAIL_ADDRESS, time: messageTime() }
        let content = this.createMessage(
          CONSTANTS.CONFIRM_EMAIL_ADDRESS,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_EMAIL_ADDRESS,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION }
        );
        if (
          !this.props.emailAddress ||
          (this.props.emailAddress && this.props.emailAddress === CONSTANTS.EMAIL_NOT_AVAILABLE)
        ) {
          content = this.createMessage(
            CONSTANTS.SUBMIT_EMAIL,
            `system`,
            MESSAGE_CONSTANTS.SUBMIT_EMAIL,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL }
          );
          //content = { messageId: uuid(), type: CONSTANTS.SUBMIT_EMAIL, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.SUBMIT_EMAIL, time: messageTime() }
        }
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
          content,
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.MOBILE_NO_VERIFIED
        );
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.VERIFY_ENROLLED_EMAIL
        );
        // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.VERIFY_ENROLLED_EMAIL, MESSAGE_CONSTANTS.SUB_STAGES.MOBILE_NO_VERIFIED);
      } else if (typedText === 'No') {
        this.setState({
          lastActivity: EVENTLIST.MOBILE_NUMBER_INPUT,
          lastUpdatedTime: new Date(),
        });
        let msgArr = [
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.INSTRUCTION_PROVIDE_MOB_NO,
            false
          ),
          this.createMessage(
            CONSTANTS.SUBMIT_CONTACT_NUMBER,
            `system`,
            MESSAGE_CONSTANTS.SUBMIT_CONTACT_NUMBER,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO }
          ),
        ];
        this._showLine(this, 0, msgArr, msgArr.length, null, 100);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.NEW_CONTACT
        );
        // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.NEW_CONTACT);
      } else {
        let content = this.createMessage(
          CONSTANTS.CONFIRM_EMAIL_ADDRESS,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_EMAIL_ADDRESS,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION }
        );
        if (
          !this.props.emailAddress ||
          (this.props.emailAddress && this.props.emailAddress === CONSTANTS.EMAIL_NOT_AVAILABLE)
        ) {
          content = this.createMessage(
            CONSTANTS.SUBMIT_EMAIL,
            `system`,
            MESSAGE_CONSTANTS.SUBMIT_EMAIL,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL }
          );
          //content = { messageId: uuid(), type: CONSTANTS.SUBMIT_EMAIL, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.SUBMIT_EMAIL, time: messageTime() }
        }
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          content,
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.VERIFY_ENROLLED_EMAIL,
          MESSAGE_CONSTANTS.SUB_STAGES.MOBILE_NO_VERIFIED
        );
        // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.VERIFY_ENROLLED_EMAIL, MESSAGE_CONSTANTS.SUB_STAGES.MOBILE_NO_VERIFIED);
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION === source) {
      this.setState({
        lastActivity: EVENTLIST.CREDIT_CARD_INPUT,
        lastUpdatedTime: new Date(),
      });
      if (typedText === 'Proceed') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        ]);
      } else if (!typedText) {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.WEB_CREDITCARD_FORM,
            `system`,
            MESSAGE_CONSTANTS.WEB_CREDITCARD_FORM,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.CREDIT_CARD_DETAILS_SUBMITTED,
            }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.ENTER_CC_DETAILS
        );
        // this.props.updateTransitionPanel(90, MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT, MESSAGE_CONSTANTS.SUB_STAGES.ENTER_CC_DETAILS, '');
      }
    } else if (CONSTANTS.PAYMENT_FEE === source) {
      if (typedText === MESSAGE_CONSTANTS.PAYMENT_CANCELLED_PROCEED) {
        this.setState({
          lastActivity: typedText,
          lastUpdatedTime: new Date(),
        });
      }
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(CONSTANTS.PAYMENT_FEE, `system`, MESSAGE_CONSTANTS.PAYMENT_FEE, true, {
          source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION,
        }),
      ]);
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
        MESSAGE_CONSTANTS.SUB_STAGES.ENTER_CC_DETAILS
      );
    } else if (CONSTANTS.SOURCE_USER_INPUT.CREDIT_CARD_DETAILS_SUBMITTED === source) {
      this.setState({
        lastActivity: EVENTLIST.CREDIT_CARD_SUBMITTED,
        lastUpdatedTime: new Date(),
      });
      MIXPANEL.track(EVENTLIST.ORDER_REVIEW, null, function () {
        MIXPANEL.time_event(EVENTLIST.ORDER_CONFIRMATION);
      });
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        // this.createMessage(CONSTANTS.PAYMENT_BEING_VERIFIED, `system`, MESSAGE_CONSTANTS.PAYMENT_BEING_VERIFIED, true)
        //this.createMessage(CONSTANTS.PAYMENT_FEE, `system`, MESSAGE_CONSTANTS.PAYMENT_FEE, true, CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION)
      ]);
    } else if (CONSTANTS.SHOW_PAYMENT_FEE_SKIP_DELIVERY === source) {
      this.setState({
        lastActivity: EVENTLIST.PAYMENT_FEE,
        lastUpdatedTime: new Date(),
      });
      if (typedText) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(CONSTANTS.PAYMENT_FEE, `system`, MESSAGE_CONSTANTS.PAYMENT_FEE, true, {
            source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION,
          }),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.PAYMENT_MODE
        );
      } else {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.PAYMENT_FEE, `system`, MESSAGE_CONSTANTS.PAYMENT_FEE, true, {
            source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION,
          }),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.PAYMENT_MODE
        );
      }
    } else if (CONSTANTS.NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT === source) {
      MIXPANEL.track(EVENTLIST.REQUEST_UNPROCESSED_CONTACT_HOTLINE);
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.DEVICE_NOT_ELIGIBLE,
            `system`,
            MESSAGE_CONSTANTS.DEVICE_NOT_ELIGIBLE,
            true,
            { source: CONSTANTS.DEVICE_NOT_ELIGIBLE }
          ),
        ],
        lastActivity: EVENTLIST.NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT,
        lastUpdatedTime: new Date(),
      });
    } else if (CONSTANTS.VOID_SCRIPT === source) {
      MIXPANEL.track(EVENTLIST.REQUEST_UNPROCESSED_CONTACT_HOTLINE);
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.DEVICE_NOT_ELIGIBLE,
            `system`,
            MESSAGE_CONSTANTS.DEVICE_NOT_ELIGIBLE,
            true,
            { source: CONSTANTS.VOID_SCRIPT }
          ),
        ],
        lastActivity: EVENTLIST.NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT,
        lastUpdatedTime: new Date(),
      });
    } else if (CONSTANTS.NOT_PROCEED_FURTHER === source) {
      MIXPANEL.track(EVENTLIST.REQUEST_UNPROCESSED_CONTACT_HOTLINE);
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.TEXT,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: { text: MESSAGE_CONSTANTS.NOT_PROCEED_FURTHER },
            time: messageTime(),
          },
          {
            messageId: uuid(),
            type: CONSTANTS.JOURNEY_ENDED,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: {
              endReason: CONSTANTS.ENDREASON_LIST.REQUEST_CANNOT_BE_PROCESSED,
            },
            time: messageTime(),
          },
        ],
        lastActivity: EVENTLIST.REQUEST_UNPROCESSED_CONTACT_HOTLINE,
        lastUpdatedTime: new Date(),
      });
    } else if (CONSTANTS.ORDER_UNDER_REVIEW === source) {
      MIXPANEL.track(EVENTLIST.ORDER_CONFIRMATION, null, function () {
        MIXPANEL.track(EVENTLIST.ORDER_UNDER_REVIEW);
      });
      this.setState({
        lastActivity: EVENTLIST.ORDER_UNDER_REVIEW,
        lastUpdatedTime: new Date(),
      });
      let msgArr = [
        {
          messageId: uuid(),
          type: CONSTANTS.TEXT,
          author: CONSTANTS.SYSTEM,
          source: CONSTANTS.SOURCE_ENUM.SYSTEM,
          data: { text: MESSAGE_CONSTANTS.ORDER_CONFIRMED },
          time: messageTime(),
        },
        {
          messageId: uuid(),
          type: CONSTANTS.TEXT,
          author: CONSTANTS.SYSTEM,
          source: CONSTANTS.SOURCE_ENUM.SYSTEM,
          data: { text: MESSAGE_CONSTANTS.PAYMENT_ORDER_PENDING },
          time: messageTime(),
        },
      ];
      this._showLine(this, 0, msgArr, msgArr.length, this._farewellMessages, 100);
      //this._farewellMessages();
    } else if (CONSTANTS.ORDER_UNDER_REVIEW_COD === source) {
      MIXPANEL.track(EVENTLIST.ORDER_CONFIRMATION, null, function () {
        MIXPANEL.track(EVENTLIST.ORDER_UNDER_REVIEW);
      });

      let msgArr = [
        {
          messageId: uuid(),
          type: CONSTANTS.TEXT,
          author: CONSTANTS.SYSTEM,
          source: CONSTANTS.SOURCE_ENUM.SYSTEM,
          data: { text: MESSAGE_CONSTANTS.ORDER_CONFIRMED },
          time: messageTime(),
        },
        {
          messageId: uuid(),
          type: CONSTANTS.TEXT,
          author: CONSTANTS.SYSTEM,
          source: CONSTANTS.SOURCE_ENUM.SYSTEM,
          data: { text: MESSAGE_CONSTANTS.PAYMENT_ORDER_PENDING_COD },
          time: messageTime(),
        },
      ];
      this._showLine(this, 0, msgArr, msgArr.length, this._farewellMessages, 100);
    } else if (CONSTANTS.SOURCE_USER_INPUT.CHANGED_DELIVERY_ADDRESS === source) {
    } else if (CONSTANTS.CONFIRM_DETAILS === source) {
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.CONFIRM_DETAILS,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_DETAILS,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.CONFIRMED_DETAILS }
        ),
      ]);
    } else if (CONSTANTS.PAYMENT_VERIFIED === source) {
      MIXPANEL.track(EVENTLIST.PAYMENT);
      this.setState({
        lastActivity: EVENTLIST.ORDER_CONFIRMATION,
        lastUpdatedTime: new Date(),
      });

      let msgArr;
      if (this.props.paymentMethod === 'cod' || !this.props.cardType) {
        msgArr = [
          // this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.CONFIRM_SR_BELOW, false),
        ];
        this._showLine(this, 0, msgArr, msgArr.length, this._stepCheckBackorderCase, 100);
      } else {
        //  typedText ?
        msgArr = [
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.CONFIRM_SR_BELOW, false),
        ];

        this._showLine(this, 0, msgArr, msgArr.length, this._stepCheckBackorderCase, 100);
      }
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.ORDER_REVIEW.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.ORDER_REVIEW.label,
        MESSAGE_CONSTANTS.SUB_STAGES.ORDER_CONFIRMATION
      );
      // this.props.updateTransitionPanel(95, MESSAGE_CONSTANTS.STAGE_NAME.ORDER_REVIEW, MESSAGE_CONSTANTS.SUB_STAGES.ORDER_CONFIRMATION);
    } else if (CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION === source) {
      if (typedText === 'Keep' || typedText === 'Confirm' || typedText === 'ยืนยัน\nวันจัดส่ง') {
        this.setState({
          lastActivity: EVENTLIST.DELIVERY_SCHEDULE_CONFIRMATION,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          // this.createMessage(CONSTANTS.PAYMENT_FEE, `system`, MESSAGE_CONSTANTS.PAYMENT_FEE, true, { source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION })
        ]);

        let srvFee =
          this.props.serviceFee.CurrencyCode +
          ' ' +
          getFormattedTotalFee(this.props.serviceFee.TotalAmount);
        var schDate = this.props.shippingMethodsResult.EstimatedDeliveryDate;
        let slot = this.props.shippingMethodsResult.ShippingMethodType;
        schDate = getFormattedDeliveryDate(schDate, slot);

        if (this.props.selectedDeliveryDate) {
          var date = new Date(this.props.selectedDeliveryDate);
          schDate = date.toDateString();
        }
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.SHIPPING.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.SHIPPING.label,
          '',
          MESSAGE_CONSTANTS.SUB_STAGES.DELIVERY_SCHEDULE_CONFIRMED + ':' + schDate
        );
        // this.props.updateTransitionPanel(50, MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT, "Payment Fee: " + srvFee, "Confirmed Schedule: " + schDate);
      } else {
        let isSchVisible = false;
        this.setState({
          lastActivity: EVENTLIST.CHANGE_DELIVERY_DATE,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.WEB_SCHEDULE_FORM,
            `system`,
            MESSAGE_CONSTANTS.WEB_SCHEDULE_FORM,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.NEW_DELIVERY_SCHEDULE_SUBMITTED,
            }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
          MESSAGE_CONSTANTS.SUB_STAGES.NEW_DELIVERY_SCHEDULE
        );
        // this.props.updateTransitionPanel(50, MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT, MESSAGE_CONSTANTS.SUB_STAGES.NEW_DELIVERY_SCHEDULE, '');
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.NEW_DELIVERY_SCHEDULE_SUBMITTED === source) {
      this.setState({
        lastActivity: EVENTLIST.NEW_DELIVERY_SCHEDULE_SUBMITTED,
        lastUpdatedTime: new Date(),
      });
      let newSch = todaysDate();
      if (this.props.selectedDeliveryDate) {
        newSch = this.props.selectedDeliveryDate;
      }
      newSch = newSch.split(' ');
      let newSchdeule = newSch[0] + ' ' + newSch[1] + ' ' + newSch[2] + ' ' + newSch[3];
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.TEXT,
          `user`,
          this.props.isWbkoSelected ? newSchdeule : typedText,
          false
        ),
        this.createMessage(
          CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION }
        ),
      ]);
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
        MESSAGE_CONSTANTS.SUB_STAGES.NEW_SCHEDULE_SUBMITTED + newSchdeule
      );
      // this.props.updateTransitionPanel(50, MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT, MESSAGE_CONSTANTS.SUB_STAGES.NEW_SCHEDULE_SUBMITTED, "New Schedule: " + newSchdeule);
    } else if (CONSTANTS.CONTINUE_PREVIOUS_SR === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.CONTINUE_PREVIOUS_SR,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: MESSAGE_CONSTANTS.RESUME_SERVICE_REQUEST,
            time: messageTime(),
          },
        ],
      });
    } else if (CONSTANTS.COD_FOR_CREDIT_CARD === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.COD_FOR_CREDIT_CARD,
            `system`,
            MESSAGE_CONSTANTS.COD_FOR_CREDIT_CARD,
            true,
            { source: CONSTANTS.COD_FOR_CREDIT_CARD }
          ),
          // { messageId: uuid(), type: CONSTANTS.COD_FOR_CREDIT_CARD, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.COD_FOR_CREDIT_CARD, time: messageTime() }
        ],
        lastActivity: EVENTLIST.PROCEED_WITH_COD,
        lastUpdatedTime: new Date(),
      });
    } else if (CONSTANTS.ALTERNATE_DEVICE_LIST === source) {
      if (typedText === CONSTANTS.WAIT_FOR_ENROLLED_DEVICE) {
        MIXPANEL.track(EVENTLIST.BACK_ORDER);
        this.setState({
          lastActivity: EVENTLIST.BACK_ORDER,
          lastUpdatedTime: new Date(),
        });
        //let content = { messageId: uuid(), type: CONSTANTS.CONFIRM_MOB_NO, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.CONFIRM_MOB_NO, time: messageTime() };
        let content = this.createMessage(
          CONSTANTS.CONFIRM_MOB_NO,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_MOB_NO,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION }
        );
        if (this.props.phoneNumber === CONSTANTS.PHONE_NOT_AVAILABLE) {
          content = this.createMessage(
            CONSTANTS.SUBMIT_CONTACT_NUMBER,
            `system`,
            MESSAGE_CONSTANTS.SUBMIT_CONTACT_NUMBER,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO }
          );
          //content = { messageId: uuid(), type: CONSTANTS.SUBMIT_CONTACT_NUMBER, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.SUBMIT_CONTACT_NUMBER, time: messageTime() }
        }
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            {
              messageId: uuid(),
              type: CONSTANTS.TEXT,
              author: this.props.customerName.fullName,
              source: CONSTANTS.SOURCE_ENUM.USER,
              data: { text: MESSAGE_CONSTANTS.WAIT_FOR_DEVICE },
              time: messageTime(),
            },
            {
              messageId: uuid(),
              type: CONSTANTS.TEXT,
              author: CONSTANTS.SYSTEM,
              source: CONSTANTS.SOURCE_ENUM.SYSTEM,
              data: { text: MESSAGE_CONSTANTS.WAIT_FOR_ENROLLED_DEVICE },
              time: messageTime(),
            },
            content,
          ],
        });
      } else if (typedText === CONSTANTS.ALTERNATIVE_DEVICES_MESSAGE) {
        this.setState({
          lastActivity: EVENTLIST.CHECK_ALTERNATE_DEVICE,
          lastUpdatedTime: new Date(),
        });
        const { deviceList } = this.props;
        let deviceInStock = _.find(deviceList, { IsInStock: true });
        if (deviceInStock) {
          this.addMessageInJourneyList([
            this.createMessage(
              CONSTANTS.TEXT,
              `user`,
              MESSAGE_CONSTANTS.CHECK_ALTERNATE_DEVICE,
              false
            ),
            this.createMessage(
              CONSTANTS.TEXT,
              `system`,
              MESSAGE_CONSTANTS.PLEASE_SELECT_ALT_DEVICE,
              false
            ),
            this.createMessage(
              CONSTANTS.ALTERNATE_DEVICE_LIST,
              `system`,
              CONSTANTS.ALTERNATE_DEVICE_LIST,
              true,
              { source: CONSTANTS.ALTERNATE_DEVICE_LIST }
            ),
          ]);
        } else {
          this.setState({
            lastActivity: EVENTLIST.NO_DEVICE_IN_STOCK,
            lastUpdatedTime: new Date(),
          });
          this.addMessageInJourneyList([
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.TEXT,
              `system`,
              MESSAGE_CONSTANTS.NO_DEVICE_IN_STOCK,
              false
            ),
            this.createMessage(
              CONSTANTS.TEXT,
              `system`,
              MESSAGE_CONSTANTS.SURVEY_CONFIRMATION.CONTENT1,
              false
            ),
            this.createMessage(
              CONSTANTS.ASK_FOR_FEEDBACK,
              `system`,
              MESSAGE_CONSTANTS.ASK_FOR_FEEDBACK,
              true,
              { source: CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION }
            ),
          ]);
        }
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          MESSAGE_CONSTANTS.SUB_STAGES.DISPLAY_ALTERNATE_DEVICES
        );
        // this.props.updateTransitionPanel(15, MESSAGE_CONSTANTS.STAGE_NAME.OFFER, MESSAGE_CONSTANTS.SUB_STAGES.DISPLAY_ALTERNATE_DEVICES, '');
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.SIM_CARD_CONFIRMATION === source) {
      this.setState({
        lastActivity: EVENTLIST.SIM_CARD_CONFIRMATION,
        lastUpdatedTime: new Date(),
      });
      if (typedText === 'Yes') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.SIM_REQUIRED, false),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.SUB_STAGES.SIM_DELIVERY_NEEDED
        );
        // this.props.updateTransitionPanel(18, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.ENROLLED_ADDRESS_CONFIRMATION, MESSAGE_CONSTANTS.SUB_STAGES.SIM_DELIVERY_NEEDED);
      }

      if (typedText === 'No') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.SUB_STAGES.SIM_DELIVERY_NOT_NEEDED
        );
      } else if (typedText === 'Cancelled' || typedText === MESSAGE_CONSTANTS.CANCELLED) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
            }
          ),
        ]);
      }
      if (!typedText) {
        // MIXPANEL.track(EVENTLIST.SHIPPING);
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
            }
          ),
        ]);
      }
    } else if (source === CONSTANTS.RESUME_POINT_CONFIRM_CONTACT_POINTS) {
      let content = this.createMessage(
        CONSTANTS.CONFIRM_MOB_NO,
        `system`,
        MESSAGE_CONSTANTS.CONFIRM_MOB_NO,
        true,
        { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION }
      );
      this.setState({
        lastActivity: EVENTLIST.CONFIRM_MOBILE_NUMBER,
        lastUpdatedTime: new Date(),
      });
      if (this.props.phoneNumber === CONSTANTS.PHONE_NOT_AVAILABLE) {
        this.setState({
          lastActivity: EVENTLIST.MOBILE_NUMBER_INPUT,
          lastUpdatedTime: new Date(),
        });
        content = this.createMessage(
          CONSTANTS.SUBMIT_CONTACT_NUMBER,
          `system`,
          MESSAGE_CONSTANTS.SUBMIT_CONTACT_NUMBER,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO }
        );
        //content = { messageId: uuid(), type: CONSTANTS.SUBMIT_CONTACT_NUMBER, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.SUBMIT_CONTACT_NUMBER, time: messageTime() }
      }
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        content,
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.ASK_FOR_SURVEY_CONFIRMATION === source) {
      if (typedText === 'Yes') {
        this.props.showFeedback();
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.YES_TEXT, false),
        ]);
      } else if (typedText === 'No') {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.NO_TEXT, false),
          this.createMessage(
            CONSTANTS.JOURNEY_ENDED,
            `system`,
            { endReason: CONSTANTS.ENDREASON_LIST.SURVEY_SKIPPED },
            true
          ),
        ]);
      }

      if (!typedText) {
        this.props.showFeedback();
      }
    } else if (CONSTANTS.SURVEY_QUESTION_RATING === source) {
      MIXPANEL.track(EVENTLIST.SURVEY_REQUESTED);
      this.setState({
        lastActivity: EVENTLIST.SURVEY_REQUESTED,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.SURVEY_QUESTION_RATING,
          `system`,
          MESSAGE_CONSTANTS.SURVEY_QUESTION_RATING,
          true,
          { source: CONSTANTS.SURVEY_QUESTION_RATING }
        ),
      ]);
    } else if (CONSTANTS.SURVEY_QUESTION_COMMENTS === source) {
      this.setState({
        lastActivity: EVENTLIST.SURVERY_QUESTION_COMMENT_INPUT,
        lastUpdatedTime: new Date(),
      });
      if (typedText) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.SURVEY_QUESTION_COMMENTS,
            `system`,
            MESSAGE_CONSTANTS.SURVEY_QUESTION_COMMENTS,
            true,
            { source: CONSTANTS.SURVEY_QUESTION_COMMENTS }
          ),
        ]);
      } else {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.SURVEY_QUESTION_COMMENTS,
            `system`,
            MESSAGE_CONSTANTS.SURVEY_QUESTION_COMMENTS,
            true,
            { source: CONSTANTS.SURVEY_QUESTION_COMMENTS }
          ),
        ]);
      }
    } else if (CONSTANTS.SURVEY_QUESTION_EXPERIENCE === source) {
      this.setState({
        lastActivity: EVENTLIST.SURVERY_QUESTION_EXPERIENCE_INPUT,
      });
      if (typedText) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.SURVEY_QUESTION_EXPERIENCE,
            `system`,
            MESSAGE_CONSTANTS.SURVEY_QUESTION_EXPERIENCE,
            true,
            { source: CONSTANTS.SURVEY_QUESTION_EXPERIENCE }
          ),
        ]);
      } else {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.SURVEY_QUESTION_EXPERIENCE,
            `system`,
            MESSAGE_CONSTANTS.SURVEY_QUESTION_EXPERIENCE,
            true,
            { source: CONSTANTS.SURVEY_QUESTION_EXPERIENCE }
          ),
        ]);
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.BACK_TO_HOME_PAGE === source) {
      if (!typedText) {
        setTimeout(() => {
          this.props.desktopConversationEnd();
        }, 3000);
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.CONFIRMED_DETAILS === source) {
      if (typedText === 'Confirmed') {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            {
              messageId: uuid(),
              type: CONSTANTS.TEXT,
              author: this.props.customerName.fullName,
              source: CONSTANTS.SOURCE_ENUM.USER,
              data: { text: MESSAGE_CONSTANTS.SUB_STAGES.ORDER_CONFIRMED },
              time: messageTime(),
            },
            // { messageId: uuid(), type: CONSTANTS.PROCESS_PAYMENT_LOADER, author: CONSTANTS.SYSTEM, data: CONSTANTS.PROCESS_PAYMENT_LOADER, time: messageTime() },
          ],
        });
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.label,
          MESSAGE_CONSTANTS.SUB_STAGES.SURVEY
        );
      } else if (!typedText) {
        MIXPANEL.track(EVENTLIST.ORDER_CONFIRMATION);
        this.setState({
          lastActivity: EVENTLIST.ORDER_CONFIRMATION,
          lastUpdatedTime: new Date(),
        });
        let msgArr = [
          {
            messageId: uuid(),
            type: CONSTANTS.TEXT,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: { text: MESSAGE_CONSTANTS.SR_CONFIRMATION },
            time: messageTime(),
          },
          {
            messageId: uuid(),
            type: CONSTANTS.TEXT,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: { text: MESSAGE_CONSTANTS.ORDER_APPROVED },
            time: messageTime(),
          },
        ];
        this._showLine(this, 0, msgArr, msgArr.length, this._farewellMessages, 100);

        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.ORDER_REVIEW.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.ORDER_REVIEW.label,
          MESSAGE_CONSTANTS.SUB_STAGES.SURVEY,
          MESSAGE_CONSTANTS.SUB_STAGES.ORDER_CONFIRMED
        );
        // this.props.updateTransitionPanel(100, MESSAGE_CONSTANTS.STAGE_NAME.ORDER_REVIEW, MESSAGE_CONSTANTS.SUB_STAGES.SURVEY, MESSAGE_CONSTANTS.SUB_STAGES.ORDER_CONFIRMED);
        //this._farewellMessages()
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION === source) {
      this.props.showFeedback();
    } else if (CONSTANTS.SOURCE_USER_INPUT.CHAT_CONFIRMATION === source) {
      if (typedText === 'Yes') {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            {
              messageId: uuid(),
              type: CONSTANTS.TEXT,
              author: this.props.customerName.fullName,
              source: CONSTANTS.SOURCE_ENUM.USER,
              data: { text: typedText },
              time: messageTime(),
            },
          ],
        });
        this.assignToAgentIfAvailable();
      } else {
        this.setState({
          showGetHelp: true,
        });
        // Resume online journey
        this._updateJourneyList(this.state.source, this.state.actionMade);
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS === source) {
      if (!typedText) {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.CURRENT_DEVICE_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
              true,
              { source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS }
            ),
            //{ messageId: uuid(), type: CONSTANTS.CURRENT_DEVICE_OPTIONS, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS, time: messageTime() }
          ],
        });
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          '',
          typedText
        );
      } else {
        this.setState({
          journeyMessageList: [
            ...this.state.journeyMessageList,
            {
              messageId: uuid(),
              type: CONSTANTS.TEXT,
              author: this.props.customerName.fullName,
              source: CONSTANTS.SOURCE_ENUM.USER,
              data: { text: typedText },
              time: messageTime(),
            },
          ],
        });
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,
          '',
          typedText
        );
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO === source) {
      this.setState({
        lastActivity: EVENTLIST.CONFIRM_EMAIL_ADDRESS,
        lastUpdatedTime: new Date(),
      });
      //let content = { messageId: uuid(), type: CONSTANTS.CONFIRM_EMAIL_ADDRESS, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.CONFIRM_EMAIL_ADDRESS, time: messageTime() }
      let content = this.createMessage(
        CONSTANTS.CONFIRM_EMAIL_ADDRESS,
        `system`,
        MESSAGE_CONSTANTS.CONFIRM_EMAIL_ADDRESS,
        true,
        { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION }
      );
      if (
        !this.props.emailAddress ||
        (this.props.emailAddress && this.props.emailAddress === CONSTANTS.EMAIL_NOT_AVAILABLE)
      ) {
        this.setState({
          lastActivity: EVENTLIST.EMAIL_ADDRESS_INPUT,
          lastUpdatedTime: new Date(),
        });
        content = this.createMessage(
          CONSTANTS.SUBMIT_EMAIL,
          `system`,
          MESSAGE_CONSTANTS.SUBMIT_EMAIL,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL }
        );
        //content = this.createMessage(CONSTANTS.SUBMIT_EMAIL, `system`, MESSAGE_CONSTANTS.SUBMIT_EMAIL, true, {source: CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL});
        //content = { messageId: uuid(), type: CONSTANTS.SUBMIT_EMAIL, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.SUBMIT_EMAIL, time: messageTime() }
      }
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.TEXT,
            author: this.props.customerName.fullName,
            source: CONSTANTS.SOURCE_ENUM.USER,
            data: { text: typedText },
            time: messageTime(),
          },
          content,
        ],
      });
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
        MESSAGE_CONSTANTS.SUB_STAGES.MOBILE_NO_VERIFIED
      );
      // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.VERIFY_ENROLLED_EMAIL, MESSAGE_CONSTANTS.SUB_STAGES.MOBILE_NO_VERIFIED);
    } else if (CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL === source) {
      if (this.props.caseType !== CONSTANTS.REPLACEMENT) {
        // can create a util function for checks like this
        // MIXPANEL.track(EVENTLIST.SHIPPING);
        this.setState({
          lastActivity: EVENTLIST.CONFIRM_ENROLLED_ADDRESS,
          lastUpdatedTime: new Date(),
        });
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
            }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
          `${MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_SUBMITTED}: ${typedText}`
        );
        // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, `${MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_SUBMITTED}: ${typedText}`, '');
      } else {
        if (isServiceOrderPreviouslyCalled(this.props.resumePoint)) {
          // MIXPANEL.track(EVENTLIST.SHIPPING);
          this.addMessageInJourneyList([
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
              `system`,
              MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
              }
            ),
          ]);
          this.props.updateTransitionPanel(
            MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
            MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
            `${MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_SUBMITTED}: ${typedText}`
          );
          // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, `${MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_SUBMITTED}: ${typedText}`, '');
        } else {
          this.addMessageInJourneyList([
            this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
            this.createMessage(
              CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
              `system`,
              MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
              }
            ),
            // this.createMessage(CONSTANTS.CONFIRM_SIM_CARD, `system`, MESSAGE_CONSTANTS.CONFIRM_SIM_CARD, true, { source: CONSTANTS.SOURCE_USER_INPUT.SIM_CARD_CONFIRMATION })
          ]);
          this.props.updateTransitionPanel(
            MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
            MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
            MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_VERIFIED,
            MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_SUBMITTED + typedText
          );
          // this.props.updateTransitionPanel(17, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.SIM_DELIVERY_CONFIRMATION, MESSAGE_CONSTANTS.SUB_STAGES.EMAIL_VERIFIED, "New Email ID: " + typedText, '');
        }
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.INVALID_CC_DETAILS === source) {
      MIXPANEL.track(EVENTLIST.PAYMENT_INPUT_FAILURE);
      this.setState({
        lastActivity: EVENTLIST.PAYMENT_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.INVALID_CC_DETAILS, false),
        this.createMessage(
          CONSTANTS.WEB_CREDITCARD_FORM,
          `system`,
          MESSAGE_CONSTANTS.WEB_CREDITCARD_FORM,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.CREDIT_CARD_DETAILS_SUBMITTED }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.CC_ATTEMPT_ERROR === source) {
      MIXPANEL.track(EVENTLIST.PAYMENT_INPUT_FAILURE);
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.CC_ATTEMPT_ERROR,
            `system`,
            MESSAGE_CONSTANTS.CC_ATTEMPT_ERROR,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CC_ATTEMPT_ERROR }
          ),
          //{ messageId: uuid(), type: CONSTANTS.CC_ATTEMPT_ERROR, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.CC_ATTEMPT_ERROR, time: messageTime() }
        ],
        chatReason: CONSTANTS.CHAT_REASON.PAYMENT,
        source: CONSTANTS.SOURCE_USER_INPUT.WEB_CREDITCARD_FORM_REATTEMPT,
        actionMade: this.state.currentAction,
        lastActivity: EVENTLIST.PAYMENT_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
      });
      this.assignToAgentIfAvailable();
    } else if (CONSTANTS.SOURCE_USER_INPUT.PAYMENT_CHARGE_ATTEMPT_ERROR === source) {
      MIXPANEL.track(EVENTLIST.PAYMENT_INPUT_FAILURE);
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.CC_ATTEMPT_ERROR,
            `system`,
            MESSAGE_CONSTANTS.CC_ATTEMPT_ERROR,
            true,
            { source: CONSTANTS.SOURCE_USER_INPUT.CC_ATTEMPT_ERROR }
          ),
          //{ messageId: uuid(), type: CONSTANTS.CC_ATTEMPT_ERROR, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.CC_ATTEMPT_ERROR, time: messageTime() }
        ],
        chatReason: CONSTANTS.CHAT_REASON.PAYMENT,
        source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_CHARGE_FORM_REATTEMPT,
        actionMade: this.state.currentAction,
        lastActivity: EVENTLIST.PAYMENT_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
      });
      this.assignToAgentIfAvailable();
    } else if (
      CONSTANTS.SOURCE_USER_INPUT.WEB_CREDITCARD_FORM_REATTEMPT === source ||
      CONSTANTS.SOURCE_USER_INPUT.PAYMENT_CHARGE_FORM_REATTEMPT === source
    ) {
      if (typedText) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.INVALID_CC_DETAILS, false),
          this.createMessage(
            CONSTANTS.WEB_CREDITCARD_FORM,
            `system`,
            MESSAGE_CONSTANTS.WEB_CREDITCARD_FORM,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.CREDIT_CARD_DETAILS_SUBMITTED,
            }
          ),
        ]);
      } else {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.WEB_CREDITCARD_FORM,
            `system`,
            MESSAGE_CONSTANTS.WEB_CREDITCARD_FORM,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.CREDIT_CARD_DETAILS_SUBMITTED,
            }
          ),
        ]);
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.GRID_VIEW_OPTIONS === source) {
      let { backOrderCase, isWbkoSelected } = this.props;
      let msgArr = [];
      if (backOrderCase) {
        msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.WAIT_FOR_ENROLLED_DEVICE,
            false
          ),
        ];
      }

      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          isWbkoSelected
            ? this.createMessage(CONSTANTS.TEXT, `user`, typedText, false)
            : this.createMessage(
                CONSTANTS.TEXT,
                `system`,
                MESSAGE_CONSTANTS.ELIGIBLE_FOR_SERVICE,
                false
              ),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.USER_CURRENT_DELIVERY_DETAILS,
            false
          ),
          this.createMessage(
            CONSTANTS.CURRENT_REQUEST_DETAILS,
            `system`,
            MESSAGE_CONSTANTS.CURRENT_REQUEST_DETAILS,
            true
          ),
          this.createMessage(
            CONSTANTS.WEB_GRID_VIEW_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.WEB_GRID_VIEW_OPTIONS,
            true
          ),
          ...msgArr,
        ],
      });
    } else if (CONSTANTS.WEB_GRID_VIEW_OPTIONS === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.WEB_GRID_VIEW_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.WBKO_WEB_GRID_VIEW_OPTIONS,
            true
          ),
        ],
      });
    } else if (CONSTANTS.TRANSFERRED_TO_AGENT === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.TRANSFERRED_TO_AGENT,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.INFO,
            data: MESSAGE_CONSTANTS.TRANSFERRED_TO_AGENT,
            time: messageTime(),
          },
        ],
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_DELIVERY_ENQUIRY === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.JOURNEY_ENDED,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: {
              endReason: CONSTANTS.ENDREASON_LIST.RESCHEDULE_DELIVERY_ENQUIRY,
            },
            time: messageTime(),
          },
        ],
      });
      let msgArr;
      if (this.props.isWbkoSelected) {
        let {
          emailAddress,
          phoneNumber,
          keepOrconfirmCount,
          selectedDeliveryDate,
          maskedUpdatedEmailId,
          maskedUpdatedMobileNumber,
        } = this.props;
        let emailMsg = MESSAGE_CONSTANTS.WBKO_RESCHEDULE_DELIVERY_ENQUIRY_EMAIL_MSG.replace(
          '{emailAddress}',
          maskedUpdatedEmailId
        );
        let dateChange = MESSAGE_CONSTANTS.WBKO_DELIVERY_DATE_CHANGE.replace(
          '{emailAddress}',
          maskedUpdatedEmailId
        ).replace('{selectedDeliveryDate}', selectedDeliveryDate);
        let noDateChnage = MESSAGE_CONSTANTS.WBKO_DELIVERY_DATE_NOCHANGE.replace(
          '{emailAddress}',
          maskedUpdatedEmailId
        ).replace('{selectedDeliveryDate}', selectedDeliveryDate);

        let smsMsg = MESSAGE_CONSTANTS.WBKO_RESCHEDULE_DELIVERY_ENQUIRY_SMS_MSG.replace(
          '{phoneNumber}',
          maskedUpdatedMobileNumber
        );
        let faqMsg = MESSAGE_CONSTANTS.WBKO_FAQ_MSG;
        msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            keepOrconfirmCount >= 1 ? dateChange : noDateChnage,
            false
          ),
          this.createMessage(CONSTANTS.TEXT, `system`, smsMsg, false),
          this.createMessage(CONSTANTS.TEXT, `system`, faqMsg, false),
          this.createMessage(
            CONSTANTS.WBKO_TRACK_OR_END,
            `system`,
            MESSAGE_CONSTANTS.WBKO_TRACK_OR_END,
            true
          ),
        ];
      } else {
        msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `user`,
            MESSAGE_CONSTANTS.RESCHEDULE_DELIVERY_ENQUIRY,
            false
          ),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.RESCHEDULE_DELIVERY_ENQUIRY,
            false
          ),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.CANCEL_MY_DELIVERY_ENQUIRY_THANKS,
            false
          ),
        ];
      }
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);

      setTimeout(() => {
        !this.props.isWbkoSelected && this.props.desktopConversationEnd();
      }, 10000);
    } else if (CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_DELIVERY_ADDRESS_ENQUIRY === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.JOURNEY_ENDED,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: {
              endReason: CONSTANTS.ENDREASON_LIST.CHANGE_DELIVERY_ADDRESS_ENQUIRY,
            },
            time: messageTime(),
          },
        ],
      });
      let msgArr;

      if (this.props.isWbkoSelected) {
        let {
          emailAddress,
          phoneNumber,
          keepOrconfirmCount,
          selectedDeliveryDate,
          changedAddress,
          maskedUpdatedEmailId,
          maskedUpdatedMobileNumber,
        } = this.props;
        let emailMsg = MESSAGE_CONSTANTS.WBKO_RESCHEDULE_DELIVERY_ENQUIRY_EMAIL_MSG.replace(
          '{emailAddress}',
          maskedUpdatedEmailId
        );
        let smsMsg = MESSAGE_CONSTANTS.WBKO_RESCHEDULE_DELIVERY_ENQUIRY_SMS_MSG.replace(
          '{phoneNumber}',
          maskedUpdatedMobileNumber
        );
        let faqMsg = MESSAGE_CONSTANTS.WBKO_FAQ_MSG;
        let address = changedAddress
          ? `${changedAddress.Address1 ? changedAddress.Address1 : ''},${
              changedAddress.Address2 ? changedAddress.Address2 : ''
            },${changedAddress.Address3 ? changedAddress.Address3 : ''},${
              changedAddress.PostalCode ? changedAddress.PostalCode : ''
            },${changedAddress.City ? changedAddress.City : ''},${
              changedAddress.StateProvinceCode ? changedAddress.StateProvinceCode : ''
            },${changedAddress.CountryCode === 'TH' ? 'Thailand' : ''}`
          : '';
        let addressChange = MESSAGE_CONSTANTS.WBKO_DELIVERY_ADDRESS_CHANGE.replace(
          '{changedAddress}',
          address
        )
          .replace('{emailAddress}', maskedUpdatedEmailId)
          .replace('{selectedDeliveryDate}', selectedDeliveryDate);
        let noAddressChnage = MESSAGE_CONSTANTS.WBKO_DELIVERY_ADDRESS_NOCHANGE.replace(
          '{emailAddress}',
          maskedUpdatedEmailId
        );

        msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            changedAddress ? addressChange : noAddressChnage,
            false
          ),
          this.createMessage(CONSTANTS.TEXT, `system`, smsMsg, false),
          this.createMessage(CONSTANTS.TEXT, `system`, faqMsg, false),
          this.createMessage(
            CONSTANTS.WBKO_TRACK_OR_END,
            `system`,
            MESSAGE_CONSTANTS.WBKO_TRACK_OR_END,
            true
          ),
        ];
      } else {
        msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.RESCHEDULE_DELIVERY_ADDRESS_ENQUIRY,
            false
          ),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.CANCEL_MY_DELIVERY_ENQUIRY_THANKS,
            false
          ),
        ];
      }
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
      setTimeout(() => {
        !this.props.isWbkoSelected && this.props.desktopConversationEnd();
      }, 10000);
    } else if (CONSTANTS.SOURCE_USER_INPUT.CANCEL_DELIVERY_ENQUIRY === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.JOURNEY_ENDED,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: {
              endReason: CONSTANTS.ENDREASON_LIST.CANCEL_DELIVERY_ENQUIRY,
            },
            time: messageTime(),
          },
        ],
      });
      let msgArr;
      let {
        emailAddress,
        customerCaseNumber,
        deviceDetails,
        WbkoCancellationReasonText,
        maskedUpdatedEmailId,
        determineIncidentResponse,
      } = this.props;
      // let deviceSpecs = getFormattedPopularName(
      //   deviceDetails.AssetCatalog.AssetCatalogName
      // );
      // let selectedDevice = deviceSpecs.modelName + " " + deviceSpecs.specs + " " + deviceSpecs.color;

      let selectedDevice = determineIncidentResponse.PopularName;

      if (this.props.isWbkoSelected) {
        let emailMsg = MESSAGE_CONSTANTS.WBKO_CANCEL_DELIVERY_MSG1.replace(
          '{srNumber}',
          customerCaseNumber
        )
          .replace('{deviceDetails}', selectedDevice)
          .replace('{emailAddress}', maskedUpdatedEmailId);
        let faqMsg = MESSAGE_CONSTANTS.WBKO_FAQ_MSG;

        let { inquiryReason } = this.props;

        msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `user`,
            inquiryReason || WbkoCancellationReasonText,
            false
          ),
          this.createMessage(CONSTANTS.TEXT, `system`, emailMsg, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.WBKO_CANCEL_DELIVERY_MSG2,
            false
          ),
          this.createMessage(CONSTANTS.TEXT, `system`, faqMsg, false),
          this.createMessage(
            CONSTANTS.WBKO_TRACK_OR_END,
            `system`,
            MESSAGE_CONSTANTS.WBKO_TRACK_OR_END,
            true
          ),
        ];
      } else {
        msgArr = [
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.CANCEL_MY_DELIVERY_ENQUIRY,
            false
          ),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.CANCEL_MY_DELIVERY_ENQUIRY_THANKS,
            false
          ),
        ];
      }
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
      setTimeout(() => {
        !this.props.isWbkoSelected && this.props.desktopConversationEnd();
      }, 10000);
    } else if (CONSTANTS.SOURCE_USER_INPUT.WBKO_UPDATE_CONTACT_DETAILS_ENQUIRY === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.JOURNEY_ENDED,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: {
              endReason: CONSTANTS.ENDREASON_LIST.WBKO_UPDATE_CONTACT_DETAILS_ENQUIRY,
            },
            time: messageTime(),
          },
        ],
      });
      let msgArr;
      if (this.props.isWbkoSelected) {
        let {
          emailAddress,
          phoneNumber,
          wbkoFirstName,
          wbkoIdNumber,
          wbkoMobileNumber,
          maskedUpdatedEmailId,
        } = this.props;
        let maskedMbkoMobileNumber = maskString(wbkoMobileNumber);
        let emailMsg = MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS_MSG1.replace(
          '{fullName}',
          wbkoFirstName
        )
          .replace('{emailAddress}', maskedUpdatedEmailId)
          .replace('{phoneNumber}', maskedMbkoMobileNumber);
        let smsMsg = MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS_MSG2.replace(
          '{phoneNumber}',
          maskedMbkoMobileNumber
        );
        let faqMsg = MESSAGE_CONSTANTS.WBKO_FAQ_MSG;

        let typedText = `${wbkoFirstName} <br/> ${wbkoMobileNumber} <br/> ${wbkoIdNumber} <br/>`;

        msgArr = [
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS_TEXT,
            false
          ),
          this.createMessage(CONSTANTS.TEXT, `system`, emailMsg, false),
          this.createMessage(CONSTANTS.TEXT, `system`, smsMsg, false),
          this.createMessage(CONSTANTS.TEXT, `system`, faqMsg, false),
          this.createMessage(
            CONSTANTS.WBKO_TRACK_OR_END,
            `system`,
            MESSAGE_CONSTANTS.WBKO_TRACK_OR_END,
            true
          ),
          /* this.createMessage(
            CONSTANTS.TEXT,
            `system`,
            MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS_MSG3,
            false
          ) */
        ];
      }
      this._showLine(this, 0, msgArr, msgArr.length, null, 100);
    } else if (CONSTANTS.ASK_FOR_ENQUIRY === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.WEB_GRID_VIEW_OPTIONS,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: MESSAGE_CONSTANTS.WEB_GRID_VIEW_OPTIONS,
            time: new Date(),
          },
        ],
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.ASK_FOR_ENQUIRY_CONFIRMATION === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.ASK_FOR_ENQUIRY,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: MESSAGE_CONSTANTS.ASK_FOR_ENQUIRY,
            time: messageTime(),
          },
        ],
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.WEB_TRACK_MY_DELIVERY === source) {
      MIXPANEL.track(EVENTLIST.TRACK_MY_DELIVERY);
      this.props.createChatEnquiry(
        MESSAGE_CONSTANTS.TRACK_DELIVERY,
        CONSTANTS.ENQUIRY_SUB_REASON.TRACK_AND_TRACE,
        '',
        '',
        '',
        '',
        '',
        '',
        true
      );
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          // this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.ELIGIBLE_FOR_SERVICE, false),
          {
            messageId: uuid(),
            type: CONSTANTS.WEB_TRACK_MY_DELIVERY,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: MESSAGE_CONSTANTS.WEB_TRACK_MY_DELIVERY,
            time: messageTime(),
          },
          {
            messageId: uuid(),
            type: CONSTANTS.ASK_FOR_ENQUIRY,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: MESSAGE_CONSTANTS.ASK_FOR_ENQUIRY,
            time: messageTime(),
          },
          // { messageId: uuid(), type: CONSTANTS.ASK_FOR_SURVEY, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: MESSAGE_CONSTANTS.SURVEY_CONFIRMATION, time: messageTime() }
        ],
        lastActivity: EVENTLIST.TRACK_MY_DELIVERY,
        lastUpdatedTime: new Date(),
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY === source) {
      this.setState({
        lastActivity: EVENTLIST.RESCHEDULE_DELIVERY,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS === source) {
      this.setState({
        lastActivity: EVENTLIST.CHANGE_DELIVERY_ADDRESS,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS,
          `system`,
          MESSAGE_CONSTANTS.CONFIRM_ENROLLED_ADDRESS,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY === source) {
      this.setState({
        lastActivity: EVENTLIST.CANCEL_DELIVERY,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY,
          `system`,
          MESSAGE_CONSTANTS.CANCEL_ENQUIRY,
          true
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.WBKO_UPDATE_CONTACT_DETAILS === source) {
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.SOURCE_USER_INPUT.WBKO_UPDATE_CONTACT_DETAILS,
          `system`,
          MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS,
          true
        ),
      ]);
    } else if (CONSTANTS.WBKO_CANCELLATION_REASON === source) {
      this.addMessageInJourneyList([
        //this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.WBKO_CANCELLATION_REASON,
          `system`,
          MESSAGE_CONSTANTS.WBKO_CANCELLATION_REASON,
          true,
          { source: CONSTANTS.WBKO_CANCELLATION_REASON }
        ),
      ]);
    } else if (CONSTANTS.WBKO_CANCELLATION_REASON_NEW === source) {
      this.addMessageInJourneyList([
        //this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.WBKO_CANCELLATION_REASON_NEW,
          `system`,
          MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelReasons,
          true,
          { source: CONSTANTS.WBKO_CANCELLATION_REASON_NEW }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.NOT_RESOLVED.MDN_NAME_NRIC === source) {
      MIXPANEL.track(EVENTLIST.ENROLLMENT_ISSUE);
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.MDN_NRIC_CLOSE,
            `system`,
            MESSAGE_CONSTANTS.MDN_NRIC_CLOSE,
            true,
            { source: CONSTANTS.MDN_NRIC_CLOSE }
          ),
          // { messageId: uuid(), type: CONSTANTS.TEXT, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: { text: MESSAGE_CONSTANTS.NOT_RESOLVED.MDN_NAME_NRIC }, time: messageTime() },
          // { messageId: uuid(), type: CONSTANTS.JOURNEY_ENDED, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: { endReason: CONSTANTS.ENDREASON_LIST.NO_ENROLLMENT }, time: messageTime() }
        ],
        lastActivity: EVENTLIST.ENROLLMENT_ISSUE,
        lastUpdatedTime: new Date(),
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.NOT_RESOLVED.GENERIC === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(
            CONSTANTS.GENERIC_NOT_RESOLVED,
            `system`,
            MESSAGE_CONSTANTS.GENERIC_NOT_RESOLVED,
            true,
            { source: CONSTANTS.GENERIC_NOT_RESOLVED }
          ),
          this.createMessage(
            CONSTANTS.REQUEST_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.REQUEST_OPTIONS,
            true,
            { enquiryType: 'request' }
          ),
          // { messageId: uuid(), type: CONSTANTS.TEXT, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: { text: MESSAGE_CONSTANTS.NOT_RESOLVED.GENERIC }, time: messageTime() },
          // { messageId: uuid(), type: CONSTANTS.JOURNEY_ENDED, author: CONSTANTS.SYSTEM, source: CONSTANTS.SOURCE_ENUM.SYSTEM, data: { endReason: CONSTANTS.ENDREASON_LIST.CHAT_UNRESOLVED }, time: messageTime() }
        ],
      });
    } else if (CONSTANTS.SOURCE_USER_INPUT.USER_DISCONNECTED === source) {
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.USER_DISCONNECTED,
          `system`,
          MESSAGE_CONSTANTS.USER_DISCONNECTED,
          true,
          { source: CONSTANTS.USER_DISCONNECTED }
        ),
        this.createMessage(
          CONSTANTS.JOURNEY_ENDED,
          `system`,
          { endReason: CONSTANTS.ENDREASON_LIST.AGENT_DISCONNECTED },
          true
        ),
        this.createMessage(
          CONSTANTS.REQUEST_OPTIONS,
          `system`,
          MESSAGE_CONSTANTS.REQUEST_OPTIONS,
          true,
          { enquiryType: 'request' }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.AGENT_DISCONNECTED === source) {
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.AGENT_DISCONNECTED,
          `system`,
          MESSAGE_CONSTANTS.AGENT_DISCONNECTED,
          true,
          { source: CONSTANTS.AGENT_DISCONNECTED }
        ),
        this.createMessage(
          CONSTANTS.JOURNEY_ENDED,
          `system`,
          { endReason: CONSTANTS.ENDREASON_LIST.AGENT_DISCONNECTED },
          true
        ),
        this.createMessage(
          CONSTANTS.REQUEST_OPTIONS,
          `system`,
          MESSAGE_CONSTANTS.REQUEST_OPTIONS,
          true,
          { enquiryType: 'request' }
        ),
      ]);
    } else if (CONSTANTS.ASK_FOR_SURVEY === source) {
      //this.props.setJourneyCmpltd(true, CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED);
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          MESSAGE_CONSTANTS.SURVEY_CONFIRMATION.CONTENT1,
          false
        ),
        this.createMessage(
          CONSTANTS.ASK_FOR_FEEDBACK,
          `system`,
          MESSAGE_CONSTANTS.ASK_FOR_FEEDBACK,
          true,
          { source: CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION }
        ),
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.ASK_TO_BOT === source) {
      this.setState({
        showBotInput: true,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          /* { messageId: uuid(), type: CONSTANTS.TEXT, author: this.props.customerName, source: CONSTANTS.SOURCE_ENUM.USER, data: { text: typedText }, time: messageTime() } */
        ],
      });
    } else if (CONSTANTS.REPLACEMENT_MATRIX_NOT_SET === source) {
      this.setState({
        lastActivity: EVENTLIST.NO_ALTERNATIVE_DEV_INSTOCK,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK,
          `system`,
          MESSAGE_CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK,
          true,
          { source: CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK }
        ),
        // this.createMessage(CONSTANTS.JOURNEY_ENDED, `system`, { endReason: CONSTANTS.ENDREASON_LIST.NO_ALTERNATE_DEVICE }, true)
      ]);
    } else if (CONSTANTS.SOURCE_USER_INPUT.EXCEEDED_CC_VALIDTION_ATTEMPTS === source) {
      MIXPANEL.track(EVENTLIST.PAYMENT_INPUT_FAILURE);
      this.setState({
        lastActivity: EVENTLIST.PAYMENT_INPUT_FAILURE,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `system`, typedText, false),
      ]);
    } else if (source === CONSTANTS.CAPTURE_IW_REMARKS) {
      if (typedText) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.CAPTURE_IW_REMARKS,
            `system`,
            MESSAGE_CONSTANTS.CAPTURE_IW_REMARKS,
            true
          ),
        ]);
      } else {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.CAPTURE_IW_REMARKS,
            `system`,
            MESSAGE_CONSTANTS.CAPTURE_IW_REMARKS,
            true
          ),
        ]);
      }
    } else if (source === CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT) {
      //showing user payment after applying discount of 1% for users confirmation on new amount
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        this.createMessage(
          CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT,
          `system`,
          MESSAGE_CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT,
          true,
          { source: CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT }
        ),
      ]);
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.PAYMENT.label,
        MESSAGE_CONSTANTS.SUB_STAGES.CREDIT_CARD_SEL
      );
      //this.props.getPCITokenInit(this.props.cacheId, this.props.paymentMethod);
    } else if (CONSTANTS.THANKS_FOR_PAYMENT === source) {
      if (this.props.noServiceFee) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, 'Proceed', false),
        ]);
      } else {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.THANKS_FOR_PAYMENT, false),
        ]);
      }
    } else if (CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_FOR_PAYMENT === source) {
      MIXPANEL.track(EVENTLIST.SHIPPING);
      this.setState({
        lastActivity: EVENTLIST.DELIVERY_DATE_SELECTION,
        lastUpdatedTime: new Date(),
      });
      if (!typedText) {
        this.addMessageInJourneyList([
          this.createMessage(
            CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION,
            }
          ),
        ]);
      } else {
        this.addMessageInJourneyList([
          //this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
            `system`,
            MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION,
            }
          ),
        ]);
      }
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.SHIPPING.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.SHIPPING.label,
        MESSAGE_CONSTANTS.SUB_STAGES.CONFIRM_DELIVERY_SCHEDULE
      );
      // this.props.updateTransitionPanel(90, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.CONFIRM_DELIVERY_SCHEDULE, '');
    } else if (CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF === source) {
      if (this.props.noServiceFee) {
        if (typedText) {
          this.addMessageInJourneyList([
            this.createMessage(CONSTANTS.TEXT, author, typedText, false),
            this.createMessage(
              CONSTANTS.PROCEED_SERVICE_FEE,
              `system`,
              MESSAGE_CONSTANTS.PROCEED_SERVICE_FEE,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF,
              }
            ),
          ]);
        } else {
          this.addMessageInJourneyList([
            this.createMessage(
              CONSTANTS.PROCEED_SERVICE_FEE,
              `system`,
              MESSAGE_CONSTANTS.PROCEED_SERVICE_FEE,
              true,
              {
                source: CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF,
              }
            ),
          ]);
        }
      } else {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `system`, MESSAGE_CONSTANTS.THANKS_FOR_ADDRESS, false),
          this.createMessage(
            CONSTANTS.PROCEED_SERVICE_FEE,
            `system`,
            MESSAGE_CONSTANTS.PROCEED_SERVICE_FEE,
            true,
            {
              source: CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF,
            }
          ),
        ]);
      }
      this.props.updateTransitionPanel(
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.percentage,
        MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT.label,
        MESSAGE_CONSTANTS.SUB_STAGES.ADDRESS
      );
      // this.props.updateTransitionPanel(85, MESSAGE_CONSTANTS.STAGE_NAME.FULLFILLMENT, MESSAGE_CONSTANTS.SUB_STAGES.ADDRESS, '');
    } else if (CONSTANTS.CONFIRM_UPLOAD_SR === source) {
      this.setState({
        lastActivity: EVENTLIST.UPLOAD_SERVICE_REPORT,
        lastUpdatedTime: new Date(),
      });
      this.addMessageInJourneyList([
        this.createMessage(
          CONSTANTS.CONFIRM_UPLOAD_SR,
          `system`,
          MESSAGE_CONSTANTS.SELECT_DOWNLOAD_DOCUMENT,
          true
        ),
      ]);
    } else if (CONSTANTS.GENERAL_ENQUIRY_ASSIGN_AGENT_IF_AVAILABLE === source) {
      this.setState({
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.JOURNEY_ENDED,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: {
              endReason: CONSTANTS.ENDREASON_LIST.CANCEL_DELIVERY_ENQUIRY,
            },
            time: messageTime(),
          },
        ],
      });
      setTimeout(() => {
        this.props.desktopConversationEnd();
      }, 8000);
      // this.props.savePayload(SET_CHAT_REASON, CONSTANTS.CHAT_REASON.GENERAL_ENQUIRY);
      // this.setState({
      //   chatReason: CONSTANTS.CHAT_REASON.GENERAL_ENQUIRY,
      //   source: CONSTANTS.GENERAL_ENQUIRY_CREATED_THANKS_FOR_CONTACTING,
      //   actionMade: this.state.currentAction,
      //   showMDNUserInput: false,
      //   showNAMEUserInput: false,
      // });
      // this.assignToAgentIfAvailable(true);
    } else if (CONSTANTS.GET_SERVICE_FEE === source) {
      this.props.createChatEnquiry(
        CONSTANTS.SERVICE_FEE_ENQUIRY,
        CONSTANTS.SERVICE_FEE_SUBTYPE,
        CONSTANTS.SERVICE_FEE_SUBTYPE,
        this.props.customerContact,
        CONSTANTS.SR_FEE_DRIVER_CAT,
        CONSTANTS.SR_FEE_DRIVER_SUB_CAT
      );
      this.setState({
        showBotInput: true,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.GET_SERVICE_FEE,
            author: CONSTANTS.SYSTEM,
            source: CONSTANTS.SOURCE_ENUM.SYSTEM,
            data: MESSAGE_CONSTANTS.GET_SERVICE_FEE,
            time: messageTime(),
          },
        ],
      });
    } else if (CONSTANTS.GET_SERVICE_FEE_PROCEED === source) {
      this.setState({
        showBotInput: true,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          {
            messageId: uuid(),
            type: CONSTANTS.TEXT,
            author: this.props.customerName,
            source: CONSTANTS.SOURCE_ENUM.USER,
            data: { text: MESSAGE_CONSTANTS.CONTINUE },
            time: messageTime(),
          },
          this.createMessage(
            CONSTANTS.REQUEST_OPTIONS,
            `system`,
            MESSAGE_CONSTANTS.REQUEST_OPTIONS,
            true,
            { enquiryType: 'request' }
          ),
        ],
      });
    } else if (CONSTANTS.BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED === source) {
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `system`, typedText, false),
        this.createMessage(
          CONSTANTS.ASK_FOR_ENQUIRY,
          `system`,
          MESSAGE_CONSTANTS.ASK_FOR_ENQUIRY,
          true
        ),
      ]);
    } else if (CONSTANTS.AIS_REASON_NOT_HELPFULL === source) {
      let otherReason = this.props.otherReason;
      let JourneyList = [...this.state.journeyMessageList];
      if (typedText && typedText.enteredReasonDesc)
        JourneyList.push(
          this.createMessage(CONSTANTS.TEXT, `user`, typedText.enteredReasonDesc, false)
        );
      if (typedText.key === CONSTANTS.OTHER_REASON && !otherReason) {
        JourneyList.push(
          this.createMessage(CONSTANTS.ENTER_OTHER_REASON, `system`, true, {
            source: CONSTANTS.ENTER_OTHER_REASON,
            inputState: CONSTANTS.ENTER_OTHER_REASON,
          })
        );
      } else {
        if (otherReason)
          JourneyList.push(this.createMessage(CONSTANTS.TEXT, `user`, otherReason, false));
        JourneyList.push(
          this.createMessage(CONSTANTS.AIS_REASON_NOT_HELPFULL, `system`, true, {
            source: CONSTANTS.SOURCE_USER_INPUT.AIS_REASON_NOT_HELPFULL,
            inputState: CONSTANTS.AIS_REASON_NOT_HELPFULL_STATE,
          })
        );
      }

      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: JourneyList,
      });
    } else if (CONSTANTS.LOAD_AIS_SUBREASON === source) {
      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.LOAD_AIS_SUBREASON, `system`, true, {
            source: CONSTANTS.SOURCE_USER_INPUT.LOAD_AIS_SUBREASON,
            inputState: CONSTANTS.LOAD_AIS_SUBREASON_STATE,
          }),
        ],
      });
    } else if (CONSTANTS.CONFIRMATION_POP_UP === source) {
      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.CONFIRMATION_POP_UP, `system`),
        ],
      });
    } else if (CONSTANTS.ACKNOWLEDGE_MSG_VIEW === source) {
      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.ACKNOWLEDGE_MSG, false),
          this.createMessage(CONSTANTS.ACKNOWLEDGE_MSG_VIEW, `system`),
        ],
      });
    } else if (CONSTANTS.ROUTE_TO_SAVEDESK === source) {
      let { otherReason } = this.props;
      let JourneyListt = [...this.state.journeyMessageList];
      if (typedText)
        JourneyListt.push(this.createMessage(CONSTANTS.TEXT, `user`, typedText, false));
      if (otherReason)
        JourneyListt.push(this.createMessage(CONSTANTS.TEXT, `user`, otherReason, false));
      JourneyListt.push(this.createMessage(CONSTANTS.ROUTE_TO_SAVEDESK, `system`));

      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: JourneyListt,
      });
    } else if (CONSTANTS.ROUTE_TO_REFUND === source) {
      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.ROUTE_TO_REFUND, `system`),
        ],
      });
    } else if (CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS === source) {
      let {
        determineIncidentResponse,
      } = this.props;
      if (determineIncidentResponse.DeliveryWindow) {
        this.setState({
          showMDNUserInput: false,
          showNRICUserInput: false,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.WBKO_TRACK_MY_DELIVERY,
              `system`,
              MESSAGE_CONSTANTS.WBKO_TRACK_MY_DELIVERY,
              true
            ),
            this.createMessage(
              CONSTANTS.WEB_GRID_VIEW_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.WBKO_WEB_GRID_VIEW_OPTIONS,
              true
            ),
          ],
        });
      } else {
        this.setState({
          showMDNUserInput: false,
          showNRICUserInput: false,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.WBKO_TRACK_MY_DELIVERY,
              `system`,
              MESSAGE_CONSTANTS.WBKO_TRACK_MY_DELIVERY,
              true
            ),
          ],
        });
      }
    } else if (CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS_NEW === source) {
      let {
        determineIncidentResponse,
      } = this.props;
      if (determineIncidentResponse.DeliveryWindow) {
        this.setState({
          showMDNUserInput: false,
          showNRICUserInput: false,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.WBKO_TRACK_MY_DELIVERY_NEW,
              `system`,
              MESSAGE_CONSTANTS.WBKO_TRACK_MY_DELIVERY,
              true
            ),
            this.createMessage(
              CONSTANTS.WEB_GRID_VIEW_OPTIONS,
              `system`,
              MESSAGE_CONSTANTS.WBKO_WEB_GRID_VIEW_OPTIONS,
              true
            ),
          ],
        });
      } else {
        this.setState({
          showMDNUserInput: false,
          showNRICUserInput: false,
          journeyMessageList: [
            ...this.state.journeyMessageList,
            this.createMessage(
              CONSTANTS.WBKO_TRACK_MY_DELIVERY_NEW,
              `system`,
              MESSAGE_CONSTANTS.WBKO_TRACK_MY_DELIVERY_NEW,
              true
            )
          ],
        });
      }
      
    } else if (CONSTANTS.WBKO_KEEP === source) {
      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
        ],
      });
      let {
        emailAddress,
        phoneNumber,
        keepOrconfirmCount,
        selectedDeliveryDate,
        maskedUpdatedEmailId,
        maskedUpdatedMobileNumber,
      } = this.props;
      let emailMsg = MESSAGE_CONSTANTS.WBKO_RESCHEDULE_DELIVERY_ENQUIRY_EMAIL_MSG.replace(
        '{emailAddress}',
        maskedUpdatedEmailId
      );
      let dateChange = MESSAGE_CONSTANTS.WBKO_DELIVERY_DATE_CHANGE.replace(
        '{emailAddress}',
        maskedUpdatedEmailId
      ).replace('{selectedDeliveryDate}', selectedDeliveryDate);
      let noDateChnage = MESSAGE_CONSTANTS.WBKO_DELIVERY_DATE_NOCHANGE.replace(
        '{emailAddress}',
        maskedUpdatedEmailId
      ).replace('{selectedDeliveryDate}', selectedDeliveryDate);

      let smsMsg = MESSAGE_CONSTANTS.WBKO_RESCHEDULE_DELIVERY_ENQUIRY_SMS_MSG.replace(
        '{phoneNumber}',
        maskedUpdatedMobileNumber
      );
      let faqMsg = MESSAGE_CONSTANTS.WBKO_FAQ_MSG;
      let msgArr = [
        this.createMessage(
          CONSTANTS.TEXT,
          `system`,
          keepOrconfirmCount >= 1 ? dateChange : noDateChnage,
          false
        ),
        this.createMessage(CONSTANTS.TEXT, `system`, smsMsg, false),
        this.createMessage(CONSTANTS.TEXT, `system`, faqMsg, false),
        this.createMessage(
          CONSTANTS.WBKO_TRACK_OR_END,
          `system`,
          MESSAGE_CONSTANTS.WBKO_TRACK_OR_END,
          true
        ),
      ];
      this.props.changedAddress || keepOrconfirmCount >= 1
        ? ''
        : this._showLine(this, 0, msgArr, msgArr.length, null, 100);
    } else if (CONSTANTS.LEAVING_AIS_POP_UP === source) {
      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: [
          ...this.state.journeyMessageList,
          this.createMessage(CONSTANTS.LEAVING_AIS_POP_UP, `system`),
        ],
      });
    } else if (CONSTANTS.LOAD_THANK_YOU === source) {
      let {
        IsRefund,
        RefundType,
        callBackComment,
        Platform,
        selectedLeavingAISReason,
        save1,
        save2,
        ClientOffer,
        ServiceEffectiveEndDate,
        IsMonthly,
        churnSavedeskAlternateContactNumber,
        otherReason,
      } = this.props;

      let JourneyListMsg = [...this.state.journeyMessageList];
      if (typedText && typedText.selectedCallBackDate) {
        let typed_text = `${MESSAGE_CONSTANTS.SELECTED_CALLBACK_DATE} : ${
          typedText.selectedCallBackDate
        }, <br/> ${MESSAGE_CONSTANTS.SELECTED_CALLBACK_TIME} : ${typedText.selectedCallBackTime}, ${
          typedText.churnSavedeskAlternateContactNumber
            ? ' <br/>' +
              MESSAGE_CONSTANTS.SELECTED_ALTERNATE_CONTACT_NUMBER +
              ' :' +
              typedText.churnSavedeskAlternateContactNumber +
              ','
            : ''
        } <br/> ${MESSAGE_CONSTANTS.ENTERED_COMMENT} : ${typedText.callBackComment} `;
        JourneyListMsg.push(this.createMessage(CONSTANTS.TEXT, `user`, typed_text, false));
      }

      this.setState({
        showMDNUserInput: false,
        showNRICUserInput: false,
        journeyMessageList: JourneyListMsg,
      });

      let Reason = CONSTANTS.CHURN_ONLINE;
      let subReason =
        Platform === 'SaveDesk'
          ? CONSTANTS.SAVEDESK_OUTBOUND
          : typedText.stayWithAIS
          ? CONSTANTS.WINBACK
          : `Churn ${IsRefund ? RefundType : 'No'} Refund - ${IsMonthly ? 'Monthly' : 'Fixed'}`;
      let saveOne = save1 || typedText.save1;
      let saveTwo = save2 || typedText.save2;
      let note = `${otherReason ? ' Other Reason : ' + otherReason : ''} ${
        callBackComment ? '\nNote : ' + callBackComment : ''
      } ${
        Platform === 'Digital'
          ? IsRefund
            ? '\n Refund Type :' + RefundType + ' Refund'
            : '\n Refund Type : No Refund'
          : ''
      } \n Platform : ${Platform} \n Churn Reason : ${selectedLeavingAISReason} \n ProgramName : ${ClientOffer} \n EffectiveEndDate : ${ServiceEffectiveEndDate} ${
        churnSavedeskAlternateContactNumber
          ? ' \n Alternate Contact Number : ' + churnSavedeskAlternateContactNumber
          : ''
      }  ${typedText.stayWithAIS ? '\n Stay With AIS :' + typedText.stayWithAIS : ''} \n ${
        saveOne ? 'Save Scripts :' + saveOne + (saveTwo ? '| ' + saveTwo : '') : ''
      }`;
      /*  Platform ==='SaveDesk' || typedText.stayWithAIS || IsRefund || IsMonthly || (IsMonthly === false && Platform !=='SaveDesk') ? this.props.createChatEnquiry(Reason , subReason, note, this.props.customerContact)
       : this.props.submitEnquiryDetails(note, Reason, note, subReason,this.props.customerContact) */
      Platform === 'SaveDesk'
        ? this.props.submitEnquiryDetails(note, Reason, note, subReason, this.props.customerContact)
        : this.props.createChatEnquiry(Reason, subReason, note, this.props.customerContact);
    } else if (CONSTANTS.UPDATE_ENROLLED_DEVICE === source) {
      if (typedText === MESSAGE_CONSTANTS.UPDATE_ENROLLED_DEVICE) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedText, false),
          this.createMessage(
            CONSTANTS.UPDATE_ENROLLED_DEVICE,
            `system`,
            MESSAGE_CONSTANTS.UPDATE_ENROLLMENT,
            true,
            { source: CONSTANTS.UPDATE_ENROLLED_DEVICE }
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          ''
        );
      }

      const options = MESSAGE_CONSTANTS.UPDATE_ENROLLMENT.buttons.map((b) => b.value) || [];
      const { value: typedValue, text } = typedText;
      if (_.includes(options, typedValue)) {
        this.addMessageInJourneyList([
          this.createMessage(CONSTANTS.TEXT, `user`, typedValue, false),
          this.createMessage(
            CONSTANTS.ENROLLED_DEVICE_ENQUIRY,
            `system`,
            { selectedType: text },
            true,
            {}
          ),
        ]);
        this.props.updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.OFFER.confirm_enrolled_device.label,
          ''
        );
      }
    } else if (CONSTANTS.UPDATE_ENROLLED_EQUIRY_SUCCESS === source) {
      let { updatedDeviceData } = this.props;
      this.addMessageInJourneyList([
        this.createMessage(CONSTANTS.TEXT, `user`, updatedDeviceData, false),
        this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.CONFIRM_DETAILS.BUTTON, false),
        this.createMessage(
          CONSTANTS.DEVICE_NOT_ELIGIBLE,
          `system`,
          { CONTENT: MESSAGE_CONSTANTS.SUBMIT_ENROLLED_ENQUIRY },
          true,
          { source: CONSTANTS.ENROLLED_DEVICE_INQUIRY_SUCCESS }
        ),
      ]);

      // this.redirectToLandingPage();
      // SMART STOCK
    } else if (CONSTANTS.SMART_STOCK === source) {
      if (this.props.deviceList && this.props.deviceList.length) {
        let LFLDevice = !_.isEmpty(this.props.deviceList) ? _.find(this.props.deviceList, { IsLikeForLike: true }) : {};

        if (LFLDevice.IsInStock) {
          this.addMessageInJourneyList([
            this.createMessage(
              CONSTANTS.DEVICE_IN_STOCK,
              `system`,
              {
                scripts: MESSAGE_CONSTANTS.DEVICE_STATUS_IN_STOCK
              },
              true,
              {
                source: CONSTANTS.SMART_STOCK
              }
            ),
            this.createMessage(
              CONSTANTS.SMART_STOCK,
              'system',
              {
                devices: this.props.deviceList,
                onDone: (device) => {
                  this.addMessageInJourneyList([
                    this.createMessage(
                      CONSTANTS.TEXT,
                      CONSTANTS.SOURCE_ENUM.USER,
                      `<p style="text-align: right; margin-bottom: 0;">
                        <strong>${formatPhoneName(device)}</strong><br />
                        ${formatCapacityFromDevice(device)} ${formatColor(device)}
                      </p>`,
                      false
                    ),
                    ...(device.IsLikeForLike
                      ? []
                      : [
                          this.createMessage(
                            CONSTANTS.TEXT,
                            CONSTANTS.SOURCE_ENUM.SYSTEM,
                            MESSAGE_CONSTANTS.SMART_STOCK.WARNING,
                            false
                          ),
                        ]),
                  ]);
                  this.props.handleSmartStockSelection(
                    device,
                    this.props.cacheId,
                    this.props.serviceFee
                  );
                },
              },
              true,
              {}
            )
          ])
        } else if (!LFLDevice.IsInStock) {
          if (typedText === MESSAGE_CONSTANTS.DEVICE_STATUS_OUT_STOCK.buttonSelectAlternate) {
            this.addMessageInJourneyList([
              this.createMessage(CONSTANTS.TEXT, `user`, MESSAGE_CONSTANTS.DEVICE_STATUS_OUT_STOCK.buttonSelectAlternate, false),
              this.createMessage(
                CONSTANTS.SMART_STOCK,
                'system',
                {
                  devices: this.props.deviceList,
                  onDone: (device) => {
                    this.addMessageInJourneyList([
                      this.createMessage(
                        CONSTANTS.TEXT,
                        CONSTANTS.SOURCE_ENUM.USER,
                        `<p style="text-align: right; margin-bottom: 0;">
                          <strong>${formatPhoneName(device)}</strong><br />
                          ${formatCapacityFromDevice(device)} ${formatColor(device)}
                        </p>`,
                        false
                      ),
                      ...(device.IsLikeForLike
                        ? []
                        : [
                            this.createMessage(
                              CONSTANTS.TEXT,
                              CONSTANTS.SOURCE_ENUM.SYSTEM,
                              MESSAGE_CONSTANTS.SMART_STOCK.WARNING,
                              false
                            ),
                          ]),
                    ]);
                    this.props.handleSmartStockSelection(
                      device,
                      this.props.cacheId,
                      this.props.serviceFee
                    );
                  },
                },
                true,
                {}
              )
            ])
          } else {
            if (LFLDevice.IsNoLongerProduced) {
              this.addMessageInJourneyList([
                this.createMessage(
                  CONSTANTS.DEVICE_NLP,
                  `system`,
                  {
                    devices: this.props.deviceList,
                    scripts: MESSAGE_CONSTANTS.DEVICE_STATUS_NLP
                  },
                  true,
                  {}
                )
              ])
            } else if (LFLDevice.IsNotCurrentlyAvailableForOrder) {
              this.addMessageInJourneyList([
                this.createMessage(
                  CONSTANTS.DEVICE_NCA, 
                  `system`, 
                  { 
                    devices: this.props.deviceList,
                    scripts: MESSAGE_CONSTANTS.DEVICE_STATUS_NCA
                   }, 
                  true, 
                  {}
                  ),
              ])
            } else {
              this.addMessageInJourneyList([
                this.createMessage(
                  CONSTANTS.DEVICE_OUT_STOCK, 
                  `system`, 
                  { 
                    devices: this.props.deviceList,
                    scripts: MESSAGE_CONSTANTS.DEVICE_STATUS_OUT_STOCK
                  }, 
                  true, 
                  {}
                  ),
              ])
            }
          }
        }
      }
      // this.addMessageInJourneyList([
      //   this.createMessage(
      //     CONSTANTS.SMART_STOCK,
      //     'system',
      //     {
      //       devices: this.props.deviceList,
      //       onDone: (device) => {
      //         this.addMessageInJourneyList([
      //           this.createMessage(
      //             CONSTANTS.TEXT,
      //             CONSTANTS.SOURCE_ENUM.USER,
      //             `<p style="text-align: right; margin-bottom: 0;">
      //               <strong>${formatPhoneName(device)}</strong><br />
      //               ${formatCapacityFromDevice(device)} ${formatColor(device)}
      //             </p>`,
      //             false
      //           ),
      //           ...(device.IsLikeForLike
      //             ? []
      //             : [
      //                 this.createMessage(
      //                   CONSTANTS.TEXT,
      //                   CONSTANTS.SOURCE_ENUM.SYSTEM,
      //                   MESSAGE_CONSTANTS.SMART_STOCK.WARNING,
      //                   false
      //                 ),
      //               ]),
      //         ]);
      //         this.props.handleSmartStockSelection(
      //           device,
      //           this.props.cacheId,
      //           this.props.serviceFee
      //         );
      //       },
      //     },
      //     true,
      //     {}
      //   ),
      // ]);
    }
  };

  assignToAgentIfAvailable = (generalEnquiryCase = false) => {
    MIXPANEL.time_event(EVENTLIST.CHAT_CONNECTED);
    MIXPANEL.track(EVENTLIST.CHAT_SUPPORT, { Reason: this.state.chatReason });
    let that = this;
    getRealtimeStatistics({ workspace: this.props.workspace }, function (workersOnChat) {
      if (workersOnChat > 0) {
        let { visitorId } = that.state.visitor.input;
        that.setState({
          requestedChatAssistance: true,
          generalEnquiryCase,
          wrapUpCode: null,
        });
        that.createChatRequest(visitorId);
      } else {
        if (generalEnquiryCase) {
          that.addMessageInJourneyList([
            that.createMessage(
              CONSTANTS.TEXT,
              `system`,
              MESSAGE_CONSTANTS.GENERAL_ENQUIRY_SUBMITTED
            ),
            that.createMessage(
              CONSTANTS.JOURNEY_ENDED,
              `system`,
              { endReason: CONSTANTS.ENDREASON_LIST.AGENTS_OFFLINE },
              true
            ),
          ]);
          // this.props.updateTransitionPanel(MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.percentage, MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED.label,'', '');
          // that.props.updateTransitionPanel(100, MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED, ``, ``)
          that.redirectToLandingPage();
        } else {
          let { visitorId } = that.state.visitor.input;
          that.setState({
            requestedChatAssistance: true,
            wrapUpCode: EVENTLIST.SERVICE_UNAVAILABLE,
          });
          that.createChatRequest(visitorId);

          if (that.props.selectedDefectOption === CONSTANTS.GENERAL_ENQUIRY) {
            that.addMessageInJourneyList([
              that.createMessage(
                CONSTANTS.ALL_AGENT_OFFLINE,
                `system`,
                MESSAGE_CONSTANTS.ALL_AGENT_OFFLINE,
                true,
                { source: CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_OFFLINE }
              ),
              that.createMessage(
                CONSTANTS.JOURNEY_ENDED,
                `system`,
                { endReason: CONSTANTS.ENDREASON_LIST.AGENTS_OFFLINE },
                true
              ),
            ]);
          } else {
            that.addMessageInJourneyList([
              that.createMessage(
                CONSTANTS.ALL_AGENT_OFFLINE,
                `system`,
                MESSAGE_CONSTANTS.ALL_AGENT_OFFLINE,
                true,
                { source: CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_OFFLINE }
              ),
              that.createMessage(
                CONSTANTS.JOURNEY_ENDED,
                `system`,
                { endReason: CONSTANTS.ENDREASON_LIST.AGENTS_OFFLINE },
                true
              ),
            ]);
          }
        }
      }
    });
  };

  sendUserDisconnected = (source) => {
    // if (this.state.userDisconnectedMessage) return false;
    let that = this;
    const newMessage = {
      input: {
        messageId: uuid(),
        visitorId: that.state.visitor.input.visitorId,
        conversationId: that.state.conversation.conversationId,
        messageType: CONSTANTS.MESSAGE_TYPE.PLAIN_TEXT,
        interactionType: CONSTANTS.INTERACTION_TYPES.ASSISTANCE,
        sender: that.state.visitor.input.userName,
        source: CONSTANTS.SOURCE_ENUM.INFO,
        content: MESSAGE_CONSTANTS.CHAT_ENDED_BY_USER,
        isSent: false,
        recipient: 'agent',
        isActive: true,
        createdAt: new Date(),
        translated: false,
        sourceLang: 'en',
        targetLang: 'en', // data.sourceLang,
      },
    };

    that.setState({
      userDisconnectedMessage: newMessage,
      transferChat: false,
      listenUpdateChat: false,
    });
  };

  onCreateVisitorError = (e) => {
    console.log('%c CreateVisitor Error  ', 'background: red; color: #bada55');
    //this.props.showServiceUnavailable()
  };

  render() {
    if (this.props.userCancelled && !this.state.userDisconnectedMessage) {
      this.sendUserDisconnected();
      // window.location.replace(config.ApplicationURL);
    }
    return (
      <Fragment>
        <div
          id="chatWindow"
          className={`${
            this.props.selectedDefectOption === CONSTANTS.LEAVE_AIS ? 'width-full' : ''
          }  col-md-9 ais_chat-window`}
        >
          {(this.state.chatRequest || this.state.chatAbandoned) && (
            <CreateChatAudit visitorActivityLog={this.state.chatAuditRequest} />
          )}
          <JourneyList
            // showInput={showInput}
            messages={this.state.journeyMessageList}
            updateJourneyList={this._updateJourneyList}
            progressValue={this.props.progressBarValue}
            desktopConversationEnd={this.props.desktopConversationEnd}
          />

          {this.state.apiError && (
            <SendMessage
              showServiceUnavailable={this.props.showServiceUnavailable}
              message={this.state.apiError}
              isAPIError={true}
            />
          )}

          {this.state.ccValidationError && (
            <SendMessage
              showServiceUnavailable={false}
              message={this.state.ccValidationError}
              isAPIError={false}
            />
          )}

          {this.state.visitor && (
            <SendMsgToAppSync
              conversation={this.state.conversation}
              visitor={this.state.visitor}
              messages={this.state.journeyMessageList}
            />
          )}
          {this.state.visitor && (
            <CreateVisitor
              visitor={this.state.visitor}
              messageList={this.state.messageList}
              onError={this.onCreateVisitorError}
            />
          )}
          {/*}*/}

          {this.state.visitor &&
            (this.state.userMDN ||
              this.props.IpAddress ||
              this.props.customerName ||
              this.props.journeyCmpltd) && (
              <UpdateVisitor
                visitorId={this.state.visitor.input.visitorId}
                mdn={this.state.userMDN}
                customerName={this.props.customerName}
                ipAddress={this.props.IpAddress}
                journeyCmpltd={this.props.journeyCmpltd}
                journeyStatus={this.props.journeyStatus}
                caseNumber={this.props.customerCaseNumber}
                interactionId={this.props.interactionLineId}
                interactionType={this.props.selectedDefectOption}
                chatReason={this.state.chatReason}
                perilType={this.props.caseType}
                lastUpdatedTime={this.state.lastUpdatedTime}
                lastActivity={this.state.lastActivity}
              />
            )}

          {this.state.requestedChatAssistance &&
          this.state.chatRequest &&
          this.state.chatRequest.input &&
          !this.state.transferChat ? (
            <CreateChatRequest
              chatRequest={this.state.chatRequest}
              twilioTaskHandler={this.twilioTaskHandler}
              wrapUpCode={this.state.wrapUpCode}
            />
          ) : null}
          {this.state.updateChatRequest && (
            <EndChatRequest chatRequest={this.state.updateChatRequest} />
          )}
          {this.state.updateChatTask && (
            <CallUpdateChatRequest chatRequest={this.state.updateChatTask} />
          )}
          {this.state.conversation ? (
            <ChatInput
              visitor={this.state.visitor}
              customerName={this.props.customerName}
              conversation={this.state.conversation}
              messageList={this.state.messageList}
            />
          ) : null}
          {/*this.state.requestedChatAssistance &&
            !this.state.conversation &&
            this.state.chatRequest &&
            !this.state.wrapUpCode &&
            this.state.chatRequest.input ? (
            <GetMyConversation
              requestId={this.state.chatRequest.input.requestId}
              showLoader={this.state.showLoader}
              getNewConversation={this._getNewConversation}
              updateJourneyList={this._updateJourneyList}
              transferChat={this.state.transferChat}
            />
            ) : null*/}
          {/*this.state.conversation ? (
            <Fragment>
              <GetMessages
                conversationId={this.state.conversation.conversationId}
                requestId={this.state.chatRequest.input.requestId}
                chatInactiveTimeOut={this.state.chatInactiveTimeOut}
                actions={{
                  setChatInactiveTimeOut: this._setChatInactiveTimeOut,
                  updateJourneyList: this._updateJourneyList,
                  addMessage: this._addMessagesInJourneyList
                }}
                messageList={this.state.messageList}
              />
            </Fragment>
              ) : null*/}
          {this.state.requestedChatAssistance &&
          this.state.chatRequest &&
          this.state.chatRequest.input &&
          !this.state.wrapUpCode ? (
            <GetConversationMessages
              requestId={this.state.chatRequest.input.requestId}
              showLoader={this.state.showLoader}
              transferChat={this.state.transferChat}
              chatInactiveTimeOut={this.state.chatInactiveTimeOut}
              messageList={this.state.messageList}
              actions={{
                setChatInactiveTimeOut: this._setChatInactiveTimeOut,
                updateJourneyList: this._updateJourneyList,
                addMessage: this._addMessagesInJourneyList,
                getNewConversation: this._getNewConversation,
              }}
            />
          ) : null}
          {(this.state.conversation && this.props.userCancelled === false) ||
          this.state.listenUpdateChat ? (
            <GetEndChat
              requestId={this.state.chatRequest.input.requestId}
              endChat={this._endChat}
              source={this.state.source}
              typedText={this.state.actionMade}
            />
          ) : null}
          {
            // This is triggeres when user cancels the ongoing chat
            this.props.userCancelled && this.state.userDisconnectedMessage ? (
              <SendMessage message={this.state.userDisconnectedMessage} />
            ) : null
          }

          {/*{this.state.showEnquiryUserInput && (
            <UserInput
              source={CONSTANTS.SOURCE_USER_INPUT.ENQUIRY}
              updateJourneyList={this._updateJourneyList}
            />
          )}*/}
          {/* this.state.showSRUserInput && <UserInput source={CONSTANTS.SOURCE_USER_INPUT.REQUEST}
            updateJourneyList={this._updateJourneyList} /> */}

          {/* this.state.showBotInput && <BotUserInput source={CONSTANTS.SOURCE_USER_INPUT.BOT_INPUT_SERVICE_FEE} updateJourneyList={this._updateJourneyList} /> */}
        </div>

        <TransitionPanel
          transition={this.props.transition}
          hideTransitionPanelFlag={this.props.selectedDefectOption === CONSTANTS.LEAVE_AIS}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    cardType = '',
    enrolledCustomerName,
    language,
    isResumeScenario,
    paymentMethod,
    progressBarValue,
    transition,
    replacementDevices,
    customerContact,
    journeyCmpltd,
    journeyStatus,
    holds,
    caseType,
    selectedDefectOption,
    triageQuestionsDisplayed,
    paymentRetryAttempt,
    deviceDetails,
    serviceFee,
    addressValidationAttemptCount,
    shippingMethodsResult,
    customerName,
    serviceRequestType,
    clientData,
    InteractionLineId,
    IpAddress,
    resumePoint,
    resumeCanceled,
    backOrderCase,
    noServiceFee,
    reverseOrderWarranty,
    selectedReplacementDevice,
    selectedDeliveryDate,
    isSimRequired,
    phoneNumber,
    emailAddress,
    visitSource,
    selectedDeliverySlot,
    selectedBrand,
    addressRetryAttempt,
    customerCaseNumber,
    verificationFailedAttempt,
    callBackComment,
    IsRefund,
    RefundType,
    Platform,
    selectedLeavingAISReason,
    ClientOffer,
    save1,
    save2,
    ServiceEffectiveEndDate,
    IsMonthly,
    churnSavedeskAlternateContactNumber,
    otherReason,
    isWbkoSelected,
    wbkoFirstName,
    wbkoMobileNumber,
    wbkoIdNumber,
    inquiryReason,
    serviceRequestNumber,
    keepOrconfirmCount,
    changedAddress,
    WbkoCancellationReasonText,
    maskedUpdatedEmailId,
    maskedUpdatedMobileNumber,
    determineIncidentResponse,
    serviceUnavailableCount,
    imeiVerificationAttempt,
    verifyIMEIResults,
    updatedDeviceData,
    serviceOrderData,
  } = state.customerData;
  const inStock = replacementDevices ? replacementDevices.ReplacementItem[0].IsInStock : true;
  let deviceList = replacementDevices ? replacementDevices.ReplacementItem : [];
  // console.log("backorder clicked", backOrderCase);
  return {
    imeiVerificationAttempt,
    verifyIMEIResults,
    serviceUnavailableCount,
    updatedDeviceData,
    keepOrconfirmCount,
    changedAddress,
    WbkoCancellationReasonText,
    maskedUpdatedEmailId,
    maskedUpdatedMobileNumber,
    ClientOffer,
    save1,
    save2,
    ServiceEffectiveEndDate,
    IsMonthly,
    otherReason,
    determineIncidentResponse,
    isWbkoSelected,
    wbkoFirstName,
    wbkoMobileNumber,
    wbkoIdNumber,
    inquiryReason,
    serviceRequestNumber,
    churnSavedeskAlternateContactNumber,
    selectedLeavingAISReason,
    callBackComment,
    IsRefund,
    RefundType,
    Platform,
    customerCaseNumber,
    paymentMethod,
    isResumeScenario,
    progressBarValue,
    cardType,
    enrolledCustomerName,
    transition,
    holds,
    resumeCanceled,
    emailAddress,
    noServiceFee,
    phoneNumber,
    reverseOrderWarranty,
    cacheId: clientData.CacheId,
    isSimRequired,
    selectedBrand,
    serviceRequestType,
    // deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
    selectedReplacementDevice: !_.isEmpty(selectedReplacementDevice)
      ? selectedReplacementDevice
      : deviceDetails,
    currentDeviceInStock: inStock,
    resumePoint,
    triageQuestionsDisplayed,
    customerName,
    deviceList,
    selectedDefectOption,
    customerContact: customerContact,
    clientId: clientData.ClientId,
    supportedCulture: clientData.SupportedCulture,
    interactionLineId: InteractionLineId,
    caseType: caseType,
    paymentRetryAttempt: paymentRetryAttempt,
    deviceDetails: deviceDetails,
    serviceFee: serviceFee || {},
    addressValidationAttemptCount: addressValidationAttemptCount,
    shippingMethodsResult,
    backOrderCase,
    selectedDeliverySlot,
    selectedDeliveryDate,
    IpAddress,
    journeyCmpltd,
    journeyStatus,
    addressRetryAttempt: addressRetryAttempt,
    language,
    visitSource,
    verificationFailedAttempt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setCaseType,
      savePayload,
      setRetryPaymentAttempt,
      setRetryAddressAttempt,
      setPaymentChargeAttempt,
      setVisitorId,
      cancelServiceRequestInit,
      doverifyNRICInit,
      setJourneyCmpltd,
      updateTransitionPanel,
      getPCITokenInit,
      createChatEnquiry,
      submitEnquiryDetails,
      getServiceFee,
      handleSmartStockSelection,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(webChatWindow);
