import React, { Component, Fragment } from "react";
// import ChatInput from "./ChatInput";

class CallCreateVisitor extends Component {
    componentWillMount() {
        this.props.createVisitor({ variables: this.props.visitor });
    }

    render() {
        return (
            <Fragment>
                
            </Fragment>
        )
    }
} 

export default CallCreateVisitor;