import React from "react";

export const TextRegular = ({ children, className }) => {
    return <span className={`font-AvenirLTStd-Book leading-tight ${className}`}>{children}</span>;
};

export const TextLight = ({ children, className }) => {
    return <span className={`font-AvenirLTStd-Book leading-tight ${className}`}>{children}</span>;
};

export const TextMedium = ({ children, className = '' }) => {
    return <span className={`font-AvenirLTStd-Medium leading-tight ${className}`}>{children}</span>;
};

export const TextBold = ({ children, className }) => {
    return <span className={`font-AvenirLTStd-Heavy leading-tight ${className}`}>{children}</span>;
};

export function H3({ children, className }) {
    return <h3 className={`font-extrabold text-xl tracking-tight ${className}`}>{children}</h3>
}

export function H4({ children, className }) {
    return <h3 className={`font-extrabold text-base ${className}`}>{children}</h3>
}

export function H5({ children, className }) {
    return <h5 className={`font-medium text-14px ${className}`}>{children}</h5>
}

