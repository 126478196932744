import React, { Component, Fragment } from "react";
import CONSTANTS from '../../../utils/Constants';

class EndChatRequest extends Component {
    componentWillMount() {
        this.props.updateChatRequest({ variables: this.props.chatRequest });
        
        if (this.props.actions) {
            this.props.actions.setChatInactiveTimeOut();
            this.props.actions.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.AGENT_DISCONNECTED);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.chatRequest) !== JSON.stringify(nextProps.chatRequest)) {
            this.props.updateChatRequest({ variables: nextProps.chatRequest });
            if (this.props.actions) {
                this.props.actions.setChatInactiveTimeOut();
                this.props.actions.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.AGENT_DISCONNECTED);
            }
        }
    }

    render() {
        return (
            <Fragment>
            </Fragment>
        )
    }
}

export default EndChatRequest;