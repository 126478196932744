import React, { Component } from 'react';
import '../AjaxLoader/AjaxLoader.css';

class UploadFileLoader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ajaxLoader: "ais_ajax-loader-uploadwrapper"
    }
  }


  render() {
    return (
      <div className={(this.props.progressValue === "100") ? "ais_ajax-loader-uploadwrapper ais_loading-completed" : "ais_ajax-loader-uploadwrapper"}>
        <div id="ajax-loader-uploadwrapper">
          <div className="ais_stage-progress-div">
            <div className="ais_progress-bar-container">
              <div className="ais_progress-bar" style={{ width: `${this.props.progressValue}%` }}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UploadFileLoader;