export const formatCapacity = (capacity, isNaNValue = '') => {
  if (isNaN(capacity)) return isNaNValue;
  let formatted = capacity;
  let unit = 'GB';
  if (capacity >= 1000) {
    formatted = Math.floor(capacity / 1000);
    unit = 'TB';
  }
  return `${formatted} ${unit}`;
};

export const formatCapacityFromDevice = (device) => {
  const {
    HorizonItemInformation: { AssetCapacity },
  } = device;
  /**
   * Extract capacity
   * --------------------------------------------------------------------------
   */
  return formatCapacity(AssetCapacity * (AssetCapacity < 2 ? 1000 : 1));
  // ==========================================================================
  // @end: extract capactiy
  // ==========================================================================
};

export const formatColor = (device) => {
  /**
   * Extract color
   * --------------------------------------------------------------------------
   */
  const {
    SCMItemInformation: { Color },
    HorizonItemInformation: { AssetColor },
  } = device;
  return AssetColor ?? Color;
  // ==========================================================================
  // @end: extract color
  // ==========================================================================
};

export const formatPhoneName = (device) => {
  /**
   * Extract phone model
   * --------------------------------------------------------------------------
   */
  const {
    SCMItemInformation: { Make, Model },
    HorizonItemInformation: { AssetFamily },
  } = device;
  return AssetFamily ?? `${Make} ${Model}`;
  // ==========================================================================
  // @end: extract phone model
  // ==========================================================================
};

export const defaultPhoneFormat = (device) =>
  `${formatPhoneName(device)} ${formatCapacityFromDevice(device)} ${formatColor(device)}`;
