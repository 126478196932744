import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submitGeneralEnquiry } from "../../actions/Dashboard/doFindAgreements";
import Icon from "../../images/ais-images/icon-ge.png";
import { getLang, messageTime } from "../../utils/utils";
import classNames from 'classnames';

class GeneralEnquiryReason extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disable: true,
      disabledTextArea: false,
      highlightedButton: false,
      remarks: "",
    };
  }

  handleTextareaChange = (event) => {
    this.setState({
      remarks: event.target.value,
      disable: !event.target.value,
    });
  };

  submitHandler = () => {
    this.setState({
      disable: true,
      disabledTextArea: true,
      highlightedButton: true,
    });

    this.props.updateJourneyList(this.props.dataprops.source, this.state.remarks);

    let lang = getLang() === 'en' ? 'en-US' : 'th-TH';

    let GeneralInquiryRequest = {
      Carrier: "AIS",
      Country: "Thailand",
      Language: lang,
      OriginalQuestion: this.state.remarks,
      InquiryDate: messageTime(),
    };

    this.props.submitGeneralEnquiry(GeneralInquiryRequest);
  };

  render() {
    let { data } = this.props;
    let { remarks, disabledTextArea, disable, highlightedButton } = this.state;

    let buttonClass = classNames({
      'ais_device-select-button': true,
      'ais_device-select-button-highlighted': highlightedButton,
  })

    return (
      // <div className="generic-card">
        <div className="ais_current-form-device-div">
          <div className="ais_header-txt-div ais_doc">
            <div>
              <img
                alt="enroll-address-icn"
                className="ais_confirm-delivery-icn"
                src={Icon}
              />
            </div>
            <label className="ais_current-mobile-device-label">{data.HEADER}</label>
            <textarea
              className="ais_feedback-reason ais_text-color"
              value={remarks}
              placeholder={data.PLACEHOLDER}
              disabled={disabledTextArea}
              onChange={this.handleTextareaChange}
            />
            <button
              disabled={disable}
              className={buttonClass}
              onClick={this.submitHandler}>{data.BUTTON}</button>
          </div>
        </div>
      // </div>
    );
  }
}

/* const mapStateToProps = state => {
  const {
  } = state.customerData;
  return { };
}; */

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitGeneralEnquiry,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(GeneralEnquiryReason);
