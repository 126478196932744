import React, { Component } from "react";

import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import requestIcon from '../../images/mobile/img-request_mobile.png';
import { endInteractionInit } from '../../actions/Dashboard/doFindAgreements';

class InformativeResumeMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            yesButtonClassName: "ais_align-popup-nosr",
            newsrButtonClassName: "ais_align-popup-nosr",
            disable: false
        }
        this.closeClick = this.closeClick.bind(this);
        this.startNewRequest = this.startNewRequest.bind(this);
    }

    closeClick() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-nosr-selected"
        });
        if(this.props.dataprops.source === CONSTANTS.USER_DISCONNECTED){
        }else{
        this.props.endInteractionInit(this.props.cacheId);
        }
    }

    startNewRequest() {
        this.setState({
            disable: true,
            newsrButtonClassName: "ais_align-popup-nosr-selected"
        });
        this.props.updateJourneyList(CONSTANTS.ENQUIRY_OPTIONS,null);
    }
    render() {
        let errorIcon = requestIcon;
        let contentClass = "ais_verificationMessage";
        return (
            <div className="ais_current-form-device-div ais_mdnInformativeMessage">
                <div className="ais_verificationHeader">
                    <label className="ais_current-device-spec ais_primary-header ais_head-width">{this.props.data.HEADER}</label>
                    <div>  <img alt="error-icon" className="ais_verificationImage" src={errorIcon} /> </div>
                    <div className={contentClass}>
                        {this.props.data.CONTENT}
                    </div>
                   <div className="ais_outer-align-button-top-bottom">
                   <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.closeClick} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
                   <button disabled={this.state.disable} className={this.state.newsrButtonClassName} onClick={this.startNewRequest} >{this.props.data.BUTTON}</button>
               </div> 
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        endInteractionInit
    }, dispatch)
}


export default connect(null, mapDispatchToProps)(InformativeResumeMessage); 