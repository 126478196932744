import React, { Component, Fragment } from "react";

class CreateChatAudit extends Component {
    componentWillMount() {
        console.log("createChatAudit", this.props.visitorActivityLog);
        this.props.createChatAudit({ variables: this.props.visitorActivityLog });
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.visitorActivityLog.input.auditLog) !== JSON.stringify(nextProps.visitorActivityLog.input.auditLog)) {
        console.log("createChatAudit", nextProps.visitorActivityLog.input.auditLog);
            this.props.createChatAudit({ variables: nextProps.visitorActivityLog });
        }
    }

    render() {
        return (
            <Fragment>
            </Fragment>
        )
    }
}

export default CreateChatAudit;