import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import CONSTANTS from "../../utils/Constants"; import moment from 'moment';
import { bindActionCreators } from 'redux'
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { savePayload, deteremineIncidentPathInit } from '../../actions/Dashboard/doFindAgreements';
import classNames from "classnames";

class WbkoCancelationConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDiv: null
    };
  }
  handleClick = data => {
    let source, text;
    let {
      updateJourneyList
    } = this.props;

    let userInput = {
      handlePrevSubmit: 1,
      userInput: this.props.inputState,
      source: this.props.source,
      value: text
    };
    let DetermineIncidentPaths = {
      SessionId: this.props.cacheId,
      caseType: this.props.caseType
    };
    this.props.deteremineIncidentPathInit(DetermineIncidentPaths, userInput);

    if (data.name === "TRACK") {
      //source = CONSTANTS.WEB_TRACK_MY_DELIVERY;
      source = '';// CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS;
      text = data.text;
      this.props.savePayload(CONSTANTS.WBKO_TRACK_TRACE);
    } else if (data.name === "END") {
      this.props.savePayload(CONSTANTS.LOAD_THANK_YOU, { showThankYou: true, stayWithAIS: undefined });
      text = data.text;
    }
    if (!this.state.selectedDiv) {
      this.setState({
        selectedDiv: data.text
      });
      updateJourneyList(source, text);
    }
  };
  _renderOptions(option, i) {
    let btnClasses = classNames({
      disabled: this.state.selectedDiv,
      "ais_options-replacement-selected-div":
        this.state.selectedDiv === option.text,
      "ais_options-replacement-div": this.state.selectedDiv !== option.text
    });
    return (
      <div
        name={option.text}
        key={option.text}
        disabled={this.state.selectedDiv}
        className={btnClasses}
        onClick={() => this.handleClick(option)}
      >
        <label
          className={
            this.state.selectedDiv === option.text
              ? "ais_options-selected-label"
              : "ais_options-label"
          }
        >
          {option.text}
        </label>
      </div>
    );
  }
  render() {
    return (
      <div>
        <div className="">
          <div className="ais_header-txt-div">
            <div className='wbkoTrackOrEndHeader'>
              {MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelConfirm1}
            </div>
            {this.props.data &&
              this.props.data.buttons.map((e, i) => this._renderOptions(e, i))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { clientData } = state.customerData;
  return {
    cacheId: clientData.CacheId
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ savePayload, deteremineIncidentPathInit }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WbkoCancelationConfirm);