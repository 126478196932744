import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { resumeServiceRequestInit, savePayload } from '../../actions/Dashboard/doFindAgreements';
import { getFormattedPopularName, getFormattedTotalFee, replacePlaceholders, getFormattedDeliveryDate } from '../../utils/utils';
import { RESUME_CANCELED } from '../../actions/Dashboard/actionTypes';
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import noImage from '../../images/icons/ic-device_notAvailable.png';

class ResumeReqForHold extends Component {

    constructor(props) {
        super(props);
        this.state = {
            yesButtonClassName: "ais_align-popup-button ais_hold",
            disable: false,
            srNo: "",
            type: "",
            sim: "",
            deviceInfo: "",
            address: "",
            deliveryDate: "",
            deliveryTime: "",
            fee: "",
            cardType: "",
            cardNo: "",
            imageMake: "",
            imageModel: "",
            buttonClassName: "ais_confirm-details-button",
            paymentRetryAttemptState: this.props.paymentRetryAttempt || false,
        }
        this.resumeSRClick = this.resumeSRClick.bind(this);
        this.createNewSRClick = this.createNewSRClick.bind(this);
    }

    resumeSRClick() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected ais_hold"
        });

        this.props.resumeServiceRequestInit(); // processIncidents & userInput is empty
    }

    createNewSRClick() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected ais_hold"
        });

        this.props.savePayload(RESUME_CANCELED, true);
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESUME_NEW_SR_ENQUIRY_OPTIONS, MESSAGE_CONSTANTS.YES_PROCEED)
    }

    componentWillMount() {
        if (this.props.dataprops.source !== CONSTANTS.NO_PAYMENT_REVIEW) {
            let replacementDevice = !_.isEmpty(this.props.selectedReplacementDevice) ? this.props.selectedReplacementDevice.HorizonItemInformation : {};
            let { Make, Model, AssetCatalogName } = replacementDevice;
            let deviceSpecs = AssetCatalogName ? getFormattedPopularName(AssetCatalogName) : {
                modelName: Make && Model ? `${Make} ${Model}` : "",
                color: "",
                specs: ""
            };
            let { caseType, postalCode, cardType, fee, username, slot, simRequired, ccmask, customerCaseNumber } = this.props;
            let finalDeliverySlot = "";
            let sim = "without SIM";
            let cc = "****";
            let filteredArr = MESSAGE_CONSTANTS.SLOTS_MAPPING.filter((item) => item.RESP_VALUE === slot);
            finalDeliverySlot = !_.isEmpty(filteredArr) ? filteredArr[0].DISPLAY_OPTION : null;
            if ((typeof simRequired !== "undefined") && (simRequired !== null)) {
                sim = (simRequired) ? CONSTANTS.WITH_SIM : CONSTANTS.WITHOUT_SIM;
            }
            if ((typeof ccmask !== "undefined") && (ccmask !== null)) {
                cc = ccmask;
            }

            var totalAmount = fee && fee.CurrencyCode && fee.TotalAmount ?
                `${fee.CurrencyCode} ${getFormattedTotalFee(fee.TotalAmount)}` : null

            this.setState({
                username: username.fullName,
                srNo: customerCaseNumber,
                type: caseType,
                deviceInfo: `${deviceSpecs.modelName} ${deviceSpecs.specs} ${deviceSpecs.color}`,
                sim: sim,
                address: postalCode,
                deliveryDate: getFormattedDeliveryDate(this.props.expectedDeliveryDate, slot),
                deliveryTime: finalDeliverySlot,
                cardType: cardType,
                cardNo: cc,
                fee: totalAmount,
                imageMake: Make,
                imageModel: Model
            });
        }
    }

    render() {
        let { srNo, deviceInfo, sim, address, deliveryDate, deliveryTime, cardType, cardNo, fee, username, imageMake, imageModel } = this.state;
        let { noServiceFee, backOrderCase, dataprops: { source }, data, resumePoint, customerCaseNumber, caseType } = this.props;
        let capsMake = imageMake ? imageMake.toUpperCase() : "";
        let capsModel = imageModel ? imageModel.toUpperCase() : "";
        let deviceImageUC = capsMake && capsModel ? capsMake + "_" + capsModel + "_S" : "";
        let deviceImageJPG = this.props.imageUrl ? this.props.imageUrl + deviceImageUC + ".jpg" : "";
        let content = data.HEADER ? data.HEADER.indexOf("{Claim ID}") > -1 ? replacePlaceholders(data.HEADER, '{Claim ID}', customerCaseNumber) : data.HEADER : data.HEADER;

        if (source === CONSTANTS.NO_PAYMENT_REVIEW) {
            return (
                <div className="ais_current-form-device-div">
                    <div className="ais_current-form-device-header-div">
                        <label className="ais_current-mobile-device-label">{content}</label>
                    </div>
                    <div className="ais_current-popup-center-div">
                    </div>

                    <div className="ais_outer-align-button">

                        {source === CONSTANTS.NO_PAYMENT_REVIEW ? <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.createNewSRClick} >{data.BUTTONS[0]}</button>
                            : <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.resumeSRClick} >{data.BUTTONS[1]}</button>}
                    </div>

                </div>
            )
        }

        return (
            <div className="confirm-details-div">
                <div className="header-txt-div">
                    <label className="current-mobile-device-label">{content}</label>
                </div>
                <div className="confirm-form-details-header-div">
                    <label className="confirm-details-blue-label">{data.SR_NO}</label>
                    {caseType ? <label className="confirm-details-blue-label">{data.TYPE}</label> : ""}
                    <label className="confirm-details-dark-blue-label">{srNo}</label>
                    {caseType ? <label className="confirm-details-dark-blue-label">{caseType}</label> : ""}

                    {deviceInfo.replace(/ /g, '') !== '' ?
                        <div className="swop-device-header-div">
                            <label className="confirm-details-blue-label">{data.DEVICE}</label>

                            <div>
                                <img alt="" className="current-form-device-icn" src={deviceImageJPG} onError={(e) => { e.target.src = noImage }} />
                                <div className="swop-device-name">{`${deviceInfo}`}</div>
                            </div>
                        </div> : ""}

                    {address && resumePoint === 'CapturePaymentDetails' ?
                        <div className="swop-device-header-div">
                            <label className="confirm-details-blue-label">{data.SHIPPING_TO}</label>
                            <div className="other-details-desc">{username.fullName}</div>
                            <div className="other-details-desc">{`${data.PIN_CODE}  ${address}`}</div>
                        </div> : ""}

                    {!backOrderCase && deliveryDate && deliveryTime && resumePoint === 'CapturePaymentDetails' ? <div className="swop-device-header-div">
                        <label className="confirm-details-blue-label">{this.props.data.DELIVERY_SCHEDULE}</label>
                        <div className="other-details-desc">{`${deliveryDate}, ${deliveryTime}`}</div>
                    </div> : ""}

                    {!noServiceFee && fee ? <div className="swop-device-header-div">
                        {cardType && cardNo ? <label className="confirm-details-blue-label">{this.props.data.PAID_WITH}</label> : ""}
                        <label className="confirm-details-blue-label">{data.FEE}</label>
                        <label className="confirm-details-dark-blue-label">{cardType && cardNo ? `${cardType} Card ${cardNo}` : ""}</label>
                        <label className="confirm-details-dark-blue-label">{fee}</label>
                    </div> : ""}
                </div>
                <div className="outer-align-button-top-bottom">
                    {source === CONSTANTS.NO_PAYMENT_REVIEW ?
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.createNewSRClick} >{data.BUTTONS[0]}</button>
                        : <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.resumeSRClick} >{data.BUTTONS[1]}</button>}
                </div>
            </div>
        )

    }
}


const mapStateToProps = state => {
    let { selectedDefectOption, determineIncidentResponse, caseType, paymentRetryAttempt, shippingMethodsResult, simRequired, ccmask, customerCase, clientData, backOrderCase, selectedReplacementDevice, postalCode, serviceFee, chargeOrderDetails, noServiceFee, customerName, deteremineResumePoint } = state.customerData;
    return {
        cacheId: clientData.CacheId,
        customerCase,
        resumePoint: deteremineResumePoint,
        paymentRetryAttempt,
        selectedReplacementDevice,
        slot: shippingMethodsResult ? shippingMethodsResult.ShippingMethodType : null,
        expectedDeliveryDate: shippingMethodsResult ? shippingMethodsResult.EstimatedDeliveryDate : null,
        caseType,
        simRequired,
        ccmask,
        postalCode,
        backOrderCase,
        noServiceFee,
        imageUrl: clientData.imageBaseUrl,
        fee: serviceFee,
        cardType: chargeOrderDetails ? chargeOrderDetails.CardBrand : null,
        username: customerName,
        customerCaseNumber: determineIncidentResponse ? determineIncidentResponse.CustomerCaseNumber : "",
        selectedDefectOption
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        resumeServiceRequestInit,
        savePayload
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeReqForHold);