import React from "react";

import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';

import fmipIcon from "../../images/ais-images/icon-turn-off-phone.png";
class TurnOffFeatures extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
        }
        this.handleYesClick = this.handleYesClick.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
    }

    handleNoClick(value) {
        // console.log("button clicked.");
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.NO_CONFIRMED_FOR_TURNOFF_FEATURES, value.target.innerText);
    }


    handleYesClick(value) {
        // console.log("button clicked.");
        MIXPANEL.track(EVENTLIST.FMIP);
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(CONSTANTS.MERGE_MALFUNCTION_FLOW, value.target.innerText);
    }

    render() {
        return (
            <div className="ais_current-form-device-div features">
                <div className="ais_header-txt-div features">
                    <label className="ais_current-mobile-device-label feature">{MESSAGE_CONSTANTS.CONFIRM_FMIP_IS_OFF.HEADER}</label>
                    <div className="ais_fmip-header-icon ais_padding-bottom-5">
                        <img alt="fmip-header-icon" className="ais_fmip-icon" src={fmipIcon} />
                    </div>
                    <div className="ais_outer-align-button">
                        <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={(e) => this.handleNoClick(e)} >{MESSAGE_CONSTANTS.CONFIRM_FMIP_IS_OFF.BUTTONS[0]}</button>
                        <div className="ais_device-divider"></div>
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={(e) => this.handleYesClick(e)} >{MESSAGE_CONSTANTS.CONFIRM_FMIP_IS_OFF.BUTTONS[1]}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default TurnOffFeatures;