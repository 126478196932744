import gql from 'graphql-tag';

export default gql`
mutation createVisitor($input:CreateVisitorInput!){
   createVisitor(input:$input){
      visitorCognitoId
      visitorId
      userName
      clientId
      languageCode
      browserAgent
      ipAddress
      journeyStatus 
  }
}`;