import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Img from 'react-image';


import { saveSelectedReplacementDevice, serviceOrderInit } from './../../actions/Dashboard/doFindAgreements';
import { getFormattedPopularName } from '../../utils/utils';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import CONSTANTS from '../../utils/Constants';

// import noImage from "../../images/icons/ic-device_notAvailable.png";

import noImage from "../../images/ais-images/icon-enrolled-device.png";
class ConfirmSelectedDevice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectBtnClassName: "ais_device-select-button",
            disabled: false
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        let { device, cacheId,serviceOrderData } = this.props;
       this.props.disableSelection();
        this.setState({
            selectBtnClassName: "ais_device-select-button ais_selected",
            disabled: true
        });
        // sending the selected device to serviceorder API
        this.props.saveSelectedReplacementDevice(device);
        // // if(this.props.caseType === CONSTANTS.MALFUNCTION || this.props.caseType === CONSTANTS.SWAP) {
        //     // SIM is not included in malfunction & SWAP case
        //     let serviceOrderId = serviceOrderData?serviceOrderData.ServiceOrderId:null;
        //     if(!serviceOrderId){
        //         this.props.serviceOrderInit(cacheId, device, false, this.props.caseType);
        //     }
        // // }
        let replacementDevice = getFormattedPopularName(device.HorizonItemInformation.AssetCatalogName);
        // show the selected device message
        let deviceName = `${replacementDevice.makename} ${replacementDevice.modelName} ${replacementDevice.specs}  ${replacementDevice.color}`;
        this.props.updateJourneyList(CONSTANTS.REPLACE_DEVICE_SELECTED, deviceName);
    }

    render() {

        let { device, serviceFee } = this.props;
        let service_fee = serviceFee;
        let replacementDevice = getFormattedPopularName(device.HorizonItemInformation.AssetCatalogName);
        let capsMake = device.HorizonItemInformation.Make.toUpperCase();
        let capsModel = device.HorizonItemInformation.Model.toUpperCase();
        let deviceImageUcase = capsMake+"_"+capsModel+"_S";
        let deviceImageJPG = this.props.deviceUrl+deviceImageUcase+".jpg";
        let deviceImagePNG = this.props.deviceUrl+deviceImageUcase+".png";
        
        let deviceImage = <Img src={[
            deviceImageJPG,
            deviceImagePNG
            ]}
            alt="current-selected-device-icn" className="ais_current-form-selected-device-icn ais_padding-right-5"
            unloader={<img src={noImage} className="ais_current-form-selected-device-icn ais_padding-right-5"/>}
          />;


        return (
            <div className="ais_current-form-device-div ais_alternate-device-list">
                <div className="ais_current-container-div">
                
                    {/* <img alt="current-selected-device-icn" className="current-form-selected-device-icn" src={deviceIcnImage} onError={(e)=>{e.target.src=noImage}}/> */}
                    <div style={{height:100}}>
                    {deviceImage}
                    <div className="ais_current-form-device-name-div">
                        <div className="ais_current-form-device-name">{replacementDevice.makename + " " +replacementDevice.modelName + " " + replacementDevice.specs + " " + replacementDevice.color}</div>
                        <div className="ais_current-form-device-main-name">{service_fee}</div>
                    </div>
                    </div>
                    <label className="ais_prefer-device-desc ais_deviceOptions">{device.IsInStock ? MESSAGE_CONSTANTS.IN_STOCK_DEVICE_MESSAGE : MESSAGE_CONSTANTS.OUT_OF_STOCK_DEVICE_MESSAGE }</label>
                    <button disabled={this.props.disable} className={this.state.selectBtnClassName}  onClick={this.handleClick} >{MESSAGE_CONSTANTS.SELECT_TEXT}</button>

                </div>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        serviceOrderInit,
        saveSelectedReplacementDevice
    }, dispatch)
};

const mapStateToProps = state => {
    let { clientData ,serviceOrderData} = state.customerData;
    return {
        deviceUrl: clientData.imageBaseUrl,
        serviceOrderData
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSelectedDevice);