import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import CONSTANTS from "../../utils/Constants"; import moment from 'moment';
import _isEmpty from 'lodash/isEmpty'
import { bindActionCreators } from 'redux'
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import wbkoTracking from "../../images/ais-images/wbko-tracking.svg";
import DeliveryDone from "../../images/ais-images/icon-track-delivery-done.png";
import wbko_task_list_to_do from "../../images/ais-images/wbko-task-list-to-do.png";

import { getFormattedPopularName, extractDeliverySlotFromDeliveryInstruction, getFormattedTotalFee } from '../../utils/utils';

import noImage from "./../../images/ais-images/icon-enrolled-device.png";
import { doSaveDeliveryDate, doSaveDeliverySlot, shippingMethodsInit, getContactDetails } from "../../actions/Dashboard/doFindAgreements";
import checkedStatusIcon from "../../images/ais-images/icon-track-delivery-done.png";
import greylineIcon from "../../images/ais-images/tracking-dots-normal.png";
import greenlineIcon from "../../images/ais-images/tracking-dots-active.png";
import firstDefaultIcon from "../../images/ais-images/track-delivery-step-1-default.png";
import secondDefaultIcon from "../../images/ais-images/track-delivery-step-2-default.png";
import thirdDefaultIcon from "../../images/ais-images/track-delivery-step-3-default.png";
import fourthDefaultIcon from "../../images/ais-images/track-delivery-step-4-default.png";
import fifthDefaultIcon from "../../images/ais-images/track-delivery-step-5-default.png";
//import "./trackMyDelivery.css";
class TrackMyDelivery extends Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.getContactDetails(this.props.cacheId, "", "");
    }
    getDAXSalesOrderId() {
        const { ShippingStatus, CustomerCaseStatus, Servicerequests } = this.props
        try {
            if (ShippingStatus === 'SHIP' && CustomerCaseStatus === 'CMPLTD')
                return Servicerequests[0].ShippingOrder.DAXSalesOrderId
        } catch (e) {
            return null
        }
    }
    componentWillMount() {
        let { currentRequestDetails, customerName } = this.props;
        let { CustomerCaseNumber, IncidentType, CardBrand, ServiceFee, ShippingAddress = {}, ExpectedDeliveyDate, DeliveryInstructions, PopularName, Make, Model, CurrencyCode } = currentRequestDetails;

        let deliveryDate = moment(ExpectedDeliveyDate).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT);
        let slotValue = DeliveryInstructions && DeliveryInstructions.substr(0, DeliveryInstructions.indexOf("|"));
        let slotTime = (slotValue && slotValue === "6") || (slotValue && slotValue === "6H") ?
            MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE.SLOTS[0] : MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE.SLOTS[1];
        //let deliverySlotObj = extractDeliverySlotFromDeliveryInstruction(DeliveryInstructions);
        let deliverySlotObj = !_isEmpty(deliverySlotObj) ? deliverySlotObj : CONSTANTS.SLOT_NOT_AVAILABLE;

        this.props.doSaveDeliveryDate(deliveryDate);
        this.props.doSaveDeliverySlot(deliverySlotObj.deliverySlot, true);

        let deviceSpecs = getFormattedPopularName(PopularName);

        /* let deviceImageJPG = `${this.props.deviceURL}${Make}_${Model}_S.jpg`;
        let deviceImagePNG = `${this.props.deviceURL}${Make}_${Model}_S.png`;
        let deviceImage = <Img src={[
            deviceImageJPG,
            deviceImagePNG,
            noImage
        ]}
            className="ais_swop-device-img"
            alt="selected-device"
        />; */

        this.setState({
            username: customerName,
            srNo: CustomerCaseNumber,
            type: IncidentType,
            deviceInfo: `${deviceSpecs.makename} ${deviceSpecs.modelName} ${deviceSpecs.specs} ${deviceSpecs.color}`,
            //sim: sim,
            address: ShippingAddress || {},
            deliveryDate: deliveryDate,
            deliveryTime: slotTime, // deliverySlotObj.humanReadableSlot,
            cardType: CardBrand,
            fee: (CurrencyCode) ? `${CurrencyCode} ${getFormattedTotalFee(ServiceFee)}` : `THB ${getFormattedTotalFee(ServiceFee)}`,
            //deviceImageUrl: deviceImage
        });
    }
    getActiveIndex() {
        //It actually returns the index of the step which is last completed on tracking UI.
        const { ShippingStatus, CustomerCaseStatus } = this.props;

        if (ShippingStatus === CONSTANTS.SHIPPING_STATUS.SHIP) {
            return 3;
        }
        if (ShippingStatus === CONSTANTS.SHIPPING_STATUS.DELIVERED) {
            return 4
        }
        return 0
    }
    render() {
        const DAXSalesOrderId = this.getDAXSalesOrderId();
        let { serviceRequestNumber, shippingStatus, isBackOrderCase, Servicerequests, PaymentMethodType, UpdatedDate } = this.props;
        let { srNo, type, deviceInfo, deliveryDate, deliveryTime, cardType, fee, address } = this.state;
        const stages = [
            {
                icon: firstDefaultIcon,
                label: MESSAGE_CONSTANTS.WBKO_CONFIRMED
            }, {
                icon: secondDefaultIcon,
                label: MESSAGE_CONSTANTS.WBKO_PROCESSING_ORDER
            }, {
                icon: thirdDefaultIcon,
                label: MESSAGE_CONSTANTS.WBKO_SHIPPED
            }
        ]
        const activeIndex = this.getActiveIndex()
        return (
            <div>
                <div className="ais_current-form-device-div ">
                    <div className="ais_header-txt-div">
                        <div><img src={wbko_task_list_to_do} alt='' /></div>
                        <div className='wbkoMargin15 wbkoSrHeader' >{MESSAGE_CONSTANTS.WBKO_SERVICE_DETAILS}</div>
                        <div className='margin-bottom20 '>
                            <div className='wbkoSrDetails'> <div className="wbkoPadding5left">{MESSAGE_CONSTANTS.WBKO_SR_NO}</div><div className="wbkoPadding5right">{srNo} </div> </div>
                            <div className='wbkoSrDetails'> <div className="wbkoPadding5left">{MESSAGE_CONSTANTS.WBKO_SR_TYPE} </div> <div className="wbkoPadding5right">{type} </div></div>
                            <div className='wbkoSrDetails'> <div className="wbkoPadding5left">{MESSAGE_CONSTANTS.WBKO_DEVICE_DETAILS} </div> <div className="wbkoPadding5right">{deviceInfo.trim()} </div></div>
                            <div className='wbkoSrDetails'>
                                <div className="wbkoPadding5left">{MESSAGE_CONSTANTS.WBKO_DELIVERY_ADDRESS} </div>
                                <div className="wbkoPadding5right">
                                    {`${address.Address1 ? address.Address1 : ''},${address.Address2 ? address.Address2 : ''},${address.Address3 ? address.Address3 : ''},${address.PostalCode ? address.PostalCode : ''},${address.City ? address.City : ''},${address.StateProvinceCode ? address.StateProvinceCode : ''},${address.CountryCode === "TH" ? 'Thailand' : ''}`}
                                </div>
                            </div>
                            <div className='wbkoSrDetails'> <div className="wbkoPadding5left">{MESSAGE_CONSTANTS.WBKO_SR_FEE} </div> <div className="wbkoPadding5right">{fee}</div></div>
                            <div className='wbkoSrDetails'> <div className="wbkoPadding5left">{MESSAGE_CONSTANTS.WBKO_MODE_OF_PAYMENT} </div> <div className="wbkoPadding5right">{PaymentMethodType === 'COD' ? MESSAGE_CONSTANTS.COD_TEXT : MESSAGE_CONSTANTS.CREDIT_CARD_TEXT}</div></div>
                            <div className='wbkoSrDetails'> <div className="wbkoPadding5left">{MESSAGE_CONSTANTS.WBKO_SUBMISSION_DATE} </div> <div className="wbkoPadding5right">{moment(UpdatedDate).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT)} </div></div>

                            {isBackOrderCase ? <div></div>
                                : <div className='wbkoSrDetails'>
                                    <div className="wbkoPadding5left">{MESSAGE_CONSTANTS.WBKO_EXPECTED_DELIVERY_DATE} </div>
                                    <div className="wbkoPadding5right">{deliveryDate + ", " + deliveryTime} </div>
                                </div>}
                        </div>
                        {stages.map((stage, index) => <Fragment key={index}>
                            <div key={index} className='wbkoDeliveryFlow'>
                                <div><img src={activeIndex >= index ? checkedStatusIcon : stage.icon} alt='' /></div>
                                <div className={` ${activeIndex >= index ? ' ' : ' wbkoDefaultStatusText '} wbkoDeliveryFlowContent`}>{stage.label}</div>

                            </div>
                        </Fragment>)}
                        {DAXSalesOrderId && <div className="wbkoShippedTrack">
                            <a className="wbkoCheckDeliverySttausDHL" href={`https://ecommerceportal.dhl.com/track/?ref=THAMY${DAXSalesOrderId}`} title="Click Here to Track the device" target="_blank" >{MESSAGE_CONSTANTS.WBKO_DELIVERY_STATUS_AFTER_SHIPPING}</a>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ doSaveDeliveryDate, doSaveDeliverySlot, shippingMethodsInit, getContactDetails }, dispatch);
};
const mapStateToProps = state => {
    const { determineIncidentResponse, customerName, shippingStatus, clientData, backOrderCase, PaymentMethodType, UpdatedDate } = state.customerData;
    return {
        cacheId: clientData.CacheId,
        currentRequestDetails: determineIncidentResponse,
        CustomerCaseStatus: determineIncidentResponse.CustomerCaseStatus || null,
        ShippingStatus: determineIncidentResponse.ShippingStatus || null,
        Servicerequests: determineIncidentResponse.Servicerequests || [],
        customerName,
        isBackOrderCase: backOrderCase,
        PaymentMethodType,
        UpdatedDate
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackMyDelivery);
//export default TrackMyDelivery;