import React, { Fragment } from "react";
import { Mutation } from "react-apollo";
import config from "./../../../config/config";
import CreateMessageMutation from "./../../../graphql/mutation/CreateMessage";
import CallCreateMessage from "./CallCreateMessage";
import MESSAGE_CONSTANTS from "../../../config/journey-messages-thai";

const SendMessage = ({ message, isAPIError, showServiceUnavailable }) => {
  if (!message.input.messageId) {
    return null;
  }
  return (
    <Mutation
      mutation={CreateMessageMutation}
      onCompleted={data => {
        if (isAPIError) {
          showServiceUnavailable();
        } else if (
          message.input &&
          message.input.content === MESSAGE_CONSTANTS.CHAT_ENDED_BY_USER
        ) {
          window.location.replace(config.ApplicationURL);
        }
      }}
    >
      {createMessage => {
        return (
          <Fragment>
            <CallCreateMessage
              createMessage={createMessage}
              message={message}
            />
          </Fragment>
        );
      }}
    </Mutation>
  );
};

export default SendMessage;
