import gql from 'graphql-tag';

export default gql`
 mutation createMessage($input:CreateMessageInput!){
   createMessage(input:$input){
			messageId
			visitorId
			conversationId
			messageType
			interactionType
			sender
			source
			content
			isSent
			recipient
			isActive
			createdAt
  }
}`;

