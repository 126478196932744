import CONSTANTS from '../utils/Constants';

const MESSAGE_CONSTANTS_EN = {
  TECHNICAL_ERROR: '503 Service',
  VOID_MSG: 'SR-entitlements',
  SERVICE_FEE_ENQUIRY: 'Fees',
  NO_DATA_AVAILABLE: 'No Data Available',
  VOID_SCRIPT:
    'Thank you for contacting AIS Mobile Care customer support! <br/> We are unable to proceed with your support request at the moment. <br/> You might have utilised all your service request entitlements or have an existing service request pending further action <br/> Or you chat with an agent if you require further support',
  FAQ: 'FAQ',
  CHAT_RESOLVED_SERVICE_UNAVAILABLE: 'Thank you for contacting AIS Mobile Care!',
  TRACK_NO_SR_MSG: 'No Completed Claim found',
  BackToMainMenu: 'Back to Main Menu',
  SCREEN_REPAIR_IN_PROGRESS_HARD_STOP_SCRIPT:
    'You have an existing request which is in progress. Sorry, we are unable to proceed with a new request now.',
  AIS_LEAVING_REASON_CONTENT1: 'Your mobile number has been successfully verified',
  AIS_LEAVING_REASON_CONTENT2: "We're sorry to hear that you want to leave AIS Mobile Care <br/>",
  AIS_LEAVING_REASON_CONTENT3:
    'To help you better, please select the reason why you would like to cancel your subscription',
  ACKNOWLEDGE_MSG:
    'I acknowledge the above.<br/>Please proceed with terminating <br/>AIS Mobile Care',
  NOTHELPFULL_NEWDEVICE_OTHERREASON_HEADER: 'Do you know what makes AIS Mobile Care unique?',
  COST_TOO_MUCH_HEADER: 'You’ll get more bang for the buck <br/> than how much you pay for',
  NO_NEED_PACKAGE_AUTO_RENEWAL_HEADER:
    'Here are what you will miss <br/> once you cancel auto-renewal <br/> of the service',
  NOT_USING_IT_OR_NOT_ENOUGH_AT_ALL_HEADER:
    'You may not know all the benefits <br/> you could have enjoyed from </br> AIS Mobile Care',
  NO_NEED_PACKAGE_RENEWAL_SUB_HEADER1: 'Easy swap and replacement for any or no reason',
  NO_NEED_PACKAGE_RENEWAL_SUB_HEADER2: 'You won’t regret having it and here’s why:',
  YOU_PAY_ONLY: 'You pay only',
  FOR_SWAP: 'For Swap',
  FOR_REPLACEMENT: 'For Replacement',
  ARE_YOU_SURE_WANT_TO_REMOVE: 'Are you sure you do not want the program to be auto-renewed?',
  STAY_WITH_AIS_MOBILE_CARE: 'Stay with AIS Mobile Care',
  STAY_WITH_AIS_MOBILE_CARE_TEXT: 'Proceed with submitting my termination request',
  PAY_LESS_SAVE_MORE:
    'With AIS Mobile Care protection plan, you <br/> only pay below amount to swap or <br/> replace your phone',
  YOU_SAVE: 'You save',
  SWAP_FEE: 'Swap Fee',
  REPLACEMENT_FEE: 'Replacement Fee',
  MODAL_HEADER:
    'After AIS Mobile Care has been terminated, you will not be able to rejoin the program on this device. You can only re-enroll on the program with a new device.',
  MODAL_TEXT:
    'To re-enroll in the program, you will need to purchase or activate it on a new device.',
  THANK_YOU_TEXT_REFUND:
    'The termination will be effective <br> immediately after this transaction has <br/> ended and you will receive a confirmation <br/> via SMS of the termination within 7 days. <br/><br/><b class="GoodByeText"> Thank you and goodbye!</b>',
  THANK_YOU_LABEL: 'Thank you for staying with <br/> AIS Mobile Care.',
  THANK_YOU_TEXT: 'Your device remains protected.',
  THANK_YOU_BTN_CLOSE: 'Close',
  LEAVE_FEEDBACK: 'Leave Feedback',
  REQUEST_SENT_SUCCESSFULLY: 'Please select preferred callback time', //'Request sent successfully',
  LEAVE_FEEDBACK_MSG:
    'We are sorry to see you leave. Our team will contact you back as per selected date and time.',
  FEEDBACK_COMMENT: 'We would like to hear from you!',
  ROUTE_TO_SAVEDESK:
    'We are sorry to see you leave.Our team will contact you back by the next business day within 9am - 6pm.',
  ROUTE_TO_SAVEDESK_TEXT: 'We would like to hear from you!',
  PRORATED_REFUND:
    'I can assist you to terminate the program. However, as per <a  class= "termeAndConditions" target="_blank" href="#/terms-conditions">Terms and Conditions</a>, we are not able to provide you with a refund.<br/><br/>If you proceed with the termination, we will offer the prorated refund as exception.',
  FULL_REFUND:
    'I can assist you to terminate the program. However, as per <a class = "termeAndConditions" target="_blank" href="#/terms-conditions">Terms and Conditions</a>, we are not able to provide you with a refund.<br/><br/>If you proceed with the termination, we will offer the full refund as exception.',
  NO_REFUND:
    'I can assist you to terminate the subscription and you will be charged the last subscription fee in this billing cycle. The termination will be effective immediately after this transaction has ended and you will receive a confirmation via SMS of the termination within 7 days.',
  NOT_ELIGIBLE_FOR_REFUND:
    'I can assist you to terminate the subscription and no refund will be provided as per <a class= "termeAndConditions" target="_blank" href="#/terms-conditions">Terms and Conditions.</a> <br/><br/>The termination will be effective immediately after this transaction has ended and you will receive a confirmation via SMS of the termination within 7 days.',
  TEXT_AREA_PLACEHOLDER: 'Let us know what can we improve on?',
  SELECT_TIME: 'Select Time',
  SELECT_DATE: 'Select Date',
  COST_TOO_MUCH_FOOTER_TEXT: 'You won’t regret having it!',
  ALTERNATE_CONTACT_NUMBER: 'Enter Alternate Contact Number',
  CALLBACK_WINDOW_MORNING: '09:00 AM to 12:00 PM',
  CALLBACK_WINDOW_EVENING: '01:00 PM to 06:00 PM',
  SAVEDESK_THANK_YOU_MSG1:
    'Our team will contact you back at the selected date and time.<br/><br/>',
  SAVEDESK_THANK_YOU_MSG2: 'Thank you for using AIS Mobile Care Service.  Good bye!',
  CHURN_HEADER: 'AIS Mobile Care Portal',
  ENTER_OTHER_REASON: 'Enter Reason',
  SELECTED_CALLBACK_DATE: 'CallBack Date',
  SELECTED_CALLBACK_TIME: 'CallBack Time',
  SELECTED_ALTERNATE_CONTACT_NUMBER: 'Alternate Contact Number',
  ENTERED_COMMENT: 'Comment',
  TRACK_THE_DEVICE: 'Click Here to Track the delivery',
  //WBKO
  WBKO_TRACK_MY_DELIVERY: 'WBKO_TRACK_MY_DELIVERY',
  WBKO_TRACK_MY_DELIVERY_NEW: 'WBKO_TRACK_MY_DELIVERY_NEW',
  WBKO_DELIVERY_DATE_CHANGE:
    'Please note that your request to change the delivery date to {selectedDeliveryDate} has been submitted and we have sent email confirmation to you at {emailAddress}',
  WBKO_DELIVERY_DATE_NOCHANGE:
    'Please note that your delivery date {selectedDeliveryDate} has no change.',
  WBKO_DELIVERY_ADDRESS_CHANGE:
    'Please note that your request to change delivery address to {changedAddress} and delivery date to {selectedDeliveryDate} has been submitted.  We have sent email confirmation to you at {emailAddress}',
  WBKO_DELIVERY_ADDRESS_NOCHANGE: 'Please note that your delivery address has no change.',
  WBKO_CANCEL_DELIVERY_MSG1:
    'Please note that your request to cancel SR# {srNumber} {deviceDetails} has been submitted and we have sent email confirmation to you at {emailAddress} ',
  WBKO_CANCEL_DELIVERY_MSG2:
    'For COD payment, you have not been charged <br/> <br/>For credit card payment, we will process a refund accordingly and you will be notified via SMS once refund has been processed. For further information on your refund status, please contact your credit card issuing bank.',
  WBKO_RESCHEDULE_DELIVERY_ENQUIRY_EMAIL_MSG:
    'Please note that your delivery arrangement has been submitted successfully and we have sent email confirmation to your email {emailAddress} ',
  WBKO_RESCHEDULE_DELIVERY_ENQUIRY_SMS_MSG:
    'Our courier company will contact you prior to the delivery at your mobile number {phoneNumber}.',
  WBKO_UPDATE_CONTACT_DETAILS_MSG1:
    'Please note that your new contact details has been updated to {fullName} and {phoneNumber} and we have sent email confirmation to you at {emailAddress}',
  WBKO_UPDATE_CONTACT_DETAILS_MSG2:
    'Our courier company will contact you prior to the delivery at your mobile {phoneNumber}.',
  WBKO_UPDATE_CONTACT_DETAILS_MSG3:
    'If 3PP is receiving the device on customer behalf, this person must <br/> 1) show the signed  copied of registered owner ID <br/> 2) show the 3PP ID card to the delivery man.',
  WBKO_FAQ_MSG:
    "For more information about AIS Mobile Care service, please <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'>visit our FAQ Page </a></strong>",
  WBKO_THANK_YOU_TEXT: 'Thanks for contacting <br/>AIS MobileCare',
  WBKO_CONFIRM_CHANGE_DELIVERY:
    'You must be personally present in person with your <b>National ID</b> in order to receive the delivery. <br/><br/>If you cannot be present on the date above, please change your available date and time',
  WBKO_CONFIRM_CHANGE_DELIVERY_HEADER:
    'You will receive the delivery in the following delivery schedule',
  WBKO_REASON_FOR_CANCEL_HEADER: 'Please enter Cancellation Reason',
  WBKO_REASON_FOR_CANCEL_PLACEHOLDER: 'Enter Cancellation Reason',
  WBKO_CANCElLATION_LANDING_HEADER: 'Please select cancellation reason',
  WBKO_UPDATE_CONTACT_DETAILS: 'Update Contact Details',
  WBKO_PREFFERED_ADDRESS: 'Please provide your preferred delivery address',
  WBKO_PREFFERED_DATE: 'Please select your preferred <br/> delivery date below',
  WBKO_SERVICE_DETAILS: 'Service Request Details',
  WBKO_SR_NO: 'SR No.',
  WBKO_SR_TYPE: 'SR Type',
  WBKO_DEVICE_DETAILS: 'Device Details',
  WBKO_SR_FEE: 'SR Fee (Inc. VAT)',
  WBKO_MODE_OF_PAYMENT: 'Mode of Payment',
  WBKO_SUBMISSION_DATE: 'Submission Date',
  WBKO_EXPECTED_DELIVERY_DATE: 'Excpected Delivery Date',
  WBKO_CREDIT_CARD: 'Credit Card',
  WBKO_CONFIRMED: 'Confirmed',
  WBKO_PROCESSING_ORDER: 'Processing Order',
  WBKO_SHIPPED: 'Shipped',
  WBKO_DELIVERY_ADDRESS: 'Delivery Address',
  WBKO_DELIVERY_STATUS_AFTER_SHIPPING: 'Click Here to Check Delivery Status',
  WBKO_TRACK_OR_END: {
    title: 'Is there anything else we can help you with?',
    buttons: [
      { name: 'TRACK', text: 'Track and Trace my Delivery' },
      { name: 'END', text: 'End the Inquiry' },
    ],
  },
  WBKO_CANCELLATION_REASON: {
    title: 'Please tell us more about your request below',
    subTitle:
      'Alternatively, for convenience, you may select from one of our commonly received requests.',
    buttons: [
      { name: 'DEVICEISWORKING', text: 'Device is working' },
      { name: 'NEWDEVICE', text: 'Bought a new device' },
      { name: 'ORIGINALDEVICE', text: 'Found original device' },
      { name: 'EXPENSIVE', text: 'SR Fee is expensive' },
      { name: 'FIXITELSEWHERE', text: 'Want to fix it elsewhere' },
      { name: 'OTHER', text: 'Other' },
    ],
  },
  WBKO_INQ_DESC: 'Customer has requested for delivery cancellation.\n Reason of cancellation : ',
  WBKO_UPDATE_CONTACT_HEADER: 'Please enter your contact details',
  WBKO_UPDATE_CONTACT_FIRSTNAME_PLACEHOLDER: 'First Name  Last Name',
  WBKO_UPDATE_CONTACT_PHONENUMBER_PLACEHOLDER: '0912345678',
  WBKO_UPDATE_CONTACT_IDNUMBER_PLACEHOLDER: 'Last 6 digits of ID Number',
  WBKO_UPDATE_CONTACT_FIRSTNAME: 'First-Last Name : ',
  WBKO_UPDATE_CONTACT_PHONENUMBER: 'Phone Number : ',
  WBKO_UPDATE_CONTACT_IDNUMBER: 'Last 6 digits of ID number : ',
  WBKO_ADDRESS_STRING: 'New Delivery Date: ',
  WBKO_UPDATE_CONTACT_DETAILS_TEXT:
    "If you are not available to receive the device at the original shipping address in person.<br> <br>1. Please signed copy of your proof of identify and provided to the authorized person...<br> <br>2. The authorized person is required to present his or her national ID card or Foreigner's ID and your signed signed copy ID to the courier upon delivery.",
  WBKO_ADDRESS_TEXT1:
    'Changing the delivery address may postpone the delivery date.\n \n <b>Would you like to change or keep your current delivery address?</b>',

  WELCOME_TO_ONLINE_SUPPORT: `Dear Khun {Username}, Welcome to our online support!`,
  HOW_MAY_I_HELP_YOU: 'How may I assist you today?',
  PLACEHOLDERS: {
    TYPE_YOUR_TEXT: 'Type your message here',
    TYPE_YOUR_ENQUIRY: 'Type your enquiry here',
    TYPE_YOUR_MOBILE_NUMBER: 'Type your mobile number here',
    TYPE_YOUR_NRIC: 'Type your NRIC number',
    TYPE_YOUR_ENROLLED_NAME: 'Type your enrolled name here',
    TYPE_YOUR_QUERY: 'Type your query here',
    TYPE_YOUR_QUERY_FOR_SERVICE_FEE: 'Please type your query to get clarification on service fee',
  },
  ENQUIRY_OPTIONS: {
    title: 'Please tell us more about your request below',
    subTitle:
      'Alternatively, for convenience, you may select from one of our commonly received requests.',
    buttons: [
      { name: 'REPLACEMENT', text: 'My phone is no longer in my possession' },
      { name: 'SWAP', text: 'My phone has visible signs of damage' },
      { name: 'MALFUNCTION', text: 'My phone is not working optimally' },
    ],
  },
  SWOP_OPTIONS: {
    title: "May I know what's the issue  you're having with your device?",
    buttons: [
      { name: 'Liquid Exposure', text: 'Liquid Exposure' },
      {
        name: 'Device is scratched or dented',
        text: 'Device is scratched or dented',
      },
      { name: 'My screen is damaged', text: 'My screen is damaged' },
      { name: 'Other issue', text: 'Other issue' },
    ],
  },
  MALFUNCTION_OPTIONS: {
    title: "May I know what's the issue you're having with your device?",
    buttons: [
      { name: 'Network error', text: 'Network error' },
      { name: 'Battery drains quickly', text: 'Battery drains quickly' },
      {
        name: 'Unable to "power off" handset',
        text: 'Unable to "power off" handset',
      },
      { name: 'Other issue', text: 'Other issue' },
    ],
  },

  UPLOAD_SR: {
    HEADER:
      "Please upload the service report that indicates that your in-warranty enrolled device's IMEI is changed.",
    CONTENT: "Drag and drop your file here or <a href=''> browse </a>file",
    ATTACHED_CONTENT: 'Drag and drop your file here or browse file',
    DISCLAIMER: 'Note: Upload a maximum of 3 files with size 1 MB each',
    ALLOWED_EXT: 'Supported file format: .pdf,.jpg,.jpeg,.png',
    BUTTONS: ['Cancel', 'Submit'],
    UPLOAD: 'Upload',
  },

  EXCHANGE_OPTIONS: {
    title: 'Select 1/3 options',
    buttons: [
      { name: 'AIS Exchange', text: 'AIS Exchange' },
      { name: 'Manufacturer Exchange', text: 'Manufacturer Exchange' },
      { name: 'No Exchange', text: 'No Exchange' },
    ],
  },
  OEM_EXCHANGE_SELECTED: 'Manufacturer Exchange',
  NO_EXCHANGE_SELECTED: 'No Exchange',
  AIS_EXCHANGE_SELECTED: 'AIS Exchange',

  // MDN Related messages
  //New MDN UI related messages
  ASK_FOR_MDN: {
    HEADER: 'Please enter your 10 digit registered mobile number',
    WBKO_HEADER: 'Please enter your 10 digit registered mobile number',
    CONTENT:
      "I'm sorry to hear, let me help you to process your request. May I ask for your registered mobile number?",
    CONTENT1:
      "I'm sorry to hear, let me help you to process your request. May I ask for your registered mobile number?",
    PLACEHOLDER: 'Enter your Mobile Number',
    WBKO_PLACEHOLDER: 'Enter your mobile number',
    BUTTON: 'Submit',
  },
  RESUME_CASE_ASK_FOR_MDN: {
    HEADER: 'Please enter your 10 digit registered mobile number',
    WBKO_HEADER: 'Please enter your 10 digit registered mobile number',
    CONTENT: 'Before we proceed, may I ask your registered mobile number?',
    PLACEHOLDER: 'Enter your Mobile Number',
    WBKO_PLACEHOLDER: 'Enter your mobile number',
    BUTTON: 'Submit',
  },
  ABOUT_ENQUIRY_CASE_ASK_FOR_MDN: {
    HEADER: 'Please enter your 10 digit registered mobile number',
    CONTENT: 'May I ask your registered mobile number?',
    PLACEHOLDER: 'Enter your Mobile Number',
    BUTTON: 'Submit',
  },
  INCORRECT_MDN_FIRST_ATTEMPT: {
    HEADER: 'Please enter your  10 digit registered mobile number',
    WBKO_HEADER: 'Please enter your 10 digit registered mobile number',
    CONTENT:
      "We regret to inform you that the mobile number you've provided doesn't match the details on our record.So can you provide your mobile number again?",
    CONTENT1:
      "We regret to inform you that the mobile number you've provided doesn't match the details on our record.So can you provide your mobile number again?",
    PLACEHOLDER: 'Enter your Mobile Number',
    WBKO_PLACEHOLDER: 'Enter your mobile number',
    BUTTON: 'Submit',
  },

  //ASK_MDN: "I'm sorry to hear, let me help you to process your request.<br><br> May I ask your registered mobile number?",
  //RESUME_CASE_ASK_MDN: "May I ask your registered mobile number?",
  //ABOUT_ENQUIRY_CASE_ASK_MDN: "May I ask your registered mobile number?",
  //INCORRECT_MDN: "We regret to inform you that the mobile number you've provided doesn't match the details on our record. <br><br> So could you provide your mobile number again?",
  MDN_ERROR: {
    HEADER: `I'm Sorry {Username}`,
    CONTENT:
      'We regret to inform that your request could not be processed further now. Our customer support will contact you shortly.',
  },
  INCORRECT_MDN_SECOND_ATTEMPT: {
    HEADER: 'Find out more about AIS Mobile Care',
    BUTTON: 'View FAQ',
  },
  MOBILE_EXAMPLE: 'Format: 0123456789',

  // Name related
  INCORRECT_NAME_FIRST_ATTEMPT: {
    HEADER: 'Please provide your <br/> full registered name again',
    CONTENT:
      "We regret to inform you that the name you've provided doesn't match the details on our record.Can you provide your full registered name again ?",
    PLACEHOLDER: 'Enter your Name',
    FIRST_NAME: 'Enter your first name',
    LAST_NAME: 'Enter your last name',
    BUTTON: 'Submit',
  },

  //INCORRECT_NAME: "We regret to inform you that the name you've provided doesn't match the details on our record.<br><br> Can you provide your full registered name again?",
  INCORRECT_NAME_SECOND_ATTEMPT: {
    HEADER: 'Please provide your <br/> full registered name again',
    CONTENT:
      "We regret to inform you that your given full name still doesn't match with our record. Can you provide your full registered name again ?",
    PLACEHOLDER: 'Enter your Name',
    FIRST_NAME: 'Enter your first name',
    LAST_NAME: 'Enter your last name',
    BUTTON: 'Submit',
  },
  // NRIC
  //New NRIC UI related messages
  ASK_FOR_NRIC: {
    HEADER: 'Please provide the last 4 digits of your Identification/Passport number',
    PLACEHOLDER: 'Enter the last 4 digits',
    WBKO_HEADER: 'Please provide the last 4 digits of your Identification/Passport number',
    WBKO_PLACEHOLDER: 'Enter the last 4 digits',
    BUTTON: 'Submit',
  },
  WBKO: 'WBKO',
  ASK_FOR_LEAVING_AIS_REASON: {
    HEADER: 'AIS reason',
    CONTENT1: 'Your details has been successfully verified',
    CONTENT2: "We're sorry to hear that you want to leave AIS Mobile Care",
    CONTENT3:
      'To help you better, please select the reason why you would like to cancel your subscription',
  },
  AIS_REASON_NOT_HELPFULL: {
    HEADER: 'AIS reason',
    CONTENT1: 'Your details has been successfully verified',
    CONTENT2: "We're sorry to hear that you want to leave AIS Mobile Care",
    CONTENT3:
      'To help you better, please select the reason why you would like to cancel your subscription',
  },
  LOAD_AIS_SUBREASON: {
    HEADER: 'AIS reason',
    CONTENT1: 'Your details has been successfully verified',
    CONTENT2: "We're sorry to hear that you want to leave AIS Mobile Care",
    CONTENT3:
      'To help you better, please select the reason why you would like to cancel your subscription',
  },
  INCORRECT_NRIC_FIRST_ATTEMPT: {
    HEADER: 'Please provide the last 4 digits of your Identification/Passport number',
    CONTENT: 'Sorry, we are still unable to validate your identification number',
    CONTENT1: 'Sorry, we are still unable to validate your identification number',
    PLACEHOLDER: 'Enter the last 4 digits',
    BUTTON: 'Submit',
    WBKO_HEADER: 'Please provide the last 4 digits of your Identification/Passport number',
    WBKO_PLACEHOLDER: 'Enter the last 4 digits',
  },

  //ASK_NRIC: "Thanks {Username}!<br><br> Could you please also provide your NRIC/FIN number for further verifications?",
  //INCORRECT_NRIC: "We regret to inform you that the identification number you've provided doesn't match the details on our record.<br><br> Can you provide your NRIC/FIN again?",
  INCORRECT_NRIC_SECOND_ATTEMPT: {
    HEADER: 'Incorrect National Identification Number/Passport Number',
    CONTENT:
      "Again, we regret to inform you that your given National Identification Number/Passport Number still doesn't match with our record.",
  },
  NRIC_ERROR: {
    HEADER: 'I am sorry {Username}',
    CONTENT:
      'We regret to inform that your request could not be processed further now. Our customer support will contact you shortly.',
  },
  ELIGIBLE_FOR_SERVICE: `Thanks {Username}, for providing all necessary information! <br><br> Your account has been verified successfully, so let's proceed to the next step.`,
  USER_CURRENT_DELIVERY_DETAILS: `Please find your current delivery request details below.`,

  CONTINUE_PREVIOUS_SR: 'Continue with previous SR',
  // NO_EXISTING_SR: "You do not have any existing Service request. Please file a new one!",
  NO_EXISTING_SR: {
    HEADER: 'Incorrect Request',
    CONTENT:
      'There is no existing request to your mobile number. \n\n Thanks for contacting AIS Mobile Care Support!',
    BUTTON: 'New SR',
  },
  PAYMENT_INVALID_LABEL: {
    NAME: 'Please insert valid name',
    CARD_NUMBER: 'Please insert valid number',
    EXPIRY: 'Please insert expiry in valid format',
    CVC: 'Please insert the valid cvc',
    NAME_MANDATORY: 'Name field is mandatory',
  },
  NATIONAL_ID_REQUEST:
    'Thank you. Could you also provide your identification details for further verification?',
  NO_CURRENT_DELIVERY: {
    CONTENT: 'There is no recent Service Request submitted with AIS Mobile Care.',
    BUTTONS: ['Close'],
  },
  CLOSE_REQUEST: {
    CONTENT: 'Thank you for contacting AIS Mobile Care Support!',
    BUTTONS: ['Close'],
  },
  NO_EXISTING_CUSTOMER:
    'Sorry, this service is only available for registered AIS Mobile Care customers only.',
  /*  NO_EXISTING_CUSTOMER: {
         CONTENT: "Sorry, this service is only available for registered AIS Mobile Care customers only.",
         BUTTONS: ["Close"]
     }, */
  TRANSFERRING_TO_AGENT:
    'You are being transferred to our live chat support to provide further assistance',
  TRANSFERRED_TO_AGENT: "You're transferred to our customer support",
  CHAT_SUPPORT_ENDED: 'CHAT SUPPORT HAS ENDED',
  CHAT_ENDED_BY_USER: 'The user has ended the chat.',
  CHECK_DEVICES_LOADER_START: 'CHECKING FOR AVAILABLE DEVICES...',
  STANDARDISE_ADDRESS_LOADER_START: 'VALIDATING ADDRESS...',
  STANDARDISE_ADDRESS_LOADER_END: 'ADDRESS VALIDATED!',
  STANDARDISE_ADDRESS_LOADER_FAILURE: 'WRONG ADDRESS!',
  CC_DETAILS_PROGRESS_START: 'CREDIT CARD DETAILS ARE BEING VERIFIED...',
  CC_DETAILS_PROGRESS_END: 'CREDIT CARD DETAILS VERIFIED SUCCESSFULLY!',
  CC_DETAILS_PROGRESS_FAILED: 'CREDIT CARD DETAILS INCORRECT!',
  SHOW_DELIVERY_TIME_DATE:
    'Please note that the delivery date for the device is <strong> {Date} {Time Slot} </strong>. <br><br>Please back up all your data prior to the delivery.',
  IMEI_FIRST_INCORRECT_ATTEMPT:
    "We regret to inform you that provided IMEI doesn't tally with the enrollment record.<br><br> Please enter last 6 digits of your device's IMEI code again?",
  IMEI_SECOND_INCORRECT_ATTEMPT:
    "The IMEI you provided doesn't tally with the enrollment record. Have you performed any exchange with AIS or OEM previously?",
  WAIT_FOR_ENROLLED_DEVICE:
    'We will place your request under a priority backorder. We will contact you back once the stock is available for delivery arrangement.',
  NOTIFY_ON_AVAILABILITY:
    'Thank you for connecting with us! We will send you an SMS once the stock is available for delivery arrangement. <br><br>Please use this online channel to engage us directly if you have any concerns or questions.',
  THANK_YOU_FOR_CONNECTING:
    'Thank you for connecting with us! <br><br> Again, you will receive a confirmation SMS and Email shortly. Please use this SMS or Email to engage us directly if you have any concerns or questions.',
  BE_PRESENT_AT_ADDRESS:
    'Please be informed that you will need to be present at the given delivery address. <br><br> Please make sure that you have your ID card as used in this verification in order for us to deliver successfully.',
  THANKS_FOR_CONNECTING_ORDER_PLACED:
    'Thank you for connecting with us! Again, you will receive a confirmation SMS and Email shortly. Please use this SMS or Email to engage us directly if you have any concerns or questions.',
  SWAP_MAL_BACKORDER_CASE_RETURN_DEVICE:
    'Upon receiving the delivered device, please return your original device with all the accessories removed (batteries, memory card  SIM card etc) to our courier specialist.<br><br> You will need to return your original device to our courier specialist upon receiving the delivered device to qualify for an exchange. ',
  SWAP_MAL_BACKORDER_CASE_RETURN_DEVICE1:
    'Please be advised to back up your data before the courier arrives. Our courier will stay with you for up to 10 minutes. Please present your identification card in person for verification and sign for the package.<br> <br> If the device cannot be delivered successfully, it will be considered as a missed delivery and delivery charges may apply for subsequent delivery. <br> <br> You will need to contact us to reschedule another delivery date.',
  REPLACEMENT_BACKORDER_RETURN_DEVICE:
    'Our courier will stay with you for up to 10 minutes. Please present your identification card in person for verification and sign for the package.<br> <br> If the device cannot be delivered successfully, it will be considered as a missed delivery and delivery charges may apply for subsequent delivery. <br> <br> You will need to contact us to reschedule another delivery date.',
  NO_EXCHANGE:
    'We regret to inform you that we are unable to proceed with the service request at this point. <br><br>Please prepare the supporting documents and contact us on this online channel.<br><br> Thanks for contacting AIS Mobile Care Support!',
  OEM_EXCHANGE:
    "Please make sure to prepare a copy of the service report indicating the replacement IMEI done by your phone manufacturer for our courier specialist to verify upon handover otherwise device can't be delivered.",
  AIS_EXCHANGE:
    "Please make sure to prepare a copy of the service report indicating the replacement IMEI is done by AIS Exchange for our courier specialist to verify upon handover otherwise device can't be delivered.",
  VALID_ADDRESS_NOTE:
    'We can ship anywhere in Thailand provided it is a residential or business address (PO box / APO address not accepted).<br><br> Note : Delivery address will be updated for this request however the registered address will remain unaltered',
  ENQUIRY_DESCRIPTION_CALLBACK:
    'On {DateTimeOfRequest}, Customer requested to schedule a callback on Mobile Number:{MobileNumber}',
  LIVE_CHAT_ASSISTANCE_UNAVAILABLE: 'Live Chat Assistance Unavailable',
  ALL_AGENT_OFFLINE_ENQUIRY: 'Agent offline',
  ALL_AGENT_UNAVAILABLE_ENQUIRY: 'Live Chat Assistance Unavailable',
  TRIAGE_QUESTION: [
    {
      HEADER: 'Have you tried to turn off and on your phone?',
      ICON: 'deviceOffIcon',
      BUTTONS: ['No', 'Yes'],
    },
    {
      HEADER: 'Have you updated operating system?',
      ICON: 'deviceOffIcon',
      BUTTONS: ['No', 'Yes'],
    },
    {
      HEADER: 'Have you reset to factory default?',
      ICON: 'helpIcon',
      BUTTONS: ['No', 'Yes'],
    },
  ],
  TRIAGE_MESSAGE: [
    'You need to perform a restart on your phone & monitor device.',
    "You need to update your device's operating system & monitor device.",
    'You need to perform a hardware reset and monitor device.',
  ],
  TRIAGE_MESSAGE_NEW: [
    {
      HEADER: '<strong>BEFORE YOU RESTART YOUR DEVICE</strong>',
      CONTENT: `<strong>If you are using your mobile phones, </strong> you can resume back to this service request by simple logging in select the <strong>Resume Existing Request</strong> to continue with the process`,
      BUTTON: 'Confirm Device Restart',
    },
    {
      HEADER: '<strong>BEFORE YOU UPDATE YOUR OPERATING SYSTEM</strong>',
      CONTENT: `<strong>If you are using your mobile phones, </strong> you can resume back to this service request by simple logging in select the <strong>Resume Existing Request</strong> to continue with the process`,
      BUTTON: 'Confirm Device Restart',
    },
    {
      HEADER: '<strong>BEFORE YOU RESET TO FACTORY DEFAULT</strong>',
      CONTENT: `<strong>If you are using your mobile phones, </strong> you can resume back to this service request by simple logging in select the <strong>Resume Existing Request</strong> to continue with the process`,
      BUTTON: 'Confirm Device Restart',
    },
  ],
  ISSUE_RESOLVED: {
    HEADER: 'Is This Issue Resolved?',
    BUTTONS: ['No', 'Yes'],
    YES_TEXT: 'Yes',
    NO_TEXT: 'No',
  },
  CURRENT_DEVICE_UNAVAILABLE:
    'We regret to inform you that, this device <storng> {UnavailableDevice} </strong> is out of stock now.',
  INCORRECT_IMEI: 'Please enter valid IMEI',

  NAME_ERROR: {
    HEADER: `I'm Sorry {Username}`,
    CONTENT:
      'Your name is not matched with your enrolled name for provided mobile number. I have to transfer your issue to our Customer Support.',
  },
  AGENT_BUSY: {
    HEADER: 'Assigned Customer Support Is Busy',
    CONTENT: 'Please wait, You are in queue. Agent will be available 2 mins/secs',
  },
  ALL_AGENT_BUSY: {
    PRIMARY_HEADER: 'Agents are busy',
    SECONDARY_HEADER:
      'Sorry! <br/> All our live chat agents are busy at this moment. <br> Please wait for a moment or try again after sometimes.',
    PRIMARY_CONTENT:
      "For more information about AIS Mobile Care service, you may visit <br> <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> ",
    SECONDARY_CONTENT:
      "For more information about AIS Mobile Care service, you may visit <br> <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong>",
    INPUT_PLACEHOLDER: 'Enter your mobile number',
    INPUT_LABEL: 'Mobile Number',
    BUTTON: ['Submit'],
  },

  ALL_AGENT_BUSY_MODAL: {
    PRIMARY_HEADER: 'Agents are busy',
    SECONDARY_HEADER:
      'Sorry! <br/> All our live chat agents are busy at this moment. <br> Please wait for a moment or try again after sometimes.',
    PRIMARY_CONTENT:
      "For more information about AIS Mobile Care service, you may visit <br> <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> ",
    SECONDARY_CONTENT:
      "For more information about AIS Mobile Care service, you may visit <br> <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> ",
    INPUT_PLACEHOLDER: 'Enter your mobile number',
    INPUT_LABEL: 'Mobile Number',
    BUTTON: ['Submit'],
  },

  ALL_AGENT_OFFLINE: {
    PRIMARY_HEADER: 'Agents are busy',
    SECONDARY_HEADER: `Sorry!<br />All our live agents are busy at this moment.<br />Please wait for a moment to try again after sometime`,
    PRIMARY_CONTENT:
      "For more information about AIS Mobile Care service, you may visit <br /><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a><br><br> Thank you for contacting AIS<br />customer support.​ ",
    SECONDARY_CONTENT:
      "For more information about AIS Mobile Care service, you may visit <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> <br><br> Thank you for contacting AIS customer support.​ ",
    INPUT_PLACEHOLDER: 'Enter your mobile number',
    INPUT_LABEL: 'Mobile Number',
    BUTTON: ['Submit'],
  },
  ALL_AGENT_OFFLINE_MODAL: {
    PRIMARY_HEADER: 'Service Unavailable',
    SECONDARY_HEADER: 'Sorry! <br/> Our live chat support is currently <br/> unavailable.',
    PRIMARY_CONTENT:
      "For more information about AIS Mobile Care service, you may visit <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> <br><br> Thank you for contacting AIS customer support.​ ",
    SECONDARY_CONTENT:
      "For more information about AIS Mobile Care service, you may visit <strong><a class='ais_support-hotline' target='_blank' href='https://ais-mobilecare.asurion.com/#/faq'> https://ais-mobilecare.asurion.com/#/faq</a></strong> <br><br> Thank you for contacting AIS customer support.​ ",
    INPUT_PLACEHOLDER: 'Enter your mobile number',
    INPUT_LABEL: 'Mobile Number',
    BUTTON: ['Submit'],
  },
  // Progressbar messages for account verification
  ACCOUNT_BEING_VERIFIED: 'YOUR ACCOUNT IS BEING VERIFIED',
  ACCOUNT_VERIFIED: 'YOUR ACCOUNT IS VERIFIED',
  ACCOUNT_UNVERIFIED: 'SORRY, YOUR ACCOUNT IS NOT VERIFIED!',

  CC_ATTEMPT_ERROR: {
    HEADER: `I'm Sorry {Username}`,
    CONTENT:
      'This is your second failed attempt. I have to transfer your issue to our Customer Support.',
  },
  ADDRESS_ATTEMPT_ERROR: {
    HEADER: `I'm Sorry {Username}`,
    CONTENT:
      'This is your second failed attempt. I have to transfer your issue to our Customer Support.',
  },
  ADDRESS: {
    ADDRESS_LINE1: 'Address Line 1:',
    ADDRESS_LINE2: 'Address Line 2:',
    ADDRESS_LINE3: 'Address Line 3:',
    PIN_CODE: 'PIN_CODE',
    CITY: 'District:',
    STATE: 'Province:',
  },
  PROCEED: 'Proceed',
  CANCEL: 'Cancel',
  CONTINUE: 'Continue',
  ACCEPT: 'Accept',
  TERMS_ACCEPTED: 'Terms Accepted',
  AGREEE_WITH_TERMS: 'Do You Agree With Terms?',
  BACKORDER_MESSAGE:
    'As your enrolled device is currently not available, We placed it on priority backorder.',
  CANT_ACCEPT_DEVICE_WITHOUT_FMIP_OFF:
    "Before we proceed further, you need to take the backup first and turn off the <strong>Find My iPhone</strong> feature of your device. We can not accept your device because it's linked to your apple account and we'll charge your <strong>Inoperable Device Fee</strong> which is like paying for our outright in today's value.",
  TURN_OFF_FMIP: `Before we proceed further, you need to turn off the <strong>Find My iPhone </strong> feature of your device.`,
  TURN_OFF_FMIP_EXTENDED:
    "We can not accept your device because it's linked to your apple account and we'll charge you <strong>Inoperable Device Fee</strong> which is like paying for our outright in today's value",
  AGREE_WITH_TERMS: 'Original Equipment Manufacture Warranty Check',
  WARRANTY_VOID_DISCLAIMER:
    "Please be informed that once you have completed the device exchange with us, the Original Equipment Manufacturer's warranty of your device will be void. However, your device will be covered with our 180 days warranty under AIS Mobile Care. <br><br> Do you agree with our terms?",
  STILL_IN_WARRANTY_DISCLAIMER:
    'Please note that your device is still covered under the 1 year Original Equipment Manufacturer warranty, you may refer to the OEM authorized dealer as well. <br><br> However, if you wish to proceed with the device swop, I would be glad to assist you. Please note that a Service Fee will apply for the Swop request.',
  IN_STOCK_DEVICE_MESSAGE: 'This device is ready to deliver',
  OUT_OF_STOCK_DEVICE_MESSAGE: 'If you still prefer this device, it will take 5-7 days to deliver',
  CHECK_ALTERNATE_DEVICE: 'Check Alternative Devices',
  WAIT_FOR_DEVICE: 'Wait for my device to be restocked',
  CONFIRM_CURRENT_DEVICE: {
    HEADER: 'Your Enrolled Device',
    CONTENT: 'Please confirm whether the following device is your enrolled device?',
    BUTTONS: ['No', 'Yes'],
    NO_TEXT: 'No',
    YES_TEXT่: 'Yes',
  },
  SELECT_YOUR_DEVICE: ['Your Enrolled Device', 'Your Replaced Device'],
  SELECT_DEVICE_CLAIM: 'Choose one of the below device',
  SELECT_OTHER_DEVICE: 'Select other device',
  // Download Messages
  DOWNLOAD_HEADER:
    'Please upload the signed service request confirmation form to proceed to next step.',
  ELIGIBLE_FOR_RESHIP: `Please contact our Customer Support on this hotline <a class='support-hotline' href='tel://0980981175'>0980981175</a> with regards to your recent shipped device.<br><br> Thanks for contacting AIS Mobile Care Support!`,
  PAYMENT_ORDER_PENDING:
    "Please note that your request has been submitted to our internal review process.<br><br><strong> Please note that service fee wouldn't be charged to your credit card until your request is fully approved.</strong><br><br>So, You will be notified by SMS and Email once it is fully approved.",
  PAYMENT_ORDER_PENDING_COD:
    'Please note that your request has been submitted to our internal review process.<br><br>So, You will be notified by SMS and Email once it is fully approved.',
  ORDER_CONFIRMED:
    'Thanks for your confirmation!<br><br> Let me proceed to submit your service request.',
  ORDER_APPROVED:
    'Please note that your request has submitted and approved successfully. <br><br>You will receive a confirmation SMS and Email shortly with your request details shortly.',
  NO_PAYMENT_REVIEW: {
    HEADER:
      "Please note that your current request initiated from our voice channel 0980981175 can't be resumed. So would you like to create a request now?",
    BUTTONS: ['Yes, Proceed'],
  },
  SELECT_DOWNLOAD_DOCUMENT: {
    HEADER: 'Select Document',
    BUTTONS: ['No', 'Yes'],
  },
  NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT:
    'If you are just enrolled the device, it takes 24 hrs to reflect or if customer just paid the bill, it takes 24 hrs to reflect and/or be able to submit the SR.',
  NOT_PROCEED_FURTHER:
    "We regret to inform you that we are unable to proceed with the service request now. Please feel free to contact our Customer Support hotline <a class='support-hotline' href='tel://0980981175'>0980981175</a> for further assistance during 8am-10pm Bangkok Time. <br><br> Thanks for contacting AIS Mobile Care Support!",
  NO_ACCESSORIES_PACKAGE: `Before we proceed with your request for the replacement device, We would like to inform you that our devices will be packed in a <strong> AIS Mobile Care box with no accessories.</strong>.`,
  S_PEN_INFO: `If your original device comes with S-Pen, please kindly return your S-Pen. If your selected devices comes with S-Pen, it will be provided.`,
  DEVICE_BACKUP:
    'We strongly recommend that you back up your data before  returning your device to us.',
  // DEVICE_IN_STOCK: `{Username}, I’m happy to inform you that we have an iPhone X 64GB Silver in stock. `,
  DEVICE_IN_STOCK: 'We are happy to inform you that we have this device in our stock now',
  CURRENT_DEVICE_OPTIONS: {
    HEADER: 'Then please choose the correct enrolled device from this list.',
    BRAND_DD_HEADER: 'Select Brand',
    MODEL_DD_HEADER: 'Select Model',
    BUTTON: 'Select',
  },
  FAQ_PAGE: {
    CONTENT: 'Need more help?',
    BUTTONS: ['No', 'Yes'],
  },
  CONFIRM_EXISTING_CUSTOMER: {
    CONTENT: 'Are you an existing AIS Mobile Care customer?',
    BUTTONS: ['No', 'Yes'],
  },
  GE_INQUIRY_REASON: {
    CONTENT: 'Please provide the details of your enquiry',
    HEADER: 'Please provide the details of your enquiry',
    PLACEHOLDER: 'Enter enquiry details',
    BUTTON: 'Submit',
  },
  GENERAL_ENQUIRY: {
    HEADER: 'What would you like to enquire?',
    ENQUIRY_TYPE_HEADER: 'Enquiry type',
    ENQUIRY_SUBTYPE_HEADER: 'Enquiry Sub type',
    PLACEHOLDER: 'Enter enquiry details',
    BUTTON: 'Submit Enquiry',
  },
  AVAIL_DEVICE: {
    HEADER: 'Do You Want Avail Replacement Device?',
    BUTTONS: ['No', 'Yes'],
  },
  PROCEED_SERVICE_FEE: {
    HEADER: 'Your Service Fee',
    CONTENT:
      'Please note that {perilType} service request fee including VAT will be <strong> THB {TotalAmount}</strong>.',
    BUTTON: 'Proceed',
  },
  COD_CONFIRMATION: {
    HEADER: 'Please prepare exact amount of cash to collect the device from our courier service',
    CONTENT: 'Service Request Fee <br/><br/> <strong> THB {TotalAmount}</strong><br/>',
    BUTTONS: ['Cancel', 'Proceed'],
  },
  TURN_OFF_MY_IPHONE: {
    HEADER: 'Please follow below steps on how to turn off FMIP',
    CONTENT1: '1.Go to setting',
    CONTENT2: '2. Select iCloud',
    CONTENT3: '3. Select FMIP',
    CONTENT4: '4. Turn off FMIP and fill in the apple ID to turn it off',
    CONTENT5: 'Enter the Apple ID password for the account listed and tap Turn Off.',
    BUTTON: 'Tap here when FMIP is turned off',
    TURN_OFF_FMIP_MESSAGE:
      "Find My iPhone will now be disabled for that iOS device, and you can continue to reset the device to its factory settings (if you're planning to sell it.) Do this using Settings > General > Reset and Erase All Content and Settings.",
  },
  CONFIRM_FMIP_IS_OFF: {
    HEADER: 'Please confirm that  your Find My iPhone feature is disabled.',
    BUTTONS: ['No', 'Yes'],
  },
  TURN_OFF_FMIP_INSTRUCTIONS: {
    HEADER: 'Please follow below steps on how to turn off FMIP',
    CONTENT1: '1. Go to setting',
    CONTENT2: '2. Select iCloud',
    CONTENT3: '3. Select FMIP',
    CONTENT4: '4. Turn off FMIP and fill in the apple ID to turn it off',
    CONTENT5:
      '<strong> Remark: please turn off FMIP untill the service request is complete to avoid the delivery delay.</strong>',
    BUTTON: 'Tap here when FMIP is turned off',
  },
  SHIPPING_ADDRESS_HOLD_CONFIRM_ENROLLED_ADDRESS:
    'In order for us to complete this service request, we can only deliver the device to your enrolled address with this postal code {Postal Code}. <br><br>Could I proceed to arrange for the delivery to your enrolled address?',
  CONFIRM_ENROLLED_ADDRESS: {
    HEADER: 'Is the below registered address correct for delivery of your offered device.',
    WBKO_HEADER: 'Is the below address correct for delivery of your offered device?',
    BUTTONS: ['No', 'Yes', 'Change', 'Cancel', 'Change', 'Keep'],
  },
  //ashwin
  FINAL_CONFIRM_ENROLLED_ADDRESS: {
    HEADER:
      'In order for us to complete this service request, we can only deliver the device to your enrolled address with this postal code',
    BUTTONS: ['Proceed'],
  },
  CONFIRM_EMAIL_ADDRESS: {
    HEADER: 'Would you like to send delivery notification Email to this enrolled email address?',
    BUTTONS: ['No', 'Yes'],
  },
  CONFIRM_MOB_NO: {
    HEADER: 'Would you like to send delivery notification SMS to your enrolled mobile number?',
    BUTTONS: ['No', 'Yes'],
  },
  ASK_CONTACT_FOR_ENQUIRY_CALLBACK: {
    HEADER: 'Please provide your mobile number.',
    PLACEHOLDER: 'Enter 10 digit contact number',
    BUTTONS: ['Submit'],
  },
  CONFIRM_DELIVERY_ADDRESS:
    'In our records, is this still the address that you want us to ship your replacement device?',
  PAYMENT_FEE: {
    HEADER: 'Mode of payment',
    CONTENT:
      'Please note that total amount charged to you will be <strong>THB {TotalAmount} including VAT as well </strong>. <br><br>You have following options to pay this service fee.',
    BUTTON: 'Proceed',
    BUTTON1: 'Credit Card',
    BUTTON2: 'Cash on delivery',
    DISCOUNT: '*1% Discount',
    DISCOUNT1: 'for Credit Card Payments',
    NOTE: '<strong>Note:</strong>You will receive 1% discount on this service fee with credit card payment method is selected)',
  },
  READY_CREDIT_CARD: 'Please get ready your credit card for payment.',
  WEB_CREDITCARD_FORM: {
    HEADER: 'Enter Your Credit Card Details',
    LABELS: {
      NAME: 'Name printed on credit Card',
      CARD_NO: '16 digit credit card number',
      EXPIRY: 'Expiration Date',
      CVC: 'CVC',
    },
    PLACEHOLDERS: {
      NAME: 'Name printed on Credit Card',
      CARD_NO: '16 digit credit card number',
      EXPIRY: 'Expiry MM-YYYY',
      CVC: 'CVC',
    },
    BUTTONS: ['Cancel', 'Clear', 'Submit'],
  },
  PAYMENT_BEING_VERIFIED: 'YOUR PAYMENT IS BEING VERIFIED',
  PAYMENT_VERIFIED: 'YOUR PAYMENT IS VERIFIED',
  ORDER_BEING_SUBMITTED: 'YOUR ORDER IS BEING SUBMITTED',
  ORDER_SUBMITTED: 'YOUR ORDER IS SUBMITTED',
  DEVICE_CREDITCARD_FORM: {
    HEADER: 'Enter Your Credit Card Details',
    PLACEHOLDERS: {
      NAME: 'Name printed on Credit Card',
      CARD_NO: '16 digit credit card number',
      EXPIRY: 'Expiry MM-YYYY',
      CVC: 'CVC',
    },
    BUTTONS: ['Submit Payment'],
  },
  THANKS_FOR_CC_DETAILS:
    'Thank you for providing credit card details.<br><br> Please wait for a moment while we validate your credit card details.',
  THANKS_FOR_PAYMENT: `Thank you {Username}. Your credit card details are validated successfully.`,
  WE_WILL_DELIVER: 'I am pleased to inform you that we’ll deliver your device today.',
  WEB_SCHEDULE_FORM: {
    HEADER: 'Change My Delivery Schedule',
    PLACEHOLDERS: {
      DATE: 'Select Date',
      SLOT: 'Select Time Slot',
    },
    SLOT_OPTIONS: ['Afternoon Slot (By 8PM)', 'Evening Slot (By 5PM)'],
    SLOT_OPTIONS_VALUES: ['6H', '14H', '17H'],
    BUTTONS: ['Submit'],
  },
  SLOTS_MAPPING: [
    {
      RESP_VALUE: 'Same day Evening',
      LABEL: 'SLOT1',
      VALUE: '6H',
      DISPLAY_OPTION: 'By 5PM',
    },
    {
      RESP_VALUE: 'Next Day Afternoon',
      LABEL: 'SLOT2',
      VALUE: '14H',
      DISPLAY_OPTION: 'By 8PM',
    },
    {
      RESP_VALUE: 'Next Day Evening',
      LABEL: 'SLOT3',
      VALUE: '17H',
      DISPLAY_OPTION: 'By 5PM',
    },
  ],
  DELIVERY_SLOT_MAPPING: [
    {
      RESP_VALUE: 'Next Day',
      LABEL: 'SLOT1',
      VALUE: '12H',
      START_TIME: '13:45PM',
      END_TIME: '23:59PM',
      DISPLAY_OPTION: '08:00 - 12:00',
    },
    {
      RESP_VALUE: 'Next Day',
      LABEL: 'SLOT2',
      VALUE: '18H',
      DISPLAY_OPTION: '14:00 - 18:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT3',
      VALUE: '12H',
      START_TIME: '00:00AM',
      END_TIME: '07:45AM',
      DISPLAY_OPTION: '08:00 - 12:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT4',
      VALUE: '4H',
      START_TIME: '07:45AM',
      END_TIME: '09:45AM',
      DISPLAY_OPTION: '10:00 - 14:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT5',
      VALUE: '4H',
      START_TIME: '09:45AM',
      END_TIME: '10:45AM',
      DISPLAY_OPTION: '11:00 - 15:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT6',
      VALUE: '4H',
      START_TIME: '10:45AM',
      END_TIME: '11:45AM',
      DISPLAY_OPTION: '12:00 - 16:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT7',
      VALUE: '4H',
      START_TIME: '11:45AM',
      END_TIME: '12:45PM',
      DISPLAY_OPTION: '13:00 - 17:00',
    },
    {
      RESP_VALUE: 'Same Day',
      LABEL: 'SLOT8',
      VALUE: '4H',
      START_TIME: '12:45PM',
      END_TIME: '13:45PM',
      DISPLAY_OPTION: '14:00 - 18:00',
    },
  ],
  CONFIRM_DELIVERY_SCHEDULE: {
    HEADER: 'You will receive the delivery in the following delivery schedule',
    SLOTS: ['By 8PM', 'By 5PM'],
    BUTTONS: ['Change', 'Keep', 'Confirm', 'Cancel'],
    WBKO_BUTTONS: ['Change', 'Keep', 'Confirm', 'Cancel'],
  },
  CONFIRM_SIM_CARD: {
    HEADER: 'Do You Need A SIM To Be Delivered Along With The Device?',
    BUTTONS: ['No', 'Yes'],
  },
  CONFIRM_CHAT_SUPPORT: {
    HEADER: 'Do You Want To Chat With Our Customer Support?',
    BUTTONS: ['No', 'Yes'],
  },
  SIM_REQUIRED:
    'For your information, the SIM card will be delivered together with your replacement device and it will be activated within 1 hour of delivery.',
  THANKS_FOR_ADDRESS: "Thanks for confirming delivery address! Let's proceed to the next step.",
  RETURN_DEVICE:
    'Please note that once we have completed this service request, the IMEI will be registered as lost with the Thailand Police Force. Therefore, should the original device comes back into your possession, please return it to AIS Mobile Care immediately.',
  CONFIRM_SR_BELOW: `Now, please review your service request before it's finalised for processing.`,
  CASH_ON_DELIVERY_SCRIPT: `Please prepare exact amount of cash to collect the device from our courier service`,
  THANKS_FOR_CONTACTING: 'Thanks for contacting AIS Mobile Care Support!',
  CHECK_COSMETIC_DAMAGES:
    'Please be reminded that your original device becomes the property of AIS at the time this service request is completed. <br><br>Before accepting the delivered device,please check for any possible cosmetic damages as the 180 days warranty will not cover cosmetic damages.',
  REPLACEMENT_CHECK_COSMETIC_DAMAGES:
    'Before accepting the delivered device,please check for any possible cosmetic damages as the 180 days warranty will not cover cosmetic damages.',
  CONFIRM_DETAILS_SCRIPT:
    'Please check the details in this confirmation form and click the following to confirm the order',
  RETURN_LOST_DEVICE_IF_FOUND:
    'Please note that that once we have completed this service request, the IMEI will be registered as lost with the Thailand Police Force. Therefore, should the original device subsequently come back into your possession, please return it to AIS Mobile Care immediately.',
  SUBMIT_IMEI_NUMBER: {
    PRIMARY_HEADER: "Please enter LAST 6 digits of your device's IMEI code",
    SECONDARY_HEADER: 'To find your IMEI code',
    CONTENT:
      'Look at the back of your device, or dial the universal code <strong>*#06#</strong> or check at the sim tray <br> <br> The IMEI is typically a 15 digit number starting with the number 3.',
    PLACEHOLDER: 'Enter last 6 digits of your IMEI',
    BUTTON: 'Submit IMEI',
    INVALID_IMEI: 'Please enter last 6 digits of IMEI1',
    APPLE_NOTE: 'Note: Please refer to IMEI 1',
  },
  ALL_DIGIT_IMEI_PLACEHOLDER: "Please enter all digits of your device's IMEI",
  ERROR_INCORRECT_IMEI: 'Incorrect IMEI',
  CASE_NO: 'SR No.',
  CAPTURE_IW_REMARKS: {
    LABEL: 'Remarks',
    PLACEHOLDER: 'Please state in brief what is wrong with your device',
    BUTTON: 'Submit',
  },
  CONFIRM_DETAILS: {
    HEADER: 'Confirm your service request details',
    SR_NO: 'CASE NUMBER',
    TYPE: 'TYPE',
    DEVICE: 'DEVICE',
    SHIPPING_TO: 'SHIPPING TO',
    DELIVERY_SCHEDULE: 'DELIVERY SCHEDULE',
    PAID_WITH: 'PAID WITH',
    FEE: 'FEE',
    PIN_CODE: 'POSTAL CODE',
    BUTTON: 'Confirm',
    CASH_ON_DELIVERY: 'Cash On Delivery',
  },
  SR_CONFIRMATION: 'Thanks for your confirmation! Let me proceed to submit your service request',
  ASK_FOR_FEEDBACK: {
    HEADER: 'Your feedback is important to us',
    CONTENT:
      'We at AIS Mobile Care will be honored if you can take a moment of your time to rate our service.',
    BUTTON: 'Rate our Service',
  },
  RESUME_SERVICE_REQUEST: {
    HEADER:
      'Currently, there is an incomplete request {Claim ID}, would you like to proceed with that request?',
    SR_NO: 'CASE NUMBER',
    TYPE: 'TYPE',
    DEVICE: 'DEVICE',
    SHIPPING_TO: 'SHIPPING TO',
    DELIVERY_SCHEDULE: 'DELIVERY SCHEDULE',
    PAID_WITH: 'PAID WITH',
    FEE: 'FEE',
    PIN_CODE: 'POSTAL CODE',
    BUTTONS: ['New SR', 'Proceed'],
  },
  DEVICE_LIST: {
    HEADER:
      'Currently, there is an incomplete request {Claim ID}, would you like to proceed with that request?',
    SR_NO: 'CASE NUMBER',
    TYPE: 'TYPE',
    DEVICE: 'DEVICE',
    SHIPPING_TO: 'SHIPPING TO',
    DELIVERY_SCHEDULE: 'DELIVERY SCHEDULE',
    PAID_WITH: 'PAID WITH',
    FEE: 'FEE',
    PIN_CODE: 'POSTAL CODE',
    BUTTONS: ['New SR', 'Proceed'],
  },
  NEW_SERVICE_REQ: 'New SR',
  PROCEED_SERVICE_REQUEST: 'Proceed',
  CHOOSE_TO_CONTINUE_BOT: {
    HEADER: 'Choose An Option Below',
    BUTTONS: ['Have Query', 'Resume Flow'],
  },
  SURVEY_CONFIRMATION: {
    CONTENT1:
      'Finally,I would like to invite you to participate in a short satisfaction survey at the end of this session to rate our online support service to you today.',
    CONTENT2: 'Would you like to take part in 1 minute survey?',
    BUTTONS: ['No', 'Yes'],
  },
  SURVEY_QUESTION_RATING: {
    BUTTON: 'Next',
  },
  SURVEY_QUESTION_COMMENTS: {
    BUTTON: 'Next',
  },
  SURVEY_QUESTION_EXPERIENCE: {
    BUTTON: 'Submit',
  },
  ADDRESS_LABELS: {
    ADDRESS1: 'Address Line 1:',
    ADDRESS2: 'Address Line 2:',
    ADDRESS3: 'Address Line 3:',
    POSTALCODE: 'PostalCode:',
    CITY: 'District:',
    STATE: 'Province:',
  },
  WEB_CHANGE_DELIVERY_ADDRESS: {
    HEADER: 'Please provide your preferred delivery address.',
    HEADER1: 'Please provide your delivery address in this form.',
    WBKO_HEADER: 'Please provide your preferred delivery address.',
    LABELS: {
      ADDRESS1: 'ADDRESS1',
      ADDRESS2: 'ADDRESS2',
      ADDRESS3: 'ADDRESS3',
      POSTALCODE: 'POSTALCODE',
      CITY: 'DISTRICT',
      STATE: 'PROVINCE',
    },
    PLACEHOLDERS: {
      FLOOR: '#Floor No-Unit No',
      BLOCK_NO: '#Block No',
      STREET_NAME: 'Street Name',
      ZIP_CODE: 'Postal Code',
      CITY: 'District',
      STATE: 'Province',
      STATE_PLACEHOLDER: 'Select Province',
      CITY_PLACEHOLDER: 'Select District',
    },
    WBKOPLACEHOLDERS: {
      FLOOR: 'Company Name, Condo, Village, Block #, Floor #',
      BLOCK_NO: 'Moo, Road, Street, Soi',
      STREET_NAME: 'AKhet/Amphoe   ',
      ZIP_CODE: 'Postal Code',
      CITY: ' Khwaeng/Tambon',
      STATE: 'Province',
      STATE_PLACEHOLDER: 'Select Province',
      CITY_PLACEHOLDER: 'Select District',
    },
    BUTTONS: ['Clear', 'Submit', 'Cancel'],
    WBKO_BUTTONS: ['Clear', 'Submit', 'Cancel'],
    DEFAULT_CITY: 'Thailand',
    ERROR_MSG: 'All fields on the form are mandatory.',
  },
  DEVICE_CHANGE_DELIVERY_ADDRESS: {
    HEADER: 'Please provide your preferred delivery address.',
    PLACEHOLDERS: {
      FLOOR: '#Floor No-Unit No',
      BLOCK_NO: 'Block No',
      STREET_NAME: 'Street Name',
      ZIP_CODE: 'Postal Code',
    },
    DEFAULT_CITY: 'Bangkok',
    BUTTONS: ['Submit New Address'],
  },
  PLEASE_SELECT_ALT_DEVICE: 'Please select one of the following alternative devices',
  CHECK_ALTERNATIVE_DEVICES: {
    HEADER: 'WE NEED YOUR INPUT',
    CONTENT1:
      'Would you like to <strong>wait for up to 5-7 days</strong> to get same device or <strong>check alternative device</strong> with similar specification?',
    CONTENT2: 'Would you like to <strong>wait for 5-7 days.',
    BUTTONS: ['Check alternative devices', 'Wait for my device to be restocked'],
  },
  FEEDBACK: {
    QUESTIONS: [
      'Based on your experience today, how likely are you to recommend AIS Mobile Care to a friend or colleague?',
      'Please share with us the most important reason for the rating you have provided?',
      'How would you rate your overall experience with AIS Mobile Care?',
    ],
    NPS_MAX: 10,
    NPS_MIN: 1,
    NPS_STEP: 1,
    NPS_DEFAULT_SELECTED: 10,
    BUTTONS: ['Finish'],
    NPS_LABEL: {
      LIKELY: 'MODERATE LIKELY',
      NOT_LIKELYNOT_LIKELY: 'NOT SATISFIED',
      EXTREMELY_LIKELY: 'EXTREMELY LIKELY',
    },
    CSAT: {
      POOR: { TEXT: 'Poor', VALUE: 1 },
      SAD: { TEXT: 'Sad', VALUE: 2 },
      NEUTRAL: { TEXT: 'Neutral', VALUE: 3 },
      HAPPY: { TEXT: 'Happy', VALUE: 4 },
      EXCELLENT: { TEXT: 'Excellent', VALUE: 5 },
    },
    EXTREMELY_LIKELY_VALUE: 8,
    LIKELY_VALUE: 7,
  },
  INSTRUCTION_PROVIDE_MOB_NO:
    'Please provide your mobile number to notify the status of your request by SMS after submission',
  SUBMIT_CONTACT_NUMBER: {
    HEADER: 'Preferred Contact Number',
    PLACEHOLDER: 'Type your contact number',
    BUTTONS: ['Submit'],
  },
  INSTRUCTION_PROVIDE_EMAIL:
    'Please provide your Email ID required to notify the status of your request after submission of your request',
  SUBMIT_EMAIL: {
    HEADER: 'Preferred Email ID',
    PLACEHOLDER: 'Type your email id',
    BUTTONS: ['Submit'],
  },
  WEB_GRID_VIEW_OPTIONS: {
    title: 'Choose an option below',
    PLACEHOLDERS: {
      TRACK: 'Track my Delivery',
      RESCHEDULE: 'Reschedule my Delivery',
      ADDRESS_CHANGE: 'Change my Delivery Address',
      ENQUIRY: 'Cancel my delivery',
      UPDATE_CONTACT_DETAILS: 'Update Contact Details',
    },
  },
  WBKO_WEB_GRID_VIEW_OPTIONS: {
    title: 'Choose an option below',
    PLACEHOLDERS: {
      TRACK: 'Track my Delivery',
      RESCHEDULE: 'Reschedule my Delivery',
      ADDRESS_CHANGE: 'Change my Delivery Address',
      ENQUIRY: 'Cancel my delivery',
      UPDATE_CONTACT_DETAILS: 'Update Contact Details',
    },
  },
  WEB_TRACK_MY_DELIVERY: {
    HEADER: 'Track My Delivery',
    PLACEHOLDERS: {
      CONFIRMED: 'Service Request Confirmed',
      PROCESSING: 'Processing Order',
      QUALITY_CHECK: 'Quality Check',
      DISPATCHED: 'Dispatched Item',
      DELIVERED: 'Product Delivered',
    },
  },
  ASK_FOR_ENQUIRY: {
    HEADER: 'Do you have any other enquiry?',
    BUTTONS: ['No', 'Yes'],
  },
  SERVICE_UNAVAILABLE: {
    PRIMARY_HEADER: '503 Service Unavailable',
    CONTENT: 'Sorry for inconvience!',
    CONTENT1: 'We were unable to process your request due to a technical issue.',
    CONTENT2: 'Please try after sometime or',
    SECONDARY_HEADER: 'For more information about AIS Mobile Care service, you may visit',
    CONTINUED_HEADER: '',
    CONTENT3: 'Thanks for contacting AIS Mobile Care Support!',
  },
  SHOW_PAYMENTFEE_WITH_DISCOUNT: {
    HEADER: 'Please use your own credit card to make this payment',
    CONTENT: 'Service Request Fee <strong>THB {TotalAmount}<strong>',
    BUTTON: 'Proceed',
  },
  AGREE_WITH_TERMS: 'Important!',
  DOWNLOAD_FILE: 'Please download a file, sign and upload it. ',
  INVALID_CC_DETAILS:
    'We regret to inform you that the provided Credit Card details are not validated successfully. <br><br> So please re-enter credit card details correctly.',
  INVALID_CC_DETAILS_SECOND_ATTEMPT:
    'Again, we regret to inform you that the provided Credit Card details are not validated successfully. <br><br> Please make sure to enter correct credit card number, expiry date and CVV again.',
  EXCEEDED_CC_VALIDTION_ATTEMPTS:
    'You have exceeded your attempts for credit card validation, please try again after some time!',
  INVALID_ADDRESS_ERROR:
    'We regret to inform that the provided address is invalid based on our address verification. <br><br> So please make sure to provide the correct *#Floor-Unit* No, *Block No*, *Street Name* and *Postal Code* again.',
  INVALID_ADDRESS_ERROR_SECOND_ATTEMPT:
    'Again, We regret to inform that the provided address is invalid based on our address verification. <br><br> So please make sure to provide the correct *#Floor-Unit* No, *Block No*, *Street Name* and *Postal Code* again.',
  STAGE_NAME: {
    ENQUIRY: {
      request: {
        label: 'Request', // 5%
        percentage: 1,
      },
      peril: {
        label: 'Request', // 5%
        percentage: 3,
      },
    },
    VERIFICATION: {
      mdn: {
        label: 'Verification', // 10%
        percentage: 5,
      },
      name: {
        label: 'Verification', // 10%
        percentage: 8,
      },
      nric: {
        label: 'Verification', // 10%
        percentage: 10,
      },
    },
    DETERMINE_INCIDENT: {
      advance_peril: {
        label: 'Incident Determination', // 30%
        percentage: 10,
      },
    },
    OFFER: {
      confirm_enrolled_device: {
        label: 'Confirm enrolled device', // 30%
        percentage: 15,
      },
      eligibility: {
        label: 'Eligibility and Offer', // 30%
        percentage: 30,
      },
    },
    FULLFILLMENT: {
      label: 'Fulfilment', // 50%
      percentage: 50,
    },
    PAYMENT: {
      label: 'Payment', // 70%
      percentage: 70,
    },
    SHIPPING: {
      label: 'Shipping', // 90%
      percentage: 90,
    },
    ORDER_REVIEW: {
      label: 'Order Review', // 98%
      percentage: 98,
    },
    JOURNEY_ENDED: {
      label: 'Journey Completed', // 100%
      percentage: 100,
    },
    GENERAL_ENQUIRY: {
      label: 'General Enquiry', // 100%
      percentage: 50,
    },
  },
  WEB_HEADERS: {
    WELCOME_TO_MOBILESWOP: 'AIS Mobile Care Portal',
    FEEDBACK: 'Rate Our Services',
  },
  SUB_STAGES: {
    WELCOME: 'Welcome to AIS Mobile Care',
    MDN_PROVIDED: 'MDN Provided',
    NRIC_PROVIDED: 'ID Provided',
    CONFIRM_ENROLLED_DEVICE: 'Confirm enrolled device',
    DEVICE_CONFIRMED: 'Enrolled Device confirmed',
    SERVICE_REQUEST_FEE_DISPLAYED: 'Service request fee displayed',
    CHECK_DEVICE_STOCK: 'Device in stock verified',
    DEVICE_NOT_IN_STOCK: 'Selected device not in stock',
    DISPLAY_ALTERNATE_DEVICES: 'Display alternate devices',
    VERIFY_ENROLLED_NUMBER_FOR_SMS: 'Verify enrolled number for SMS',
    MOBILE_NO_VERIFIED: 'Mobile Number verified for sms',
    VERIFY_ENROLLED_EMAIL: 'Verify enrolled email for notifications',
    EMAIL_VERIFIED: 'Email Verified',
    DOCUMENT_DOWNLOAD: 'Download confirmation form',
    DOCUMENT_UPLOAD: 'Document uploaded successfully',
    EMAIL_SUBMITTED: 'New Email Submitted',
    SIM_DELIVERY_CONFIRMATION: 'Sim delivery confirmation',
    SIM_DELIVERY_NEEDED: 'Sim delivery needed',
    SIM_DELIVERY_NOT_NEEDED: 'Sim delivery not needed',
    ENROLLED_ADDRESS_CONFIRMATION: 'Enrolled address confirmation',
    ENROLLED_ADDRESS_CONFIRMED: 'Enrolled address confirmed',
    PROVIDE_NEW_ADDRESS: 'Provide new address',
    CONFIRM_DELIVERY_SCHEDULE: 'Delivery schedule confirmation',
    DELIVERY_SCHEDULE_CONFIRMED: 'Delivery schedule confirmed',
    NEW_DELIVERY_SCHEDULE: 'Provide new delivery schedule',
    NEW_SCHEDULE_SUBMITTED: 'New Delivery schedule submitted',
    PAYMENT_FEE_DISPLAYED: 'Payment fee displayed',
    PAYMENT_MODE: 'Payment Mode',
    CASH_ON_DEL: 'Selected cash on delivery',
    CREDIT_CARD_SEL: 'Selected credit card',
    ENTER_CC_DETAILS: 'Enter credit card details',
    ORDER_CONFIRMATION: 'Order confirmation',
    ORDER_CONFIRMED: 'Order confirmed',
    SURVEY: 'Help us with quick survey',
    NEW_CONTACT: 'Enter new contact number',
    NEW_EMAIL: 'Enter new email address',
    VALIDATE_IMEI: 'Provide IMEI for validation',
    FMIP: "Turn Off 'Find My Phone' feature",
    TRIAGE_QUESTION: 'Answer the triage question(s)',
    TRY_TURN_OFF: 'Try turn off and on your phone',
    UPDATE_OS: 'Try Update OS',
    RESET_FACTORY_DEFAULT: 'Reset factory default',
    TRIAGE_RESOLVED: 'Triage resolved',
    TURN_OFF_DIDNT_WORK: "Turning Off device didn't resolved the issue",
    UPDATE_OS_DIDNT_WORK: 'Updating OS didnt resolved the issue',
    RESET_DIDNT_WORK: 'Reseting the phone didnt resolved the issue',
    VERIFIED_IMEI: 'IMEI verified',
    DEVICE_STILL_IN_WARRANTY: 'Device is still in warranty',
    WARRANTY_VOID: 'Manufacturer warranty selected',
  },
  TRY_LATER: 'Our support staff is not available. Please try after some time.',
  EPISODIC_FEEDBACK: {
    HEADER_TEXT: 'Rate Our Service',
    QUESTIONS: [
      {
        QID: 'Q1',
        QUESTION_TXT:
          '1. Based on your experience today, how likely are you to recommend AIS Mobile Care to a friend or colleague?',
      },
      {
        QID: 'Q2',
        QUESTION_TXT:
          '2. Please rate your satisfaction with the length of time it took to receive your device replacement?',
      },
      {
        QID: 'Q3',
        QUESTION_TXT:
          '3. Please rate your satisfaction with the quality of the device replacement.',
      },
      {
        QID: 'Q4',
        QUESTION_TXT: '4. Please rate your satisfaction with the Courier service.',
      },
      {
        QID: 'Q5',
        QUESTION_TXT: '5. Overall, how easy was it to get your replacement device?',
      },
      {
        QID: 'Q6',
        QUESTION_TXT: '6. Do you have any other comments you would like to share?',
      },
    ],
    NPS_MAX: 10,
    NPS_MIN: 1,
    NPS_STEP: 1,
    NPS_DEFAULT_SELECTED: 8,
    BUTTONS: ['Finish'],
    NPS_LABEL: {
      LIKELY: 'LIKELY',
      NOT_LIKELY: 'NOT AT ALL LIKELY',
      EXTREMELY_LIKELY: 'EXTREMELY LIKELY',
    },
    CSAT: {
      POOR: 'Poor',
      SAD: 'Sad',
      NEUTRAL: 'Neutral',
      HAPPY: 'Happy',
      EXCELLENT: 'Excellent',
    },
    EXTREMELY_LIKELY_VALUE: 8,
  },
  NOT_RESOLVED: {
    MDN_NAME_NRIC:
      'We regret to inform that your request could not be processed further now. Our customer support will contact you directly once your enrollment issue is resolved.\n\n Thanks for contacting AIS Mobile Care Support ',
    GENERIC:
      'We regret to inform you that we are unable to proceed with the service request now.\n\n Thanks for contacting AIS Mobile Care Support!',
  },
  // USER_DISCONNECTED: "We need to end this chat due to inactivity. Thank you for connecting with us.",
  USER_DISCONNECTED: {
    HEADER: 'No Response',
    CONTENT:
      'The chat session has been closed due to inactivity of 10 minutes \n\n Thanks for contacting AIS Mobile Care Support!',
  },
  // AGENT_DISCONNECTED: "We need to end this chat due to some technical issues. Thank you for connecting with us.",
  AGENT_DISCONNECTED: {
    PRIMARY_HEADER: 'Technical Issue',
    SECONDARY_HEADER:
      'We regret to inform you that your chat session has been disconnected due to some technical issues.',
    PRIMARY_CONTENT:
      "Please feel free to contact our Customer Support n this hotline number <strong><a class='support-hotline' href='tel://0980981175'>0980981175</a></strong> during 8am-10pm Bangkok Time..",
    SECONDARY_CONTENT: 'Thanks for contacting AIS Mobile Care Support!',
    BUTTONS: ['Cancel', 'Continue'],
  },
  CURRENT_REQUEST_DETAILS: {
    HEADER: 'Current delivery request details',
    SR_NO: 'CASE NUMBER',
    TYPE: 'TYPE',
    DEVICE: 'SWAP DEVICE',
    SHIPPING_TO: 'SHIPPING TO',
    DELIVERY_SCHEDULE: 'DELIVERY SCHEDULE',
    PAID_WITH: 'PAID WITH',
    FEE: 'FEE',
    PIN_CODE: 'POSTAL CODE ',
    BUTTONS: [],
  },
  CANCEL_REQUEST: {
    HEADER: 'Exit the current request',
    CONTENT: 'Do you want to exit the current request?',
    BUTTONS: ['No', 'Yes'],
  },
  CANCEL_CURRENT_SR: {
    HEADER: 'Cancel current request',
    CONTENT: 'Do you want to cancel current service request?',
    BUTTONS: ['No', 'Yes'],
  },
  NO_DEVICE_IN_STOCK: 'No Device available in stock.',
  UPLOAD_YOUR_SUPPORTING_DOCS: {
    HEADER: 'Upload Your Supporting Documents',
    SELECT_FROM_PHONE: 'Select file from your phone',
    SELECT_FROM_COMPUTER: 'Select file from your computer',
    BUTTONS: ['Upload File'],
  },
  UPLOAD_DOCUMENTS: {
    HEADER: 'Please upload the signed Confirmation Form here.',
    CONTENT: "Drag and drop your file here or <a href=''> browse </a>file",
    ATTACHED_CONTENT: 'Drag and drop your file here or browse file',
    DISCLAIMER: 'Note: Upload a maximum of 3 files with size 1 MB each',
    ALLOWED_EXT: 'Supported file format: .pdf,.jpg,.jpeg,.png',
    BUTTONS: ['Cancel', 'Attach'],
  },

  MAX_SIZE_ALERT: {
    ALERT_HEADER: 'Alert',
    ALERT_MESSAGE: "We support only '.pdf,.jpg,.jpeg,.png' file format with maximum size of 1 MB.",
    BUTTON: 'Ok',
  },
  DOWNLOAD_ALERT: 'Would you like to download confirmation form?',
  NO_ALTERNATE_DEVICE_IN_STOCK: 'No alternate device available in stock.',
  DOWNLOAD_CONFIRMATION_FORM: {
    HEADER: 'How To Download Confirmation Form',
    INSTRUCTION_1:
      '<strong>Download and print out the declaration form by click below Download button</strong>.',
    INSTRUCTION_2:
      'Find out your <strong>name, occupation, identity card no. and address</strong> in the provided spces in the form.',
    INSTRUCTION_3:
      'Fill out the <strong>make and model</strong> (Example: Phone Make and Model, Colour, Capacity), <strong>IMEI, and mobile number associated with the Eligible Device</strong> in the provided  spaces in the form.',
    INSTRUCTION_4:
      '<strong>Sign the declaration</strong> and please note that <strong>digital signature will not be accepted</strong>.',
    INSTRUCTION_5:
      '<strong>Upload the scanned signed declaration form in PDF,PNG,JPG,JPEG format only</strong>.',
    BUTTONS: ['Skip', 'Download'],
    CAVEAT: '*Click on <strong>Skip</strong> if you already download the confirmation form.',
  },
  CONVERSATION_END: {
    THANK_YOU: 'Thank you for using AIS Mobile Care',
    CLOSE: 'X Close window',
  },
  CANCEL_ENQUIRY: {
    HEADER: 'Would you like to cancel your current delivery request?',
    BUTTONS: ['Yes', 'Submit'],
  },
  COD_FOR_CREDIT_CARD: {
    CONTENT:
      'Again, we regret to inform you that the provided Credit Card details are not validated successfully. Would you like to proceed with Cash on delivery option?',
    BUTTONS: ['Cancel', 'Proceed'],
  },
  ALTERNATE_DEVICE_NOTE: {
    CONTENT:
      'Please note that once you have accepted the comparable model offered, you will not be able to request for the enrolled model for future service request',
    BUTTONS: ['Cancel', 'Proceed'],
  },
  CLOSE_SR: {
    CONTENT:
      "Please note that your request has been submitted to our internal review process.Please note that service fee wouldn't be charged to your credit card until your request is fully approved.So, You will be notified by SMS and Email once it is fully approved.",
    BUTTONS: ['Close'],
  },
  NO_ALTERNATIVE_DEV_INSTOCK: {
    CONTENT: 'No alternate device available in stock',
    BUTTONS: ['Close'],
  },
  DEVICE_NOT_ELIGIBLE: {
    CONTENT:
      'If you are just enrolled the device, it takes 24 hrs to reflect or if customer just paid the bill, it takes 24 hrs to reflect and/or be able to submit the SR.',
    BUTTONS: ['Close'],
  },
  MDN_NRIC_CLOSE: {
    CONTENT:
      'We regret to inform that your request could not be processed further now. Our customer support will contact you directly once your enrollment issue is resolved.\n\n Thanks for contacting AIS Mobile Care Support',
    BUTTONS: ['Close'],
  },
  GENERIC_NOT_RESOLVED: {
    CONTENT:
      'We regret to inform you that we are unable to proceed with the service request now.\n\n Thanks for contacting AIS Mobile Care Support!',
    BUTTONS: ['Close'],
  },
  DOWNLOAD_SCRIPT:
    'Based on the details you’ve provided, you will be required to complete a service request confirmation form before we can proceed with your request.<br> <br> For your awareness of the terms and conditions - once your confirmation form is received and we finalize this Service Request, your device that is not available for swap will become the property of AIS. As AIS becomes the owner of that device, you are not permitted to use, sell, give away or enter into any dealing or transaction in relation to that device from the time that the Service Request is finalized. Please contact us back to return the original device if the device has been found. <br> <br> By uploading the confirmation form, you are accepting that you are aware and agreeable to this.',
  CLOSE_JOURNEY_AFTER_ADDRESS_FAILURE:
    "We regret to inform that your request could not be processed further now due to invalid given address.<br><br>Please feel free to contact our Customer Support on this hotline number<strong><a class='support-hotline' href='tel://0980981175'>0980981175</a></strong> during 8am-10pm Bangkok Time.<br><br>Thanks for contacting AIS Mobile Care Support!",
  RESCHEDULE_DELIVERY_ENQUIRY:
    'Your delivery schedule change request has been submitted for internal approval, you will be contacted once it is approved.',
  RESCHEDULE_DELIVERY_ADDRESS_ENQUIRY:
    'Your delivery address change request has been submitted for internal approval, you will be contacted once it is approved.',
  CANCEL_MY_DELIVERY_ENQUIRY:
    'Your delivery cancel request has been submitted for internal approval, you will be contacted once it is approved.',
  CANCEL_MY_DELIVERY_ENQUIRY_THANKS: 'Thanks for contacting AIS Mobile Care',
  CHANGE_DELIVERY_ADDRESS_ENQUIRY:
    'Customer has requested the delivery address change. New Address:',
  CREATE_GENERAL_ENQUIRY:
    'Customer has requested a general enquiry on {DateTimeOfRequest}, preferred callback Number is: {MobileNumber}',
  GENERAL_ENQUIRY_SUBMITTED:
    'Your enquiry request has been submitted so you will be contacted soon. Thanks for contacting AIS Mobile Care Support!',
  CHANGE_DELIVERY_SCHEDULE_ENQUIRY:
    'Customer has requested the delivery schedule change. New Schedule:',
  CANCEL_DELIVERY_ENQUIRY: 'Customer has requested for delivery cancellation.',
  TRACK_DELIVERY: 'Track my Delivery',
  RESCHEDULE_MY_DELIVERY: 'Reschedule my Delivery',
  CHANGE_MY_DELIVERY_ADDRESS: 'Change my Delivery Address',
  CANCEL_MY_DELIVERY: 'Cancel my delivery',
  FILES_UPLOADED_SUCCESSFULLY:
    'Thank you for submitting your documents.  We are looking into it and we will get back to you via email and/or sms as soon as possible.',
  INCORRECT_POSTAL_CODE: 'Incorrect Postal Code',
  INSERT_VALID_NAME: 'Please insert valid name',
  INSERT_VALID_NUMBER: 'Please insert valid number',
  INSERT_EXPIRY_FORMAT: 'Please insert expiry in valid format',
  INSERT_VALID_CVC: 'CVC is Invalid',
  MANDATE_EXPIRY_DATE: 'Expiry Date is mandatory',
  MANDATE_CVC: 'cvc is mandatory',
  MANDATE_CREDIT_CARD: 'Credit Card Number is mandatory',
  EXPIRED_DATE: 'Provided date is already expired',
  // CENTRALIZED JOURNEY CONST
  WBKO_KEEP: 'Keep',
  WBKO_CHANGE: 'Change',
  YES_TEXT: 'Yes',
  NO_TEXT: 'No',
  CLOSE_TEXT: 'Close',
  CHAT_WITH_US: 'Chat with us',
  SERVICE_UNAVAILABLE_TEXT:
    'Sorry for inconvience! <br />We were unable to process your request due <br />to a technical issue.<br /><br />Thanks for contacting AIS Mobile Care!',
  YES_PROCEED: 'Yes, Proceed',
  SELECT_TEXT: 'Select',
  RETRY_BUTTON: 'Retry',
  COD_TEXT: 'Cash On Delivery',
  CREDIT_CARD_TEXT: 'Credit Card',
  CANCEL_SR: 'Cancel Request',
  BACK_TO_PREV_STEP: 'Back to previous step',
  BACK_TO_MAIN_MENU: 'Back to Main Menu',
  PAYMENT_CANCELLED_PROCEED: 'Payment cancelled, proceed again',
  COMMENT_HERE_PLACEHOLDER: 'Your comments here...',
  SELECT_ENQUIRY_TYPE: 'Select enquiry type',
  SELECT_ENQUIRY_SUBTYPE: 'Select enquiry sub type',
  CREATE_NEW_SR: 'Create New Request',
  CREATE_NEW_SR_END: 'Create New Request',
  REASON_FOR_CANCEL: 'Reason for cancellation...',
  ENTERED_ID: 'Entered National ID/Passport Number:',
  ENTERED_NAME: 'Entered Name:',
  ENTERED_MDN: 'Entered MDN:',
  CHANGE_EMAIL_ID: 'Change Email Id',
  CHANGE_MOBILE_NO: 'Change Mobile Number',
  ERROR_MSG_INVALID_CONTACT_NO: 'Please enter valid contact number.',
  ERROR_MSG_INVALID_EMAIL_ID: 'Please enter valid email id.',
  ENQUIRY_DETAILS_SAVED: 'Enquiry details saved.',
  ENQUIRY_SUBMITTED: 'Enquiry submitted.',
  PAYMENT_SUBMITTED: 'Payment submitted',
  SERVICE_FEE: 'Service Fee:',
  REASON_FOR_CHANGE: 'Reason for change...',
  EDIT_DELIVERY_SCHEDULE: 'Change delivery schedule',
  EDIT_DELIVERY_ADDRESS: 'Change delivery address',
  PAYMENT_NEW: 'Change payment mode',
  CANCELLED: 'Cancelled',
  ENTER_YOUR_MESSAGE: 'Enter your message here',
  AGREE_TERMS_CONDITION: 'DO YOU AGREE WITH OUR TERMS?',
  OEM_WARRENTY_SELECTED: 'OEM warranty selected',

  // progress bar
  CHECK_ADDRESS: 'Checking your delivery address',
  SHIPPING_DETAILS_VERIFY: 'Checking shipping details',
  CREATING_NEW_REQUEST: 'Creating new request',
  VERIFIED_DEVICE_IN_STOCK: 'Available stocks',
  VERIFYING_DEVICE_IN_STOCK: 'Checking availability of the device in stock',
  INVALID_NRIC: 'Your national ID/Passport Number is not verified',
  VERIFYING_IMEI: 'Checking your IMEI number',
  UPLOAD_CONFIRMATION_FORM: 'Uploading confirmation form',
  INVALID_IMEI: 'Your IMEI number is invalid',
  INVALID_MDN: 'Your mobile number is invalid',
  VERIFIED_MDN: 'Your mobile number is verified',
  VERIFIED_NAME: 'Your name is verified',
  INVALID_NAME: 'Your name is not verified',
  VERIFYING_PAYMENT: 'Your payment is being verified',
  VERIFIED_REQUEST: 'Your request is verified',
  VERIFIED_PAYMENT: 'Your payment is verified',
  VERIFIED_IMEI: 'Your IMEI number is verified',
  VERIFIED_NRIC: 'Your National Id/Passport Number is verified',
  VERIFYING_NRIC: 'Your National Id/Passport Number is being verified',
  VERIFYING_REQUEST: 'Checking the status of your request',
  VERIFYING_MDN: 'Checking your mobile number',
  VERIFYING_NAME: 'Your name is being verified',
  FEE_CALCULATION:
    'Fee Amount = Purchase Fee * (Fee %) <br>Tax Amount = FeeAmount * (tax %) <br> Total Amount = Fee Amount + Tax Amount',
  GET_FEE_DETAILS: 'Click here to get fee details',
  PURCHASE_FEE: 'Purchase price',
  FEE_CHARGES: 'Fee',
  TAX_CHARGES: 'Tax',
  TAX_AMMOUNT: 'Tax Amount',
  TOTAL_AMOUNT: 'Total Amount',
  FEE_AMOUNT: 'Fee Amount',

  WBKO_VERIFYING_MDN: 'Verifying your mobile number',
  WBKO_VERIFIED_MDN: 'Your mobile number has been successfully verified',
  WBKO_VERIFIED_NRIC: 'Your Identification details has been successfully verified',
  WBKO_VERIFYING_NRIC: 'Your Identification details is being verified ',
  CASE_GRATH_INITIATED: 'Please wait ',
  CASE_GRAPH_SUCCESS: 'Devices loaded successfully',
  VERIFY_AGREEMENT_SUCCESS: 'Service requests loaded successfully',

  SELECTED_DEVICE: 'Selected Device ',
  RESUME_EXISTING_REQUEST: 'Resume existing request',
  SELECT_BRAND: 'Select brand',
  SELECT_MODEL: 'Select Model',
  SELECT_DEVICE: 'Select Device',
  SELECT_ADDRESS: 'Select Address',
  GENERAL_ENQUIRY_OPTIONS: [
    'Package information',
    'Conditions to make service request',
    'Delivery Service',
    'Service Not Found',
    'In warranty Support',
    'SR Fee',
    'Other',
  ],
  GE_THANK_YOU_MESSAGE: 'Thank you for contacting AIS Mobile Care',
  NO_EXISTING_CUSTOMER:
    'Sorry, we are unable to validate your AIS Mobile Care subscription at this time.',
  NO_EXISTING_CUSTOMER_NOTE:
    'Please note that it will take up to 24 hrs for your AIS Mobile Care subscription to reflect in our system. If you have just enrolled today, please try again later!',
  CHECKING_SERVICE_REQUEST_FEE: 'CHECKING SERVICE REQUEST FEE',
  GET_SERVICE_FEE: {
    HEADER: 'Your Service Fee',
    CONTENT1:
      '<strong> Replacement </strong> service request fee will be <strong> THB {TotalAmount}</strong>.',
    CONTENT2:
      '<strong> Swap </strong> service request fee will be <strong> THB {TotalAmount}</strong>.',
    BUTTON: 'Continue',
  },
  TRACK_DEVICE_DELIVERY: 'Track my device delivery',
  BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED:
    'As your selected device {MakeModel} is currently not available. We will send you an SMS once the stock is available for delivery arrangement.',

  LANDING_PAGE: {
    WELCOME_HEADING: 'Welcome to AIS Mobile Care',
    WELCOME_SUBHEADING: 'Complete care in one package',
    HOW_WORKS_HEADING: 'How it works',
    HOW_WORKS_SUBHEADING:
      'When buying a new mobile phone, you can change devices up to 2 times / 12 months. Just sign up for the AIS Mobile Care package.',
    HOW_WORKS: [
      // {
      //   HEADING: "Device Swap Or Replacement",
      //   SUBHEADING:
      //     "Enjoy 2 swaps or 1 replacement and 1 swap in every 12 rolling months.",
      //   icon: "SWAP"
      // },
      {
        HEADING: 'Speedy Free Delivery',
        SUBHEADING:
          'Enjoy free delivery right to your doorstep within 6 hours for Bangkok Metropolitan Area.',
        icon: 'GROUP',
      },
    ],
    HOW_WORKS_NEW: [
      {
        HEADING: 'Device Swap Or Replacement',
      },
      {
        HEADING: 'AIS Mobile Care Swap Unlimited',
        SUBHEADING: 'Enjoy unlimited swaps​',
        hasSeal: true,
        sealText: 'NEW',
      },
      {
        HEADING: 'AIS Mobile Care​',
        SUBHEADING: 'Enjoy 2 swaps or 1 replacement and 1 swap in every 12 rolling months ​',
      },
    ],
    FOOTER_LINKS: [
      {
        TEXT: 'What is AIS Mobile Care?',
        HREF: '/faq',
      },
      {
        TEXT: 'Privacy Policy',
        HREF: '/privacy-policy',
      },
      {
        TEXT: 'Terms and Conditions',
        HREF: '/terms-conditions',
      },
    ],
    FOOTER_LINKS_NEW: {
      leftCol: [
        {
          TEXT: 'What is AIS Mobile Care?',
          HREF: '/faq',
        },
        {
          TEXT: 'What is AIS Mobile Care Swap Unlimited?',
          HREF: '/faq-swap-unlimited',
        },
      ],
      rightCol: [
        {
          TEXT: 'Terms and Conditions - Mobile Care',
          HREF: '/terms-conditions',
        },
        {
          TEXT: 'Terms and Conditions - Mobile Care Swap Unlimited',
          HREF: '/terms-conditions-swap-unlimited',
        },
        {
          TEXT: 'Privacy Policy',
          HREF: '/privacy-policy',
        },
      ],
    },
  },
  REQUEST_OPTIONS: {
    title: 'Choose an option below.',
    buttons: [
      {
        name: CONSTANTS.CREATE_A_NEW_REQUEST,
        subText: 'Have an issue with your device?',
        text: 'Start a request',
        icon: 'START_REQ',
      },
      {
        name: CONSTANTS.CHECK_SERVICE_FEE,
        subText: 'How much do I need to pay',
        text: 'Check fee',
        icon: 'CHECK_FEE',
      },
      {
        name: CONSTANTS.WBKO,
        subText: 'Already filed a request?',
        text: 'Check status',
        icon: 'CHECK_STATUS',
      },
      {
        name: CONSTANTS.GET_HELP_MORE_INFO,
        subText: 'General and Subscription related Inquiries',
        text: 'Get help and more info',
        icon: 'GET_HELP',
      },
    ],
  },
  GET_MOREINFO: {
    title: 'Please select an option',
    buttons: [
      {
        text: 'General Enquiries',
        icon: 'GE',
        name: CONSTANTS.GENERAL_ENQUIRY,
      },
      {
        text: 'View my subscription details',
        icon: 'SUBSCRIPTION',
        name: 'SUBSCRIPTION',
      },
      {
        text: 'I am thinking of leaving AIS Mobile Care',
        icon: 'LEAVE_AIS',
        name: CONSTANTS.LEAVE_AIS,
      },
    ],
  },
  GET_STARREQ: {
    title: 'Please select an option',
    buttons: [
      { name: CONSTANTS.CREATE_A_NEW_REQUEST, text: 'Create a new Request' },
      {
        name: CONSTANTS.RESUME_EXISTING_REQUEST,
        text: 'Resume Existing Request',
      },
    ],
  },
  SUBSCRIPTION_OPTION: {
    SwipeToSeeAllDevices: 'Swipe to see all devices',
    Sub1: 'Registered Name',
    Sub2: 'Subscription Fee',
    Sub3: 'Service Fee (VAT inc.)',
    Sub4: 'Agreement Start Date & Status',
    Sub5: 'IMEI Number (Last 3 digits)',
    SrNo: 'SR No.',
    SrType: 'Service Request Type',
    Status: 'Status',
    SectionTitle: 'Hi, Please confirm your device to proceed further',
    EnroledDevice: 'Your enrolled devices',
    ServiceRequests: 'Your Service Request',
    CantFindDevice: 'Can’t find your enrolled device?',
    ClickToUpdate: 'Click here to update',
    TITLE: 'Subscription Details',
    EnrolledDevice: 'Enrolled Device',
    Proceed: 'Proceed',
    MobileDeviceNumber: 'Registered Mobile Number',
    ProgramName: 'Program Name',
    AgreementStartDate: 'Agreement Start Date & Status',
    Subscription: 'Subscription Fee (VAT inc.)',
    SWAP: 'Swap Fee (VAT inc.)',
    REPLACE: 'Replacement Fee (VAT inc.)',
  },
  CONFIRM_EXISTING_CUST: 'Confirm Existing Customer',
  CHANGE_DEVICE_SELECTION: 'Change device selection',
  AGENT_CHAT_THANKYOU_MSG: {
    HEADING: 'Thanks for contacting AIS MobileCare, we will be in touch soon.',
    SUB_HEADING: 'Our customer support will contact you directly to assist you at the earliest',
  },
  USER_ONLINE: 'User Online',
  USER_OFFLINE: 'User Offline',
  UPDATE_ENROLLED_DEVICE: 'Update enrolled device',
  UPDATE_ENROLLMENT: {
    title: 'Could you please confirm if you have performed any of the following device exchanges?',
    buttons: [
      {
        name: CONSTANTS.AIS_OEM_EXCHANGE,
        text: 'OEM Exchange (Authorised Service Center)',
        value: 'OEM Exchange (Authorised Service Center)',
      },
      { name: CONSTANTS.AIS_STOP_EXCHANGE, text: 'Shop Exchange', value: 'Shop Exchange' },
      {
        name: CONSTANTS.AIS_NO_EXCHANGE,
        text: 'No I have not exchanged my device',
        value: 'No, I have not exchanged my device',
      },
    ],
  },
  UPDATE_ENROLLED_FORM: {
    title:
      'Please provide the following information along with the proof of device exchange performed',
    content_header: 'Upload supporting documents',
    content_placeholder: 'Choose a file or drag it here',
    placeholder1: 'Enter 15 digits of IMEI Number',
    placeholder2: 'Preferred Contact Number',
    placeholder3: 'Select Device Make',
    placeholder4: 'Select Device Model',
    placeholder5: 'Enter Device Capacity',
    placeholder6: 'Enter Device Color',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    content_footer: [
      'Any Supporting Document indicating the IMEI',
      '(Eg: Service report, exchange slip, etc)',
      '5MB Max, file size',
    ],
  },
  SUBMIT_ENROLLED_ENQUIRY:
    'We have received your document. You will be notified through email and/or sms within 2 working days',
  PROCEED_SELECTED_DEVICE: '<strong>Enrolled Device</strong><br />{selectedDevice}',
  SELECTED_DEVICE: '<strong>Selected Device</strong><br />{selectedDevice}',
  MAX_FILE_SIZE_EXCEEDED: 'Max file size exceeded',
  INPUT_VALIDATION_CONTACT_NO: 'Please enter valid contact number',
  INPUT_VALIDATION_IMEI: 'Please enter 15 digit IMEI',
  SMART_STOCK: {
    WARNING:
      '<span style="color:#DF070E;">Please be informed that the device model you selected will be the registered device for future requests upon the completion of this service request.</span>',
  },
  DEVICE_STATUS_IN_STOCK: {
    header: "Great News!",
    subHeader: "We are happy to inform you that this device <strong>{DeviceName}</strong> is currently <strong>in stock/available now</strong>"
  },
  DEVICE_STATUS_OUT_STOCK: {
    header: "Oh no! Out of stock",
    subHeader: "We regret to inform you that this device <strong>{DeviceName}</strong> is currently <strong>out of stock</strong>.",
    subHeaderNoRpm: "Please select <strong>\"Wait 5-7 working days for device to be re-stocked\"</strong> and provide the necessary details in order for us to place your request under a priority backorder. Please remember to submit your order and we will contact you within 5-7 working days. <br/><br/>Alternatively, you may choose to cancel this request and go back to the main menu.",
    subHeaderWithRpm: "Please click on <strong>\"Select Alternative Device\"</strong> to choose a comparable device available in stock. <br/><br/>Alternatively, you may choose to cancel this request and go back to the main menu.",
    buttonSelectAlternate: "Select Alternative Device",
    buttonWait: "Wait 5-7 working days for device to be re-stocked",
    buttonCancel: "Cancel Service Request"
  },
  DEVICE_STATUS_NLP: {
    header: "Oh no! Discontinued",
    subHeader: "We regret to inform you that this device <strong>{DeviceName}</strong> has been <strong>discontinued</strong>. ",
    subHeaderNoRpm: "Please select <strong>\"Wait 5-7 working days for alternative device to be offered\"</strong> and provide the necessary details in order for us to place your request under a priority backorder. Please remember to submit your order and we will contact you within 5-7 working days. <br/><br/>Alternatively, you may choose to cancel this request and go back to the main menu.",
    subHeaderWithRpm: "Please click on <strong>\"Select Alternative Device\"</strong> to choose a comparable device available in stock. <br/><br/>Alternatively, you may choose to cancel this request and go back to the main menu.",
    buttonSelectAlternate: "Select Alternative Device",
    buttonWait: "Wait 5-7 working days for alternative device to be offered",
    buttonCancel: "Cancel Service Request"
  },
  DEVICE_STATUS_NCA: {
    header: "Oh no! Supply Shortage",
    subHeader: "We regret to inform you that this device <strong>{DeviceName}</strong> currently <strong>unavailable due to supply shortage</strong> and will not be restocked so soon. ",
    subHeaderNoRpm: "Please select <strong>\"Wait 5-7 working days for alternative device to be offered\"</strong> and provide the necessary details in order for us to place your request under a priority backorder. Please remember to submit your order and we will contact you within 5-7 working days. <br/><br/>Alternatively, you may choose to cancel this request and go back to the main menu.",
    subHeaderWithRpm: "Please click on <strong>\"Select Alternative Device\"</strong> to choose a comparable device available in stock. <br/><br/>Alternatively, you may choose to cancel this request and go back to the main menu.",
    buttonSelectAlternate: "Select Alternative Device",
    buttonWait: "Wait 5-7 working days for alternative device to be offered",
    buttonCancel: "Cancel Service Request"
  },
  WBKO_RESCHEDULE: {
    greetings: "Hi, {CustomerName}",
    OOSTitle: "We regret to inform you that your device is still out of stock.",
    LFLTitle: "We are pleased to inform you that your device {DeviceName} is now in stock, please confirm your service request details.",
    NLFLTitle: "We are pleased to offer you {DeviceName} in replacement of your original enrolled device as it is not in stock. Please refer to more details below.",
    NLFLNote: "Please note that once you have accepted the comparable model offered, you will not be able to request for the enrolled model in future Service Request",
    CancelReasonsTitle: "Please share with us why you like to cancel this service request (You may select more than one reason)",
    CancelReasons: [
      {
        DisplayText: "Waited too long to get swap / replacement device",
        Value: "Waited too long to get swap / replacement device"
      },
      {
        DisplayText: "Concerned about swap / replacement device quality",
        Value: "Concerned about swap / replacement device quality"
      },
      {
        DisplayText: "Do not like the offered device model",
        Value: "Do not like the offered device model"
      },
      {
        DisplayText: "Decided to purchase new device",
        Value: "Decided to purchase new device"
      },
      {
        DisplayText: "Decided to repair device elsewhere",
        Value: "Decided to repair device elsewhere"
      },
      {
        DisplayText: "Fee is too high",
        Value: "Fee is too high"
      }
    ],
    CancelConfirm1: "Are you sure you would like to cancel this Service Request?",
    CancelConfirm2: "Please be informed that this action cannot be undone.",
    NewScheduleReminder1: "You must be personally present in person with your National ID in order to receive the delivery.",
    NewScheduleReminder2: "If you cannot be present on the date above, please change your available date and time.",
    RescheduleImportantNote1: "Thank you for confirming the details",
    RescheduleImportantNote2: "You will receive SMS and email confirmation shortly",
    RescheduleImportantNote3: "Please be informed that you will need to be present at the given delivery address.",
    RescheduleImportantNote4: "Please make sure that you have your ID card as used in this verification in order for us to deliver successfully.",
    RescheduleImportantNote5: "Please use this SMS or Email to engage us directly if you have any concerns or questions.",
    RescheduleImportantNote6: "Thank you for connecting with us!",
    ProceedButton: "Proceed with Delivery Arrangement",
    CancelSRButton: "Cancel Service Request",
    BackButton: "Back",
    SubmitButton: "Submit"
  },
  PaymentDisclaimer: "Any payment made to Asurion (Thailand) Limited through this website will be transacted through NEW Asurion Singapore Pte Ltd."
};

export default MESSAGE_CONSTANTS_EN;
