import React, { Component, Fragment } from "react";

class UpdateChatRequest extends Component {
    componentWillMount() {
        // console.log("UpdateChatRequest componentWillMount");
        this.props.updateChatRequest({ variables: this.props.chatRequest });        
    }

    render() {
        return (
            <Fragment>
            </Fragment>
        )
    }
} 

export default UpdateChatRequest;