import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { ENTER_OTHER_REASON } from '../../actions/Dashboard/actionTypes';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import {
  savePayload,
  updateTransitionPanel
} from "../../actions/Dashboard/doFindAgreements";
import sendNormalIcon from '../../images/ais-images/button-send-normal.png';
import sendActiveIcon from '../../images/ais-images/other-reason-send-active.svg';


class UserInput extends Component {

    constructor(props) {
        super(props);
        this.state= {disabled : false}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.props.savePayload(ENTER_OTHER_REASON, event.target.value);
    }
    handleKey = (event) => {
        let {disabled} = this.state;
        if (event.keyCode === 13 && !event.shiftKey && event.target.value && !disabled) {
            //this.setState({disabled : true });
            this.handleSubmit(event);
        }
    }
    handleSubmit(event) {
        let {disabled} = this.state;
        if(!disabled){
        this.props.savePayload(ENTER_OTHER_REASON,this.props.otherReason );this.props.updateJourneyList(CONSTANTS.AIS_REASON_NOT_HELPFULL,{key : CONSTANTS.OTHER_REASON,value : this.props.otherReason });
        let {Platform} =this.props;
        MIXPANEL.track(EVENTLIST.ROUTE_TO_REFUND_OR_SAVEDESK,{'Route To Refund Or Savedesk':Platform});
        if(Platform==='SaveDesk'){
        this.props.updateJourneyList(CONSTANTS.ROUTE_TO_SAVEDESK, '' );
        }
        this.setState({disabled : true });
        }
    }


    render() {
        let {disabled} = this.state;
        let {otherReason} = this.props;
        let otherReasonLength = otherReason && otherReason.length >= 1;
        return (
            <Fragment>
                <div className= 'wrapper-otherReason'>
                    <div className={` ais_ui-wrapper_reason`}>
                        <div className={`is_ui_inputwrapper`}>
                            <input type="text"
                            name="otherReason"
                            value={this.props.otherReason}
                            className={`ais_ui_input other_reason_text ${disabled ?' disabled ' : '' }`}
                            placeholder={MESSAGE_CONSTANTS.ENTER_OTHER_REASON}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKey}
                            maxLength={2000}
                            minLength={1} />
                        </div>
                    </div>
                    <button className={`ais_ui_button other_reason_btn ${!otherReasonLength || disabled ? ' disabled' : ''} `} onClick={this.handleSubmit}>
                        <img src={ otherReasonLength ? sendActiveIcon : sendNormalIcon} disabled= {!otherReasonLength} className="img-responsive" ></img>
                    </button>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    let { otherReason ,Platform} = state.customerData
    return { otherReason,Platform};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        savePayload,
        updateTransitionPanel
    }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(UserInput);

