import React, { Fragment, Component } from 'react';
import "./ServiceUnavailable.css";
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import allAgentOffline from "../../images/ais-images/agent-busy.png";
import CONSTANTS from '../../utils/Constants';
import { createChatEnquiry, savePayload } from '../../actions/Dashboard/doFindAgreements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { SERVICE_UNAVAILABLE_COUNT } from '../../actions/Dashboard/actionTypes';
import config from '../../config/config'

class ServiceUnavailable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false
    }
  }
  componentDidMount() {
    let { serviceUnavailableCount } = this.props;
    serviceUnavailableCount++;
    this.props.savePayload(SERVICE_UNAVAILABLE_COUNT, serviceUnavailableCount)
    return
  }
  closewindow = () => {
    window.location.replace(config.ApplicationURL);
    this.setState({
      disable: true
    })
  }
  faqClick = () => {
    this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.GET_MOREINFO, MESSAGE_CONSTANTS.GET_MOREINFO.buttons[0].text);
    this.setState({
      disable: true
    })
  }
  render() {
    let agentStatusIcon = allAgentOffline;
    let { disable } = this.state;

    return (
      <Fragment>
        <div className={`ais_current-form-device-div `}>
          <div className="ais_header-txt-div">
            <div>  <img alt="error-icon" className="ais_verificationImage" src={agentStatusIcon} /> </div>
            <div dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE_TEXT }}></div>
            <div className='ais_margin-bottom-60 center serviceUnavailableBtnClass'>
              <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width margin-10 ${this.state.disable ? 'ais_selected' : ''}`} onClick={this.faqClick} >{MESSAGE_CONSTANTS.FAQ}</button>
              <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width margin-10 ${this.state.disable ? 'ais_selected' : ''}`} onClick={this.closewindow} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
            </div>
          </div>
        </div>
        {/*  <div className="ais_service-unavailable-header">
          <div id="serviceUnavailablelogo" className="ais_serviceUnavailablelogo">
            <div className="ais_logo-wrapper">
              <a href="javascript:window.location.reload();">
                <img alt="mobileswop-white-logo" src={logo} />
              </a>
            </div>
            <div className="ais_serviceUnavailableMsg" id="serviceUnavailableMsg">
              <div className="ais_serviceUnavailableContent">
                <p className="ais_errorCode">{MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE.PRIMARY_HEADER}</p>
                <p className="ais_errorMsg">{MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE.CONTENT}</p>
                <p className="ais_errorMsg">{MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE.CONTENT1 || ""}</p>
                <img alt="service-unavailable-logo" src={hotlineIcon} />
                <p className="ais_errorMsg">{MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE.CONTENT2 || ""}</p>
                <p className="ais_errorMsg">{MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE.SECONDARY_HEADER} <br /><br /><Link className='ais_support-hotline' target='_blank' to='/faq'> {window.location.origin}/#/faq</Link>{MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE.CONTINUED_HEADER}</p>
                <p className="ais_errorMsg">{MESSAGE_CONSTANTS.SERVICE_UNAVAILABLE.CONTENT3}</p>
                <button className='ais-submit-button' onClick={this.closewindow} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
              </div>
            </div>
          </div>
        </div> */}
      </Fragment >
    );
  }
}
const mapStateToProps = state => {
  let { chatReason, serviceUnavailableCount } = state.customerData;
  return {
    chatReason, serviceUnavailableCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createChatEnquiry, savePayload }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceUnavailable);
//export default ServiceUnavailable;