import React, { Fragment } from 'react';

const CompletedStage = (props) => {
    return (
        <Fragment>
            <div className="ais_grey-line">
            </div>
            <div className="ais_grey-div">
                <div className="ais_grey-circle"></div>
                <div className="ais_step-div">{props.stage}</div>
            </div>
        </Fragment>
    )
}

export default CompletedStage;