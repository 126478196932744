import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { endInteractionInit } from '../../actions/Dashboard/doFindAgreements';
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';

class AskForEnquiry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
        }
        this.yesButtonClicked = this.yesButtonClicked.bind(this);
        this.noButtonClicked = this.noButtonClicked.bind(this);
    }

    yesButtonClicked() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });

        this.props.updateJourneyList(CONSTANTS.ASK_FOR_ENQUIRY, "")
    }

    noButtonClicked() {
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.source, MESSAGE_CONSTANTS.NO_TEXT);
        this.props.endInteractionInit(this.props.cacheId);
    }

    render() {

        let content = this.props.data.HEADER;

        return (
            <div className="ais_confirm-delivery-icn">
                <div className="ais_current-form-device-div">
                    <div className="ais_current-form-device-header-div">
                        <div className="ais_container-div">
                            <label className="ais_pincode_txt_content">{content}</label>
                        </div>
                        <div className="ais_outer-confirm-delivery-align-button">
                            <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={this.noButtonClicked} >{this.props.data.BUTTONS[0]}</button>
                            <div className="ais_device-divider" />
                            <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.yesButtonClicked} >{this.props.data.BUTTONS[1]}</button>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        endInteractionInit
    }, dispatch)
}

const mapStateToProps = state => {
  const {determineIncidentResponse, caseType, selectedDefectOption, clientData} = state.customerData;

  return {
    customerCaseNumber: determineIncidentResponse ? determineIncidentResponse.CustomerCaseNumber : "",
    caseType,
    selectedDefectOption,
    cacheId: clientData.CacheId,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AskForEnquiry);