import { Form as _Form, Field, Formik } from 'formik';
import { noop, uniqueId } from 'lodash';
import * as React from 'react';
import {
  Box,
  Button,
  chakra,
  createIcon,
  FormControl,
  Heading,
  Input,
  HStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  InputGroup,
  InputLeftAddon,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { formatCapacity } from './functions';
import { useTranslation } from 'react-i18next';

const Form = chakra(_Form);

export const CircleIcon = createIcon({
  displayName: 'CircleIcon',
  viewBox: '0 0 200 200',
  path: <circle fill="currentColor" cx="100" cy="100" r="100"></circle>,
});

export const FilterForm = ({
  id = uniqueId('filter-form-'),
  initialValues = {
    color: '',
    serviceFee: '',
    capacity: '',
  },
  currency = 'THB',
  colors = [],
  capacities = [],
  range = [0, 100],
  onSubmit = noop,
  onClose = noop,
}) => {
  const { t } = useTranslation('', { keyPrefix: "SmartStock.forms"})

  const handleSubmit = ({ color, capacity, serviceFee }) => {
    onSubmit?.({
      color,
      capacity: capacity ? parseInt(capacity) : '',
      serviceFee,
    });
  };

  const handleClose = () => {
    onClose?.();
  };

  const selectStyling = {
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    valueContainer: (styles) => ({
      ...styles,
      border: 'none',
    }),
    dropdownIndicator: (styles, { selectProps }) => ({
      ...styles,
      background: 'none',
      '> svg': {
        transition: 'transform 0.2s',
        transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
      },
    }),
    menu: (styles) => ({
      ...styles,
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: 'gray.150',
      borderRadius: '10px',
    }),
    placeholder: (styles, { selectProps: { value, placeholderColor } }) => ({
      ...styles,
      color: value ? 'black' : placeholderColor,
    }),
    option: (styles, { selectProps: { value }, value: optionValue }) => ({
      ...styles,
      backgroundColor: optionValue === value ? 'brand.primary' : 'white',
      ':hover': {
        backgroundColor: optionValue === value ? 'brand.primary' : 'brand.highlight',
      },
    }),
  };

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ resetForm, setFieldValue, values }) => (
        <Form
          id={id}
          display={'flex'}
          flexDirection={'column'}
          w={'full'}
          gap={'15px'}
          p={'45px'}
          onReset={() => {
            resetForm();
            setFieldValue('serviceFee', '');
            setFieldValue('color', '');
            setFieldValue('capacity', '');
          }}
        >
          <HStack justifyContent={'space-between'}>
            <Heading as="h3" fontSize={'lg'}>
              {t('filterForm.header')}
            </Heading>
            <Button onClick={handleClose} variant="link" fontWeight={'normal'}>
              {t('close')}
            </Button>
          </HStack>
          <Field name="serviceFee">
            {({ field, form }) => (
              <FormControl gap={2} display={'flex'} flexDirection={'column'}>
                <InputGroup>
                  {form.values.serviceFee[1] && (
                    <InputLeftAddon
                      children={currency}
                      background={'none'}
                      color={'gray.550'}
                      pr={'1ch'}
                    />
                  )}

                  <Input
                    focusBorderColor="none"
                    borderLeft={form.values?.serviceFee[1] ? 'none' : 'reset'}
                    pl={form.values.serviceFee[1] ? 0 : 'reset'}
                    value={form.values?.serviceFee[1] || ''}
                    placeholder={t('filterForm.serviceFeeRangePlaceholder')}
                    color={'gray.550'}
                    readOnly
                  />
                </InputGroup>
                <Box>
                  <Slider
                    colorScheme={'brand.primary'}
                    min={range[0]}
                    max={range[1]}
                    aria-label="slider-ex-2"
                    value={form.values.serviceFee[1] || range[1]}
                    defaultValue={form.initialValues.serviceFee[1] || range[1]}
                    onChange={(value) => {
                      form.setFieldValue(field.name, [0, value]);
                    }}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                  <HStack>
                    <Text color={'gray.550'} fontSize={'sm'} lineHeight={'15px'}>
                      {currency} {parseInt(range[0])}
                    </Text>
                    <Spacer />
                    <Text color={'gray.550'} fontSize={'sm'} lineHeight={'15px'}>
                      {currency} {parseInt(range[1])}
                    </Text>
                  </HStack>
                </Box>
              </FormControl>
            )}
          </Field>
          <Field name="color">
            {({ field }) => (
              <FormControl>
                <Select
                  name={field.name}
                  value={field.value}
                  placeholder={
                    field.value !== '' ? (
                      <HStack>
                        <CircleIcon
                          color={field.value}
                          sx={{
                            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset',
                            borderRadius: '50%',
                          }}
                        />
                        <Text>{field.value}</Text>
                      </HStack>
                    ) : (
                      t('filterForm.colorPlaceholder')
                    )
                  }
                  color={'gray.550'}
                  options={colors.map((color) => ({
                    value: color,
                    label: (
                      <HStack>
                        <CircleIcon
                          color={color}
                          sx={{
                            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset',
                            borderRadius: '50%',
                          }}
                        />
                        <Text>{color}</Text>
                      </HStack>
                    ),
                  }))}
                  chakraStyles={selectStyling}
                  onChange={({ value }) => {
                    setFieldValue(field.name, value);
                  }}
                  isSearchable={false}
                />
              </FormControl>
            )}
          </Field>
          <Field name="capacity">
            {({ field }) => (
              <FormControl>
                <Select
                  name={field.name}
                  value={field.value}
                  placeholder={field.value !== '' ? formatCapacity(field.value) : t('filterForm.capacityPlaceholder')}
                  color={'gray.550'}
                  selectedOptionColor="brand.primary"
                  options={capacities.map((capacity) => ({
                    value: capacity,
                    label: formatCapacity(capacity),
                  }))}
                  chakraStyles={selectStyling}
                  onChange={({ value }) => {
                    setFieldValue(field.name, value);
                  }}
                  isSearchable={false}
                />
              </FormControl>
            )}
          </Field>
          <HStack w="full" spacing={0} gap={'20px'}>
            <Button flex={1} type="reset">
              {t('reset')}
            </Button>
            <Button flex={1} type="submit">
              {t('applyFilter')}
            </Button>
          </HStack>
        </Form>
      )}
    </Formik>
  );
};

export default FilterForm;
