import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';

import ConfirmSelectedDevice from '../ConfirmSelectedDevice/confirmSelectedDevice';
import SelectAddressList from '../SelectAddressList/SelectAddressList';
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';

class SlideAlternativeDevices extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            disableBckButton: false
        }
        this.disableSelection = this.disableSelection.bind(this);
        this.backButtonClicked = this.backButtonClicked.bind(this);
    }

    _renderAddress(address, i) {
        if (i < 5) {
            return (
                <SelectAddressList warehouseId={this.props.warehouseId} key={i} address={address} />
            )
        }
    }

    disableSelection() {
        this.setState({
            disabled: true,
            disableBckButton:true
        });
    }

    backButtonClicked() {
        this.setState({
            disabled: true,
            disableBckButton: true
        })

        const { deviceList } = this.props;

        let LFLDevice = _.find(deviceList, { 'IsLikeForLike': true });

        if (LFLDevice) {
          if (LFLDevice.IsNoLongerProduced) {
            this.props.updateJourneyList(CONSTANTS.CHECK_DEVICE_STOCK, MESSAGE_CONSTANTS.BACK_TO_PREV_STEP)
          } else if (LFLDevice.IsNotCurrentlyAvailableForOrder) {
            this.props.updateJourneyList(CONSTANTS.CHECK_DEVICE_STOCK, MESSAGE_CONSTANTS.BACK_TO_PREV_STEP)
          } else {
            this.props.updateJourneyList(CONSTANTS.ALTERNATE_DEVICE_SELECTED, MESSAGE_CONSTANTS.SELECT_DEVICE)
          }
        }
    }

    _renderDevices(device, i) {
        if (device.IsInStock) {
            return (
                <ConfirmSelectedDevice key={i} device={device} caseType={this.props.caseType}
                    disableSelection={this.disableSelection.bind(this)}
                    disable={this.state.disabled}
                    cacheId={this.props.cacheId} serviceFee={this.props.serviceFee} updateJourneyList={this.props.updateJourneyList} />
            )
        }
    }

    // _renderNotAvailable() {
    //     this.props.updateJourneyList(CONSTANTS.REPLACE_DEVICE_NOT_AVAILABLE, "No Device available in stock.")
    // }

    render() {

        const { deviceList } = this.props;

        let deviceInStock = _.find(deviceList, { 'IsInStock': true });

        if (deviceInStock) {
            return (
                <div className="ais_scrolling-wrapper">
                    <div className="ais_card">
                        {/* {this.props.source === CONSTANTS.SOURCE_USER_INPUT.SELECT_ADDRESS_CONFIRMATION ?
                            <div>
                                {addressList && addressList.map((e, i) => this._renderAddress(e, i))}
                            </div> : */}
                        <div>
                            {deviceList && deviceList.map((e, i) => this._renderDevices(e, i))}
                        </div>
                        {/* } */}
                    </div>
                    <div>
                        {this.props.backEnabled ? <button className="ais_go_back" disabled={this.state.disableBckButton} onClick={this.backButtonClicked}>  {MESSAGE_CONSTANTS.BACK_TO_PREV_STEP} </button> : ""}
                    </div>
                </div>
            )
        }
        // else {
        //     return (
        //         <div>{this._renderNotAvailable()}</div>
        //     )
        // }

    }
}

const mapStateToProps = state => {
    const { caseType, replacementDevices, clientData, serviceFee, backEnabled } = state.customerData;
    let deviceList = replacementDevices ? replacementDevices.ReplacementItem : [];

    // console.log("deviceList", deviceList)

    return {
        caseType,
        deviceList,
        backEnabled,
        cacheId: clientData.CacheId,
        fee: serviceFee ? serviceFee.CurrencyCode + " " + serviceFee.TotalAmount : null,
    }
};

export default connect(mapStateToProps, null)(SlideAlternativeDevices);
//export default SlideAlternativeDevices;