const CONSTANTS = {
  REQUEST_CHANNELS: {
    WEB: 'Web',
    MOBILE_WEB: 'Mobile Web',
  },
  SHOW_PAYMENTFEE_WITH_DISCOUNT: 'SHOW_PAYMENTFEE_WITH_DISCOUNT',
  IMEI_ATTEMPTS_EXCEEDED: 'IMEI_ATTEMPTS_EXCEEDED',
  IMEI_VERFIED: 'IMEI_VERFIED',
  CAPTURE_IW_REMARKS: 'CAPTURE_IW_REMARKS',
  CONFIRM_MALFUNCTION_DEVICE_MEASURES: 'CONFIRM_MALFUNCTION_DEVICE_MEASURES',
  LOST_DEVICE: 'I lost my device',
  DEVICE_IS_BROKEN: 'My device is broken',
  KNOW_MY_STATUS: 'I know my current status',
  DEVICE_NOT_WORKING: 'My device is not working',
  CREATE_A_NEW_REQUEST: 'Create a new request',
  ABOUT_MY_CURRENT_DELIVERY: 'About my current delivery',
  GENERAL_ENQUIRY: 'General Enquiry',
  CHECK_SERVICE_FEE: 'Know my service request fee',
  SWAP: 'SWAP',
  RESUME_POINT_CONFIRM_CONTACT_POINTS: 'RESUME_POINT_CONFIRM_CONTACT_POINTS',
  IMEI_INCORRECT_ATTEMPT: 'IMEI_INCORRECT_ATTEMPT',
  IMEI_ATTEMPTS_EXCEEDED: 'IMEI_ATTEMPTS_EXCEEDED',
  APPLE: 'APPLE',
  REPLACEMENT: 'REPLACEMENT',
  MALFUNCTION: 'MALFUNCTION',
  REQUEST_OPTIONS: 'REQUEST_OPTIONS',
  ENQUIRY_OPTIONS: 'ENQUIRY_OPTIONS',
  NO_EXISTING_SR_RESUME: 'NO_EXISTING_SR_RESUME',
  ENTERED_MDN: 'ENTERED_MDN',
  ENTERED_NAME: 'ENTERED_NAME',
  ENTERED_NRIC: 'ENTERED_NRIC',
  LEAVING_AIS_REASON_STATE: 'LEAVING_AIS_REASON_STATE',
  AIS_REASON_NOT_HELPFULL_STATE: 'AIS_REASON_NOT_HELPFULL_STATE',
  LOAD_AIS_SUBREASON_STATE: 'LOAD_AIS_SUBREASON_STATE',
  // IN_WARRANTY: "HI",
  IN_WARRANTY: 'REFOEM',
  OUT_OF_WARRANTY: 'IW',
  OEM_EXCHANGE_OPTIONS: 'OEM_EXCHANGE_OPTIONS',
  NO_EXCHANGE: 'No Exchange',
  OEM_EXCHANGE: 'Manufacturer Exchange',
  AIS_EXCHANGE: 'AIS Exchange',
  CHECK_SELECTED_FLOW: 'CHECK_SELECTED_FLOW',
  SERVICE_REQUEST_NO: 'SR No.',
  SYSTEM: 'System',
  ME: 'me',
  TEXT: 'text',
  MESSAGE_TIME_FORMAT: 'hh:mm:ss a',
  HEADER_TIME_FORMAT: 'DD MMM YYYY hh:mm A',
  CC_ATTEMPT_ERROR: 'CC_ATTEMPT_ERROR',
  ADDRESS_ATTEMPT_ERROR: 'ADDRESS_ATTEMPT_ERROR',
  NO_EXISTING_SR: 'NO_EXISTING_SR',
  NO_CURRENT_DELIVERY: 'NO_CURRENT_DELIVERY',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  STEPPED_MESSAGES: 'STEPPED_MESSAGES',
  STEPPED_JOURNEY_MESSAGES: 'STEPPED_JOURNEY_MESSAGES',
  INCORRECT_MDN_SECOND_ATTEMPT: 'INCORRECT_MDN_SECOND_ATTEMPT',
  INCORRECT_NAME_SECOND_ATTEMPT: 'INCORRECT_NAME_SECOND_ATTEMPT',
  INCORRECT_NRIC_SECOND_ATTEMPT: 'INCORRECT_NRIC_SECOND_ATTEMPT',
  ADDRESS_LINE1: 'Address Line 1:',
  ADDRESS_LINE2: 'Address Line 2:',
  ADDRESS_LINE3: 'Address Line 3:',
  PIN_CODE: 'PIN_CODE',
  CITY: 'District:',
  STATE: 'Province:',
  SOURCE_USER_INPUT: {
    SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
    ASSIGN_TO_AGENT_FOR_CHAT: 'ASSIGN_TO_AGENT_FOR_CHAT',
    REQUEST: 'REQUEST',
    ENQUIRY: 'ENQUIRY',
    ENQUIRY_DATA: 'ENQUIRY_DATA',
    RESUME_CASE_ASK_MDN: 'RESUME_CASE_ASK_MDN',
    SWOP_SELECTED: 'SWOP_SELECTED',
    MALFUNCTION_SELECTED: 'MALFUNCTION_SELECTED',
    MDN: 'MDN',
    NAME: 'NAME',
    NRIC: 'NRIC',
    ASK_FOR_LEAVING_AIS_REASON: 'ASK_FOR_LEAVING_AIS_REASON',
    AIS_REASON_NOT_HELPFULL: 'AIS_REASON_NOT_HELPFULL',
    LOAD_AIS_SUBREASON: 'LOAD_AIS_SUBREASON',
    CHANGED_DELIVERY_ADDRESS: 'CHANGED_DELIVERY_ADDRESS',
    CURRENT_DEVICE_SELECTION: 'CURRENT_DEVICE_SELECTION',
    AVAIL_DEVICE_SELECTION: 'DEVICE_AVAILED',
    SERVICE_FEE_PROCEED: 'SERVICE_FEE_PROCEED',
    ENROLLED_ADDRESS_CONFIRMATION: 'ENROLLED_ADDRESS_CONFIRMATION',
    QAS_API_FAILURE: 'QAS_API_FAILURE',
    ENROLLED_EMAIL_CONFIRMATION: 'ENROLLED_EMAIL_CONFIRMATION',
    ENROLLED_MOB_NO_CONFIRMATION: 'ENROLLED_MOB_NO_CONFIRMATION',
    PAYMENT_FEE_CONFIRMATION: 'PAYMENT_FEE_CONFIRMATION',
    CREDIT_CARD_DETAILS_SUBMITTED: 'CREDIT_CARD_DETAILS_SUBMITTED',
    NEW_DELIVERY_SCHEDULE_SUBMITTED: 'NEW_DELIVERY_SCHEDULE_SUBMITTED',
    DELIVERY_SCHEDULE_CONFIRMATION: 'DELIVERY_SCHEDULE_CONFIRMATION',
    SIM_CARD_CONFIRMATION: 'SIM_CARD_CONFIRMATION',
    CHAT_CONFIRMATION: 'CHAT_CONFIRMATION',
    CONFIRMED_DETAILS: 'CONFIRMED_DETAILS',
    FEEDBACK_CONFIRMATION: 'FEEDBACK_CONFIRMATION',
    ASK_FOR_SURVEY_CONFIRMATION: 'ASK_FOR_SURVEY_CONFIRMATION',
    MDN_WRONG_ATTEMPT: 'MDN_WRONG_ATTEMPT',
    DEVICE_LIST: 'DEVICE_LIST',
    DEVICE_LIST_SUBSCRIPTION: 'DEVICE_LIST_SUBSCRIPTION',
    VERIFIED_AGREEMENT_DEVICE_LIST: 'VERIFIED_AGREEMENT_DEVICE_LIST',
    NRIC_WRONG_ATTEMPT: 'NRIC_WRONG_ATTEMPT',
    NAME_WRONG_ATTEMPT: 'NAME_WRONG_ATTEMPT',
    INCORRECT_NAME_SECOND_ATTEMPT: 'INCORRECT_NAME_SECOND_ATTEMPT',
    MDN_VERIFICAIION_ERROR_DISPLAY: 'MDN_VERIFICAIION_ERROR_DISPLAY',
    NAME_VERIFICAIION_ERROR_DISPLAY: 'NAME_VERIFICAIION_ERROR_DISPLAY',
    NRIC_VERIFICAIION_ERROR_DISPLAY: 'NRIC_VERIFICAIION_ERROR_DISPLAY',
    MDN_VERIFICAIION_ERROR_DISPLAYED: 'MDN_VERIFICAIION_ERROR_DISPLAYED',
    NRIC_VERIFICAIION_ERROR_DISPLAYED: 'NRIC_VERIFICAIION_ERROR_DISPLAYED',
    ASSIGNED_AGENT_BUSY: 'ASSIGNED_AGENT_BUSY',
    ALL_AGENT_BUSY: 'ALL_AGENT_BUSY',
    ALL_AGENT_OFFLINE: 'ALL_AGENT_OFFLINE',
    ALL_AGENT_BUSY_MODAL: 'ALL_AGENT_BUSY_MODAL',
    ALL_AGENT_OFFLINE_MODAL: 'ALL_AGENT_OFFLINE_MODAL',
    SELECT_ADDRESS_CONFIRMATION: 'SELECT_ADDRESS_CONFIRMATION',
    CURRENT_DEVICE_OPTIONS: 'CURRENT_DEVICE_OPTIONS',
    BACK_TO_HOME_PAGE: 'BACK_TO_HOME_PAGE',
    SUBMIT_NEW_CONTACT_NO: 'SUBMIT_NEW_CONTACT_NO',
    SUBMIT_NEW_EMAIL: 'SUBMIT_NEW_EMAIL',
    INVALID_CC_DETAILS: 'INVALID_CC_DETAILS',
    EXCEEDED_CC_VALIDTION_ATTEMPTS: 'EXCEEDED_CC_VALIDTION_ATTEMPTS',
    CC_ATTEMPT_ERROR: 'CC_ATTEMPT_ERROR',
    PAYMENT_CHARGE_ATTEMPT_ERROR: 'PAYMENT_CHARGE_ATTEMPT_ERROR',
    PAYMENT_CHARGE_FORM_REATTEMPT: 'PAYMENT_CHARGE_FORM_REATTEMPT',
    ADDRESS_ATTEMPT_ERROR: 'ADDRESS_ATTEMPT_ERROR',
    WEB_CREDITCARD_FORM: 'WEB_CREDITCARD_FORM',
    WEB_CREDITCARD_FORM_REATTEMPT: 'WEB_CREDITCARD_FORM_REATTEMPT',
    GRID_VIEW_OPTIONS: 'GRID_VIEW_OPTIONS',
    WEB_TRACK_MY_DELIVERY: 'WEB_TRACK_MY_DELIVERY',
    DEVICE_TRACK_MY_DELIVERY: 'DEVICE_TRACK_MY_DELIVERY',
    RESCHEDULE_MY_DELIVERY: 'RESCHEDULE_MY_DELIVERY',
    CHANGE_MY_DELIVERY_ADDRESS: 'CHANGE_MY_DELIVERY_ADDRESS',
    I_HAVE_AN_ENQUIRY: 'I_HAVE_AN_ENQUIRY',
    DELIVERY_SCHEDULE_FOR_PAYMENT: 'DELIVERY_SCHEDULE_FOR_PAYMENT',
    SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF: 'SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF',
    ENQUIRY_NOEXISTING_RESUMECASE: 'ENQUIRY_NOEXISTING_RESUMECASE',
    //NOTRESOLVED:"Not Resolved",
    NOT_RESOLVED: {
      MDN_NAME_NRIC: 'MDN_NAME_NRIC',
      GENERIC: 'GENERIC',
    },
    USER_DISCONNECTED: 'User Disconnected',
    AGENT_DISCONNECTED: 'Agent Disconnected',
    RESUME_REQUEST_OPTIONS: 'RESUME_REQUEST_OPTIONS',
    RESUME_ENQUIRY_OPTIONS: 'RESUME_ENQUIRY_OPTIONS',
    RESUME_NEW_SR_ENQUIRY_OPTIONS: 'RESUME_NEW_SR_ENQUIRY_OPTIONS',
    ASK_TO_BOT: 'ASK_TO_BOT',
    BOT_INPUT_SERVICE_FEE: 'BOT_INPUT_SERVICE_FEE',
    NO_CONFIRMED_FOR_TURNOFF_FEATURES: 'NO_CONFIRMED_FOR_TURNOFF_FEATURES',
    FMIP: 'FMIP',
    ASK_FOR_ENQUIRY_CONFIRMATION: 'ASK_FOR_ENQUIRY_CONFIRMATION',
    RESCHEDULE_DELIVERY_ENQUIRY: 'RESCHEDULE_DELIVERY_ENQUIRY',
    RESCHEDULE_DELIVERY_ADDRESS_ENQUIRY: 'RESCHEDULE_DELIVERY_ADDRESS_ENQUIRY',
    CANCEL_DELIVERY_ENQUIRY: 'CANCEL_DELIVERY_ENQUIRY',
    GET_MOREINFO: 'GET_MOREINFO',
    GET_STARREQ: 'GET_STARREQ',
    WBKO_UPDATE_CONTACT_DETAILS: 'WBKO_UPDATE_CONTACT_DETAILS',
    WBKO_UPDATE_CONTACT_DETAILS_ENQUIRY: 'WBKO_UPDATE_CONTACT_DETAILS_ENQUIRY',
    WBKO_CANCELLATION_REASON: 'WBKO_CANCELLATION_REASON',
  },
  SHOW_PAYMENT_FEE_SKIP_DELIVERY: 'SHOW_PAYMENT_FEE_SKIP_DELIVERY',
  USER_DISCONNECTED: 'USER_DISCONNECTED',
  AGENT_DISCONNECTED: 'AGENT_DISCONNECTED',
  TERMS_ACCEPTED: 'TERMS_ACCEPTED',
  IMEI_VERIFIED: 'IMEI_VERIFIED',
  GENERIC_LOADER: 'GENERIC_LOADER',
  THANKS_FOR_CONTACTING: 'THANKS_FOR_CONTACTING',
  CAPTURE_INCIDENT_DETAILS: 'CaptureIncidentDetails',
  MDN_USER_INPUT: 'MDN_USER_INPUT',
  CHECK_DEVICE_STOCK: 'CHECK_DEVICE_STOCK',
  TURN_OFF_FMIP: 'TURN_OFF_FMIP',
  TURN_OFF_FMIP_INSTRUCTIONS: 'TURN_OFF_FMIP_INSTRUCTIONS',
  NRIC_USER_INPUT: 'NRIC_USER_INPUT',
  MDN_VERFIED: 'MDN_VERFIED',
  ORDER_UNDER_REVIEW: 'ORDER_UNDER_REVIEW',
  ORDER_UNDER_REVIEW_COD: 'ORDER_UNDER_REVIEW_COD',
  BID_FAREWELL: 'BID_FAREWELL',
  MERGE_MALFUNCTION_FLOW: 'MERGE_MALFUNCTION_FLOW',
  NAME_USER_INPUT: 'NAME_USER_INPUT',
  NAME_VERIFIED: 'NAME_VERIFIED',
  PAYMENT_BEING_VERIFIED: 'PAYMENT_BEING_VERIFIED',
  PAYMENT_VERIFIED: 'PAYMENT_VERIFIED',
  CHECKING_SERVICE_REQUEST_FEE: 'CHECKING_SERVICE_REQUEST_FEE',
  ACCOUNT_VERIFICATION_IN_PROGRESS: 'ACCOUNT_VERIFICATION_IN_PROGRESS',
  ACCOUNT_VERIFICATION_COMPLETED: 'ACCOUNT_VERIFICATION_COMPLETED',
  RESUME_FLOW_FOR_HOLD: 'RESUME_FLOW_FOR_HOLD',
  ASK_FOR_ENQUIRY: 'ASK_FOR_ENQUIRY',
  AVAIL_DEVICE: 'AVAIL_DEVICE',
  REPLACEMENT_DEVICE: 'REPLACEMENT_DEVICE',
  CONFIRM_CURRENT_DEVICE: 'CONFIRM_CURRENT_DEVICE',
  DOWNLOAD_DOCUMENTS: 'DOWNLOAD_DOCUMENTS',
  DOWNLOAD_DOCUMENTS_RESUME: 'DOWNLOAD_DOCUMENTS_RESUME',
  ELIGIBILITY_VOID: 'ELIGIBILITY_VOID',
  CANCEL_UPLOAD: 'CANCEL_UPLOAD',
  HOLD_SCRIPT: 'HOLD_SCRIPT',
  UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
  CONFIRM_UPLOAD_DOCUMENTS: 'CONFIRM_UPLOAD_DOCUMENTS',
  DEVICE_CONFIRM_UPLOAD_DOCUMENTS: 'DEVICE_CONFIRM_UPLOAD_DOCUMENTS',
  ELIGIBLE_FOR_RESHIP: 'ELIGIBLE_FOR_RESHIP',
  PAYMENT_REVIEW_PENDING: 'PAYMENT_REVIEW_PENDING',
  NO_PAYMENT_REVIEW: 'NO_PAYMENT_REVIEW',
  ACCEPT_TERMS: 'ACCEPT_TERMS',
  CHECK_ALTERNATIVE_DEVICES: 'CHECK_ALTERNATIVE_DEVICES',
  CONFIRM_ENROLLED_ADDRESS: 'CONFIRM_ENROLLED_ADDRESS',
  FINAL_CONFIRM_ENROLLED_ADDRESS: 'CONSTANTS.FINAL_CONFIRM_ENROLLED_ADDRESS',
  CONFIRM_EMAIL_ADDRESS: 'CONFIRM_EMAIL_ADDRESS',
  CONFIRM_MOB_NO: 'CONFIRM_MOB_NO',
  PAYMENT_FEE: 'PAYMENT_FEE',
  CONTINUE_PREVIOUS_SR: 'CONTINUE_PREVIOUS_SR',
  DEVICE_LIST: 'DEVICE_LIST',
  VERIFIED_AGREEMENT_DEVICE_LIST: 'VERIFIED_AGREEMENT_DEVICE_LIST',
  WEB_CHANGE_DELIVERY_ADDRESS: 'WEB_CHANGE_DELIVERY_ADDRESS',
  DEVICE_CHANGE_DELIVERY_ADDRESS: 'DEVICE_CHANGE_DELIVERY_ADDRESS',
  WEB_CREDITCARD_FORM: 'WEB_CREDITCARD_FORM',
  DEVICE_CREDITCARD_FORM: 'DEVICE_CREDITCARD_FORM',
  WEB_SCHEDULE_FORM: 'WEB_SCHEDULE_FORM',
  DEVICE_SCHEDULE_FORM: 'DEVICE_SCHEDULE_FORM',
  CONFIRM_DELIVERY_SCHEDULE: 'CONFIRM_DELIVERY_SCHEDULE',
  SELECT_ADDRESS_LIST: 'SELECT_ADDRESS_LIST',
  ALTERNATIVE_DEVICES_MESSAGE: 'Check alternative device',
  ALTERNATE_DEVICE_LIST: 'ALTERNATE_DEVICE_LIST',
  WAIT_FOR_ENROLLED_DEVICE: 'Wait for 3 - 5 days',
  WAIT_OR_SELECT_ALTERNATE_DEVICE: 'WAIT_OR_SELECT_ALTERNATE_DEVICE',
  REPLACE_DEVICE_SELECTED: 'REPLACE_DEVICE_SELECTED',
  REPLACE_DEVICE_NOT_AVAILABLE: 'REPLACE_DEVICE_NOT_AVAILABLE',
  FLOW_AFTER_PROCESS_FEE: 'FLOW_AFTER_PROCESS_FEE',
  CONFIRM_SIM_CARD: 'CONFIRM_SIM_CARD',
  TRIAGE_QUESTION: 'TRIAGE_QUESTION',
  TRIAGE_ISSUE_RESOLVED: 'TRIAGE_ISSUE_RESOLVED',
  TRIAGE_FAILED: 'TRIAGE_FAILED',
  ASK_FOR_FEEDBACK: 'ASK_FOR_FEEDBACK',
  ASK_FOR_SURVEY: 'ASK_FOR_SURVEY',
  SURVEY_QUESTION_RATING: 'SURVEY_QUESTION_RATING',
  SURVEY_QUESTION_COMMENTS: 'SURVEY_QUESTION_COMMENTS',
  SURVEY_QUESTION_EXPERIENCE: 'SURVEY_QUESTION_EXPERIENCE',
  EXPERIENCE_FEEDBACK_SUBMITTED: 'EXPERIENCE_FEEDBACK_SUBMITTED',
  CONFIRM_CHAT_SUPPORT: 'CONFIRM_CHAT_SUPPORT',
  CONFIRM_SELECTED_DEVICE: 'CONFIRM_SELECTED_DEVICE',
  CONFIRM_DETAILS: 'CONFIRM_DETAILS',
  CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
  DROP_MESSAGE: 'DROP_MESSAGE',
  GRID_ENQUIRY_OPTIONS: 'GRID_ENQUIRY_OPTIONS',
  PROCEED_SERVICE_FEE: 'PROCEED_SERVICE_FEE',
  MDN_VERIFICAIION_ERROR: 'MDN_VERIFICAIION_ERROR',
  NAME_VERIFICAIION_ERROR: 'NAME_VERIFICAIION_ERROR',
  NRIC_VERIFICAIION_ERROR: 'NRIC_VERIFICAIION_ERROR',
  SENT_MESSAGE_SUCCEED: 'SENT_MESSAGE_SUCCEED',
  SUBMIT_CONTACT_NUMBER: 'SUBMIT_CONTACT_NUMBER',
  SUBMIT_EMAIL: 'SUBMIT_EMAIL',
  SUBMIT_IMEI_NUMBER: 'SUBMIT_IMEI_NUMBER',
  EMAIL_NOT_AVAILABLE: 'EMAIL_NOT_AVAILABLE',
  PHONE_NOT_AVAILABLE: 'PHONE_NOT_AVAILABLE',
  TURNOFF_FEATURES: 'TURNOFF_FEATURES',
  WEB_GRID_VIEW_OPTIONS: 'WEB_GRID_VIEW_OPTIONS',
  WEB_TRACK_MY_DELIVERY: 'WEB_TRACK_MY_DELIVERY',
  DEVICE_TRACK_MY_DELIVERY: 'DEVICE_TRACK_MY_DELIVERY',
  ASSIGNED_AGENT_BUSY: 'ASSIGNED_AGENT_BUSY',
  ALL_AGENT_BUSY: 'ALL_AGENT_BUSY',
  ALL_AGENT_OFFLINE: 'ALL_AGENT_OFFLINE',
  TRANSFERRED_TO_AGENT: 'TRANSFERRED_TO_AGENT',
  CHAT_SUPPORT_ENDED: 'CHAT_SUPPORT_ENDED',
  CHECKING_DEVICES_LOADER: 'CHECKING_DEVICES_LOADER',
  LEX_PROCESSING_LOADER: 'LEX_PROCESSING_LOADER',
  CC_DETAILS_VERIFIED: 'CC_DETAILS_VERIFIED',
  STANDARDISE_ADDRESS_LOADER: 'STANDARDISE_ADDRESS_LOADER',
  PROCESS_PAYMENT_LOADER: 'PROCESS_PAYMENT_LOADER',
  ALL_AGENT_BUSY_MODAL: 'ALL_AGENT_BUSY_MODAL',
  ALL_AGENT_OFFLINE_MODAL: 'ALL_AGENT_OFFLINE_MODAL',
  CURRENT_REQUEST_DETAILS: 'CURRENT_REQUEST_DETAILS',
  COD_CONFIRMATION: 'COD_CONFIRMATION',
  CLOSE_SR: 'CLOSE_SR',
  NO_ALTERNATIVE_DEV_INSTOCK: ' NO_ALTERNATIVE_DEV_INSTOCK',
  DEVICE_NOT_ELIGIBLE: 'DEVICE_NOT_ELIGIBLE',
  MDN_NRIC_CLOSE: 'MDN_NRIC_CLOSE',
  GENERIC_NOT_RESOLVED: 'GENERIC_NOT_RESOLVED',
  CLOSE_REQUEST: 'CLOSE_REQUEST',
  // RESOLVED_CODE: "Resolved",
  // NOT_RESOLVED_CODE: "NotResolved",
  CHAT_REQUEST_STATUS: {
    INITIATED: 'Initiated',
    ACCEPTED: 'Accepted',
    TRANSFERRING: 'Transferring',
    ABANDONED: 'Abandoned',
    RESOLVED: 'Resolved',
    NOTRESOLVED: 'Not Resolved',
    USER_DISCONNECTED: 'User Disconnected',
    AGENT_DISCONNECTED: 'Agent Disconnected',
    TRANSFER_ABANDONED: 'Transfer Abandoned',
    ENDED: 'Ended',
    AGENT_ENDED: 'Agent Ended'
  },
  CURRENT_DEVICE_OPTIONS: 'CURRENT_DEVICE_OPTIONS',
  CLIENT_KEY: 'ais',
  // InitializeApplication: {
  // Client: "SingTel"
  //    Client: "ais"
  // }

  NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT: 'NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT',
  VOID_SCRIPT: 'VOID_SCRIPT',
  NO_OF_DAYS_FOR_DELIVERY_SCHEDULE: 7,
  ASK_CONTACT_FOR_ENQUIRY_CALLBACK: 'ASK_CONTACT_FOR_ENQUIRY_CALLBACK',
  SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK: 'SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK',
  DISABLED_DAYS: 'Sunday',
  DELIVERY_SCHEDULE_DATE_FORMAT: 'MMM DD YYYY',
  ESTIMATED_DELIVERY_DATE_FORMAT: 'YYYY-MM-DD',
  CURRENT_REQUEST_DATE_FORMAT: 'ddd MMM DD YYYY',
  DISPLAY_DELIVERY_DATE_FORMAT: 'dddd MMM DD YYYY',
  SAME_DAY_EVENING: '6H',
  NEXT_DAY_AFTERNOON: '14H',
  NEXT_DAY_EVENING: '17H',
  VALID_CONTACT_NO_LENGTH: 10,
  INTERACTION_TYPE: 'ONLINE',
  INTERACTION_STATUS: 'INPRGRS',
  CHANNEL_NAME: 'ONLINE',
  CLIENT_PROFILE_ID: '6040',
  EXTERNAL_SYSTEM_NAME: 'ConsoleOne',
  INTERACTION_LINE_REASON: 'STRTSR',
  API_CALLING_SOURCE: {
    KEEP_DELIVERY_SCHEDULE: 'KEEP_DELIVERY_SCHEDULE',
    CHANGE_DELIVERY_SCHEDULE: 'CHANGE_DELIVERY_SCHEDULE',
  },

  SR_STATUS: 'CMPLTD',
  MAX_INVALID_ADDRESS_ATTEMPTS: 3,
  SHIPPING_STATUS: {
    READY: 'READY',
    SHIPPED: 'SHIPPED',
    SHIP: 'SHIP',
    DISPATCHED: 'started',
    DELIVERED: 'DLVRD',
  },
  // SLOTS_MAPPING: [{RESP_VALUE: "Next Day", LABEL: "SLOT1", VALUE: "12H", DISPLAY_OPTION: "08:00AM - 12:00PM"},
  // {RESP_VALUE: "Same Day", LABEL: "SLOT2", VALUE: "18H", DISPLAY_OPTION: "02:00PM - 06:00PM"},
  // {RESP_VALUE: "Same Day Morning", LABEL: "SLOT3", VALUE: "4H", DISPLAY_OPTION: "08:00AM - 12:00PM"}],
  // {RESP_VALUE: "Next Day", LABEL: "SLOT1", VALUE: "17H", DISPLAY_OPTION: "08:00AM - 10:00AM"},
  // {RESP_VALUE: "Same Day Morning", LABEL: "SLOT3", VALUE: "6H", DISPLAY_OPTION: "08:00AM - 10:00AM"},
  // {RESP_VALUE: "Same Day Afternoon", LABEL: "SLOT2", VALUE: "14H", DISPLAY_OPTION: "11:00AM - 01:00PM"},
  // {RESP_VALUE: "Same Day Evening", LABEL: "SLOT4", VALUE: "17H", DISPLAY_OPTION: "02:00PM - 04:00PM"}

  GREATER_BANGKOK_PROVINCES: ['10', '11', '12', '73', '74'],
  ALLOWED_GENERAL_ENQUIRY_TYPES: ['FEES', 'DOC', 'SHIP'],

  SKILLID: process.env.REACT_APP_SKILL_ID, //'singtel-chat'

  //Application Skill Id
  SOURCE_ENUM: {
    AGENT: 'Agent',
    USER: 'User',
    SYSTEM: 'System',
    INFO: 'Info',
    INTERNAL: 'Internal',
  },

  MESSAGE_TYPE: {
    HTML: 'HTML',
    PLAIN_TEXT: 'Plain Text',
  },
  WITH_SIM: 'with SIM Card',
  WITHOUT_SIM: 'without SIM Card',
  MDN_VERIFIED: 'MDN_VERIFIED',
  DEFAULT_INACTIVITY_TIMEOUT_MINUTES: 10,
  SELECTED_ENQUIRY_OPTIONS: 'SELECTED_ENQUIRY_OPTIONS',
  SELECTED_REQUEST_OPTIONS: 'SELECTED_REQUEST_OPTIONS',
  BOT_HANGUP_PHRASE: 'transfer you to our customer support',
  BOT_FAILURE_PHRASE: 'Can you please rephrase your query',
  CONFIRM_DETAILS_DATE_FORMAT: 'MMMM Do YYYY',
  SLOT_NOT_AVAILABLE: 'Slot Not Available',
  SURVEY_TYPE: 'NPS',
  SURVEY_TRIGGER_POINT: 'SR_Creation',
  GENERAL_ENQUIRY_ASSIGN_AGENT_IF_AVAILABLE: 'GENERAL_ENQUIRY_ASSIGN_AGENT_IF_AVAILABLE',
  GENERAL_ENQUIRY_CREATED_THANKS_FOR_CONTACTING: 'GENERAL_ENQUIRY_CREATED_THANKS_FOR_CONTACTING',
  CHAT_REASON: {
    REQUEST: 'Unrecognised Enquiry Query',
    ENQUIRY: 'Unrecognised Request Query',
    GENERAL_ENQUIRY: 'General Enquiry',
    MDN: 'Invalid MDN',
    NAME: 'Invalid Name',
    NRIC: 'Invalid NRIC',
    IMEI: 'Invalid IMEI',
    EMAIL: 'Invalid Email ID',
    PAYMENT: 'Payment Failure',
    ADDRESS: 'Address Failure',
    SERVICE_FEE_BOT: 'Unrecognised Service Fee Query',
    FMIP: 'FMIP not turned off',
    RESCHEDULE_DELIVERY: 'Reschedule Delivery',
    CANCEL_DELIVERY: 'Cancel Delivery',
    CHANGE_DELIVERY_ADDRESS: 'Change Delivery Address',
    SERVICE_UNAVAILABLE: 'Technical Error',
    VOID_SCRIPT: 'SR-entitlements',
  },
  CHOOSE_TO_CONTINUE_BOT: 'CHOOSE_TO_CONTINUE_BOT',
  INTERACTION_TYPES: {
    ASSISTANCE: 'Assistance',
    ONLINE: 'Online',
  },

  TWILIO_TASKSTATUS: {
    CANCELED: 'canceled',
    ASSIGNED: 'assigned',
  },
  DISABLED_PROCEED_SERVICE_FEE: 'DISABLED_PROCEED_SERVICE_FEE',
  PIN_CODE: 'Postal Code: ',
  BIG_MSG_CHARS: 200,
  BIG_MSG_DELAY: 5000,
  MEDIUM_MSG_CHARS: 100,
  MEDIUM_MSG_DELAY: 3000,
  DEFAULT_MSG_DELAY: 2000,
  SELECT_FROM_PHONE_FOR_CHAT_HISTORY: 'Select file from your phone',
  SELECT_FROM_COMPUTER_FOR_CHAT_HISTORY: 'Select file from your computer',
  REPLACEMENT_MATRIX_NOT_SET_ERROR: 'ReplacementMatrix is not setup for asset',
  REPLACEMENT_MATRIX_NOT_SET: 'REPLACEMENT_MATRIX_NOT_SET',
  API_NAMES: {
    INITIALIZE: 'INITIALIZE',
    START_INTERACTION: 'START_INTERACTION',
    FIND_AGREEMENTS: 'FIND_AGREEMENTS',
    VERIFY_NRIC: 'VERIFY_NRIC',
    DETERMINE_INCIDENT_PATH: 'DETERMINE_INCIDENT_PATH',
    RESUME_SERVICE_REQUEST: 'RESUME_SERVICE_REQUEST',
    TRIAGE_RESOLVED: 'TRIAGE_RESOLVED',
    NEW_SERVICE_REQUEST: 'NEW_SERVICE_REQUEST',
    PROCESS_INCIDENT: 'PROCESS_INCIDENT',
    FULLFILLMENT_OPTIONS: 'FULLFILLMENT_OPTIONS',
    GET_FULLFILLMENT_OPTIONS: 'GET_FULLFILLMENT_OPTIONS',
    REPLACEMENT: 'REPLACEMENT',
    SERVICE_ORDER: 'SERVICE_ORDER',
    STANDARDISE_ADDRESS: 'STANDARDISE_ADDRESS',
    SHIPPING_ORDER: 'SHIPPING_ORDER',
    SHIPPING_METHODS: 'SHIPPING_METHODS',
    PAYMENT: 'PAYMENT',
    PCI_TOKEN: 'PCI_TOKEN',
    BILLING_GATEWAY: 'BILLING_GATEWAY',
    CHARGE_ORDER: 'CHARGE_ORDER',
    SUBMIT_ORDER: 'SUBMIT_ORDER',
    END_INTERACTION: 'END_INTERACTION',
    EVALUATE_IMEI: 'EVALUATE_IMEI',
    CONTACT_POINTS: 'CONTACT_POINTS',
    MAKE_AND_MODEL: 'MAKE_AND_MODEL',
    CANCEL_SERVICE_REQUEST: 'CANCEL_SERVICE_REQUEST',
    UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
    SUBMIT_SURVEY: 'SUBMIT_SURVEY',
    GET_PROVINCE_FAILURE: 'GET_PROVINCE_FAILURE',
    GET_SR_FEE: 'GET_SR_FEE',
    SUBMIT_GENERAL_ENQUIRY_FAILURE: 'SUBMIT_GENERAL_ENQUIRY_FAILURE',
    VERIFY_AGREEMENT: 'VERIFY_AGREEMENT',
    CUSTOMER_CASE_GRAPH: 'CUSTOMER_CASE_GRAPH',
  },
  JOURNEY_ENDED: 'JOURNEY_ENDED',
  ONLINE_JOURNEY_STATUS_LIST: {
    COMPLETED: 'Completed',
    INPROGRESS: 'In Progress',
    FAILURE: 'Failure',
  },

  ENDREASON_LIST: {
    AGENTS_BUSY: 'Agents Busy',
    AGENTS_OFFLINE: 'Agents Offline',
    USER_DISCONNECTED: 'User Disconnected',
    AGENT_DISCONNECTED: 'Agent Disconnected',
    CHAT_UNRESOLVED: 'Chat Unresolved',
    NO_ENROLLMENT: 'No Enrollment Issue',
    RESHIP: 'Reship',
    INTERNAL_REVIEW: 'Internal Review',
    NO_EXISTING_SR: 'No Existing SR',
    TRIAGE_SUCCESSFUL: 'Triage Successful',
    OEM_INWARRANTY_CONFIRMED: 'OEM In Warranty Confirmed',
    RESCHEDULE_DELIVERY_ENQUIRY: 'Reschedule Delivery Enquiry',
    CHANGE_DELIVERY_ADDRESS_ENQUIRY: 'Change Delivery Address Enquiry',
    CANCEL_DELIVERY_ENQUIRY: 'Cancel Delivery',
    NO_ALTERNATE_DEVICE: 'No Alternate Device',
    VOID: 'VOID',
    REQUEST_CANNOT_BE_PROCESSED: 'Request Unprocessed',
    SURVEY_SKIPPED: 'Skip Survey',
    SURVEY_SUBMITTED: 'Survey Submitted',
    HOME_PAGE: 'Back To Home Page',
    WBKO_UPDATE_CONTACT_DETAILS_ENQUIRY: 'Update Contact Details',
  },

  WBKO: 'WBKO',
  WBKO_ALL_DELIVERY_OPTIONS: 'WBKO_ALL_DELIVERY_OPTIONS',
  WBKO_TRACK_MY_DELIVERY: 'WBKO_TRACK_MY_DELIVERY',
  WBKO_UPDATE_CONTACT_DETAILS: 'WBKO_UPDATE_CONTACT_DETAILS',
  WBKO_KEEP: 'WBKO_KEEP',
  WBKO_TRACK_OR_END: 'WBKO_TRACK_OR_END',
  WBKO_CANCELLATION_REASON: 'WBKO_CANCELLATION_REASON',
  WBKO_TRACK_TRACE: 'WBKO_TRACK_TRACE',

  ASK_FOR_MDN: 'ASK_FOR_MDN',
  ASK_FOR_NRIC: 'ASK_FOR_NRIC',
  ASK_FOR_NAME: 'ASK_FOR_NAME',
  RESCHEDULE_DELIVERY_TRACK: 'Reschedule my Delivery',
  CHANGE_DELIVERY_ADDRESS_TRACK: 'Change my Delivery Address',
  CANCEL_DELIVERY_TRACK: 'Cancel my delivery',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',

  ASK_FOR_LEAVING_AIS_REASON: 'ASK_FOR_LEAVING_AIS_REASON',
  AIS_REASON_NOT_HELPFULL: 'AIS_REASON_NOT_HELPFULL',
  LOAD_AIS_SUBREASON: 'LOAD_AIS_SUBREASON',
  LOAD_THANK_YOU: 'LOAD_THANK_YOU',
  CONFIRMATION_POP_UP: 'CONFIRMATION_POP_UP',
  ACKNOWLEDGE_MSG: 'ACKNOWLEDGE_MSG',
  ACKNOWLEDGE_MSG_VIEW: 'ACKNOWLEDGE_MSG_VIEW',
  ROUTE_TO_SAVEDESK: 'ROUTE_TO_SAVEDESK',
  ROUTE_TO_REFUND: 'ROUTE_TO_REFUND',
  ENTER_OTHER_REASON: 'ENTER_OTHER_REASON',
  NOT_HELPFULL: 'not_helpfull',
  OTHER_REASON: 'other_reason',
  COST_TOO_MUCH: 'cost_too_much',
  NO_NEED_PACKAGE_AUTO_RENEWAL: 'no_need_package_auto_renewal',
  BUYING_NEW_DEVICE: 'buying_new_device',
  NOT_USING_IT_ENOUGH_OR_NOT_AT_ALL: 'not_using_it_enough_or_not_at_all',
  WINBACK: 'WinBack',
  LEAVING_AIS_POP_UP: 'LEAVING_AIS_POP_UP',

  GENERIC_IN_PROGRESS_BAR: 'GENERIC_IN_PROGRESS_BAR',

  // Progress Bar texts
  UPLOADED_FORM: 'Uploaded successfully',
  THANKS_FOR_PAYMENT: 'THANKS_FOR_PAYMENT',
  CONFIRM_UPLOAD_SR: 'CONFIRM_UPLOAD_SR',
  COD_FOR_CREDIT_CARD: 'COD_FOR_CREDIT_CARD',
  NOT_PROCEED_FURTHER: 'NOT_PROCEED_FURTHER',
  ALTERNATE_DEVICE_NOTE: 'ALTERNATE_DEVICE_NOTE',
  ALTERNATE_DEVICE_SELECTED: 'ALTERNATE_DEVICE_SELECTED',
  ASSIGN_TO_AGENT: 'ASSIGN_TO_AGENT',
  FAQ_PAGE: 'FAQ_PAGE',
  CONFIRM_EXISTING_CUSTOMER: 'CONFIRM_EXISTING_CUSTOMER',
  GE_INQUIRY_TYPE: 'Product Related',
  LEAVING_AIS_INQUIRY: 'Leaving AIS',
  LEAVING_AIS_INQUIRY_LEAVING_AIS: 'Online - Leaving AIS',
  SAVEDESK_OUTBOUND: 'Online - SaveDesk Outbound',
  SAVEDESK_OUTBOUND1: 'SaveDesk Outbound',
  SUBSCRIPTION_INQUIRY: 'Online - Subscription Details',
  STAFF_BUSY_INQUIRY: 'Online - Staff Busy',
  TECHNICAL_ERROR_INQUIRY: 'Online - 503 Service',
  VOID_MSG_INQUIRY: 'Online - SR-entitlements',
  TERMNATED_INQUIRY: 'Online - Suspended/Terminated',
  CHURN_ONLINE: 'Online Churn',
  GE_INQUIRY_FAQ: 'Online – FAQ',
  GE_INQUIRY_CHAT: 'Online – Chat',
  FAQ_INQUIRY: 'FAQ Enquiry',
  CHAT_INQUIRY: 'Chat Enquiry',
  ENQUIRY_MDN: '0000000000',
  SR_FEE_DRIVER_CAT: 'General Inquiry',
  GET_SERVICE_FEE: 'GET_SERVICE_FEE',
  GET_SERVICE_FEE_PROCEED: 'GET_SERVICE_FEE_PROCEED',
  STAFF_BUSY: 'Staff Busy',
  TERMNATED: 'Suspended/Terminated',
  TECHNICAL_ERROR: '503 Service',
  VOID_MSG: 'SR-entitlements',
  SERVICE_FEE_ENQUIRY: 'Fees',
  SERVICE_FEE_SUBTYPE: 'Check SR Fee',
  SR_FEE_DRIVER_SUB_CAT: 'Service Fees & Device Quality',
  CONFIRM_EXISTING_CUST: 'Confirm Existing Customer',
  GE_INQUIRY_REASON: 'GE_INQUIRY_REASON',
  GE_INQUIRY_REASON_SUBMITTED: 'GE_INQUIRY_REASON_SUBMITTED',
  KNOW_SERVICE_REQUEST_FEE: 'Know my service request fee',
  RESUME_EXISTING_REQUEST: 'Resume my request submission',
  ABOUT_MY_CURRENT_DELIVERY: 'About my current delivery',
  TRACK_CURRENT_DELIVERY: 'Track my device delivery',
  CHANGE_MY_DEL_ADDRESS: 'Change my delivery address',
  CANCEL_MY_DELIVERY: 'Cancel my current delivery',
  RESCHEDULE_CURRENT_DELIVERY: 'Reschedule my current delivery',
  BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED: 'BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED',
  ENQUIRY_REASON: 'Online Channel',
  GET_MOREINFO: 'GET_MOREINFO',
  ENQUIRY_SUB_REASON: {
    CHAT: 'Chat',
    FAQ: 'FAQ',
    CHANGE_ETA: 'Change ETA',
    CHANGE_DELIVERY_INFO: 'Change delivery info',
    CANCEL_SR: 'Cancel SR',
    SR_SUBMITTED: 'SR Submitted',
    SAVEDESK_OUTBOUND: 'SaveDesk Outbound',
    CHECK_SR_FEE: 'Check SR Fee',
    CHURN_ONLINE: 'Churn Online',
    TRACK_AND_TRACE: 'Track and Trace',
    LEAVING_AIS: 'Leaving AIS',
    SUBSCRIPTION: 'Subscription',
    WBKO_UPDATE_CONTACT_DETAILS: 'Update Contact Details',
    UPDATE_ENROLLED_DEVICE: 'Update enrolled device',
    STAFF_BUSY: 'Staff Busy',
    TERMNATED: 'Suspended/Terminated',
    TECHNICAL_ERROR: '503 Service',
    VOID_MSG: 'SR-entitlements',
  },
  CREATE_A_NEW_REQUEST: 'Create a new request',
  CHECK_SERVICE_FEE: 'Know my service request fee',
  ABOUT_MY_CURRENT_DELIVERY: 'About my current delivery',
  RESUME_EXISTING_REQUEST: 'Resume existing request',
  GENERAL_ENQUIRY: 'General Enquiry',
  GET_HELP_MORE_INFO: 'Get help and more info',
  LEAVE_AIS: 'I am thinking of leaving AIS Mobile Care',
  SUBSCRIPTION: 'SUBSCRIPTION',
  GET_STARREQ: 'GET_STARREQ',
  DEVICE_LIST_SUBSCRIPTION: 'DEVICE_LIST_SUBSCRIPTION',
  BACK_TO_PREV_STEP: 'Back to previous step',
  UPDATE_ENROLLED_DEVICE: 'UPDATE_ENROLLED_DEVICE',
  ENROLLED_DEVICE_ENQUIRY: 'ENROLLED_DEVICE_ENQUIRY',
  AIS_OEM_EXCHANGE: 'OEM Exchange (Authorised Service Center)',
  AIS_STOP_EXCHANGE: 'Shop Exchange',
  AIS_NO_EXCHANGE: 'No, I have not exchanged my device',
  UPDATE_ENROLLED_EQUIRY_SUCCESS: 'UPDATE_ENROLLED_EQUIRY_SUCCESS',
  ENROLLED_DEVICE_INQUIRY_SUCCESS: 'ENROLLED_DEVICE_INQUIRY_SUCCESS',
  FAST_PAYMENT_SDK_ERROR: 'FAST_PAYMENT_SDK_ERROR',
  FAST_PAYMENT_SDK_ERROR_RESET: 'FAST_PAYMENT_SDK_ERROR_RESET',
  SMART_STOCK: 'SMART_STOCK',
  DEVICE_IN_STOCK: 'DEVICE_IN_STOCK',
  DEVICE_OUT_STOCK: 'DEVICE_OUT_STOCK',
  DEVICE_NLP: 'DEVICE_NLP',
  DEVICE_NCA: 'DEVICE_NCA',

  WBKO_RESCHEDULE: 'WBKO_RESCHEDULE',
  WBKO_ALL_DELIVERY_OPTIONS_NEW: 'WBKO_ALL_DELIVERY_OPTIONS_NEW',
  WBKO_TRACK_MY_DELIVERY_NEW: 'WBKO_TRACK_MY_DELIVERY_NEW',
  WBKO_CHANGE_DELIVERY_ADDRESS_NEW: 'WBKO_CHANGE_DELIVERY_ADDRESS_NEW',
  WBKO_CHANGE_DELIVERY_SCHEDULE_NEW: 'WBKO_CHANGE_DELIVERY_SCHEDULE_NEW',
  WBKO_CANCELLATION_REASON_NEW: 'WBKO_CANCELLATION_REASON_NEW',
  WBKO_CANCELLATION_CONFIRM: 'WBKO_CANCELLATION_CONFIRM'
};

export default CONSTANTS;
