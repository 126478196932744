import {
  INIT_APP_SUCCESS,
  INIT_APP_FAILURE,
  INIT_INTERACTION_SUCCESS,
  INIT_INTERACTION_FAILURE,
  FIND_AGREEMENTS_INIT,
  FIND_AGREEMENTS_SUCCESS,
  FIND_AGREEMENTS_FAILURE,
  SAVE_USER_MDN,
  RESUME_CANCELED,
  SET_INPUT_STATE,
  SET_VERIFICATION_PROGRESS,
  RESET_VERIFICATION_PROGRESS,
  SET_PROCESS_INCIDENT_PROGRESS,
  DETEREMINE_INCIDENT_PATH_INIT,
  DETEREMINE_INCIDENT_PATH_SUCCESS,
  DETEREMINE_INCIDENT_PATH_FAILURE,
  CREATE_NEW_SR_INIT,
  CREATE_NEW_SR_SUCCESS,
  CREATE_NEW_SR_FAILURE,
  RESUME_SR_INIT,
  RESUME_SR_SUCCESS,
  RESUME_SR_FAILURE,
  VERIFY_NRIC_INIT,
  VERIFY_NRIC_SUCCESS,
  VERIFY_NRIC_FAILURE,
  CANCEL_SR_SUCCESS,
  PROCESS_INCIDENT_INIT,
  PROCESS_INCIDENT_SUCCESS,
  PROCESS_INCIDENT_SUCCESS_VOID,
  PROCESS_INCIDENT_FAILURE,
  FULFILLMENT_INIT,
  FULFILLMENT_SUCCESS,
  FULFILLMENT_FAILURE,
  GET_FULFILLMENT_INIT,
  GET_FULFILLMENT_SUCCESS,
  GET_FULFILLMENT_FAILURE,
  GET_REPLACEMENT_INIT,
  GET_REPLACEMENT_SUCCESS,
  GET_REPLACEMENT_FAILURE,
  SHIPPING_METHODS_INIT,
  SHIPPING_METHODS_SUCCESS,
  SHIPPING_METHODS_FAILURE,
  SHIPPING_METHODS_V2_INIT,
  SHIPPING_METHODS_V2_SUCCESS,
  SHIPPING_METHODS_V2_FAILURE,
  SAVE_ESTIMATED_DELIVERY_DATE,
  PAYMENT_INIT,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  GET_PCI_TOKEN_INIT,
  GET_PCI_TOKEN_SUCCESS,
  GET_PCI_TOKEN_FAILURE,
  GET_SHIPPING_ORDER_INIT,
  GET_SHIPPING_ORDER_SUCCESS,
  GET_SHIPPING_ORDER_FAILURE,
  CHARGE_ORDER_INIT,
  CHARGE_ORDER_SUCCESS,
  CHARGE_ORDER_FAILURE,
  UPDATE_SHIPPING_ORDER_INIT,
  UPDATE_SHIPPING_ORDER_SUCCESS,
  UPDATE_SHIPPING_ORDER_FAILURE,
  GET_STANDARDISEADDRESS_INIT,
  GET_STANDARDISEADDRESS_SUCCESS,
  GET_STANDARDISEADDRESS_FAILURE,
  PROCESS_PAYMENT_INIT,
  PROCESS_PAYMENT_SUCCESS,
  PROCESS_PAYMENT_FAILURE,
  SUBMIT_ORDER_INIT,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILURE,
  EVALUATE_IMEI_INIT,
  EVALUATE_IMEI_SUCCESS,
  EVALUATE_IMEI_FAILURE,
  SET_CASE_TYPE,
  SAVE_DEFECT_OPTION,
  SET_TRIAGE_DISPLAY_COUNT,
  SERVICE_ORDER_INIT,
  SERVICE_ORDER_SUCCESS,
  SERVICE_ORDER_FAILURE,
  SAVE_REPLACEMENT_OPTION,
  SAVE_CONTACT_NO,
  SAVE_EMAIL_ID,
  UPDATE_CONTACT_DETAILS,
  UPDATE_CONTACT_DETAILS_SUCCESS,
  UPDATE_CONTACT_DETAILS_FAILURE,
  GET_CONTACT_DETAILS,
  GET_CONTACT_DETAILS_SUCCESS,
  GET_CONTACT_DETAILS_FAILURE,
  POST_CC_DETAILS_TO_BG_SUCCESS,
  SAVE_DELIVERY_DATE,
  SAVE_DELIVERY_SPEED,
  SAVE_DEFAULT_DELIVERY_SLOT,
  SAVE_DELIVERY_SLOT,
  SAVE_ESTIMATED_SHIPPMENT_DATE,
  SET_PAYMENT_RETRY_ATTEMPT,
  SET_PAYMENT_CHARGE_ATTEMPT,
  SET_ADDRESS_RETRY_ATTEMPT,
  SET_VISITOR_ID,
  GET_DEVICE_LIST_INIT,
  GET_DEVICE_LIST_SUCCESS,
  GET_DEVICE_LIST_FAILURE,
  GET_MAKE_LIST_INIT,
  GET_MAKE_LIST_SUCCESS,
  GET_MAKE_LIST_FAILURE,
  SAVE_ADDRESS_VALIDATION_ATTEMPT_COUNT,
  SAVE_SELECTED_DEVICE,
  SUBMIT_SURVEY_INIT,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_FAILURE,
  GET_SURVEY_INIT,
  GET_SURVEY_SUCCESS,
  GET_SURVEY_FAILURE,
  SAVE_CUSTOMERNAME,
  SET_LEX_PROGRESS,
  UPLOAD_DOCUMENT_INIT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  END_INTERACTION_INIT,
  END_INTERACTION_SUCCESS,
  END_INTERACTION_FAILURE,
  SAVE_CONVERSATION,
  SET_CHAT_REQUEST_ID,
  SET_USER_CANCEL_FLAG,
  SET_TRIAGE_SUCCESSFUL_FLAG,
  BACK_ORDER_CASE,
  MISMATCHED_DEVICE_DETAILS,
  SAVE_CC_DETAILS,
  CREDIT_CARD_ATTEMPT,
  PAYMENT_CHARGE_ATTEMPT,
  SAVE_DEVICE_DETAILS,
  SAVE_SELECTED_ENQUIRY_OPTION,
  SAVE_OFFERED_DEVICE_IMG,
  SAVE_CHANGED_ADDRESS,
  UPLOADED_FILE_NAMES,
  SAVE_SELECTED_MAKE_MODEL,
  SAVE_JOURNEY_COMPLETED,
  SAVE_ENQUIRY_REASON,
  SAVE_SERVICE_REQUEST_ID,
  SAVE_HOLD_LOGGED,
  SET_TRANSITION_PROGRESS,
  SET_GENERIC_PROGRESS_BAR,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAILURE,
  GET_PROVINCE,
  SET_CHAT_REASON,
  SAVE_IMEI_MISMATCH,
  SAVE_SIX_DIGITS_IMEI,
  SAVE_GENERAL_ENQUIRY_DETAILS,
  CANCEL_BUTTON_CLICKED,
  SAVE_CUSTOMER_CASE_NUMBER,
  EDIT_BUTTON_CLICKED,
  SAVE_SR_DOCUMENT,
  TRIAGE_RESOLVED_SUCCESS,
  SAVE_PROCESSINCIDENT_PARAMS,
  GET_SR,
  GET_SR_SUCCESS,
  CLEAR_AGREEMENT,
  INCREASE_CAROUSALCOUNT,
  INCREASE_CAROUSALCOUNT_FOR_SR,
  GET_SR_FAILURE,
  SAVE_VISITSOURCE,
  SAVE_SELECTED_ASSET_INSTANCE,
  CREATE_CHAT_ENQUIRY,
  CREATE_CHAT_ENQUIRY_SUCCESS,
  CREATE_CHAT_ENQUIRY_FAILURE,
  SELECTED_LEAVING_AIS_REASON,
  SELECTED_CALLBACK_DATE,
  SELECTED_CALLBACK_TIME,
  CALLBACK_COMMENT,
  LOAD_THANK_YOU,
  STAY_WITH_AIS,
  SAVE_1,
  SAVE_2,
  CHURN_SAVEDESK_ALTERNATE_CONTACT_NUMBER,
  ENTER_OTHER_REASON,
  WBKO,
  WBKO_SAVE_FIRST_NAME,
  WBKO_SAVE_MOBILE_NUMBER,
  WBKO_SAVE_ID_NUMBER,
  WBKO_KEEP_OR_CONFIRM_COUNT,
  WBKO_CANCELLATION_REASON,
  SAVE_CHANGED_ADDRESS_STRING,
  WBKO_TRACK_TRACE,
  SET_SELECTED_ASSET,
  VERIFY_AGREEMENT_INIT,
  VERIFY_AGREEMENT_SUCCESS,
  VERIFY_AGREEMENT_FAILURE,
  SAVE_SHIPPING_ADDDRESS,
  SELECTED_ASSET_PROGRAM_NAME,
  CUSTOMER_CASE_GRAPH_INIT,
  CUSTOMER_CASE_GRAPH_SUCCESS,
  CUSTOMER_CASE_GRAPH_FAILURE,
  GET_ALL_SR_SUCCESS,
  SAVE_SELECTED_SR_ASSET_ID,
  SERVICE_UNAVAILABLE_COUNT,
  UPDATED_DEVICE_DATA,
  SMART_STOCK_SELECTED,
} from '../../actions/Dashboard/actionTypes';
import _ from 'lodash';
import CONSTANTS from '../../utils/Constants';
import {
  createDetermineIncidentObj,
  getFormattedUserName,
  determineUserIntent,
  getLang,
  processRescheduleArray,
} from '../../utils/utils';
import maskString from '../../utils/mask-string';

let initialState = {
  errors: [],
  active: null,
  IpAddress: null,
  InteractionLineId: null,
  clientData: {},
  generalEnquiryOptions: {},
  creditCardName: '',
  deliveryDate: null,
  deliveryWindow: null,
  serviceRequestNumber: '',
  newserviceRequestNumber: '',
  loading: null,
  inWarrantyRemarks: '',
  agreementFound: null,
  noServiceFee: false,
  reverseOrderWarranty: false,
  simRequired: false,
  resumeCanceled: false,
  creditCardReAttempt: 1,
  paymentChargeAttempt: 1,
  newServiceRequestCreated: 0,
  ccmask: null,
  surveyQuestions: null,
  nameVerified: null,
  mismatchedDeviceDetails: {},
  verifiedNRIC: null,
  inputState: null,
  deviceDetails: null,
  LFLDeviceInStock: false,
  NLFLDeviceInStock: false,
  LFLDevice: {},
  resumePoint: null,
  deteremineResumePoint: null,
  language: getLang(),
  customerName: { firstName: 'User', lastName: '', fullName: 'User ' },
  warehouseId: null,
  triageQuestionsDisplayed: 0,
  warrantyType: null,
  processIncidentData: null,
  pciToken: null,
  referenceId: null,
  caseType: null,
  customerCase: {},
  selectedDefectOption: null,
  selectedReplacementDevice: {},
  returnCode: null,
  encryptionKey: null,
  verificationProgressValue: 0,
  ccdetailsProgressValue: 0,
  paymentProgressValue: 0,
  lexProgressValue: 0,
  triageSuccesfulFlag: false,
  checkAvailableDeviceProgress: 0,
  addressValidationLoaderValue: 0,
  agreementFailedAttempt: 0,
  verificationFailedAttempt: 0,
  nameVerificationAttempt: 0,
  imeiVerificationAttempt: 0,
  customerContact: '',
  serviceRequestType: null,
  resumeServiceRequestResult: {},
  replacementDevices: null,
  baseItemId: null,
  serviceFee: null,
  emailAddress: null,
  phoneNumber: null,
  postalCode: null,
  enrolledPostalCode: null,
  chargeOrderDetails: null,
  shippingOrderResult: null,
  updateShippingOrderResult: null,
  addressList: {},
  selectedAddress: null,
  shippingMethodsResult: null,
  customerNewContact: '',
  customerNewEmail: '',
  selectedDeliverySlot: null,
  selectedDeliveryDate: null,
  agentApprovedPaymentRetry: false,
  agentApprovedPaymentChargeAttempt: false,
  assetDetails: null,
  makeAndModelList: null,
  selectedBrand: null,
  enquiryDetails: {},
  addressValidationAttemptCount: null,
  processIncidentProgress: 0,
  holds: null,
  voidScript: null,
  selectedModel: null,
  submittedSurveyResult: null,
  uploadProgressValue: 0,
  endInteraction: false,
  conversation: '',
  requestId: '',
  userCancelled: false,
  backOrderCase: false,
  deviceNotAvailable: false,
  selectedDeviceDetails: '',
  offeredDeviceImgUrl: '',
  changedAddress: null,
  uploadedFileNames: null,
  slectedMakeModel: null,
  selectedEnquiryOption: null,
  inquiryReason: null,
  serviceRequestId: null,
  newServiceRequestId: null,
  serviceOrderId: null,
  customerCaseNumber: null,
  customerCaseId: null,
  correlationId: null,
  clientAccountId: null,
  agreementId: null,
  clientChannelId: null,
  imeiMismatch: false,
  lastSixDigitsImei: '',
  serviceOrderData: null,
  processPaymentResults: null,
  backEnabled: true,
  // Progress Bar related states only
  progressBarValue: 0,
  trickleStep: 0,
  trickleInterval: 0,
  cancelSR: false,
  filesList: null,
  enrolledAsset: null,
  replacedAsset: null,
  replacementFee: null,
  swapFee: null,
  assetInstance: null,
  // Transition Panel
  transition: {
    progress: 0,
    progressBar: '0%',
    stageName: '',
    completedStages: [],
  },
  isPreviousClaimCompleted: false,
  wbko: false,
  WbkoChangedAddressString: null,
  keepOrconfirmCount: 0,
  serviceFeeResponse: [],
  carousalLoadCount: 0,
  carousalLoadCountForSR: 0,
  selectedAsset: {},
  serviceUnavailableCount: 0,
};

function findAgreements(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSITION_PROGRESS:
      let { resetTransitionStagesFromStore = false } = action;
      return Object.assign({}, state, {
        transition: {
          progress: action.progress,
          progressBar: `${action.progress}%`,
          stageName: action.stageName,
          completedStages: resetTransitionStagesFromStore
            ? []
            : action.subStageText || action.userText
            ? [`${action.subStageText} ${action.userText}`, ...state.transition.completedStages]
            : [...state.transition.completedStages],
        },
      });
    /** START api/v2/initialize **/
    case INIT_APP_SUCCESS:
      let inquiryTypeList =
        action.clientData.Masterdata &&
        _.filter(action.clientData.Masterdata.InquiryType, (inquiryType) => {
          return _.indexOf(CONSTANTS.ALLOWED_GENERAL_ENQUIRY_TYPES, inquiryType.InquiryType) > -1;
        });
      return Object.assign({}, state, {
        clientData: action.clientData,
        generalEnquiryOptions: inquiryTypeList || [],
        errors: [],
      });
    case INIT_APP_FAILURE:
      return Object.assign({}, state, {
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });
    /** END api/v2/initialize **/

    /** START api/v2/interaction **/
    case INIT_INTERACTION_SUCCESS:
      return Object.assign({}, state, {
        active: action.data.Active,
        InteractionLineId: action.data.InteractionLineId,
        IpAddress: action.data.SourceIPAddress,
        errors: [],
      });
    case INIT_INTERACTION_FAILURE:
      return Object.assign({}, state, {
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });
    /** END api/v2/interaction **/

    case SET_INPUT_STATE:
      return Object.assign({}, state, {
        inputState: action.inputState,
      });
    case SET_CASE_TYPE:
      return Object.assign({}, state, {
        caseType: _.upperCase(action.caseType),
      });
    case SET_TRIAGE_SUCCESSFUL_FLAG:
      return Object.assign({}, state, {
        triageSuccessfulFlag: action.payload,
      });
    case SET_PAYMENT_RETRY_ATTEMPT:
      return Object.assign({}, state, {
        agentApprovedPaymentRetry: true,
        creditCardReAttempt: 1,
      });
    case SET_PAYMENT_CHARGE_ATTEMPT:
      return Object.assign({}, state, {
        paymentChargeAttempt: 1,
        agentApprovedPaymentChargeAttempt: true,
      });
    case SET_ADDRESS_RETRY_ATTEMPT:
      return Object.assign({}, state, {
        addressRetryAttempt: action.addressRetryAttempt,
        addressValidationAttemptCount: null,
      });
    case SET_VISITOR_ID:
      return Object.assign({}, state, {
        visitorId: action.visitorId,
      });
    case SAVE_REPLACEMENT_OPTION:
      return Object.assign({}, state, {
        selectedReplacementDevice: action.device || state.selectedReplacementDevice,
      });
    case SAVE_VISITSOURCE:
      return Object.assign({}, state, {
        visitSource: action.payload,
      });
    case SAVE_SELECTED_ASSET_INSTANCE:
      return Object.assign({}, state, {
        assetInstance: action.payload,
      });
    case CREDIT_CARD_ATTEMPT:
      return Object.assign({}, state, {
        creditCardReAttempt: action.payload,
      });
    case PAYMENT_CHARGE_ATTEMPT:
      return Object.assign({}, state, {
        paymentChargeAttempt: action.payload,
      });
    case SET_VERIFICATION_PROGRESS:
      return Object.assign({}, state, {
        verificationProgressValue: action.progressValue,
      });
    case SET_PROCESS_INCIDENT_PROGRESS:
      return Object.assign({}, state, {
        processIncidentProgress: action.payload,
      });
    case SET_LEX_PROGRESS:
      return Object.assign({}, state, {
        lexProgressValue: action.payload,
      });
    case RESET_VERIFICATION_PROGRESS:
      return Object.assign({}, state, {
        verificationProgressValue: action.progressValue,
      });

    /** START api/findagreements **/
    case FIND_AGREEMENTS_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case FIND_AGREEMENTS_SUCCESS:
      let {
        AgreementFound,
        EnrolledName,
        NameVerified,
        AgreementFailedAttempt,
        NameFailedAttempt,
        correlationid,
        ClientAccountId,
        AgreementId,
        ClientChannelId,
        ClientOffer,
        EnrolledAddress: enrolledAddress = {},
      } = action.agreementRecord;

      // let customerName = EnrolledName.split(" ") || [];

      return Object.assign({}, state, {
        ClientOffer: ClientOffer,
        agreementFound: AgreementFound,
        enrolledCustomerName: EnrolledName,
        customerName:
          (NameVerified && EnrolledName && getFormattedUserName(EnrolledName)) ||
          state.customerName,
        nameVerified: NameVerified,
        agreementFailedAttempt: AgreementFailedAttempt,
        nameVerificationAttempt: NameFailedAttempt,
        correlationId: correlationid,
        clientAccountId: ClientAccountId,
        agreementId: AgreementId,
        enrolledAddress,
        clientChannelId: ClientChannelId,
        errors: [],
        loading: false,
        // deviceDetails: action.agreementRecord.ClaimedAsset || state.deviceDetails,
      });
    case FIND_AGREEMENTS_FAILURE:
      return Object.assign({}, state, {
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
        loading: false,
      });
    /** END api/findagreements **/

    case SAVE_USER_MDN:
      return Object.assign({}, state, {
        customerContact: action.customerContact || state.customerContact,
        errors: [],
      });
    case SAVE_JOURNEY_COMPLETED:
      return Object.assign({}, state, {
        journeyCmpltd: action.journeyCmpltd,
        journeyStatus: action.journeyStatus,
        errors: [],
      });

    case RESUME_CANCELED:
      return Object.assign({}, state, {
        resumeCanceled: action.payload,
      });
    case SAVE_DEVICE_DETAILS:
      return Object.assign({}, state, {
        selectedDeviceDetails: action.payload,
      });
    case SAVE_SELECTED_ENQUIRY_OPTION:
      return Object.assign({}, state, {
        selectedEnquiryOption: action.payload,
      });
    case SAVE_PROCESSINCIDENT_PARAMS:
      return Object.assign({}, state, {
        processIncidentData: action.payload,
      });
    case SAVE_GENERAL_ENQUIRY_DETAILS:
      return Object.assign({}, state, {
        enquiryDetails: action.payload,
      });
    case SAVE_CHANGED_ADDRESS:
      return Object.assign({}, state, {
        changedAddress: action.payload,
      });
    case SAVE_SR_DOCUMENT:
      return Object.assign({}, state, {
        filesList: action.payload,
      });

    case UPLOADED_FILE_NAMES:
      return Object.assign({}, state, {
        uploadedFileNames: action.payload,
      });
    case SAVE_SELECTED_MAKE_MODEL:
      return Object.assign({}, state, {
        slectedMakeModel: action.payload,
      });
    case SAVE_OFFERED_DEVICE_IMG:
      return Object.assign({}, state, {
        offeredDeviceImgUrl: action.payload,
      });
    case SAVE_CONTACT_NO:
      return Object.assign({}, state, {
        customerNewContact: action.payload || state.customerNewContact,
        errors: [],
      });
    case BACK_ORDER_CASE:
      return Object.assign({}, state, {
        backOrderCase: action.payload,
        deviceNotAvailable: true,
      });
    case SAVE_CUSTOMERNAME:
      return Object.assign({}, state, {
        customerName: getFormattedUserName(action.payload),
      });
    case SAVE_SERVICE_REQUEST_ID:
      return Object.assign({}, state, {
        serviceRequestId: action.payload,
      });
    case SAVE_DELIVERY_DATE:
      return Object.assign({}, state, {
        selectedDeliveryDate: action.selectedDeliveryDate,
        errors: [],
      });
    case SAVE_DELIVERY_SPEED:
      return Object.assign({}, state, {
        selectedDeliverySpeed: action.selectedDeliverySpeed,
      });
    case SAVE_DEFAULT_DELIVERY_SLOT:
      return Object.assign({}, state, {
        defaultDeliverySlot: action.defaultDeliverySlot,
        errors: [],
      });
    case SAVE_DELIVERY_SLOT:
      return Object.assign({}, state, {
        selectedDeliverySlot: action.selectedDeliverySlot,
        errors: [],
      });
    case SAVE_ESTIMATED_SHIPPMENT_DATE:
      return Object.assign({}, state, {
        estimatedShipmentDate: action.estimatedShipmentDate,
        errors: [],
      });
    case SAVE_ENQUIRY_REASON:
      return Object.assign({}, state, {
        inquiryReason: action.payload,
        errors: [],
      });

    case CANCEL_BUTTON_CLICKED:
      return Object.assign({}, state, {
        cancelSR: action.payload,
      });

    case EDIT_BUTTON_CLICKED:
      return Object.assign({}, state, {
        backEnabled: action.payload,
      });

    case SAVE_CUSTOMER_CASE_NUMBER:
      return Object.assign({}, state, {
        customerCase: action.payload,
      });
    case SAVE_EMAIL_ID:
      return Object.assign({}, state, {
        customerNewEmail: action.payload,
        errors: [],
      });
    case VERIFY_NRIC_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
        verificationProgressValue: 0,
      });
    case VERIFY_NRIC_SUCCESS:
      let { VerifyCustomerResults: { VerificationOutcome = false } = {} } = action.data;
      let Platform =
        action.data.Platform === 'SaveDesk1' ||
        action.data.Platform === 'SaveDesk2' ||
        action.data.Platform === 'SaveDesk3' ||
        action.data.Platform === 'SaveDesk4'
          ? 'SaveDesk'
          : 'Digital';
      const { enrolledAsset, Agreement } = action.data;
      let Make = '',
        Model = '';
      try {
        if (Agreement) {
          Make = Agreement.ClaimedAsset.Make.Name;
          Model = Agreement.ClaimedAsset.Model.Name;
        } else {
          Make = enrolledAsset.Make.Name;
          Model = enrolledAsset.Model.Name;
        }
      } catch (e) {
        Make = '';
        Model = '';
      }
      const subscriptionDeviceImgName = `${Make.toUpperCase()}_${Model.toUpperCase()}_S.jpg`;
      return Object.assign({}, state, {
        ServiceEffectiveEndDate: action.data.ServiceEffectiveEndDate,
        PlatformKey: action.data.Platform,
        Platform: Platform,
        IsRefund: action.data.IsRefund,
        RefundType: action.data.RefundType,
        IsMonthly: action.data.IsMonthly,
        EnrolledName: action.data && action.data.Agreement && action.data.Agreement.EnrolledName,
        verifiedNRIC: VerificationOutcome === 'Verified' ? true : false,
        verificationFailedAttempt: action.data.VerificationFailedAttempt,
        emailAddress: action.data.EmailAddress,
        phoneNumber: action.data.PhoneNumber,
        postalCode: action.data.PostalCode,
        enrolledPostalCode: action.data.PostalCode,
        errors: [],
        deviceDetails: enrolledAsset,
        enrolledAsset: enrolledAsset,
        replacedAsset: action.data.replacedAsset,
        // deviceDetails: action.data.enrolledAsset || state.deviceDetails,
        loading: false,
        enrolledAddress:
          action.data.Agreement &&
          action.data.Agreement[0] &&
          action.data.Agreement[0].EnrolledAddress,
        clientAccountId: action.data.Agreement && action.data.Agreement.ClientAccountId,
        agreementId: action.data.Agreement && action.data.Agreement.AgreementId,
        clientChannelId: action.data.Agreement && action.data.Agreement.ClientChannelId,
        ISCOD: action.data.ISCOD || false,
        subscriptionDeviceImgName,
        Agreement,
      });
    case VERIFY_NRIC_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        deviceDetails: null,
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });
    case DETEREMINE_INCIDENT_PATH_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
        backToSrOptionPage: false,
      });

    case DETEREMINE_INCIDENT_PATH_SUCCESS:
      let summarySimRequired = false;
      let {
        payload,
        payload: {
          IncidentType,
          Type = null,
          CustomerCaseId,
          PaymentMethodType = '',
          UpdatedDate = '',
          ServiceOrderId = '',
          ExpectedDeliveyDate,
          ServiceRequestId = '',
          ShippingOrderId = '',
          ShippingMethodType = '',
          ServiceRequestNumber: serviceRequestNumber = '',
          EnrolledCustomerName,
          ClaimedAsset = {},
          ClaimedAsset: { Model: claimedDeviceModel = '' } = {},
          CustomerCaseResult = {},
          CustomerCaseResult: {
            selectedReplacementDevice = {},
            selectedReplacementDevice: {
              Accessories: { Accessory = [] } = {},
              Servicefee: customerCaseServiceFee = null,
            } = state.selectedReplacementDevice,
            Servicefee: ServiceFee = {},
          } = {},
        } = {},
      } = action;

      let {
        Servicerequests: lastShippedRequest = [],
        CustomerCaseNumber: lastOrderCustomerCaseNumber,
        isPreviousClaimCompleted = false,
      } = payload;

      lastShippedRequest = lastShippedRequest && lastShippedRequest[0];
      let lastChargeOrder =
        lastShippedRequest && lastShippedRequest.ChargeOrder && lastShippedRequest.ChargeOrder[0];
      let lastServiceOrder = lastShippedRequest && lastShippedRequest.ServiceOrder;
      let lastShippedAddress = lastShippedRequest && lastShippedRequest.ShippingOrder;
      let { CustomerCaseNumber, SRAssetCatalogName } = CustomerCaseResult;
      if (!_.isEmpty(selectedReplacementDevice) && !_.isEmpty(Accessory)) {
        summarySimRequired = _.find(
          Accessory,
          (item) => item.SCMItemInformation.Description.indexOf('SIM Card') > -1
        );
        summarySimRequired = !_.isEmpty(summarySimRequired) || summarySimRequired;
      }

      if (
        (!_.isEmpty(lastShippedRequest) && state.isTrackOptionSelected) ||
        (!_.isEmpty(lastShippedRequest) && state.isWbkoSelected)
      ) {
        // transform response
        lastShippedRequest.EnrolledDevice.AssetCatalog['AssetCatalogName'] =
          lastShippedRequest.EnrolledDevice.AssetCatalog.Name;
        lastShippedRequest.ReplacedDevice.AssetCatalog['AssetCatalogName'] =
          lastShippedRequest.ReplacedDevice.AssetCatalog.Name;

        return Object.assign({}, state, {
          ShippingAddress: lastShippedAddress.Address,
          loading: false,
          serviceRequestId: ServiceRequestId,
          PaymentMethodType: lastChargeOrder && lastChargeOrder.PaymentMethodType,
          UpdatedDate: lastChargeOrder && lastChargeOrder.UpdatedDate,
          serviceOrderId:
            lastShippedRequest.ServiceOrderId ||
            (lastServiceOrder && lastServiceOrder.ServiceOrderId),
          shippingMethodsResult: Object.assign(
            {},
            {
              EstimatedDeliveryDate: lastShippedRequest.ShippingOrder.ExpectedDeliveryDate,
              ShippingMethodType: lastShippedRequest.ShippingOrder.ShippingMethodType,
            }
          ),
          customerName: getFormattedUserName(EnrolledCustomerName) || state.customerName,
          customerCaseId: lastShippedRequest.CustomerCaseId || state.customerCaseId,
          //serviceOrderId: lastShippedRequest.ServiceOrderId || state.serviceOrderId,
          expectedDeliveyDate: ExpectedDeliveyDate,
          serviceRequestNumber:
            lastShippedRequest.serviceRequestNumber || state.serviceRequestNumber,
          customerCaseNumber: lastOrderCustomerCaseNumber || state.customerCaseNumber,
          determineIncidentResponse: createDetermineIncidentObj(payload),
          lastShippedRequest: lastShippedRequest,
          serviceRequestType: lastShippedRequest.ServiceRequestType || state.serviceRequestType,
          serviceRequestStatus:
            lastShippedRequest.ServiceRequestStatus || state.serviceRequestStatus,
          shippingStatus: lastShippedRequest.ShippingOrder.ShippingStatus || state.shippingStatus,
          isPreviousClaimCompleted: isPreviousClaimCompleted,
          // holds: state.holds,
          backOrderCase: lastShippedRequest.ShippingOrder.ShippingStatus === 'BORD',
          deteremineResumePoint: state.deteremineResumePoint,
          customerCase: state.customerCase,
          caseType: lastShippedRequest.CoveredEvent || state.caseType,
          serviceFee: customerCaseServiceFee || state.serviceFee,
          noServiceFee: lastShippedRequest.ChargeOrder.TotalAmount === 0 || state.serviceFee,
          // simRequired: summarySimRequired || state.simRequired,
          postalCode: lastShippedRequest.ShippingOrder.Address.PostalCode || state.postalCode,
          serviceOrderStatus:
            lastShippedRequest.ServiceOrder.ServiceOrderStatus || state.serviceOrderStatus,
          hasUnresolvedHolds: lastShippedRequest.ServiceRequestType === 'WaitForHoldResolution',
          selectedReplacementDevice: lastShippedRequest.ReplacedDevice || state.deviceDetails,
          // NLFLDeviceInStock: NotLikeForLikeDeviceIsInStock,
          deviceDetails: lastShippedRequest.ReplacedDevice || state.deviceDetails,
          errors: [],
        });
      } else {
        return Object.assign({}, state, {
          ShippingMethodType: ShippingMethodType,
          ShippingOrderId: ShippingOrderId,
          OldShippingAddress: action.payload.ShippingAddress,
          ShippingAddress: action.payload.ShippingAddress,
          PaymentMethodType: PaymentMethodType,
          UpdatedDate: UpdatedDate,
          loading: false,
          serviceRequestId: ServiceRequestId,
          customerName: getFormattedUserName(EnrolledCustomerName) || state.customerName,
          customerCaseId: CustomerCaseId,
          serviceRequestNumber,
          serviceOrderId: ServiceOrderId,
          expectedDeliveyDate: ExpectedDeliveyDate,
          customerCaseNumber: CustomerCaseNumber || payload.CustomerCaseNumber,
          determineIncidentResponse: Object.assign({}, payload),
          serviceRequestType: payload.Type,
          serviceRequestStatus: payload.ServiceRequestStatus,
          shippingStatus: payload.ShippingStatus,
          isPreviousClaimCompleted: isPreviousClaimCompleted,
          holds: payload.Holds || null,
          backOrderCase:
            payload.ShippingStatus === 'BORD' || CustomerCaseResult.ServiceOrderStatus === 'BORD',
          deteremineResumePoint: CustomerCaseResult.Actions || state.deteremineResumePoint,
          customerCase: CustomerCaseResult || state.customerCase,
          caseType: CustomerCaseResult.IncidentType || IncidentType || state.caseType,
          serviceFee: customerCaseServiceFee || ServiceFee,
          noServiceFee: customerCaseServiceFee
            ? Math.round(parseInt(customerCaseServiceFee.TotalAmount)) === 0
            : ServiceFee
            ? Math.round(parseInt(ServiceFee.TotalAmount)) === 0
            : state.serviceFee,
          shippingMethodsResult: CustomerCaseResult.ShippingMethod || state.shippingMethodsResult,
          simRequired: summarySimRequired || state.simRequired,
          postalCode: CustomerCaseResult.PostalCode || state.postalCode,
          serviceOrderStatus: CustomerCaseResult.ServiceOrderStatus || state.serviceOrderStatus,
          hasUnresolvedHolds: Type === 'WaitForHoldResolution',
          selectedReplacementDevice: CustomerCaseResult.selectedReplacementDevice
            ? CustomerCaseResult.selectedReplacementDevice
            : SRAssetCatalogName
            ? { AssetCatalogName: SRAssetCatalogName }
            : state.deviceDetails
            ? state.deviceDetails
            : state.selectedReplacementDevice,
          // NLFLDeviceInStock: NotLikeForLikeDeviceIsInStock,
          // deviceDetails:
          //   payload.Type === "StartServiceRequest"
          //     ? state.enrolledAsset
          //     : state.deviceDetails,
          // selectedReplacementDevice: CustomerCaseResult.selectedReplacementDevice || state.selectedReplacementDevice || finalDevice,
          errors: [],
        });
      }

    case DETEREMINE_INCIDENT_PATH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });
    case CREATE_NEW_SR_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
        newServiceRequestCreated: 1,
      });
    case CREATE_NEW_SR_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        customerCase: action.payload,
        serviceRequestNumber: action.payload.ServiceRequestNumber,
        customerCaseNumber: action.payload.CustomerCaseNumber,
        serviceRequestId: action.payload.ServiceRequestId,
        customerCaseId: action.payload.CustomerCaseId,
        // deviceDetails: state.enrolledAsset,
        errors: [],
      });
    case CREATE_NEW_SR_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });
    case CANCEL_SR_SUCCESS:
      return Object.assign({}, state, {
        resumeCanceled: true,
        backOrderCase: false,
        selectedReplacementDevice: {},
        repairRequest: undefined,
        chargeOrder: undefined,
      });
    case SERVICE_ORDER_INIT:
      return Object.assign({}, state, {
        loading: action.payload.loading,
        simRequired: action.payload.IsSimCard,
      });
    case SERVICE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceOrderData: action.payload,
        serviceOrderId: action.payload.ServiceOrderId,
        errors: [],
      });
    case SERVICE_ORDER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });
    case RESUME_SR_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
        isResumeScenario: true,
      });
    case RESUME_SR_SUCCESS:
      let {
        Actions: resumePoint = state.resumePoint,
        Servicefee: serviceFee = {},
        ReceiverAddress = state.standardizedAddress,
        selectedReplacementDevice: replacementDevice = {},
        PostalCode: postalCode = state.postalCode,
        ShippingMethod: shippingMethodsResult = {},
        selectedReplacementDevice: {
          Accessories: { Accessory: accessoryList = [] } = {},
        } = state.selectedReplacementDevice,
        ServiceOrderStatus: serviceOrderStatus = '',
        ServiceOrderId: serviceOrderId = '',
      } = action.payload;

      if (ReceiverAddress) {
        ReceiverAddress['Country'] =
          ReceiverAddress.CountryCode === 'TH' ? 'Thailand' : ReceiverAddress.CountryCode;
      }

      let simRequired = false;
      if (!_.isEmpty(replacementDevice) && !_.isEmpty(accessoryList)) {
        _.forEach(accessoryList, function (item) {
          simRequired =
            item.SCMItemInformation.Description.indexOf('SIM') > -1 ? true : simRequired;
        });
      }

      return Object.assign({}, state, {
        loading: false,
        resumeServiceRequestResult: action.payload,
        resumePoint,
        serviceFee,
        noServiceFee: !_.isEmpty(action.payload.Servicefee)
          ? Math.round(parseInt(action.payload.Servicefee.TotalAmount)) === 0
          : state.noServiceFee,
        shippingMethodsResult,
        postalCode,
        standardizedAddress: ReceiverAddress,
        backOrderCase: serviceOrderStatus === 'BORD' || state.backOrderCase,
        serviceOrderStatus,
        serviceOrderId,
        selectedReplacementDevice: replacementDevice || state.selectedReplacementDevice,
        selectedReplacementDeviceFromDetermine: state.selectedReplacementDevice,
        simRequired,
        errors: [],
      });
    case RESUME_SR_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });
    case PROCESS_INCIDENT_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
        processIncidentProgress: 0,
      });
    case PROCESS_INCIDENT_SUCCESS:
      let {
        CustomerCase: {
          CustomerCaseNumber: customerCaseNumber,
          Holds = [],
          Eligibility: { EligibilityCriteriaStatus = null } = {},
        } = {},
        CustomerCase = {},
      } = action.processIncidentData;
      return Object.assign({}, state, {
        loading: false,
        processIncidentData: CustomerCase,
        customerCase: Object.assign({}, state.customerCase, {
          CustomerCaseNumber: customerCaseNumber,
        }),
        customerCaseNumber,
        holds: Holds.length ? Holds : null,
        warrantyType: EligibilityCriteriaStatus,
        voidScript: CustomerCase.Void,
        errors: [],
      });
    case PROCESS_INCIDENT_SUCCESS_VOID:
      let {
        CustomerCase: processIncidentData = {},
        CustomerCase: { Holds: voidHolds = [], ServiceRequest: { WarrantyType = null } = {} } = {},
      } = action.processIncidentData;
      return Object.assign({}, state, {
        loading: false,
        processIncidentProgress: 100,
        checkAvailableDeviceProgress: 100,
        processIncidentData: processIncidentData,
        holds: voidHolds.length ? voidHolds : null,
        warrantyType: WarrantyType,
        voidScript: processIncidentData.Void,
        errors: [],
      });
    case PROCESS_INCIDENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        processIncidentProgress: 100,
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });

    case FULFILLMENT_INIT:
      return Object.assign({}, state, {
        serviceRequestType: null,
      });

    case FULFILLMENT_SUCCESS:
      return Object.assign({}, state, {
        serviceRequestType: action.payload.response,
      });

    case FULFILLMENT_FAILURE:
      return Object.assign({}, state, {
        serviceRequestType: action.error,
        processIncidentProgress: 100,
      });

    case GET_FULFILLMENT_INIT:
      return Object.assign({}, state, {
        loading: true,
        inWarrantyRemarks: action.remarks,
      });

    case GET_FULFILLMENT_SUCCESS:
      let { FulfillmentOptionResults: { FulfillmentOptionResult: fulfillOptionsArr = [] } = {} } =
        action.payload;
      let fulfillOptionObj = _.find(fulfillOptionsArr, { FulfillmentOption: 'OEM' });
      return Object.assign({}, state, {
        loading: false,
        reverseOrderWarranty: !!fulfillOptionObj && fulfillOptionObj.FulfillmentOption === 'OEM',
      });

    case GET_FULFILLMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        processIncidentProgress: 100,
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });

    case GET_REPLACEMENT_INIT:
      return Object.assign({}, state, {
        loading: true,
      });

    case GET_REPLACEMENT_SUCCESS:
      let {
        ServiceFee: replacementServiceFee = {},
        ReplcementMatrix: {
          ReplacementEquipmentResults = {},
          ReplacementEquipmentResults: {
            ReplacementItems = {},
            ReplacementItems: { ReplacementItem = [] } = {},
          } = {},
        } = {},
      } = action.payload;

      let LFLDevice = !_.isEmpty(ReplacementItem)
        ? _.find(ReplacementItem, { IsLikeForLike: true })
        : {};
      let LFLDeviceInStock = LFLDevice.IsInStock || false;
      let NLFLDevice = !_.isEmpty(ReplacementItem)
        ? _.filter(ReplacementItem, { IsLikeForLike: false })
        : {};
      let NLFLDeviceInStockCheck = !_.isEmpty(NLFLDevice)
        ? _.filter(NLFLDevice, { IsInStock: true })
        : {};
      let NLFLDeviceInStock = NLFLDeviceInStockCheck.length || false;

      // let NLFLDeviceInStock = !_.isEmpty(NLFLDevice) ? NLFLDevice.IsInStock : false;

      return Object.assign({}, state, {
        loading: false,
        checkAvailableDeviceProgress: 100,
        processIncidentProgress: 100,
        replacementDevices: ReplacementItems,
        LFLDeviceInStock: LFLDeviceInStock || state.LFLDeviceInStock,
        NLFLDeviceInStock: NLFLDeviceInStock || state.NLFLDeviceInStock,
        LFLDevice: !_.isEmpty(LFLDevice) ? LFLDevice : state.LFLDevice,
        selectedBrand: !_.isEmpty(LFLDevice)
          ? LFLDevice.HorizonItemInformation.Make
          : state.selectedBrand,
        baseItemId: ReplacementEquipmentResults.BaseItemId,
        caseType: _.upperCase(ReplacementEquipmentResults.IncidentType) || state.caseType,
        warehouseId: ReplacementItem[0].SCMItemInformation.WarehouseId,
        serviceFee: replacementServiceFee.ServiceFee,
        noServiceFee: !_.isEmpty(replacementServiceFee.ServiceFee)
          ? Math.round(parseInt(replacementServiceFee.ServiceFee.TotalAmount)) === 0
          : state.noServiceFee,
      });
    case SMART_STOCK_SELECTED:
      const {
        ServiceFee: smartStockSeviceFee,
        HorizonItemInformation: { Make: _smartStockMake },
      } = action.payload.device;

      const {
        TaxPercentage: taxPercentage,
        FeePercentage: feePercentage,
        CurrencyCode,
      } = action.payload.serviceFee;

      const priceBeforeTax = (smartStockSeviceFee * 100) / (100 + taxPercentage);
      const purchasePrice = priceBeforeTax / (feePercentage / 100);

      return Object.assign({}, state, {
        replacementDevices: {
          ReplacementItem: [action.payload.device],
        },
        serviceFee: {
          CurrencyCode,
          PurchasePrice: purchasePrice.toFixed(2),
          FeePercentage: feePercentage,
          FeeAmount: priceBeforeTax.toFixed(2),
          TaxPercentage: taxPercentage,
          TaxAmount: (smartStockSeviceFee - priceBeforeTax).toFixed(2),
          TotalAmount: smartStockSeviceFee,
        },
        selectedBrand: _smartStockMake,
        noServiceFee: Math.round(parseInt(smartStockSeviceFee)) === 0,
      });
    case GET_PROVINCE:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case GET_PROVINCE_SUCCESS:
      let provinceList = _.uniq(action.cityStatesList.map((o) => o.State));
      return Object.assign({}, state, {
        cityStatesList: action.cityStatesList || [],
        provinceList: provinceList || state.provinceList,
        /* provinceList: action.provinceList || state.provinceList,
        cityList: action.cityList || state.provinceList */
      });
    case GET_PROVINCE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        cityStatesList: [],
        errors: action.payload ? action.payload.errors : ['Oops, blank response!'],
      });
    case GET_REPLACEMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        checkAvailableDeviceProgress: 100,
        processIncidentProgress: 100,
        error: action.payload,
      });

    case SAVE_DEFECT_OPTION:
      let selectedIntent = determineUserIntent(action.payload, {
        isTrackOptionSelected: state.isTrackOptionSelected,
        isSROptionSelected: state.isSROptionSelected,
        isGEOptionSelected: state.isGEOptionSelected,
        isWbkoSelected: state.isWbkoSelected,
      });
      return Object.assign({}, state, {
        selectedDefectOption: action.payload,
        isTrackOptionSelected: selectedIntent.isTrackOptionSelected,
        isSROptionSelected: selectedIntent.isSROptionSelected,
        isGEOptionSelected: selectedIntent.isGEOptionSelected,
        isWbkoSelected: selectedIntent.isWbkoSelected,
      });

    case SAVE_IMEI_MISMATCH:
      return Object.assign({}, state, {
        imeiMismatch: action.payload,
      });

    case SAVE_SIX_DIGITS_IMEI:
      return Object.assign({}, state, {
        lastSixDigitsImei: action.payload,
      });

    case SET_TRIAGE_DISPLAY_COUNT:
      return Object.assign({}, state, {
        triageQuestionsDisplayed: action.triageCount,
      });

    case TRIAGE_RESOLVED_SUCCESS:
      return Object.assign({}, state, {
        newserviceRequestNumber: action.payload.TriageResponse.ServiceRequestNumber,
        newServiceRequestId: action.payload.TriageResponse.ServiceRequestId,
      });

    case GET_STANDARDISEADDRESS_INIT:
      return Object.assign({}, state, {
        loading: true,
        addressValidationLoaderValue: 0,
      });
    case GET_STANDARDISEADDRESS_SUCCESS:
      let {
        isValidAddress,
        QASAPIFailure = false,
        AddressResult: { CorrectedAddress: { PostalCode = state.postalCode } = {} } = {},
      } = action.payload;
      return Object.assign({}, state, {
        loading: false,
        addressList: action.payload,
        addressValidationLoaderValue: QASAPIFailure
          ? 100
          : !isValidAddress
          ? 100
          : state.addressValidationLoaderValue,
        postalCode: PostalCode,
      });
    case GET_STANDARDISEADDRESS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        addressValidationLoaderValue: 100,
      });

    case MISMATCHED_DEVICE_DETAILS:
      return Object.assign({}, state, {
        mismatchedDeviceDetails: action.payload,
      });

    case GET_SHIPPING_ORDER_INIT:
      return Object.assign({}, state, {
        loading: true,
      });

    case GET_SHIPPING_ORDER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        shippingOrderResult: action.payload,
      });

    case GET_SHIPPING_ORDER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        addressValidationLoaderValue: 100,
        error: action.payload,
      });

    case SHIPPING_METHODS_INIT:
      return Object.assign({}, state, {
        loading: true,
        shippingMethodsResult: null,
      });

    case SHIPPING_METHODS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        addressValidationLoaderValue: !state.backOrderCase
          ? 100
          : state.addressValidationLoaderValue,
        shippingMethodsResult: action.payload,
      });

    case SHIPPING_METHODS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        addressValidationLoaderValue: 100,
        error: action.payload,
      });

    //shipping method integration
    case SHIPPING_METHODS_V2_INIT:
      return Object.assign({}, state, {
        loading: true,
        shippingMethodsV2Result: [],
        shippingMethodsResult: null,
      });

    case SHIPPING_METHODS_V2_SUCCESS:
      let resArr = action.payload;

      let holds = state.holdsLogged || state.holds || [];

      let rescheduleDeliveryFlag =
        state.selectedEnquiryOption === 'Reschedule my Delivery' ||
        state.selectedEnquiryOption === 'Reschedule my current delivery';
      //"Reschedule my Delivery";

      let holdsExist = holds && Array.isArray(holds) && holds.length;
      let isPaymentHold = holdsExist ? holds.includes('PMTHLD') : false;

      if (isPaymentHold && resArr.length > 2) resArr = resArr.slice(2);
      if (holdsExist && !isPaymentHold && resArr.length > 1) resArr = resArr.slice(1);
      if (rescheduleDeliveryFlag)
        resArr = processRescheduleArray(resArr, state.expectedDeliveryDate);

      return Object.assign({}, state, {
        loading: false,
        addressValidationLoaderValue: !state.backOrderCase
          ? 100
          : state.addressValidationLoaderValue,
        shippingMethodsResult: {
          ShippingMethodType: resArr[0].ShippingMethodType,
          EstimatedDeliveryDate: resArr[0].EstimatedDeliveryDate,
          EstimatedShipmentDate: resArr[0].EstimatedShipmentDate,
        },
        selectedDeliverySpeed: undefined,
        estimatedShipmentDate: resArr[0].EstimatedShipmentDate,
        shippingMethodsV2Result: [...state.shippingMethodsV2Result, ...resArr],
      });

    case SAVE_ESTIMATED_DELIVERY_DATE: {
      return Object.assign({}, state, {
        loading: false,
        shippingMethodsResult: Object.assign({}, state.shippingMethodsResult, {
          EstimatedDeliveryDate: action.EstimatedDeliveryDate,
          EstimatedShipmentDate: action.EstimatedShipmentDate,
          ShippingMethodType: action.ShippingMethodType,
        }),
        estimatedShipmentDate: action.EstimatedShipmentDate,
        selectedDeliverySpeed: action.ShippingMethodType,
      });
    }

    case SHIPPING_METHODS_V2_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        addressValidationLoaderValue: 100,
        error: action.payload,
      });
    case UPDATE_SHIPPING_ORDER_INIT:
      return Object.assign({}, state, {
        loading: true,
      });

    case UPDATE_SHIPPING_ORDER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        addressValidationLoaderValue: 100,
        updateShippingOrderResult: action.payload,
      });

    case UPDATE_SHIPPING_ORDER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        addressValidationLoaderValue: 100,
        error: action.payload,
      });

    case PAYMENT_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        response: action.response,
      });

    case PAYMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case POST_CC_DETAILS_TO_BG_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        ccdetailsAccepted: action.payload === 'BG-0',
      });

    case GET_PCI_TOKEN_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case GET_PCI_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        pciToken: action.payload.PCIToken,
        referenceId: action.payload.ReferenceId,
        encryptionKey: action.payload.Encryptionkey,
        returnCode: action.payload.ReturnCode,
      });

    case GET_PCI_TOKEN_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case SAVE_CC_DETAILS:
      return Object.assign({}, state, {
        ccmask: action.payload.ccmask,
        cardType: action.payload.cardType,
      });

    case CHARGE_ORDER_INIT:
      return Object.assign({}, state, {
        loading: true,
        creditCardName: action.creditCardName,
        paymentProgressValue: 0,
        paymentMethod: action.paymentMethod,
      });

    case CHARGE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        paymentProgressValue: 100,
        chargeOrderDetails: action.payload,
      });

    case CHARGE_ORDER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        paymentProgressValue: 100,
        error: action.payload,
      });

    case PROCESS_PAYMENT_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
        ccdetailsProgressValue: 0,
      });

    case PROCESS_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        ccdetailsProgressValue: 100,
        processPaymentResults: action.payload,
      });

    case PROCESS_PAYMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        ccdetailsProgressValue: 100,
        error: action.payload,
      });

    case SUBMIT_ORDER_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case SUBMIT_ORDER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.payload,
      });

    case SUBMIT_ORDER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });
    case EVALUATE_IMEI_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case EVALUATE_IMEI_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        verifyIMEIResults: action.payload,
        imeiVerificationAttempt: action.payload.VerifyIMEIResults.IMEIVerificationFailedAttempt,
      });

    case EVALUATE_IMEI_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case UPDATE_CONTACT_DETAILS:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case UPDATE_CONTACT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        updateContactDetails: action.payload,
      });

    case UPDATE_CONTACT_DETAILS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });
    case GET_CONTACT_DETAILS:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case GET_CONTACT_DETAILS_SUCCESS:
      let arrayToBeFilter =
        action.payload &&
        action.payload.ContactPointResults &&
        action.payload.ContactPointResults.ContactPoints &&
        action.payload.ContactPointResults.ContactPoints.ContactPoint;

      let filteredEmailIds =
        arrayToBeFilter && arrayToBeFilter.filter((value) => value.ContactPointType === 'EMAIL');
      let filteredMobileNumbers =
        arrayToBeFilter && arrayToBeFilter.filter((value) => value.ContactPointType === 'MOBILE');
      let updatedEmailId =
        filteredEmailIds && filteredEmailIds[0] && filteredEmailIds[0].EmailAddress;
      let updatedMobileNumber =
        filteredMobileNumbers && filteredMobileNumbers[0] && filteredMobileNumbers[0].PhoneNumber;
      return Object.assign({}, state, {
        loading: false,
        getContactDetails: action.payload,
        updatedEmailId,
        updatedMobileNumber,
        maskedUpdatedEmailId: maskString(updatedEmailId),
        maskedUpdatedMobileNumber: maskString(updatedMobileNumber),
      });

    case GET_CONTACT_DETAILS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case GET_MAKE_LIST_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case GET_MAKE_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        assetDetails: action.payload.GetAssetsMakeModelResponse,
        selectedBrand: null,
      });

    case GET_MAKE_LIST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case GET_DEVICE_LIST_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case GET_DEVICE_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        makeAndModelList: _.sortBy(action.payload.GetAssetsMakeModelResponse, ['AssetCatalogName']),
        selectedBrand: action.payload.GetAssetsMakeModelResponse[0].Make,
      });

    case GET_DEVICE_LIST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case SAVE_SELECTED_DEVICE:
      return Object.assign({}, state, {
        loading: false,
        selectedModel: action.payload,
      });

    case SAVE_ADDRESS_VALIDATION_ATTEMPT_COUNT:
      return Object.assign({}, state, {
        loading: false,
        addressValidationAttemptCount: action.addressValidationAttemptCount,
      });

    case SUBMIT_SURVEY_INIT:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUBMIT_SURVEY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        submittedSurveyResult: action.payload,
      });

    case SUBMIT_SURVEY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case GET_SURVEY_INIT:
      return Object.assign({}, state, {
        loading: true,
      });

    case GET_SURVEY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        surveyQuestions: action.payload.SurveyQuestions,
      });

    case GET_SURVEY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });
    case UPLOAD_DOCUMENT_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
        uploadProgressValue: 50,
      });

    case UPLOAD_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        uploadProgressValue: 100,
      });

    case UPLOAD_DOCUMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        uploadProgressValue: 0,
        error: action.payload,
      });

    case END_INTERACTION_INIT:
      return Object.assign({}, state, {
        loading: action.loading,
        endInteraction: false,
      });

    case END_INTERACTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        endInteraction: true,
      });

    case END_INTERACTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        endInteraction: false,
      });

    case SAVE_CONVERSATION:
      return Object.assign({}, state, {
        loading: false,
        conversation: action.payload,
      });

    case SET_CHAT_REQUEST_ID:
      return Object.assign({}, state, {
        loading: false,
        requestId: action.payload,
      });

    case SET_CHAT_REASON:
      return Object.assign({}, state, {
        loading: false,
        chatReason: action.payload,
      });

    case SET_USER_CANCEL_FLAG:
      return Object.assign({}, state, {
        loading: false,
        userCancelled: action.payload,
      });

    case SAVE_HOLD_LOGGED:
      return Object.assign({}, state, {
        holdsLogged: action.holdsLogged,
      });
    // Generic progress bar
    case SET_GENERIC_PROGRESS_BAR:
      return Object.assign({}, state, {
        progressBarValue: action.progressValue,
        trickleStep: action.trickleStep,
        trickleInterval: action.trickleInterval,
        label: action.label,
        //completeLabel: action.completeLabel
      });

    case GET_SR:
      return Object.assign({}, state, {
        loading: true,
        srFeeProgressValue: 0,
      });

    case CLEAR_AGREEMENT:
      return Object.assign({}, state, {
        serviceFeeResponse: [],
      });
    case INCREASE_CAROUSALCOUNT:
      return Object.assign({}, state, {
        carousalLoadCount: state.carousalLoadCount + 1,
      });
    case INCREASE_CAROUSALCOUNT_FOR_SR:
      return Object.assign({}, state, {
        carousalLoadCountForSR: state.carousalLoadCountForSR + 1,
      });

    case GET_SR_SUCCESS:
      let { ServiceFees } = action.serviceFeeResponse;
      let serviceFees = ServiceFees?.forEach((e) => {
        ServiceFees[e.IncidentType] = e.Fees;
      });
      let replacementFee = !_.isEmpty(ServiceFees)
        ? _.find(ServiceFees, { IncidentType: CONSTANTS.REPLACEMENT })
        : {};
      let swapFee = !_.isEmpty(ServiceFees)
        ? _.find(ServiceFees, { IncidentType: CONSTANTS.SWAP })
        : {};
      let res = Object.assign({}, action.serviceFeeResponse, {
        ServiceFees: ServiceFees,
        swapFee: swapFee,
        replacementFee: replacementFee,
      });
      return Object.assign({}, state, {
        serviceFeeResponse: [...state.serviceFeeResponse, res],
        replacementFee: replacementFee,
        swapFee: swapFee,
        srFeeProgressValue: 100,
        loading: false,
        serviceFees,
      });

    case GET_ALL_SR_SUCCESS:
      const srs = action.serviceFeeResponses || [];
      const data = srs.reduce((result, sr) => {
        const { ServiceFees } = sr;
        const serviceFees = ServiceFees?.forEach((e) => {
          ServiceFees[e.IncidentType] = e.Fees;
        });
        const replacementFee = !_.isEmpty(ServiceFees)
          ? _.find(ServiceFees, { IncidentType: CONSTANTS.REPLACEMENT })
          : {};
        const swapFee = !_.isEmpty(ServiceFees)
          ? _.find(ServiceFees, { IncidentType: CONSTANTS.SWAP })
          : {};
        const res = Object.assign({}, sr, {
          ServiceFees: ServiceFees,
          swapFee: swapFee,
          replacementFee: replacementFee,
        });

        result.push({
          res,
          serviceFees,
          swapFee,
          replacementFee,
        });
        return result;
      }, []);

      const values = _.head(data);

      return Object.assign({}, state, {
        serviceFeeResponse: [...data.map(({ res }) => res)],
        replacementFee: values && values.replacementFee,
        swapFee: values && values.swapFee,
        srFeeProgressValue: 100,
        loading: false,
        serviceFees: values && values.serviceFees,
      });

    case GET_SR_FAILURE:
      return Object.assign({}, state, {
        srFeeProgressValue: 100,
        loading: false,
      });

    case CREATE_CHAT_ENQUIRY:
      return Object.assign({}, state, {
        loading: true,
      });

    case CREATE_CHAT_ENQUIRY_SUCCESS:
      let { TLCEventUpdateresponse = {} } = action.payload;
      return Object.assign({}, state, {
        loading: false,
        createInquiryResponse: action.payload,
        currentDeliveryTrackingUrl:
          TLCEventUpdateresponse &&
          TLCEventUpdateresponse.TrackingUrl &&
          TLCEventUpdateresponse.TrackingUrl.replace('http', 'https'),
        currentDeliveryTrackingStatus: TLCEventUpdateresponse.Status,
      });

    case CREATE_CHAT_ENQUIRY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case SELECTED_LEAVING_AIS_REASON:
      return Object.assign({}, state, {
        selectedLeavingAISReason: action.payload,
      });

    case SELECTED_CALLBACK_DATE:
      return Object.assign({}, state, {
        selectedCallBackDate: action.payload,
      });

    case SELECTED_CALLBACK_TIME:
      return Object.assign({}, state, {
        selectedCallBackTime: action.payload,
      });
    case CALLBACK_COMMENT:
      return Object.assign({}, state, {
        callBackComment: action.payload,
      });
    case LOAD_THANK_YOU:
      return Object.assign({}, state, {
        showThankYouFlag: action.payload.showThankYou,
        stayWithAIS: action.payload.stayWithAIS,
      });
    case STAY_WITH_AIS:
      return Object.assign({}, state, {
        stayWithAIS: action.payload.stayWithAIS,
      });
    case SAVE_1:
      return Object.assign({}, state, {
        save1: action.payload,
      });
    case SAVE_2:
      return Object.assign({}, state, {
        save2: action.payload,
      });
    case CHURN_SAVEDESK_ALTERNATE_CONTACT_NUMBER:
      return Object.assign({}, state, {
        churnSavedeskAlternateContactNumber: action.payload,
      });
    case ENTER_OTHER_REASON:
      return Object.assign({}, state, {
        otherReason: action.payload,
      });
    case WBKO:
      return Object.assign({}, state, {
        wbko: action.payload,
      });
    case WBKO_SAVE_FIRST_NAME:
      return Object.assign({}, state, {
        wbkoFirstName: action.payload,
      });
    case WBKO_SAVE_MOBILE_NUMBER:
      return Object.assign({}, state, {
        wbkoMobileNumber: action.payload,
      });
    case WBKO_SAVE_ID_NUMBER:
      return Object.assign({}, state, {
        wbkoIdNumber: action.payload,
      });
    case WBKO_KEEP_OR_CONFIRM_COUNT:
      return Object.assign({}, state, {
        keepOrconfirmCount: action.payload,
      });
    case WBKO_CANCELLATION_REASON:
      return Object.assign({}, state, {
        WbkoCancellationReason: action.payload && action.payload.name,
        WbkoCancellationReasonText: action.payload && action.payload.text,
      });
    case SAVE_CHANGED_ADDRESS_STRING:
      return Object.assign({}, state, {
        WbkoChangedAddressString: action.payload,
        keepOrconfirmCount: state.keepOrconfirmCount + 1,
      });
    case WBKO_TRACK_TRACE:
      return Object.assign({}, state, {
        keepOrconfirmCount: 0,
        isTrackOptionSelected: false,
        isWbkoSelected: true,
        WbkoChangedAddressString: null,
        changedAddress: null,
        backToSrOptionPage: true,
      });
    case SET_SELECTED_ASSET:
      return Object.assign({}, state, {
        selectedAsset: action.asset,
      });
    case VERIFY_AGREEMENT_INIT:
      return Object.assign({}, state, {
        loading: true,
      });

    case VERIFY_AGREEMENT_SUCCESS:
      // let selected = action.payload && action.payload.VerifyAgreementsResults && action.payload.VerifyAgreementsResults.Agreement.Assets.Asset;
      // let selectedAsset = _.find(selected, { AssetInstance: 'ENROLLED' });
      let selectedAsset = state.selectedAsset;

      return Object.assign({}, state, {
        selectedAsset,
        selectedAgreement: action.payload.VerifyAgreementsResults,
        deviceDetails: selectedAsset,
        assetInstance: selectedAsset.AssetInstance,
        loading: false,
      });

    case VERIFY_AGREEMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });
    case SAVE_SHIPPING_ADDDRESS:
      return Object.assign({}, state, {
        ShippingAddress: action.payload,
      });

    case SELECTED_ASSET_PROGRAM_NAME:
      let abc = action.payload;
      return Object.assign({}, state, {
        AssetsInformation: action.payload.AssetsInformation,
        selectedMakeName: action.payload?.Make?.Name,
        selectedmodelName: action.payload?.Model?.Name,
        programName: action.payload?.programName,
      });
    case CUSTOMER_CASE_GRAPH_INIT:
      return Object.assign({}, state, {
        loading: true,
      });

    case CUSTOMER_CASE_GRAPH_SUCCESS:
      return Object.assign({}, state, {
        completedSr:
          action.payload &&
          action.payload.CustomerCaseGraphResponse &&
          action.payload.CustomerCaseGraphResponse.CustomerCases,
        loading: false,
      });

    case CUSTOMER_CASE_GRAPH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });

    case SAVE_SELECTED_SR_ASSET_ID:
      return Object.assign({}, state, {
        loading: false,
        AssetIdForSpecificSr: action.payload,
      });
    case SERVICE_UNAVAILABLE_COUNT:
      return Object.assign({}, state, {
        loading: false,
        serviceUnavailableCount: action.payload,
      });
    case UPDATED_DEVICE_DATA:
      return Object.assign({}, state, {
        loading: false,
        updatedDeviceData: action.payload,
      });

    default:
      return state;
  }
}

export default findAgreements;
