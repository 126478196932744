import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Provider } from 'react-redux';

import CONSTANTS from './../../utils/Constants';
import store from './../../store';
import SendMessage from '../../components/common/Chat/SendMessage';

import EnquiryOptions from '../EnquiryOptions/EnquiryOptions';
import EnquiryOptionsSA from '../EnquiryOptions/EnquiryOptions-SA';
import RequestForMDN from '../RequestForMDNAndNRIC/RequestForMDNAndNRIC';
import RequestForLeavingAIS from '../RequestForLeavingAisReason/RequestForLeavingAisReason';

import ResumeServiceRequest from '../ResumeServiceRequest/ResumeServiceRequest';
import ConfirmCurrentDevice from '../ConfirmCurrentDevice/confirmCurrentDevice';
import CheckAlternativeDevice from '../CheckAlternativeDevice/checkAlternativeDevice';
import TurnOffFeatures from '../TurnOffFeatures/TurnOffFeatures';
import SubmitIMEICode from '../SubmitIMEICode/submitIMEICode';
//import SubmitIMEICode from '../../samsung_components/SubmitIMEICode/submitIMEICode';
import ProceedServiceFee from '../ProceedServiceFee/proceedServiceFee';
import CurrentDeviceOptions from '../CurrentDeviceOptions/CurrentDeviceOptions';
import SubmitContactNumber from '../SubmitContactNumber/submitContactNumber';
import ConfirmEnrolledAddress from '../ConfirmEnrolledAddress/confirmEnrolledAddress';
import ConfirmDeliverySchedule from '../ConfirmDeliverySchedule/confirmDeliverySchedule';
import ConfirmYourDetails from '../ConfirmYourDetails/confirmYourDetails';
import CustomerSupportChat from '../CustomerSupportChat/customerSupportChat';
import ChangeDeliveryAddress from '../ChangeDeliveryAddress/changeDeliveryAddress';
import CurrentRequestDetails from '../CurrentRequestDetails/currentRequestDetails';
import GridViewOptions from '../GridViewOptions/GridViewOptions';
import TrackMyDelivery from '../TrackMyDelivery/trackMyDelivery';
import AskForEnquiry from '../AskForEnquiry/AskForEnquiry';
import ChangeDeliveryScheduleEnquiry from '../ChangeDeliveryScheduleEnquiry/ChangeDeliveryScheduleEnquiry';
import ChangeDeliveryAddressEnquiry from '../ChangeDeliveryAddressEnquiry/ChangeDeliveryAddressEnquiry';
import ChangeDeliverySchedule from '../ChangeDeliverySchedule/changeDeliverySchedule';
import TextAreaWithSubmit from '../TextAreaWithSubmit/TextAreaWithSubmit';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion';
import UploadFileScript from '../UploadFileScript/UploadFileScript';
import DownloadDocument from '../DownloadDocument/DownloadDocument';
import SelectUploadDocument from '../SelectUploadDocument/SelectUploadDocument';
import GeneralEnquiry from '../GeneralEnquiry/GeneralEnquiry';
import SlideAlternativeDevices from '../SlideAlternativeDevices/SlideAlternativeDevices';
import CancelDeliveryEnquiry from '../CancelDeliveryEnquiry/CancelDeliveryEnquiry';
import DisclaimerWithButtons from '../DisclaimerWithButtons/DisclaimerWithButtons';
import FMIPInstructionSlider from '../FMIPInstructionSlider/FMIPInstructionSlider';
import AcceptTerms from '../acceptTerms/acceptTerms';
import CODForCreditCard from '../CODForCreditCard/CODForCreditCard';
import DesktopAgentStatus from '../DesktopAgentStatus/DesktopAgentStatus';
import FAQPage from '../FAQPage/FAQPage';
import _ from 'lodash';

import { getFormattedPopularName, messageTime } from './../../utils/utils';
import GetMoreInfo from '../GetMoreInfo/GetMoreInfo';
import AISReasonCommonPage from '../AISReasonCommonPage/AISReasonCommonPage';
import RequestForLeavingAisReason from '../RequestForLeavingAisReason/RequestForLeavingAisReason';
import RouteToRefund from '../AISReasonCommonPage/RouteToRefund';
import LeavingAISAcknowledge from '../AISReasonCommonPage/LeavingAISAcknowledge';
import RequestForMDNAndNRIC from '../RequestForMDNAndNRIC/RequestForMDNAndNRIC';
import TriageConfirmBox from '../TriageConfirmBox/TriageConfirmBox';
import GetServiceFee from '../GetServiceFee/GetServiceFee';
import SubscriptionDetails from '../SubscriptionDetails/SubscriptionDetails';
import StartRequest from '../StartRequest/StartRequest';
import AISSubReasonPage from '../AISReasonCommonPage/AISSubReasonPage';
import ThankYoupage from '../AISReasonCommonPage/ThankYoupage';
import OtherReason from '../AISReasonCommonPage/OtherReason';
import WbkoTrackMyDelivery from '../Wbko/TrackMyDelivery';
import WbkoUpdateCOntactDetails from '../Wbko/WbkoUpdateContactDetails';
import ChurnPopup from '../AISReasonCommonPage/ChurnPopup';
import WbkoTrackOrEnd from '../Wbko/TrackOrEnd';
import WbkoCancellationReason from '../Wbko/CancelationReason';
import DeviceList from '../SubscriptionDetails/DeviceList';
import UpdateEnrolledDevice from '../UpdateEnrolledDevice/UpdateEnrolledDevice';
import EnrolledDeviceInquiry from '../EnrolledDeviceInquiry/EnrolledDeviceInquiry';
import VerifiedDeviceList from '../SubscriptionDetails/VerifiedDeviceList';
import ServiceUnavailable from '../ServiceUnavailable/ServiceUnavailable';
import SmartStock from '../SmartStock/SmartStock';
import DeviceInStock from '../DeviceStockStatus/DeviceInStock';
import DeviceOutStock from '../DeviceStockStatus/DeviceOutStock';
import DeviceNLP from '../DeviceStockStatus/DeviceNLP';
import DeviceNCA from '../DeviceStockStatus/DeviceNCA';

import WbkoTrackMyDeliveryNew from '../WbkoNew/WbkoTrackMyDeliveryNew';
import WbkoCancelationReasonNew from '../WbkoNew/WbkoCancelationReasonNew';
import WbkoChangeDeliveryAddressNew from '../WbkoNew/WbkoChangeDeliveryAddressNew';
import WbkoChangeDeliveryScheduleNew from '../WbkoNew/WbkoChangeDeliveryScheduleNew';
import WbkoCancelationConfirm from '../WbkoNew/WbkoCancelationConfirm';

//window.appsyncindex = 0;
//console.log(store.getState().customerData.customerName);
export default class SendMsgToAppSync extends Component {
  constructor(props) {
    super(props);

    this.index = 0;
    // Mapper for both constants and its associated component name, taken from Message.js file
    this.mapper = {
      [CONSTANTS.REQUEST_OPTIONS]: EnquiryOptions,
      [CONSTANTS.ENQUIRY_OPTIONS]: EnquiryOptionsSA,
      [CONSTANTS.ASK_FOR_MDN]: RequestForMDN,
      [CONSTANTS.ASK_FOR_NAME]: RequestForMDN,
      [CONSTANTS.ASK_FOR_NRIC]: RequestForMDN,
      [CONSTANTS.ASK_FOR_LEAVING_AIS_REASON]: RequestForLeavingAIS,
      [CONSTANTS.CONTINUE_PREVIOUS_SR]: ResumeServiceRequest,
      [CONSTANTS.CONFIRM_CURRENT_DEVICE]: ConfirmCurrentDevice,
      [CONSTANTS.SUBMIT_IMEI_NUMBER]: SubmitIMEICode,
      [CONSTANTS.PROCEED_SERVICE_FEE]: ProceedServiceFee,
      [CONSTANTS.CONFIRM_MOB_NO]: ConfirmEnrolledAddress,
      [CONSTANTS.CONFIRM_EMAIL_ADDRESS]: ConfirmEnrolledAddress,
      [CONSTANTS.CONFIRM_ENROLLED_ADDRESS]: ConfirmEnrolledAddress,
      [CONSTANTS.CONFIRM_DELIVERY_SCHEDULE]: ConfirmDeliverySchedule,
      [CONSTANTS.PAYMENT_FEE]: ProceedServiceFee,
      [CONSTANTS.CONFIRM_DETAILS]: ConfirmYourDetails,
      [CONSTANTS.TRIAGE_QUESTION]: CustomerSupportChat,
      [CONSTANTS.TRIAGE_ISSUE_RESOLVED]: CustomerSupportChat,
      [CONSTANTS.CONFIRM_SIM_CARD]: CustomerSupportChat,
      // [CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS]: ChangeDeliveryAddress,
      [CONSTANTS.WEB_SCHEDULE_FORM]: ChangeDeliverySchedule,
      [CONSTANTS.CURRENT_DEVICE_OPTIONS]: CurrentDeviceOptions,
      [CONSTANTS.SUBMIT_EMAIL]: SubmitContactNumber,
      [CONSTANTS.SUBMIT_CONTACT_NUMBER]: SubmitContactNumber,
      [CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT]: ProceedServiceFee,
      [CONSTANTS.CURRENT_REQUEST_DETAILS]: CurrentRequestDetails,
      [CONSTANTS.WEB_GRID_VIEW_OPTIONS]: GridViewOptions,
      [CONSTANTS.WEB_TRACK_MY_DELIVERY]: TrackMyDelivery,
      [CONSTANTS.ASK_FOR_ENQUIRY]: AskForEnquiry,
      [CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS]: ChangeDeliveryAddressEnquiry,
      [CONSTANTS.CAPTURE_IW_REMARKS]: TextAreaWithSubmit,
      [CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY]: ChangeDeliveryScheduleEnquiry,
      [CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY]: CancelDeliveryEnquiry,
      [CONSTANTS.IMEI_ATTEMPTS_EXCEEDED]: EnquiryOptions,
      [CONSTANTS.SURVEY_QUESTION_RATING]: SurveyQuestion,
      [CONSTANTS.SURVEY_QUESTION_COMMENTS]: SurveyQuestion,
      [CONSTANTS.SURVEY_QUESTION_EXPERIENCE]: SurveyQuestion,
      [CONSTANTS.HOLD_SCRIPT]: UploadFileScript,
      [CONSTANTS.DOWNLOAD_DOCUMENTS]: DownloadDocument,
      [CONSTANTS.CONFIRM_UPLOAD_DOCUMENTS]: SelectUploadDocument,
      [CONSTANTS.ASK_FOR_FEEDBACK]: ProceedServiceFee,
      [CONSTANTS.GENERAL_ENQUIRY]: GeneralEnquiry,
      [CONSTANTS.DEVICE_IS_BROKEN]: EnquiryOptions,
      [CONSTANTS.DEVICE_NOT_WORKING]: EnquiryOptions,
      [CONSTANTS.CHECK_ALTERNATIVE_DEVICES]: CheckAlternativeDevice,
      [CONSTANTS.TURNOFF_FEATURES]: TurnOffFeatures,
      [CONSTANTS.COD_CONFIRMATION]: ProceedServiceFee,
      [CONSTANTS.ALTERNATE_DEVICE_LIST]: SlideAlternativeDevices,
      [CONSTANTS.TRIAGE_FAILED]: DisclaimerWithButtons,
      [CONSTANTS.ALL_AGENT_OFFLINE]: DesktopAgentStatus,
      [CONSTANTS.ALL_AGENT_BUSY]: DesktopAgentStatus,
      [CONSTANTS.AGENT_DISCONNECTED]: DesktopAgentStatus,
      [CONSTANTS.CLOSE_SR]: CODForCreditCard,
      [CONSTANTS.CLOSE_REQUEST]: CODForCreditCard,
      [CONSTANTS.DEVICE_NOT_ELIGIBLE]: CODForCreditCard,
      [CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK]: CODForCreditCard,
      [CONSTANTS.MDN_NRIC_CLOSE]: CODForCreditCard,
      [CONSTANTS.GENERIC_NOT_RESOLVED]: CODForCreditCard,
      [CONSTANTS.ALTERNATE_DEVICE_NOTE]: CODForCreditCard,
      [CONSTANTS.COD_FOR_CREDIT_CARD]: CODForCreditCard,
      // [CONSTANTS.TURN_OFF_FMIP_INSTRUCTIONS]: FMIPInstructionSlider,
      [CONSTANTS.ACCEPT_TERMS]: AcceptTerms,
      [CONSTANTS.CONFIRM_EXISTING_CUSTOMER]: FAQPage,
      [CONSTANTS.FAQ_PAGE]: FAQPage,

      [CONSTANTS.LEAVE_AIS]: RequestForLeavingAIS,
      [CONSTANTS.ASK_FOR_LEAVING_AIS_REASON]: RequestForLeavingAisReason,
      [CONSTANTS.AIS_REASON_NOT_HELPFULL]: AISReasonCommonPage,
      [CONSTANTS.LOAD_AIS_SUBREASON]: AISSubReasonPage,
      [CONSTANTS.LOAD_THANK_YOU]: ThankYoupage,
      [CONSTANTS.ROUTE_TO_REFUND]: RouteToRefund,
      [CONSTANTS.ROUTE_TO_SAVEDESK]: LeavingAISAcknowledge,
      [CONSTANTS.ENTER_OTHER_REASON]: OtherReason,
      [CONSTANTS.LEAVING_AIS_POP_UP]: ChurnPopup,

      // [CONSTANTS.WBKO_TRACK_MY_DELIVERY]: WbkoTrackMyDelivery,
      [CONSTANTS.WBKO_TRACK_MY_DELIVERY]: WbkoTrackMyDeliveryNew,
      [CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS]: WbkoUpdateCOntactDetails,
      [CONSTANTS.WBKO_TRACK_OR_END]: WbkoTrackOrEnd,
      [CONSTANTS.WBKO_CANCELLATION_REASON]: WbkoCancellationReason,

      [CONSTANTS.ASK_FOR_NAME]: RequestForMDNAndNRIC,
      [CONSTANTS.CLOSE_SR]: CODForCreditCard,
      [CONSTANTS.CLOSE_REQUEST]: CODForCreditCard,
      [CONSTANTS.DEVICE_NOT_ELIGIBLE]: CODForCreditCard,
      [CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK]: CODForCreditCard,
      [CONSTANTS.MDN_NRIC_CLOSE]: CODForCreditCard,
      [CONSTANTS.GENERIC_NOT_RESOLVED]: CODForCreditCard,
      [CONSTANTS.ALTERNATE_DEVICE_NOTE]: CODForCreditCard,
      [CONSTANTS.COD_FOR_CREDIT_CARD]: CODForCreditCard,
      [CONSTANTS.CONFIRM_MALFUNCTION_DEVICE_MEASURES]: TriageConfirmBox,
      [CONSTANTS.CAPTURE_IW_REMARKS]: TextAreaWithSubmit,
      [CONSTANTS.GET_SERVICE_FEE]: GetServiceFee,
      [CONSTANTS.GET_MOREINFO]: GetMoreInfo,
      [CONSTANTS.SUBSCRIPTION]: SubscriptionDetails,
      [CONSTANTS.GET_STARREQ]: StartRequest,
      [CONSTANTS.DEVICE_LIST_SUBSCRIPTION]: DeviceList,
      [CONSTANTS.UPDATE_ENROLLED_DEVICE]: UpdateEnrolledDevice,
      [CONSTANTS.ENROLLED_DEVICE_ENQUIRY]: EnrolledDeviceInquiry,
      [CONSTANTS.VERIFIED_AGREEMENT_DEVICE_LIST]: VerifiedDeviceList,
      [CONSTANTS.SERVICE_UNAVAILABLE]: ServiceUnavailable,

      [CONSTANTS.DEVICE_IN_STOCK]: DeviceInStock,
      [CONSTANTS.DEVICE_OUT_STOCK]: DeviceOutStock,
      [CONSTANTS.DEVICE_NLP]: DeviceNLP,
      [CONSTANTS.DEVICE_NCA]: DeviceNCA,

      [CONSTANTS.WBKO_TRACK_MY_DELIVERY_NEW]: WbkoTrackMyDeliveryNew,
      [CONSTANTS.WBKO_CHANGE_DELIVERY_ADDRESS_NEW]: WbkoChangeDeliveryAddressNew,
      [CONSTANTS.WBKO_CHANGE_DELIVERY_SCHEDULE_NEW]: WbkoChangeDeliveryScheduleNew,
      [CONSTANTS.WBKO_CANCELLATION_REASON_NEW]: WbkoCancelationReasonNew,
      [CONSTANTS.WBKO_CANCELLATION_CONFIRM]: WbkoCancelationConfirm
    };

    this.getComponent = this.getComponent.bind(this);
    this.createAppSyncMessages = this.createAppSyncMessages.bind(this);
    this.wrapper = this.wrapper.bind(this);
  }

  createAppSyncMessages(message, Component) {
    let content;
    const { visitor } = this.props;
    const { input } = visitor || {};
    const { visitorId, userName } = input || {};

    if (Component === undefined) {
      return;
    }
    if (!this.mapper[message.type]) {
      content = Component;
    } else {
      content =
        message.type === 'text' ? Component : ReactDOMServer.renderToStaticMarkup(Component);
    }

    return {
      input: {
        conversationId: null,
        visitorId: visitorId,
        messageId: message.messageId,
        sender: message.author,
        source: message.source,
        content: content,
        recipient: userName,
        isSent: false,
        //this workaround is just to make sure text message gets first in appsync and then component. All other is working fine
        createdAt: message.type === CONSTANTS.DEVICE_IS_BROKEN ? messageTime() : message.time,
        messageType: message.type,
        interactionType: 'Online',
        isActive: true,
        serialNo: this.index,
      },
    };
  }

  getComponent(message) {
    let type = message.type;
    let { data } = message;
    let { text } = data || {};
    let messageText = text || '';

    if (type === 'text' && message.data.text) {
      const { deviceDetails, LFLDevice, customerName } = store.getState().customerData;

      const { HorizonItemInformation: { AssetCatalogName } = {} } = LFLDevice;
      let AssetCatName =
        deviceDetails && deviceDetails.AssetCatalog && deviceDetails.AssetCatalog.AssetCatalogName;

      const selectedDevice = !_.isEmpty(LFLDevice)
        ? getFormattedPopularName(AssetCatalogName)
        : deviceDetails
        ? getFormattedPopularName(AssetCatName)
        : {};
      const unavailableDevice =
        selectedDevice.makename || selectedDevice.modelName
          ? `${selectedDevice.makename} ${selectedDevice.modelName}`
          : 'selected above';

      return messageText
        .replace('{Username}', customerName.firstName || `User`)
        .replace('{UnavailableDevice}', unavailableDevice);
    }

    let expectionList = [CONSTANTS.WEB_CREDITCARD_FORM, CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS];

    if (expectionList.includes(type)) {
      return null;
    }

    switch (message.type) {
      case CONSTANTS.TRANSFERRED_TO_AGENT:
        return `Chat support started`;
      case CONSTANTS.CHAT_SUPPORT_ENDED:
        return `Chat support Ended`;
      case CONSTANTS.EXPERIENCE_SURVEY_SUBMITTED:
        const customerData = store.getState().customerData;
        if (customerData.surveyResolution || customerData.surveyQuestionComment) {
          return `Survey Resolution: ${customerData.surveyResolution}<br/>Survey NPS: ${customerData.surveyQuestionComment}<br/>Survey Remark: ${customerData.surveyReason}`;
        }
    }

    let ComponentName = this.mapper[type];
    if (ComponentName) {
      return this.wrapper(ComponentName, message.data, message.dataprops);
    }
  }

  wrapper(Component, data, dataprops) {
    return (
      <Provider store={store}>
        <Component data={data} dataprops={dataprops} />
      </Provider>
    );
  }

  render() {
    const { conversation, messages } = this.props;

    if (conversation) {
      return null;
    }

    let msg = messages.map((message, i) => {
      // if(message && message.id) {
      //   //console.log("APPSYNC", "skipping due to id");
      //   return null;
      // }

      if (message && !message.messageId) {
        return null;
      }

      let Component, msgObj;
      if (this.index === i) {
        Component = this.getComponent(message);
        if (Component) {
          // console.log(Component);
          msgObj = this.createAppSyncMessages(message, Component);
          //index++;
          this.index++;
          return msgObj;
        }
        this.index++;
      }
    });
    return (
      <div>
        {msg &&
          msg.map((m) => {
            if (m) {
              return <SendMessage message={m} key={m.input.messageId} />;
            }
          })}
      </div>
    );
  }
}
