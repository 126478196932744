import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import CONSTANTS from "../../utils/Constants";
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import trackDeliveryIcon from "../../images/ais-images/icon-track-delivery-status.png";
import checkedStatusIcon from "../../images/ais-images/icon-track-delivery-done.png";
import greylineIcon from "../../images/ais-images/tracking-dots-normal.png";
import greenlineIcon from "../../images/ais-images/tracking-dots-active.png";
import firstDefaultIcon from "../../images/ais-images/track-delivery-step-1-default.png";
import secondDefaultIcon from "../../images/ais-images/track-delivery-step-2-default.png";
import thirdDefaultIcon from "../../images/ais-images/track-delivery-step-3-default.png";
import fourthDefaultIcon from "../../images/ais-images/track-delivery-step-4-default.png";
import fifthDefaultIcon from "../../images/ais-images/track-delivery-step-5-default.png";
import "./trackMyDelivery.css";
class TrackMyDelivery extends Component {

    constructor(props) {
        super(props);
    }

    getDAXSalesOrderId() {
        const {ShippingStatus, CustomerCaseStatus, Servicerequests} = this.props
        try {
            if(ShippingStatus === 'SHIP' && CustomerCaseStatus  === 'CMPLTD')
                return Servicerequests[0].ShippingOrder.DAXSalesOrderId
            return null
        }catch(e) {
            return null
        }
    }

    getActiveIndex() {
        //It actually returns the index of the step which is last completed on tracking UI.
        const { ShippingStatus, CustomerCaseStatus } = this.props;

        if(ShippingStatus === CONSTANTS.SHIPPING_STATUS.SHIP) {
            return 3;
        }
        if(ShippingStatus === CONSTANTS.SHIPPING_STATUS.DELIVERED) {
            return 4
        }
        return 0
      }


    render() {
        const DAXSalesOrderId = this.getDAXSalesOrderId()

        const { data } = this.props;

        const { PLACEHOLDERS } = data;

        const stages = [
            {
                icon: firstDefaultIcon,
                label: PLACEHOLDERS.CONFIRMED
            },{
                icon: secondDefaultIcon,
                label: PLACEHOLDERS.PROCESSING
            },{
                icon: thirdDefaultIcon,
                label: PLACEHOLDERS.QUALITY_CHECK
            },{
                icon: fourthDefaultIcon,
                label: PLACEHOLDERS.DISPATCHED
            },{
                icon: fifthDefaultIcon,
                label: PLACEHOLDERS.DELIVERED,
            }
        ]

        const activeIndex = this.getActiveIndex()

        return (
            <div className="ais_outer-frame">
                <img alt="track-delivery" src={trackDeliveryIcon} className="ais_track-delivery"/>
                <div className="ais_tracker-delivery-header">{this.props.data.HEADER}</div>
                <div className="ais_tracker-delivery-header-separator">
                    {stages.map((stage, index) => <Fragment>
                    {index !==0 && <img src={activeIndex >= index ? greenlineIcon : greylineIcon} className="ais_progress-line ais_inline"></img>}
                    <div className="ais_inline">
                        <div className="ais_default-stage">
                            <img src={activeIndex >= index ? checkedStatusIcon : stage.icon} className="ais_default-status-icon inline"></img>
                        </div>
                    <div className={activeIndex >= index ? 'ais_active-status-text' : 'ais_default-status-text'}>{stage.label}</div>
                    </div>
                    </Fragment>
                    )}

                </div>
                {DAXSalesOrderId &&<div className="shippedTrack">
                    <a className="ais_device-select-button ais_selected ais_device-btn-width" href={`https://ecommerceportal.dhl.com/track/?ref=THAMY${DAXSalesOrderId}`} title="Click Here to Track the device" target="_blank" >{MESSAGE_CONSTANTS.TRACK_THE_DEVICE}</a>
                </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { determineIncidentResponse } = state.customerData;
    return {
        CustomerCaseStatus: determineIncidentResponse.CustomerCaseStatus || null,
        ShippingStatus: determineIncidentResponse.ShippingStatus || null,
        Servicerequests: determineIncidentResponse.Servicerequests || [],
    };
};

export default connect(mapStateToProps, null)(TrackMyDelivery);
//export default TrackMyDelivery;