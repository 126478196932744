import React, { Component } from "react";

import CONSTANTS from "../../utils/Constants";
import MESSAGE_CONSTANTS from "../../config/journey-messages";

import downloadIcon from "../../images/ais-images/icon-download-form.png";
import SweetAlert from 'react-bootstrap-sweetalert';


class DownloadDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
            showClosePrompt: false,
            downloadFile :false
        }
        this.handleDownload = this.handleDownload.bind(this);
        this.handleSkip = this.handleSkip.bind(this);
    }


    showModal = () => {
        this.setState({ showClosePrompt: true });
    };

    hideModal = () => {
        this.setState({
            showClosePrompt: false,
            disableButton: false
        });
    };

    handleDownload = () => {
        // this.setState({
        //     disable: true,
        //     yesButtonClassName: "ais_align-popup-button-selected"
        // });
        this.showModal();

        // this.props.updateJourneyList(CONSTANTS.UPLOAD_DOCUMENTS, null);
        // this.props.updateJourneyList(CONSTANTS.CONFIRM_UPLOAD_DOCUMENTS, null);
    }

    downloadForm =() => {
       this.setState({
            disable: true,
            showClosePrompt: false,
            yesButtonClassName: "ais_align-popup-button-selected",
            disableButton: false
        }, this.refs["download"].click()
        )
        
         this.props.updateJourneyList(CONSTANTS.CONFIRM_UPLOAD_DOCUMENTS, null);
    }


    handleSkip = (e) => {
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        e.preventDefault();
        // this.props.updateJourneyList(CONSTANTS.UPLOAD_DOCUMENTS, null);
        this.props.updateJourneyList(CONSTANTS.CONFIRM_UPLOAD_DOCUMENTS, null);
    }

    render() {
        console.log("$$$$$ downloadFile", this.state.downloadFile);

        let alert = "";
        let download = "";

        if (this.state.showClosePrompt) {
            alert = <SweetAlert
                showCancel
                cancelBtnText={MESSAGE_CONSTANTS.CANCEL}
                confirmBtnText={MESSAGE_CONSTANTS.YES_TEXT}
                confirmBtnCssClass="ais_align-endChat-button-selected"
                cancelBtnCssClass="ais_align-endChat-button"
                onConfirm={this.downloadForm}
                onCancel={this.hideModal} >
                <span className="ais_other-details-desc">{MESSAGE_CONSTANTS.DOWNLOAD_ALERT}</span>
            </SweetAlert>

            // maxSizeExceed = <SweetAlert
            //     confirmBtnText={MESSAGE_CONSTANTS.MAX_SIZE_ALERT.BUTTON}
            //     confirmBtnCssClass="ais_align-endChat-button-selected"
            //     cancelBtnCssClass="ais_align-endChat-button"
            //     title={<span className="ais_cancel-request">{MESSAGE_CONSTANTS.MAX_SIZE_ALERT.ALERT_HEADER}</span>}
            //     onConfirm={this.hideModal}
            // >

            // </SweetAlert>;
        }

        return (
            <div className="ais_current-form-device-div ais_display-script">
                <div className="ais_header-txt-div docScript">
                    <label className="ais_current-mobile-device-label ais_download-instructions-header">{MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.HEADER}</label>
                    <div>
                        <img alt="invoice-icon" className="ais_confirm-delivery-icn" src={downloadIcon} />
                    </div>
                    <div className="">
                        <ol className="ais_download-instructions ais_instruction-section">
                            <li dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.INSTRUCTION_1 }}></li>
                            <li dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.INSTRUCTION_2 }}></li>
                            <li dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.INSTRUCTION_3 }}></li>
                            <li dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.INSTRUCTION_4 }}></li>
                            <li dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.INSTRUCTION_5 }}></li>
                        </ol>
                    </div>
                    <div className="ais_outer-align-button">
                        <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={(e) => this.handleSkip(e)} ><strong>{MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.BUTTONS[0]}</strong></button>
                        <div className="ais_device-divider"></div>
                       <a href="/Confirmation Form.pdf" ref ="download" download></a>
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={(e) => this.handleDownload(e)}>{MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.BUTTONS[1]}</button>
  
                    </div>
                    <div className="ais_download-caveat" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.DOWNLOAD_CONFIRMATION_FORM.CAVEAT }}></div>
                </div>
                {download}
                {alert}
            </div>
        )
    }
}
export default DownloadDocument;