import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { doFulfillmentInit } from '../../actions/Dashboard/doFindAgreements';
import MESSAGE_CONSTANTS from '../../config/journey-messages';

import termsIcon from "../../images/ais-images/icon-terms.png";

class AcceptTerms extends Component {

    constructor(props) {
        super(props)
        this.state = {
            acceptTermsClass: "ais_device-select-button term",
            disabled:false
        }
        this.handleAcceptTermsClick = this.handleAcceptTermsClick.bind(this);
    }

    handleAcceptTermsClick(){
        this.setState({
            acceptTermsClass: "ais_device-select-button term ais_selected ais_term",
            disabled:true
        })

        this.props.updateJourneyList(this.props.dataprops.source, MESSAGE_CONSTANTS.TERMS_ACCEPTED);
        // call fulfiloptions & repalement API
        this.props.doFulfillmentInit(this.props.cacheId, this.props.caseType, this.props.deviceMake);
    }


    render() {
        return (
            <div className="ais_current-terms-div">
              <div className="ais_header-terms-div">
                <label className="ais_current-mobile-device-label">{MESSAGE_CONSTANTS.AGREE_TERMS_CONDITION}</label>
                <div><img alt="terms-icn" className="ais_confirm-delivery-icn" src={termsIcon}/></div>
                <div className="ais_prefer-device-desc">
                <p dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WARRANTY_VOID_DISCLAIMER }}></p>
                </div>
                <button className={this.state.acceptTermsClass}  disabled ={this.state.disabled} onClick={this.handleAcceptTermsClick} >{MESSAGE_CONSTANTS.ACCEPT}</button>
              </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
  const {caseType, clientData, selectedBrand, deviceDetails} = state.customerData;

  return {
    caseType: caseType,
    cacheId: clientData.CacheId,
    deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
  }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        doFulfillmentInit
    }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(AcceptTerms);