import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  serviceOrderInit,
  setBackOrderCase,
  saveSelectedReplacementDevice,
  doGetReplacementInit,
  cancelServiceRequestInit,
  endInteractionInit,
  setInputState
} from '../../actions/Dashboard/doFindAgreements';

import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';

import {
  BaseImg,
} from '../../utils/utils';

class DeviceNLP extends React.Component {
  constructor(props) {
    super(props);
    console.log("DeviceNLP props", props)

    this.state = {
      waitButtonClasses: 'ais_device-select-button-custum ais_checkDevice',
      checkAlternateDevicesButtonClasses: 'ais_device-select-button-custum ais_checkDevice',
      cancelSRButtonClasses: 'ais_device-select-button-custum ais_checkDevice',
      disabled: false,
    };

    this.handleCheckDeviceClick = this.handleCheckDeviceClick.bind(this);
    this.handleWaitForClick = this.handleWaitForClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.replaceToken = this.replaceToken.bind(this);
  }

  handleCheckDeviceClick() {
    this.setState({
      checkAlternateDevicesButtonClasses:
        'ais_device-select-button-custum ais_checkDevice ais_selected',
      disabled: true,
    });
    this.props.setBackOrderCase(false);
    
    if (this.props.caseType === CONSTANTS.SWAP) {
      this.props.updateJourneyList(null, MESSAGE_CONSTANTS.DEVICE_STATUS_OUT_STOCK.buttonSelectAlternate);
      // // this.props.cancelServiceRequestInit(this.props.cacheId);
      // // this.props.doGetReplacementInit();
      // this.props.setInputState({
      //   source: CONSTANTS.SMART_STOCK,
      //   values: this.props.data.deviceList,
      // })
      this.props.updateJourneyList(
        CONSTANTS.SMART_STOCK,
        MESSAGE_CONSTANTS.DEVICE_STATUS_OUT_STOCK.buttonSelectAlternate
      );
    } else {
      this.props.updateJourneyList(
        CONSTANTS.ALTERNATE_DEVICE_LIST,
        CONSTANTS.ALTERNATIVE_DEVICES_MESSAGE
      );
    }
  }

  handleCancelClick() {
    this.setState({
      cancelSRButtonClasses:
        'ais_device-select-button-custum ais_checkDevice ais_selected',
      disabled: true,
    });
    this.props.setBackOrderCase(false);

    this.props.updateJourneyList(null, MESSAGE_CONSTANTS.DEVICE_STATUS_OUT_STOCK.buttonCancel);
    this.props.cancelServiceRequestInit(this.props.cacheId, {}, "Cancel_SR");
    this.props.endInteractionInit(this.props.cacheId);
  }

  handleWaitForClick() {
    let { cacheId, replacementDevice, caseType, serviceOrderData } = this.props;
    this.setState({
      waitButtonClasses: 'ais_device-select-button-custum ais_checkDevice ais_selected',
      disabled: true,
    });
    this.props.setBackOrderCase(true);
    this.props.saveSelectedReplacementDevice(replacementDevice);

    let serviceOrderId = serviceOrderData ? serviceOrderData.ServiceOrderId : null;
    if (!serviceOrderId) {
      this.props.serviceOrderInit(cacheId, replacementDevice, false, caseType);
    }
    this.props.updateJourneyList(
      CONSTANTS.ALTERNATE_DEVICE_LIST,
      CONSTANTS.WAIT_FOR_ENROLLED_DEVICE
    );
  }

  replaceToken(stringConstant, tokenName, tokenValue) {
    let result = stringConstant.replace(
      `{${tokenName}}`,
      tokenValue
    );

    return result
  }

  componentWillMount() {
    // console.log(this.props.data);
  }

  render() {
    const { replacementDevice, NLFLDeviceInStock } = this.props;
    const DeviceName = replacementDevice?.HorizonItemInformation ? `${replacementDevice?.HorizonItemInformation?.Make} ${replacementDevice?.HorizonItemInformation?.Model}` : "Unknown Device"

    return (
      <div className="ais_current-form-device-div" style={{ marginBottom: "30px", marginTop: "30px" }}>
        <div style={{
          display: "flex"
        }}>
          <div className="deviceIcon" style={{ minWidth: "42px", marginTop: "30px", marginLeft: "20px", justifyContent: "flex-start", "alignItems": "unset" }}>
            <BaseImg make={""} model={""} />
          </div>
          <div className="ais_header-txt-div" style={{ textAlign: "left", marginLeft: "0", marginBottom: "10px" }}>
            <label className="ais_current-mobile-device-label" style={{ textAlign: "left", marginBottom: "10px", color: "#DF070E" }}
              dangerouslySetInnerHTML={{ __html: this.replaceToken(this.props.data.scripts.header, "DeviceName", DeviceName) }}
            >
            </label>
            <p style={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: this.replaceToken(this.props.data.scripts.subHeader, "DeviceName", DeviceName) }}
            ></p>

            {NLFLDeviceInStock
              ?
              <>
                <p style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{ __html: this.replaceToken(this.props.data.scripts.subHeaderWithRpm, "DeviceName", DeviceName) }}
                ></p>
              </>
              :
              <>
                <p style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{ __html: this.replaceToken(this.props.data.scripts.subHeaderNoRpm, "DeviceName", DeviceName) }}
                ></p>
              </>
            }
          </div>
        </div>
          
        <div className="ais_outer-align-button" style={{ padding: "0 20px"}}>
          {NLFLDeviceInStock ? (
            <button
              className={this.state.checkAlternateDevicesButtonClasses}
              disabled={this.state.disabled}
              onClick={this.handleCheckDeviceClick}
            >
              {this.props.data.scripts.buttonSelectAlternate}
            </button>
          ) : (
            <button
              className={this.state.checkAlternateDevicesButtonClasses}
              disabled={this.state.disabled}
              onClick={this.handleWaitForClick}
            >
              {this.props.data.scripts.buttonWait}
            </button>
          )}

          <button
            className={this.state.cancelSRButtonClasses}
            disabled={this.state.disabled}
            onClick={this.handleCancelClick}
          >
            {this.props.data.scripts.buttonCancel}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    LFLDeviceInStock,
    NLFLDeviceInStock,
    serviceOrderData,
    caseType,
    replacementDevices,
    clientData,
  } = state.customerData;
  let deviceList = replacementDevices ? replacementDevices.ReplacementItem : [];
  return {
    LFLDeviceInStock: LFLDeviceInStock || false,
    NLFLDeviceInStock: NLFLDeviceInStock || false,
    caseType: caseType,
    replacementDevice: deviceList[0],
    cacheId: clientData.CacheId,
    serviceOrderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      serviceOrderInit,
      setBackOrderCase,
      saveSelectedReplacementDevice,
      doGetReplacementInit,
      cancelServiceRequestInit,
      endInteractionInit,
      setInputState
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceNLP);
