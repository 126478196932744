import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import { createNewServiceRequestInit, savePayload, processIncidentInit } from '../../actions/Dashboard/doFindAgreements';
import { SAVE_DEFECT_OPTION, SAVE_IMEI_MISMATCH } from '../../actions/Dashboard/actionTypes';
import CONSTANTS from '../../utils/Constants';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import MESSAGE_CONSTANTS from '../../config/journey-messages';

class EnquiryOptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDiv: null,
            disableComponent: false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (name, value) => {
        // console.log(name);

        let source;

        let {
            cacheId,
            caseType,
            savePayload,
            selectedDefectOption,
            dataprops: { enquiryType },
            deviceMake,
            resumePoint,
            updateJourneyList,
            resumeCanceled
        } = this.props;

        let ProcessIncidentParameters = {
            SessionId: cacheId,
            Incident: {
                FailureDescriptiveText: selectedDefectOption,
                IncidentType: caseType
            }
        }
        savePayload(SAVE_DEFECT_OPTION, value)
        let filter = MESSAGE_CONSTANTS.REQUEST_OPTIONS;
        let filter2 = MESSAGE_CONSTANTS.EXCHANGE_OPTIONS;
        let filteredValue = filter && filter.buttons.filter(data => data.name === value);
        let filteredValue2 = filter2 && filter2.buttons.filter(data => data.name === value);
        let namee = filteredValue && filteredValue[0] && filteredValue[0].name;
        let namee2 = filteredValue2 && filteredValue2[0] && filteredValue2[0].name;

        if (value === namee2 || (value === namee)) {
        } else {
            this.props.createNewServiceRequestInit(cacheId, ProcessIncidentParameters);
        }

        if (resumeCanceled && value !== namee2 && value != namee) {
            source = CONSTANTS.SUBMIT_IMEI_NUMBER;
        } else {

            if (enquiryType === 'request') {
                source = CONSTANTS.SOURCE_USER_INPUT.REQUEST;
            } else if (enquiryType === 'replacement') {
                source = CONSTANTS.SOURCE_USER_INPUT.ENQUIRY;
            } else if (enquiryType === 'swap' || enquiryType === 'malfunction') {
                MIXPANEL.track(EVENTLIST.DEVICE_ISSUE, { "Defect": name });

                if (resumePoint && resumePoint === 'CaptureIncidentDetails') {
                    source = CONSTANTS.SUBMIT_IMEI_NUMBER;
                    // source = CONSTANTS.CONFIRM_CURRENT_DEVICE;
                } else {
                    source = CONSTANTS.SOURCE_USER_INPUT.MALFUNCTION_SELECTED;
                }
                // routed the process incident after triage question in case of malfunction
            } else if (enquiryType === 'OEM-Exchange') {
                source = CONSTANTS.OEM_EXCHANGE_OPTIONS;

                /*if (caseType !== CONSTANTS.REPLACEMENT && (name === MESSAGE_CONSTANTS.OEM_EXCHANGE_SELECTED || name === MESSAGE_CONSTANTS.AIS_EXCHANGE_SELECTED)) {
                    let ProcessIncidentParameters = {
                        SessionId: cacheId,
                        Incident: {
                            FailureDescriptiveText: selectedDefectOption,
                            IncidentType: caseType
                        },
                        AssetDetails: {
                            AssetCatalogId: this.props.deviceDetails ? this.props.deviceDetails.AssetCatalog.AssetCatalogId : "",
                            SerialNumber: '000000000' + this.props.lastSixDigitsImei,
                        }
                    }
                    if (caseType === CONSTANTS.MALFUNCTION) {
                        let Triage = Object.assign({}, {
                            Triage: {
                                TriageAccepted: true,
                                TriageRefusalReason: "Triage refused by customer",
                                Notes: ""
                            }
                        });
                        _.extend(ProcessIncidentParameters.Incident, Triage);
                    }
                    this.props.processIncidentInit(ProcessIncidentParameters, cacheId)
                }*/
            }
        }

        if (!this.state.selectedDiv) {
            this.setState({
                selectedDiv: name
            });
            // console.log("NMM", name);
            updateJourneyList(source, name);
        }

        this.setState({
            disableComponent: true
        })
    }

    _renderOptions(option, i) {

        let btnClasses = classNames({
            "ais_options-selected-div": this.state.selectedDiv === option.text,
            "ais_options-div": this.state.selectedDiv !== option.text,
            "disabled": this.state.disableComponent
        })

        return <div name={option.name} key={i} className={btnClasses}
            onClick={() => this.handleClick(option.text, option.name)}>
            <label className={this.state.selectedDiv === option.text ? "ais_options-selected-label" : "ais_options-label"}>{option.text}</label></div>
    }

    render() {

        // Do not render same button option for current running flow.. 
        const { selectedDefectOption } = this.props;
        let buttonsToRender = this.props.data && this.props.data.buttons;
        if (selectedDefectOption && !this.state.disableComponent) {
            const filteredButtons = buttonsToRender.filter(({ name }) => name !== selectedDefectOption);
            if (filteredButtons.length) {
                buttonsToRender = filteredButtons;
            }
        }

        return (
            <Fragment>
                <div>
                    <div className="ais_info-container">
                        {/* <div className="ais_info-icon"></div> */}
                        <label className="ais_choose-option-txt">{this.props.data.title}</label>
                    </div>
                    {buttonsToRender && buttonsToRender.map((e, i) => this._renderOptions(e, i))}
                </div>
            </Fragment>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createNewServiceRequestInit,
        processIncidentInit,
        savePayload
    }, dispatch)
};


const mapStateToProps = state => {
    const { resumePoint, clientData, caseType, selectedDefectOption, selectedBrand, deviceDetails, lastSixDigitsImei, resumeCanceled } = state.customerData;
    return {
        cacheId: clientData.CacheId,
        resumePoint,
        caseType,
        selectedDefectOption,
        deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
        deviceDetails,
        lastSixDigitsImei,
        resumeCanceled,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(EnquiryOptions);
