import React from "react";
import { connect } from 'react-redux';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import CONSTANTS from '../../utils/Constants';
import EVENTLIST from '../../mixpanel/Eventlist';
import { bindActionCreators } from "redux";
import { savePayload, submitEnquiryDetails, doSaveDeliverySpeed, doSaveDeliveryDate } from "../../actions/Dashboard/doFindAgreements";
import { isClaimWithinCutoff, calculateDeliverySlot } from '../../utils/utils'
import scheduleDelivery from "../../images/ais-images/icon-delivery-schedule.png";
import wbko_date from "../../images/ais-images/wbko-date.png";
import wbko_calendar_small from "../../images/ais-images/wbko-calendar-small.png";
import moment from 'moment';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { WBKO_KEEP_OR_CONFIRM_COUNT } from "../../actions/Dashboard/actionTypes";
import { shippingmethodsV2Init } from "./../../actions/Dashboard/shippingMethodsV2";
import _find from "lodash/find";


class ChangeDeliveryScheduleEnquiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            noButtonClassName: "ais_device-select-button ais_credit-cardd",
            cancelButtonClassName: "ais_device-select-button ais_credit-cardd",
            deliveryDay: "",
            deliveryTime: "",
            deliveryWindow: "",
            showButton: "",
            deliverySlot: "",
            keepOrconfirmCount: 0
        }
        this.handleChangeClick = this.handleChangeClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }
    handleChangeClick() {
        MIXPANEL.track(EVENTLIST.RESCHEDULE_DELIVERY);
        this.setState({
            disable: true,
            noButtonClassName: "ais_device-select-button ais_credit-cardd ais_btnSel",
            keepOrconfirmCount: this.state.keepOrconfirmCount + 1
        });
        let { keepOrconfirmCount } = this.props;
        this.props.savePayload(WBKO_KEEP_OR_CONFIRM_COUNT, keepOrconfirmCount ? keepOrconfirmCount : this.state.keepOrconfirmCount + 1)
        this.props.shippingmethodsV2Init({ skipScheduleConfirmation: true })
    }
    handleCancelClick() {
        MIXPANEL.track(EVENTLIST.RESCHEDULE_DELIVERY);
        this.setState({
            disable: true,
            cancelButtonClassName: "ais_device-select-button ais_credit-cardd ais_btnSel"
        });
        this.props.updateJourneyList(CONSTANTS.WEB_GRID_VIEW_OPTIONS, "Cancel");
    }
    handleKeepClick = async () => {
        this.setState({
            disable: true
        });
        let { cacheId, selectedEnquiryOption, shippingMethodsResult, shippingOrderResult, selectedDeliveryDate, inquiryReason, keepOrconfirmCount, WbkoChangedAddressString, isWbkoSelected } = this.props;
        keepOrconfirmCount = keepOrconfirmCount ? keepOrconfirmCount : this.state.keepOrconfirmCount;
        if (selectedEnquiryOption) {
            var description = `${MESSAGE_CONSTANTS.CHANGE_DELIVERY_SCHEDULE_ENQUIRY} ${this.state.deliveryDay} ${this.state.deliverySlot}`;
            description = WbkoChangedAddressString ? WbkoChangedAddressString + "\n" + MESSAGE_CONSTANTS.WBKO_ADDRESS_STRING + (this.state.deliveryDay) + " " + this.state.deliverySlot  /* filteredArr[0].DISPLAY_OPTION */ : description;
            let UpdateDeliverySchedule = { DeliveryWindow: this.state.deliveryWindow, NewDeliveryDate: this.state.deliveryDate || this.state.deliveryDay };

            this.props.doSaveDeliveryDate(this.state.deliveryDay);
            if ((keepOrconfirmCount > 0) || (keepOrconfirmCount == 0 && WbkoChangedAddressString)) {
                let currentDeliveryObj = _find(this.props.shippingMethodsV2Result, {
                    EstimatedDeliveryDate: moment(this.state.deliveryDay).format(
                        CONSTANTS.ESTIMATED_DELIVERY_DATE_FORMAT
                    )
                });

                let MethodType = currentDeliveryObj && currentDeliveryObj.ShippingMethodType;
                let selectedDate = moment(new Date(this.state.deliveryDay)).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT);
                let currentDate = moment(new Date()).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT); moment(new Date());
                let compareFlag = selectedDate === currentDate;
                let deliveryWindow = MethodType === "Same Day" && compareFlag ? '6H' : '17H';
                this.props.doSaveDeliverySpeed(MethodType);

                UpdateDeliverySchedule = { DeliveryWindow: deliveryWindow, NewDeliveryDate: this.state.deliveryDate || this.state.deliveryDay };

                this.props.submitEnquiryDetails(description, selectedEnquiryOption, inquiryReason, CONSTANTS.ENQUIRY_SUB_REASON.CHANGE_DELIVERY_INFO, null, null, UpdateDeliverySchedule);
            }

            this.props.isWbkoSelected && keepOrconfirmCount == 0 && this.props.updateJourneyList(CONSTANTS.WBKO_KEEP, this.props.isWbkoSelected && keepOrconfirmCount === 0 ? this.props.data.WBKO_BUTTONS[1] :
                this.props.isWbkoSelected ? this.props.data.WBKO_BUTTONS[2] : this.props.data.BUTTONS[2]);
            //keepOrconfirmCount && keepOrconfirmCount === 0 && !WbkoChangedAddressString &&  this.props.isWbkoSelected && this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_DELIVERY_ENQUIRY);
        } else {
            if (selectedDeliveryDate) {
                this.props.updateJourneyList(this.props.dataprops.source, isWbkoSelected ? this.props.data.WBKO_BUTTONS[2] : this.props.data.BUTTONS[2]);
            } else {
                this.props.updateJourneyList(this.props.dataprops.source, isWbkoSelected ? this.props.data.WBKO_BUTTONS[1] : this.props.data.BUTTONS[1]);
            }
            this.props.updateShippingOrderInit(cacheId, shippingMethodsResult, shippingOrderResult, this.state.deliveryWindow, this.state.deliveryDate, true);
        }
    }
    UNSAFE_componentWillMount() {
        const {
            deliveryDate,
            selectedDeliveryDate,
            selectedDeliverySlot,
            EnquiryForChangeDeliveryDate,
            deliverySpeed, shippingMethodsV2Result,
        } = this.props;
        var d;
        if (!deliveryDate && !selectedDeliveryDate) {
            d = new Date(EnquiryForChangeDeliveryDate);
        } else if (selectedDeliveryDate) {
            d = new Date(selectedDeliveryDate);
        } else {
            d = new Date(deliveryDate);
        }
        if (d.getDay() === 0) {
            d = new Date(d.getTime() + 86400000);
        }
        const date = d.toDateString();

        let currentDate = moment(new Date()).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT); moment(new Date());

        let arr = shippingMethodsV2Result && shippingMethodsV2Result.filter(item => moment(new Date(item.EstimatedDeliveryDate)).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT) !== currentDate)
        let dd = moment(EnquiryForChangeDeliveryDate).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT);//arr && arr[0] && arr[0].EstimatedDeliveryDate;

        let ddFormat = new Date(dd);
        if (ddFormat.getDay() === 0) {
            ddFormat = new Date(ddFormat.getTime() + 86400000);
        }
        /* let currentDeliveryObj = _find(shippingMethodsV2Result, {
            EstimatedDeliveryDate: moment(date).format(
                CONSTANTS.ESTIMATED_DELIVERY_DATE_FORMAT
            )
        }); */
        let deliveryWindow = ddFormat.toDateString(); /* (compareFlag && !currentDeliveryObj) || (!currentDeliveryObj)
            ? ddFormat.toDateString()
            : date; */
        this.props.doSaveDeliveryDate(deliveryWindow);

        this.setState({
            deliveryDay: deliveryWindow,
            deliveryTime: deliverySpeed,
            deliveryWindow: CONSTANTS.NEXT_DAY_WINDOW,
            showButton: this.props.data.BUTTONS[1]
        });
    }

    render() {
        let { isWbkoSelected, keepOrconfirmCount, slot } = this.props;
        keepOrconfirmCount = keepOrconfirmCount ? keepOrconfirmCount : this.state.keepOrconfirmCount;
        return <div className="ais_current-form-device-div">
            <div className="ais_header-txt-div">
                {!isWbkoSelected && <label className="ais_current-mobile-device-label">
                    {this.props.data.HEADER}
                </label>}
                {isWbkoSelected && keepOrconfirmCount === 0 && <div className="wbkoConfirmDeliveryHeader" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_CONFIRM_CHANGE_DELIVERY_HEADER }} />}
                {/* <div className="confirm-delivery-icn"></div> */}
                <div>
                    {" "}<img alt="schedule-delivery-icn" className="ais_confirm-delivery-icn wbkoDateIcon" src={isWbkoSelected ? wbko_date : scheduleDelivery} />{" "}
                </div>
                {isWbkoSelected && keepOrconfirmCount > 0 && <div className="wbkoConfirmDeliveryHeader" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_CONFIRM_CHANGE_DELIVERY_HEADER }} />}
                {isWbkoSelected && keepOrconfirmCount > 0 && <img alt="schedule-delivery-icn" className="ais_confirm-delivery-icn wbkoDateIcon" src={wbko_calendar_small} />}
                <label className="ais_current--form-device-spec">
                    {this.state.deliveryDay}
                </label>
                {<div>
                    {" "}<label className="ais_current--form-device-spec">
                        {this.state.deliveryTime}
                    </label>{" "}
                </div>}
                {isWbkoSelected && keepOrconfirmCount > 0 && <div className="wbkoConfirmDeliverySchedleText" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_CONFIRM_CHANGE_DELIVERY }} />}

                {isWbkoSelected ?
                    <div className={`ais_outer-align-button `}>
                        <div className="ais_device-divider ais_button" />
                        <button disabled={this.state.disable} className={' margin-10 ' + this.state.noButtonClassName} onClick={e => this.handleChangeClick(e)}>
                            {this.props.data.WBKO_BUTTONS[0]}
                        </button>
                        <button disabled={this.state.disable} className={this.state.cancelButtonClassName} onClick={e => this.handleKeepClick(e)}>
                            {this.props.data.WBKO_BUTTONS[1]}
                        </button>
                    </div> :
                    <div className="ais_outer-align-button">
                        <button disabled={this.state.disable} className={this.state.cancelButtonClassName} onClick={e => this.handleCancelClick(e)}>
                            {this.props.data.BUTTONS[3]}
                        </button>
                        <div className="ais_device-divider ais_button" />
                        <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={e => this.handleChangeClick(e)}>
                            {this.props.data.BUTTONS[0]}
                        </button>
                    </div>}
                {/* <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={(e) => this.handleChangeClick(e)} >{this.props.data.BUTTONS[0]}</button> */}
            </div>
        </div>;
    }
}

const mapStateToProps = state => {
    const {
        selectedDeliveryDate,
        selectedDeliverySlot,
        clientData,
        shippingMethodsResult,
        determineIncidentResponse,
        isWbkoSelected,
        keepOrconfirmCount,
        selectedEnquiryOption,
        deliverySpeed,
        WbkoChangedAddressString,
        shippingMethodsV2Result
    } = state.customerData;
    return {
        shippingMethodsV2Result,
        WbkoChangedAddressString,
        deliverySpeed,
        selectedEnquiryOption,
        keepOrconfirmCount,
        isWbkoSelected,
        cacheId: clientData.CacheId,
        selectedDeliveryDate,
        selectedDeliverySlot,
        slot: shippingMethodsResult ? shippingMethodsResult.ShippingMethodType : null,
        EnquiryForChangeDeliveryDate: determineIncidentResponse ? determineIncidentResponse.ExpectedDeliveyDate : "",
        deliveryDate: shippingMethodsResult ? shippingMethodsResult.EstimatedDeliveryDate : null,
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            shippingmethodsV2Init, submitEnquiryDetails,
            savePayload,
            doSaveDeliverySpeed, doSaveDeliveryDate
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(
    ChangeDeliveryScheduleEnquiry
);