import React from "react";
import { connect } from 'react-redux';
import Img from 'react-image';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty'
import { bindActionCreators } from 'redux'

import { getFormattedPopularName, extractDeliverySlotFromDeliveryInstruction,getFormattedTotalFee } from '../../utils/utils';
import CONSTANTS from '../../utils/Constants';

import noImage from "./../../images/ais-images/icon-enrolled-device.png";
import { doSaveDeliveryDate, doSaveDeliverySlot } from "../../actions/Dashboard/doFindAgreements";
import MESSAGE_CONSTANTS from "../../config/journey-messages";

class CurrentRequestDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            srNo: "",
            type: "",
            sim: "",
            deviceInfo: "",
            address: "",
            deliveryDate: "",
            deliveryTime: "",
            fee: "",
            cardType: ""
        }
    }

    componentWillMount() {
        let { CustomerCaseNumber, IncidentType, CardBrand, ServiceFee, ShippingAddress = {}, ExpectedDeliveyDate, DeliveryInstructions, PopularName, Make, Model, CurrencyCode } = this.props.currentRequestDetails;

        let deliveryDate = moment(ExpectedDeliveyDate).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT);
        let deliverySlotObj = extractDeliverySlotFromDeliveryInstruction(DeliveryInstructions);

        deliverySlotObj = !_isEmpty(deliverySlotObj) ? deliverySlotObj : CONSTANTS.SLOT_NOT_AVAILABLE;

        this.props.doSaveDeliveryDate(deliveryDate);
        this.props.doSaveDeliverySlot(deliverySlotObj.deliverySlot, true);

        let deviceSpecs = getFormattedPopularName(PopularName);

        let deviceImageJPG = `${this.props.deviceURL}${Make}_${Model}_S.jpg`;
        let deviceImagePNG = `${this.props.deviceURL}${Make}_${Model}_S.png`;
        let deviceImage = <Img src={[
            deviceImageJPG,
            deviceImagePNG,
            noImage
        ]}
            className="ais_swop-device-img"
            alt="selected-device"
        />;

        this.setState({
            username: this.props.customerName,
            srNo: CustomerCaseNumber,
            type: IncidentType,
            deviceInfo: `${deviceSpecs.makename} ${deviceSpecs.modelName} ${deviceSpecs.specs} ${deviceSpecs.color}`,
            //sim: sim,
            address: ShippingAddress || {},
            deliveryDate: deliveryDate,
            deliveryTime: deliverySlotObj.humanReadableSlot ? deliverySlotObj.humanReadableSlot : MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE.SLOTS[1],
            cardType: CardBrand,
            fee: (CurrencyCode) ? `${CurrencyCode} ${getFormattedTotalFee(ServiceFee)}` : `THB ${getFormattedTotalFee(ServiceFee)}`,
            deviceImageUrl: deviceImage
        });
    }

    render() {
        let { srNo, type, deviceInfo, sim, address, deliveryDate, deliveryTime, fee, username, deviceImageUrl } = this.state;
        let { data, isBackOrderCase } = this.props;
        let { AssetCatalogName, Make, Model } = this.props.deviceDetails;
        let capsMake = Make.Name.toUpperCase();
        let capsModel = Model.Name.toUpperCase();
        let deviceImgName = capsMake + '_' + capsModel + '_S';
        let deviceImageJPG = this.props.deviceURL + deviceImgName + '.jpg';
        return (
            <div className="ais_confirm-details-div">
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label">{data.HEADER}</label>
                </div>
                <div className="ais_confirm-form-details-header-div ais_current-request-details">
                    <label className="ais_confirm-details-blue-label">{data.SR_NO}</label>
                    <label className="ais_confirm-details-blue-label">{data.TYPE}</label>
                    <label className="ais_confirm-details-dark-blue-label">{srNo}</label>
                    <label className="ais_confirm-details-dark-blue-label">{type}</label>

                    <div className="ais_swop-device-header-div">
                        <label className="ais_confirm-details-blue-label">{data.DEVICE}</label>

                        <div>
                            <img alt="" className="ais_current-form-device-icn" src={deviceImageJPG} onError={(e) => { e.target.src = noImage }} />
                            <div className="ais_swop-device-name">{deviceInfo + " " + sim}</div>
                        </div>
                    </div>

                    <div className="ais_swop-device-header-div">
                        <label className="ais_confirm-details-blue-label">{data.SHIPPING_TO}</label>
                        <div className="ais_other-details-desc">{username.fullName}</div>
                        {(address.Address1) ? <div><label className="ais_confirm-details-dark-label">{address.Address1}</label></div> : ''}
                        {(address.Address2) ? <div><label className="ais_confirm-details-dark-label">{address.Address2}</label></div> : ''}
                        {(address.Address3) ? <div><label className="ais_confirm-details-dark-label">{address.Address3}</label></div> : ''}
                        {(address.PostalCode) ? <div><label className="ais_confirm-details-dark-label">{address.PostalCode}</label></div> : ''}
                        {(address.City) ? <div><label className="ais_confirm-details-dark-label">{address.City}</label></div> : ''}
                        {(address.StateProvinceCode) ? <div><label className="ais_confirm-details-dark-label">{address.StateProvinceCode}</label></div> : ''}
                        {(address.CountryCode === "TH") ? <div><label className="ais_confirm-details-dark-label">{'Thailand'}</label></div> : ''}
                    </div>

                    {isBackOrderCase ? <div></div>
                        : <div className="ais_swop-device-header-div">
                            <label className="ais_confirm-details-blue-label">{data.DELIVERY_SCHEDULE}</label>
                            <div className="ais_other-details-desc">{deliveryDate + ", " + deliveryTime}</div>
                        </div>}


                    <div className="ais_swop-device-header-div">
                        <label className="ais_confirm-details-blue-label">{data.FEE}</label>
                        {/* <label className="confirm-details-blue-label">{data.FEE}</label>
                        <label className="confirm-details-dark-blue-label">{" Card "}</label> */}
                        <label className="ais_other-details-desc">{fee}</label>
                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        doSaveDeliveryDate,
        doSaveDeliverySlot,
    }, dispatch)
};

const mapStateToProps = state => {
    const {
        determineIncidentResponse,
        backOrderCase,
        customerName,
        deviceDetails,
        selectedBrand
      } = state.customerData;
    return {
        deviceURL: state.customerData.clientData.imageBaseUrl,
        currentRequestDetails: determineIncidentResponse,
        isBackOrderCase: backOrderCase,
        customerName,
        deviceDetails,
        deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentRequestDetails);