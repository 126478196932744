import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Img from 'react-image';
import _ from 'lodash';
import DisplayDeviceList from '../DisplayDeviceList/DisplayDeviceList';

import {
    createNewServiceRequestInit,
    processIncidentInit,
    setProgressBarValue,
    savePayload
} from '../../actions/Dashboard/doFindAgreements';

import CONSTANTS from '../../utils/Constants';

import { getFormattedPopularName } from '../../utils/utils';
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import noImage from "../../images/ais-images/icon-enrolled-device.png";
import { SAVE_SELECTED_ASSET_INSTANCE } from '../../actions/Dashboard/actionTypes';

class ConfirmCurrentDevice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
            deviceName: "",
            deviceSpec: ""
        }
        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleYesClick = this.handleYesClick.bind(this);
    }

    handleNoClick() {
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.dataprops.source, "No");
    }

    handleYesClick() {
        let {
            dataprops: { source },
            cacheId,
            selectedDefectOption,
            resumeCanceled,
            caseType,
            deviceMake,
            serviceRequestType,
            updateJourneyList,
            createNewServiceRequestInit,
            processIncidentInit,
            deviceDetails,
            customerCaseNumber
        } = this.props;
        updateJourneyList(source, "Yes");
        let ProcessIncidentParameters = {
            SessionId: cacheId,
            Incident: {
                FailureDescriptiveText: selectedDefectOption,
                IncidentType: caseType
            }
        }
        this.props.savePayload(SAVE_SELECTED_ASSET_INSTANCE, deviceDetails.AssetInstance);

        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });


      if ((!serviceRequestType || serviceRequestType === "StartServiceRequest")
    //   && !customerCaseNumber
      ) {
            createNewServiceRequestInit(cacheId, ProcessIncidentParameters);
        } else {
            if (resumeCanceled) {
                this.canceledPreviousSRCase(ProcessIncidentParameters);
            } else {
                if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
                    updateJourneyList(CONSTANTS.SUBMIT_IMEI_NUMBER, MESSAGE_CONSTANTS.YES_TEXT่);
                } else {
                    processIncidentInit(ProcessIncidentParameters, cacheId);
                }
            }
        }
    }

    canceledPreviousSRCase(ProcessIncidentParameters) {
        let {
            cacheId,
            checkForDeviceMismatch,
            caseType,
            deviceMake,
            updateJourneyList,
            processIncidentInit,
            setProgressBarValue
        } = this.props;

        if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
            if (checkForDeviceMismatch) {
                //   updateJourneyList(CONSTANTS.IMEI_VERFIED, MESSAGE_CONSTANTS.SUB_STAGES.VERIFIED_IMEI);
                if (caseType === CONSTANTS.MALFUNCTION) {
                    let Triage = Object.assign({}, {
                        Triage: {
                            TriageAccepted: true,
                            TriageRefusalReason: "Triage refused by customer",
                            Notes: ""
                        }
                    });
                    _.extend(ProcessIncidentParameters.Incident, Triage);
                }
                processIncidentInit(ProcessIncidentParameters, cacheId);
            } else {
                updateJourneyList(CONSTANTS.SUBMIT_IMEI_NUMBER, MESSAGE_CONSTANTS.YES_TEXT);
            }
            setProgressBarValue(100);
        } else {
            processIncidentInit(ProcessIncidentParameters, cacheId)
        }
    }

    render() {
        let { deviceDetails } = this.props;
        let { AssetCatalog, Make, Model } = deviceDetails || {};
        let capsMake = Make.Name.toUpperCase();
        let capsModel = Model.Name.toUpperCase();
        let deviceImgName = capsMake + '_' + capsModel + '_S';
        let deviceSpecs = getFormattedPopularName(AssetCatalog.AssetCatalogName);
        let deviceImageJPG = this.props.deviceURL + deviceImgName + '.jpg';
        let deviceImagePNG = this.props.deviceURL + deviceImgName + '.png';
        let deviceImage = <Img src={[
            deviceImageJPG,
            deviceImagePNG,
            noImage
        ]}
            className="ais_current-form-device-icn"
        />;

        return (
            _.isEmpty(this.props.replacedAsset) ?
                <div className="ais_current-form-device-div">
                    <div className="ais_current-form-device-header-div">
                        <label className="ais_current-mobile-device-label">
                            {this.props.data.HEADER}
                        </label>
                        <div className="ais_current-mobile-device-content">
                            {this.props.data.CONTENT}
                        </div>
                    </div>
                    <div className="ais_current-popup-center-div">
                        <img alt="" className="ais_current-form-device-icn" src={deviceImageJPG} onError={(e) => { e.target.src = noImage }} />

                        <div className="ais_current-form-your-device-name-div">
                            <div className="ais_current-form-device-main-name">{deviceSpecs.modelName}</div>
                            <div className="ais_current-form-device-desc">{deviceSpecs.specs} {deviceSpecs.color}</div>
                        </div>
                    </div>
                    <div className="ais_outer-align-button">
                        <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={(e) => this.handleNoClick(e)} >{this.props.data.BUTTONS[0]}</button>
                        <div className="ais_device-divider"></div>
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={(e) => this.handleYesClick(e)} >{this.props.data.BUTTONS[1]}</button>
                    </div>
                </div>
                 :
                <div>
                    <DisplayDeviceList dataprops={this.props.dataprops} data={this.props.data} updateJourneyList={this.props.updateJourneyList} />
                </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createNewServiceRequestInit,
        processIncidentInit,
        setProgressBarValue,
        savePayload
    }, dispatch)
};


const mapStateToProps = state => {
    let { clientData, inputState, caseType,customerCaseNumber, serviceRequestType, holds, makeAndModelList, selectedBrand, deviceDetails, replacedAsset, resumeCanceled, selectedDefectOption } = state.customerData;
    return {
        cacheId: clientData.CacheId,
        userInputState: inputState,
        deviceDetails,
        caseType,
        deviceURL: clientData.imageBaseUrl,
        serviceRequestType,
        isHoldApplied: _.find(holds, { 'HoldType': 'DOCUMENT' }),
        checkForDeviceMismatch: makeAndModelList,
        resumeCanceled,
        selectedDefectOption,
        replacedAsset,
        deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
        customerCaseNumber
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCurrentDevice);
