import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import CONSTANTS from "../../utils/Constants"; 
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty'
import { bindActionCreators } from 'redux'
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import wbkoTracking from "../../images/ais-images/wbko-tracking.svg";
import DeliveryDone from "../../images/ais-images/icon-track-delivery-done.png";
import wbko_task_list_to_do from "../../images/ais-images/wbko-task-list-to-do.png";

import { getFormattedPopularName, extractDeliverySlotFromDeliveryInstruction, getFormattedTotalFee } from '../../utils/utils';

import noImage from "./../../images/ais-images/icon-enrolled-device.png";
import { doSaveDeliveryDate, doSaveDeliverySlot, shippingMethodsInit, getContactDetails } from "../../actions/Dashboard/doFindAgreements";
import checkedStatusIcon from "../../images/ais-images/icon-track-delivery-done.png";
import greylineIcon from "../../images/ais-images/tracking-dots-normal.png";
import greenlineIcon from "../../images/ais-images/tracking-dots-active.png";
import firstDefaultIcon from "../../images/ais-images/track-delivery-step-1-default.png";
import secondDefaultIcon from "../../images/ais-images/track-delivery-step-2-default.png";
import thirdDefaultIcon from "../../images/ais-images/track-delivery-step-3-default.png";
import fourthDefaultIcon from "../../images/ais-images/track-delivery-step-4-default.png";
import fifthDefaultIcon from "../../images/ais-images/track-delivery-step-5-default.png";
//import "./trackMyDelivery.css";
import { SAVE_SELECTED_ENQUIRY_OPTION } from '../../actions/Dashboard/actionTypes';
import { savePayload } from '../../actions/Dashboard/doFindAgreements';
import { shippingmethodsV2Init } from "./../../actions/Dashboard/shippingMethodsV2";

class WbkoTrackMyDeliveryNew extends Component {

    constructor(props) {
        super(props);
        console.log("props", props)
        this.state = {
          disable: false,
          proceedDeliveryButtonClassName: "ais_device-select-button",
          cancelSRButtonClassName: props.ShippingStatus === "BORD" ? "ais_device-select-button" : "ais_device-select-button-custum",
        }
        this.handleProceedDeliveryClick = this.handleProceedDeliveryClick.bind(this);
        this.handleCancelSRClick = this.handleCancelSRClick.bind(this);
    }
    handleCancelSRClick() {
      this.setState({
        disable: true,
        cancelSRButtonClassName: "ais_device-select-button ais_btnSel"
      });
      this.props.savePayload(SAVE_SELECTED_ENQUIRY_OPTION, CONSTANTS.CANCEL_DELIVERY_TRACK);
      // this.props.updateJourneyList(CONSTANTS.WBKO_CANCELLATION_REASON);
      this.props.updateJourneyList(CONSTANTS.WBKO_CANCELLATION_REASON_NEW);
    }
    handleProceedDeliveryClick() {
      this.setState({
        disable: true,
        proceedDeliveryButtonClassName: "ais_device-select-button-custum ais_btnSel"
      });
      this.props.savePayload(SAVE_SELECTED_ENQUIRY_OPTION, CONSTANTS.RESCHEDULE_DELIVERY_TRACK);
      //this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY, MESSAGE_CONSTANTS.WBKO_RESCHEDULE.ProceedButton);
      //this.props.savePayload(WBKO_KEEP_OR_CONFIRM_COUNT, keepOrconfirmCount ? keepOrconfirmCount : this.state.keepOrconfirmCount + 1)
      this.props.shippingmethodsV2Init({ skipScheduleConfirmation: true, isWBKOReschedule: true })
    }
    componentDidMount() {
        this.props.getContactDetails(this.props.cacheId, "", "");
    }
    getDAXSalesOrderId() {
        const { ShippingStatus, CustomerCaseStatus, Servicerequests } = this.props
        try {
            if (ShippingStatus === 'SHIP' && CustomerCaseStatus === 'CMPLTD')
                return Servicerequests[0].ShippingOrder.DAXSalesOrderId
        } catch (e) {
            return null
        }
    }
    checkIfWBKOLFL(Assets, srDeviceName) {
      let enrolledAssets = Assets.filter((value) => value.AssetInstance === 'ENROLLED');
      let currentAsset = enrolledAssets[0];
      let replacedAssets = Assets.filter((value) => value.AssetInstance === 'REPLACED');
      let sortedReplacedAssetsByCreateDate = replacedAssets && replacedAssets.length
        ? replacedAssets.sort((a, b) => moment(b.CreatedDate).format('YYYYMMDD') - moment(a.CreatedDate).format('YYYYMMDD'))
        : null;

      if (currentAsset.EndDate) {
        if (!moment(currentAsset.EndDate).isValid() || moment().isAfter(moment(currentAsset.EndDate))) {
          if (sortedReplacedAssetsByCreateDate &&
            sortedReplacedAssetsByCreateDate.length &&
            sortedReplacedAssetsByCreateDate[0] &&
            sortedReplacedAssetsByCreateDate[0].StartDate &&
            moment(sortedReplacedAssetsByCreateDate[0].StartDate).isAfter(moment(currentAsset.StartDate))) {
            currentAsset = sortedReplacedAssetsByCreateDate[0];
          }
        }
      } else {
        if (sortedReplacedAssetsByCreateDate) {
          if (moment(currentAsset.StartDate).format('YYYYMMDD') !==
            moment(sortedReplacedAssetsByCreateDate[0].StartDate).format('YYYYMMDD')) {
            if (moment(currentAsset.StartDate).isBefore(moment(sortedReplacedAssetsByCreateDate[0].StartDate))) {
              currentAsset = (sortedReplacedAssetsByCreateDate[0]) || currentAsset;
            }
          }
        } else {
          let notCusVerifyAssets = agreement.AssetsList.Asset.filter((value) => (value.AssetInstance === 'ENROLLED' || value.AssetInstance === 'REPLACED'));
          let sortedNotCusVerifyAssets = notCusVerifyAssets.sort((a, b) => moment(b.CreatedDate).format('YYYYMMDD') - moment(a.CreatedDate).format('YYYYMMDD'))
          currentAsset = sortedNotCusVerifyAssets[0];
        }
      }

      if (currentAsset.AssetInstance === "REPLACED" && sortedReplacedAssetsByCreateDate.length > 1) {
        if (moment(currentAsset.StartDate).format('YYYYMMDD') ===
          moment(enrolledAssets[0].StartDate).format('YYYYMMDD') &&
          moment(sortedReplacedAssetsByCreateDate[1].StartDate).isAfter(moment(enrolledAssets[0].StartDate))) {
          currentAsset = sortedReplacedAssetsByCreateDate[1];
        }
      }

      console.log("currentAsset", currentAsset)
      console.log("currentAsset catalogName:", currentAsset.AssetCatalog?.AssetCatalogName)
      console.log("srDeviceName:", srDeviceName)

      return currentAsset.AssetCatalog?.AssetCatalogName === srDeviceName
    }
    componentWillMount() {
        let { currentRequestDetails, customerName, AssetsList } = this.props;
        let { CustomerCaseNumber, IncidentType, CardBrand, ServiceFee, ShippingAddress = {}, ExpectedDeliveyDate, DeliveryInstructions, PopularName, Make, Model, CurrencyCode, DeliveryWindow } = currentRequestDetails;

        let deliveryDate = moment(ExpectedDeliveyDate).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT);
        let slotValue = DeliveryInstructions && DeliveryInstructions.substr(0, DeliveryInstructions.indexOf("|"));
        let slotTime = (slotValue && slotValue === "6") || (slotValue && slotValue === "6H") ?
            MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE.SLOTS[0] : MESSAGE_CONSTANTS.CONFIRM_DELIVERY_SCHEDULE.SLOTS[1];
        //let deliverySlotObj = extractDeliverySlotFromDeliveryInstruction(DeliveryInstructions);
        let deliverySlotObj = !_isEmpty(deliverySlotObj) ? deliverySlotObj : CONSTANTS.SLOT_NOT_AVAILABLE;

        this.props.doSaveDeliveryDate(deliveryDate);
        this.props.doSaveDeliverySlot(deliverySlotObj.deliverySlot, true);

        let deviceSpecs = getFormattedPopularName(PopularName);

        /* let deviceImageJPG = `${this.props.deviceURL}${Make}_${Model}_S.jpg`;
        let deviceImagePNG = `${this.props.deviceURL}${Make}_${Model}_S.png`;
        let deviceImage = <Img src={[
            deviceImageJPG,
            deviceImagePNG,
            noImage
        ]}
            className="ais_swop-device-img"
            alt="selected-device"
        />; */

        this.setState({
            username: customerName,
            srNo: CustomerCaseNumber,
            type: IncidentType,
            deviceInfo: `${deviceSpecs.makename} ${deviceSpecs.modelName} ${deviceSpecs.specs} ${deviceSpecs.color}`,
            //sim: sim,
            address: ShippingAddress || {},
            deliveryDate: deliveryDate,
            deliveryTime: slotTime, // deliverySlotObj.humanReadableSlot,
            cardType: CardBrand,
            fee: (CurrencyCode) ? `${CurrencyCode} ${getFormattedTotalFee(ServiceFee)}` : `THB ${getFormattedTotalFee(ServiceFee)}`,
            isLFL: this.checkIfWBKOLFL(AssetsList, PopularName),
            deliveryWindow: DeliveryWindow
            //deviceImageUrl: deviceImage
        });
    }
    getActiveIndex() {
        //It actually returns the index of the step which is last completed on tracking UI.
        const { ShippingStatus, CustomerCaseStatus } = this.props;

        if (ShippingStatus === CONSTANTS.SHIPPING_STATUS.SHIP) {
            return 3;
        }
        if (ShippingStatus === CONSTANTS.SHIPPING_STATUS.DELIVERED) {
            return 4
        }
        return 0
    }

    replaceToken(stringConstant, tokenName, tokenValue) {
      let result = stringConstant.replace(
        `{${tokenName}}`,
        tokenValue
      );
  
      return result
    }

    render() {
        const DAXSalesOrderId = this.getDAXSalesOrderId();
        let { serviceRequestNumber, ShippingStatus, isBackOrderCase, Servicerequests, PaymentMethodType, UpdatedDate, customerName } = this.props;
        let { srNo, type, deviceInfo, deliveryDate, deliveryTime, cardType, fee, address, isLFL, deliveryWindow } = this.state;
        const stages = [
            {
                icon: firstDefaultIcon,
                label: MESSAGE_CONSTANTS.WBKO_CONFIRMED
            }, {
                icon: secondDefaultIcon,
                label: MESSAGE_CONSTANTS.WBKO_PROCESSING_ORDER
            }, {
                icon: thirdDefaultIcon,
                label: MESSAGE_CONSTANTS.WBKO_SHIPPED
            }
        ]
        const activeIndex = this.getActiveIndex()
      return (
        <div style={{margin: "30px 0"}}>
          {!deliveryWindow && <p>
            <strong>{this.replaceToken(MESSAGE_CONSTANTS.WBKO_RESCHEDULE.greetings, "CustomerName", customerName?.fullName || `${customerName?.firstName} ${customerName?.lastName}`)}</strong>
          </p>}

          {!deliveryWindow && ShippingStatus === "BORD" &&
            <p>
              {MESSAGE_CONSTANTS.WBKO_RESCHEDULE.OOSTitle}
            </p>
          }

          {!deliveryWindow && ShippingStatus === "READY" && isLFL &&
            <p>
              {this.replaceToken(MESSAGE_CONSTANTS.WBKO_RESCHEDULE.LFLTitle, "DeviceName", deviceInfo.trim())}
            </p>
          }

          {!deliveryWindow && ShippingStatus === "READY" && !isLFL &&
            <p>
              {this.replaceToken(MESSAGE_CONSTANTS.WBKO_RESCHEDULE.NLFLTitle, "DeviceName", deviceInfo.trim())}
            </p>
          }

          <div className="ais_current-form-device-div ">
            <div className="ais_header-txt-div">
              <div><img src={wbko_task_list_to_do} alt='' /></div>

              <div style={{border: "solid 1px #E7E9EA", borderBottomWidth: "0", marginTop: "20px"}}>
                <div className='wbkoMargin15 wbkoSrHeader' style={{margin: "0", padding: "7px 20px", borderBottom: "solid 1px #E7E9EA"}} >{MESSAGE_CONSTANTS.WBKO_SERVICE_DETAILS}</div>
                <div className='margin-bottom20 '>
                  <div className='wbkoSrDetails' style={{ borderBottom: "solid 1px #E7E9EA" }}>
                    <div className="wbkoPadding5left" style={{ marginRight: "0", padding: "7px", textAlign: "left", color: "inherit", fontWeight: "bold", backgroundColor: "#F5F5F5", borderRight: "solid 1px #E7E9EA" }}>{MESSAGE_CONSTANTS.WBKO_SR_NO}</div>
                    <div className="wbkoPadding5right" style={{ padding: "7px" }}>{srNo} </div>
                  </div>
                  <div className='wbkoSrDetails' style={{ borderBottom: "solid 1px #E7E9EA" }}>
                    <div className="wbkoPadding5left" style={{ marginRight: "0", padding: "7px", textAlign: "left", color: "inherit", fontWeight: "bold", backgroundColor: "#F5F5F5", borderRight: "solid 1px #E7E9EA" }}>{MESSAGE_CONSTANTS.WBKO_SR_TYPE} </div>
                    <div className="wbkoPadding5right" style={{ padding: "7px" }}>{type} </div>
                  </div>
                  <div className='wbkoSrDetails' style={{ borderBottom: "solid 1px #E7E9EA" }}>
                    <div className="wbkoPadding5left" style={{ marginRight: "0", padding: "7px", textAlign: "left", color: "inherit", fontWeight: "bold", backgroundColor: "#F5F5F5", borderRight: "solid 1px #E7E9EA" }}>{MESSAGE_CONSTANTS.WBKO_DEVICE_DETAILS} </div>
                    <div className="wbkoPadding5right" style={{ padding: "7px" }}>{deviceInfo.trim()} </div>
                  </div>
                  <div className='wbkoSrDetails' style={{ borderBottom: "solid 1px #E7E9EA" }}>
                    <div className="wbkoPadding5left" style={{ marginRight: "0", padding: "7px", textAlign: "left", color: "inherit", fontWeight: "bold", backgroundColor: "#F5F5F5", borderRight: "solid 1px #E7E9EA" }}>{MESSAGE_CONSTANTS.WBKO_DELIVERY_ADDRESS} </div>
                    <div className="wbkoPadding5right" style={{ padding: "7px" }}>
                      {`${address.Address1 ? address.Address1 : ''},${address.Address2 ? address.Address2 : ''},${address.Address3 ? address.Address3 : ''},${address.PostalCode ? address.PostalCode : ''},${address.City ? address.City : ''},${address.StateProvinceCode ? address.StateProvinceCode : ''},${address.CountryCode === "TH" ? 'Thailand' : ''}`}
                    </div>
                  </div>
                  <div className='wbkoSrDetails' style={{ borderBottom: "solid 1px #E7E9EA" }}>
                    <div className="wbkoPadding5left" style={{ marginRight: "0", padding: "7px", textAlign: "left", color: "inherit", fontWeight: "bold", backgroundColor: "#F5F5F5", borderRight: "solid 1px #E7E9EA" }}>{MESSAGE_CONSTANTS.WBKO_SR_FEE} </div>
                    <div className="wbkoPadding5right" style={{ padding: "7px" }}>{fee}</div>
                  </div>
                  <div className='wbkoSrDetails' style={{ borderBottom: "solid 1px #E7E9EA" }}>
                    <div className="wbkoPadding5left" style={{ marginRight: "0", padding: "7px", textAlign: "left", color: "inherit", fontWeight: "bold", backgroundColor: "#F5F5F5", borderRight: "solid 1px #E7E9EA" }}>{MESSAGE_CONSTANTS.WBKO_MODE_OF_PAYMENT} </div>
                    <div className="wbkoPadding5right" style={{ padding: "7px" }}>{PaymentMethodType === 'COD' ? MESSAGE_CONSTANTS.COD_TEXT : MESSAGE_CONSTANTS.CREDIT_CARD_TEXT}</div>
                  </div>
                  <div className='wbkoSrDetails' style={{ borderBottom: "solid 1px #E7E9EA" }}>
                    <div className="wbkoPadding5left" style={{ marginRight: "0", padding: "7px", textAlign: "left", color: "inherit", fontWeight: "bold", backgroundColor: "#F5F5F5", borderRight: "solid 1px #E7E9EA" }}>{MESSAGE_CONSTANTS.WBKO_SUBMISSION_DATE} </div>
                    <div className="wbkoPadding5right" style={{ padding: "7px" }}>{moment(UpdatedDate).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT)} </div>
                  </div>

                  {!deliveryWindow ? <></>
                    : <div className='wbkoSrDetails' style={{ borderBottom: "solid 1px #E7E9EA" }}>
                      <div className="wbkoPadding5left" style={{ marginRight: "0", padding: "7px", textAlign: "left", color: "inherit", fontWeight: "bold", backgroundColor: "#F5F5F5", borderRight: "solid 1px #E7E9EA" }}>{MESSAGE_CONSTANTS.WBKO_EXPECTED_DELIVERY_DATE} </div>
                      <div className="wbkoPadding5right" style={{ padding: "7px" }}>{deliveryDate + ", " + deliveryTime} </div>
                    </div>}
                </div>
              </div>

              {deliveryWindow &&
                <div>
                  {stages.map((stage, index) => <Fragment key={index}>
                    <div key={index} className='wbkoDeliveryFlow'>
                      <div><img src={activeIndex >= index ? checkedStatusIcon : stage.icon} alt='' /></div>
                      <div className={` ${activeIndex >= index ? ' ' : ' wbkoDefaultStatusText '} wbkoDeliveryFlowContent`}>{stage.label}</div>

                    </div>
                  </Fragment>)}
                  {DAXSalesOrderId && <div className="wbkoShippedTrack">
                    <a className="wbkoCheckDeliverySttausDHL" href={`https://ecommerceportal.dhl.com/track/?ref=THAMY${DAXSalesOrderId}`} title="Click Here to Track the device" target="_blank" >{MESSAGE_CONSTANTS.WBKO_DELIVERY_STATUS_AFTER_SHIPPING}</a>
                  </div>}
                </div>
              }

              {!deliveryWindow && ShippingStatus === "BORD" &&
                <div className="ais_outer-align-button">
                  <button disabled={this.state.disable} style={{ width: "100%"}} className={this.state.cancelSRButtonClassName} onClick={e => this.handleCancelSRClick(e)}>
                    {MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelSRButton}
                  </button>
                </div>
              }

              {!deliveryWindow && ShippingStatus === "READY" &&
                <div className="ais_outer-align-button">
                  <button disabled={this.state.disable} style={{ width: "100%", marginBottom: "10px" }} className={this.state.proceedDeliveryButtonClassName} onClick={e => this.handleProceedDeliveryClick(e)}>
                    {MESSAGE_CONSTANTS.WBKO_RESCHEDULE.ProceedButton}
                  </button>

                  <button disabled={this.state.disable} style={{ width: "100%", marginTop: "0" }} className={this.state.cancelSRButtonClassName} onClick={e => this.handleCancelSRClick(e)}>
                    {MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelSRButton}
                  </button>
                </div>
              }
              
            </div>
          </div>
        </div>
      )
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ savePayload, doSaveDeliveryDate, doSaveDeliverySlot, shippingMethodsInit, getContactDetails, shippingmethodsV2Init }, dispatch);
};
const mapStateToProps = state => {
    console.log("state.customerData", state.customerData)
    const { determineIncidentResponse, customerName, shippingStatus, clientData, backOrderCase, PaymentMethodType, UpdatedDate, selectedAgreement } = state.customerData;
    return {
        cacheId: clientData.CacheId,
        currentRequestDetails: determineIncidentResponse,
        CustomerCaseStatus: determineIncidentResponse.CustomerCaseStatus || null,
        ShippingStatus: determineIncidentResponse.ShippingStatus || null,
        Servicerequests: determineIncidentResponse.Servicerequests || [],
        customerName,
        isBackOrderCase: backOrderCase,
        PaymentMethodType,
        UpdatedDate,
        AssetsList: selectedAgreement.Agreement.Assets.Asset,
        DeliveryWindow: determineIncidentResponse.DeliveryWindow
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WbkoTrackMyDeliveryNew);