import * as React from 'react';
import { ChakraProvider, Box, Container, VStack, Text } from '@chakra-ui/react';
import theme from '../../themes/main';
import FilterOptions from './FilterOptions';
import mockedDevicesResponse from './mocked.json';
import { useMachine } from '@xstate/react';
import createSmartStockMachine from './machines/smartStock';
import {
  find,
  filter,
  map,
  maxBy,
  orderBy,
  get,
  uniq,
  toNumber,
  chain,
  pickBy,
  toPairs,
  isArray,
  identity,
  remove,
  noop,
  isUndefined,
} from 'lodash';
import DeviceCarousel from './DeviceCarousel';
import { inspect } from '@xstate/inspect';
import { DeviceGrid } from './DeviceGrid';
import CssReset from './CssReset';
import { isDev } from '../../utils/utils';
import FormOverlay from './FormOverlay';
import { useTranslation } from 'react-i18next';

const mockedDevices =
  mockedDevicesResponse.ReplacementResponse.ReplcementMatrix.ReplacementEquipmentResults
    .ReplacementItems.ReplacementItem;

const { useCallback, useEffect } = React;

if (process.env.NODE_ENV === 'development') {
  inspect({ iframe: false });
}

export const SMART_STOCK_LOSS_TYPE = ['UP1', 'DOWN1'];

export const SmartStock = ({ devices = isDev() ? mockedDevices : [], onDone = noop }) => {
  const { t } = useTranslation('', { keyPrefix: 'SmartStock' });
  const [state, push] = useMachine(createSmartStockMachine, {
    devTools: isDev(),
    services: {
      filterDevices: (context, event) => (callback) => {
        const filterIfNotEmpty = (criteria) => (inputArray) => {
          let intermediate = inputArray;
          let criteriaFn = identity;
          toPairs(pickBy(criteria)).forEach((criteria) => {
            if (isArray(criteria[1])) {
              criteriaFn =
                ([value, [low, high]]) =>
                (current) =>
                  low <= current[value] && current[value] <= high;
            }
            intermediate = filter(intermediate, criteriaFn(criteria));
          });
          return intermediate;
        };

        const devices = chain(context.devices).thru(filterIfNotEmpty(event.filterBy)).value();
        callback({
          type: 'DONE',
          devices,
        });
      },
      onDone: (context) => {
        const selectedDevice = find(devices, [
          'SCMItemInformation.DAXItemId',
          context.selectedDevice,
        ]);
        onDone?.(selectedDevice);
      },
    },
  });

  useEffect(() => {
    const remappedDevices = chain(devices)
      .filter((device) => device.IsLikeForLike || device.IsInStock)
      .thru((devices) => {
        const lflAvailable = find(devices, 'IsLikeForLike').IsInStock || false;
        return devices.filter(
          (device) =>
            device.IsLikeForLike ||
            device.IsLikeForLike === lflAvailable ||
            SMART_STOCK_LOSS_TYPE.includes(device.LossType)
        );
      })
      // .filter((device) => device.IsLikeForLike === !!find(devices, 'IsLikeForLike') || SMART_STOCK_LOSS_TYPE.includes(device.LossType))
      .map((device) => {
        const {
          SCMItemInformation: { DAXItemId: id, Color: color, Make: brand, Model: model },
          HorizonItemInformation: {
            AssetFamily: phoneName,
            AssetCapacity: capacity,
            AssetCatalogStartDate: releaseDate,
          },
          ServiceFee: serviceFee,
          IsInStock: isInStock,
          IsLikeForLike: isLikeForLike,
          IsAvailableForBackorder: isAvailableForBackorder,
          IsNotCurrentlyAvailableForOrder: isNotCurrentlyAvailableForOrder,
          IsNoLongerProduced: isNoLongerProduced,
          LossType: lossType,
          PhysicalInvent: stock,
        } = device;

        return {
          id,
          brand,
          color,
          model,
          phoneName,
          capacity: capacity * (capacity < 2 ? 1000 : 1),
          serviceFee: parseInt(serviceFee.match(/[+-]?\d+(\.\d+)?/g)[0] || 0),
          stock: toNumber(stock),
          lossType,
          releaseDate,
          flags: {
            isLikeForLike,
            isInStock,
            isAvailableForBackorder,
            isNotCurrentlyAvailableForOrder,
            isNoLongerProduced,
          },
          rawDevice: device,
        };
      })
      .value();
    push({
      type: 'SET_DEVICES',
      devices: remappedDevices,
    });

    /**
     * Setup filter options
     * --------------------------------------------------------------------------
     */
    const colors = uniq(map(remappedDevices, 'color')).sort();
    push({
      type: 'SET_FILTER_OPTIONS',
      option: 'colors',
      value: colors,
    });

    const capacity = uniq(map(remappedDevices, 'capacity')).sort((a, b) => b - a);
    push({
      type: 'SET_FILTER_OPTIONS',
      option: 'capacity',
      value: capacity,
    });

    const maxSrf = get(maxBy(remappedDevices, 'serviceFee'), 'serviceFee');
    push({
      type: 'SET_FILTER_OPTIONS',
      option: 'srfRange',
      value: [0, maxSrf],
    });
    // ==========================================================================
    // @end: filter options
    // ==========================================================================

    /**
     * Setup pre-sorted lists
     * @see {@link ./README.md|README}
     * --------------------------------------------------------------------------
     */

    // POPULAR
    const popular = orderBy(
      remappedDevices,
      ['stock', 'serviceFee', 'capacity', 'releaseDate'],
      ['desc', 'asc', 'desc', 'desc']
    );
    push({
      type: 'SET_PRE_SORTED_DEVICES',
      criteria: 'popular',
      devices: popular,
    });
    // --------------------------------------------------------------------------

    // BEST DEAL
    const bestDeal = orderBy(
      remappedDevices,
      ['serviceFee', 'stock', 'capacity', 'releaseDate'],
      ['asc', 'desc', 'desc', 'desc']
    );
    push({
      type: 'SET_PRE_SORTED_DEVICES',
      criteria: 'bestDeal',
      devices: bestDeal,
    });
    // --------------------------------------------------------------------------

    // Latest
    const latest = orderBy(
      remappedDevices,
      ['releaseDate', 'stock', 'serviceFee', 'capacity'],
      ['desc', 'desc', 'asc', 'desc']
    );
    push({
      type: 'SET_PRE_SORTED_DEVICES',
      criteria: 'latest',
      devices: latest,
    });
    // --------------------------------------------------------------------------

    // Recommended
    const recommended = orderBy(
      remappedDevices,
      ['capacity', 'stock', 'serviceFee', 'releaseDate'],
      ['desc', 'desc', 'asc', 'desc']
    );
    push({
      type: 'SET_PRE_SORTED_DEVICES',
      criteria: 'recommended',
      devices: recommended,
    });
    // --------------------------------------------------------------------------

    // PROCESS INITIAL LIST
    const findAndAttachRibbon = (input, ribbonLabel, predicate, ribbonProps = {}) => {
      const selectedDevice = find(input, predicate);
      if (selectedDevice) {
        return {
          ...selectedDevice,
          ribbonLabel,
          ribbonProps,
        };
      }
      return undefined;
    };

    let initialDevices = [];
    const lflServiceFee = get(find(remappedDevices, 'flags.isLikeForLike'), 'serviceFee');
    initialDevices.push(
      findAndAttachRibbon(remappedDevices, t('labels.registeredDevice'), 'flags.isLikeForLike', {
        color: '#4A227C',
        shadow: '#4d3b63',
      })
    );

    const labelledPredicates = (device) =>
      SMART_STOCK_LOSS_TYPE.includes(device.lossType) &&
      device.serviceFee !== lflServiceFee &&
      !map(initialDevices, 'id').includes(device.id);

    const findNotInInital = (input, label, labelProps) =>
      findAndAttachRibbon(input, label, labelledPredicates, labelProps);

    initialDevices.push(
      findNotInInital(latest, t('labels.latest'), {
        color: '#DF070E',
        shadow: '#A90308',
      })
    );
    initialDevices.push(findNotInInital(popular, t('labels.popular')));
    initialDevices.push(findNotInInital(bestDeal, t('labels.bestDeal')));
    initialDevices.push(findNotInInital(recommended, t('labels.recommended')));
    remove(initialDevices, isUndefined);
    const existingIds = map(initialDevices, 'id');

    const fillers = filter(
      remappedDevices,
      (device) =>
        !SMART_STOCK_LOSS_TYPE.includes(device.lossType) && existingIds.indexOf(device.id) < 0
    ).slice(0, initialDevices.length);

    initialDevices.splice(1, 0, ...fillers);

    push({
      type: 'SET_INITIAL_DEVICES',
      devices: initialDevices,
    });
    // ==========================================================================
    // @end: pre-sorting lists
    // ==========================================================================

    push('INIT_COMPLETE');
  }, []);

  const onFilterShown = useCallback((node) => {
    if (node) {
      node?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      <CssReset scope={'.chakra-ui-scope'} />
      <div className="chakra-ui-scope">
        <DeviceCarousel
          disabled={state.matches('deviceSelected') || state.context.showAllOptions}
          devices={state.context.initialDevices}
          onSelect={(id) => push({ type: 'SELECT_DEVICE', id })}
        />
        <VStack w={'full'} gap={4} px={4}>
          <FilterOptions
            disabled={state.matches('deviceSelected')}
            buttons={[
              {
                label: t('forms.filterOptions.filter'),
                props: {
                  isActive:
                    state.context.filteredDevices.length > 0 && !state.context.showAllOptions,
                  onClick: () => push('SHOW_FILTER_FORM'),
                },
              },
              {
                label: t('forms.filterOptions.seeAllOptions'),
                props: {
                  isActive: state.context.showAllOptions && !state.matches('selectFilterCriterion'),
                  onClick: () => push({ type: 'SHOW_ALL_OPTIONS', showAllOptions: true }),
                },
              },
            ]}
          />

          {state.context.showAllOptions && (
            <DeviceGrid
              disabled={state.matches('deviceSelected')}
              devices={state.context.filteredDevices}
              onSelect={(id) => push({ type: 'SELECT_DEVICE', id })}
            />
          )}
        </VStack>

        {state.context.filteredDevices &&
          !state.matches('showAllOptions') &&
          !state.context.showAllOptions &&
          state.context.filterActive &&
          (state.context.filteredDevices.length > 0 ? (
            <Box mt={4} ref={onFilterShown}>
              <DeviceCarousel
                disabled={state.matches('deviceSelected')}
                devices={state.context.filteredDevices}
                onSelect={(id) => push({ type: 'SELECT_DEVICE', id })}
              />
            </Box>
          ) : (
            <Container my={4} ref={onFilterShown}>
              <VStack borderRadius={'10px'} border={'1px'} borderColor={'gray.150'} p={'20px'}>
                <Text>{t('forms.filterOptions.emptyResult')}</Text>
              </VStack>
            </Container>
          ))}

        <FormOverlay
          isOpen={state.matches('selectFilterCriterion')}
          onClose={() => push('CLOSE')}
          initialValues={{
            color: state.context.filterBy.color,
            serviceFee: state.context.filterBy.serviceFee,
            capacity: state.context.filterBy.capacity,
          }}
          onSubmit={(values) => push({ type: 'SET_FILTER_CRITERION', filterBy: values })}
          onFormClose={() => push('CLOSE')}
          colors={state.context.filterOptions.colors}
          range={state.context.filterOptions.srfRange}
          capacities={state.context.filterOptions.capacity}
        />
      </div>
    </ChakraProvider>
  );
};

export default SmartStock;
