const EVENTLIST = {
    ONLINE_PAGE_VIEW: "Online Page View",
    JOURNEY_STARTED: "Journey Started",
    JOURNEY_COMPLETED: "Journey Completed",
    CHATBOT_SUPPORT: "Chatbot Support",
    GENERAL_ENQUIRY: "General Enquiry",
    CREATE_NEW_SERVICE_REQUEST: "Create New Service Request",
    RESUME_EXISTING_SERVICE_REQUEST: "Resume Existing Service Request",
    ABOUT_MY_CURRENT_DELIVERY: "About My Current Delivery",
    NEED_MORE_HELP: "Need more help",
    EXISTING_CUSTOMER_SELECTION: "Existing customer selection",
    GE_INQUIRY_REASON: "General Enquiry reason",
    PERIL_SELECTION: "Peril Selection",
    MDN_INPUT: "MDN Input",
    MDN_INPUT_FAILURE: "MDN Input Failure",
    NAME_INPUT: "Name Input",
    NAME_INPUT_FAILURE: "Name Input Failure",
    NRIC_INPUT: "NRIC Input",

    LEAVING_AIS: 'AIS Leavig Reason',
    SUBMITTED_AIS_LEAVING_REASON: 'Submitted AIS Leaving Reason',
    LEAVING_AIS_POP_UP: 'Leaving AIS Popup',
    AIS_LEAVING_ACKNOWLEDGE: 'AIS Leaving Acknowledgement',
    ROUTE_TO_REFUND_OR_SAVEDESK: 'Route to Refund or Savedesk based on Patform',
    SELECTED_CALLBACK_DATE: 'Selected Callback Date',
    SELECTED_CALLBACK_TIME: 'Selected Callback Time',
    CALL_BACK_COMMENT: 'Callback Comment',
    THANK_YOU: 'Thank You',
    ALTERNATE_CONTACT_NUMBER: 'Alternate Cotact Number',

    WBKO : 'WBKO',
    WBKO_UPDATE_CONTACT_DETAILS:'Update Contact Details',


    NRIC_INPUT_FAILURE: "NRIC Input Failure",
    ENROLLMENT_ISSUE: "Enrollment Issue",
    CHAT_SUPPORT: "Chat Support",
    CHAT_CONNECTED: "Chat Connected",
    CHAT_DISCONNECTED: "Chat Ended",
    AGENTS_BUSY: "Agents Busy",
    AGENTS_OFFLINE: "Agents Offline",
    SERVICE_UNAVAILABLE: "Service Unavailable",
    RESUME_VOICE_TO_ONLINE_FAILURE: "Resume Voice to Online Failure",
    REQUEST_UNPROCESSED_CONTACT_HOTLINE: "Request Unprocessed-Contact Hotline ",
    CONFIRMATION_FORM_REQUEST: "Confirmation Form Request",
    DEVICE_ISSUE: "Device Issue",
    DEVICE_MISMATCHED: "Device Mismatch",
    IMEI_INPUT: "IMEI Input",
    IMEI_INPUT_FAILURE: "IMEI Input Failure",
    AIS_EXCHANGE: "AIS Exchange",
    MANUFACTURE_EXCHANGE: "Manufacture Exchange",
    NO_EXCHANGE: "No Exchange",
    TRIAGE: "Triage",
    ORDER_UNDER_REVIEW: "Order Under Review",
    OEM_IN_WARRANTY: "OEM In-Warranty",
    FMIP: "FMIP",
    CONFIRMATION_FORM_UPLOADED: "Confirmation Form Uploaded",
    SERVICE_REQUEST_FEE: "Service Request Fee",
    DEVICE_IN_STOCK: "Device In Stock",
    DEVICE_OUT_OF_STOCK: "Device Out Of Stock",
    BACK_ORDER: "Back Order",
    SHIPPING: "Shipping",
    SHIPPING_ADDRESS_INPUT_FAILURE: "Shipping Address Input Failure",
    PAYMENT_FEE: "Payment Fee",
    PAYMENT: "Payment",
    PAYMENT_INPUT_FAILURE: "Payment Input Failure",
    ORDER_REVIEW: "Order Review",
    ORDER_CONFIRMATION: "Order Confirmation",
    SURVEY_REQUESTED: "Survey Requested",
    SURVEY_SUBMITTED: "Survey Submitted",
    TRACK_MY_DELIVERY: "Track My Delivery",
    RESCHEDULE_DELIVERY: "Reschedule Delivery",
    CHANGE_DELIVERY_ADDRESS: "Change Delivery Address",
    CANCEL_DELIVERY: "Cancel Delivery",
    RESHIP: "Reship",
    VOID: "VOID",
    HOLD: "Hold",
    NPS_SUBMITTED: "NPS Submitted",
    COMMENT_SUBMITTED: "Comment Submitted",
    EXPERIENCE_SUBMITTED: "Experience Submitted",
    CSAT_SUBMITTED: "CSAT Submitted",
    SUBSCRIPTION: 'View my subscription details',
    MAIN_MENU_OPTIONS_DISPLAYED: "Main menu options displayed",
    REQUEST_CHAT_SUPPORT: "Chat support requested for ",
    CHAT_RESOLVED: "Chat resolved for ",
    CHAT_NOT_RESOLVED: "Chat not resolved for ",
    USER_DISCONNECTED: "User disconnected",
    AGENT_DISCONNECTED: "Agent disconnected",
    CHAT_CONNECTED_REASON: "Chat Connected for ",
    ALL_AGENTS_BUSY: "- All agents busy - ",
    GET_SR_FEE: "Get SR",
    EXISTING_SR_DISPLAYED: "Existing SR displayed",
    CONFIRM_ENROLLED_DEVICE: "Confirm enrolled device",
    SERVICE_FEE_CONFIRMATION: "Confirm service fee",
    CONFIRM_MOBILE_NUMBER: "Confirm mobile number",
    EXCHANGE_OPTIONS_DISPLAYED: "Exchange options displayed",
    CONFIRM_PAYMENT_FEE: "Confirm payment fee",
    CONFIRM_ENROLLED_ADDRESS: "Confirm Enrolled address",
    EMAIL_ADDRESS_INPUT: "Email address input",
    CONFIRM_EMAIL_ADDRESS: "Confirm email Id",
    ENROLLED_ADDRESS_INPUT: "Enrolled address input",
    MOBILE_NUMBER_INPUT: "Mobile number input",
    CREDIT_CARD_INPUT: "Credit card input",
    CREDIT_CARD_SUBMITTED: "Credit card submitted",
    CREDIT_CARD_CANCEL: "Credit card Cancel",
    CREDIT_CARD_INVALID: "Credit card Invalid Details",
    DELIVERY_SCHEDULE_CONFIRMATION: "Delivery schedule confirmed",
    NEW_DELIVERY_SCHEDULE_SUBMITTED: "New delivery schedule submitted",
    NO_DEVICE_IN_STOCK: "No device instock",
    CHECK_ALTERNATE_DEVICE: "Check alternate device",
    SIM_CARD_CONFIRMATION: "SIM card confirmation",
    SURVERY_CONFIRMATION: "Survey confirmation",
    UPLOAD_CONFIRMATION_FORM: "Upload Confirmation form",
    DELIVERY_DATE_SELECTION: "Delivery date selection",
    CHANGE_DELIVERY_DATE: "Change delivery date",
    PROCEED_WITH_COD: "Credit card invalid, proceed with COD",
    GE_REASON_PROVIDED: "Enquiry reason provided",
    SURVERY_CONFIRMATION: "Survey confirmation",
    SURVERY_QUESTION_COMMENT_INPUT: "Survey question input",
    SURVERY_QUESTION_CALLBACK_INPUT: "Survey callback input",
    SURVERY_QUESTION_CHECKLIST_INPUT: "Survey checklist input",
    SURVERY_QUESTION_EXPERIENCE_INPUT: "Survey question experience input",
    NO_CURRENT_DELIVERY: "No current delivery available",
    NO_ALTERNATIVE_DEV_INSTOCK: "No alternate device in stock",
    PROCEED_WITH_COD: "Credit card invalid, proceed with COD",
    UPLOAD_SERVICE_REPORT: "IMEI service report",
    BGV3_CREDIT_CARD_FAILURE: "Billing Gateway credit card validation error"

}

export default EVENTLIST;