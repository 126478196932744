import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';

import {
  doFindAgreementsInit,
  doSaveMDN,
  savePayload,
  doverifyNRICInit,
  updateTransitionPanel
} from "../../actions/Dashboard/doFindAgreements";

import { SAVE_CUSTOMERNAME } from '../../actions/Dashboard/actionTypes';
import { getMaskedstr, validateNameRegex } from '../../utils/utils';
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';

import contactIcon from "../../images/ais-images/enter-mdn.png";
import nricIcon from "../../images/ais-images/contact-details.png";
import nameIcon from "../../images/ais-images/icon-provide-full-name.png";
import wbko_mdn_validation from "../../images/ais-images/wbko-mdn-validation.png";
import wbko_ic_nric from "../../images/ais-images/wbko-ic-NRIC.png";
import DOMPurify from "dompurify";

class RequestForMDN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      value: "",
      btnDisable: true,
      textboxDisable: false,
      highlightedClass: "",
      disableButton: true,
      disableBckButton: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.backButtonClicked = this.backButtonClicked.bind(this);
    /* if (props.dataprops.inputState === CONSTANTS.ENTERED_MDN) {
      this.state.value = "9867467877"
    } else if (props.dataprops.inputState === CONSTANTS.ENTERED_NRIC) {
      this.state.value = "de4239903k"
    } */
  }

  backButtonClicked() {
    this.setState({
      buttonDisable: true,
      textboxDisable: true,
      disableBckButton: true
    })
    this.props.updateJourneyList(CONSTANTS.FAQ_PAGE, CONSTANTS.CONFIRM_EXISTING_CUST);
  }

  handleChange(e) {

    const { dataprops } = this.props;
    if (dataprops.inputState === CONSTANTS.ENTERED_MDN) {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }
    if (dataprops.inputState === CONSTANTS.ENTERED_NRIC) {
      e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '');
    }
    if (e.target.value.trim() === "") {
      this.setState({
        value: "",
        btnDisable: true
      });
    } else {
      this.setState({
        value: e.target.value,
        btnDisable: false
      });
    }
  }

  handleKey(event) {
    if (event.keyCode === 13 && !event.shiftKey && this.state.value || event.keyCode === 13 && !event.shiftKey && this.state.firstName) {
      this.handleSubmit();
    }
  }

  handleSubmit() {
    let { cacheId, visitorId, customerName, customerContact, selectedDefectOption, caseType, dataprops, verificationFailedAttempt } = this.props;

    if (this.state.value.length < 10 && dataprops.inputState === CONSTANTS.ENTERED_MDN) {
      return false;
    }
    else if (dataprops.inputState === CONSTANTS.ENTERED_NRIC && this.state.value.length < 4) {
      return false;
    }

    let text = this.state.value;
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let {
      agreementFailedAttempt,
      nameVerificationAttempt,
    } = this.props;

    if (dataprops.inputState === CONSTANTS.ENTERED_MDN) {
      this.props.doSaveMDN(text);
      this.props.updateJourneyList(this.props.source, text);
      this.props.updateTransitionPanel(MESSAGE_CONSTANTS.STAGE_NAME.VERIFICATION.mdn.percentage, MESSAGE_CONSTANTS.STAGE_NAME.VERIFICATION.mdn.label, '', MESSAGE_CONSTANTS.ENTERED_MDN + `${getMaskedstr(text)}`); // 5%
      this.props.doFindAgreementsInit({
        FindAgreementsParameters: {
          MobileDeviceNumber: text || '',
          AgreementFailedAttempt: agreementFailedAttempt,
          SessionId: cacheId,
          VisitorId: visitorId,
          CustomerName: customerName.fullName,
          // FirstName: customerName.firstName,
          // LastName: customerName.lastName || "user",
        },
        userInput: {
          handlePrevSubmit: 1,
          userInput: dataprops.inputState,
          source: CONSTANTS.MDN_VERIFIED,
          value: MESSAGE_CONSTANTS.ASK_FOR_NRIC
        },
        nameInput: {
          handlePrevSubmit: 1,
          userInput: dataprops.inputState,
          source: CONSTANTS.MDN_VERIFIED,
          value: MESSAGE_CONSTANTS.INCORRECT_NAME
        },
        generalEnquiryCase: dataprops.generalEnquiryCase,
      });
      this.setState({
        textboxDisable: true,
        btnDisable: true,
        highlightedClass: "selected",
        disableBckButton: true

      });
    }
    else if (dataprops.inputState === CONSTANTS.ENTERED_NRIC) {
      this.setState({
        textboxDisable: true,
        btnDisable: true,
        highlightedClass: "selected"
      });
      this.props.updateJourneyList(this.props.source, text);
      this.props.updateTransitionPanel(MESSAGE_CONSTANTS.STAGE_NAME.VERIFICATION.nric.percentage, MESSAGE_CONSTANTS.STAGE_NAME.VERIFICATION.nric.label, '', MESSAGE_CONSTANTS.ENTERED_ID + `${getMaskedstr(text)}`); // 10%
      this.props.doverifyNRICInit({
        VerifyAgreementsParameters: {
          MobileDeviceNumber: customerContact || '',
          PassportNumber: text,
          FailedAttempt: verificationFailedAttempt || 0,
          CustomerVerificationMethod: "PASSNBR",
          SessionId: cacheId
        },
        userInput: {
          handlePrevSubmit: 1,
          userInput: dataprops.inputState,
          source: dataprops.source,
          value: text
        },
        DetermineIncidentPaths: {
          SessionId: cacheId,
          caseType: caseType
        },
        ProcessIncidentParameters: {
          SessionId: cacheId,
          Incident: {
            FailureDescriptiveText: selectedDefectOption,
            IncidentType: caseType
          }
        }
      });

    }
    else if (dataprops.inputState === CONSTANTS.ENTERED_NAME) {
      this.setState({
        textboxDisable: true,
        btnDisable: true,
        highlightedClass: "selected"
      });

      let name = `${firstName} ${lastName}`
      this.props.savePayload(SAVE_CUSTOMERNAME, name);
      // this.props.savePayload(SAVE_CUSTOMERNAME, {name: `${firstName} ${lastName}`});
      this.props.updateJourneyList(null, `${firstName} ${lastName}`);
      this.props.updateTransitionPanel(MESSAGE_CONSTANTS.STAGE_NAME.VERIFICATION.name.percentage, MESSAGE_CONSTANTS.STAGE_NAME.VERIFICATION.name.label, '', MESSAGE_CONSTANTS.ENTERED_NAME + ` ${getMaskedstr(name)}`); // 8%
      // this.doFindAgreementsInit({name: `${firstName} ${lastName}`});
      this.props.doFindAgreementsInit({
        FindAgreementsParameters: {
          MobileDeviceNumber: customerContact || this.props.mdn || '',
          SessionId: cacheId,
          VisitorId: visitorId,
          NameFailedAttempt: nameVerificationAttempt,
          AgreementFailedAttempt: agreementFailedAttempt,
          CustomerName: `${firstName} ${lastName}`
        },
        userInput: {
          handlePrevSubmit: 1,
          userInput: dataprops.inputState,
          source: CONSTANTS.MDN_VERIFIED,
          value: MESSAGE_CONSTANTS.ASK_FOR_NRIC
        },
        generalEnquiryCase: dataprops.generalEnquiryCase,
      });
    }
  }

  componentDidMount() {
    this.refs['ref_input'].focus();
    // this.refs['firstname'].focus();
  }

  setFirstName(value) {
    value = value.replace(validateNameRegex(), '');
    if (value.trim() === "") {
      this.setState({
        firstName: "",
        disableButton: true
      });
    } else {
      this.setState({
        firstName: value,
        disableButton: false
      });
    }
  }

  setLastName(value) {
    value = value.replace(validateNameRegex(), '');
    if (value.trim() === "") {
      this.setState({
        lastName: "",
        disableButton: true
      });
    } else {
      this.setState({
        lastName: value,
        disableButton: false
      });
    }
  }

  render() {
    let maxlen = "";
    let minlen = "";
    let img = "";

    let { dataprops: { inputState },isWbkoSelected } = this.props;
    let { firstName, lastName } = this.state;
    // console.log(inputState);

    let btnclass = classNames({
      "ais_device-select-button": true,
      "ais_selected": this.state.highlightedClass,
      "ais_device-btn-width": true
    });

    let inputclass = classNames({
      "ais_contact-number-input-cus": true,
      "ais_text-color": this.state.value !== '',
      "ais_placeholder-color": this.state.value == '',
      "ais_placeholder-font": true
    });

    let nameclass = classNames({
      "ais_contact-number-input-cus": true,
      "ais_margin-bottom": true,
      "ais_text-color": this.state.firstName !== '',
      "ais_placeholder-color": this.state.firstName == '',
      "ais_text-color": this.state.lastName !== '',
      "ais_placeholder-color": this.state.lastName == '',
      "ais_placeholder-font": true
    });

    if (inputState === CONSTANTS.ENTERED_MDN) {
      maxlen = 10;
      minlen = 5;
      img = isWbkoSelected ? wbko_mdn_validation :  contactIcon;
    } else if (inputState === CONSTANTS.ENTERED_NRIC) {
      maxlen = 4;
      minlen = 4;
      img = isWbkoSelected ? wbko_ic_nric : nricIcon;
    } else if (inputState === CONSTANTS.ENTERED_NAME) {
      img = nameIcon;
    }

    let disableButton = !(firstName.trim().length > 0 && lastName.trim().length > 0)
    return (

      <div>
        { (isWbkoSelected && this.props.data.CONTENT1 ) || (!isWbkoSelected && !!this.props.data.CONTENT) ?
          <div className="ais_message-blk">
            <div className="ais_message">
              <div className="ais_msg-time">{moment(this.props.time).format(CONSTANTS.MESSAGE_TIME_FORMAT)}</div>
              <div className="ais_received">
                <div className="ais_message-content">
                  { isWbkoSelected && this.props.data.CONTENT1 ? this.props.data.CONTENT1 : this.props.data.CONTENT}
                  <br />
                </div>
              </div>
            </div>
          </div>
          :
          <div></div>
        }
        <div className={`ais_current-form-device-div ${isWbkoSelected ? 'wbkoRequestForMDN' : ''}`}>
          <div className="ais_header-txt-div">
            <div>
              <img
                alt="enroll-address-icn"
                className="ais_confirm-delivery-icn"
                src={img}
              />
            </div>
            { !isWbkoSelected && <label className="ais_current-mobile-device-label" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.HEADER) }}></label>}
            { isWbkoSelected && <label className="ais_current-mobile-device-label wbkoVerifyMdn" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.WBKO_HEADER) }}></label>}
            {inputState === CONSTANTS.ENTERED_NAME ? <div> <input
              type="text"
              name="firstName"
              className={nameclass}
              // onChange={this.handleChange}
              onChange={(e) => this.setFirstName(e.target.value)}
              onKeyDown={this.handleKey}
              ref="ref_input"
              placeholder={this.props.data.FIRST_NAME}
              value={this.state.firstName}
              autoComplete="off"
              disabled={this.state.textboxDisable}
            />
              <input
                type="text"
                name="lastName"
                className={nameclass}
                onChange={(e) => this.setLastName(e.target.value)}
                onKeyDown={this.handleKey}
                // ref="ref_input"
                placeholder={this.props.data.LAST_NAME}
                value={this.state.lastName}
                autoComplete="off"
                disabled={this.state.textboxDisable}
              /></div> :
              <div><input
                type="text"
                className={inputclass}
                onChange={this.handleChange}
                onKeyDown={this.handleKey}
                ref="ref_input"
                maxLength={maxlen}
                minLength={minlen}
                placeholder={isWbkoSelected && inputState === CONSTANTS.ENTERED_MDN ? this.props.data.WBKO_PLACEHOLDER : this.props.data.PLACEHOLDER}
                value={this.state.value}
                disabled={this.state.textboxDisable}
              />
              {inputState === CONSTANTS.ENTERED_MDN && 
              <input type="hidden" id="txt_sea_ais_mdn_id" value={this.state.value} />
              }
              </div>}


            {inputState === CONSTANTS.ENTERED_NAME ?
              <button
                disabled={disableButton}
                onClick={this.handleSubmit}
                className={btnclass}
              >
                {this.props.data.BUTTON}
              </button> : <button
                disabled={this.state.btnDisable}
                onClick={this.handleSubmit}
                className={btnclass}
              >
                {this.props.data.BUTTON}
              </button>}
          </div>
          {inputState === CONSTANTS.ENTERED_MDN && this.props.backEnabled
            && this.props.selectedDefectOption === CONSTANTS.GENERAL_ENQUIRY ? <button className="ais_go_back" disabled={this.state.disableBckButton} onClick={this.backButtonClicked}>  {MESSAGE_CONSTANTS.BACK_TO_PREV_STEP} </button> : ""}
        </div>
        <br />

      </div>
    );
  }
}

const mapStateToProps = state => {
  let { visitorId, clientData, loading, customerName, backEnabled, customerContact, selectedDefectOption, caseType, verificationFailedAttempt, agreementFailedAttempt,
    nameVerificationAttempt,
    mdn ,isWbkoSelected} = state.customerData;
  return {
    isWbkoSelected,
    cacheId: clientData.CacheId,
    customerName,
    loading,
    visitorId,
    customerContact,
    selectedDefectOption,
    caseType,
    backEnabled,
    verificationFailedAttempt,
    agreementFailedAttempt,
    nameVerificationAttempt,
    verificationFailedAttempt,
    mdn
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      doFindAgreementsInit,
      doSaveMDN,
      savePayload,
      doverifyNRICInit,
      updateTransitionPanel
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestForMDN);
