import React, { Component, Fragment } from "react";
import { v4 as uuid } from "uuid";
import SendMessage from './../Chat/SendMessage';
import CONSTANTS from './../../../utils/Constants';
import MESSAGE_CONSTANTS from "./../../../config/journey-messages";

class ChatInput extends Component {

    constructor(props) {
        super(props);
        this.state = {value: '',
        newMessage: null,
        sendClicked: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value,
            sendClicked:false
        });
    }

    handleKey(event) {
        if (event.keyCode === 13 && !event.shiftKey) {
          this.handleSubmit(event);
        }
    }

    handleSubmit(event) {
        const { customerName, visitor, conversation } = this.props;
        const { conversationId } = conversation || {};
        const { fullName } = customerName || {};
        const { input } = visitor || {};
        const { visitorId, userName } = input || {};

        let senderName = (fullName && fullName !== userName) ? fullName : userName;

        let text = this.state.value;
        let convId = null;
        conversation ? convId = conversationId : convId = null;
        

        const newMessage = {
            input :{
                "messageId" : uuid(),
                "visitorId" : visitorId,
                "conversationId" : convId,
                "messageType" : CONSTANTS.MESSAGE_TYPE.PLAIN_TEXT,
                "interactionType" : CONSTANTS.INTERACTION_TYPES.ASSISTANCE,
                "sender" : senderName,
                "source": CONSTANTS.SOURCE_ENUM.USER,
                "content" : text,
                "isSent" : false,
                "recipient" : "agent",
                "isActive" : true,
                "createdAt" : new Date()
            }
        };

        this.setState({value: '' ,
        newMessage: newMessage,
        sendClicked: true
        });
    }

    componentDidMount(){
        this.refs['chatInput'].focus();
    }
    render() {
        let sendButtonClass = this.state.value !== '' ? "ais_activated-send-icon": "ais_default-send-icon";
        let textColor = this.state.value !== '' ? " ais_text-color": " ais_placeholder-color";

        let userInputClass = "ais_user-input-div ais_enquiry-input-margin";
        return (
            <Fragment>
              {/*   <div className={userInputClass}>
                    <div className="ais_user-input-textbox-div">
                        <input ref="chatInput" id="chatInput" type="text" className={"ais_user-input-textbox "+textColor} placeholder="Enter your message here"
                            name="messageInputTextBox" value={this.state.value}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKey.bind(this)}
                            />
                    </div>
                    <div className={sendButtonClass} onClick={this.handleSubmit}>
                    </div>
                </div> */}

                    <div className="ais_user-input-textbox-div">
                      <input ref="chatInput" id="chatInput" type="text" className={"ais_user-input-textbox" + textColor} placeholder={MESSAGE_CONSTANTS.ENTER_YOUR_MESSAGE}
                          name="messageInputTextBox" value={this.state.value}
                          onChange={this.handleChange}
                          onKeyDown={this.handleKey.bind(this)}
                      />
                      <div className="ais_user-input-img-wrapper">
                        <i className="ais_activated-send-icon" onClick={this.handleSubmit}></i>
                      </div>
                    </div>
                {this.state.sendClicked ? <SendMessage message={this.state.newMessage}/> : null}
            </Fragment>
        )
    }
}

export default ChatInput;