import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
  updateShippingOrderInit,
  doSaveDeliveryDate,
  doSaveDeliverySlot,
  submitEnquiryDetails,
  savePayload,
  doSaveDefaultSlot
} from '../../actions/Dashboard/doFindAgreements';
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import scheduleDelivery from "../../images/ais-images/icon-delivery-schedule.png";
import wbko_date from "../../images/ais-images/wbko-date.png";
import wbko_calendar_small from '../../images/ais-images/wbko-calendar-small.png';
import { isClaimWithinCutoff, calculateDeliverySlot, calculateDeliverySpan } from "../../utils/utils";
import { WBKO_KEEP_OR_CONFIRM_COUNT } from '../../actions/Dashboard/actionTypes';
import moment from 'moment';

class ConfirmDeliverySchedule extends Component {
  constructor(props) {

    super(props);
    this.state = {
      disable: false,
      yesButtonClassName: "ais_align-popup-button width-auto",
      noButtonClassName: "ais_align-popup-button width-auto",
      deliveryDate: "",
      deliveryTime: "",
      deliveryWindow: "",
      showButton: "",
      deliverySlot: "",
      keepOrconfirmCount: 0
    }
    this.handleKeepClick = this.handleKeepClick.bind(this);
    this.handleChangeClick = this.handleChangeClick.bind(this);
  }

  handleKeepClick() {
    this.props.doSaveDeliveryDate(this.state.deliveryDate);
    this.props.doSaveDeliverySlot(this.state.deliveryTime, true);

    this.setState({
      disable: true,
      yesButtonClassName: "ais_align-popup-button-selected"
    });
    let { cacheId, selectedEnquiryOption, shippingMethodsResult, shippingOrderResult, selectedDeliveryDate, inquiryReason, keepOrconfirmCount, WbkoChangedAddressString, isWbkoSelected } = this.props;
    keepOrconfirmCount = keepOrconfirmCount ? keepOrconfirmCount : this.state.keepOrconfirmCount;
    if (selectedEnquiryOption) {
      var description = `${MESSAGE_CONSTANTS.CHANGE_DELIVERY_SCHEDULE_ENQUIRY} ${this.state.deliveryDate} ${this.state.deliverySlot}`;
      description = WbkoChangedAddressString ? WbkoChangedAddressString + "\n" + MESSAGE_CONSTANTS.WBKO_ADDRESS_STRING + this.state.deliveryDate + " " + this.state.deliverySlot : description;
      let UpdateDeliverySchedule = { DeliveryWindow: this.state.deliveryWindow, NewDeliveryDate: this.state.deliveryDate };
      this.props.submitEnquiryDetails(description, selectedEnquiryOption, inquiryReason, CONSTANTS.ENQUIRY_SUB_REASON.CHANGE_DELIVERY_INFO, null, null, UpdateDeliverySchedule);
      this.props.isWbkoSelected && this.props.updateJourneyList(CONSTANTS.WBKO_KEEP, this.props.isWbkoSelected && keepOrconfirmCount === 0 ? this.props.data.WBKO_BUTTONS[1] :

        this.props.isWbkoSelected ? this.props.data.WBKO_BUTTONS[2] : this.props.data.BUTTONS[2]);//if confirm count is greater that 0

    } else {
      if (selectedDeliveryDate) {
        this.props.updateJourneyList(this.props.dataprops.source, isWbkoSelected ? this.props.data.WBKO_BUTTONS[2] : this.props.data.BUTTONS[2]);
      } else {
        this.props.updateJourneyList(this.props.dataprops.source, isWbkoSelected ? this.props.data.WBKO_BUTTONS[1] : this.props.data.BUTTONS[1]);
      }
      this.props.updateShippingOrderInit(cacheId, shippingMethodsResult, shippingOrderResult, this.state.deliveryWindow, this.state.deliveryDate, true);
    }
  }

  handleChangeClick() {
    // console.log("Change button clicked.");
    this.setState({
      disable: true,
      noButtonClassName: "ais_align-popup-button-selected",
      keepOrconfirmCount: this.state.keepOrconfirmCount + 1
    });
    let { keepOrconfirmCount, isWbkoSelected } = this.props;
    this.props.savePayload(WBKO_KEEP_OR_CONFIRM_COUNT, keepOrconfirmCount ? keepOrconfirmCount : this.state.keepOrconfirmCount + 1)
    this.props.updateJourneyList(this.props.dataprops.source, isWbkoSelected ? this.props.data.WBKO_BUTTONS[0] : this.props.data.BUTTONS[0]);
  }

  componentWillMount() {
    let {
      deliveryDate,
      userSelectedDeliverySpeed,
      userSelectedDeliveryDate,
      userSelectedDeliverySlot,
      defaultDeliveryInfoObj,
      data, keepOrconfirmCount
    } = this.props;
    keepOrconfirmCount = keepOrconfirmCount ? keepOrconfirmCount : this.state.keepOrconfirmCount;
    let {
      DeliverySlots = [],
      selectedDeliverySlots: sameDayDeliverySlots = [],
    } = defaultDeliveryInfoObj;

    let currentDeliverySlot = sameDayDeliverySlots.length
      ? sameDayDeliverySlots[0]
      : DeliverySlots && DeliverySlots[0];

    currentDeliverySlot = userSelectedDeliverySlot || currentDeliverySlot;
    /*  calculateDeliverySpan(
       userSelectedDeliverySpeed,
       currentDeliverySlot,
       sameDayDeliverySlots
     ); */
    let claimWithinCutoff = isClaimWithinCutoff();

    let dateString = moment(new Date(deliveryDate)).format(
      CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT
    );
    //remove time slot validation bcoz its not more for selection for ais
    //if (userSelectedDeliveryDate && userSelectedDeliverySlot) {
    let time;
    let selectedDate = moment(new Date(userSelectedDeliveryDate)).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT);
    let currentDate = moment(new Date()).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT); moment(new Date());
    let compareFlag = selectedDate === currentDate;

    let deliverySpan = userSelectedDeliverySpeed === "Same Day" && compareFlag ? '6H' : '17H';
    if (userSelectedDeliveryDate) {
      time = (claimWithinCutoff && (userSelectedDeliverySpeed === "Same Day")) && compareFlag ? data.SLOTS[0] : data.SLOTS[1] //userSelectedDeliverySlot,
      this.setState({
        deliveryDate: moment(new Date(userSelectedDeliveryDate)).format(
          CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT
        ),
        deliverySlot: time,
        deliveryTime: time,//userSelectedDeliverySlot,
        deliveryWindow: deliverySpan,
        showButton: this.props.isWbkoSelected && keepOrconfirmCount === 0 ? this.props.data.WBKO_BUTTONS[1] :

          this.props.isWbkoSelected ? this.props.data.WBKO_BUTTONS[2] : this.props.data.BUTTONS[2] // if keepconfirm count is greated that 0
      });
      this.props.doSaveDeliverySlot(time, true);
    } else {
      if (userSelectedDeliverySpeed === "Same Day") {

        let dateString1 = moment(new Date(dateString)).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT);
        let currentDate2 = moment(new Date()).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT); //moment(new Date());
        let compareFlag2 = dateString1 === currentDate2;
        time = (claimWithinCutoff && (userSelectedDeliverySpeed === "Same Day")) && compareFlag2 ? data.SLOTS[0] : data.SLOTS[1];
        this.setState({
          deliveryDate: dateString,
          deliverySlot: time,
          deliveryTime: time,
          deliveryWindow: deliverySpan,
          showButton: this.props.isWbkoSelected ? this.props.data.WBKO_BUTTONS[1] : data.BUTTONS[1]
        });
        this.props.doSaveDeliverySlot(time, true);
      } else {
        this.setState({
          deliveryDate: dateString,
          deliverySlot: time,
          deliveryTime:
            data.SLOTS[1],
          deliveryWindow: MESSAGE_CONSTANTS.DELIVERY_SLOT_MAPPING[0].VALUE,
          showButton: this.props.isWbkoSelected ? this.props.data.WBKO_BUTTONS[1] : data.BUTTONS[1],
        });
        this.props.doSaveDeliverySlot(data.SLOTS[1], true);
      }
    }
  }

  render() {
    let { isWbkoSelected, keepOrconfirmCount } = this.props;
    keepOrconfirmCount = keepOrconfirmCount ? keepOrconfirmCount : this.state.keepOrconfirmCount;
    return (
      <div className="ais_current-form-device-div">
        <div className="ais_header-txt-div">
          {!isWbkoSelected && <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>}
          {isWbkoSelected && keepOrconfirmCount === 0 && <div className='wbkoConfirmDeliveryHeader' dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_CONFIRM_CHANGE_DELIVERY_HEADER }} />}

          {/* <div className="confirm-delivery-icn"></div> */}
          <div> <img alt="schedule-delivery-icn" className="ais_confirm-delivery-icn wbkoDateIcon" src={isWbkoSelected ? wbko_date : scheduleDelivery} /> </div>
          {isWbkoSelected && keepOrconfirmCount > 0 && <div className='wbkoConfirmDeliveryHeader' dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_CONFIRM_CHANGE_DELIVERY_HEADER }} />}
          {isWbkoSelected && keepOrconfirmCount > 0 && <img alt="schedule-delivery-icn" className="ais_confirm-delivery-icn wbkoDateIcon" src={wbko_calendar_small} />}
          <label className="ais_current--form-device-spec">{this.state.deliveryDate}</label>
          {<div> <label className="ais_current--form-device-spec">{this.state.deliveryTime}</label> </div>}
          {isWbkoSelected && keepOrconfirmCount > 0 && <div className='wbkoConfirmDeliverySchedleText' dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_CONFIRM_CHANGE_DELIVERY }} />}
          <div className="ais_outer-confirm-delivery-align-button">
            <button
              disabled={this.state.disable}
              className={this.state.noButtonClassName}
              onClick={this.handleChangeClick} >{isWbkoSelected ? this.props.data.WBKO_BUTTONS[0] : this.props.data.BUTTONS[0]}
            </button>
            <div className="ais_device-divider" />
            {this.state.showButton &&
              <button
                disabled={this.state.disable}
                className={this.state.yesButtonClassName}
                onClick={this.handleKeepClick} >{this.state.showButton}
              </button>}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    selectedEnquiryOption,
    shippingMethodsResult,
    shippingOrderResult,
    selectedDeliveryDate,
    selectedDeliverySlot,
    selectedDeliverySpeed,
    clientData,
    determineIncidentResponse,
    inquiryReason,
    holds,
    shippingMethodsV2Result,
    holdsLogged, isWbkoSelected, keepOrconfirmCount,
    WbkoChangedAddressString
  } = state.customerData;
  return {
    WbkoChangedAddressString,
    isWbkoSelected, keepOrconfirmCount,
    shippingMethodsV2Result,
    holds: holds,
    holdsLogged,
    cacheId: clientData.CacheId,
    shippingMethodsResult,
    shippingOrderResult,
    userPostalCode:
      (shippingOrderResult && shippingOrderResult.DefaultAddress.PostalCode) ||
      determineIncidentResponse.ShippingAddress.PostalCode,
    userSelectedDeliveryDate: selectedDeliveryDate,
    userSelectedDeliverySlot: selectedDeliverySlot,
    userSelectedDeliverySpeed: selectedDeliverySpeed
      ? selectedDeliverySpeed
      : shippingMethodsResult ? shippingMethodsResult.ShippingMethodType : null,
    inquiryReason,
    selectedEnquiryOption,
    deliveryDate: shippingMethodsResult
      ? shippingMethodsResult.EstimatedDeliveryDate
      : null,
    defaultDeliveryInfoObj:
      shippingMethodsV2Result && shippingMethodsV2Result[0]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateShippingOrderInit,
      doSaveDeliveryDate,
      doSaveDeliverySlot,
      submitEnquiryDetails,
      doSaveDefaultSlot,
      submitEnquiryDetails,
      savePayload
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeliverySchedule);