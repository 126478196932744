import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import classNames from 'classnames';

import { savePayload } from '../../actions/Dashboard/doFindAgreements';
import { SAVE_SELECTED_ENQUIRY_OPTION } from '../../actions/Dashboard/actionTypes';
//import { isDeviceOrWeb } from "../../utils/utils"
import CONSTANTS from "../../utils/Constants";
import helpIcon from "../../images/ais-images/icon-track-delivery.png";
import helpIcon1 from "../../images/ais-images/icon-reschedule-delivery.png";
import helpIcon2 from "../../images/ais-images/icon-change-address.png";
import helpIcon3 from "../../images/ais-images/icon-cancel-delivery.png";
import helpIcon4 from "../../images/ais-images/wbko_img-mobile_no.png";

/* import helpIconActive0 from "../../images/ais-images/icon-track-delivery-white.png";
import helpIconActive1 from "../../images/ais-images/icon-reschedule-delivery-active.png";
import helpIconActive2 from "../../images/ais-images/icon-change-address-active.png";
import helpIconActive3 from "../../images/ais-images/icon-cancel-delivery-active.png";
import helpIconActive4 from "../../images/ais-images/wbko_img-mobile_no_white.png"; */

import helpIconActive0 from "../../images/ais-images/icon-track-delivery.png";
import helpIconActive1 from "../../images/ais-images/icon-reschedule-delivery.png";
import helpIconActive2 from "../../images/ais-images/icon-change-address.png";
import helpIconActive3 from "../../images/ais-images/icon-cancel-delivery.png";
import helpIconActive4 from "../../images/ais-images/wbko_img-mobile_no.png";

import { shippingmethodsV2Init } from "./../../actions/Dashboard/shippingMethodsV2";
import MESSAGE_CONSTANTS from "../../config/journey-messages"
class GridViewOptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDiv: null,
            trackClicked: false,
            rescheduleClicked: false,
            addressClicked: false,
            cancelClicked: false,
            buttonClicked: false
        }
    }

    _onOptionClick = (label, index) => {
        if (!this.state.selectedDiv) {
            this.setState({
                selectedDiv: label,
                buttonClicked: true
            });
            let enquiryOption = "";
            if (this.props.isWbkoSelected) {
                if (this.props.isBackOrderCase) {
                    index === 0 ? enquiryOption = CONSTANTS.WEB_TRACK_MY_DELIVERY :
                        index === 1 ? enquiryOption = CONSTANTS.CHANGE_DELIVERY_ADDRESS_TRACK :
                            index === 2 ? enquiryOption = CONSTANTS.CANCEL_DELIVERY_TRACK :
                                enquiryOption = CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS;
                } else {
                    index === 0 ? enquiryOption = CONSTANTS.WEB_TRACK_MY_DELIVERY :
                        index === 1 ? enquiryOption = CONSTANTS.RESCHEDULE_DELIVERY_TRACK :
                            index === 2 ? enquiryOption = CONSTANTS.CHANGE_DELIVERY_ADDRESS_TRACK :
                                index === 3 ? enquiryOption = CONSTANTS.CANCEL_DELIVERY_TRACK :
                                    enquiryOption = CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS;
                }
            } else if (this.props.isBackOrderCase) {
                index == 1 ? enquiryOption = CONSTANTS.CHANGE_DELIVERY_ADDRESS_TRACK :
                    enquiryOption = CONSTANTS.CANCEL_DELIVERY_TRACK;
            } else {
                index === 1 ? enquiryOption = CONSTANTS.RESCHEDULE_DELIVERY_TRACK :
                    index === 2 ? enquiryOption = CONSTANTS.CHANGE_DELIVERY_ADDRESS_TRACK :
                        enquiryOption = CONSTANTS.CANCEL_DELIVERY_TRACK;
            }

            this.props.savePayload(SAVE_SELECTED_ENQUIRY_OPTION, enquiryOption);
            //let origin = isDeviceOrWeb();
            if (this.props.data.PLACEHOLDERS.TRACK === label) {
                this.setState({
                    trackClicked: true
                });
                this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.WEB_TRACK_MY_DELIVERY, label);
            } /* else if (this.props.data.PLACEHOLDERS.TRACK === label) {
              this.setState({
                trackClicked: true
              });
              this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.DEVICE_TRACK_MY_DELIVERY, label);
            } */

            else if (this.props.data.PLACEHOLDERS.RESCHEDULE === label) {
                this.setState({
                    rescheduleClicked: true
                });
                this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY, label);

            } else if (this.props.data.PLACEHOLDERS.ADDRESS_CHANGE === label) {
                this.setState({
                    addressClicked: true
                });
                this.props.isWbkoSelected ? this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION, label)
                    : this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS, label);

            } else if (this.props.data.PLACEHOLDERS.ENQUIRY === label) {
                this.setState({
                    cancelClicked: true
                });
                this.props.isWbkoSelected ? this.props.updateJourneyList(CONSTANTS.WBKO_CANCELLATION_REASON)
                    : this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY, label);
            } else if (this.props.data.PLACEHOLDERS.UPDATE_CONTACT_DETAILS === label) {
                this.setState({
                    updateContactDetailsClicked: true
                });
                this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.WBKO_UPDATE_CONTACT_DETAILS, label);
            }
        }

    }

    _renderOptions(option, i) {
        let { trackClicked, rescheduleClicked, cancelClicked, addressClicked } = this.state;
        return (
            <div key={i}
                className={this.state.selectedDiv === option.label ? "ais_current-form-device-div ais_enquiryBox-selected getmore-option removeclass" : "ais_current-form-device-div getmore-option ais_enquiryBox removeclass"} onClick={() => this._onOptionClick(option.label, i)}>
                <div className="ais_header-txt-div ais_enquiryBox">
                    <div><img alt="enquiry-option-icn" src={option.icon} /> </div>
                    {(this.state.selectedDiv === option.label) ? <label className="ais_current-device-enquiryBox wbkodisplaycontent" /* style={{ color: "white" }} */>{option.label}</label>
                        : <label className="ais_current-device-enquiryBox wbkodisplaycontent">{option.label}</label>}
                </div>
            </div>
        )
    }
    UNSAFE_componentWillMount() {
        this.props.shippingmethodsV2Init({ skipScheduleConfirmation: true, nochnageForm: true, isWBKO: !this.props.deliveryWindow })
    }
    backToMainMenu = () => {
        this.props.updateJourneyList(CONSTANTS.VERIFIED_AGREEMENT_DEVICE_LIST, MESSAGE_CONSTANTS.BackToMainMenu);
    }
    render() {
        let { getContactDetails, isWbkoSelected, shippingMethodsResult } = this.props;
        let { trackClicked, rescheduleClicked, cancelClicked, addressClicked, updateContactDetailsClicked } = this.state;
        let options = [trackClicked ? { icon: helpIconActive0, label: this.props.data.PLACEHOLDERS.TRACK } : { icon: helpIcon, label: this.props.data.PLACEHOLDERS.TRACK },
        rescheduleClicked ? { icon: helpIconActive1, label: this.props.data.PLACEHOLDERS.RESCHEDULE } : { icon: helpIcon1, label: this.props.data.PLACEHOLDERS.RESCHEDULE },
        addressClicked ? { icon: helpIconActive2, label: this.props.data.PLACEHOLDERS.ADDRESS_CHANGE } : { icon: helpIcon2, label: this.props.data.PLACEHOLDERS.ADDRESS_CHANGE },
        cancelClicked ? { icon: helpIconActive3, label: this.props.data.PLACEHOLDERS.ENQUIRY } : { icon: helpIcon3, label: this.props.data.PLACEHOLDERS.ENQUIRY },
        ];
        let obj = this.props.isWbkoSelected && (updateContactDetailsClicked ? { icon: helpIconActive4, label: this.props.data.PLACEHOLDERS.UPDATE_CONTACT_DETAILS }
            : { icon: helpIcon4, label: this.props.data.PLACEHOLDERS.UPDATE_CONTACT_DETAILS });
        obj && options.push(obj);
        if (this.props.isWbkoSelected) {
            if (this.props.isBackOrderCase) {
                options.splice(1, 1);
            } else {
                //options.splice(0, 1);
            }
        } else if (this.props.isBackOrderCase) {
            options.splice(1, 1);
        }

        return (
            <div>
                <div className="ais_info-container">
                    <label className="ais_choose-option-txt">{this.props.data.title}</label>
                </div>
                <div
                    className={`${isWbkoSelected ? getContactDetails && shippingMethodsResult ? 'ais_wrapper ais_grid-options ' : ' disabled  ais_wrapper ais_grid-options' : ' ais_wrapper ais_grid-options '}`} >
                    {options.map((e, i) => this._renderOptions(e, i))}
                </div>
                <div class='backToMainMenu'>
                    <a className="backToMainMenu" disabled="" onClick={this.backToMainMenu} >{MESSAGE_CONSTANTS.BackToMainMenu}</a>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { backOrderCase, isWbkoSelected, clientData, warehouseId, getContactDetails, shippingMethodsResult, determineIncidentResponse } = state.customerData;

    return {
        getContactDetails, shippingMethodsResult,
        isBackOrderCase: backOrderCase,
        isWbkoSelected: isWbkoSelected,
        cacheId: clientData.CacheId,
        warehouseId: warehouseId,
        deliveryWindow: determineIncidentResponse.DeliveryWindow
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        savePayload, shippingmethodsV2Init
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewOptions);