import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { createNewServiceRequestInit, savePayload } from '../../actions/Dashboard/doFindAgreements';
import { SAVE_DEFECT_OPTION } from '../../actions/Dashboard/actionTypes';
import CONSTANTS from '../../utils/Constants';

class EnquiryOptionsSA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDiv: null
        }
    }

    handleClick = (name) => {
        // console.log(name);

        let source;

        let {
            savePayload,
            dataprops: { enquiryType, skipvalidation },
            resumePoint,
            updateJourneyList
        } = this.props;

        //  savePayload(SAVE_DEFECT_OPTION,name)



        if (enquiryType === 'replacement' && !skipvalidation) {
            source = CONSTANTS.SOURCE_USER_INPUT.ENQUIRY;
        } else if ((enquiryType === 'swap' || enquiryType === 'malfunction') && !skipvalidation) {
            source = CONSTANTS.SOURCE_USER_INPUT.MALFUNCTION_SELECTED;
            // routed the process incident after triage question in case of malfunction
        } else if (enquiryType === 'replacement' && skipvalidation) {
            source = CONSTANTS.SOURCE_USER_INPUT.ENQUIRY_NOEXISTING_RESUMECASE
        }
        if (!this.state.selectedDiv) {
            this.setState({
                selectedDiv: name
            });
            // console.log("NMM", name);
            updateJourneyList(source, name);
        }
    }

    _renderOptions(option, i) {

        let btnClasses = classNames({
            'disabled': this.state.selectedDiv,
            "ais_options-replacement-selected-div": this.state.selectedDiv === option.text,
            "ais_options-replacement-div": this.state.selectedDiv !== option.text
        })

        return <div
            name={option.text}
            key={option.text}
            disabled={this.state.selectedDiv}
            className={btnClasses}
            onClick={() => this.handleClick(option.text)}>
            <label className={this.state.selectedDiv === option.text ? "ais_options-selected-label" : "ais_options-label"}>{option.text}</label>
        </div>
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="ais_info-container">
                        {/*  <div className="ais_info-icon"></div> */}
                        <label className="ais_choose-option-txt">{this.props.data.title}</label>
                        {this.props.data.subTitle && <p className="sub-title">{this.props.data.subTitle}</p>}
                    </div>
                    {this.props.data && this.props.data.buttons.map((e, i) => this._renderOptions(e, i))}
                </div>
            </Fragment>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        createNewServiceRequestInit,
        savePayload
    }, dispatch)
};


const mapStateToProps = state => {
    return {
        cacheId: state.customerData.clientData.CacheId,
        resumePoint: state.customerData.resumePoint
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(EnquiryOptionsSA);
