import React from "react";

import CONSTANTS from '../../utils/Constants';

import myIphone from "../../images/mobile/img-fmip_50.png";

class TurnOffFindMyPhone extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            buttonClassName: "ais_device-select-button ais_turn-off-fmip",
            disabled: false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {        
        this.setState({
            buttonClassName: "ais_device-select-button ais_selected ",
            disabled: true
        })
        this.props.updateJourneyList(CONSTANTS.MERGE_MALFUNCTION_FLOW, "");
    }

    render() {
        const {data} = this.props;
        return (
            <div className="ais_current-form-device-div turn-off-fmip">
                <div className="ais_header-txt-div turn-off-fmip">
                    <label className="ais_current-mobile-device-label">{data.HEADER}</label>
                    <div>  <img alt="alternate-device-icn" className="ais_confirm-delivery-icn" src={myIphone} /> </div>
                    <ol>
                      <li>{data.CONTENT1}</li>
                      <li>{data.CONTENT2}</li>
                      <li>{data.CONTENT3}</li>
                      <li>{data.CONTENT4}</li>
                      <li>{data.CONTENT5}</li>
                    </ol>
                    <label className="ais_current-device-spec">
                      {data.TURN_OFF_FMIP_MESSAGE}
                    </label>
                    {/* <div className="outer-align-button">
                        <button className={this.state.buttonClassName} disabled={this.state.disabled} onClick={this.handleClick} >{data.BUTTON}</button>
                    </div> */}
                </div>
            </div>
        )
    }
}
export default TurnOffFindMyPhone;