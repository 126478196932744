import moment from "moment"
import React, { Component } from "react"
import { connect } from "react-redux"
import Icons, { EnrolledDeviceIcon, LeftArrowIcon } from "../../components/Icons/Icons"
import MESSAGE_CONSTANTS from "../../config/journey-messages"
import CONSTANTS from "../../utils/Constants"
import './SubscriptionDetails.css'

const { SUBSCRIPTION_OPTION } = MESSAGE_CONSTANTS
class SubscriptionDetailsComponent extends Component {
  constructor() {
    super()
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    imgError: true
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({
        imgError: false
      })
    }, 500)
  }

  handleClick() {
    this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESUME_REQUEST_OPTIONS, MESSAGE_CONSTANTS.BACK_TO_PREV_STEP)
  }


  render() {
    const { serviceFeeResponse, subscriptionDeviceImgName, imageBaseUrl } = this.props;
    const { SubscriptionDetails, ServiceFee } = serviceFeeResponse || {};
    // const { FeeAmount: swapFeeAmount, CurrencyCode: swapCurrencyCode } = this.props.swapFee.Fees
    // const { FeeAmount: replaceFeeAmount, CurrencyCode: replaceCurrencyCode } = this.props.replaceFee.Fees
    const deviceImageJPG = `${imageBaseUrl}${subscriptionDeviceImgName}`;


    const SUBSCRIPTION_OPTIONS = [
      // {
      //   icon: 'ENTROLLED_DEVICE',
      //   label: SUBSCRIPTION_OPTION.EnrolledDevice,
      //   value: SubscriptionDetails.EnrolledDevice,
      // }, 
      {
        icon: 'MOBILE',
        label: SUBSCRIPTION_OPTION.MobileDeviceNumber,
        value: SubscriptionDetails.MobileDeviceNumber,
      }, {
        icon: 'MOBILE',
        label: SUBSCRIPTION_OPTION.ProgramName,
        value: SubscriptionDetails.ProgramName,
      }, {
        icon: 'CALENDER',
        label: SUBSCRIPTION_OPTION.AgreementStartDate,
        value: moment(SubscriptionDetails.AgreementStartDate).format('DD MMMM YYYY'),
        status: SubscriptionDetails.AgreementStatus,
      }, {
        icon: 'SUBSCRIPTION_FEE',
        label: SUBSCRIPTION_OPTION.Subscription,
        value: `THB ${ServiceFee || 'No Data Available'} ${this.props.IsMonthly ? '/month' : '/year'}`,
      }, {
        icon: 'SWAPFEE',
        label: SUBSCRIPTION_OPTION.SWAP,
        value: this.props.swapFee.Fees.CurrencyCode + ' ' + this.props.swapFee.Fees.TotalAmount, //Get from same API
      }, {
        icon: 'REPLACEMENT',
        label: SUBSCRIPTION_OPTION.REPLACE,
        value: this.props.replacementFee.Fees.CurrencyCode + ' ' + this.props.replacementFee.Fees.TotalAmount,
      },
    ]




    return (
      <div className="ais_current-form-device-div subscription-details-wrap">
        <div className="ais_header-txt-div">
          <label className="ais_choose-option-txt">{SUBSCRIPTION_OPTION.TITLE}</label>
          <ul className="subscription-list">
          <li className="d-flex">
              <i>{!this.state.imgError ? <img src={deviceImageJPG}  onError={() => {this.setState({imgError: true})}}/>:
             <EnrolledDeviceIcon />}</i>
              <div className="subscription-content">
                <label>{SUBSCRIPTION_OPTION.EnrolledDevice}</label>
                <strong>{SubscriptionDetails.EnrolledDevice}</strong>
              </div>
            </li>
            {SUBSCRIPTION_OPTIONS.map(e => <li key={e.label} className="d-flex">
              <i>{Icons[e.icon]}</i>
              <div className="subscription-content">
                <label>{e.label}</label>
                <strong>{e.value}</strong>
                {e.status && <button className="btn btn-darkgreen">{e.status}</button>}
              </div>
            </li>)}
          </ul>
          <div className="text-left"><span className="ais_go_back" onClick={this.handleClick}><LeftArrowIcon /> {MESSAGE_CONSTANTS.BACK_TO_PREV_STEP}</span></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ customerData }) => {
  return {
    serviceFeeResponse: customerData.serviceFeeResponse,
    swapFee: customerData.swapFee,
    replacementFee: customerData.replacementFee,
    IsMonthly: customerData.IsMonthly,
    imageBaseUrl: customerData.clientData.imageBaseUrl,
    subscriptionDeviceImgName: customerData.subscriptionDeviceImgName,
  }
}

export default connect(mapStateToProps)(SubscriptionDetailsComponent)