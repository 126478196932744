import React from "react";

import CONSTANTS from "../../utils/Constants";
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import {isDeviceOrWeb} from '../../utils/utils';

import uploadIcon from "../../images/mobile/ic-upload.png";

class UploadDocument extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            buttonClassName: "ais_device-select-button",
        }
    }

    handleClick = () => {
        this.setState({
            disable: true,
            buttonClassName: "ais_device-select-button-submitted"
        }); 
         this.props.updateJourneyList(CONSTANTS.CONFIRM_UPLOAD_DOCUMENTS, null);
    }

    render() {
        return (
            <div className="ais_current-form-device-div">
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label">{MESSAGE_CONSTANTS.UPLOAD_YOUR_SUPPORTING_DOCS.HEADER}</label>
                    <div>
                        <img alt="invoice-icon" className="ais_confirm-delivery-icn" src={uploadIcon} />
                    </div>
                    <label className="ais_current-device-spec upload">
                    {isDeviceOrWeb() === 'mobile'?MESSAGE_CONSTANTS.UPLOAD_YOUR_SUPPORTING_DOCS.SELECT_FROM_PHONE:MESSAGE_CONSTANTS.UPLOAD_YOUR_SUPPORTING_DOCS.SELECT_FROM_COMPUTER}
                    </label>
                    <div className="ais_outer-align-button doc">
                        <button disabled={this.state.disable} className={this.state.buttonClassName} onClick={this.handleClick}>{MESSAGE_CONSTANTS.UPLOAD_YOUR_SUPPORTING_DOCS.BUTTONS[0]}</button>
                    </div>
                </div>
            </div>


        )
    }
}
export default UploadDocument;