import axios from 'axios';
import config from './../config/config'
import _ from 'lodash';

let API_ENDPOINT = config.UIAPIServer.baseUrl;

let headers = process.env.REACT_APP_IS_LOCAL_ENVIORNMENT === 'TRUE' ? Object.assign({}, { 'Content-Type': 'application/json' }) : Object.assign({}, {
  'Content-Type': 'application/json',
  "x-api-key": config.UIAPIServer.APIKey
});

let API_ENDPOINT_SLS = config.slsAPIs.baseURL;

let headerSLS =  Object.assign({}, {
  'Content-Type': 'application/json',
  "x-api-key": config.slsAPIs.APIKey
});

export const horizon_axios_instance = axios.create({
  baseURL: API_ENDPOINT,
  headers
})

// Add a request interceptor
horizon_axios_instance.interceptors.request.use(

  axconfig => {
    // Do something before request is sent
    // Loader appears
    return axconfig;
  },
  error => {

    // https://github.com/axios/axios#handling-errors
    if (error.request) {
      // Show service unavailable component
    }
    // Loader *poof*
    return Promise.reject(error);
  }
);

// response interceptor
horizon_axios_instance.interceptors.response.use(
  response => {  
    if(_.isEmpty(response.data)) {
      return Promise.reject({});
    } else {
      return response;
    }
  },
  error => {   
    return Promise.reject(error);
  }
);


export const horizon_axios_sls_instance = axios.create({
  baseURL: API_ENDPOINT_SLS,
  headers:headerSLS
})

// Add a request interceptor
horizon_axios_sls_instance.interceptors.request.use(
  axconfig => {
    return axconfig;
  },
  error => {
    if (error.request) {
    }
    return Promise.reject(error);
  }
);

// response interceptor
horizon_axios_sls_instance.interceptors.response.use(
  response => {  
    if(_.isEmpty(response.data)) {
      return Promise.reject({});
    } else {
      return response;
    }
  },
  error => {   
    return Promise.reject(error);
  }
);