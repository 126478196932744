import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  savePayload,
  updateTransitionPanel,
  createChatEnquiry
} from "../../actions/Dashboard/doFindAgreements";
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import CONSTANTS from '../../utils/Constants';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import { LOAD_THANK_YOU, STAY_WITH_AIS, WBKO_SAVE_FIRST_NAME, WBKO_SAVE_MOBILE_NUMBER, WBKO_SAVE_ID_NUMBER } from '../../actions/Dashboard/actionTypes';
import wbko_ic_NRIC from '../../images/ais-images/wbko-contact-details.png';
import { validateNameRegex } from '../../utils/utils';

class ChurnPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminateAISMobileCare: null,
      disabled: false,
      keepButtonClassName: "ais_device-select-button ais_credit-cardd",
      chaneButtonClassName: "ais_device-select-button ais_credit-cardd",
    };
    this.submitContactDetails = this.submitContactDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    switch (event.target.name) {
      case 'wbkoFirstName':
        event.target.value = event.target.value.replace(validateNameRegex(), '');
        this.props.savePayload(WBKO_SAVE_FIRST_NAME, event.target.value)
        break;
      case 'wbkoMobileNumber':
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        this.props.savePayload(WBKO_SAVE_MOBILE_NUMBER, event.target.value)
        break;
      case 'wbkoIdNumber':
        this.props.savePayload(WBKO_SAVE_ID_NUMBER, event.target.value)
        break;
    }
  }

  submitContactDetails(e) {
    let value = e.target.value;
    MIXPANEL.track(EVENTLIST.WBKO_UPDATE_CONTACT_DETAILS);
    this.setState({
      disable: true,
      disableInput: true,
      chaneButtonClassName: value === 'Change' ? "ais_device-select-button ais_credit-cardd ais_btnSel" : this.state.chaneButtonClassName,
      keepButtonClassName: value === 'Keep' ? "ais_device-select-button ais_credit-cardd ais_btnSel" : this.state.keepButtonClassName
    });

    const { selectedEnquiryOption, wbkoFirstName, wbkoIdNumber, wbkoMobileNumber } = this.props;
    let typedText = `${MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_FIRSTNAME} ${wbkoFirstName} \n ${MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_PHONENUMBER} ${wbkoMobileNumber} \n ${MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_IDNUMBER} ${wbkoIdNumber} \n`;
    //this.props.submitEnquiryDetails(typedText, CONSTANTS.ENQUIRY_SUB_REASON.WBKO_UPDATE_CONTACT_DETAILS,this.state.reasonText, CONSTANTS.ENQUIRY_SUB_REASON.WBKO_UPDATE_CONTACT_DETAILS);
    this.props.createChatEnquiry(CONSTANTS.ENQUIRY_SUB_REASON.WBKO_UPDATE_CONTACT_DETAILS, CONSTANTS.ENQUIRY_SUB_REASON.WBKO_UPDATE_CONTACT_DETAILS, typedText, this.props.customerContact, null,
      null, null, null, false, 'Low', true);
  }
  render() {
    let { disabled, disableInput, disable } = this.state;
    let { wbkoMobileNumber, wbkoIdNumber, wbkoFirstName } = this.props;
    let validity = !wbkoMobileNumber || !wbkoIdNumber || !wbkoFirstName;

    let mobileHeader = MESSAGE_CONSTANTS && MESSAGE_CONSTANTS.MODAL_HEADER && MESSAGE_CONSTANTS.MODAL_HEADER;
    let alternateContactNoValidationFlag = wbkoMobileNumber && wbkoMobileNumber.length < 8;
    let idValidationFlag = wbkoIdNumber && wbkoIdNumber.length < 6;


    return (
      <div>
        <div className="ais_current-form-device-div">
          <div className="ais_header-txt-div">
            <div>
              <div className="center">
                <div> <img alt="schedule-delivery-icn" className="ais_confirm-delivery-icn" src={wbko_ic_NRIC} /> </div>
                <div className='wbkoMargin8 wbkoUpdateContactDetailsHeader'>{MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_HEADER}</div>
                <div className='margin-bottom10 wbkoUpdateContactDetailsInput' >
                  <input
                    type="text"
                    name="wbkoFirstName"
                    className='wbkoInputbox'
                    // onChange={this.handleChange}
                    onChange={(e) => this.handleChange(e)}
                    onKeyDown={this.handleKey}
                    ref="ref_input"
                    placeholder={MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_FIRSTNAME_PLACEHOLDER}
                    value={this.state.wbkoFirstName}
                    autoComplete="off"
                    disabled={this.state.disableInput}
                  /> </div>
                <div className='margin-bottom10 wbkoUpdateContactDetailsInput'>
                  <input
                    type="text"
                    name="wbkoMobileNumber"
                    className='wbkoInputbox'
                    // onChange={this.handleChange}
                    onChange={(e) => this.handleChange(e)}
                    onKeyDown={this.handleKey}
                    ref="ref_input"
                    placeholder={MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_PHONENUMBER_PLACEHOLDER}
                    value={this.state.wbkoMobileNumber}
                    autoComplete="off"
                    disabled={this.state.disableInput}
                    maxLength={11}
                    minLength={8}
                  /></div>
                <div className='margin-bottom10 wbkoUpdateContactDetailsInput'>
                  <input
                    type="text"
                    name="wbkoIdNumber"
                    className='wbkoInputbox'
                    // onChange={this.handleChange}
                    onChange={(e) => this.handleChange(e)}
                    onKeyDown={this.handleKey}
                    ref="ref_input"
                    placeholder={MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_IDNUMBER_PLACEHOLDER}
                    value={this.state.wbkoIdNumber}
                    autoComplete="off"
                    disabled={this.state.disableInput}
                    maxLength={6}
                    minLength={6}
                  /></div>
                {/* <div className = 'wbkoMarginTop25'> {MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS_TEXT}</div>WBKO_CHANGE WBKO_KEEP */}
                {/* <div className="center">
                        <button className="wbkoUpdateContactDetailsBtn" disabled = {validity || disableInput || alternateContactNoValidationFlag || idValidationFlag}  onClick={this.submitContactDetails}>Submit</button>
                    </div> */}
                <div className="ais_outer-align-button">
                  <button
                    value='Change'
                    disabled={validity || disableInput || alternateContactNoValidationFlag || idValidationFlag || disable}
                    className={this.state.chaneButtonClassName}
                    onClick={(e) => this.submitContactDetails(e)} >{MESSAGE_CONSTANTS.WBKO_CHANGE}
                  </button>
                  <div className="ais_device-divider ais_button" />
                  <button
                    value='Keep'
                    disabled={validity || disableInput || alternateContactNoValidationFlag || idValidationFlag || disable}
                    className={this.state.keepButtonClassName}
                    onClick={(e) => this.submitContactDetails(e)} >{MESSAGE_CONSTANTS.WBKO_KEEP}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  let { createInquiryResponse, selectedEnquiryOption, wbkoFirstName, wbkoIdNumber, wbkoMobileNumber, customerContact } = state.customerData;
  return {
    createInquiryResponse, selectedEnquiryOption, wbkoFirstName, wbkoIdNumber, wbkoMobileNumber, customerContact
  }
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePayload,
      updateTransitionPanel,
      createChatEnquiry
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChurnPopup);

