import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  savePayload,
  updateTransitionPanel
} from "../../actions/Dashboard/doFindAgreements";

import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { SELECTED_LEAVING_AIS_REASON } from '../../actions/Dashboard/actionTypes';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import { getLang } from './../../utils/utils';
import OtherReasonsIcon from '../../images/ais-images/icon-other-reasons.png'

class RequestForLeavingAIS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iconData:[
        {"en_name":"Not Helpful",
        "thai_name":"บริการไม่มีประโยชน์",
        "key":CONSTANTS.NOT_HELPFULL,
        "image":"/images/not-helpfull.svg",
        "afterSelectionImage":"/images/ic-not-helpful-active.svg"
      },
        {"en_name":"Cost too much",
        "thai_name":"ราคาแพงเกินไป",
        "key":CONSTANTS.COST_TOO_MUCH,
        "image":"/images/cost-too-much.svg",
        "afterSelectionImage":"images/cost-too-much-afterselection.svg"
      },
        {"en_name":"Buying a new device",
        "thai_name":"ต้องการซื้อเครื่องใหม่",
        "key":CONSTANTS.BUYING_NEW_DEVICE,
        "image":"/images/buying-new-device.svg",
        "afterSelectionImage":"images/buying-new-device-after-selection.svg"
      },
        {"en_name":"No need package auto-renewal",
        "thai_name":"แพคเกจต่ออัติโนมัติ",
        "key":CONSTANTS.NO_NEED_PACKAGE_AUTO_RENEWAL,
        "image":"/images/no-need-package-auto-renewal.svg",
        "afterSelectionImage" :'images/no-need-package-auto-renewal-afterselection.svg'
      },
        {"en_name":"Not using it enough or not at all",
        "thai_name":"ไม่มีความจำเป็น",
        "key":CONSTANTS.NOT_USING_IT_ENOUGH_OR_NOT_AT_ALL,
        "image":"/images/not-using-it-enough-or-not-at-all.svg",
        "afterSelectionImage":"/images/not-using-it-enough-or-not-at-all-afterselection.svg"
      },
        {"en_name":"Other reason",
        "thai_name":"เหตุผลอื่น ๆ โปรดระบุ ",
        "key":CONSTANTS.OTHER_REASON,
        "image":OtherReasonsIcon,
        "afterSelectionImage":'images/other-reason-afterselection.svg'
      }]
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(text) {
    MIXPANEL.track(EVENTLIST.LEAVING_AIS,{'AIS Leavig Reason':text});
    this.props.savePayload(SELECTED_LEAVING_AIS_REASON, text);
    let language = getLang();
    let {iconData} = this.state;
    let enteredReason= iconData && iconData.filter(data=>{return data.key===text});
    let filteredData= enteredReason && enteredReason[0];
    let enteredReasonDesc=language==='th'?filteredData.thai_name :filteredData.en_name;
    this.props.updateJourneyList(CONSTANTS.AIS_REASON_NOT_HELPFULL, {enteredReasonDesc : enteredReasonDesc, key : filteredData.key});
    let {Platform} =this.props;
    if(text !== CONSTANTS.OTHER_REASON){
      MIXPANEL.track(EVENTLIST.ROUTE_TO_REFUND_OR_SAVEDESK,{'Route To Refund Or Savedesk':Platform});
        if(Platform==='SaveDesk'){
          this.props.updateJourneyList(CONSTANTS.ROUTE_TO_SAVEDESK, enteredReasonDesc );
        }
    }

  }
  render() {
    const lang = getLang();
    let {iconData}= this.state;
    let {selectedLeavingAISReason}=this.props;

    return (
      <div>
        <div className="ais_current-form-device-div width-full desktop-no-box-view">
          <div className="ais_header-txt-div">
           <label className="ais_current-mobile-device-content1" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.AIS_LEAVING_REASON_CONTENT1 }}></label>
            <hr className='green-break-line' />
            <label className="ais_current-mobile-device-content2" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.AIS_LEAVING_REASON_CONTENT2 }}></label>
            <label className="ais_current-mobile-device-content3" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.AIS_LEAVING_REASON_CONTENT3 }}></label>
            <div className='reason-wrapper' >
            {iconData && iconData.map((data)=>{
              return <div
                    key={data.key}
                    className={`reason-inner ${selectedLeavingAISReason=== data.key? ' reason-inner-selected ' :'' } ${selectedLeavingAISReason? ' disabled ': ''}`}
                    disabled={selectedLeavingAISReason ? true: false}
                    onClick={(e) => this.handleClick(data.key)}>
                    <div className="reason-items">
                      <div><img src={data.image} /></div>
                      <div>{(lang === "th") ? data.thai_name : data.en_name}</div>
                      </div>
                  </div>
              })}
            </div>
          </div>
        </div>
        <br />
      </div>
    )
  }
}
const mapStateToProps = state => {
  let {swapFee,selectedLeavingAISReason,Platform}= state.customerData;
  return {swapFee,selectedLeavingAISReason,Platform}
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePayload,
      updateTransitionPanel
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestForLeavingAIS);

