import React from "react";
import { Mutation } from "react-apollo";
import CreateVisitorMutation from './../../../graphql/mutation/CreateVisitor';
import CallCreateVisitor from './CallCreateVisitor';

const CreateVisitor = ({ visitor, sendMessage, messageList, onError }) => (
  <Mutation
    mutation={CreateVisitorMutation}
    onError={onError}
  >
    {(createVisitor) => {
      return (
        <CallCreateVisitor createVisitor={createVisitor} visitor={visitor} sendMessage={sendMessage}
          messageList={messageList} />
      )
    }}
  </Mutation>
);

export default CreateVisitor;