import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import {
    getShippingOrderInit,
    getStandardiseAddress,
    submitEnquiryDetails,
    updateContactDetails
} from '../../actions/Dashboard/doFindAgreements';

import CONSTANTS from "../../utils/Constants";
import { isServiceOrderPreviouslyCalled } from '../../utils/utils';

import enrolledAddressIcn from "../../images/ais-images/icon-delivery-address.png";
import enrolledEmailIcn from "../../images/ais-images/icon-email-address.png";
import enrolledMobNoIcn from "../../images/ais-images/icon-mobile-number.png";
import wbko_chnage_delivery_address from '../../images/ais-images/wbko-change-address.png'
import MESSAGE_CONSTANTS from "../../config/journey-messages";

class ConfirmEnrolledAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
            content: ""
        }
        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleYesClick = this.handleYesClick.bind(this);
        this.emailBackBtton = this.emailBackBtton.bind(this);
        this.addressBackBtton = this.addressBackBtton.bind(this);
    }

    handleNoClick() {
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.dataprops.source, this.props.isWbkoSelected ? 'Change': "No");
    }

    emailBackBtton() {
        this.setState({
            disable: true,
        });
        this.props.updateJourneyList(CONSTANTS.RESUME_POINT_CONFIRM_CONTACT_POINTS, MESSAGE_CONSTANTS.CHANGE_MOBILE_NO);
    }

    addressBackBtton() {
        this.setState({
            disable: true,
        });
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION, MESSAGE_CONSTANTS.CHANGE_EMAIL_ID);
    }

    handleYesClick() {

        const { serviceRequestType, resumePoint: currentResumePoint, dataprops: { source }, enquiryDetails, submitEnquiryDetails } = this.props;

        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });
        if (source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION) {
            this.props.getStandardiseAddress(this.props.cacheId, this.props.postalCode, true, this.props.warehouseId)
            // this.props.getShippingOrderInit(this.props.cacheId,this.props.warehouseId);
            this.props.updateJourneyList(source, "Yes");
            let {isWbkoSelected} = this.props;
            isWbkoSelected && this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_DELIVERY_ADDRESS_ENQUIRY, '');
            //if wants to create inquiry pass address details and create submit inquiry
            //let {enrolledAddress,isWbkoSelected,selectedEnquiryOption} = this.props;
            //isWbkoSelected && this.props.submitEnquiryDetails(description, selectedEnquiryOption, '', CONSTANTS.ENQUIRY_SUB_REASON.CHANGE_DELIVERY_INFO)

        } else if (source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION) {
            let { cacheId, newContactNo } = this.props;
            this.props.updateContactDetails(cacheId, newContactNo, "");

            // resumeServiceRequest should skip SIM, if serviceOrder is called previously
            if ((this.props.caseType === CONSTANTS.MALFUNCTION || this.props.caseType === CONSTANTS.SWAP) || (serviceRequestType === "ResumeServiceRequest" && isServiceOrderPreviouslyCalled(currentResumePoint))) {
                // skip the SIM popup and go to postal
                this.props.updateJourneyList(source, "SkipToPostal");
            } else {
                this.props.updateJourneyList(source, "Yes");
            }
        } else {
            this.props.updateJourneyList(source, "Yes");
        }
    }

    componentWillMount() {
        const { dataprops: { source } } = this.props;

        if (source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION) {
            this.setState({
                content: CONSTANTS.PIN_CODE + this.props.postalCode
            });
        } else if (source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION) {
            this.setState({
                content: this.props.emailAddress
            });
        } else if (source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION) {
            this.setState({
                content: this.props.phoneNumber
            });
        }
    }

    render() {
        const { dataprops: { source } ,isWbkoSelected,ShippingAddress} = this.props;
        return <div className="ais_current-form-address-popup">
            {!isWbkoSelected && <div className="ais_header-txt-div ais_enrolledAddress">
                <label className="ais_current-mobile-device-label-cus">
                  {this.props.data.HEADER}
                </label>
              </div>}
            <div className="ais_center-align">
              {source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION ? <div>
                    {" "}<img alt="enroll-address-icn" className="ais_confirm-delivery-icn" src={isWbkoSelected ? wbko_chnage_delivery_address : enrolledAddressIcn} />{" "}
                  </div> : source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION ? <div>
                      {" "}<img alt="enrolled-email-icn" className="ais_confirm-delivery-icn" src={enrolledEmailIcn} />{" "}
                    </div> : source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION || source === CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK ? <div>
                        {" "}<img alt="enrolled-mobileNo-icn" className="ais_confirm-delivery-icn" src={enrolledMobNoIcn} />{" "}
                      </div> : null}
              {isWbkoSelected && <div className="wbkoChangeAddressHeader">
                  {this.props.data.WBKO_HEADER}
                </div>}

              {source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION ?
                isWbkoSelected ?
                  <div className="container-fluid wbko-address-wrapper">
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt wbko-ais_address_padding ">
                        <div style={{ display: "initial" }} classname="wbko-ais_address-label-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS1 }} />
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left wbko-ais_address_padding ">
                        <label className="wbko-asi-address-text-align">
                          { ShippingAddress.Address1}
                        </label>
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt wbko-ais_address_padding ">
                        {ShippingAddress.Address2 ? <div style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS2 }} /> : ""}
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left wbko-ais_address_padding ">
                        {ShippingAddress.Address2 ? <label className="wbko-asi-address-text-align">
                              {ShippingAddress.Address2}
                            </label> : ""}
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt wbko-ais_address_padding ">
                        {ShippingAddress.Address3 ? <div style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS3 }} /> : ""}
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left wbko-ais_address_padding ">
                        {ShippingAddress.Address3 ? <label className="wbko-asi-address-text-align">
                              {ShippingAddress.Address3}
                            </label> : ""}
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt wbko-ais_address_padding ">
                        <div style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.POSTALCODE }} />
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left wbko-ais_address_padding ">
                        <label className="wbko-asi-address-text-align">
                          {ShippingAddress.PostalCode}
                        </label>
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt wbko-ais_address_padding ">
                        <div style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.CITY }} />
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left wbko-ais_address_padding ">
                        <label className="wbko-asi-address-text-align">
                          {ShippingAddress.City}
                        </label>
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt wbko-ais_address_padding ">
                        <div style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.STATE }} />
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left wbko-ais_address_padding ">
                        <label className="wbko-asi-address-text-align">
                          {ShippingAddress.StateProvinceCode}
                        </label>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="container-fluid wbko-address-wrapper">
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                        <label style={{ display: "initial" }} className="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS1 }} />
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                        <label className="ais_address_align">
                          {this.props.enrolledAddress.Address1}
                        </label>
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                        {this.props.enrolledAddress.Address2 ? <label style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS2 }} /> : ""}
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                        {this.props.enrolledAddress.Address2 ? <label className="ais_address_align">
                              {this.props.enrolledAddress.Address2}
                            </label> : ""}
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                        {this.props.enrolledAddress.Address3 ? <label style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS3 }} /> : ""}
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                        {this.props.enrolledAddress.Address3 ? <label className="ais_address_align">
                              {this.props.enrolledAddress.Address3}
                            </label> : ""}
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                        <label style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.POSTALCODE }} />
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                        <label className="ais_address_align">
                          {this.props.enrolledAddress.PostalCode}
                        </label>
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                        <label style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.CITY }} />
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                        <label className="ais_address_align">
                          {this.props.enrolledAddress.City}
                        </label>
                      </div>
                    </div>
                    <div className="wbko-address-item">
                      <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                        <label style={{ display: "initial" }} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.STATE }} />
                      </div>
                      <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                        <label className="ais_address_align">
                          {this.props.enrolledAddress.StateProvinceCode}
                        </label>
                      </div>
                    </div>
                  </div>
                  :
                  <label className="ais_pincode_txt_content">
                    {this.state.content}
                  </label>}
            </div>
            {this.props.isWbkoSelected ? <div className="wbkoais_note-div" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_ADDRESS_TEXT1 }} /> : ""}

              { this.props.isWbkoSelected ?
                    <div className="wbko-ais_outer-align-button">
                    <div className="ais_device-divider ais_button" />
                        <button disabled={this.state.disable} className={'margin-10 ' + this.state.noButtonClassName} onClick={e => this.handleNoClick(e)}>
                        {this.props.data.BUTTONS[4]}
                        </button>
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={e => this.handleYesClick(e)}>
                        {this.props.data.BUTTONS[5]}
                        </button>
                    </div> :
            this.props.dataprops.isFinalConfirmedEnrolledAddr ? <div className="ais_outer-confirm-delivery-align-button">
                  <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={e => this.handleYesClick(e)}>
                    {this.props.data.BUTTONS[0]}
                  </button>
                </div> : <div className="ais_outer-confirm-delivery-align-button">
                  <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={e => this.handleNoClick(e)}>
                    {this.props.data.BUTTONS[0]}
                  </button>
                  <div className="ais_device-divider" />
                  <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={e => this.handleYesClick(e)}>
                    {this.props.data.BUTTONS[1]}
                  </button>
                </div>}
            {!this.props.isWbkoSelected ? (source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION && this.props.backEnabled ? <button className="ais_go_back" disabled={this.state.disable} onClick={this.emailBackBtton}>
                    {" "}{MESSAGE_CONSTANTS.BACK_TO_PREV_STEP}{" "}
                  </button> : source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION && this.props.backEnabled ? <button className="ais_go_back" disabled={this.state.disable} onClick={this.addressBackBtton}>
                      {" "}{MESSAGE_CONSTANTS.BACK_TO_PREV_STEP}
                    </button> : "") : ""}
          </div>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getShippingOrderInit,
        getStandardiseAddress,
        submitEnquiryDetails,
        updateContactDetails
    }, dispatch)
};


const mapStateToProps = state => {
    console.log(state.customerData);
    let { clientData, customerNewContact, enquiryDetails, backEnabled, enrolledPostalCode, 
      warehouseId, caseType, phoneNumber, serviceRequestType, resumePoint, emailAddress,
      enrolledAddress,isWbkoSelected,ShippingAddress } = state.customerData;
    return {
        isWbkoSelected,
        cacheId: clientData.CacheId,
        newContactNo: customerNewContact,
        postalCode: enrolledPostalCode,
        warehouseId: warehouseId,
        caseType,
        enquiryDetails,
        phoneNumber,
        serviceRequestType,
        resumePoint,
        emailAddress,
        enrolledAddress,
        ShippingAddress,
        backEnabled
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEnrolledAddress);
