import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { saveSelectedReplacementDevice } from '../../actions/Dashboard/doFindAgreements';
import CONSTANTS from "../../utils/Constants";
import MESSAGE_CONSTANTS from "../../config/journey-messages";

import invoiceIcon from "../../images/mobile/img-invoice_50.png"
import paymentFeeIcon from "../../images/mobile/img-payment_50.png";
import feedbackFeeIcon from "../../images/mobile/img-survey_50.png";

class DisabledProceedServiceFee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            highlightedClass: "",
            disable: false,
        };
    }

    componentDidMount() {
        if(_.isEmpty(this.props.selectedReplacementDevice)) {
            this.props.saveSelectedReplacementDevice(this.props.LFLDevice);
        }
    }

    replaceValues() {
        var { data, serviceFee, noServiceFee } = this.props;
        var messageContent = data.CONTENT;
        let componentMsgToBeReplaced = [
            CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED,
            CONSTANTS.DISABLED_PROCEED_SERVICE_FEE,
            CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION
        ]
        if(_.indexOf(componentMsgToBeReplaced, this.props.dataprops.source) > -1) {
            if(noServiceFee) {
                messageContent = messageContent.replace('{CurrencyCode}',serviceFee.CurrencyCode).replace('{TotalAmount}',serviceFee.TotalAmount).replace(MESSAGE_CONSTANTS.READY_CREDIT_CARD, '');
            } else {
              messageContent = messageContent.replace('{CurrencyCode}',serviceFee.CurrencyCode).replace('{TotalAmount}',serviceFee.TotalAmount);
            }
          }
        return messageContent;
    }

    render() {
      const {dataprops: {source}} = this.props;
        return (
            <div className="ais_current-form-device-div proceed">
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
                    {source === CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED ? <div>  <img alt="invoice-icon" className="ais_confirm-delivery-icn" src={invoiceIcon} /> </div>
                    :source === CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION ? <div>  <img alt="payment-fee-icon" className="ais_confirm-delivery-icn" src={paymentFeeIcon} /> </div>
                    :source === CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION ? <div>  <img alt="feedback-icon" className="ais_confirm-delivery-icn" src={feedbackFeeIcon} /> </div>
                    : null}
                    <label className="ais_current-device-spec font-weight" dangerouslySetInnerHTML={{ __html: this.replaceValues() }}></label>
                    <div className="ais_outer-align-button">
                        <button disabled={this.state.disable} className={"ais_device-select-button proceed " + this.state.highlightedClass} >{this.props.data.BUTTON}</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveSelectedReplacementDevice
    }, dispatch)
};

const mapStateToProps = state => {
  const {noServiceFee, LFLDevice, LFLDeviceInStock, selectedReplacementDevice, serviceFee} = state.customerData;
  return {
    noServiceFee,
    LFLDevice: LFLDevice || {},
    LFLDeviceInStock: LFLDeviceInStock || {},
    selectedReplacementDevice: LFLDeviceInStock ? LFLDevice : selectedReplacementDevice,
    serviceFee
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DisabledProceedServiceFee);

