import * as React from 'react';
import { Box, Center, Button, HStack, Text, Image, VStack, Heading } from '@chakra-ui/react';
import defaultImage from '../../images/ais-images/icon-enrolled-device.png';
import { noop, uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import DefaultPhoneDisplay from './DefaultPhoneDisplay';

const { useState, useEffect } = React;

const Ribbon = ({ children, color = 'brand.primary', shadow = '#547C22' }) => (
  <Box
    px={'10px'}
    pt={'4px'}
    pb={'14px'}
    textTransform={'uppercase'}
    fontFamily={'Helvetica'}
    bgColor={color}
    color={'white'}
    position={'absolute'}
    fontWeight={'bold'}
    right={'-20px'}
    __css={{
      ['--t']: '10px',
      ['--f']: '10px',
      inset: 'var(--t) 0 auto auto',
      boxShadow: `0 calc(-1*var(--f)) 0 inset ${shadow}`,
      clipPath: `polygon(
        0 0,
        100% 0,
        100% calc(100% - var(--t)),
        calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),
        0 calc(100% - var(--f))
      )`,
    }}
  >
    {children}
  </Box>
);

export const DeviceCard = ({
  id = uniqueId('device-'),
  brand,
  model,
  serviceFee,
  currency = '฿',
  flags,
  ribbonProps = {},
  ribbonLabel = false,
  disabled = false,
  onSelect = noop,
  rawDevice,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'SmartStock' });
  const handleClick = () => {
    setSelectedId(id);
    onSelect?.(id);
  };

  const [selectedId, setSelectedId] = useState();
  const [label, setLabel] = useState(t('forms.deviceCard.select'));

  useEffect(() => {
    if (flags.isLikeForLike) {
      if (!flags.isInStock) {
        if (flags.isAvailableForBackorder) {
          setLabel(t('outOfStockBackorderAvailable'));
        } else {
          setLabel(t('outOfStock'));
        }
      }
    }
  }, [flags]);

  return (
    <VStack
      minH={{
        base: '330px',
      }}
      borderRadius={'10px'}
      border={'1px'}
      borderColor={'gray.150'}
      mx={'10px'}
      w={'320px'}
      py={'20px'}
      px={'10px'}
      gap={'15px'}
      spacing={0}
    >
      <Box h={'30px'} w={'full'} position={'relative'}>
        {ribbonLabel && <Ribbon {...ribbonProps}>{ribbonLabel}</Ribbon>}
      </Box>
      <HStack w={'full'} gap={'10px'} spacing={0}>
        <Center w={'80px'}>
          <Image src={defaultImage} h={'80px'} title={`${brand} ${model}`} />
        </Center>
        <VStack spacing={0} gap={'5px'} alignItems={'flex-start'}>
          <DefaultPhoneDisplay device={rawDevice} />
          <Text mt={0} fontSize={'lg'}>
            {t('paymentLabel', { currency, amount: serviceFee.toLocaleString() })}
          </Text>
        </VStack>
      </HStack>
      <Text>
        {flags.isInStock ? t('forms.deviceCard.available') : t('forms.deviceCard.notAvailable')}
      </Text>

      <Button
        w={'full'}
        variant={selectedId === id ? 'solid' : 'outline'}
        onClick={handleClick}
        color={
          flags.isLikeForLike && !flags.isInStock && flags.isAvailableForBackorder
            ? 'brand.error'
            : 'black'
        }
        isDisabled={
          disabled || (flags.isLikeForLike && !flags.isInStock && !flags.isAvailableForBackorder)
        }
      >
        {label}
      </Button>

      {flags.isLikeForLike && 
      flags.isAvailableForBackorder &&
      !flags.isInStock && 
      !flags.isNotCurrentlyAvailableForOrder && 
      !flags.isNoLongerProduced && (
        <Center textAlign={'center'} color={'brand.error'} lineHeight={'normal'}>
          {t('backOrderNotice')}
        </Center>
      )}
    </VStack>
  );
};

export default DeviceCard;
