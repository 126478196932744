import React, { Component, Fragment } from "react";
import ais_mobile_care_logo_3x  from '../../images/ais-images/ais-mobile-care-logo@3x.png';
export default function PrivacyPolicy() {

  document.title = "Privacy Policy"

  return (


    <Fragment>
      <div className="row header">
        {/*<div class="headerTermsText">Privacy Policy</div>*/}
        <div className="headerTermsTime">1 May 2018 9:31 AM</div>
      </div>
      <div className="">
        <div className="topSpace">
          <div className="">
            <div className="text">
              <img src={ais_mobile_care_logo_3x} alt className="mobileSwop-logo" />
            </div> <br />
          </div>
          <div className="headerTopSpace">
            <div className="text">
              <div>
                <div className="">
                  <div className=" acc-header">AIS Mobile Care Chat Support Privacy Policy</div>
                </div>
                <div className="">
                  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    {/* Asurion Privacy Statement */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="generalHeader">
                        <h4 className="panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#general" aria-expanded="true" aria-controls="general">
                            <span className="headings">Asurion Privacy Statement</span>
                          </a>
                        </h4>
                      </div>
                      <div id="general" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="generalHeader">
                        <div className="panel-body">
                          <h5>Last updated: 14 June 2019</h5>This Privacy Statement describes how NEW Asurion
                      Singapore Pte Ltd and its subsidiaries and affiliates (“Asurion,” “we,” “us”)
                      collects and uses the personal information you provide. It also describes your
                      choices regarding use, access and correction of your personal information. This
                      Privacy Statement applies to webpages linking to this Statement. At Asurion,
                      we are all working hard to keep your personal information safe, secure, and private.
                      We provide this statement to demonstrate our firm commitment to protecting your
                      privacy. We want you to feel comfortable about visiting our website and providing
                      us with your personal information.
                    </div>
                      </div>
                    </div>
                    {/*  What kind of customer information do we collect, and where did we get it? */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="service-summary-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#service-summary" aria-expanded="false" aria-controls="service-summary">
                            <span className="headings"> We value your privacy</span>
                          </a>
                        </h4>
                      </div>
                      <div id="service-summary" className="panel-collapse collapse" role="tabpanel" aria-labelledby="service-summary-header">
                        <div className="panel-body">
                          <p>At Asurion (as defined below), we take protecting your privacy seriously. In
                          order for us to provide our services (“services”) to you we need to collect
                          and use some of your personal information. We want you to understand what
                        personal information we collect and how it is used.</p>
                          <p>When you use our services, or by accessing the Asurion website (as set out in
                        the <strong>Country</strong> section below), you acknowledge that you (i)
                        have read and accepted this Policy without exception; (ii) consent to our
                        privacy practices, including our collection, use and disclosure of your personal
                        information as set out in this Policy; and (iii) agree to be bound by the
                        Policy. </p>
                          <p>If you do not consent to this Policy, please do not use our services or provide
                        any personal information.</p>
                          <p><br />In this Policy:<br /> (a) “we” and “us” means the Asurion entity offering
                        or providing services to you (as identified in the <strong>Country</strong>                                                section below); and<br /> (b) we set out in the<strong> Country</strong> section
                        below variations to this Policy which apply if your personal information
                        is provided from within one of the countries so listed.
                      </p>
                        </div>
                      </div>
                    </div>
                    {/*  What do we do with your information? */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="enrolling-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false" aria-controls="enrolling">
                            <span className="headings"> What’s covered by this Policy</span>
                          </a>
                        </h4>
                      </div>
                      <div id="enrolling" className="panel-collapse collapse" role="tabpanel" aria-labelledby="enrolling-header">
                        <div className="panel-body">
                          <p>Any service which links or refers to this Policy and any collection, storage,
                          transfer, sharing, disclosure and handling of your personal information when
                        you use our services which we define below.</p>
                          <p>This Policy does not cover and we are not responsible for third-party sites or
                          applications that may be linked from our sites; linking to them does not
                          imply an endorsement. You should always review the privacy policy of any
                        third-party site or application.</p>
                          <p>Please see the end of this Policy for any country specific variations which will
                        apply to you if you receive the services in a country so listed.</p>
                        </div>
                      </div>
                    </div>
                    {/*  Who do we share your information with? */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="fees-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#fees" aria-expanded="false" aria-controls="fees">
                            <span className="headings"> Personal information we collect</span>
                          </a>
                        </h4>
                      </div>
                      <div id="fees" className="panel-collapse collapse" role="tabpanel" aria-labelledby="fees-header">
                        <div className="panel-body">
                          <p>When you use our services, you consent to us collecting your personal information.
                          Examples of your personal information we may collect include:
                      </p>
                          <li>Name;</li>
                          <li>Mailing address;</li>
                          <li>Email address;</li>
                          <li>Cell/mobile/hand phone number;</li>
                          <li>Information that identifies your mobile device, such as Electronic Serial Number
                        ("ESN") or International Mobile Equipment Identity ("IMEI") data;</li>
                          <li>Device serial number and media access control ("MAC") address; </li>
                          <li>Information collected after obtaining your permission during your use of the
                          services;
                      </li>
                          <li>Any information that you provide as part of requesting our services (a “service
                          request”);
                      </li>
                          <li>Other information that personally identifies you or is linked or linkable to
                          you received in the course of providing you with the services, such as the
                        date that you purchase our services, and cookies when you access our website.</li>
                          <p>Asurion will endeavor to collect personal information directly from you whenever
                          possible. Your personal information may be collected directly from you during
                          your telephone discussions with us, when you submit forms to us, from Asurion
                          affiliated companies, from our records of how you use our services or when
                          you complete on-line interfaces including mobile applications, amongst other
                          sources. By providing your personal information, you expressly consent to
                        our collection of it.</p>
                          <p>As part of the service request, Asurion or its affiliates may require verification
                          of your identity by requesting that you present your driver’s license, passport,
                          military ID, or other personal identification card. Additionally, in order
                          to process your service request, Asurion or its affiliates may request your
                          shipping and billing information, credit card information or other preferred
                        payment means.</p>
                          <p>Please note that if we do not have your personal information, we may not be in
                        a position to:</p>
                          <li>provide our services to you, (or to a company who we provide services to who
                        you are contracted to), either to the same standard or at all;</li>
                          <li>provide you with information about services that you may want, including information
                        about special promotions; and/or</li>
                          <li>tailor the content of our website to your preferences and your experience of
                        our website may not be as enjoyable or useful.</li>
                        </div>
                      </div>
                    </div>
                    {/* Access and Choice */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="termination-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#termination" aria-expanded="false" aria-controls="termination">
                            <span className="headings">What are the purposes for which we collect, use and disclose your personal information</span>
                          </a>
                        </h4>
                      </div>
                      <div id="termination" className="panel-collapse collapse" role="tabpanel" aria-labelledby="termination-header">
                        <div className="panel-body">
                          <p>Asurion currently, and/or may in the future, collect, use and disclose your personal
                          information as authorised or required by written law and/or for any or all
                        of the following purposes (“Purposes”):</p>
                          <li>Fulfill a service or a service request to you;</li>
                          <li>Complete your purchase;</li>
                          <li>Establish and verify your identity;</li>
                          <li>Activate and maintain and your account;</li>
                          <li>Handle and resolve billing disputes;</li>
                          <li>Monitor and maintain the performance and functionality of the device consistent
                        with your choices;</li>
                          <li>Provide technical support and click-to-chat functionality;</li>
                          <li>Protect and improve our services;</li>
                          <li>Offer updates, notices and other information about product or services available
                          from Asurion or its affiliates as permitted by law. (Note: at any time you
                          may choose to withdraw your consent to Asurion’s use or disclosure of your
                          personal information for marketing and promotional purposes by contacting
                          Asurion as outlined in Where to turn with questions section of this Policy);
                      </li>
                          <li>Respond to your questions, inquiries, comments and instructions; </li>
                          <li>Preventing, detecting or investigating crime and other unlawful activities, including
                          fraud and money-laundering or terrorist financing, and analysing and managing
                        commercial risks; </li>
                          <li>Comply with applicable laws, regulations, codes of practice, guidelines, or rules,
                          or to assist in law enforcement and investigations conducted by any governmental
                        and/or regulatory authority; </li>
                          <li>In connection with any claims, actions or proceedings (including but not limited
                          to drafting and reviewing documents, transaction documentation, obtaining
                          legal advice, and facilitating dispute resolution), and/or protecting and
                        enforcing our contractual and legal rights and obligations;</li>
                          <li>Track and analyze de-identified data and provide such data to third parties that
                        provide services to Asurion;</li>
                          <li>Statistical purposes to help improve the service;</li>
                          <li>Protect against fraud and potential fraud;</li>
                          <li>Notify you of any known breach of your personal information;</li>
                          <li>Maintain the security and integrity of Asurion’s systems and services;</li>
                          <li>Market services related to the services you have subscribed to;</li>
                          <li>Transmit to any of our affiliated entities and relevant governmental and/or regulatory
                        authorities, for the aforementioned purposes; and</li>
                          <li>Any other related purposes that would reasonably be expected for which you provided
                        the information,</li>
                          <p>in accordance with consent obtained from you in accordance with local laws applying
                        in the country in which you receive the services.</p>
                          <p>These purposes may continue to apply even in situations where your relationship
                          with us has been terminated or altered in any way. In such cases, we may
                          still retain personal data relating to you and use or disclose such information
                          for legal and/or business purposes, including for compliance with local laws
                        in the country in which you receive the services.</p>
                        </div>
                      </div>
                    </div>
                    {/* Revising or updating this Privacy Statement */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="nochange-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#nochange" aria-expanded="false" aria-controls="nochange">
                            <span className="headings">How we share and transfer your personal information</span>
                          </a>
                        </h4>
                      </div>
                      <div id="nochange" className="panel-collapse collapse" role="tabpanel" aria-labelledby="nochange-header">
                        <div className="panel-body">
                          <p>We may share and transfer personal information within the Asurion group and with
                          Asurion’s service providers that we collect about you in countries outside
                          of the country in which you receive the services but only in accordance with
                          the Purposes. We will not use or disclose your personal information to non-affiliated
                        third parties except as disclosed in this Policy.</p>
                          <p>Asurion may transfer or license your personal information to: </p>
                          <li>Non-affiliated companies to assist us in providing, maintaining and operating
                          our services or marketing. These companies are required to comply with the
                          principles set out in this Policy and only use such personal information
                        for the purposes for which it was provided to them;</li>
                          <li>A third-party, in the event of a proposed or actual purchase, sale (including
                          a liquidation, realization, foreclosure or repossession), lease, merger,
                          amalgamation or any other type of acquisition, disposal, transfer, conveyance
                          or financing of all or any portion of its business or of any assets or shares
                          of our business or a division thereof in order for you to continue to receive
                          the same or similar products and services from the third-party. In such an
                          event, we will provide notice of any material change to this Policy, or our
                          services, in the manner described in this Policy. In these circumstances,
                          personal information may be shared with the actual or prospective purchasers
                        or assignees, or with the newly acquired business.</li>
                          <p>Asurion may also disclose your personal information to:</p>
                          <li>Comply with the law and/or legal process in which a formal request has been made
                          (e.g. request from an administrative oversight agency, civil suit, subpoena,
                        court order or judicial or administrative proceeding);</li>
                          <li>Defend our rights and/or property or the rights and property of others;</li>
                          <li>Enforce this Policy;</li>
                          <li>Respond to claims that the content(s) of a communication violates the rights
                        of another;</li>
                          <li>Detect, prevent or otherwise address fraud, security, or technical issues.</li>
                        </div>
                      </div>
                    </div>
                    {/* APEC Participation */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy" aria-expanded="false" aria-controls="data-privacy">
                            <span className="headings">How we protect your personal information</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>We use commercially reasonable technical, administrative, and physical controls
                          best practices to protect your personal information including data encryption
                          where appropriate; however, since the internet is not a 100% secure environment,
                          we cannot ensure the security of personal information during its transmission
                          between you and us. Accordingly, you acknowledge that when you transmit such
                          personal information, you do so at your own risk. Nevertheless, we strive
                          to protect the security of your information and are constantly reviewing
                          and enhancing our information security measures. We will notify you of any
                          confirmed security breach of your personal information to the extent required
                          by and in accordance with local laws applying in the country in which you
                        receive the services</p>
                        </div>
                      </div>
                    </div>
                    {/* How long we retain your personal information */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#personal-info" aria-expanded="false" aria-controls="data-privacy">
                            <span className="headings">How long we retain your personal information</span>
                          </a>
                        </h4>
                      </div>
                      <div id="personal-info" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>Asurion will retain your personal information as long as necessary to fulfill
                          the purposes outlined in this Policy and in accordance with local laws applying
                        in the country in which you receive the services.</p>
                        </div>
                      </div>
                    </div>
                    {/* How you are notified if this Policy changes */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-1" aria-expanded="false" aria-controls="data-privacy-1">
                            <span className="headings">How you are notified if this Policy changes</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>We may change this Policy from time to time. If we do, we will post the updated
                          Policy with the last revised date. Your continued use of the product or service
                          means that you consent to the updates. You should periodically read the Policy
                          to understand our privacy practices. If we make material changes to this
                          Policy, then we will publish on Asurion website or email you to seek your
                          affirmative consent if required by local laws applying in the country in
                          which you receive the services.
                      </p>
                        </div>
                      </div>
                    </div>
                    {/* Communicating with you electronically */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-2" aria-expanded="false" aria-controls="data-privacy-2">
                            <span className="headings">Communicating with you electronically</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>By using our services, you agree that we can communicate with you electronically.
                          This includes any required notifications (i.e. legal, technical, regulatory,
                          security, privacy) relating to your use of the services.
                      </p>
                        </div>
                      </div>
                    </div>
                    {/* Commercial messages */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-3" aria-expanded="false" aria-controls="data-privacy-3">
                            <span className="headings">Commercial messages</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>Where required, we will seek your express consent to send such messages. You
                          may withdraw your consent by using the "unsubscribe" link located at the
                          bottom of our messages, contacting us at the address listed above, or by
                        emailing <a href="/" target="_blank" rel="noopener">privacy@asurion.com</a>.
                      </p>
                        </div>
                      </div>
                    </div>
                    {/*Your rights related to correction and access */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-4" aria-expanded="false" aria-controls="data-privacy-4">
                            <span className="headings">Your rights related to correction and access</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>While we take various steps to ensure the accuracy and completeness of your personal
                          information, we rely upon you to provide accurate and complete personal information
                        when interacting with us. </p>
                          <p>Upon your request and as allowed or required by local laws applying in the country
                          in which you receive the services and consistent with our applicable partner
                          agreements, you may request Asurion to take the following actions with your
                          personal information: (i) provide you with a copy, (ii) correct, (iii) update,
                          (iv) delete. To make such a request, or if you have a privacy-related complaint,
                        please contact Asurion via postal mail or email as provided in the <a href="/" target="_blank"> Where
                          to turn with questions </a> section of this Policy. We will respond to your
                        request within a reasonable timeframe.</p>
                          <p>If you have an unresolved privacy or data use concern that we have not addressed
                          satisfactorily, please contact our U.S.-based third-party dispute resolution
                        provider at <a href="/" target="_blank"> https://feedback-form.truste.com/watchdog/request. </a>                                              </p>
                        </div>
                      </div>
                    </div>
                    {/* Cookies and Web Beacons  */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-5" aria-expanded="false" aria-controls="data-privacy-5">
                            <span className="headings">Cookies and Web Beacons</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>When you use our website, we may collect certain information by automated or
                          passive means using a variety of technologies such as cookies (small text
                          files created on a computer or device when its web browser loads a website
                          or web application) or web beacons (a transparent image file used to keep
                          track of one’s navigation and behaviour through the website.
                      </p>
                          <p>We use these technologies across our website to enhance your user experience.
                          These technologies that we place on your device may be used to allow us to
                          personalise your online experience, recognise you as a previous visitor,
                          save your user preferences and maintain your login and password information
                          on secure portions of our website. We also use these technologies to analyse
                          how users use our website and to monitor the performance of our website.
                          For the avoidance of doubt, we do not combine the general information collected
                          through these technologies with other personal data such as to render you
                          identifiable. By using the website, you agree that we can place these technologies
                        on your device. </p>
                          <p>You have the option of adjusting your browser or device settings to restrict
                          or limit the use of these technologies. However, if you choose to do so,
                        the functionality of the website and your user experience may be affected.</p>
                        </div>
                      </div>
                    </div>
                    {/* Children under 18 */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-6" aria-expanded="false" aria-controls="data-privacy-6">
                            <span className="headings">Children under 18</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>The services are not directed to children under 18. Unless allowed under local
                          laws applying in the country in which you receive the services, we do not
                          knowingly collect personal information from children under 18. If you become
                          aware that your child is accessing the service and providing personal information
                          without your consent in a country where local laws do not permit, please
                          contact us by using the information provided below. We will take steps to
                          remove personal information from our storage systems and terminate the account
                          if a child under 18 has accessed the services in a country where not permitted
                          by local laws.
                      </p>
                        </div>
                      </div>
                    </div>
                    {/* APEC participation */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-7" aria-expanded="false" aria-controls="data-privacy-7">
                            <span className="headings">APEC participation</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>The Asurion privacy practices, described in this Policy, comply with the APEC
                          Cross Border Privacy Rules System. The APEC CBPR system provides a framework
                          for organizations to ensure protection of personal information transferred
                          among participating APEC economies. More information about the APEC framework
                        can be found <a href="/" target="_blank"> here.</a></p>
                          <p>To learn more about our APEC CBPR Certification click <a href="/" target="_blank">here.</a>                                              </p>
                        </div>
                      </div>
                    </div>
                    {/* Accountability and governance */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-8" aria-expanded="false" aria-controls="data-privacy-8">
                            <span className="headings">Accountability and governance</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-8" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>Asurion has implemented various measures to monitor compliance with and enforce
                        this Policy. </p>
                        </div>
                      </div>
                    </div>
                    {/* Where to turn with questions */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-9" aria-expanded="false" aria-controls="data-privacy-9">
                            <span className="headings">Where to turn with questions</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-9" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>If you have any questions about this Policy, about our product or service, or
                          want to request access to, correction, or deletion of your personal information,
                        or have a privacy-related complaint or query, please contact:</p>
                          <p>(a) the Privacy Officer or privacy contact for the country in which you are based
                        as detailed in the<strong> Country</strong> section below; or </p>
                          <p>(b) (if the country in which you are based is not listed in Country section below)
                        us by email at <a href="/" target="_blank"> privacy@asurion.com.</a> </p>
                        </div>
                      </div>
                    </div>
                    {/* Asurion */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-10" aria-expanded="false" aria-controls="data-privacy-10">
                            <span className="headings">Asurion</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-10" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>For the purposes of this Policy, Asurion group means the Asurion entity offering
                          or providing services to you and its affiliates including any entity listed
                        in the Country section below.</p>
                        </div>
                      </div>
                    </div>
                    {/* Country */}
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="data-privacy-header">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-11" aria-expanded="false" aria-controls="data-privacy-11">
                            <span className="headings">Country</span>
                          </a>
                        </h4>
                      </div>
                      <div id="data-privacy-11" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                        <div className="panel-body">
                          <p>This section sets out any country specific variations to this Policy, and any
                        further details, as required by the laws of that country.</p>
                          <h4>(a) SINGAPORE </h4>
                          <table className="terms-conditions-table">
                            <tbody>
                              <tr>
                                <td>Privacy Officer:</td>
                                <td>Director, Legal SEA at <a href="/" target="_blank"> privacy@asurion.com </a>or
                              on <br />+65 6403 9280 or 7 Temasek Boulevard, <br />Suntec Tower
                              One #09-01, Singapore 038987
                            </td>
                              </tr>
                              <tr>
                                <td>Website:</td>
                                <td><a href="/" target="_blank"> www.asurion.com.sg </a> </td>
                              </tr>
                              <tr>
                                <td>Asurion entity:</td>
                                <td>NEW Asurion Singapore Pte Ltd </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <h4>(b) MALAYSIA </h4>
                          <table className="terms-conditions-table">
                            <tbody>
                              <tr>
                                <td>Privacy contact:</td>
                                <td>Director, Legal SEA at <a href="/" target="_blank"> privacy@asurion.com </a>
                              or on<br /> +65 6403 9280
                            </td>
                              </tr>
                              <tr>
                                <td>Website:</td>
                                <td><a href="/" target="_blank"> www.asurion.com.my </a> </td>
                              </tr>
                              <tr>
                                <td>Asurion entity:</td>
                                <td>Asurion Technology Malaysia Sdn Bhd </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <h4>(c) THAILAND </h4>
                          <table className="terms-conditions-table">
                            <tbody>
                              <tr>
                                <td>Privacy contact:</td>
                                <td>Director, Legal SEA at <a href="/" target="_blank"> privacy@asurion.com </a>
                              or on <br /> +65 6403 9280
                            </td>
                              </tr>
                              <tr>
                                <td>Website:</td>
                                <td><a href="/" target="_blank"> www.asurion.com.th </a> </td>
                              </tr>
                              <tr>
                                <td>Asurion entity:</td>
                                <td>Asurion (Thailand) Limited </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-2">
            <div className="text">&nbsp;</div>
          </div>
        </div>
      </div>
    </Fragment>

  )
}