import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';

import enrolledAddressIcn from "../../images/ais-images/icon-delivery-address.png";
import CONSTANTS from "../../utils/Constants";

import { MIXPANEL } from '../../mixpanel/Mixpanel';
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import EVENTLIST from '../../mixpanel/Eventlist';

class ChangeDeliveryAddressEnquiry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            noButtonClassName: "ais_device-select-button ais_credit-cardd",
            cancelButtonClassName: "ais_device-select-button ais_credit-cardd",
            content: "",
            content1:""
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleClick() {
        MIXPANEL.track(EVENTLIST.CHANGE_DELIVERY_ADDRESS);
        this.setState({
            disable: true,
            noButtonClassName: "ais_device-select-button ais_credit-cardd ais_btnSel"
        });

        const {Address1,Address2,Address3,City,StateProvinceCode} = this.props.shippingAddress;
        this.props.updateJourneyList(this.props.dataprops.source, "Change");
    }
    
    handleCancel() {
        this.setState({
            disable: true,
            cancelButtonClassName: "ais_device-select-button ais_credit-cardd ais_btnSel",
        });
        this.props.updateJourneyList(CONSTANTS.WEB_GRID_VIEW_OPTIONS, "Cancel");
    }

    componentWillMount() {
        if (this.props.source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION) {
            this.setState({
                content: CONSTANTS.PIN_CODE + this.props.selectedPostalCode
            });
        } else if (this.props.source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION) {
            this.setState({
                content: this.props.emailAddress
            });
        } else if (this.props.source === CONSTANTS.SOURCE_USER_INPUT.ENROLLED_MOB_NO_CONFIRMATION) {
            this.setState({
                content: this.props.phoneNumber
            });
        }
    }

    render() {
        console.log(this.props.shippingAddress);
        return (
            <div className="ais_current-form-device-div" style={{ width: '295px' }}>
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label-cus">{this.props.data.HEADER}</label>
                    <div className="ais_container-div">
                        <div>  <img alt="enroll-address-icn" className="ais_confirm-delivery-icn" src={enrolledAddressIcn} /> </div>
                        <div className="container-fluid">
                            <div className="row ais_row-adress">
                                <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                                    <label style={{ display: 'initial'}} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS1 }}></label>
                                </div>
                                <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                                    <label className="ais_address_align">{this.props.shippingAddress.Address1}
                                    </label>
                                </div>
                            </div>
                            <div className="row ais_row-adress">
                                <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                                    {this.props.shippingAddress.Address2 ? <label style={{ display: 'initial'}} classname="ais_center-align" dangerouslySetInnerHTML={{ __html:  MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS2 }}></label> : ''}
                                </div>
                                <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                                    {this.props.shippingAddress.Address2 ? <label className="ais_address_align">{this.props.shippingAddress.Address2}</label> : ''}
                                </div>
                            </div>
                            <div className="row ais_row-adress">
                                <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                                    {this.props.shippingAddress.Address3 ? <label style={{ display: 'initial'}} classname="ais_center-align" dangerouslySetInnerHTML={{ __html:MESSAGE_CONSTANTS.ADDRESS_LABELS.ADDRESS3 }}></label> : ''}
                                </div>
                                <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                                    {this.props.shippingAddress.Address3 ?
                                        <label className="ais_address_align">{this.props.shippingAddress.Address3}</label> : ''}
                                </div>
                            </div>
                            <div className="row ais_row-adress">
                                <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                                    <label style={{ display: 'initial'}} classname="ais_center-align" dangerouslySetInnerHTML={{ __html:MESSAGE_CONSTANTS.ADDRESS_LABELS.POSTALCODE }}></label>
                                </div>
                                <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                                    <label className="ais_address_align">{this.props.selectedPostalCode}</label>
                                </div>
                            </div>
                            <div className="row ais_row-adress">
                                <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                                    <label style={{ display: 'initial'}} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.CITY }}></label>
                                </div>
                                <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                                    <label className="ais_address_align">{this.props.shippingAddress.City}</label></div>
                            </div>
                            <div className="row ais_row-adress">
                                <div className="col-md-6 col-xs-6 ais_align_rgt ais_address_padding">
                                    <label style={{ display: 'initial'}} classname="ais_center-align" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.ADDRESS_LABELS.STATE }}></label>
                                </div>
                                <div className="col-md-6 col-xs-6 ais_align_left ais_address_padding">
                                    <label className="ais_address_align">{this.props.shippingAddress.StateProvinceCode}</label></div>
                            </div>
                        </div>
                    </div>
                    <div className="ais_outer-align-button">
                    <button disabled={this.state.disable} className={this.state.cancelButtonClassName} onClick={(e) => this.handleCancel(e)} >{this.props.data.BUTTONS[3]}</button>    
                    <div className="ais_device-divider ais_button" />
                    <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={(e) => this.handleClick(e)} >{this.props.data.BUTTONS[2]}</button>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = state => {
    let { clientData: { CacheId } = {}, customerNewContact, determineIncidentResponse, enrolledPostalCode, enrolledAddress } = state.customerData;
    return {
        cacheId: CacheId,
        newContactNo: customerNewContact,
        selectedPostalCode: determineIncidentResponse.PostalCode || enrolledPostalCode,
        shippingAddress: determineIncidentResponse.ShippingAddress || enrolledAddress
    };
};


export default connect(mapStateToProps, null)(ChangeDeliveryAddressEnquiry);
