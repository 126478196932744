import React, { Component, Fragment } from 'react';
import logo from '../../images/ais-images/ais-mobile-care-logo@3x.png';
import './HomePage.css';
import Icons from '../../components/Icons/Icons';
import { Link } from 'react-router-dom';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SAVE_DEFECT_OPTION } from '../../actions/Dashboard/actionTypes';
import { savePayload } from '../../actions/Dashboard/doFindAgreements';
import ReCAPTCHA from 'react-google-recaptcha';
import { getLang } from '../../utils/utils';
import ic_poor_active_big from '../../images/mobile/ic-poor_active_big.png';
import config from '../../config/config';

const {
  LANDING_PAGE,
  REQUEST_OPTIONS,
  LANDING_PAGE: { FOOTER_LINKS, HOW_WORKS, FOOTER_LINKS_NEW, HOW_WORKS_NEW },
  PaymentDisclaimer
} = MESSAGE_CONSTANTS;
const LANGS = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Thai',
    value: 'th',
  },
];

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.grecaptchaObject = window.grecaptcha;
    this.state = {
      token: '',
    };
  }

  handleOptionSelection = async (name, text) => {
    // const isQA = config?.UIAPIServer?.env?.toLowerCase() === "qa";
    const isQA = ['qa', 'uat'].includes(config?.UIAPIServer?.env?.toLowerCase());
    if (isQA) {
      //no recaptcha in QA env for QA automation tests
      this.props.savePayload(SAVE_DEFECT_OPTION, name);
      this.props.handleOptionSelection(text);
      this.setState({ token: '', selectedOption: name });
    } else {
      try {
        const isDev = process.env.NODE_ENV === 'development';
        const token = await this.recaptchaRef.current.executeAsync();
        if (!isDev && !token) {
          this.grecaptchaObject.execute();
        } else {
          this.props.savePayload(SAVE_DEFECT_OPTION, name);
          this.props.handleOptionSelection(text);
          this.setState({ token, selectedOption: name });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  verifyCaptcha = (token) => {
    console.log(`captcha token is ${token}`);
  };

  render() {
    return (
      <Fragment>
        <header className="left-space">
          <Link to="/" className="logo-lg">
            <img src={logo} alt="logo" className="img-responsive" />
          </Link>
          <ul className="d-flex lang-list">
            {LANGS.map((e) => (
              <li key={e.label}>
                <a className="" href={`?lang=${e.value}`}>
                  {e.label}{' '}
                </a>
              </li>
            ))}
          </ul>
        </header>
        <main className="home-page">
          <section className="banner-img left-space">
            <div className="content-wrap">
              <Link to="/" className="logo-sm">
                <img src={logo} alt="logo" className="img-responsive" />
              </Link>
              <h1 className="welcome-heading">{LANDING_PAGE.WELCOME_HEADING}</h1>
              <p className="welcome-subheading">{LANDING_PAGE.WELCOME_SUBHEADING}</p>
              <ul className="d-flex request-list">
                {REQUEST_OPTIONS.buttons.map((req) => (
                  <li key={req.name}>
                    <div
                      className={`request-card ${
                        this.state.selectedOption === req.name ? 'active' : ''
                      }`}
                      onClick={() => {
                        this.handleOptionSelection(req.name, req.text);
                      }}
                    >
                      <i className="request-icon">{Icons[req.icon]}</i>
                      <p>{req.subText}</p>
                      <span className="btn btn-darkgreen">{req.text}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section className="howwork-section">
            <h2 className="howwork-heading text-center">{LANDING_PAGE.HOW_WORKS_HEADING}</h2>
            {/*<p className="howwork-subheading text-center">{LANDING_PAGE.HOW_WORKS_SUBHEADING}</p>*/}
            <ul className="d-flex howwork-list">
              {/*{HOW_WORKS_NEW.map(hw => <li className="d-flex" key={hw.HEADING}>
                <div className="howwork-content" style={{paddingTop: "0"}}>
                  <h5 className="req-heading">{hw.HEADING}
                  {hw.hasSeal && <span className="new-seal">{hw.sealText}</span>}</h5>
                  <p className="req-subheading">{hw.SUBHEADING}</p>
                </div>
              </li>)}
              {HOW_WORKS.map(hw => <li className="d-flex" key={hw.HEADING}>
                <div>{Icons[hw.icon]}</div>
                <div className="howwork-content">
                  <h5 className="req-heading">{hw.HEADING}</h5>
                  <p className="req-subheading">{hw.SUBHEADING}</p>
                </div>
              </li>)}*/}
              <li>
                <ul>
                  {HOW_WORKS_NEW.map((hw) => (
                    <li className="d-flex" key={hw.HEADING}>
                      <div className="howwork-content" style={{ paddingTop: '0' }}>
                        <h5 className="req-heading">
                          {hw.HEADING}
                          {hw.hasSeal && <span className="new-seal">{hw.sealText}</span>}
                        </h5>
                        {hw.SUBHEADING && <p className="req-subheading">{hw.SUBHEADING}</p>}
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <ul>
                  {HOW_WORKS.map((hw) => (
                    <li className="d-flex" key={hw.HEADING}>
                      <div style={{ paddingTop: 0 }}>{Icons[hw.icon]}</div>
                      <div className="howwork-content">
                        <h5 className="req-heading">{hw.HEADING}</h5>
                        <p className="req-subheading">{hw.SUBHEADING}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </section>
        </main>
        <footer className="">
          {/*<ul className="d-flex footer-link-list">
            {FOOTER_LINKS.map(link => <li key={link.TEXT}>
              <Link to={link.HREF} className="footer-link" target="_blank" title={link.TEXT}>{link.TEXT}</Link>
            </li>)}
            </ul>*/}
          {/*<ul className="new-footer-link-list">
            {FOOTER_LINKS_NEW.map(link => <li key={link.TEXT} className={`${link.SPAN === 2 ? "new-footer-li-span2" : ""}`}>
              <Link to={link.HREF} className={"new-footer-link"} target="_blank" title={link.TEXT}>{link.TEXT}</Link>
            </li>)}
          </ul>*/}
          <div className="new-footer-link-list">
            <div>
              <ul>
                {FOOTER_LINKS_NEW.leftCol.map((link) => (
                  <li key={link.TEXT} className={`${link.SPAN === 2 ? 'new-footer-li-span2' : ''}`}>
                    <Link
                      to={link.HREF}
                      className={'new-footer-link'}
                      target="_blank"
                      title={link.TEXT}
                    >
                      {link.TEXT}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <ul>
                {FOOTER_LINKS_NEW.rightCol.map((link) => (
                  <li key={link.TEXT} className={`${link.SPAN === 2 ? 'new-footer-li-span2' : ''}`}>
                    <Link
                      to={link.HREF}
                      className={'new-footer-link'}
                      target="_blank"
                      title={link.TEXT}
                    >
                      {link.TEXT}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <p style={{ textAlign: "center", margin: "20px 0", color: "white", fontWeight: "bold"}}>{PaymentDisclaimer}</p>
        </footer>
        <div className="hidden">
          <ReCAPTCHA
            ref={this.recaptchaRef}
            sitekey={'6LcS60AaAAAAACbE4FyfRWvVTlXiTvA95eIYk7_A'}
            onChange={this.verifyCaptcha}
            size="invisible"
            grecaptcha={this.grecaptchaObject}
            hl={getLang()}
          />
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      savePayload,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(HomePage);
