import React, { Fragment, Component } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import config from "../../../config/config";
import {
  savePayload,
  setJourneyCmpltd,
  cancelServiceRequestInit
} from "../../../actions/Dashboard/doFindAgreements";
import { CANCEL_BUTTON_CLICKED } from "../../../actions/Dashboard/actionTypes";
import CONSTANTS from "../../../utils/Constants";
import { isDeviceOrWeb } from "../../../utils/utils";
import MESSAGE_CONSTANTS from "../../../config/journey-messages";
import EndChatRequest from "../../common/Chat/EndChatRequest";

import closeButton from "../../../images/ais-images/button-close.png";
import logo from "../../../images/ais-images/ais-mobile-care-logo.png";
import { MIXPANEL } from "./../../../mixpanel/Mixpanel";
import EVENTLIST from "./../../../mixpanel/Eventlist";

class Header extends Component {
  state = {
    showClosePrompt: false,
    showCancelPrompt: false,
    ChatEnded: false,
    ChatRequest: "",
    timeHeaderClass: "ais_app-header-time sr"
  };

  showModal = () => {
    this.props.setJourneyCmpltd(
      true,
      CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED
    );
    this.setState({ showClosePrompt: true });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.processPaymentResults) {
    } else if (nextProps.customerCaseNumber) {
      this.setState({
        timeHeaderClass: "ais_app-header-time"
      });
    } else {
      this.setState({
        timeHeaderClass: "ais_app-header-time sr"
      });
    }
  }

  endOnlineJourney = () => {
    let chatRequest = null;
    MIXPANEL.track(EVENTLIST.JOURNEY_COMPLETED, {
      "End Reason": "User Closed"
    });

    if (this.props.conversation) {
      chatRequest = {
        input: {
          requestId: this.props.requestId,
          requestStatus: CONSTANTS.CHAT_REQUEST_STATUS.USER_DISCONNECTED,
          comments: "User disconnected during assistance chat"
        }
      };
      this.setState({ ChatEnded: true, ChatRequest: chatRequest });
    }
    else {
      window.location.replace(config.ApplicationURL);
    }
  };

  cancelSR = () => {
    this.setState({
      showClosePrompt: false,
      showCancelPrompt: false,
    });
    this.props.savePayload(CANCEL_BUTTON_CLICKED, true);
    this.props.cancelServiceRequestInit(this.props.cacheId, {}, "Cancel_SR");
    this.props.desktopConversationEnd();
  };

  hideModal = () => {
    this.setState({ showClosePrompt: false, showCancelPrompt: false });
  };

  render() {
    // console.log("Header props", this.props)
    let defaultHeaderText = MESSAGE_CONSTANTS.WEB_HEADERS.WELCOME_TO_MOBILESWOP;
    let endChatconfirm = "";
    let updateAppsync = "";
    if (this.props.showHeader) {
      defaultHeaderText = MESSAGE_CONSTANTS.WEB_HEADERS.FEEDBACK;
    }

    if (this.props.customerCaseNumber) {
    }
    if (this.state.ChatEnded) {
      updateAppsync = (
        <EndChatRequest
          selfTerminatedChat={this.state.ChatEnded}
          setUserClosedJourneyDuringAssistance={
            this.props.setUserClosedJourneyDuringAssistance
          }
          chatRequest={this.state.ChatRequest}
        />
      );
    }
    if (this.state.showClosePrompt) {
      endChatconfirm = (
        <SweetAlert
          showCancel
          cancelBtnText={MESSAGE_CONSTANTS.CANCEL_REQUEST.BUTTONS[0]}
          confirmBtnText={MESSAGE_CONSTANTS.CANCEL_REQUEST.BUTTONS[1]}
          confirmBtnCssClass="ais_align-endChat-button-selected"
          cancelBtnCssClass="ais_align-endChat-button"
          title={
            <span className="ais_cancel-request">
              {MESSAGE_CONSTANTS.CANCEL_REQUEST.HEADER}
            </span>
          }
          onConfirm={this.endOnlineJourney}
          onCancel={this.hideModal}
        >
          <span className="ais_other-details-desc">
            {MESSAGE_CONSTANTS.CANCEL_REQUEST.CONTENT}
          </span>
        </SweetAlert>
      );
    }

    if (this.state.showCancelPrompt) {
      endChatconfirm = (
        <SweetAlert
          showCancel
          cancelBtnText={MESSAGE_CONSTANTS.CANCEL_CURRENT_SR.BUTTONS[0]}
          confirmBtnText={MESSAGE_CONSTANTS.CANCEL_CURRENT_SR.BUTTONS[1]}
          confirmBtnCssClass="ais_align-endChat-button-selected"
          cancelBtnCssClass="ais_align-endChat-button"
          title={
            <span className="ais_cancel-request">
              {MESSAGE_CONSTANTS.CANCEL_CURRENT_SR.HEADER}
            </span>
          }
          onConfirm={this.cancelSR}
          onCancel={this.hideModal}
        >
          <span className="ais_other-details-desc">
            {MESSAGE_CONSTANTS.CANCEL_CURRENT_SR.CONTENT}
          </span>
        </SweetAlert>
      );
    }
    return (
      <Fragment>
        <div id="appHeader" className="ais_app-header">

          <div className="d-flex ais-wrap">
            <span className="ais_logo">
              <img src={logo} />
            </span>
            <div className="ais_app-header-text-cus">
              {defaultHeaderText}
            </div>
          </div>
          <div className="color-line hidden-md hidden-lg"></div>

          <div className="bottom-wrap">
            <div className="ais_app_header_div d-flex">
              <div className={this.state.timeHeaderClass}>
                {moment(new Date()).format(CONSTANTS.HEADER_TIME_FORMAT)}
              </div>
              <span className="ais_close-button-wrapper">
                <img
                  onClick={this.showModal}
                  className="ais_close-btn"
                  alt="close-interaction"
                  src={closeButton}
                />
              </span>
            </div>

            {this.props.customerCaseNumber && <div
              className="ais_app-header-sr"
            >
              {MESSAGE_CONSTANTS.CASE_NO} {this.props.customerCaseNumber}
              <input type="hidden" id="txt_sea_ais_caseno_id" value={this.props.customerCaseNumber} />
            </div>}
          </div>


        </div>
        <div className="color-line hidden-xs hidden-sm"></div>

        {endChatconfirm}
        {updateAppsync}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    customerCase: { CustomerCaseNumber },
    processPaymentResults,
    resumeServiceRequestResult: {
      CustomerCaseNumber: resumeCustomerCaseNumber
    },
    conversation,
    requestId = "",
    clientData,
    cancelSR
  } = state.customerData;
  return {
    customerCaseNumber: CustomerCaseNumber || resumeCustomerCaseNumber,
    conversation,
    requestId: requestId,
    cacheId: clientData.CacheId,
    cancelSR,
    processPaymentResults
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePayload,
      setJourneyCmpltd,
      cancelServiceRequestInit
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
