import * as React from 'react';
import { Button, Heading, HStack, Text, VStack, useDimensions } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const { useRef } = React;

export const FilterOptions = ({ disabled = false, buttons = [] }) => {
  const { t } = useTranslation('', { keyPrefix: 'SmartStock.forms.filterOptions' });
  const header = useRef(document.getElementById('appHeader'));
  const headerDim = useDimensions(header, true);
  const ceiling = (headerDim?.contentBox.height ?? 0) + 8;

  return (
    <VStack
      background={'white'}
      borderRadius={'10px'}
      border={'1px'}
      borderColor={'gray.150'}
      p={'20px'}
      w={'full'}
      maxW={'330px'}
      spacing={0}
      gap={'20px'}
      position={!disabled ? 'sticky' : 'static'}
      top={!disabled ? ceiling : 'auto'}
    >
      <VStack gap={'10px'} spacing={0} alignItems={'flex-start'}>
        <Heading as="h3" fontSize={'lg'} color={'gray.650'}>
          {t('header')}
        </Heading>
        <Text color={'gray.650'}>{t('description')}</Text>
      </VStack>
      <HStack w="full" spacing={0} gap={'20px'}>
        {buttons.map(({ label, props }, i) => (
          <Button flex={1} {...props} key={`controls-${label}-${i}`} isDisabled={disabled}>
            {label}
          </Button>
        ))}
      </HStack>
    </VStack>
  );
};

export default FilterOptions;
