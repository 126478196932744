import React, { Fragment } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CONSTANTS from "../../utils/Constants";
import { savePayload } from '../../actions/Dashboard/doFindAgreements';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { RESUME_CANCELED } from '../../actions/Dashboard/actionTypes';

class DisplayScript extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            highlightedClass: "",
            disable: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        this.setState({
            highlightedClass: "ais_device-select-button-highlighted",
            disable: true
        });
     
        this.props.savePayload(RESUME_CANCELED, true);
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESUME_ENQUIRY_OPTIONS, null)

    }
    
    render() {
        return (
            <Fragment>
               <div className="ais_current-form-device-div ais_display-script ais_proceed">
                    <div className="ais_display-script-container">
                        <div className="ais_current-device-spec ais_upload">
                        {MESSAGE_CONSTANTS.NO_PAYMENT_REVIEW}
                        </div>
                    </div>
                    <div className="ais_outer-align-button">
                        <button disabled={this.state.disable} className={"ais_device-select-button ais_proceed " + this.state.highlightedClass} onClick={this.handleClick} >{MESSAGE_CONSTANTS.YES_PROCEED}</button>
                    </div>
                </div>
                
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators ({
        savePayload
    }, dispatch)
}

export default connect(null, mapDispatchToProps)(DisplayScript);

