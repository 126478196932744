import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';

import { uploadDocument, savePayload } from '../../actions/Dashboard/doFindAgreements';
import { UPLOADED_FILE_NAMES }  from '../../actions/Dashboard/actionTypes';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import CONSTANTS from "../../utils/Constants";
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import UploadFileLoader from "../UploadFileLoader/UploadFileLoader";

import imageIcon from "../../images/ais-images/icon-form-uploaded.png";
import rightIcon from "../../images/ais-images/check.png";
import crossIcon from "../../images/ais-images/icon-cross.png";
import crossIconGrey from "../../images/mobile/ic-upload_delete_default.png";

class SelectUploadDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            clearBtnClass: 'ais_device-select-button-attach term',
            attachBtnClass: 'ais_device-select-button-attach term',
            files: [],
            fileSelected: false,
            progressValue: 0,
            disableButton: true,
            icon: crossIconGrey,
            showClosePrompt: false
        }
        this.removeAttachedFile = this.removeAttachedFile.bind(this);
        this._renderFiles = this._renderFiles.bind(this);
    }

    showModal = () => {
        this.setState({ showClosePrompt: true });
    };

    hideModal = () => {
    if(this.state.files.length){
      this.setState({ showClosePrompt: false, disableButton:false});
    }else{
      this.setState({ showClosePrompt: false, disableButton:true});
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.uploadProgressValue === 100)
            this.setState({
                icon: rightIcon
            })
    }

    handleCancel = (e) => {
        this.setState({
            disable: true,
            clearBtnClass: "ais_device-select-button-attach ais_term ais_btnSel",
            disableButton: true
        });
        this.props.updateJourneyList(CONSTANTS.CANCEL_UPLOAD, "Cancel");
    }


    handleAttachClick(e) {
        MIXPANEL.track(EVENTLIST.CONFIRMATION_FORM_UPLOADED);
        this.setState({
            disable: true,
            attachBtnClass: "ais_device-select-button-attach ais_term ais_btnSel",
            disableButton: true,
            icon: crossIcon
        });

        var fileList = [];

        this.state.files.map((file, i) => {
            var reader = new FileReader();
            var fileName = file.name.replace(/\s/g, "");
            reader.onload = (upload) => {
                fileList.push({
                    fileId: "file-" + i,
                    name: fileName,
                    fileType: file.type,
                    preview: file.preview,
                    fileSize: file.size,
                    data: upload.target.result
                })
                // console.log("selected Files", fileList);
                if (fileList.length === this.state.files.length) {
                    let fileNames = fileList.map(f => f.name);
                    this.props.savePayload(UPLOADED_FILE_NAMES, fileNames );
                    this.props.uploadDocument(this.props.cacheId, fileList)
                }
            }
            reader.readAsDataURL(file);
        })

    }

    onDrop(files) {
        if (!files.length) {
            // alert("Max size exceed.")
            this.showModal();
        }
        this.setState(previousState => ({
            files: [...previousState.files, ...files],
            fileSelected: true,
            disableButton: false
        }));

        if (this.state.files.length > 3) {
            var array = this.state.files.slice(0, 3);
            this.setState({
                files: [...array],
                disableButton: false
            })
        }
    }


    _renderFiles = (file, i) => {
        return <div key={i} className="ais_inline-element">
            <img alt="fileIcon" className="ais_file-icon" src={imageIcon} />
            <div className="ais_device-divider" />
            <div className="ais_inline-element-div">
                <label className="ais_current-device-spec"> {file.name} </label>
                <UploadFileLoader progressValue={this.props.uploadProgressValue} />
            </div>
            <div className="ais_device-divider" />
            <img alt="cancel" className="ais_cancel-icon" src={this.state.icon} onClick={(e) => this.removeAttachedFile(file)} />
        </div>
    }

    browseFiles = (e) => {
        e.preventDefault();
    }


    removeAttachedFile = (file) => {
        if (this.state.disable) {
            return;
        } else {
            var removeFile = [...this.state.files];
            var index = removeFile.indexOf(file)
            removeFile.splice(index, 1);
            this.setState({
                files: removeFile,
                disableButton: removeFile.length ? false : true
            });
        }
    }

    render() {

        let maxSizeExceed = "";
        if (this.state.showClosePrompt) {
            maxSizeExceed = <SweetAlert
                confirmBtnText={MESSAGE_CONSTANTS.MAX_SIZE_ALERT.BUTTON}
                confirmBtnCssClass="ais_align-endChat-button-selected"
                cancelBtnCssClass="ais_align-endChat-button"
                title={<span className="ais_cancel-request">{MESSAGE_CONSTANTS.MAX_SIZE_ALERT.ALERT_HEADER}</span>}
                onConfirm={this.hideModal}
            >
                <span className="ais_other-details-desc">{MESSAGE_CONSTANTS.MAX_SIZE_ALERT.ALERT_MESSAGE}</span>
            </SweetAlert>;
        }

        return (
            <div className="ais_device-outer ais_web ais_doc">
                <div className="ais_container-div-address ais_web ais_doc">
                    <label className="ais_current-mobile-device-label">{MESSAGE_CONSTANTS.UPLOAD_DOCUMENTS.HEADER}</label>

                    <section>
                        <Dropzone className="ais_dashed-placeholder"
                            accept=".jpg, .jpeg, .pdf, .png"
                            // accept="application/pdf,image/jpeg, image/png,image/jpg"
                            maxSize={1000000} onClick={(e) => this.browseFiles(e)} onDrop={this.onDrop.bind(this)}
                            disableClick={this.state.disable} disabled={this.state.disable}>
                            <div className="drop-div">
                                {this.state.disable ? <label className="ais_current-device-spec ais_upload" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.UPLOAD_DOCUMENTS.ATTACHED_CONTENT }}></label>
                                    : <label className="ais_current-device-spec ais_upload" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.UPLOAD_DOCUMENTS.CONTENT }}></label>}
                                <label className="ais_primary-header" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.UPLOAD_DOCUMENTS.DISCLAIMER }}></label>
                                <div><label className="ais_primary-header">{MESSAGE_CONSTANTS.UPLOAD_DOCUMENTS.ALLOWED_EXT}</label></div>
                            </div>
                        </Dropzone>
                    </section>

                    {this.state.fileSelected ? <div>
                        {this.state.files && this.state.files.map((e, i) => this._renderFiles(e, i))}
                    </div> : ""}
                    <div className="ais_outer-align-button">
                        {/* <button disabled={this.state.disable} className={this.state.clearBtnClass} onClick={(e) => this.handleCancel(e)} >{MESSAGE_CONSTANTS.UPLOAD_DOCUMENTS.BUTTONS[0]}</button>
                        <div className="device-divider button" /> */}
                        <button disabled={this.state.disableButton} className={this.state.attachBtnClass} onClick={(e) => this.handleAttachClick(e)} >{MESSAGE_CONSTANTS.UPLOAD_DOCUMENTS.BUTTONS[1]}</button>
                    </div>
                </div>
                {maxSizeExceed}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        uploadDocument,
        savePayload
    }, dispatch)
};


const mapStateToProps = state => {
    return {
        cacheId: state.customerData.clientData.CacheId,
        uploadProgressValue: state.customerData.uploadProgressValue
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SelectUploadDocument);
