import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _compact from 'lodash/compact';

import {
    getStandardiseAddress,
    savePayload,
    setRetryAddressAttempt,
    submitEnquiryDetails,
    getProvinces
} from '../../actions/Dashboard/doFindAgreements';

import {
    SAVE_CHANGED_ADDRESS,
    SAVE_ENQUIRY_REASON,
    SAVE_CHANGED_ADDRESS_STRING
} from '../../actions/Dashboard/actionTypes';

import MESSAGE_CONSTANTS from '../../config/journey-messages';
import CONSTANTS from '../../utils/Constants';
import { isDeviceOrWeb, validateAddressRegex } from '../../utils/utils';

import closeIcon from '../../images/ais-images/icon-cross.png';
import wbko_chnage_delivery_address from '../../images/ais-images/wbko-change-address.png'
import { shippingmethodsV2Init } from "./../../actions/Dashboard/shippingMethodsV2";

class ChangeDeliveryAddress extends Component {

    constructor(props) {

        super(props);
        this.state = {
            disable: true,
            floor: '',
            block: '',
            street: '',
            zipcode: '',
            clearBtnClass: 'ais_device-select-button ais_credit-cardd',
            submitBtnClass: 'ais_device-select-button ais_credit-cardd',
            floorTextboxClass: 'ais_deliver-address-input ais_emi',
            blockTextboxClass: 'ais_deliver-address-input ais_emi',
            streetTextboxClass: 'ais_deliver-address-input ais_emi',
            zipcodeTextboxClass: 'ais_deliver-address-input ais_emi',
            disableClear: false,
            disableCancel: false,
            textboxDisable: false,
            disableSelect: false,
            disableSubmit: false,
            showError: false,
            city: '',
            cities: [],
            states: [],
            State: '',
            formErrors: { Address1: '', Postalcode: '' },
            hideModalPopUp: false,
            disablePostalCode: true
        };
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.setReason = this.setReason.bind(this);

        this.handleCitySelected = this.handleCitySelected.bind(this);
        this.handleStateSelected = this.handleStateSelected.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

    }

    isFormValid() {
        let { floor, block, street, zipcode } = this.state;

        if (floor !== "" && block !== "" && zipcode !== "") {
            this.setState({
                disable: false
            })
        }

    }

    validateForm() {
        let { floor, block, street, zipcode } = this.state;
        if (floor !== "" && block !== "" && zipcode !== "") {
            return true;
        } else {
            return false;
        }
    }

    handleSubmitClick = async (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            let addressObj = {
                Address1: this.state.floor.trim(),
                Address2: this.state.block.trim(),
                Address3: this.state.street.trim(),
                PostalCode: this.state.zipcode.trim(),
                City: this.state.city.trim(),
                StateProvinceCode: this.state.State.trim(),
                Country: this.props.data.DEFAULT_CITY || 'Thailand',
                CountryCode: 'TH'
            }
            this.setState({
                submitBtnClass: 'ais_device-select-button ais_credit-cardd ais_btnSel',
                disable: true,
                disableClear: true,
                clearBtnClass: 'ais_device-select-button ais_credit-cardd',
                textboxDisable: true,
                disableCancel: true,
                disableSelect: true,
                disableSubmit: true,
                hideModalPopUp: true,
                showError: false
            });
            this.props.savePayload(SAVE_CHANGED_ADDRESS, addressObj);
            const { cacheId, warehouseId, addressRetryAttempt, selectedEnquiryOption, isWbkoSelected, backOrderCase } = this.props;
            const { floor, block, street, zipcode, reasonText, city, State, states } = this.state;
            let selectedState = states.length ? states[0] : "";
            if (selectedEnquiryOption) {
                var address = [floor.trim(), block.trim(), street.trim(), zipcode.trim(), city.trim(), selectedState.trim(), this.props.data.DEFAULT_CITY];
                let description = MESSAGE_CONSTANTS.CHANGE_DELIVERY_ADDRESS_ENQUIRY + address.join(" ");
                let changeAddresskeepScheduleFlag = true;

                this.props.savePayload(SAVE_CHANGED_ADDRESS_STRING, description);
                this.props.savePayload(SAVE_ENQUIRY_REASON, this.state.reasonText);
                !isWbkoSelected && this.props.updateJourneyList(null, `${floor} ${block} ${street} ${city} ${selectedState} ${zipcode}`);
                await this.props.getStandardiseAddress(cacheId, this.state.zipcode, false, warehouseId, addressObj, addressRetryAttempt, changeAddresskeepScheduleFlag);
                await this.props.shippingmethodsV2Init({ skipScheduleConfirmation: true, nochnageForm: true })

                isWbkoSelected && !backOrderCase && this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION, `${floor} ${block} ${street} ${city} ${selectedState} ${zipcode}`);
                (!isWbkoSelected || (backOrderCase && isWbkoSelected)) && this.props.submitEnquiryDetails(description, selectedEnquiryOption, reasonText, CONSTANTS.ENQUIRY_SUB_REASON.CHANGE_DELIVERY_INFO);
            }
            else {
                // this.props.savePayload(SAVE_CHANGED_ADDRESS, addressObj);
                this.props.getStandardiseAddress(cacheId, this.state.zipcode, false, warehouseId, addressObj, addressRetryAttempt);
                if (this.props.addressRetryAttempt) {
                    this.props.setRetryAddressAttempt(false);
                }
                //this.props.updateJourneyList(this.props.source, getMaskedstr(this.state.zipcode));
                this.props.updateJourneyList(null, `${floor} ${block} ${street} ${city} ${selectedState} ${zipcode}`);
            }
        } else {
            this.setState({
                showError: true
            });
        }
    }

    handleClearClick(e) {
        e.preventDefault();
        this.setState({
            floor: '',
            block: '',
            street: '',
            zipcode: '',
            city: '',
            State: '',
            cities: [],
            states: [],
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cityStatesList.length === 0 && this.state.zipcode.length > 0) {
            this.setState({
                disablePostalCode: false
            })
        } else {
            this.setState({
                disablePostalCode: true
            })
        }
        if (this.props.cityStatesList !== nextProps.cityStatesList) {
            if (nextProps.cityStatesList.length) {
                let cities = nextProps.cityStatesList.map(o => o.City);
                this.setState({ postalCodeError: false, cities: cities })
            }
            this.setState({
                cityStatesList: nextProps.cityStatesList,
                states: nextProps.states,
                State: nextProps.states[0]
            })
        }
    }

    setFieldValue(e, field) {
        let { floor, block, street, zipcode } = this.state;
        let { value, id, name } = e.target;

        // let trimmedInput = e.target.value === " " ? e.target.value.trim() : e.target.value.replace(/[^a-zA-Z0-9-# ]/g, '');
        switch (field) {
            case "floor": value = value.replace(validateAddressRegex(), '');
                /*    this.setState({
                       floor: trimmedInput !== "" ? trimmedInput : "",
                       floorTextboxClass: "ais_deliver-address-input ais_emi ais_active",
                       disableClear: false,
                       showError: false

                   });
                   if (block !== "" && street !== "" && zipcode !== "") {
                       this.setState({
                           disable: false
                       })
                   } */
                break;
            case "block": value = value.replace(validateAddressRegex(), '');
                /*     this.setState({
                        block: trimmedInput !== "" ? trimmedInput : "",
                        blockTextboxClass: "ais_deliver-address-input ais_emi ais_active",
                        disableClear: false,
                        showError: false
                    });
                    if (floor !== "" && street !== "" && zipcode !== "") {
                        this.setState({
                            disable: false
                        })
                    } */
                break;
            /*            case "street":
                           this.setState({
                               street: trimmedInput !== "" ? trimmedInput : "",
                               streetTextboxClass: "ais_deliver-address-input ais_emi ais_active",
                               disableClear: false,
                               showError: false
                           });
                           if (floor !== "" && block !== "" && zipcode !== "") {
                               this.setState({
                                   disable: false
                               })
                           }
                           break; */
            case "zipcode":
                //trimmedInput = trimmedInput.replace(/[^[0-9]/g, '');
                value = value.replace(/[^0-9]/g, '');
                if (value.length === 5) {
                    this.props.getProvinces(value);
                    console.log(value);
                    this.setState({ disablePostalCode: true })
                } else {
                    this.setState({ cities: [], states: [], city: '', State: '', disablePostalCode: false })
                }
                /*   this.setState({
                      zipcode: trimmedInput !== "" ? trimmedInput : "",
                      zipcodeTextboxClass: "ais_deliver-address-input ais_emi ais_active",
                      disableClear: false,
                      showError: false
                  })

                  if (floor !== "" && block !== "" && street !== "") {
                      this.setState({
                          disable: false
                      })
                  } */
                break;
        }
        this.setState({ [field]: value !== "" ? value : "" });
    }

    setReason(value) {
        this.setState({
            reasonText: value
        })
    }

    handleCancel() {
        this.setState({
            disable: true,
            disableClear: true,
            disableCancel: true,
            disableSelect: true,
            disableSubmit: true,
            hideModalPopUp: true,
            textboxDisable: true
        });

        if (CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS === this.props.dataprops.source) {
            if (this.props.isWbkoSelected) {
                this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION);
            } else {
                this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS, MESSAGE_CONSTANTS.CANCELLED);
            }
        } else {
            this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.SIM_CARD_CONFIRMATION, MESSAGE_CONSTANTS.CANCELLED);
        }
    }

    handleCitySelected(e) {
        this.setState({
            city: e.target.value
        })
    }

    handleStateSelected(e) {
        let { value } = e.target;
        let cities = this.props.cityStatesList.map(o => { if (o.State === value) return o.City })
        this.setState({
            State: value,
            cities: _compact(cities)
        })
    }

    renderCityList = cities => {
        return cities.map(city => <option value={city} key={city}>{city}</option>)
    }

    renderStateList = states => {
        return states.map(state => <option value={state} key={state}>{state}</option>)
    }

    render() {
        const { data, serviceRequestStatus, isWbkoSelected } = this.props;
        let { city, cities, states, State, floor, disableSubmit } = this.state;
        let btnDisable = !(floor.trim().length > 0 && city && states);
        //let selectDisable = (states.length || cities.length);

        let commonDiv = <div>
            <div className={`${isWbkoSelected ? '' : 'form-row row'}`}>
                <div className="form-group ais_padding-right-5 ais_padding-left-5">
                    {!isWbkoSelected && <label htmlFor="floor" className="ais_textbox-label">{data.LABELS.ADDRESS1}</label>}
                    <input disabled={this.state.textboxDisable}
                        className="ais_address-input-field wbkoPadding15" type="text"
                        value={this.state.floor} name="floor"
                        maxLength="50"
                        onChange={(e) => this.setFieldValue(e, "floor")}
                        onPaste={(e) => this.setFieldValue(e, "floor")}
                        placeholder={isWbkoSelected ? data.WBKOPLACEHOLDERS.FLOOR : data.PLACEHOLDERS.FLOOR} />
                </div>
                <div className="form-group ais_padding-right-5 ais_padding-left-5">
                    {!isWbkoSelected && <label htmlFor="block" className="ais_textbox-label">{data.LABELS.ADDRESS2}</label>}
                    <input disabled={this.state.textboxDisable}
                        className="ais_address-input-field wbkoPadding15" type="text"
                        value={this.state.block} name="block"
                        maxLength="50"
                        onChange={(e) => this.setFieldValue(e, "block")}
                        onPaste={(e) => this.setFieldValue(e, "block")}
                        placeholder={isWbkoSelected ? data.WBKOPLACEHOLDERS.STREET_NAME : data.PLACEHOLDERS.STREET_NAME} />
                </div>
            </div>

            <div className={`${isWbkoSelected ? '' : 'form-row row'}`}>
                <div className="form-group ais_padding-right-5 ais_padding-left-5">
                    {!isWbkoSelected && <label htmlFor="street" className="ais_textbox-label">{data.LABELS.ADDRESS3}</label>}
                    <input disabled={this.state.textboxDisable}
                        className="ais_address-input-field wbkoPadding15" type="text"
                        value={this.state.street} name="street"
                        maxLength="50"
                        onChange={(e) => this.setFieldValue(e, "street")}
                        onPaste={(e) => this.setFieldValue(e, "street")}
                        placeholder={isWbkoSelected ? data.WBKOPLACEHOLDERS.BLOCK_NO : data.PLACEHOLDERS.BLOCK_NO} />
                </div>
                <div className="form-group ais_padding-right-5 ais_padding-left-5">
                    {!isWbkoSelected && <label htmlFor="zipcode" className="ais_textbox-label">{data.LABELS.POSTALCODE}</label>}
                    <input type="text"
                        disabled={this.state.textboxDisable}
                        className="ais_address-input-field wbkoPadding15"
                        value={this.state.zipcode} name="zipcode"
                        maxLength="5"
                        onChange={(e) => this.setFieldValue(e, "zipcode")}
                        onPaste={(e) => this.setFieldValue(e, "zipcode")}
                        placeholder={isWbkoSelected ? data.WBKOPLACEHOLDERS.ZIP_CODE : data.PLACEHOLDERS.ZIP_CODE} />
                    {this.state.disablePostalCode ? "" : <label className="ais_cc-text-error">{MESSAGE_CONSTANTS.INCORRECT_POSTAL_CODE}</label>}
                </div>
            </div>
            {isWbkoSelected ? <div className={`${isWbkoSelected ? '' : 'form-row row'}`}>
                <div className="form-group ais_padding-right-5 ais_padding-left-5">
                    <select id="city" disabled={this.state.selectDisbale} className="ais_select-field wbkoPadding10" value={this.state.city} onChange={this.handleCitySelected}>
                        <option value="">{isWbkoSelected ? data.WBKOPLACEHOLDERS.CITY : data.PLACEHOLDERS.CITY_PLACEHOLDER}</option>
                        {this.renderCityList(cities)}
                    </select>
                </div>
                <div className="form-group ais_padding-right-5 ais_padding-left-5">
                    {!isWbkoSelected && <label htmlFor="zipcode" className="ais_textbox-label">{data.LABELS.CITY}</label>}

                    <select id="State" disabled={this.state.selectDisbale} className="ais_select-field wbkoPadding10" name="state" value={this.state.State} onChange={this.handleStateSelected}>
                        {states.length ? "" : <option value="">{isWbkoSelected ? data.WBKOPLACEHOLDERS.STATE : data.PLACEHOLDERS.STATE_PLACEHOLDER}</option>}
                        {this.renderStateList(states)}
                    </select>
                </div>
            </div>
                :
                <div className={`${isWbkoSelected ? '' : 'form-row row'}`}>
                    <div className="form-group ais_padding-right-5 ais_padding-left-5">
                        <label htmlFor="state" className="ais_textbox-label">{data.LABELS.STATE}</label>
                        <select id="State" disabled={this.state.selectDisbale} className="ais_select-field" name="state" value={this.state.State} onChange={this.handleStateSelected}>
                            {states.length ? "" : <option value="">{data.PLACEHOLDERS.STATE_PLACEHOLDER}</option>}
                            {this.renderStateList(states)}
                        </select>
                    </div>
                    <div className="form-group ais_padding-right-5 ais_padding-left-5">
                        <label htmlFor="zipcode" className="ais_textbox-label">{data.LABELS.CITY}</label>
                        <select id="city" disabled={this.state.selectDisbale} className="ais_select-field" value={this.state.city} onChange={this.handleCitySelected}>
                            <option value="">{data.PLACEHOLDERS.CITY_PLACEHOLDER}</option>
                            {this.renderCityList(cities)}
                        </select>
                    </div>
                </div>}

            {this.state.showError && <div className="ais_error-div address">
                <label>{this.props.data.ERROR_MSG}</label>
            </div>}

            {this.props.selectedEnquiryOption ? <textarea className="ais_current-form-drop-Message-Input ais_textarea ais_address"
                value={this.state.reasonText}
                onChange={(e) => this.setReason(e.target.value)}
                disabled={this.state.textboxDisable}
                placeholder={MESSAGE_CONSTANTS.REASON_FOR_CHANGE} /> : ""}

            {isWbkoSelected && <div className="ais_note-div" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_VALID_ADDRESS_NOTE }} />}
            <div className="ais_outer-align-button">
                <button disabled={this.state.disableClear} className={this.state.clearBtnClass} onClick={this.handleClearClick} >{isWbkoSelected ? this.props.data.WBKO_BUTTONS[0] : this.props.data.BUTTONS[0]}</button>

                <div className="ais_device-divider ais_button" />
                {this.props.serviceRequestStatus !== 'CMPLTD' ?
                    <button disabled={this.state.disableCancel} className={this.state.clearBtnClass} onClick={this.handleCancel} >{isWbkoSelected ? this.props.data.WBKO_BUTTONS[2] : this.props.data.BUTTONS[2]}</button> : ""}
                <div className="ais_device-divider ais_button" />
                <button disabled={disableSubmit || btnDisable} className={this.state.submitBtnClass} onClick={this.handleSubmitClick} >{isWbkoSelected ? this.props.data.WBKO_BUTTONS[1] : this.props.data.BUTTONS[1]}</button>
                {!isWbkoSelected && <div className="ais_note-div" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.VALID_ADDRESS_NOTE }} />}
            </div>
            {/* </form> */}</div>

        let addChangeDiv;
        if (isDeviceOrWeb() === "web") {
            addChangeDiv = <div className={`${isWbkoSelected ? ' ais_current-form-device-div ' : ' ais_device-outer ais_deliverySchedule '} `}>
                {isWbkoSelected &&
                    <div className="ais_center-align">
                        <div>
                            <img alt="enroll-address-icn" className="margin15" src={wbko_chnage_delivery_address} />
                        </div>
                    </div>}
                <div className="ais_container-div-address ais_web">
                    {isWbkoSelected ? <div className="wbkoChangeAddressAlign">{this.props.data.WBKO_HEADER}</div>
                        : <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>}
                    {commonDiv}
                </div>
            </div>
        } else {
            let style = this.state.hideModalPopUp ? { 'display': 'none' } : {};
            addChangeDiv = <div id="modalAddressDetails" className="ais_modal" style={style}>
                <div className={`${isWbkoSelected ? ' ais_current-form-device-div ais_modal-content ais_address' : 'ais_modal-content ais_modal-CC-wrapper ais_address'}`}>
                    <div className="ais_scroll-div-address">
                        <img alt="close-icn" className="ais_close-btn-add" src={closeIcon} onClick={this.handleCancel} />
                        {isWbkoSelected &&
                            <div className="ais_center-align">
                                <div>
                                    <img alt="enroll-address-icn" className="margin15" src={wbko_chnage_delivery_address} />
                                </div>
                            </div>}
                        <div className={`${isWbkoSelected ? ' ais_container-div-CC-modal wbkoMarginTop ' : 'ais_container-div-CC-modal'} `} >
                            <div className="ais_container-wrapper-lw" style={{ 'border': 0, 'borderRadius': 0 }}>
                                <label className={`${isWbkoSelected ? ' margin-bottom20 ais_mobile-header-text ' : 'ais_mobile-header-text'}`}>{this.props.data.HEADER}</label>
                                {commonDiv}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        return (
            <div>
                {addChangeDiv}
            </div>
        )
    }
}

const mapStateToProps = state => {
    let { provinceList, cityStatesList, selectedEnquiryOption,
        clientData, warehouseId, addressRetryAttempt, serviceRequestStatus, isWbkoSelected, backOrderCase } = state.customerData
    return {
        backOrderCase,
        isWbkoSelected,
        states: provinceList || [],
        cityStatesList: cityStatesList || [],
        selectedEnquiryOption: selectedEnquiryOption,
        cacheId: clientData.CacheId,
        warehouseId: warehouseId,
        addressRetryAttempt: addressRetryAttempt,
        serviceRequestStatus
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getStandardiseAddress,
        savePayload,
        setRetryAddressAttempt,
        submitEnquiryDetails,
        getProvinces,
        shippingmethodsV2Init
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDeliveryAddress);