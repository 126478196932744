import React, { Component } from "react";
import { connect } from 'react-redux';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import CONSTANTS from '../../utils/Constants';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import { SELECTED_CALLBACK_DATE,SELECTED_CALLBACK_TIME,CALLBACK_COMMENT,LOAD_THANK_YOU,CHURN_SAVEDESK_ALTERNATE_CONTACT_NUMBER,STAY_WITH_AIS } from '../../actions/Dashboard/actionTypes';
import {savePayload} from "../../actions/Dashboard/doFindAgreements";
import { bindActionCreators } from 'redux';
import { getLang } from './../../utils/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {ReactComponent as Calendar} from "../../images/ais-images/calendar.svg";
import {ReactComponent as DropdownArrow} from "../../images/ais-images/drop-down-arrow.svg";

class LeavingAISAcknowledge extends Component {
  constructor(props) {
    super(props);
    this.state = {
        comment: null,
        maxChar:2000,
        disabled: false
    };
    this.leaveFeedback = this.leaveFeedback.bind(this);
    this.setSelectedDate= this.setSelectedDate.bind(this);
    this.handleChange= this.handleChange.bind(this);
  }

  componentDidMount() {
    let {selectedCallBackDate} = this.props
    if (!selectedCallBackDate) {
      console.log("LeavingAISAcknowledge selectedCallBackDate", selectedCallBackDate)
      let defaultCallbackDate = new Date();
      defaultCallbackDate.setDate(defaultCallbackDate.getDate() + 1);
      this.props.savePayload(SELECTED_CALLBACK_DATE, moment(defaultCallbackDate.toISOString()).format('L'));
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.createInquiryResponse){
      setTimeout(() => {
        this.props.savePayload(LOAD_THANK_YOU, {showThankYou:true, stayWithAIS:false});
      }, 3500);
    }
  }
  leaveFeedback(){
    let {selectedCallBackDate,selectedCallBackTime,callBackComment,churnSavedeskAlternateContactNumber,createInquiryResponse} = this.props;
    MIXPANEL.track(EVENTLIST.THANK_YOU,{'Load Thank You':''});
    this.props.savePayload(STAY_WITH_AIS, {stayWithAIS:false});
    this.props.updateJourneyList(CONSTANTS.LOAD_THANK_YOU,{stayWithAIS:false,
    selectedCallBackDate : selectedCallBackDate,selectedCallBackTime : selectedCallBackTime,
    callBackComment : callBackComment,churnSavedeskAlternateContactNumber : churnSavedeskAlternateContactNumber});
    this.setState({disabled : true})
  }
  setSelectedDate=(date)=>{
    MIXPANEL.track(EVENTLIST.SELECTED_CALLBACK_DATE,{'Selected Callback Date':date});
    this.props.savePayload(SELECTED_CALLBACK_DATE, date);
  }

  handleChange=(event)=>{
    switch(event.target.name){
      case 'selectSlot':
        let time= event.target.value;
        MIXPANEL.track(EVENTLIST.SELECTED_CALLBACK_TIME,{'Selected Callback Time':time});
        this.props.savePayload(SELECTED_CALLBACK_TIME, time);
        break;
      case 'alternateContactNumber':
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        MIXPANEL.track(EVENTLIST.ALTERNATE_CONTACT_NUMBER,{'Alternate COntact Number': event.target.value});
        this.props.savePayload(CHURN_SAVEDESK_ALTERNATE_CONTACT_NUMBER, event.target.value);
        break;
      case 'comment':
        this.setState({ comment: event.target.value })
        MIXPANEL.track(EVENTLIST.CALL_BACK_COMMENT,{'Callback Comment':event.target.value});
        this.props.savePayload(CALLBACK_COMMENT, event.target.value);
        break;
    }
  }

  render(){
    let {disabled} = this.state;
    let {selectedCallBackDate,selectedCallBackTime,churnSavedeskAlternateContactNumber} = this.props;
    let {comment,maxChar} = this.state;
    let length= comment ? comment.length : 0;

    let date = new Date();
    date.setDate(date.getDate() + 1);  // tomorrow
    const minDateValue = date.toISOString();

    let maxD = new Date();
    maxD.setDate(maxD.getDate() + 2);
    let maxDateValue = maxD.toISOString();

    const isWeekday = date => {
      const birthday = new Date(date);
      const day = birthday && birthday.getDay();
      return day !== 0 && day !== 6;
    };
    let maxlen = 10, minlen = 5;
    let alternateContactNoValidationFlag = churnSavedeskAlternateContactNumber && churnSavedeskAlternateContactNumber.length < 5;

    console.log("rendered selectedCallBackDate", selectedCallBackDate)
      
    return(
      <div>
        <div className="ais_current-form-device-div">
          <div className="ais_header-txt-div">
            <div>
                <div className="acknowledge-text left">{MESSAGE_CONSTANTS.LEAVE_FEEDBACK_MSG}</div>
                <div className="callbackWindow">{MESSAGE_CONSTANTS.REQUEST_SENT_SUCCESSFULLY}</div>
                <div className='datepicker' style={{position: "relative"}}>
                    <DatePicker
                    className='selectSlot'
                    name="datePicker"
                    selected={selectedCallBackDate ? moment(selectedCallBackDate) : moment(minDateValue)}
                    onChange={(d) => { this.setSelectedDate(moment(d).format('L')) }}
                    placeholderText={MESSAGE_CONSTANTS.SELECT_DATE}
                    minDate={moment(minDateValue)}
                    maxDate = {moment(maxDateValue)}
                    //readOnly={true}
                    dateFormat="YYYY-MM-DD"
                    autoComplete="off"
                    //filterDate={isWeekday}
                    />
                    <Calendar className="calendarIcon" />
                </div>
                <div className="datepicker">
                  <select ref="selectSlot" name="selectSlot" id="selectSlot" className='selectSlot' onChange={this.handleChange} placeholder={MESSAGE_CONSTANTS.SELECT_TIME}>
                      <option >{MESSAGE_CONSTANTS.SELECT_TIME}</option>
                      <option value='09:00 AM to 12:00 PM'>{MESSAGE_CONSTANTS.CALLBACK_WINDOW_MORNING}</option>
                      <option value='01:00 PM to 06:00 PM'>{MESSAGE_CONSTANTS.CALLBACK_WINDOW_EVENING}</option>
                  </select>
                  <DropdownArrow className="drop_down_arrow" />
                </div>
                <input
                  type="text"
                  className='selectSlot'
                  name="alternateContactNumber"
                  onChange={this.handleChange}
                  maxLength={maxlen}
                  minLength={minlen}
                  placeholder={MESSAGE_CONSTANTS.ALTERNATE_CONTACT_NUMBER}
              />
                <div className='acknowledge_comment_text left'>{MESSAGE_CONSTANTS.FEEDBACK_COMMENT}</div>
                <div className='acknowledge_comment'>
                    <textarea
                    name="comment"
                    length={comment ? comment.length : 0}
                    onChange={this.handleChange}
                    maxLength= "2000" className= 'textarea_border' placeholder={MESSAGE_CONSTANTS.TEXT_AREA_PLACEHOLDER}/>
                </div>
                   {maxChar ? <span className="float-right text_area_limit"> {length}/{maxChar}</span> : null}
                 <div>
                  <button className={`stay-with-ais-button  ${!selectedCallBackDate || !selectedCallBackTime || selectedCallBackTime === 'เวลาที่สะดวก' || selectedCallBackTime === 'Select Time'  ||
                   !comment || selectedCallBackDate==='Invalid date'  || alternateContactNoValidationFlag || disabled ? ' disabled  ais_device-select-button' :' ais_device-select-button stay-with-ais-button-highlighted'}`}
                    onClick={this.leaveFeedback}
                    disabled= {!selectedCallBackDate || !selectedCallBackTime || selectedCallBackTime === 'เวลาที่สะดวก' || selectedCallBackTime === 'Select Time'  ||
                     !comment || selectedCallBackDate==='Invalid date' || alternateContactNoValidationFlag || disabled }>{MESSAGE_CONSTANTS.LEAVE_FEEDBACK}
                  </button>
                </div>
            </div>
        </div>
        </div>
      </div>
      )
  }
}
const mapStateToProps = state => {
  let {selectedCallBackDate,selectedCallBackTime,churnSavedeskAlternateContactNumber,
    callBackComment,createInquiryResponse}= state.customerData
  return {
   selectedCallBackDate,selectedCallBackTime,churnSavedeskAlternateContactNumber,callBackComment,createInquiryResponse
  }
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePayload
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeavingAISAcknowledge);

