import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLang } from './../../utils/utils';

import {
  savePayload,
  updateTransitionPanel
} from "../../actions/Dashboard/doFindAgreements";
import CONSTANTS from '../../utils/Constants';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import enJsonFile from "../../config/ais-leaving-reasons-en.json";
import thaiJsonFile from "../../config/ais-leaving-reasons-thai.json";
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { LOAD_THANK_YOU, SAVE_1, STAY_WITH_AIS } from '../../actions/Dashboard/actionTypes';
import DOMPurify from "dompurify";

class AISReasonCommonPage extends Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.stayWithAIS = this.stayWithAIS.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.createInquiryResponse) {
      setTimeout(() => {
        this.props.savePayload(LOAD_THANK_YOU, { showThankYou: true, stayWithAIS: true });
      }, 3500);
    }
  }
  handleSubmit() {
    MIXPANEL.track(EVENTLIST.SUBMITTED_AIS_LEAVING_REASON);
    this.props.savePayload(SAVE_1, 'save1');
    this.props.updateJourneyList(CONSTANTS.LOAD_AIS_SUBREASON, 'LOAD_AIS_SUBREASON');
    this.setState({ disabled: true })
  }
  stayWithAIS() {
    MIXPANEL.track(EVENTLIST.THANK_YOU, { 'Load Thank You': '' });
    this.props.savePayload(SAVE_1, 'save1');
    this.props.savePayload(STAY_WITH_AIS, { stayWithAIS: true });
    this.props.updateJourneyList(CONSTANTS.LOAD_THANK_YOU, { stayWithAIS: true, save1: 'save1' });
    this.setState({ disabled: true })
  }

  render() {
    let { disabled } = this.state;
    let { selectedLeavingAISReason, swapFee, replacementFee } = this.props;
    let { CurrencyCode, TotalAmountWithDiscount, TotalAmount } = swapFee && swapFee.Fees;
    let CurrencyCodeForReplacement = replacementFee && replacementFee.Fees && replacementFee.Fees.CurrencyCode;
    let TotalAmountWithDiscountForReplacement = replacementFee && replacementFee.Fees && replacementFee.Fees.TotalAmountWithDiscount;
    let TotalAmountForReplacement = replacementFee && replacementFee.Fees && replacementFee.Fees.TotalAmount
    let finalizeArrayToMap, headerLabel;

    const lang = getLang()
    let jsonFile = (lang === "th") ? thaiJsonFile : enJsonFile;

    if ((selectedLeavingAISReason === CONSTANTS.NOT_HELPFULL) ||
      (selectedLeavingAISReason === CONSTANTS.BUYING_NEW_DEVICE) ||
      (selectedLeavingAISReason === CONSTANTS.OTHER_REASON)
    ) {
      finalizeArrayToMap = jsonFile && jsonFile.notHelpfull_buyingNewDevice_otherReason;
      headerLabel = MESSAGE_CONSTANTS.NOTHELPFULL_NEWDEVICE_OTHERREASON_HEADER;
    } else if (selectedLeavingAISReason === CONSTANTS.COST_TOO_MUCH) {
      finalizeArrayToMap = jsonFile && jsonFile.costTooMuch;
      headerLabel = MESSAGE_CONSTANTS.COST_TOO_MUCH_HEADER;
    } else if (selectedLeavingAISReason === CONSTANTS.NO_NEED_PACKAGE_AUTO_RENEWAL) {
      finalizeArrayToMap = jsonFile && jsonFile.noNeedPackageAutoRenewal;
      headerLabel = MESSAGE_CONSTANTS.NO_NEED_PACKAGE_AUTO_RENEWAL_HEADER;
    } else if (selectedLeavingAISReason === CONSTANTS.NOT_USING_IT_ENOUGH_OR_NOT_AT_ALL) {
      finalizeArrayToMap = jsonFile && jsonFile.notUsingItOrNotEnoughAtAll;
      headerLabel = MESSAGE_CONSTANTS.NOT_USING_IT_OR_NOT_ENOUGH_AT_ALL_HEADER;
    }
    const sanitizer = DOMPurify.sanitize;
    return (
      <div>
        <div className="ais_current-form-device-div desktop-no-box-view">
          <div className="ais_header-txt-div">
            <div className="ais-label" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(headerLabel) }}></div>
            {selectedLeavingAISReason === CONSTANTS.NO_NEED_PACKAGE_AUTO_RENEWAL &&
              <div className="subLabel">{MESSAGE_CONSTANTS.NO_NEED_PACKAGE_RENEWAL_SUB_HEADER1}</div>}
            {selectedLeavingAISReason === CONSTANTS.NOT_USING_IT_ENOUGH_OR_NOT_AT_ALL &&
              <div className="subLabel">{MESSAGE_CONSTANTS.NO_NEED_PACKAGE_RENEWAL_SUB_HEADER2}</div>}

            {selectedLeavingAISReason === CONSTANTS.COST_TOO_MUCH &&
              <div>
                <div>{MESSAGE_CONSTANTS.YOU_PAY_ONLY}</div>
                <div className="payOnly">
                  <div><div className=" costTooMuch costTooMuchAmt">{`${CurrencyCode} ${TotalAmount}`}</div><div className=" costTooMuchLbl">{MESSAGE_CONSTANTS.FOR_SWAP}</div></div>
                  <div><div className="costTooMuch costTooMuchAmt">{`${CurrencyCodeForReplacement} ${TotalAmountForReplacement}`}</div><div className=" costTooMuchLbl">{MESSAGE_CONSTANTS.FOR_REPLACEMENT}</div></div>
                </div>
              </div>
            }

            {finalizeArrayToMap && selectedLeavingAISReason && finalizeArrayToMap.map((data, i) => {
              return <div className={`${i % 2 === 0 || i === 0 ? ' ais-leaving-reasons  ' : ' ais-leaving-reasons reverse '}`} key={data.text}>
                <div className='json-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.text) }}></div>
                <div className="logo-sm-churn background">
                  <img className=" " alt="" src={data.backgroundImg} />
                  {/*  <img className="background hidden-xs hidden-sm" alt="" src={data.desktopImage} /> */}
                  {/* <img className={`${data.backgroundImg==='/images/left_bg.svg'? 'icon reverse-margin  ' :'icon '}`} icon alt="" src={data.imageUrl}/> */}
                </div>
                <div className="logo-lg-churn background">
                  {/* <img className="background hidden-md hidden-lg" alt="" src={data.backgroundImg} /> */}
                  <img className="" alt="" src={data.desktopImage} />
                  {/* <img className={`${data.backgroundImg==='/images/left_bg.svg'? 'icon reverse-margin  ' :'icon '}`} icon alt="" src={data.imageUrl}/> */}
                </div>
              </div>
            })
            }
            {selectedLeavingAISReason === 'no_need_package_auto_renewal' &&
              <div className="footerText">{MESSAGE_CONSTANTS.ARE_YOU_SURE_WANT_TO_REMOVE}</div>
            }
            {
              selectedLeavingAISReason === 'cost_too_much' &&
              <div className="costTooMuchFooterText">{MESSAGE_CONSTANTS.COST_TOO_MUCH_FOOTER_TEXT}</div>
            }
            <div className="center">
              <button className="stay-with-ais-button" disabled={disabled} onClick={this.stayWithAIS}>{MESSAGE_CONSTANTS.STAY_WITH_AIS_MOBILE_CARE}</button>
            </div>
            <div className={`submit-text ${disabled ? ' disabled' : ' '}`} onClick={this.handleSubmit}>{MESSAGE_CONSTANTS.STAY_WITH_AIS_MOBILE_CARE_TEXT}</div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  let { selectedLeavingAISReason, swapFee, replacementFee, createInquiryResponse } = state.customerData;
  return {
    selectedLeavingAISReason, swapFee, replacementFee, createInquiryResponse
  }
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePayload,
      updateTransitionPanel
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AISReasonCommonPage);

