import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import CONSTANTS from '../utils/Constants'
import WebDashboard from "../components/web/WebDashboard/WebDashboard";
//import DeviceDashboard from "../components/device/DeviceDashboard/DeviceDashboard";
//import { isDeviceOrWeb } from "../utils/utils";

import {
  doIntializeAppRequest,
  savePayload,
  setJourneyCmpltd
} from '../actions/Dashboard/doFindAgreements';
import { SAVE_CUSTOMERNAME, SAVE_VISITSOURCE } from '../actions/Dashboard/actionTypes';
import { getWorkspaceToken } from '../twilio/services/api'
import { initWorkspace, getRealtimeStatistics } from '../twilio/services/twilio'

import './../styles/styles.css';
import "./../styles/device_styles.css";
class Layout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      workspace: "",
      userCancelled: false,
      elapsed: 0,
      userName: { firstName: 'User', lastName: '', fullName: 'User ' },
      lastTimestamp: Date.parse(new Date()),
      timer: setInterval(this.tick, 50)
    }
    this.props.setJourneyCmpltd(false);
  }

  tick = () => {
    this.setState({ elapsed: new Date() - this.state.lastTimestamp });
  }

  componentWillMount() {
    let visitSource = sessionStorage.getItem("visitSource") || ""; // read visitSource from session
    this.props.savePayload(SAVE_VISITSOURCE, visitSource); // save visitSource in redux store

    this.props.savePayload(SAVE_CUSTOMERNAME, this.state.userName.fullName);
  }

  componentDidMount() {
    this.props.doIntializeAppRequest(this.state.userName.fullName);
    getWorkspaceToken()
      .then(res => initWorkspace(res))
      .then(this.twilioHandler);

    const dom = ReactDOM.findDOMNode(this);
    dom.addEventListener('mousedown', e => this.handleMouseDown(e));
    dom.addEventListener('keydown', e => this.handleKeyDown(e));
  }

  handleMouseDown(e) {
    this.handleUserActivity(e);
  }

  handleKeyDown(e) {
    this.handleUserActivity(e);
  }

  handleUserActivity(e) {
    this.props.journeyCmpltd && this.props.setJourneyCmpltd(false);
    clearInterval(this.state.timer);
    this.setState({
      elapsed: 0,
      lastTimestamp: Date.parse(new Date()),
      timer: setInterval(this.tick, 50)
    });
  }

  twilioHandler = (workspace) => {
    getRealtimeStatistics({ workspace });
    this.setState({
      workspace: workspace
    })
  }

  _setUserClosedJourneyDuringAssistance = () => {
    this.setState({
      userCancelled: true
    });
  }

  render() {
    if(!this.props.InteractionLineId) return <div className="loader-wrap">
        <div className="loader"></div>
      </div>
    let { visitorCognitoId } = this.props;
    var miliseconds = this.state.elapsed
    var totalSeconds = Math.floor(miliseconds / 1000);
    var minutes = Math.floor(totalSeconds / 60);

    if (!this.props.journeyCmpltd && CONSTANTS.DEFAULT_INACTIVITY_TIMEOUT_MINUTES === minutes) {
      const { setJourneyCmpltd } = this.props;
      setJourneyCmpltd(true, CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED);
    }

    return (
      <WebDashboard workspace={this.state.workspace} visitorCognitoId={visitorCognitoId} sourceAction={this.props.sourceAction}
        userCancelled={this.state.userCancelled}
        setUserClosedJourneyDuringAssistance={this._setUserClosedJourneyDuringAssistance}
        showThankYouFlag={this.props.showThankYouFlag}/>
    )
  }
}

const mapStateToProps = state => {
  const { journeyCmpltd, InteractionLineId,showThankYouFlag } = state.customerData;
  return {
    journeyCmpltd,
    InteractionLineId,
    showThankYouFlag
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    savePayload,
    doIntializeAppRequest,
    setJourneyCmpltd
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);