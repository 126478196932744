import React from "react";
import { sanitizeHTML } from "./../utils/utils";

const TextMessage = ({ textMessage, isText = false }) => {
  /*   return isText ? (
    <div className="ais_message-content">{textMessage}</div>
  ) : ( */
  return (
    <div
      className="ais_message-content"
      dangerouslySetInnerHTML={{ __html: sanitizeHTML(textMessage) }}
    />
  );
  // );
};

export default TextMessage;
