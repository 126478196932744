import React, { Component, Fragment } from "react";
import { connect } from "react-redux"
import { bindActionCreators } from 'redux';
import classNames from "classnames";
import MESSAGE_CONSTANTS_EN from "../../config/journey-messages";
import CONSTANTS from "../../utils/Constants";
import MESSAGE_CONSTANTS from "../../config/journey-messages"
import { deviceListLoadingProgress } from '../../actions/Dashboard/doFindAgreements'

class UpdateEnrolledDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
  }

  handleClick = (name, value, text) => {
    this.setState({
      selectedOption: name,
    });
    this.props.updateJourneyList(CONSTANTS.UPDATE_ENROLLED_DEVICE, { value, text} );
  };
  _renderOptions(option) {
    let btnClasses = classNames({
      disabled: this.state.selectedOption,
      "ais_options-replacement-selected-div":
        this.state.selectedOption === option.name,
      "ais_options-replacement-div": this.state.selectedOption !== option.name,
    });

    return (
      <div
        name={option.text}
        key={option.text}
        className={`${btnClasses} updateEnrollment`}
        disabled={this.state.selectedOption}
        onClick={() => this.handleClick(option.name, option.value, option.text)}
      >
        <label
          className={
            this.state.selectedOption === option.name
              ? "ais_options-selected-label"
              : "ais_options-label"
          }
        >
          {option.value}
        </label>
      </div>
    );
  }
  backToMainMenu = () => {
    this.props.deviceListLoadingProgress(true)
    this.props.updateJourneyList(CONSTANTS.DEVICE_LIST, MESSAGE_CONSTANTS.BackToMainMenu);
  }
  render() {
    return (
      <Fragment>
        <div>
          <div className="enrollment-container">
            <label>{MESSAGE_CONSTANTS_EN.UPDATE_ENROLLMENT.title}</label>
          </div>
          {MESSAGE_CONSTANTS_EN.UPDATE_ENROLLMENT.buttons.map((e, i) =>
            this._renderOptions(e, i)
          )}
        </div>
        <div class='backToMainMenu'>
          <a className="backToMainMenu" disabled="" onClick={this.backToMainMenu} >{MESSAGE_CONSTANTS.BackToMainMenu}</a>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
   deviceListLoadingProgress
  }, dispatch)
};

export default connect(null, mapDispatchToProps)(UpdateEnrolledDevice);
