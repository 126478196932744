import config from './../../config/config'
import { getTwilioConfig } from '../../utils/utils'
import axios from 'axios';
//const APINAME = 'TWTaskRouter';
// const methodNames = ['/workspace', '/worker'];
const [workspaceTwilio, workerTwilio, tokenTwilioPath, fetchTwilioPath] = ['workspace', 'worker', 'token', 'fetch'];
const { APIKey, baseURL } = config.slsAPIs;
const TWILIO_URL = `${baseURL}/api/subsystem/v1/twilio/gettoken`;

export async function getWorkerToken(workerSid) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${TWILIO_URL}?path=${tokenTwilioPath}&methodname=${workspaceTwilio}&workersid=${workerSid}`,
            headers: { 'x-api-key': APIKey },
        }).then(({ data = {} }) => {
            if (data.getTokenResponse) {
                console.log("WorkerToken generated successfully.");
                resolve(data.getTokenResponse);
            }
            else
                reject('Error while generating WorkerToken')
        }).catch(err => {
            console.log('Error while generating WorkerToken : ', err)
            reject(err);
        });
    });
}

export async function getWorkspaceToken() {
    let twilioConfig = await getTwilioConfig();
    let region = 'sea';
    
    if (twilioConfig) {
        sessionStorage.setItem("workflowId", twilioConfig.workflowid);
        region = twilioConfig.region;
    } else {
        sessionStorage.setItem("workflowId", config.Twilio.workflowSid);
    }
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${TWILIO_URL}?path=${tokenTwilioPath}&methodname=${workspaceTwilio}&identifier=${region}`,
            headers: { 'x-api-key': APIKey },
        }).then(({ data = {} }) => {
            if (data.getTokenResponse) {
                resolve(data.getTokenResponse);
                console.log("WorkspaceToken generated successfully.");
            }
            else
                reject('Error while generating workspaceToken')
        }).catch(err => {
            console.log('Error while generating workspaceToken : ', err)
            reject(err);
        });
    });
}