import React from 'react'
import ais_mobile_care_logo_3x from '../../images/ais-images/ais-mobile-care-logo@3x.png';
export default function TermConditions() {
  document.title = "Terms and Conditions"

  return (
    <div className="term-condition-page">
      <div className=" header">
        {/*<div class="headerText">Terms & Conditions</div>*/}
        <div className="headerTime">17 Jul 2019 9:31 AM</div>
      </div>
      <div className="">
        <div className=" topSpace">
          <div className="">
            <div className="text">
              <img src={ais_mobile_care_logo_3x} alt className="mobileSwop-logo" />
            </div> <br />
          </div>
          <div className="headerTopSpace">
            <div className="text">
              <center>
                <div className="row">
                  <div className="acc-header">TERMS &amp; CONDITIONS FOR AIS MOBILE CARE</div>
                </div>
                <div >
                  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <ol>
                      <div className="terms_general">
                        {/* General */}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="generalHeader">
                            <h4 className="panel-title">
                              {/*<a role="button" data-toggle="collapse" data-parent="#accordion" href="#general" aria-expanded="true" aria-controls="general">*/}
                              <li className="headerTitle">GENERAL</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="general" className="panel-collapse in" role="tabpanel" aria-labelledby="generalHeader">
                            <div className="panel-body">
                              <ol>
                                <li>These terms and conditions of services shall apply to and between
                                Advanced Wireless Network Company Limited (“Provider”) and a
                              User of the AIS Mobile Care Services.</li><br />
                                <li>By applying for the AIS Mobile Care Services, the User agrees to
                                be bound by and act in full compliance herewith. Such enrolment
                                is complete when the User receives SMS confirming his or her
                                enrolment.
                            </li>
                                <br />
                                <li>Subject to the terms contained herein, the Provider may decline to
                                provide all or part of the AIS Mobile Care Services to a User.
                            </li>
                                <br />
                                <li>The Provider has appointed Asurion (Thailand) Limited (the “Company”)
                                as its service provider to provide the Services (as defined in
                                clause 2.1) and collect the Swap Fee or Replacement Fee (such
                                terms as specified in clause 12.5). The User agrees that where
                                the Provider has rights or obligations in relation to the Services
                                or under these terms and conditions, the Company, as the Provider’s
                                service provider, may exercise these rights or discharge these
                              obligations on the Provider’s behalf. </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/* Service Summary */}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="service-summary-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#service-summary" aria-expanded="false"
                                                  aria-controls="service-summary">*/}
                              <li className="headerTitle">DEFINITIONS</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="service-summary" className="panel-collapse" role="tabpanel" aria-labelledby="service-summary-header">
                            <div className="panel-body">
                              <ol>
                                <li>Services means the AIS Mobile Care Services comprised of the Swap
                              and Replacement Program (as defined in clause 2.40).</li><br />
                                <li>Provider means Advanced Wireless Network Company Limited.</li>
                                <br />
                                <li>Company means Asurion (Thailand) Limited. </li>
                                <br />
                                <li>User means an applicant for enrolment for the Services and received
                              an SMS confirming his or her enrolment. </li><br />
                                <li>Courier means a person who: (i) transports AIS Mobile Care Devices;
                                (ii) accepts Registered Devices; (iii) accepts payments of Swap
                              Fees, Replacement Fees or other Fees. </li><br />
                                <li>Phone Number means mobile phone number from the Provider’s network,
                                used by the User in connection with the Registered Device for
                                his or her application for enrolment for the AIS Mobile Care
                              Services. </li><br />
                                <li>Registered Device means a Device bought by the User from AIS Shop
                                or Telewiz Shop or the Provider or other sources being of the
                                brand and model specified by the Provider and registered for
                                enrolment for the Services in accordance with the registering
                                method as designated by the Provider, the IMEI of which shall
                                be recorded by the AIS Mobile Care Providers as data for provision
                                of the Services to the User, and includes a Device acquired by
                                the User under the manufacturer's warranty for a Registered Device,
                                subject to notice thereof being made to the AIS Mobile Care Providers
                                by the User in accordance with the AIS Mobile Care Providers’
                                requirements.
                            </li><br />
                                <li>IMEI (International Mobile Station Equipment Identity) means the
                              unique number assigned to each Device. </li><br />
                                <li>AIS Mobile Care Device means a Device, new or otherwise, obtained
                                by a Swap or Replacement, and it shall be deemed the new Registered
                                Device after being delivered to the User, but as compared to
                                the original Registered Device, it: (i) is of similar kind, quality
                                and functionality; (ii) may be refurbished (containing OEM’s
                                or non-OEM’s parts); (iii) has same or greater memory; (iv) may
                                be a different make, model or color; (v) has a different IMEI;
                                (vi) is not provided in the OEM’s packaging; (vii) is provided
                                in packaging marked “not for resale”; and (viii) does not include
                                any Device Accessories.
                            </li><br />
                                <li>Device Accessories means any equipment or anything that is either
                                provided by the OEM in the box with a Device or sold separately
                                to be used with a Device. It includes: (i) batteries; (ii) SIM
                                cards; (iii) memory cards; (iv) chargers; (v) ear buds; (vi)
                                cases; (vii) boxes; (viii) cables; (ix) styli; and (x) mounts.
                            </li><br />
                                <li>Swap means the ability to request an AIS Mobile Care Device in exchange
                                for the Registered Device if the Registered Device is in the
                                User’s possession.
                            </li><br />
                                <li>Replacement means the ability to request an AIS Mobile Care Device
                                in exchange for the Registered Device if the Registered Device
                              is not in the User’s possession. </li><br />
                                <li>Registered Device Price as of Buying Date means the full recommended
                                marketing retail purchase price (RRP) of the Registered Device
                                (including VAT) on the Buying Date of the Registered Device,
                              as determined and notified by the Provider. </li><br />
                                <li>Fee means the: (1) AIS Mobile Care Package Fee, (2) Swap Fee, (3)
                                Replacement Fee or (4) Additional Fee (5) any other amount that
                                might be charged to the User on account of use of any Service
                              by the User. </li><br />
                                <li>AIS Mobile Care Call Center means the call center dedicated to the
                                Services as available at 098 098 1175 everyday (no day off),
                              from 08:00 a.m. to 10:00 p.m. </li><br />
                                <li>Bangkok Metropolitan Area means the areas of Bangkok, Nonthaburi, Pathum Thani,
                              Samut Prakan and Samut Sakhon Provinces. </li><br />
                                <li>Business Day means Monday to Saturday (excluding public holidays).
                            </li><br />
                                <li>Website means the website www.ais.co.th/mobile care showing the details
                                of the Services, AIS Mobile Care Package Fees, Swap Fees, Replacement
                                Fees, the Terms and Conditions for AIS Mobile Care and other
                              details, request certain Service-related assistances, etc. </li><br />
                                <li>Inspection means an inspection of a Device carried out in the AIS
                                Shop which involves testing Device to confirm that the Device
                                is in good condition and operating normally to the satisfaction
                                of the Provider. A device passes an Inspection where the Provider
                                is satisfied that the device is in good condition and operating
                                normally.
                            </li><br />
                                <li>Freeze Period means 30 days period from the enrolment date the User
                                shall not be able to make a Service Request for Swap or Replacement
                                including any other period of time that the AIS Mobile Care Providers
                              shall designate and inform the User in advance. </li> <br />
                                <li>National ID means a valid Thai national identity card.</li><br />
                                <li>Prohibited ID means a National ID or a Foreigner ID of the User for
                                which the AIS Mobile Care Providers have terminated the Services
                              or decided to deny enrolment in the Services. </li> <br />
                                <li>OEM means original equipment manufacturer. </li><br />
                                <li>Software Modification means modification made to a Registered Device’s
                                operating system not undertaken or authorized by the OEM and
                                includes software modification known as ‘jail-breaking’ and ‘rooting’.
                            </li><br />
                                <li>Hardware Modification means any repair and/or modification made to
                                a Registered Device’s hardware not undertaken or authorized by
                                the OEM.
                            </li><br />
                                <li>Modification means Software Modification or Hardware Modification
                                or both.
                            </li><br />
                                <li>Additional Fee means a service charge additionally collected from
                                the User equal to the Replacement Fee payable as specified in
                                the table in clause 12.5 less the Swap Fee already paid. The
                                Additional Fee shall be charged where the User has already paid
                                the Swap Fee, then the Company finds later that the User is not
                                entitled to use the Swap Service subject to the Terms and Conditions
                                which the Company shall treat the Swap as a Replacement and the
                              User shall pay Replacement Fee accordingly. </li><br />
                                <li>Acceptance Date means the day upon which the Service Request for
                              Swap or Service Request for Replacement is accepted. </li><br />
                                <li>AIS Shop means a customer service center operated by the Provider,
                              or its affiliate or the Provider’s authorizations. </li><br />
                                <li>Buying Date means the date of purchase of the Registered Device.
                            </li><br />
                                <li>Device means a mobile phone including a mobile wireless device that:
                                (i) has a display screen; (ii) supports one or more wireless
                                network connectivity options; and (iii) that is operated using
                                voice, touch or a miniature keyboard. It does not include any
                                Device Accessories.
                            </li><br />
                                <li>Fixed Term Package means Fixed Term 12 months Package. </li><br />
                                <li>Foreigner ID means a User's passport, Work Permit and Certificate
                              of Residence of Foreigner (if any).</li><br />
                                <li>AIS Mobile Care Providers means the Provider, the Company, and the
                              Company’s affiliates and subcontractors. </li><br />
                                <li>AIS Mobile Care Package Fee has the meaning given to it in clause
                                12.1.
                            </li><br />
                                <li>Package means: (i) Monthly Package; or (ii) Fixed Term 12 months
                                Package.
                            </li><br />
                                <li>Program has the meaning given to it in clause 5.3. </li> <br />
                                <li>VAT means value added tax charged at the prevailing rate at the time
                              of the transaction. </li><br />
                                <li>Service Request means a request for the Services made in accordance
                              with these these terms and conditions. </li><br />
                                <li>Swap and Replacement Program means the program as described in clause
                                5.3.
                            </li><br />
                                <li>Telewiz Shop means a shop which is authorized by the Provider or
                              its affiliates to distribute goods and services. </li><br />
                                <li>Tier means the tier outlined for the Registered Device as advised
                                at the time of application for enrolment for the AIS Mobile Care
                                Services.
                            </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/* Enrolling */}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">PROVISION OF SERVICES</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <p>AIS Mobile Care is the provision of the Services to a User upon making
                              a Service Request for a Swap or a Replacement in accordance with
                            these Terms and Conditions.</p>
                            </div>
                          </div>
                        </div>
                        {/* Fees */}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="fees-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#fees" aria-expanded="false" aria-controls="fees">*/}
                              <li className="headerTitle">ELIGIBILITY TO APPLY</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="fees" className="panel-collapse" role="tabpanel" aria-labelledby="fees-header">
                            <div className="panel-body">
                              <ol>
                                <li>To be eligible to apply for the Services, the User must:
                              <ol>
                                    <p>(a) be a subscriber of the Provider, registering under the
                                    name of or represented by a natural person only and not
                                    be in default of any mobile service payment obligations
                                    to the Provider.
                                </p>
                                    <p> (b) buy a Device (of the brand and model as designated by
                                    the Provider only) from AIS Shops, Telewiz Shop, or other
                                    shops and distribution channels as designated from time
                                    to time by the Provider and use such Device with the
                                  Phone Number.</p>
                                    <p>(c) Not have been previously rejected or terminated from
                                  the Services or service similar to the Services. </p>
                                    <p>(d) be domiciled in Thailand and holding either: </p>
                                    <ol>
                                      <p>(i) a National ID or </p>
                                      <p>(ii) in the case the User is a foreigner, Foreigner ID
                                      and the User must be permitted to legally stay in
                                    Thailand, which is not a Prohibited ID.</p>
                                    </ol>
                                  </ol>
                                </li>
                                <li>Open Enrolments (OE) is a promotional campaign based offering with
                                varying terms and conditions and notified by the Provider from
                                time to time. Unless otherwise specified in each promotional
                                campaign terms and conditions:
                              <ol>
                                    <p> (a) if a Device is enrolled without an Inspection, the User
                                    will be subject to a Freeze Period before the User may
                                  make a Service Request.</p>
                                    <p> (b) only Packages or Devices as indicated in specific campaigns
                                  are eligible for Open Enrolment.</p>
                                    <p>(c) the Device intended to be registered for the Service
                                  shall be purchased: </p>
                                    <ol>
                                      <p> (i) from AIS Shop or Telewiz Shop or other shops and
                                      distribution channels as specified by the Provider
                                    in the relevant campaign; and </p>
                                      <p>(ii) in the fixed time period as specified by the Provider
                                      in the relevant campaign and proof of purchase may
                                      be required to be provided to the Provider at the
                                    time of the enrolment. </p>
                                    </ol>
                                  </ol>
                                </li>
                                <li>The following additional conditions shall apply if a User wishes
                                to enroll post purchase (a Post Purchase Enrolment) application:
                              <ol>
                                    <p>(a) the application for enrolment must be made: (i) at an
                                    AIS Shop or via the AIS’ call center or other sales channel
                                    allowed by the Provider; (ii) within 30 days of the Device
                                    purchase date from AIS Shops, Telewiz Shop or other sales
                                    channel allowed by the Provider e.g. via the Website;
                                    and (iii) where required by the Provider, with proof
                                  of purchase of the Device. </p>
                                    <p>(b) if a Device is enrolled without an Inspection, the User
                                    will be subject to a Freeze Period before the User may
                                  make a Service Request. </p>
                                  </ol>
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/* How to enroll*/}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">HOW TO ENROLL</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <ol>
                                <li>Enroll at all AIS Shops or Telewiz Shop unless otherwise indicated;
                                or, HOW TO ENROLL
                            </li><br />
                                <li>Press *567*2*IMEI of the Registered Device then press # and call
                                from the Registered Device on the day of buying the Device or
                              within other specified period; and, </li> <br />
                                <li>5.3 Select AIS Mobile Care Package being Swap and Replacement Program
                                - being the program providing the User with ability to request
                                either: (i) a Swap; or (ii) a Replacement, subject to Section
                                XI, (Service Limits), of these Terms and Conditions (the Program).
                            </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/*START DATE*/}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">START DATE</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <p>The User will be enrolled for AIS Mobile Care from the date that the
                            User receives SMS confirming enrolment. </p>
                            </div>
                          </div>
                        </div>
                        {/*TERMS AND USE OF SERVICES */}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">TERMS AND USE OF SERVICES </li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <ol>
                                <li>One User is eligible to enroll for the Services for up to 3 Registered
                                Devices per Phone Number. A Registered Device may be enrolled
                                for a maximum period of 48 continuous months or until the User
                                inform of his or her intention to terminate the Service for Monthly
                              Package whichever the earlier. </li><br />
                                <li>In the case the User is a foreigner, such User must be in Thailand
                              at the time of requesting a Swap or a Replacement. </li><br />
                                <li>Expiry of Fixed Term Package: Users who subscribe to a Fixed Term
                                Package will automatically be renewed for the same Fixed Term
                                Package, unless the Users notify the AIS Mobile Care Providers
                                that the Users do not desire to continue subscribing for the
                                Fixed Term Package.
                            </li><br />
                                <li>User enrolled for a Fixed Term Package may change the Package within
                                30 days prior to the expiration of Service term to a Monthly
                                Package. However the balance of the subscription period for the
                                original Fixed Term Package shall be immediately forfeited.
                              <ol>
                                    <p>(a) A Monthly Package cannot be changed to the Fixed Term
                                    Package.
                                </p>
                                    <p>(b) If the User is post-paid user, the new Package will be
                                    effective upon the next bill. In the event the User is
                                    pre-paid user, the new Package will be in effect immediately.
                                </p>
                                    <p>(c) Users moving from a Fixed Term Package to the Monthly
                                    Package will carry forward their Section XI Service Limits
                                    as if they had been on the Monthly Package from enrolment
                                    and will not be reset.
                                </p>
                                  </ol>
                                </li>
                                <li>In case the User temporarily suspends the Phone Number (but for a
                                period not exceeding two successive months) for whatever reason
                                other than a lost SIM card, the User will be able to make a Swap
                                or Replacement after 30 days from the date when temporary suspension
                                of the Phone Number is reactivated. If the suspension exceeds
                              2 months the Services will be terminated by the Provider. </li><br />
                                <li> The User consents to the AIS Mobile Care Providers collecting, storing,
                                processing, arranging, accessing, transferring, using and disclosing
                                the User's personal information on the Services in Thailand or
                                other countries for the purposes of: (i) assessing the User’s
                                eligibility for enrollment (or continued enrollment) in the Program
                                or use of the Services; (ii) providing the User with the Services;
                                (iii) contacting the User (directly or indirectly) in connection
                                with the Services; (iv) managing commercial risks and preventing,
                                detecting and investigating suspected illegal activity, fraud
                                or disputes; and (v) creation of an aggregated, non-personally
                              identifiable database of information. </li><br />
                                <li> The User acknowledges that the User has to delete all data from the
                                Registered Device before making a Service Request. The AIS Mobile
                                Care Providers are not responsible for any data which the User
                                leaves on the Registered Device nor for transfer of any data
                                or information between the Registered Device and the AIS Mobile
                                Care Device. Such data left on the Registered Device or transfer
                                of any data or information is done entirely at the User’s risk.
                                In the event there is any inconvenience, delay, loss, misappropriation
                                of or damage to any data or information, the User agrees not
                                to hold the AIS Mobile Care Providers responsible or liable for
                              any such loss, delay or damage to the User. </li><br />
                                <li> The User agrees not to transfer the title (ownership) or possession/control
                                of the Registered Device during the Service term. In case the
                                User violates this condition, it shall be deemed that the User
                                has illegal intention to use the Service. In the event of a Service
                                Request for a Swap or a Replacement it is a condition that the
                                title to such original Registered Device will transfer to the
                                Provider as at the time the Service Request is accepted. In addition,
                                for a Service Request for a Swap, the Registered Device must
                                be returned to the Courier. The Provider reserves the right to
                                all civil and criminal claims in order to protect its property
                              interests. </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/*SERVICE REQUEST FOR SWAP OR REPLACEMENT*/}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">SERVICE REQUEST FOR SWAP OR REPLACEMENT</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <ol>
                                <li>The User requests a Swap or Replacement via the AIS Mobile Care Call
                                Center or the Website.
                            </li><br />
                                <li>The User agrees to provide the following information for verification
                                and confirmation of a User: (i) Phone Number; (ii) Registered
                                Device IMEI; (iii) first-last name; (iv) address; (v) Copy of
                                National ID or the Foreigner’s ID, as applicable, as proof of
                                identity issued by competent authority; and (vi) any other information,
                                including to arrange for a service request confirmation form,
                                as requested by the Company. The Company reserves the right to
                                reject to provide the Services if such User provides incorrect
                              or incomplete information. </li><br />
                                <li>The Registered Device may not change except for:
                              <ol>
                                    <p>(a) the change made following the User’s receipt of AIS Mobile
                                  Care Device obtained by a Swap or Replacement; or </p>
                                    <p>(b) the exchange of the User’s Registered Device in accordance
                                    with the conditions of the warranty provided by the OEM
                                    for a new Device which is identical to the Registered
                                    Device. The User must inform the AIS Mobile Care Providers
                                    of such change of the Registered Device immediately via:
                                    (i) the AIS Mobile Care Call Centre or the Website; or
                                    (ii) visiting the AIS Shop or Telewiz Shop to provide
                                    details of Device so provided by the OEM, and the User
                                    must provide proof of the exchange so that the Company
                                    can update its records with the IMEI of the new Device
                                    from which time the new Device is considered as a Registered
                                  Device. </p>
                                  </ol>
                                </li>
                                <p>In the event that the User fails to inform of the exchange of such
                                Registered Device pursuant to OEM warranty claim, the User will
                                not be able to make a Service Request for a Swap or Replacement
                                until the AIS Mobile Care Call Center has received proof of the
                              exchange. </p>
                                <li>When the User informs of its intention to make a Service Request
                                for a Swap or a Replacement, the IMEI of the original Registered
                              Device may be restricted. </li><br />
                                <li>While making a Service Request for a Swap or a Replacement, the Registered
                                Device cannot be the subject of another Service Request for a
                              Swap or a Replacement.</li>
                                <br />
                                <li>The User shall have an active Phone Number (which is not subject
                                to suspension) associated with the enrolled Phone Number on the
                                Provider’s network with no outstanding AIS Mobile Care Package
                                Fee, mobile service bill or any payment with the Provider. The
                                Registered Device must have been used in association with the
                              Phone Number. </li><br />
                                <li>The Company may ask the User about the condition of the Registered
                                Device for informational purposes to better facilitate the Services.
                            </li><br />
                                <li>The User shall receive only the AIS Mobile Care Device and no Device
                                Accessories. In case the battery of the Registered Device cannot
                                be removed from its body, the User shall receive the AIS Mobile
                              Care Device including the battery. </li><br />
                                <li>At the time of the Service Request for a Swap or a Replacement, the
                                Company will indicate to the User the Device it will provide
                              as the AIS Mobile Care Device. </li><br />
                                <li>The AIS Mobile Care Providers will be deemed to have discharged their
                                obligation to provide an AIS Mobile Care Device to the User as
                                long as the Company offers the User a Device to be provided as
                                an AIS Mobile Care Device in accordance with these Terms and
                                Conditions. In the event that the User does not wish to accept
                                the Device offered, the Company in its sole discretion, may offer
                                to the User the option to wait for up to 30 days for a Device
                                of identical specifications as the Registered Device to become
                              available (“Backorder”). <br /> If the User agrees to wait for
                              a Backorder, the delivery timeframes specified in clause 13 do
                              not apply until the Backorder is resolved in accordance with
                              clause 8.11(a) below. </li><br />
                                <li>The Company will contact the User before or upon the expiry of the
                                Backorder to inform the User as to the availability of a Device
                                to be provided as an AIS Mobile Care Device. Upon being contacted,
                                the User must:
                              <ol>
                                    <p> (a) accept the Device to be provided as an AIS Mobile Care
                                    Device - The time when the User accepts such AIS Mobile
                                    Care Device is the Acceptance Time for the purposes of
                                    clause 13 and such Device will become the Registered
                                  Device; or</p>
                                    <p>(b) decline the Device to be provided as an AIS Mobile Care
                                    Device - the Service Request will be cancelled by the
                                    Company and any Swap Fee or Replacement Fee previously
                                  paid will be refunded to the User. </p>
                                  </ol>
                                </li><br />
                                <li>If the Company offers and the User accepts a Device that does not
                                have the same specifications as the Registered Device to be provided
                                as an AIS Mobile Care Device, the Company will provide subsequent
                                AIS Mobile Care Devices based on the specifications of the User’s
                                original Registered Device and the date upon which the User accepts
                              the Device so offered will be the ‘Specification Date’. </li>
                                <br />
                                <li>If the Company reasonably believes that the User is using the Service
                                in a manner which is: (i) fraudulent, illegal or related to any
                                criminal activity; or (ii) intended to make a commercial gain,
                                the Company reserves the right to reject to provide the Services
                              to the User. </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/*TERMS FOR A SWAP*/}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">TERMS FOR A SWAP</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <ol>
                                <li>The User’s ability to make a Service Request for a Swap requires
                              the User to be compliant with these Terms and Conditions.</li><br />
                                <li>The User is not required to prove the Registered Device is defective
                              or damaged. </li><br />
                                <li>In case the Registered Device does not display any or the IMEI matching
                                the Registered Device, the User will not be able to request a
                                Swap but can request a Replacement upon payment of the Replacement
                                Fee at the percentage of the Registered Device Price as of Buying
                                Date as per the table specified in clause 12.5 depending upon
                                the time which has passed since the User commenced subscription
                                to the Program.
                            </li><br />
                                <li>The User must turn off any personal lock security feature (e.g. ‘Find
                                My iPhone’) on the Registered Device before returning such Registered
                                Device to the Courier. If the User making a Service Request for
                                a Swap (“Present Request”) does not turn off the personal lock
                                security feature the Company may: (i) cancel the Present Request;
                                or (ii) upon the User’s consent, treat the Present Request as
                                a Service Request for a Replacement and charge the User the Replacement
                                Fee at a percentage of 45% of the Registered Device Price as
                                of Buying Date provided that the User has not made a Service
                                Request for a Replacement within (i) 12 months of the Present
                                Request if the User is on a Monthly Package or (ii) the subscription
                              period if the User is a on a Fixed Term Package. </li><br />
                                <li>The User must deliver the Registered Device which can display the
                                IMEI matching the Registered Device to the Courier and agrees
                                that the title to the original Registered Device as well as all
                                rights and benefits, if any, such as a right under the warranty
                                of the OEM, shall be transferred to the Provider as from the
                                Acceptance Date. The User agrees that the delivery of the AIS
                                Mobile Care Device is sufficient consideration for the transfer
                                of title and other rights in the original Registered Device to
                              the Provider. </li><br />
                                <li>In case the Company finds later that the User has returned a Device
                                other than the Registered Device to the Courier, the User must
                                return the Registered Device to the Company within seven days
                                of the date of User’s receipt of the AIS Mobile Care Device from
                                Courier. If the User fails to do so within such period, the User
                              shall pay the Additional Fee. </li><br />
                                <li>If the Company finds later that the Registered Device that the User
                                tendered to the Courier or returned via the Courier has been
                                subject to Modification, then the Company will at its sole discretion:
                              <ol>
                                    <p>(a) where returned via the Courier, treat the Swap as a Replacement
                                  and charge the User the Additional Fee; or </p>
                                    <p>(b) where returned via the Courier, return to the User at
                                    the User’s cost the original Registered Device tendered
                                    to the Courier. The User must also return to the Company
                                    the AIS Mobile Care Device provided as a Swap. The Swap
                                    request will be considered cancelled. The Company will
                                    refund to the User the Swap Fee paid upon receipt of
                                    the AIS Mobile Care Device.
                                </p>
                                  </ol>
                                </li><br />
                                <li>Once the User gives the User's Device to the Company, either directly
                                or via the Courier, the Company will not be able to guarantee
                                the return of the User’s Device or the return of any of the User’s
                                Device Accessories given with the Device. In the event that the
                                User’s Device or Device Accessories are given to the Company,
                                and the Company is able to return the User’s Device or Device
                                Accessories, the User will bear all of the Company’s costs of
                              returning the User’s Device or Device Accessories. </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/*TERMS FOR A REPLACEMENT*/}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">TERMS FOR A REPLACEMENT</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <ol>
                                <li>The User's ability to make a Service Request for a Replacement requires
                              the User to be compliant with these Terms and Conditions.</li><br />
                                <li>The User may only make a Service Request for a Replacement if the
                                Registered Device is not in the User’s possession. The User is
                                not required to prove the Registered Device is defective, damaged,
                                lost or stolen. The Company may ask the User about the condition
                                of the User’s Registered Device or the reason why the User’s
                                Registered Device is not available for a Swap for informational
                              purposes to best facilitate the provision of Services. </li><br />
                                <li>The User shall receive the AIS Mobile Care Device with no Device
                                Accessories. The User agrees that the title to the original Registered
                                Device as well as all rights and benefits, if any, such as a
                                right under the warranty of the manufacturer, shall be transferred
                                to the Provider from the date when the User makes the Service
                                Request. The User agrees that the delivery of the AIS Mobile
                                Care Device is sufficient consideration for the transfer of title
                                and other rights in the original Registered Device to Provider.
                            </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/*LIMITS FOR THE SERVICES*/}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">LIMITS FOR THE SERVICES</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <p>The User is eligible to make Service Requests for a Swap or Replacement
                            as follows: </p>
                              <ol>
                                <li>For Fixed Term Package: – During the subscription period the User
                                may make either:
                              <ol>
                                    <p> (a) 2 Service Requests each for a Swap;</p>
                                    <p>(b) 1 Service Request for a Replacement and 1 Service Request
                                  for a Swap. </p>
                                  </ol>
                                </li><br />
                                <li>11.2 For Monthly Package: During a rolling 12 month period, the User
                                may make either:
                              <ol>
                                    <p>(a) 2 Service Requests each for a Swap;</p>
                                    <p>(b) 1 Service Request for a Replacement and 1 Service Request
                                  for a Swap. </p>
                                  </ol>
                                </li><br />
                              </ol>
                              <p> The table below illustrates the User’s eligibility to make Service Requests
                            for a Swap or Replacement for the Monthly Package:</p>
                              <table className="terms-conditions-table">
                                <tbody>
                                  <tr>
                                    <th style={{width: '30%'}}>Sequence Number of Request</th>
                                    <th>Limits for Monthly Package </th>
                                  </tr>
                                  <tr>
                                    <td>Request 1</td>
                                    <td>Anytime within 12 months from the enrolment date</td>
                                  </tr>
                                  <tr>
                                    <td>Request 2</td>
                                    <td>Anytime within 12 months from the enrolment date</td>
                                  </tr>
                                  <tr>
                                    <td>Request 3</td>
                                    <td>12 months after Acceptance Date of request 1</td>
                                  </tr>
                                  <tr>
                                    <td>Request 4</td>
                                    <td>12 months after Acceptance Date of request 2</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/*FEES*/}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">FEES</li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <ol>
                                <li>The User agrees to pay the relevant fee as set out in the schedule
                                below according to the Tier of the Device and the Package the
                                User has enrolled in (“AIS Mobile Care Package Fee”).
                            </li>
                                <br />
                                <table className="terms-conditions-table">
                                  <tbody>
                                    <tr>
                                      <th />
                                      <th colSpan={5}>Swap and Replacement Program</th>
                                    </tr>
                                    <tr>
                                    </tr><tr>
                                      <th />
                                      <th>1</th>
                                      <th>2</th>
                                      <th>3</th>
                                      <th>4</th>
                                      <th>5</th>
                                    </tr>
                                    <tr>
                                      <td>Monthly Package</td>
                                      <td>49</td>
                                      <td>89</td>
                                      <td>139</td>
                                      <td>189</td>
                                      <td>259</td>
                                    </tr>
                                    <tr>
                                      <td>Fixed Term Package</td>
                                      <td>529</td>
                                      <td>969</td>
                                      <td>1,499</td>
                                      <td>1,999</td>
                                      <td>2,499</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p>(Excluding VAT) </p><br />
                                <li>Post-paid User will be charged for the AIS Mobile Care Package Fee
                              via mobile service bills.<br /> Where the User’s Package is the
                              Monthly Package, if enrolment is made during the billing cycle,
                              the Monthly Fee will be charged to the actual days of Service
                              provision from the date the User receives an SMS confirming his
                              or her enrolment until the end of the plan period or until his
                              or her notice of termination of Monthly Package is given.
                            </li><br />
                                <li>As for pre-paid User, the AIS Mobile Care Package Fee will be charged
                              from top up immediately upon enrolment. <br /> In the case that
                              the User chooses the Monthly Package, the AIS Mobile Care Package
                              Fee will be charged every month.
                            </li><br />
                                <li>AIS Mobile Care Package Fee is not included in the mobile service
                              credit limit. </li><br />
                                <li>The User agrees to pay the Swap Fee or Replacement Fee at the rate
                                as fixed and notified by the Provider in an introduction letter
                                or other materials that the Provider provides to the User. Payment
                                will be made in cash directly to the Courier or by credit card
                              at the time the User makes a Swap or Replacement request.<br />                                                            Swap Fee is at 25 percentage of the Registered Device Price as
                              of Buying Date.<br />Replacement Fee is at the following percentage
                              of the Registered Device Price as of Buying Date:
                            </li><br />
                                <table className="terms-conditions-table">
                                  <tbody>
                                    <tr>
                                      <td>Year 1</td>
                                      <td>Year 2</td>
                                      <td>Year 3</td>
                                      <td>Year 4</td>
                                    </tr>
                                    <tr>
                                    </tr><tr>
                                      <td>45%</td>
                                      <td>40%</td>
                                      <td>30%</td>
                                      <td>30%</td>
                                    </tr>
                                  </tbody>
                                </table><br />
                                <p>The User acknowledges and agrees that Swap Fee and the Replacement
                                Fee will be non-refundable once the AIS Mobile Care Device has
                              been delivered to and accepted by the User. </p><br />
                                <li>The Company will directly collect from the User the Swap Fee or Replacement
                              Fee as applicable.</li><br />
                                <li>12.7 If the User pays the Swap Fee or Replacement Fee by credit card,
                                the User must use a credit card issued in the User’s name. If
                                the User uses a credit card issued in another person’s name,
                                the Company reserves the right to reject to provide the Services.
                            </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        {/*DELIVERY OF AIS MOBILE CARE DEVICE */}
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id="enrolling-header">
                            <h4 className="panel-title">
                              {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                  aria-controls="enrolling">*/}
                              <li className="headerTitle">DELIVERY OF AIS MOBILE CARE DEVICE </li>
                              {/*</a>*/}
                            </h4>
                          </div>
                          <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                            <div className="panel-body">
                              <ol>
                                <li>After the Company has verified the information from the User and
                                found it correct, the Company will deliver the AIS Mobile Care
                                Device to the User via the Courier within the period of time
                                specified but subject to exception on: (i) any extensions as
                                may be required for force majeure events or otherwise events
                                beyond the control of Company (including no stock available of
                                applicable AIS Mobile Care Devices); (ii) any additional verifications
                                relating to User's Swap or Replacement which the Company deems
                                necessary to perform; or (iii) any Backorder in accordance with
                              clauses 8.10 and 8.11. </li><br />
                                <li>The User agrees that the delivery period commences from the time
                                when the Company has received the User's information completely,
                                the User has agreed to pay the Swap Fee or the Replacement Fee
                                and the Company has accepted the Service Request (“Acceptance
                              Time”). </li><br />
                                <li>In case of delivery of the AIS Mobile Care Device in the Greater
                                Bangkok: If the Acceptance Time for a Service Request is on Monday
                                to Saturday before 2:00 p.m., the AIS Mobile Care Device will
                                be delivered by Courier to the User within six hours upon the
                                Acceptance Time.
                              <br />If the Acceptance Time for a Service Request is on Monday
                              to Saturday, after 2:00 p.m. until 9:59 p.m. or on Sunday or
                              public holiday, the AIS Mobile Care Device will delivered by
                              Courier to the User by 2:00 p.m. of the next Business Day. </li><br />
                                <li>In case of delivery of the AIS Mobile Care Device in the areas outside
                                the Bangkok Metropolitan Area, AIS Mobile Care Call Center will directly
                                advise the User of the delivery time of the AIS Mobile Care Device
                                as the delivery period depends on the distance of each province.
                            </li><br />
                                <table className="terms-conditions-table">
                                  <tbody>
                                    <tr>
                                      <th style={{width: '20%'}}>Delivery Location</th>
                                      <th style={{width: '40%'}}>Acceptance Time</th>
                                      <th>Delivery Date – Time#</th>
                                    </tr>
                                    <tr>
                                      <td>Bangkok Metropolitan Area</td>
                                      <td>Monday to Saturday, 08:00 a.m. to 02:00 p.m.</td>
                                      <td>Within 6 hours from the Acceptance Time</td>
                                    </tr>
                                    <tr>
                                      <td>Bangkok Metropolitan Area</td>
                                      <td>Monday to Saturday, 02:01 p.m. to 9:59 p.m.</td>
                                      <td>The next day before 02:00 p.m.<br /> If such day is Sunday
                                    or public holiday, delivery will be<br /> made by
                                    02:00 p.m. of the next Business Day.
                                  </td>
                                    </tr>
                                    <tr>
                                      <td>Bangkok Metropolitan Area</td>
                                      <td>Sunday</td>
                                      <td>The next day before 02:00 p.m.<br /> If such day is a
                                    public holiday,<br /> delivery will be made by 02:00
                                    p.m. of the next Business Day.
                                  </td>
                                    </tr>
                                    <tr>
                                      <td>Other provinces </td>
                                      <td>Everyday</td>
                                      <td>Directly advise the User of the delivery time.</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p># Subject to the exceptions specified in clause 13.1.</p>
                                <br />
                                <li>The Courier will deliver the AIS Mobile Care Device to the User only,
                                provided that the User is required to present his or her National
                                ID Card or Foreigner’s ID, as the case may be, or any other evidence
                                issued by government agency proving the User’s identity. Before
                                or at the time of delivery, the User agrees to sign on any service
                                request confirmation form or other documents as requested by
                              the Company. </li><br />
                                <li>The Courier will deliver the AIS Mobile Care Device to the User's
                                residential address as registered with the Provider for the Services
                                only. The Courier may also deliver the AIS Mobile Care Device
                                at other premises as specified by the User on the enrolment date
                                or the premises as specified during the Service Request for a
                                Swap or Replacement, provided that such premises shall not be
                                a shopping centre, department store, public transportation terminal,
                                car park, market, public park or other premises similar to these
                              premises. <br /> The Company reserves its right not to deliver
                              to the address specified by the User for delivery of the AIS
                              Mobile Care Device to the User as appropriate if the address
                              requested is not the User’s registered residential address.
                            </li><br />
                                <li>The Company agrees not to charge the User for the delivery of the
                                AIS Mobile Care Device for the first and the second attempts
                                of delivery if: (i) there is no intended recipient present; or
                                (ii) the recipient is present but the User's identity cannot
                                be proved; or (iii) the User rejects delivery of the AIS Mobile
                                Care Device in accordance with clause 13.8. For the third or
                                subsequent attempt of delivery, the User agrees to pay to the
                                Company for the delivery of the AIS Mobile Care Device in cash
                                via the Courier or by credit card to the AIS Mobile Call Center
                              at the rate of THB500 for each additional delivery.</li><br />
                                <li>The User can only reject delivery of the AIS Mobile Care Device if
                                the User is not satisfied by indicating to the Courier on the
                                spot and not accepting the delivered AIS Mobile Care Device.
                                The Company will then deliver another AIS Mobile Care Device
                                for the User. Such return of the AIS Mobile Care Device will
                                not count towards User’s Section XI Service Limits and no Swap
                              Fee or Replacement Fees will be due. <br /> For the User who travels
                              outside of Thailand and makes a Service Request for either a
                              Swap or a Replacement and requests the Company to deliver the
                              AIS Mobile Care Device to the User in a destination outside of
                              Thailand, such request shall be granted or rejected by the Company
                              in its sole discretion and deemed to be a Service Request for
                              a Replacement, subject to the User’s Limit set out in clause
                              11. In the event the Service Request is accepted, the User will
                              pay the actual cost of delivery and the Replacement Fee at the
                              rate that the Company has informed the User at the time of requesting
                              the Replacement.<br /> Upon such user returning to Thailand and
                              if the User wishes to return the original Registered Device to
                              the Company, then such Service Request will be treated as a Swap
                              and the Company will refund an amount equivalent to the difference
                              between Swap Fee and Replacement Fee to the User after the User
                              returns Registered Device to the Courier within 30 days of the
                              User's return of the Registered Device to the Courier. </li>
                              </ol>
                            </div>
                          </div>
                          {/*WARRANTY FOR AIS MOBILE CARE DEVICE*/}
                          <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="enrolling-header">
                              <h4 className="panel-title">
                                {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                      aria-controls="enrolling">*/}
                                <li className="headerTitle">WARRANTY FOR AIS MOBILE CARE DEVICE</li>
                                {/*</a>*/}
                              </h4>
                            </div>
                            <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                              <div className="panel-body">
                                <ol>
                                  <li>The User is entitled to a warranty for the AIS Mobile Care Device
                                for manufacturer malfunctions and manufacturer defects. </li><br />
                                  <li>14.2 The User is not entitled to make warranty claims if the
                                  AIS Mobile Care Device: (i) does not have manufacturer malfunctions
                                  or manufacturer defects; (ii) has physical damage, cracked
                                  screens, or water damage; (iii) has systems damage, or application
                                  malfunctions; or (iv) has non-manufacturer malfunctions or
                                  defects. The User is also not entitled to make warranty claims
                                  for damaged Device Accessories. All of the above warranty
                                claims are invalid warranty claims. </li><br />
                                  <li>Where applicable, the User is entitled to make a warranty claim
                                  on the AIS Mobile Care Device after the lapse of 15 days
                                  from the date the User has previously requested for the warranty
                                claim. </li><br />
                                  <li>The warranty period for the AIS Mobile Care Device:
                                <ol>
                                      <p>(a) for an Apple branded Device being iPhone 6s or 6s
                                      Plus (or later models) within six months of the Buying
                                      Date, the warranty period will be one year from the
                                    Buying Date; </p>
                                      <p>(b) for an Apple branded Device being iPhone 6s or 6s
                                      Plus (or later models) at any time after six months
                                      from the Buying Date, the warranty period will be
                                    180 days from the Acceptance Date; </p>
                                      <p>(c) for a Tier 1 Device, Tier 2 Device, Tier 3 Device
                                      Tier 4 Device or a Tier 5 Device not being an Apple
                                      branded Device at any time, the warranty period will
                                    be 180 days from the Acceptance Date; or </p>
                                      <p>(d) for the Device provided pursuant to clause 8.12 above,
                                      the warranty period will be 180 days from the Specification
                                    Date. </p>
                                    </ol>
                                  </li><br />
                                  <li>The User acknowledges that if it is determined that the User
                                  has made a warranty claim under clause 14.1 above with respect
                                  to the AIS Mobile Care Device, this will not count towards
                                  User’s Limits under the Fixed Package or Monthly Package
                                  and no Swap or Replacement Fees will be due. The User acknowledges
                                  that if it is determined that the User has made an invalid
                                  warranty claim under clause 14.2 above or the returned Device
                                  has no malfunction or defect, either the Device will be returned
                                  to the User, or the User will be charged a Swap Fee and such
                                  Swap will count towards User’s Limits. The User acknowledges
                                  that the User’s future warranty claims may be rejected if
                                  the User has previously made any invalid warranty claims
                                  under clause 14.2 above. In any cases, the Device provided
                                  under a warranty claim will be based on the specifications
                                of the AIS Mobile Care Device. </li><br />
                                  <li>The User may exercise the right to make a warranty claim for
                                  the AIS Mobile Care Device if the User has no outstanding
                                  Swap Fee, Replacement Fee and payment for the AIS Mobile
                                  Care Package Fee via the AIS Mobile Care Call Center or the
                                  Website. The Company will deliver the AIS Mobile Care Device
                                  to the User in the same manner as a Swap or Replacement.
                              </li>
                                </ol>
                              </div>
                            </div>
                            {/*TERMINATION BY USER*/}
                            <div className="panel panel-default">
                              <div className="panel-heading" role="tab" id="enrolling-header">
                                <h4 className="panel-title">
                                  {/*<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false"
                                                          aria-controls="enrolling">*/}
                                  <li className="headerTitle">TERMINATION BY USER</li>
                                  {/*</a>*/}
                                </h4>
                              </div>
                              <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                                <div className="panel-body">
                                  <ol>
                                    <li>The User on a Monthly Package is entitled to terminate the
                                    Service by giving notice of his or her intention at least
                                    30 days in advance via AIS Mobile Care Call Center. Once
                                    a User has so terminated, the User cannot re-apply for
                                    the Service with the same Registered Device with the
                                  corresponding matching IMEI. </li><br />
                                    <li>Despite the termination of the Services, the User is still
                                    obliged to pay the Fee incurred before the date of termination
                                  in full even if the Service has been terminated. </li>
                                  </ol>
                                </div>
                              </div>
                              {/*REJECTION OF SERVICE PROVISION */}
                              <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="enrolling-header">
                                  <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false" aria-controls="enrolling">
                                      <li className="headings">REJECTION OF SERVICE PROVISION </li>
                                    </a>
                                  </h4>
                                </div>
                                <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                                  <div className="panel-body">
                                    <p>The AIS Mobile Care Providers are entitled to reject to provide
                                  the Services promptly for the following reasons: </p>
                                    <ol>
                                      <li>When it appears that the User's name is shown in the
                                      system as having outstanding payment for the AIS
                                      Mobile Care Package Fee and/or mobile service bill
                                      of the Provider. In the case of pre-paid Users, if
                                      the top up is not sufficient for Fixed Fee or Monthly
                                    Fees within 15 days when it falls due.</li><br />
                                      <li>When it appears that the User's Phone Number was or is
                                      suspended due to one of the reasons in Clause XVII.
                                  </li><br />
                                      <li>When it appears that the User's Phone number was or is
                                      terminated by the Provider due to one of the reasons
                                    in Clause XVII. </li><br />
                                      <li>When the Company reasonably believes that the User has:
                                      (i) illegal intention to enroll for or use the Services;
                                      (ii) intention to use the Services in a manner which
                                      is fraudulent or related to any criminal activity;
                                      or (iii) intention to make a commercial gain from
                                    the Services. </li><br />
                                      <li>When the User's qualifications or the use of Services
                                      are not in compliance with these Terms and Conditions.
                                  </li><br />
                                      <li>When one of the following information given by the User:
                                      (i) Phone Number; (ii) Registered Device IMEI; (iii)
                                      first-last name; (iv) address; (v) Copy of National
                                      ID or the Foreigner’s ID, as applicable, as proof
                                      of identity issued by competent authority; or (vi)
                                      any other information, including service request
                                      confirmation forms, as requested by the Company,
                                      is either incorrect according to the AIS Mobile Care
                                      Providers’ records or is not provided to the Company’s
                                    satisfaction. </li><br />
                                      <li>When the Company found, upon verification, that IMEI
                                      of the Registered Device does not correspond to IMEI
                                    recorded by the Provider. </li><br />
                                      <li>When the Registered Device has not been active with the
                                    associated Phone Number. </li><br />
                                      <li>The Company reasonably believes that the User has transferred
                                      the title to the Registered Device or Phone Number
                                    to another person except for legacy. </li>
                                    </ol>
                                  </div>
                                </div>
                                {/*TERMINATION	*/}
                                <div className="panel panel-default">
                                  <div className="panel-heading" role="tab" id="enrolling-header">
                                    <h4 className="panel-title">
                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false" aria-controls="enrolling">
                                        <li className="headings">TERMINATION</li>
                                      </a>
                                    </h4>
                                  </div>
                                  <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                                    <div className="panel-body">
                                      <p>The AIS Mobile Care Providers are entitled to terminate
                                    the Services in the following events: </p>
                                      <ol>
                                        <li>The User's death, bankruptcy, or inability to pay debts
                                      as they fall due; </li><br />
                                        <li>The User defaults on payment for AIS Mobile Care Package
                                        Fee by due date, or in the case of pre-paid Users,
                                        if the top up is not sufficient for Fixed Fees or
                                      Monthly Fees; </li><br />
                                        <li>The User defaults on payment for mobile service bill
                                        by due date as specified in a bill for two consecutive
                                        times;
                                    </li><br />
                                        <li>The Company has reasonable ground to believe that the
                                        User uses the Services or mobile service in a fraudulent
                                        manner, or in a way that may adversely impact the
                                        AIS Mobile Care Providers’ reputation, or illegally
                                        uses the Services or mobile service or breaches these
                                        Terms and Conditions or any other terms of the AIS
                                        Mobile Care Providers or uses the Services for commercial
                                      gain; </li><br />
                                        <li>The AIS Mobile Care Providers cannot provide the Services
                                        for the cause beyond the AIS Mobile Care Providers’
                                      control; </li><br />
                                        <li>The Services are terminated by provisions of law; </li><br />
                                        <li>The User has breached any of these Terms and Conditions;
                                    </li><br />
                                        <li>The User has provided incorrect, false, or incomplete
                                      information; </li><br />
                                        <li>The User has ported their Phone Number from the Provider’s
                                        network or terminated their Phone Number;
                                    </li><br />
                                        <li>The User’s Phone Number has been suspended for more than
                                      60 days as set out in clause 7.5 above; </li><br />
                                        <li>The User’s Phone Number has been terminated by the User
                                      or the Provider; </li><br />
                                        <li>The Company reasonably believes that the User has transferred
                                        title to the Registered Device or Phone Number to
                                      another person; </li><br />
                                        <li>The Company finds later that the Registered Device has
                                      been subject to Modification; </li><br />
                                        <li>The User fails to return the AIS Mobile Care Device in
                                      contravention of clauses 9.5 and 9.6; </li><br />
                                        <li>The User fails to provide any additional information
                                        reasonably requested by the Company including in
                                        the form of a signed declaration or acknowledgment;
                                    </li><br />
                                        <li>If the AIS Mobile Care Providers are required to comply
                                        with an order, instruction or request of regulatory
                                        authority, government authority or any other competent
                                        authority which resulted in the AIS Mobile Care Providers
                                      having to stop or terminate the Services; or </li><br />
                                        <li>For any other reason at the AIS Mobile Care Providers’
                                      discretion. </li>
                                      </ol>
                                    </div>
                                  </div>
                                  {/*OTHERS*/}
                                  <div className="panel panel-default">
                                    <div className="panel-heading" role="tab" id="enrolling-header">
                                      <h4 className="panel-title">
                                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false" aria-controls="enrolling">
                                          <li className="headings">OTHERS</li>
                                        </a>
                                      </h4>
                                    </div>
                                    <div id="enrolling" className="panel-collapse" role="tabpanel" aria-labelledby="enrolling-header">
                                      <div className="panel-body">
                                        <ol>
                                          <li>The Provider reserves its right to change and amend
                                          these Terms and Conditions, or withdraw the Services
                                          by giving the User a prior notice through this
                                          Website which the User will be deemed to accept.
                                      </li><br />
                                          <li>Any provision of these Terms and Conditions which
                                          becomes illegal or unenforceable shall be deemed
                                          void only on the illegal or unenforceable part
                                          without impact on other provisions which shall
                                        remain valid. </li><br />
                                          <li>If the Services or service similar to the Services
                                          where the Company is the service provider have
                                          been terminated for a Registered Device by the
                                          AIS Mobile Care Providers: (i) the Services cannot
                                          be reactivated for that Registered Device; and
                                          (ii) the User may not be allowed to enroll for
                                        the Services with any other Device. </li><br />
                                          <li>If Provider introduces new services and the User
                                          is on a Monthly Package, the User may be entitled
                                          to subscribe to such services (at an additional
                                          cost) by contacting the AIS Mobile Care Call
                                          Center. The User shall pay the service charges
                                          subject to the new service accordingly. The Services
                                          in general are subject to events beyond Provider’s
                                          reasonable control. If such events occur, the
                                          Services may be temporarily suspended and any
                                          pending Service Requests may be suspended by
                                          the Provider until the events do not exist provided
                                          that the Provider shall notify the User of such
                                        suspension in advance. </li><br />
                                          <li>Apart from these Terms and Conditions, the AIS Mobile
                                          Care Providers expressly and to the fullest extent
                                          permitted by law disclaim all warranties of any
                                          kind, whether: (i) express; (ii) implied; or
                                          (iii) statutory, including, but not limited to
                                          the implied warranties of merchantability and
                                          fitness for a particular purpose. The AIS Mobile
                                          Care Providers will, to the fullest extent permitted
                                          by law, not be responsible for any loss or damage
                                          caused to User because of the actions of any
                                          employee or any person or representative of the
                                          AIS Mobile Care Providers who acts beyond their
                                          authority. The AIS Mobile Care Providers shall,
                                          to the fullest extent permitted by law, not be
                                          liable for any direct or indirect loss or damage
                                          caused to User in respect of any matter howsoever
                                          arising in connection with the provision or User’s
                                          use of the Services. User agrees to defend, indemnify
                                          and hold faultless the AIS Mobile Care Providers,
                                          their associates and their directors, officers,
                                          successors and assigns, from and against any
                                          and all liabilities, damages, losses, costs and
                                          expenses caused by or arising out of User’s use
                                        of the Services. </li><br />
                                          <li>Without prejudice to the generality of the preceding
                                          statements, under no circumstances will the AIS
                                          Mobile Care Providers be liable for any indirect,
                                          consequential, special damages arising out of
                                          or in connection with the provision or User’s
                                          use of the Services. The AIS Mobile Care Providers’
                                          aggregate liability to the User (whether based
                                          in contract, negligence, strict liability, statute
                                          or other theory of liability) will not exceed:
                                          (i) if the User are paying a Monthly Fee, the
                                          Monthly Fees paid by the User during the two
                                          months prior to the event giving rise to the
                                          claim; or (ii) if the User is on a Fixed Term
                                          Package, one sixth of the User’s AIS Mobile Care
                                        Package Fee. </li><br />
                                          <li>The Agreement will be governed by and construed in
                                          accordance with the laws of the Kingdom of Thailand.
                                          Most of User’s concerns or questions about the
                                          Services can be addressed via the AIS Mobile
                                          Care Call Center or the Website. For any dispute
                                          with the AIS Mobile Care Providers, User agrees
                                          to first contact Provider and attempt to resolve
                                        the dispute with Provider informally. </li><br />
                                          <li> The Provider may from time to time offer promotions
                                          relating to all or any of the Services. Any such
                                          promotions shall be governed by the terms and
                                          conditions attached thereto by the Provider,
                                          and by these Terms to the extent that the promotion’s
                                          terms and conditions are silent. In the event
                                          of any conflicts between a promotion's terms
                                          and conditions and these Terms, the promotion’s
                                        terms and conditions shall prevail.</li>
                                        </ol>
                                      </div>
                                    </div>
                                    {/**/}
                                  </div>
                                  <ol>
                                  </ol></div>
                              </div>
                            </div></div></div></div></ol></div></div></center>
            </div>
          </div>
          <div className="col-xs-12 col-sm-2">
            <div className="text">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>

  )
}