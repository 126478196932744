'use strict'

String.prototype.replaceAt = function (index, char) {
    return this.substr(0, index) + char + this.substr(index + char.length);
}

function maskString(str) {
    const REPLACEMENT_CHAR = "*";
    const MASK_END = str.length - 1;
    const MASK_START = 1;
    const MASK_THRESHOLD = 3;
    let strLen = str.length;
    let maskedCharacter = 0;
    for (let i = MASK_START; i < MASK_END; i++) {
        if (Math.random() > 0.5 && str.charAt(i) !== '.' && str.charAt(i) !== '@') {
            str = str.replaceAt(i, REPLACEMENT_CHAR);
            maskedCharacter++;
        }
    }
    if (maskedCharacter < (strLen / MASK_THRESHOLD)) {
        return maskString(str);
    } else {
        return str;
    }
}

module.exports = maskString