import _ from "lodash";

import * as actionTypes from "./actionTypes";
import CONSTANTS from "../../utils/Constants";
import { horizon_axios_sls_instance } from "../../utils/axios";
import EVENTLIST from "./../../mixpanel/Eventlist";
import { setInputState, updateShippingOrderInit } from "./doFindAgreements";
import MESSAGE_CONSTANTS from "../../config/journey-messages";

export function shippingmethodsV2Init({ skipScheduleConfirmation = false, nochnageForm, isWBKO = false, isWBKOReschedule = false }) {
  return async (dispatch, getStore) => {
    dispatch({
      type: actionTypes.SHIPPING_METHODS_V2_INIT,
      loading: true
    });
    if (skipScheduleConfirmation) {
      dispatch({
        type: actionTypes.SET_GENERIC_PROGRESS_BAR,
        progressValue: 10,
        trickleStep: 10,
        trickleInterval: 500,
        label: MESSAGE_CONSTANTS.SHIPPING_DETAILS_VERIFY
      });
    }

    let {
      clientData: { CacheId: cacheId },
      warehouseId,
      serviceOrderId,
      serviceRequestId,
      lastShippedRequest,
      standardizedAddress,
      determineIncidentResponse: { ShippingAddress: shippingAddress },
      addressList: {
        AddressResult = {},
        AddressResult: {
          StandardisedFromAddressId = "",
          CorrectedAddress = {}
        } = {}
      } = {}
    } = getStore().customerData;


    let correctedAddress =
      !_.isEmpty(AddressResult) && !_.isEmpty(CorrectedAddress)
        ? CorrectedAddress
        : !_.isEmpty(standardizedAddress)
          ? standardizedAddress
          : shippingAddress
    delete correctedAddress["IsOverridden"];

    if (!correctedAddress.AddressId) {
      correctedAddress["AddressId"] = StandardisedFromAddressId;
    }

    let LookupShippingMethodsParameters = {
      ServiceOrderId: serviceOrderId,
      ServiceRequestId: serviceRequestId || (lastShippedRequest && lastShippedRequest.ServiceRequestId),
      SessionId: cacheId,
      Warehouse: warehouseId || "SGP",
      CustomerType: "Retail",
      ReceiverAddress: correctedAddress,
      IsWBKO: isWBKO
    };


    const response = await horizon_axios_sls_instance
      .post(
        "/api/physicalassetfulfillment/v2/shippingmethods",
        JSON.stringify({ LookupShippingMethodsParameters })
      ).catch(error => shippingMethodsV2Failure(dispatch, error));

    // console.log("before await");
    await dispatch({
      type: actionTypes.SHIPPING_METHODS_V2_SUCCESS,
      payload: response.data.ShippingMethodResponse.ShippingMethods
    });
    // console.log("after await");
    shippingMethodsV2Success(dispatch, getStore, response, skipScheduleConfirmation, nochnageForm, isWBKOReschedule);
  }
}


function shippingMethodsV2Success(dispatch, getStore, response, skipScheduleConfirmation, nochnageForm, isWBKOReschedule) {
  let {
    clientData: { CacheId: cacheId },
    backOrderCase,
    customerName,
    isEnrolledAddress,
    shippingOrderResult,
    shippingMethodsResult,
    isWbkoSelected,
    WbkoChangedAddressString, keepOrconfirmCount
  } = getStore().customerData;

  //console.log("inside success function");

  if (!backOrderCase) {
    // show delivery schedule if not backorder
    if (!skipScheduleConfirmation) { //normal case
      dispatch(
        setInputState({
          userInput: "",
          source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_FOR_PAYMENT,
          value: null
        })
      );
    } else {
      // if ((isWbkoSelected && keepOrconfirmCount == 0 && WbkoChangedAddressString) || nochnageForm) {
      if ((isWbkoSelected && keepOrconfirmCount == 0 && isWBKOReschedule)) {
        dispatch(
          setInputState({
            userInput: "",
            source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION,
            value: MESSAGE_CONSTANTS.WBKO_RESCHEDULE.ProceedButton
          })
        );
      } else if ((isWbkoSelected && keepOrconfirmCount == 0 && WbkoChangedAddressString) || nochnageForm) {
        //do nothing
      } else {
        dispatch(
          setInputState({
            userInput: "",
            source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION,
            value: isWbkoSelected ? MESSAGE_CONSTANTS.WBKO_CHANGE : "Change"
          })
        );
      }
    }
    dispatch({
      type: actionTypes.SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: MESSAGE_CONSTANTS.SHIPPING_DETAILS_VERIFY
    });
  } else {
    let deliveryWindow = "",
      deliveryDate = "";
    if (isWbkoSelected) {
      dispatch({
        type: actionTypes.SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.SHIPPING_DETAILS_VERIFY
      });
    }
    if (!isWbkoSelected) {
      dispatch(
        updateShippingOrderInit(
          cacheId,
          //{ShippingMethodType, EstimatedDeliveryDate},
          shippingMethodsResult,
          shippingOrderResult,
          deliveryWindow,
          deliveryDate,
          customerName,
          isEnrolledAddress
        )
      );
    }

  }
}

function shippingMethodsV2Failure(dispatch, error) {
  dispatch({
    type: actionTypes.SHIPPING_METHODS_V2_FAILURE,
    error
  });
  dispatch(
    setInputState({
      userInput: "",
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: EVENTLIST.GET_SHIPPING_METHOD_V2_FAILURE,
      error: {
        APIName: CONSTANTS.API_NAMES.SHIPPING_METHODS_V2,
        APIResponse: error.response && error.response.data
      }
    })
  );
}