import gql from 'graphql-tag';

export default gql`
 mutation createEncryptedChatRequest($input:CreateChatRequestInput!){
	createEncryptedChatRequest(input:$input){
		requestId
		visitorId
		taskId
		visitorName
		interactionId
		mdn
		customerId
		skillId
		languageCode
		clientName
		chatReason
		requestStatus
		requestType
		engagementType
		chatWaitTime
		startTimestamp
		caseNumber
		browserAgent
		wrapUpCode
		requestChannel
  }
}`;

