import React, { Component } from "react";
import classNames from 'classnames';

import CONSTANTS from '../../utils/Constants';

import restartIcon from "../../images/mobile/img-restart_50.png";
import DOMPurify from "dompurify";

class TriageConfirmBox extends Component {
  constructor(props) {
    super(props);

    this.state = {      
      btnDisable: false,
      highlightedClass: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }


  handleKey(event) {
    if (event.keyCode === 13 && !event.shiftKey && event.target.value) {
      this.handleSubmit(event);
    }
  }

  handleSubmit(e) {
    this.setState({
        btnDisable: true,
        highlightedClass: "ais_device-select-button-highlighted",
    });
    this.props.updateJourneyList(CONSTANTS.CONFIRM_MALFUNCTION_DEVICE_MEASURES);
  }

  render() {
    let btnclass = classNames({
      "ais_device-select-button": true,
      "ais_selected": this.state.highlightedClass,
    });
   
    return (
      <div>       
        <div className="ais_current-form-device-div">
          <div className="ais_header-txt-div">
            <label className="ais_current-mobile-device-label" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.text.HEADER) }}></label>
            <div>
              <img
                alt="enroll-address-icn"
                className="ais_confirm-delivery-icn"
                src={restartIcon}
              />
            </div>
            <div className="ais_triage-device-desc" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.text.CONTENT)}}></div>
            <button
              disabled={this.state.btnDisable}
              onClick={this.handleSubmit}
              className={btnclass}
            >
              {this.props.data.text.BUTTON}
            </button>
          </div>
        </div>
        <br />
      </div>
    );
  }
}


export default TriageConfirmBox;