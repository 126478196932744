import React, {Fragment} from "react";
import { Subscription } from "react-apollo";
// import MessageList from './../WebChatWindow/MessageList';
import SubscribeToEndChat from './../../../graphql/subscription/SubscribeToEndChat';

const GetEndChat = ({ requestId, endChat, source, typedText }) => {
    // console.log("Request  ID: ",requestId);

    return (
       <Subscription
           subscription={SubscribeToEndChat}
           variables={{ requestId }}
           onSubscriptionData={(options) => {
            console.log("SubscribeToEndChat DATA:", options.subscriptionData)
          }}
       >
 
       {({ data, loading }) => {
        //    console.log("GetEndCHat data:", data);
        //    console.log(source);           
            if(data) {                
                endChat(source, typedText, data.onUpdateChatRequest);
            }
       return (
           
        <Fragment>           
            
        </Fragment>
       )}}
       </Subscription>
)};

export default GetEndChat;