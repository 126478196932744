import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import CONSTANTS from "../../utils/Constants";
import { bindActionCreators } from 'redux'
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import {savePayload,submitEnquiryDetails} from "../../actions/Dashboard/doFindAgreements";
import classNames from "classnames";

class CancelationReason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDiv: null
    };
  }
  handleClick = (event, data) => {
    try {
      document.querySelectorAll('.ais_cancel_reason_button').forEach(elem => {
        elem.disabled = true;
      });
    } catch (err) {
      console.log("error in CancelDeliveryEnquiry button click", err)
    }

    let source;
    let {
      updateJourneyList, selectedEnquiryOption
    } = this.props;
    this.props.savePayload(CONSTANTS.WBKO_CANCELLATION_REASON, data);
    if (data.name === "OTHER") {
      this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY, data.text);
    } else {
      let desc = `${MESSAGE_CONSTANTS.WBKO_INQ_DESC} ${data.text}`;
      this.props.submitEnquiryDetails(desc, selectedEnquiryOption, desc, CONSTANTS.ENQUIRY_SUB_REASON.CANCEL_SR);
    }
    
    if (!this.state.selectedDiv) {
      this.setState({
        selectedDiv: data.text
      });
      updateJourneyList(source, name);
    }
  };
  _renderOptions(option, i) {
    let btnClasses = classNames({
      "ais_cancel_reason_button": true,
      disabled: this.state.selectedDiv,
      "ais_options-replacement-selected-div":
        this.state.selectedDiv === option.text,
      "ais_options-replacement-div": this.state.selectedDiv !== option.text
    });
    return (
      <button
        name={option.text}
        key={option.text}
        disabled={this.state.selectedDiv}
        className={btnClasses}
        onClick={(event) => this.handleClick(event, option)}
        style={{
          display: "block",
          position: "relative"
        }}
      >
        <label
          className={
            this.state.selectedDiv === option.text
              ? "ais_options-selected-label"
              : "ais_options-label"
          }
          style={{
            position: "absolute",
            float: "none",
            top: "unset",
            left: "unset"
          }}
        >
          {option.text}
        </label>
      </button>
    );
  }
  render() {
    let { serviceRequestNumber, shippingStatus } = this.props;
    return (
      <div>
        <div className="">
          <div className="ais_header-txt-div">
            <div className = 'WbkoCancellationLandngHeader'>
              {this.props.data.title}
            </div>
            {this.props.data &&
              this.props.data.buttons.map((e, i) => this._renderOptions(e, i))}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { selectedEnquiryOption} = state.customerData;

  return {
    selectedEnquiryOption,
  }
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ savePayload, submitEnquiryDetails }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(CancelationReason);