import React from "react";
import config from '../../config/config';
import CONSTANTS from '../../utils/Constants';
import DOMPurify from "dompurify";

class FAQPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button"
        }
        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleYesClick = this.handleYesClick.bind(this);
    }

    handleYesClick() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.dataprops.source, "Yes")
    }


    handleNoClick() {
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.dataprops.source, "No")
    }

    render() {
        let { dataprops } = this.props;
        return (
            <div>
                {dataprops.source === CONSTANTS.FAQ_PAGE ?
                    <div className="ais_current-form-device-div FAQ_page holds-the-iframe"> <iframe
                        src="https://kb-prod.sea.soluto.asurion.com/helpie-faq-page/?domain=PROD"
                        title="Screen Repair Service"
                        width="100%"
                        style={{ height: "100vh" }}
                        frameBorder="0"
                    /> <div className="ais_header-faq">
                            <label className="ais_current-mobile-device-label faq"
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.CONTENT) }}></label>
                            <div className="ais_outer-align-button-top-bottom">
                                <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={this.handleNoClick} >{this.props.data.BUTTONS[0]}</button>
                                <div className="ais_device-divider"></div>
                                <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.handleYesClick} >{this.props.data.BUTTONS[1]}</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="ais_current-form-device-div ExCust">
                        <div className="ais_header-txt-div">
                            <label className="ais_current-mobile-device-label"
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.CONTENT) }}></label>
                            <div className="ais_outer-align-button-top-bottom">
                                <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={this.handleNoClick} >{this.props.data.BUTTONS[0]}</button>
                                <div className="ais_device-divider"></div>
                                <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.handleYesClick} >{this.props.data.BUTTONS[1]}</button>
                            </div>
                        </div> </div>}
                <div>
                </div>
            </div>
        )
    }
}
export default FAQPage;

