import React from "react";

import msgIcon from "../../images/mobile/img-message_50.png";

class DropMessage extends React.Component {

  constructor(props) {

    super(props)
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(value) {
    // console.log("Drop message");
  }

  render() {
    return (
      <div className="ais_confirm-details-div">
        <div className="ais_header-txt-div">
          <label className="ais_current-mobile-device-label">DROP A MESSAGE</label>
          <div>  <img alt="message-icn" className="ais_confirm-delivery-icn" src={msgIcon} /> </div>
          <label className="ais_current-form-device-desc dropMessage">Type your email and send us a message and we’ll get back to you.</label>
          <input className="ais_current-form-drop-Message-Input" type="text" onChange={(e) => this.setName(e)} placeholder="Your email address" />          
          <textarea className="ais_current-form-drop-Message-Input ais_textarea" placeholder="Your message here..." />
          <div className="ais_outer-align-button">
            <button className="ais_device-select-button ais_proceed" onClick={(e) => this.handleClick(e)} >Send my Message</button>
          </div>
        </div>
      </div>
    )
  }
}
export default DropMessage;

