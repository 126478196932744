import React, { Component } from 'react';
import { connect } from 'react-redux';

//import _ from 'lodash';
import './AjaxLoader.css';

import ReactProgressBar from './ReactProgressBar';

class ProgressBarContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Label: this.props.label || "PROCESSING...",
      //completeLabel: this.props.completeLabel || "DONE!",
     // failureLabel: this.props.failureLabel || "ERROR OCCURED!",
      trickle: this.props.trickle || false,
      progressValue: this.props.progressValue || 0,
      trickleStep: this.props.trickleStep || 10,
      // shouldHideAfterCompletion: this.props.shouldHideAfterCompletion || false,
      trickleInterval: this.props.trickleInterval || 1500,
    }

    this.trickleProgress = this.trickleProgress.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.progressValue !== nextProps.progressValue) {
      this.setState({
        progressValue: nextProps.progressValue,
        trickleStep: nextProps.trickleStep,
        trickleInterval: nextProps.trickleInterval,
        Label: nextProps.label
      })
    }
  }

  componentDidMount() {    
    let { trickle, trickleInterval } = this.state;
    if (trickle) {
      let intervaledTrickleProgress = setInterval(this.trickleProgress, trickleInterval)
      this.setState({
        intervaledTrickleProgress
      })
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.progressValue !== prevProps.progressValue) {
      let { trickle, trickleInterval } = this.state;
      if (trickle && !this.state.intervaledTrickleProgress) {
        let intervaledTrickleProgress = setInterval(this.trickleProgress, trickleInterval)
        this.setState({
          intervaledTrickleProgress
        })
      }
    }

  }

  trickleProgress() {
    let progressValue= this.state.progressValue + this.state.trickleStep
    if (progressValue < 90) {
      this.setState({
        progressValue: progressValue
      });
    } else {
      clearInterval(this.state.intervaledTrickleProgress);
      this.setState({
        intervaledTrickleProgress: null
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervaledTrickleProgress);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.progressValue > 100 || nextProps.progressValue > 100) {
      clearInterval(this.state.intervaledTrickleProgress);
      this.setState({
        intervaledTrickleProgress: null
      })
      return false;
    }
    return true;
  }

  render() {
    let { progressValue, Label } = this.state;
    //console.log(progressValue, Label);
    return  <ReactProgressBar percent={progressValue} Label={Label} />
  }
}

const mapStateToProps = state => {
  const { trickleStep, trickleInterval, label } = state.customerData;
  return {
    trickleStep,
    trickleInterval,
    label,
  }
}
export default connect(mapStateToProps, null)(ProgressBarContainer);