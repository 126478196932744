import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import CONSTANTS from '../../utils/Constants';

import '../../fonts/fonts.css';

import allAgentOffline from "../../images/ais-images/agent-busy.png";
import allAgentBusy from "../../images/ais-images/icon-customer-agent.png";
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { submitEnquiryDetails, savePayload } from '../../actions/Dashboard/doFindAgreements';
import config from '../../config/config';
import { LOAD_THANK_YOU } from '../../actions/Dashboard/actionTypes';

class DesktopAgentStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            field: '',
            continueButtonClassName: "ais_align-popup-button",
            submittedBtnClass: "ais_device-select-button ais_device-btn-width margin0",
            cancelButtonClassName: "ais_align-popup-button",
            retryButtonClaas: "ais_align-popup-button ais_busy_all"
        }
    }

    handleCancelClick = (value) => {
        // console.log("Cancel button clicked.");
        this.setState({
            disable: true,
            cancelButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.dataprops.source, "Cancel");
    }

    handleContinueClick = (value) => {
        this.setState({
            disable: true,
            continueButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.dataprops.source, "Continue");
    }

    handleSubmit() {
        this.setState({
            submittedBtnClass: "ais_device-select-button ais_device-btn-width ais_selected margin0",
            disable: true
        })
        var enquiryDescription = MESSAGE_CONSTANTS.ENQUIRY_DESCRIPTION_CALLBACK;
        var enquiryOption = "";
        enquiryDescription = enquiryDescription.replace('{DateTimeOfRequest}', new Date()).replace('{MobileNumber}', this.state.field);
        this.props.source === CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_OFFLINE ? enquiryOption = MESSAGE_CONSTANTS.ALL_AGENT_OFFLINE_ENQUIRY :
            enquiryOption = MESSAGE_CONSTANTS.ALL_AGENT_UNAVAILABLE_ENQUIRY;
        this.props.createChatEnquiry(enquiryOption, CONSTANTS.ENQUIRY_SUB_REASON.CHAT, enquiryDescription, MESSAGE_CONSTANTS.LIVE_CHAT_ASSISTANCE_UNAVAILABLE, null, null, this.state.field);
    }

    setNumber(value) {
        value = value.replace(/[^0-9]/g, '');
        if (value === "") {
            this.setState({
                field: ""
            });
        } else {
            this.setState({
                field: value,
                disable: false
            })
        }
    }

    handleKey(e) {
        if (e.keyCode === 13 && !e.shiftKey) {
            this.handleSubmit();
        }
    }

    faqClick = () => {
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.GET_MOREINFO, MESSAGE_CONSTANTS.GET_MOREINFO.buttons[0].text);
        this.setState({
            disable: true,
            selectFAQ: true,
        })
    }
    handleClick(e) {
        this.props.savePayload(LOAD_THANK_YOU, { showThankYou: true, stayWithAIS: 'AGENT_CHAT' });
    }

    handleRetryClick(e) {
        this.setState({
            disable: true,
            retryButtonClaas: "ais_align-popup-button-selected ais_busy_all"
        });
        this.props.updateJourneyList(CONSTANTS.ASSIGN_TO_AGENT, null);
    }

    render() {
        let displayButtons = "";
        let agentStatusIcon = allAgentOffline;
        /*  if (this.props.dataprops && this.props.dataprops.source === CONSTANTS.ALL_AGENT_BUSY) {
             agentStatusIcon = allAgentBusy;
         } */
        return (
            <Fragment>
                <div className="ais_current-form-device-div ais_mdnInformativeMessage">
                    <div className="ais_verificationHeader">
                        <div>  <img alt="error-icon" className="ais_verificationImage" src={agentStatusIcon} /> </div>
                        <label className="ais_primary-header ais_primary-head-width">{this.props.data.PRIMARY_HEADER}</label>
                        <label className="ais_secondary-header ais_secondary-head-width" dangerouslySetInnerHTML={{ __html: this.props.data.SECONDARY_HEADER }}></label>
                        <Fragment>
                            <div className="ais_agent-busy-content">
                                {(this.props.chatReason === CONSTANTS.CHAT_REASON.GENERAL_ENQUIRY) ?
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: this.props.data.SECONDARY_CONTENT }}></p>
                                        <div className='ais_margin-bottom-60 center'>
                                            <button className='ais_align-popup-button btn' onClick={(e) => this.handleClick(e)} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
                                        </div>
                                    </div> :
                                    (this.props.dataprops.source === CONSTANTS.AGENT_DISCONNECTED) ?
                                        <div>
                                            <p dangerouslySetInnerHTML={{ __html: this.props.data.PRIMARY_CONTENT }}></p>
                                            <div className='ais_margin-bottom-60 center'>
                                                <button className='ais_align-popup-button btn' onClick={(e) => this.handleClick(e)} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
                                            </div>
                                        </div> :
                                        <Fragment>
                                            {/* <div>
                                                <p dangerouslySetInnerHTML={{ __html: this.props.data.PRIMARY_CONTENT }}></p>
                                            </div> */}
                                            {this.props.dataprops.source === CONSTANTS.ALL_AGENT_BUSY ?
                                                <div className='ais_margin-bottom-60 center'>
                                                    <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width margin-10 ${this.state.disable ? 'ais_selected' : ''}`} onClick={this.faqClick} >{MESSAGE_CONSTANTS.FAQ}</button>
                                                    <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width ${this.state.disable ? 'ais_selected' : ''}`} onClick={(e) => this.handleClick(e)} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
                                                    <div className="ais_device-divider ais_button" />
                                                    <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width margin0 ${this.state.disable ? 'ais_selected' : ''}`} onClick={(e) => this.handleRetryClick(e)} >{MESSAGE_CONSTANTS.RETRY_BUTTON}</button>
                                                </div> :
                                                <div className='ais_margin-bottom-60 center'>
                                                    <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width margin-10 ${this.state.disable ? 'ais_selected' : ''}`} onClick={this.faqClick} >{MESSAGE_CONSTANTS.FAQ}</button>
                                                    <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width ${this.state.disable ? 'ais_selected' : ''}`} onClick={(e) => this.handleClick(e)} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
                                                    <div className="ais_device-divider ais_button" />
                                                    <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width margin0 ${this.state.disable ? 'ais_selected' : ''}`} onClick={(e) => this.handleRetryClick(e)} >{MESSAGE_CONSTANTS.RETRY_BUTTON}</button>
                                                </div>
                                            }
                                        </Fragment>}
                            </div>
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        )
    }
};

const mapStateToProps = state => {
    let { chatReason } = state.customerData;
    return {
        chatReason
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        submitEnquiryDetails,
        savePayload,
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(DesktopAgentStatus);