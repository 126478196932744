import React from "react";

import CONSTANTS from '../../utils/Constants';

class ContinueBotFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
        }
        this.askQuery = this.askQuery.bind(this);
        this.resumeFlow = this.resumeFlow.bind(this);
    }

    askQuery(){
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.ASK_TO_BOT, this.props.data.BUTTONS[0]);
    }

    resumeFlow(){
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.updateJourneyList(this.props.botCalledFrom.source, this.props.botCalledFrom.action, "", this.props.data.BUTTONS[1]);
    }

    render() {

        return (
            <div className="ais_current-form-device-div">
                <div className="ais_current-form-device-header-div">
                    <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
                </div>
                <div className="ais_current-popup-center-div">
                </div>
                <div className="ais_outer-align-button">
                    <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={this.askQuery} >{this.props.data.BUTTONS[0]}</button>
                    <div className= "device-divider"></div>
                    <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.resumeFlow} >{this.props.data.BUTTONS[1]}</button>
                </div>
            </div>
        )
    }
}

export default ContinueBotFlow;