import React, { Fragment, Component } from 'react';

import WebChatWindow from '../WebChatWindow/WebChatWindow';
import Header from '../WebDashboard/Header';
import Feedback from '../../../ais_components/Feedback/Feedback';
import DesktopConversationEnd from '../../../ais_components/DesktopConversationEnd/DesktopConversationEnd';
import ServiceUnavailable from '../../../ais_components/ServiceUnavailable/ServiceUnavailable';
import ThankYouPage from '../../../ais_components/AISReasonCommonPage/ThankYoupage';

class webDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFeedback: false,
            feedbackHeader: false,
            conversationEnd: false,
            showServiceUnavailable: false,

        }
        this.showFeedback = this.showFeedback.bind(this);
        this.showServiceUnavailable = this.showServiceUnavailable.bind(this);
        this.conversationEnd = this.conversationEnd.bind(this);
    }


    showFeedback() {
        this.setState({
            showFeedback: true,
            feedbackHeader: true
        })
    }

    showServiceUnavailable() {
        this.setState({
            showServiceUnavailable: true
        })
    }

    conversationEnd() {
        this.setState({
            conversationEnd: true,
            showFeedback: false
        });
    }

    render() {
        let { visitorCognitoId, workspace, userCancelled, setUserClosedJourneyDuringAssistance, journeyCmpltd, showThankYouFlag } = this.props;

        let showComp = <WebChatWindow sourceAction={this.props.sourceAction} workspace={workspace} visitorCognitoId={visitorCognitoId}
            userCancelled={userCancelled} desktopConversationEnd={this.conversationEnd}
            showServiceUnavailable={this.showServiceUnavailable}
            showFeedback={this.showFeedback} />;


        if (this.state.showFeedback) {
            return (
                <div className="web-container-fluid">
                    <Header setUserClosedJourneyDuringAssistance={setUserClosedJourneyDuringAssistance}
                        showHeader={this.state.feedbackHeader} desktopConversationEnd={this.conversationEnd} />
                    <Feedback desktopConversationEnd={this.conversationEnd} />
                </div>
            );
        }

        /*  if (this.state.showServiceUnavailable) {
             return <ServiceUnavailable />
         } */

        if (this.state.conversationEnd) {
            return <DesktopConversationEnd />
        }
        if (showThankYouFlag) {
            return <ThankYouPage />
        }

        return (
            <Fragment>
                <div className="web-container-fluid">
                    <Header
                        setUserClosedJourneyDuringAssistance={setUserClosedJourneyDuringAssistance}
                        showClosePrompt={this.showClosePrompt}
                        showHeader={this.state.feedbackHeader}
                        desktopConversationEnd={this.conversationEnd} />
                    <div id="appBody" className="row ais_app-body">
                        {showComp}
                    </div>
                </div>
            </Fragment>
        );
    }
};

export default webDashboard;