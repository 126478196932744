import React, { Component, Fragment } from "react";

class CallUpdateVisitor extends Component {
    componentWillMount() {
        this.props.updateVisitor({ variables: this.props.visitor });
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.visitor) !== JSON.stringify(nextProps.visitor)) {
             this.props.updateVisitor({ variables: nextProps.visitor });
        }
    }

    render() {
        return (
            <Fragment>                
            </Fragment>
        )
    }
} 

export default CallUpdateVisitor;