import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMaskedstring } from '../../utils/utils';
import { savePayload, updateContactDetails, createChatEnquiry } from '../../actions/Dashboard/doFindAgreements';
import { SAVE_CONTACT_NO, SAVE_EMAIL_ID } from '../../actions/Dashboard/actionTypes';
import CONSTANTS from "../../utils/Constants";

import contactIcon from "../../images/ais-images/icon-mobile-number.png";
import enrolledEmailIcn from "../../images/ais-images/icon-email-address.png";
import MESSAGE_CONSTANTS from "../../config/journey-messages";

class SubmitContactNumber extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            field: "",
            disable: true,
            content: "",
            errorMsg: null,
            highlightedClass: "",
            textboxDisable: false,
        }
        this.handleClick = this.handleClick.bind(this);
        this.setNumber = this.setNumber.bind(this);
        this.validateField = this.validateField.bind(this);

    }

    validateField() {
        const { dataprops } = this.props;
        if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO || dataprops.source === CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK) {
            return this.state.field.length === CONSTANTS.VALID_CONTACT_NO_LENGTH && /^\d+$/.test(this.state.field);
        } else if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.state.field);
        }
    }

    handleClick() {
        // console.log("button clicked.");
        const { dataprops, enquiryDetails } = this.props;
        if (this.validateField()) {
            this.setState({
                highlightedClass: "ais_device-select-button-highlighted",
                disable: true,
                textboxDisable: true
            });
            if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO) {
                this.props.savePayload(SAVE_CONTACT_NO, this.state.field);
            } else if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL) {
                this.props.savePayload(SAVE_EMAIL_ID, this.state.field);
                let { cacheId, newContactNo } = this.props;
                this.props.updateContactDetails(cacheId, newContactNo, this.state.field);
            } else if (dataprops.source === CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK) {
                this.props.updateJourneyList(null, MESSAGE_CONSTANTS.ENQUIRY_SUBMITTED);
                let enquiryDesc = MESSAGE_CONSTANTS.CREATE_GENERAL_ENQUIRY.replace('{DateTimeOfRequest}', new Date()).replace('{MobileNumber}', this.state.field);
                this.props.createChatEnquiry( CONSTANTS.GENERAL_ENQUIRY, `${enquiryDetails.enquiryType}`, enquiryDesc, this.state.field)

                return false;
            }
            this.props.updateJourneyList(dataprops.source, getMaskedstring(this.state.field));
        } else {
            if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO) {
                this.refs['contactNo'].focus();
                this.setState({
                    errorMsg: MESSAGE_CONSTANTS.ERROR_MSG_INVALID_CONTACT_NO,
                    disable: true
                })
            } else if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL) {
                this.refs['emailId'].focus();
                this.setState({
                    errorMsg: MESSAGE_CONSTANTS.ERROR_MSG_INVALID_EMAIL_ID,
                    disable: true
                })
            }
        }
    }

    setNumber(value) {
        const { dataprops: { source } } = this.props;
        if (source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO || source === CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK) {
            value = value.replace(/[^0-9]/g, '');
            if (value === "") {
                this.setState({
                    field: ""
                });
            } else {
                this.setState({
                    field: value,
                    errorMsg: null,
                    disable: false
                })
            }
        } else {
            this.setState({
                field: value,
                errorMsg: null,
                disable: false
            })
        }
    }

    componentDidMount() {
        const { dataprops } = this.props;
        if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO || dataprops.source === CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK) {
            this.refs['contactNo'].focus();
        } else if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL) {
            this.refs['emailId'].focus();
        }
    }

    render() {
        const { dataprops } = this.props;

        let placeholder = "Type your text here";
        if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO || dataprops.source === CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK) {
            placeholder = this.props.data.PLACEHOLDER;
        } else if (dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_EMAIL) {
            placeholder = this.props.data.PLACEHOLDER;
        }
        return (
            <div className="ais_current-form-device-div">
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
                    {(dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO || dataprops.source === CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK) ? <div>  <img alt="enroll-address-icn" className="ais_confirm-delivery-icn" src={contactIcon} /> </div>
                        : this.props.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_EMAIL ? <div>  <img alt="enrolled-email-icn" className="ais_confirm-delivery-icn" src={enrolledEmailIcn} /> </div>
                            : null}
                    {(dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SUBMIT_NEW_CONTACT_NO || dataprops.source === CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK) ?
                        <div>
                            <input ref="contactNo" disabled={this.state.textboxDisable} className="ais_contact-number-input" type="text" pattern="[0-9]{10}" value={this.state.field} maxLength="10" minLength="8" onChange={(e) => this.setNumber(e.target.value)} placeholder={placeholder} />
                            <label className="ais_current-mobile-device-label-custom">{MESSAGE_CONSTANTS.MOBILE_EXAMPLE}</label>
                        </div>
                        : <input ref='emailId' disabled={this.state.textboxDisable} className="ais_contact-number-input" type="text" value={this.state.field} onChange={(e) => this.setNumber(e.target.value)} placeholder={placeholder} />}
                    {this.state.errorMsg && <div className="ais_error-div"><label>{this.state.errorMsg}</label></div>}
                    <button disabled={this.state.disable} className={"ais_device-select-button enter-email-submit-button " + this.state.highlightedClass} onClick={this.handleClick} >{this.props.data.BUTTONS[0]}</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let { clientData, customerNewContact, enquiryDetails } = state.customerData
    return {
        cacheId: clientData.CacheId,
        enquiryDetails,
        newContactNo: customerNewContact
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        savePayload,
        createChatEnquiry,
        updateContactDetails
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitContactNumber);