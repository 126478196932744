import React, { Fragment } from 'react'
import config from "../../config/config";

export default function FAQSwapUnlimited() {
  document.title = 'AISMobileCare Swap Unlimited FAQ'
  return (
    <Fragment>
      <h1 id="faq_header" style={{ width: 'auto', fontFamily: 'Helvetica-Bold', fontSize: 26, color: '#3d3d3d', textAlign: 'center', lineHeight: 1.2 }}>คำถามที่พบบ่อยเกี่ยวกับบริการ AIS Mobile Care Swap Unlimited</h1>
      <div className="holds-the-iframe">
        <iframe id="faq-iframe" className="faq-iframe" style={{ width: '100%', height: '100vh' }} src={`${config.WPAPIServer.baseUrl}/ais-mobile-care-swap-unlimited-faq-th-page/?domain=PROD`} title="Screen Repair Service" />
      </div>
    </Fragment>

  )
}