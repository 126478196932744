import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CONSTANTS from '../utils/Constants';
import MESSAGE_CONSTANTS from '../config/journey-messages';
import { getPlaceholder } from '../utils/utils';
import {  doSaveMDN,
          setInputState,
          doFindAgreementsInit,
          doverifyNRICInit  } from '../actions/Dashboard/doFindAgreements';
import { MIXPANEL } from '../mixpanel/Mixpanel';
import EVENTLIST from '../mixpanel/Eventlist';

import sendNormalIcon from '../images/ais-images/button-send-normal.png';

class UserInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            loading: null,
            foundAgreement: null,
            NRICVerfied: null,
            nameVerfied: null,
            mdnAttemptCount: 1,
            nricAttemptCount: 1,
            // nameAttemptCount: 1,
            savedInput: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {

        if(this.props.inputState === "ENTERED_MDN" ){
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }
        if(e.target.value === ""){
            this.setState({
                value: ""
            });
        }else{
            this.setState({
                value: e.target.value
            });
        }
    }

    handleKey = (event) => {
        if (event.keyCode === 13 && !event.shiftKey && event.target.value) {
            this.handleSubmit(event);
        }
    }

    // checkNumberInput(inputState,e){
    //     if(inputState === "ENTERED_MDN"){
    //         e.target.value = e.target.value.replace(/[^\d\-]|^[\-]*$/g, '');
    //         return e.target.value;
    //     }else{
    //         return true;
    //     }
    // }

    handleSubmit() {
        let text = this.state.value;
        let inputState = this.props.inputState || '';

        if((this.state.value.trim() === "" || this.state.value.length < 5) && (inputState !== CONSTANTS.ENTERED_NAME)) {
            return false;
        }

        if(CONSTANTS.SOURCE_USER_INPUT.REQUEST === this.props.source){
            MIXPANEL.track(EVENTLIST.CHATBOT_SUPPORT, { "User Query" : text, "Source" : "Enquiry Bot" });
        }else if (CONSTANTS.SOURCE_USER_INPUT.ENQUIRY === this.props.source){
            MIXPANEL.track(EVENTLIST.CHATBOT_SUPPORT, { "User Query" : text, "Source" : "Request Type Bot" });
        }

        if (inputState === CONSTANTS.ENTERED_MDN) {
            this.props.doSaveMDN(text);
            this.props.updateJourneyList(this.props.source, text)
            this.props.doFindAgreementsInit({
                FindAgreementsParameters: {
                    MobileDeviceNumber: text || '',
                    SessionId: this.props.cacheId,
                    VisitorId: this.props.visitorId,
                    CustomerName: this.props.customerName.fullName
                },
                userInput: {
                    handlePrevSubmit: 1,
                    userInput: this.props.inputState,
                    source: CONSTANTS.MDN_VERIFIED,
                    value: MESSAGE_CONSTANTS.ASK_NRIC
                }
                //comment Below code to disable Name Verification
                ,
                nameInput: {
                    handlePrevSubmit: 1,
                    userInput: this.props.inputState,
                    source: CONSTANTS.MDN_VERIFIED,
                    value: MESSAGE_CONSTANTS.INCORRECT_NAME
                }
            });
            this.setState({ value: '' })
        }
        //comment below code to disable Name verification
        else if(inputState === CONSTANTS.ENTERED_NAME){

            this.props.updateJourneyList(this.props.source, text)
            this.props.doFindAgreementsInit({
                FindAgreementsParameters: {
                    MobileDeviceNumber: this.props.customerContact || '',
                    SessionId: this.props.cacheId,
                    VisitorId: this.props.visitorId,
                    CustomerName: text
                },
                userInput: {
                    handlePrevSubmit: 1,
                    userInput: this.props.inputState,
                    source: CONSTANTS.MDN_VERIFIED,
                    value: MESSAGE_CONSTANTS.ASK_NRIC
                }
            });
            this.setState({ value: '' })
        }
        else if (inputState === CONSTANTS.ENTERED_NRIC) {
            // this.props.updateJourneyList(this.props.source, text)
            this.props.doverifyNRICInit({
                VerifyAgreementsParameters: {
                    MobileDeviceNumber: this.props.customerContact || '',
                    PassportNumber: text,
                    FailedAttempt: 0,
                    CustomerVerificationMethod: "PASSNBR",
                    SessionId: this.props.cacheId
                },
                userInput: {
                    handlePrevSubmit: 1,
                    userInput: this.props.inputState,
                    source: this.props.source,
                    value: text
                },
                DetermineIncidentPaths: {
                    SessionId: this.props.cacheId,
                    caseType: this.props.caseType
                },
                ProcessIncidentParameters: {
                    SessionId: this.props.cacheId,
                    Incident: {
                        FailureDescriptiveText: this.props.selectedDefectOption,
                        IncidentType: this.props.caseType
                    }
                }
            });
            this.setState({ value: '' });
            this.props.updateJourneyList(this.props.source, text);
            // this.dispatchVerifyNRIC(text);
        } else {
            this.setState({ value: '' });
            this.props.updateJourneyList(this.props.source, text);
        }
    }

    componentDidMount(){

        if(this.props.inputState === "ENTERED_NRIC"){
            this.refs['nric'].focus();
        }else if(this.props.inputState === "ENTERED_MDN"){
            this.refs['mdn'].focus();
        }else if(this.props.inputState === "ENTERED_NAME"){
            this.refs['username'].focus();
        }else{
            this.refs['enquiry'].focus();
        }

    }

    render() {

        let placeholder = getPlaceholder(this.props.source);
        let sendButtonClass = this.state.value.length >= 5 || this.props.inputState === "ENTERED_NAME" ? "activated-send-icon" : "default-send-icon";
        let textColor = this.state.value !== '' ? " text-color" : " placeholder-color";
        let maxlen = "";
        let reference = "";
        let minlen = "";
        if(this.props.inputState === "ENTERED_NRIC"){
            maxlen = 13;
            minlen = 10;
            reference = "nric";
        }
       else if(this.props.inputState === "ENTERED_MDN"){
            maxlen = 8;
            minlen = 5;
            reference = "mdn";
        }else if(this.props.inputState === "ENTERED_NAME"){
            reference = "username";
        }else{
            reference = "enquiry";
        }

        //let userInputClass = this.props.source === CONSTANTS.SOURCE_USER_INPUT.ENQUIRY ? "user-input-div enquiry-input-margin" : "user-input-div";
        let userInputClass = "ais_user-input-div ais_enquiry-input-margin";
        return (
            <Fragment>
                {/* <div className={userInputClass}>
                    <div className="ais_user-input-textbox-div">
                        <input ref={reference} id="chatInput" type="text" className={"ais_user-input-textbox" + textColor} placeholder={placeholder}
                            name="messageInputTextBox" value={this.state.value}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKey}
                            // onKeyUp={(e) => this.checkNumberInput(this.props.inputState,e)}
                            maxLength={maxlen}
                            minLength={minlen}
                        />
                    </div>
                    <div className={sendButtonClass} onClick={this.handleSubmit}>
                    </div>
                </div> */}

                <div className="ais_ui-wrapper">
                  <button className="ais_ui_button" onClick={this.handleSubmit}>
                    <img src={sendNormalIcon}></img>
                  </button>
                  <div className="ais_ui_inputwrapper">
                    <input type="text"
                      ref={reference} id="chatInput"
                      name="messageInputTextBox"
                      value={this.state.value}
                      className="ais_ui_input"
                      placeholder={placeholder}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKey}
                      maxLength={maxlen}
                      minLength={minlen} />
                  </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    let { visitorId, loading, setProgressBarValue, inputState,  customerContact, agreementFailedAttempt, verificationFailedAttempt, agreementFound, verifiedNRIC, caseType, selectedDefectOption, NameVerified, clientData, customerName } = state.customerData
    return {
        cacheId: clientData.CacheId,
        selectedDefectOption,
        customerName,
        loading,
        setProgressBarValue,
        userInputState: inputState,
        customerContact,
        agreementFailedAttempt,
        verificationFailedAttempt,
        agreementFound,
        verifiedNRIC,
        nameVerified: NameVerified,
        caseType,
        visitorId
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        doFindAgreementsInit,
        doSaveMDN,
        setInputState,
        doverifyNRICInit
    }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(UserInput);

