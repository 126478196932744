

import React from 'react'
const defaultProps = {
  className: 'ais_progress-bar-wrapper',
  color: 'rainbow',
  height: 4,
  hideDelay: .4,
  percent: 0,
  speed: .4,
  style: {
    backgroundImage: 'linear-gradient(to left, #cadd1f, #6ea32d)'
  }
};

function ReactProgressBar(props) {
  props = {
    ...defaultProps,
    ...props
  };

  let wrapperStyle = {
    marginTop: '14px'
  }

  let containerStyle = {
    opacity: props.percent < 100 ? 1 : 0,
    disabled :props.percent < 100 ? 1 : 0,
    display: props.percent < 100 ? 'block'  : 'none' ,
    WebkitTransition: `${props.speed}s opacity`,
    transition: `${props.speed}s opacity`,
    WebkitTransitionDelay: `${props.percent < 100 ? 0 : props.hideDelay}s`,
    transitionDelay: `${props.percent < 100 ? 0 : props.hideDelay}s`
  };

  let barStyle = {
    width: `${props.percent}%`,
    maxWidth: '100% !important',
    height: `${props.height}px`,
    boxShadow: '1px 1px 1px rgba(0,0,0,0.4)',
    borderRadius: '0 1px 1px 0',
    WebkitTransition: props.percent <= 100 && props.percent > 10 ? `${props.speed}s width, ${props.speed}s background-image` : `0s width, 0s background-color`,
    transition: props.percent <= 100 && props.percent > 10 ? `${props.speed}s width, ${props.speed}s background-image` : `0s width, 0s background-color`,
    ...props.style
  };

  //barStyle.backgroundColor = props.style.backgroundColor || props.color;
  barStyle.backgroundImage = props.style.backgroundImage || props.color;

  return (
    <div className={props.className} style={containerStyle}>
      <div className="ais-progressbar-div" style={wrapperStyle}>
        <label>{props.Label}</label>
        <div style={{ backgroundColor: '#e7e9ea' }}>
          <div className={props.className.length ? `${props.className}__bar` : ''} style={barStyle}></div>
        </div>
      </div>
    </div>
  );
}

export default ReactProgressBar;
