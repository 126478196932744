import * as React from 'react';
import { Text, Heading } from '@chakra-ui/react';
import { formatColor, formatPhoneName, formatCapacityFromDevice } from './functions';

export const DefaultPhoneDisplay = ({ device }) => {
  return (
    <>
      <Heading as="h2" fontSize={'lg'} lineHeight={'normal'}>
        {formatPhoneName(device)}
      </Heading>
      <Text mt={0} fontSize={'lg'}>
        {formatCapacityFromDevice(device)} {formatColor(device)}
      </Text>
    </>
  );
};

export default DefaultPhoneDisplay;
