import React, { Component, Fragment } from 'react'
import Icons from '../../components/Icons/Icons'
import MESSAGE_CONSTANTS from '../../config/journey-messages'
import CONSTANTS from '../../utils/Constants'
import './GetMoreInfo.css'

export default class GetMoreInfo extends Component {
    state = {
        selectedOption: ''
    }

    handleClick = (text, name) => {
        this.setState({
            selectedOption: name
        });
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.GET_MOREINFO, text);
    }

    render() {
        return (
            <div className="getmore-info-wrap text-center">
                {/* <label className="getmore-text">{MESSAGE_CONSTANTS.GET_MOREINFO.title}</label> */}
                <ul className="d-flex getmore-options">
                    {MESSAGE_CONSTANTS.GET_MOREINFO.buttons.map(option => <li
                        key={option.name}
                        className={this.state.selectedOption === option.name ? 'active' : ''}
                    >
                        <div
                            className={`getmore-option ${this.state.selectedOption ? 'disabled' : ''}`}
                            onClick={() => this.handleClick(option.text, option.name)}
                            disabled={this.state.selectedOption}
                        >
                            <i>{Icons[option.icon]}</i>
                            <p>{option.text}</p>
                        </div>
                    </li>)}
                </ul>
            </div>
        )
    }
}
