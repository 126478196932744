import config from "./../../config/config";
import CryptoJS from "crypto-js";
import axios from 'axios';

export function initWorkspace(workspaceToken) {
  const token = workspaceToken.Token;
  return new window.Twilio.TaskRouter.Workspace(token);
}

export async function createTask(
  workspace,
  skillId,
  language,
  visitorId,
  chatRequestId,
  callback
) {
  const attributes = {
    skillId: skillId,
    selected_language: language,
    visitor_id: visitorId,
    request_id: chatRequestId
  };
  const params = {
    WorkflowSid: sessionStorage.getItem("workflowId") || config.Twilio.workflowSid,
    TaskChannel: config.Twilio.taskchannelName,
    Timeout: config.Twilio.taskTimeout,
    Attributes: JSON.stringify(attributes)
  };
  console.log("twilio params ======>", params);
  workspace.tasks.create(params, function (error, task) {
    if (error) {
      console.log(error.code);
      console.log(error.message);
      return;
    }
    // console.log("Created Task : ", task);
    callback(task, chatRequestId);
  });
}

// export async function getRealtimeStatistics({ workspace, skillId }, callback) {
//   const TASK_QUEUE_NAME = 'AIS-DP-CHAT_TH-TH'
//   const params = { FriendlyName: TASK_QUEUE_NAME };
//   workspace.taskqueues.statistics.fetch(params, function (error, statistics) {
//     try {
//       const workersOnChat = statistics[0].realtime.totalAvailableWorkers;
//       console.log("TotalAvailableWorkers : ", workersOnChat);
//       if (callback) callback(workersOnChat);
//     } catch (e) {
//       console.error("getRealtimeStatistics error", e);
//       if (callback) callback(0);
//     }
//   });
// }

export async function getRealtimeStatistics({ workspace, skillId }, callback) {
  const { totalAvailableWorkers } = await getWorkersAvailable();
  console.log("TotalAvailableWorkers : ", totalAvailableWorkers);
  if (callback) callback(totalAvailableWorkers);
}



export async function fetchTask(workspace, taskSid, callback) {
  workspace.tasks.fetch(taskSid, function (error, task) {
    if (error) {
      console.log(error.code);
      console.log(error.message);
      return;
    }
    callback(task.assignmentStatus);
  });
}

export const ChatwrapperApiConstants = {
  CHAT_DOMAIN: `${process.env.REACT_APP_CHATWRAPPER_BASE_ENDPOINT}`,
  CHAT_ENV: `${process.env.REACT_APP_CHATWRAPPER_ENV}`
};

export const generateAuthorization = ({
  domain = '',
  httpMethod = 'GET',
  path = '/',
  timestamp = Date.now(),
  apiClientKey = '',
  payload = null
}) => {
  const reverse = (input) => Array.from(input).reverse().join('');
  const message = `${domain}${httpMethod}${path}${timestamp}${reverse(apiClientKey)}${payload ? JSON.stringify(payload) : ''}`

  console.log("generateAuthorization message:", message)

  let hmacResult = CryptoJS.HmacSHA256(message, apiClientKey).toString()

  console.log("hmacResult", hmacResult)

  return hmacResult
}

export const chatWrapperAPI = async (method, basePath, path, params, isSecured = true) => {
  let options = {};
  const url = `${basePath}${path}`;

  if (isSecured) {
    const timestamp = Date.now();
    const payload = params?.data ? JSON.stringify(params.data) : '';

    options.headers = {
      'x-client-key': process.env.REACT_APP_CHATWRAPPER_API_KEY,
      'x-nvu-authorization': generateAuthorization({
        domain: process.env.REACT_APP_CHATWRAPPER_SOURCE_DOMAIN,
        httpMethod: method,
        timestamp: timestamp,
        path: path,
        apiClientKey: process.env.REACT_APP_CHATWRAPPER_API_KEY,
        payload: payload,
      }),
      'x-nvu-client': process.env.REACT_APP_CHATWRAPPER_CLIENT,
      'x-nvu-program': process.env.REACT_APP_CHATWRAPPER_PROGRAM,
      'x-nvu-channel': 'chat',
      'x-request-ts': timestamp,
    };
  }

  switch (method.toUpperCase()) {
    case 'GET':
      return await axios.get(url, options);
    case 'POST':
      return await axios.post(url, params?.data, options);
    case 'PUT':
      return await axios.put(url, params?.data, options);
    case 'DELETE':
      return await axios.delete(url, options);
    default:
      return await axios.get(url, options);
  }
}

export const getWorkersAvailable = async () => {
  try {
    const path = '/api/v1/nvu/worker/stats';
    const { data } = await chatWrapperAPI(
      'GET',
      `${ChatwrapperApiConstants.CHAT_DOMAIN}/${ChatwrapperApiConstants.CHAT_ENV}`,
      path
    );
    return data;
  } catch (e) {
    console.error('Failed to get available workers', e);
    return {"totalAvailableWorkers": 0}
  }
};
