import React, { Component, Fragment } from "react";

class CallCreateMessage extends Component {
    componentWillMount() {
        this.props.createMessage({ variables: this.props.message });
    }

    render() {
        return (
            <Fragment>
            </Fragment>
        )
    }
} 

export default CallCreateMessage;