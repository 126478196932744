import React, { Fragment } from 'react';
import CompletedStage from './CompletedStage';

const TransitionBar = (props) => {
    // console.log("Completed Stages",props.completedStages);
    return (
        <Fragment>
            <div className="ais_progress-div">
                <div className="ais_percentage-div">
                    <span className="ais_app-header-progress">
                        {props.progress}<sup>%</sup>
                    </span>
                </div>
                <div className="ais_stage-progress-div">
                    <span className="ais_stage-name">{props.stageName}</span>
                    <div className="ais_progress-bar-container">
                        <div className="ais_progress-bar" style={{width:props.progressBar}}></div>
                        {/* <button className = 'ais_transition-panel-cancel-btn'> Cancel SR </button> */}

                    </div>
                </div>
            </div>
            <div className="ais_blue-div">
                <div className="ais_blue-circle"></div>
                <div className="ais_blue-dotted-line">...</div>
            </div>
            {props.completedStages && props.completedStages.map((stage, i) => {
                return <CompletedStage stage={stage} key={i} />
            })}
            
        </Fragment>
    )
}

export default TransitionBar;