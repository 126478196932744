export const INIT_APP_REQUEST = 'INIT_APP_REQUEST';
export const INIT_APP_SUCCESS = 'INIT_APP_SUCCESS';
export const INIT_APP_FAILURE = 'INIT_APP_FAILURE';

export const INIT_INTERACTION_INIT = 'INIT_INTERACTION_INIT';
export const INIT_INTERACTION_SUCCESS = 'INIT_INTERACTION_SUCCESS';
export const INIT_INTERACTION_FAILURE = 'INIT_INTERACTION_FAILURE';

export const SET_INPUT_STATE = 'SET_INPUT_STATE';
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';
export const SAVE_USER_MDN = 'SAVE_USER_MDN';
export const SET_VERIFICATION_PROGRESS = 'SET_VERIFICATION_PROGRESS';
export const RESET_VERIFICATION_PROGRESS = 'RESET_VERIFICATION_PROGRESS';
export const SET_PROCESS_INCIDENT_PROGRESS = 'SET_PROCESS_INCIDENT_PROGRESS';

export const FIND_AGREEMENTS_INIT = 'FIND_AGREEMENTS_INIT';
export const FIND_AGREEMENTS_SUCCESS = 'FIND_AGREEMENTS_SUCCESS';
export const FIND_AGREEMENTS_FAILURE = 'FIND_AGREEMENTS_FAILURE';

export const VERIFY_NRIC_INIT = 'VERIFY_NRIC_INIT';
export const VERIFY_NRIC_SUCCESS = 'VERIFY_NRIC_SUCCESS';
export const VERIFY_NRIC_FAILURE = 'VERIFY_NRIC_FAILURE';

export const DETEREMINE_INCIDENT_PATH_INIT = 'DETEREMINE_INCIDENT_PATH_INIT';
export const DETEREMINE_INCIDENT_PATH_SUCCESS = 'DETEREMINE_INCIDENT_PATH_SUCCESS';
export const DETEREMINE_INCIDENT_PATH_FAILURE = 'DETEREMINE_INCIDENT_PATH_FAILURE';

export const CREATE_NEW_SR_INIT = 'CREATE_NEW_SR_INIT';
export const CREATE_NEW_SR_SUCCESS = 'CREATE_NEW_SR_SUCCESS';
export const CREATE_NEW_SR_FAILURE = 'CREATE_NEW_SR_FAILURE';

export const CANCEL_SR_INIT = 'CANCEL_SR_INIT';
export const CANCEL_SR_SUCCESS = 'CANCEL_SR_SUCCESS';
export const CANCEL_SR_FAILURE = 'CANCEL_SR_FAILURE';

export const RESUME_SR_INIT = 'RESUME_SR_INIT';
export const RESUME_SR_SUCCESS = 'RESUME_SR_SUCCESS';
export const RESUME_SR_FAILURE = 'RESUME_SR_FAILURE';

export const PROCESS_INCIDENT_INIT = 'PROCESS_INCIDENT_INIT';
export const PROCESS_INCIDENT_SUCCESS = 'PROCESS_INCIDENT_SUCCESS';
export const PROCESS_INCIDENT_FAILURE = 'PROCESS_INCIDENT_FAILURE';
export const PROCESS_INCIDENT_SUCCESS_VOID = 'PROCESS_INCIDENT_SUCCESS_VOID';

export const FULFILLMENT_INIT = 'FULFILLMENT_INIT';
export const FULFILLMENT_SUCCESS = 'FULFILLMENT_SUCCESS';
export const FULFILLMENT_FAILURE = 'FULFILLMENT_FAILURE';

export const GET_FULFILLMENT_INIT = 'GET_FULFILLMENT_INIT';
export const GET_FULFILLMENT_SUCCESS = 'GET_FULFILLMENT_SUCCESS';
export const GET_FULFILLMENT_FAILURE = 'GET_FULFILLMENT_FAILURE';

export const GET_REPLACEMENT_INIT = 'GET_REPLACEMENT_INIT';
export const GET_REPLACEMENT_SUCCESS = 'GET_REPLACEMENT_SUCCESS';
export const GET_REPLACEMENT_FAILURE = 'GET_REPLACEMENT_FAILURE';

export const SERVICE_REQUEST_INIT = 'SERVICE_REQUEST_INIT';
export const SERVICE_REQUEST_SUCCESS = 'SERVICE_REQUEST_SUCCESS';
export const SERVICE_REQUEST_FAILURE = 'SERVICE_REQUEST_FAILURE';

export const TRIAGE_RESOLVED_INIT = 'TRIAGE_RESOLVED_INIT';
export const TRIAGE_RESOLVED_SUCCESS = 'TRIAGE_RESOLVED_SUCCESS';
export const TRIAGE_RESOLVED_FAILURE = 'TRIAGE_RESOLVED_FAILURE';

export const SET_LEX_PROGRESS = 'SET_LEX_PROGRESS';
export const MISMATCHED_DEVICE_DETAILS = 'MISMATCHED_DEVICE_DETAILS';
export const SET_TRIAGE_SUCCESSFUL_FLAG = 'SET_TRIAGE_SUCCESSFUL_FLAG';

export const GET_SHIPPING_ORDER_INIT = 'GET_SHIPPING_ORDER_INIT';
export const GET_SHIPPING_ORDER_SUCCESS = 'GET_SHIPPING_ORDER_SUCCESS';
export const GET_SHIPPING_ORDER_FAILURE = 'GET_SHIPPING_ORDER_FAILURE';

export const SHIPPING_METHODS_INIT = 'SHIPPING_METHODS_INIT';
export const SHIPPING_METHODS_SUCCESS = 'SHIPPING_METHODS_SUCCESS';
export const SHIPPING_METHODS_FAILURE = 'SHIPPING_METHODS_FAILURE';

export const PAYMENT_INIT = 'PAYMENT_INIT';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';

export const GET_PCI_TOKEN_INIT = 'GET_PCI_TOKEN_INIT';
export const GET_PCI_TOKEN_SUCCESS = 'GET_PCI_TOKEN_SUCCESS';
export const GET_PCI_TOKEN_FAILURE = 'GET_PCI_TOKEN_FAILURE';

export const UPDATE_SHIPPING_ORDER_INIT = 'UPDATE_SHIPPING_ORDER_INIT';
export const UPDATE_SHIPPING_ORDER_SUCCESS = 'UPDATE_SHIPPING_ORDER_SUCCESS';
export const UPDATE_SHIPPING_ORDER_FAILURE = 'UPDATE_SHIPPING_ORDER_FAILURE';

export const CHARGE_ORDER_INIT = 'CHARGE_ORDER_INIT';
export const CHARGE_ORDER_SUCCESS = 'CHARGE_ORDER_SUCCESS';
export const CHARGE_ORDER_FAILURE = 'CHARGE_ORDER_FAILURE';

export const GET_STANDARDISEADDRESS_INIT = 'GET_STANDARDISEADDRESS_INIT';
export const GET_STANDARDISEADDRESS_SUCCESS = 'GET_STANDARDISEADDRESS_SUCCESS';
export const GET_STANDARDISEADDRESS_FAILURE = 'GET_STANDARDISEADDRESS_FAILURE';

export const SUBMIT_ORDER_INIT = 'SUBMIT_ORDER_INIT';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';

export const SERVICE_ORDER_INIT = 'SERVICE_ORDER_INIT';
export const SERVICE_ORDER_SUCCESS = 'SERVICE_ORDER_SUCCESS';
export const SERVICE_ORDER_FAILURE = 'SERVICE_ORDER_FAILURE';

export const PROCESS_PAYMENT_INIT = 'PROCESS_PAYMENT_INIT';
export const PROCESS_PAYMENT_SUCCESS = 'PROCESS_PAYMENT_SUCCESS';
export const PROCESS_PAYMENT_FAILURE = 'PROCESS_PAYMENT_FAILURE';

export const END_INTERACTION_INIT = 'END_INTERACTION_INIT';
export const END_INTERACTION_SUCCESS = 'END_INTERACTION_SUCCESS';
export const END_INTERACTION_FAILURE = 'END_INTERACTION_FAILURE';

export const EVALUATE_IMEI_INIT = 'EVALUATE_IMEI_INIT';
export const EVALUATE_IMEI_SUCCESS = 'EVALUATE_IMEI_SUCCESS';
export const EVALUATE_IMEI_FAILURE = 'EVALUATE_IMEI_FAILURE';

export const SET_CASE_TYPE = 'SET_CASE_TYPE';
export const SAVE_DEFECT_OPTION = 'SAVE_DEFECT_OPTION';
export const SET_TRIAGE_DISPLAY_COUNT = 'SET_TRIAGE_DISPLAY_COUNT';
export const SAVE_REPLACEMENT_OPTION = 'SAVE_REPLACEMENT_OPTION';

export const SAVE_CONTACT_NO = 'SAVE_CONTACT_NO';
export const SAVE_EMAIL_ID = 'SAVE_EMAIL_ID';

export const UPDATE_CONTACT_DETAILS = 'UPDATE_CONTACT_DETAILS';
export const UPDATE_CONTACT_DETAILS_SUCCESS = 'UPDATE_CONTACT_DETAILS_SUCCESS';
export const UPDATE_CONTACT_DETAILS_FAILURE = 'UPDATE_CONTACT_DETAILS_FAILURE';

export const GET_CONTACT_DETAILS = 'GET_CONTACT_DETAILS';
export const GET_CONTACT_DETAILS_SUCCESS = 'GET_CONTACT_DETAILS_SUCCESS';
export const GET_CONTACT_DETAILS_FAILURE = 'GET_CONTACT_DETAILS_FAILURE';

export const SAVE_DELIVERY_DATE = 'SAVE_DELIVERY_DATE';
export const SAVE_DELIVERY_SLOT = 'SAVE_DELIVERY_SLOT';
export const SAVE_BACKORDER = 'SAVE_BACKORDER';
export const SAVE_CUSTOMERNAME = 'SAVE_CUSTOMERNAME';

export const SET_PAYMENT_RETRY_ATTEMPT = 'SET_PAYMENT_RETRY_ATTEMPT';
export const SET_PAYMENT_CHARGE_ATTEMPT = 'SET_PAYMENT_CHARGE_ATTEMPT';
export const GET_DEVICE_LIST_INIT = 'GET_DEVICE_LIST_INIT';
export const GET_DEVICE_LIST_SUCCESS = 'GET_DEVICE_LIST_SUCCESS';
export const GET_DEVICE_LIST_FAILURE = 'GET_DEVICE_LIST_FAILURE';

export const GET_MAKE_LIST_INIT = 'GET_MAKE_LIST_INIT';
export const GET_MAKE_LIST_SUCCESS = 'GET_MAKE_LIST_SUCCESS';
export const GET_MAKE_LIST_FAILURE = 'GET_MAKE_LIST_FAILURE';

export const RESUME_CANCELED = 'RESUME_CANCELED';
export const SAVE_CC_DETAILS = 'SAVE_CC_DETAILS';
export const CREDIT_CARD_ATTEMPT = 'CREDIT_CARD_ATTEMPT';
export const PAYMENT_CHARGE_ATTEMPT = 'PAYMENT_CHARGE_ATTEMPT';

export const POST_CC_DETAILS_TO_BG_INIT = 'POST_CC_DETAILS_TO_BG_INIT';
export const POST_CC_DETAILS_TO_BG_SUCCESS = 'POST_CC_DETAILS_TO_BG_SUCCESS';
export const POST_CC_DETAILS_TO_BG_FAILURE = 'POST_CC_DETAILS_TO_BG_FAILURE';

export const SAVE_ADDRESS_VALIDATION_ATTEMPT_COUNT = 'SAVE_ADDRESS_VALIDATION_ATTEMPT_COUNT';

export const SET_ADDRESS_RETRY_ATTEMPT = 'SET_ADDRESS_RETRY_ATTEMPT';
export const SET_VISITOR_ID = 'SET_VISITOR_ID';
export const SET_CHAT_REQUEST_ID = 'SET_CHAT_REQUEST_ID';
export const SAVE_CONVERSATION = 'SAVE_CONVERSATION';
export const SET_USER_CANCEL_FLAG = 'SET_USER_CANCEL_FLAG';
export const SAVE_SELECTED_DEVICE = 'SAVE_SELECTED_DEVICE';

export const UPLOAD_DOCUMENT_INIT = 'UPLOAD_DOCUMENT_INIT';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export const GET_SURVEY_INIT = 'GET_SURVEY_INIT';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAILURE = 'GET_SURVEY_FAILURE';

export const SUBMIT_SURVEY_INIT = 'SUBMIT_SURVEY_INIT';
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
export const SUBMIT_SURVEY_FAILURE = 'SUBMIT_SURVEY_FAILURE';

export const BACK_ORDER_CASE = 'BACK_ORDER_CASE';

export const SAVE_DEVICE_DETAILS = 'SAVE_DEVICE_DETAILS';

export const SAVE_SELECTED_ENQUIRY_OPTION = 'SAVE_SELECTED_ENQUIRY_OPTION';
export const SAVE_ENQUIRY_REASON = 'SAVE_ENQUIRY_REASON';

export const SAVE_OFFERED_DEVICE_IMG = 'SAVE_OFFERED_DEVICE_IMG';
export const SAVE_CHANGED_ADDRESS = 'SAVE_CHANGED_ADDRESS';
export const UPLOADED_FILE_NAMES = 'UPLOADED_FILE_NAMES';

export const SAVE_SELECTED_MAKE_MODEL = 'SAVE_SELECTED_MAKE_MODEL';

export const SAVE_JOURNEY_COMPLETED = 'SAVE_JOURNEY_COMPLETED';

export const SUBMIT_ENQUIRY_INIT = 'SUBMIT_ENQUIRY_INIT';
export const SUBMIT_ENQUIRY_SUCCESS = 'SUBMIT_ENQUIRY_SUCCESS';
export const SUBMIT_ENQUIRY_FAILURE = 'SUBMIT_ENQUIRY_FAILURE';

export const SAVE_SERVICE_REQUEST_ID = 'SAVE_SERVICE_REQUEST_ID';

export const SAVE_HOLD_LOGGED = 'SAVE_HOLD_LOGGED';

export const SET_TRANSITION_PROGRESS = 'SET_TRANSITION_PROGRESS';

export const SET_GENERIC_PROGRESS_BAR = 'SET_GENERIC_PROGRESS_BAR';

export const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_SUCCESS';
export const GET_PROVINCE_FAILURE = 'GET_PROVINCE_FAILURE';
export const GET_PROVINCE = 'GET_PROVINCE';
export const SAVE_IMEI_MISMATCH = 'SAVE_IMEI_MISMATCH';
export const SAVE_SIX_DIGITS_IMEI = 'SAVE_SIX_DIGITS_IMEI';

export const SET_CHAT_REASON = 'SET_CHAT_REASON';

export const SAVE_GENERAL_ENQUIRY_DETAILS = 'SAVE_GENERAL_ENQUIRY_DETAILS';

export const CANCEL_BUTTON_CLICKED = 'CANCEL_BUTTON_CLICKED';
export const EDIT_BUTTON_CLICKED = 'EDIT_BUTTON_CLICKED';
export const SAVE_CUSTOMER_CASE_NUMBER = 'SAVE_CUSTOMER_CASE_NUMBER';
export const SAVE_SR_DOCUMENT = 'SAVE_SR_DOCUMENT';

export const TRANSFER_HOLDS_INIT = 'TRANSFER_HOLDS_INIT';
export const TRANSFER_HOLDS_SUCCESS = 'TRANSFER_HOLDS_SUCCESS';
export const TRANSFER_HOLDS_FAILURE = 'TRANSFER_HOLDS_FAILURE';
export const SAVE_PROCESSINCIDENT_PARAMS = 'SAVE_PROCESSINCIDENT_PARAMS';

export const CREATE_CHAT_ENQUIRY = 'CREATE_CHAT_ENQUIRY';
export const CREATE_CHAT_ENQUIRY_SUCCESS = 'CREATE_CHAT_ENQUIRY_SUCCESS';
export const CREATE_CHAT_ENQUIRY_FAILURE = 'CREATE_CHAT_ENQUIRY_FAILURE';

export const GET_SR = 'GET_SR';
export const GET_SR_SUCCESS = 'GET_SR_SUCCESS';
export const GET_SR_FAILURE = 'GET_SR_FAILURE';

export const SAVE_VISITSOURCE = 'SAVE_VISITSOURCE';

export const SAVE_SELECTED_ASSET_INSTANCE = 'SAVE_SELECTED_ASSET_INSTANCE';

export const SUBMIT_GENERAL_ENQUIRY = 'SUBMIT_GENERAL_ENQUIRY';
export const SUBMIT_GENERAL_ENQUIRY_SUCCESS = 'SUBMIT_GENERAL_ENQUIRY_SUCCESS';
export const SUBMIT_GENERAL_ENQUIRY_FAILURE = 'SUBMIT_GENERAL_ENQUIRY_FAILURE';
export const SET_ELIGIBILITY = 'SET_ELIGIBILITY';

export const SELECTED_LEAVING_AIS_REASON = 'SELECTED_LEAVING_AIS_REASON';
export const SELECTED_CALLBACK_DATE = 'SELECTED_CALLBACK_DATE';
export const SELECTED_CALLBACK_TIME = 'SELECTED_CALLBACK_TIME';
export const CALLBACK_COMMENT = 'CALLBACK_COMMENT';
export const LOAD_THANK_YOU = 'LOAD_THANK_YOU';
export const SAVE_1 = 'SAVE_1';
export const SAVE_2 = 'SAVE_2';
export const CHURN_SAVEDESK_ALTERNATE_CONTACT_NUMBER = 'CHURN_SAVEDESK_ALTERNATE_CONTACT_NUMBER';
export const ENTER_OTHER_REASON = 'ENTER_OTHER_REASON';
export const STAY_WITH_AIS = 'STAY_WITH_AIS';

export const WBKO = 'WBKO';
export const WBKO_SAVE_FIRST_NAME = 'WBKO_SAVE_FIRST_NAME';
export const WBKO_SAVE_MOBILE_NUMBER = 'WBKO_SAVE_MOBILE_NUMBER';
export const WBKO_SAVE_ID_NUMBER = 'WBKO_SAVE_ID_NUMBER';
export const WBKO_KEEP_OR_CONFIRM_COUNT = 'WBKO_KEEP_OR_CONFIRM_COUNT';
export const WBKO_CANCELLATION_REASON = 'WBKO_CANCELLATION_REASON';
export const SAVE_CHANGED_ADDRESS_STRING = 'SAVE_CHANGED_ADDRESS_STRING';
export const WBKO_TRACK_TRACE = 'WBKO_TRACK_TRACE';

export const WBKO_RESCHEDULE = 'WBKO_RESCHEDULE';

//Get Date Options (Shipping Method v2 Integration)
export const SHIPPING_METHODS_V2_INIT = 'SHIPPING_METHODS_V2_INIT';
export const SHIPPING_METHODS_V2_SUCCESS = 'SHIPPING_METHODS_V2_SUCCESS';
export const SHIPPING_METHODS_V2_FAILURE = 'SHIPPING_METHODS_V2_FAILURE';
export const SAVE_DELIVERY_SPEED = 'SAVE_DELIVERY_SPEED';
export const SAVE_DEFAULT_DELIVERY_SLOT = 'SAVE_DEFAULT_DELIVERY_SLOT';
export const SAVE_ESTIMATED_SHIPPMENT_DATE = 'SAVE_ESTIMATED_SHIPPMENT_DATE';
export const SAVE_ESTIMATED_DELIVERY_DATE = 'SAVE_ESTIMATED_DELIVERY_DATE';

export const SET_SELECTED_ASSET = 'SET_SELECTED_ASSET';
export const VERIFY_AGREEMENT_INIT = 'VERIFY_AGREEMENT_INIT';
export const VERIFY_AGREEMENT_SUCCESS = 'VERIFY_AGREEMENT_SUCCESS';
export const VERIFY_AGREEMENT_FAILURE = 'VERIFY_AGREEMENT_FAILURE';
export const CLEAR_AGREEMENT = 'CLEAR_AGREEMENT';
export const INCREASE_CAROUSALCOUNT = 'INCREASE_CAROUSALCOUNT';
export const INCREASE_CAROUSALCOUNT_FOR_SR = 'INCREASE_CAROUSALCOUNT_FOR_SR';
export const SAVE_SHIPPING_ADDDRESS = 'SAVE_SHIPPING_ADDDRESS';
export const SELECTED_ASSET_PROGRAM_NAME = 'SELECTED_ASSET_PROGRAM_NAME';
export const CUSTOMER_CASE_GRAPH_INIT = 'CUSTOMER_CASE_GRAPH_INIT';
export const CUSTOMER_CASE_GRAPH_SUCCESS = 'CUSTOMER_CASE_GRAPH_SUCCESS';
export const CUSTOMER_CASE_GRAPH_FAILURE = 'CUSTOMER_CASE_GRAPH_FAILURE';
export const GET_ALL_SR_SUCCESS = 'GET_ALL_SR_SUCCESS';
export const SAVE_SELECTED_SR_ASSET_ID = 'SAVE_SELECTED_SR_ASSET_ID';
export const SERVICE_UNAVAILABLE_COUNT = 'SERVICE_UNAVAILABLE_COUNT';
export const UPDATED_DEVICE_DATA = 'UPDATED_DEVICE_DATA';

export const SMART_STOCK_SELECTED = 'SMART_STOCK_SELECTED';
