import React, { Component, Fragment } from 'react';

import Message from '../../../../ais_components/Message';
import ProgressBarContainer from '../../../../ais_components/AjaxLoader/ProgressBarContainer';

class JourneyList extends Component {

  componentDidUpdate() {
    if(this.messagesEnd){
      setTimeout(() => {
          // this.scrollList.scrollTop = this.scrollList.scrollHeight;
          if(this.messagesEnd){
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
          }
      }, 50);
    }
  }

  render () {
    // console.log('JourneyList==', this.props.messages)
    return (
      <Fragment>
         <div className="ais_message-list" ref={el => this.scrollList = el}>
        {this.props.messages && this.props.messages.map((message, i) => {
          return <Message
                  areBusyVisible={this.props.areBusyVisible}
                  areOfflineVisible={this.props.areOfflineVisible}
                  areAllAgentBusy={this.props.areAllAgentBusy}
                  ccModalDisplay={this.props.ccModalDisplay}
                  isAddVisible={this.props.isAddVisible}
                  isSchVisible={this.props.isSchVisible}
                  message={message}
                  key={i}
                  updateJourneyList={this.props.updateJourneyList}
                  />
        })}
        <div id='spacer' ref={(el) => this.messagesEnd = el}></div>
        {(this.props.progressValue!==0) &&
            <ProgressBarContainer
              progressValue={this.props.progressValue}
              trickle={true}
              //trickleStep={this.props.trickleStep}
              //trickleInterval= {this.props.trickleInterval}
            />
          }
      </div>

      </Fragment>

      )
  }
}

export default JourneyList;