import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MESSAGE_CONSTANTS from "../../config/journey-messages";
import CONSTANTS from "../../utils/Constants";
import { cancelServiceRequestInit } from '../../actions/Dashboard/doFindAgreements';

import termsIcon from "../../images/ais-images/icon-terms.png";

class DisclaimerWithButtons extends Component {

    constructor(props) {
        super(props)
        this.state = {
            yesBtnClassName: "ais_device-select-button disclaimerButtons",
            disabled: false,
            noBtnClassName: "ais_device-select-button disclaimerButtons",
        };
        this.handleYesClick = this.handleYesClick.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
    }


    handleYesClick() {
        this.setState({
          yesBtnClassName: "ais_device-select-button ais_selected",
          disabled: true,
        });
        this.props.updateJourneyList(CONSTANTS.TRIAGE_FAILED, "EXTENDED_WARRANTY");
    }

    handleNoClick() {
        this.setState({
            noBtnClassName: "ais_device-select-button ais_selected",
            disabled: true,
        });
        this.props.updateJourneyList(CONSTANTS.CLOSE_REQUEST, MESSAGE_CONSTANTS.OEM_WARRENTY_SELECTED);

        this.props.cancelServiceRequestInit(this.props.cacheId);
    }


    render() {

        return (
            <div className="ais_current-terms-div ais_warranty-disclaimer">
                <div className="ais_header-terms-div">
                    <label className="ais_current-mobile-device-label">{MESSAGE_CONSTANTS.AGREE_WITH_TERMS}</label>
                    <div>
                        <img alt="terms-icn" className="ais_confirm-delivery-icn" src={termsIcon}/> </div>
                        <div className="ais_prefer-device-desc">
                            <p dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.STILL_IN_WARRANTY_DISCLAIMER }}></p>
                        </div>
                        <div className="ais_buttons-wrapper">
                            <button className={this.state.noBtnClassName} disabled={this.state.disabled} onClick={this.handleNoClick} >{MESSAGE_CONSTANTS.CANCEL}</button>
                            <button className={this.state.yesBtnClassName} disabled={this.state.disabled} onClick={this.handleYesClick} >{MESSAGE_CONSTANTS.PROCEED}</button>
                        </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        cancelServiceRequestInit
    }, dispatch)
};

const mapStateToProps = state => {
  return {
    cacheId: state.customerData.clientData.CacheId
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DisclaimerWithButtons);

