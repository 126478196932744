import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import { resumeServiceRequestInit, savePayload } from '../../actions/Dashboard/doFindAgreements';
import { getFormattedPopularName, replacePlaceholders, getFormattedDeliveryDate, getFormattedTotalFee, getLang } from '../../utils/utils';
import { RESUME_CANCELED } from '../../actions/Dashboard/actionTypes';
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import CONSTANTS from '../../utils/Constants';

import noImage from '../../images/ais-images/icon-enrolled-device.png';

class ResumeServiceRequest extends Component {

    constructor(props) {
        super(props);
        console.log("ResumeServiceRequest props:", props)
        this.state = {
            yesButtonClassName: "ais_align-popup-button-resume",
            noButtonClassName: "ais_align-popup-button-resume",
            disable: false,
            srNo: "",
            type: "",
            sim: "",
            deviceInfo: "",
            address: "",
            deliveryDate: "",
            deliveryTime: "",
            fee: "",
            cardType: "",
            cardNo: "",
            imageMake: "",
            imageModel: "",
            buttonClassName: "ais_confirm-details-button",
            paymentRetryAttemptState: this.props.paymentRetryAttempt || false,
            perilType: ""
        }
        this.createNewSRClick = this.createNewSRClick.bind(this);
        this.resumeSRClick = this.resumeSRClick.bind(this);
    }

    createNewSRClick() {
        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected-resume"
        });
        let lang = getLang()
        this.props.savePayload(RESUME_CANCELED, true);

        lang === 'th' ? this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESUME_ENQUIRY_OPTIONS, "เริ่มทำรายการใหม่") :
            this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESUME_ENQUIRY_OPTIONS, MESSAGE_CONSTANTS.NEW_SERVICE_REQ)
    }

    resumeSRClick() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected-resume"
        });

        if (this.props.isDocHoldApplied) {
            let lang = getLang()
            lang === 'th' ? this.props.updateJourneyList(CONSTANTS.DOWNLOAD_DOCUMENTS_RESUME, "ทำรายการต่อ") :
                this.props.updateJourneyList(CONSTANTS.DOWNLOAD_DOCUMENTS_RESUME, MESSAGE_CONSTANTS.PROCEED)
        } else {
            this.props.resumeServiceRequestInit(); // processIncidents & userInput is empty
        }

    }

    componentWillMount() {
        let { selectedReplacementDevice: { AssetCatalogName: enrolledAssetCatalogName, Make: enrolledMake, Model: enrolledModel, HorizonItemInformation = {}, HorizonItemInformation: { Make, Model, AssetCatalogName } = {} } = {}, caseType, cardType, fee, username, slot, simRequired, ccmask, customerCaseNumber, fullAddress } = this.props;
        let deviceSpecs = !_isEmpty(HorizonItemInformation) ?
            AssetCatalogName ? getFormattedPopularName(AssetCatalogName) : (Make && Model) ? {
                modelName: `${Make} ${Model}`,
                color: "",
                specs: ""
            } : {} :
            enrolledAssetCatalogName ? getFormattedPopularName(enrolledAssetCatalogName) : {};

        deviceSpecs = _isEmpty(deviceSpecs) ? {
            modelName: (enrolledMake || Make) && (enrolledModel || Model) ? `${enrolledMake.Name || Make} ${enrolledModel.Name || Model}` : "",
            color: "",
            specs: ""
        } : deviceSpecs;
        // let finalDeliverySlot = "";
        let filteredArr = MESSAGE_CONSTANTS.SLOTS_MAPPING.filter((item) => item.RESP_VALUE === slot);
        let finalDeliverySlot = !_isEmpty(filteredArr) ? filteredArr[0].DISPLAY_OPTION : null;
        let sim = (simRequired) ? CONSTANTS.WITH_SIM : CONSTANTS.WITHOUT_SIM;
        let cc = ccmask || "****";

        var totalAmount = fee && fee.CurrencyCode && fee.TotalAmount ?
            `${fee.CurrencyCode} ${getFormattedTotalFee(fee.TotalAmount)}` : null;

        var smartStockSelectedDeviceFee = !_isEmpty(HorizonItemInformation) ? 
        (HorizonItemInformation.ServiceFee ? 
        `${fee?.CurrencyCode || 'THB'} ${getFormattedTotalFee(`${parseFloat(HorizonItemInformation.ServiceFee).toFixed(2)}`)}` :
        null) : null

        this.setState({
            username: username.fullName,
            srNo: customerCaseNumber,
            type: caseType,
            deviceInfo: `${deviceSpecs.modelName} ${deviceSpecs.specs} ${deviceSpecs.color}`,
            sim: sim,
            address: fullAddress,
            deliveryDate: getFormattedDeliveryDate(this.props.expectedDeliveryDate, slot),
            deliveryTime: finalDeliverySlot,
            cardType: cardType,
            cardNo: cc,
            fee: smartStockSelectedDeviceFee || totalAmount,
            imageMake: Make,
            imageModel: Model,
            perilType: caseType
        });
    }


    render() {
        let { srNo, deviceInfo, address, cardType, cardNo, fee, username, imageMake = '', imageModel = '', deliveryDate, deliveryTime } = this.state;
        let { noServiceFee, hold, data, resumePoint, backOrderCase, customerCaseNumber, hasUnresolvedHolds, chargeOrder } = this.props;

        let deviceImageUC = imageMake && imageMake ? `${imageMake.toUpperCase()}_${imageModel.toUpperCase()}_S` : "";
        let deviceImageJPG = this.props.imageUrl ? `${this.props.imageUrl} ${deviceImageUC}.jpg` : "";
        let content = data.HEADER ? data.HEADER.indexOf("{Claim ID}") > -1 ? replacePlaceholders(data.HEADER, '{Claim ID}', customerCaseNumber) : data.HEADER : data.HEADER;

        if (resumePoint === 'CaptureIncidentDetails') {
            return (
                <div className="ais_current-form-device-div">
                    <div className="ais_current-form-device-header-div">
                        <label className="ais_current-mobile-device-label">{content}</label>
                    </div>
                    <div className="ais_current-popup-center-div">
                    </div>

                    <div className="ais_outer-align-button">

                        <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={this.createNewSRClick} >{this.props.data.BUTTONS[0]}</button>
                        <div className="ais_device-divider"></div>
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.resumeSRClick} >{this.props.data.BUTTONS[1]}</button>
                    </div>

                </div>)
        }

        return (
            <div className="ais_confirm-details-div">
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label">{content}</label>
                </div>
                <div className="ais_confirm-form-details-header-div">
                    <label className="ais_confirm-details-blue-label">{data.SR_NO}</label>
                    {this.state.perilType ?
                        <label className="ais_confirm-details-blue-label-custum">{data.TYPE}</label> : ""}
                    <label className="ais_confirm-details-dark-blue-label">{srNo}</label>
                    {this.state.perilType ?
                        <label className="ais_confirm-details-dark-blue-label-custum">{this.state.perilType}</label> : ""}

                    {!!deviceInfo.replace(/ /g, '') ?
                        <div className="ais_swop-device-header-div">
                            <label className="ais_confirm-details-blue-label">{data.DEVICE}</label>

                            <div>
                                <img alt="" className="ais_current-form-device-icn" src={deviceImageJPG} onError={(e) => { e.target.src = noImage }} />
                                <div className="ais_swop-device-name">{`${deviceInfo}`}</div>
                            </div>
                        </div> : ""}

                    {(address) && (resumePoint === 'CapturePaymentDetails' || resumePoint === 'CaptureShippingMethod' || hasUnresolvedHolds) ?
                        <div className="ais_swop-device-header-div">
                            <label className="ais_confirm-details-blue-label">{data.SHIPPING_TO}</label>
                            <div className="ais_other-details-desc">{username}</div>
                            {(address.AddressLine1) ? <div><label className="ss_address_txt_content">{address.AddressLine1}</label></div> : ''}
                            {(address.AddressLine2) ? <div><label className="ss_address_txt_content">{address.AddressLine2}</label></div> : ''}
                            {(address.AddressLine3) ? <div><label className="ss_address_txt_content">{address.AddressLine3}</label></div> : ''}
                            {(address.PostalCode) ? <div><label className="ss_address_txt_content">{address.PostalCode}</label></div> : ''}
                            {(address.City) ? <div><label className="ss_address_txt_content">{address.City}</label></div> : ''}
                            {(address.StateProvinceCode) ? <div><label className="ss_address_txt_content">{address.StateProvinceCode}</label></div> : ''}
                            {(address.CountryCode === "TH") ? <div><label className="ss_confirm-details-dark-label">{'Thailand'}</label></div> : ''}
                        </div> : ""}

                    {(!backOrderCase && deliveryDate && deliveryTime && _isEmpty(this.props.holds)) && (resumePoint === 'CapturePaymentDetails' || !hasUnresolvedHolds) ? <div className="ais_swop-device-header-div">
                        <label className="ais_confirm-details-blue-label">{data.DELIVERY_SCHEDULE}</label>
                        <div className="ais_other-details-desc">{`${deliveryDate}, ${deliveryTime}`}</div>
                    </div> : ""}

                    {!noServiceFee && fee ? <div className="ais_swop-device-header-div">
                        {cardType && cardNo ? <label className="ais_confirm-details-blue-label">{data.PAID_WITH}</label> : ""}
                        <label className="ais_confirm-details-blue-label">{data.FEE}</label>
                        <label className="ais_confirm-details-dark-blue-label">{cardType && cardNo ? `${cardType} Card ${cardNo}` : ""}</label>
                        <label className="ais_confirm-details-dark-blue-label">{fee}</label>
                    </div> : ""}
                </div>
                {hold || (chargeOrder && chargeOrder.ChargeOrderStatus && chargeOrder.ChargeOrderStatus.toUpperCase() === "CHARGED")?
                    <div className="ais_outer-align-button-top-bottom">
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.resumeSRClick} >{data.BUTTONS[1]}</button>
                    </div>
                    : !data.BUTTONS.length ? '' : <div className="ais_outer-align-button-top-bottom">
                        <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={this.createNewSRClick} >{data.BUTTONS[0]}</button>
                        <div className="ais_device-divider"></div>
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.resumeSRClick} >{data.BUTTONS[1]}</button>
                    </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    let { selectedDefectOption, determineIncidentResponse, hasUnresolvedHolds, holds, caseType, paymentRetryAttempt, shippingMethodsResult, simRequired, ccmask, customerCase, clientData, backOrderCase, selectedReplacementDevice, postalCode, serviceFee, chargeOrderDetails, noServiceFee, customerName, deteremineResumePoint } = state.customerData;
    return {
        isDocHoldApplied: _find(holds, { 'HoldType': 'DOCUMENT' }),
        holds,
        cacheId: clientData.CacheId,
        customerCase,
        customerName,
        resumePoint: deteremineResumePoint,
        paymentRetryAttempt,
        selectedReplacementDevice,
        slot: shippingMethodsResult ? shippingMethodsResult.ShippingMethodType : null,
        expectedDeliveryDate: shippingMethodsResult ? shippingMethodsResult.EstimatedDeliveryDate : null,
        caseType,
        simRequired,
        ccmask,
        postalCode,
        backOrderCase,
        hasUnresolvedHolds,
        noServiceFee,
        imageUrl: clientData.imageBaseUrl,
        fee: serviceFee,
        cardType: chargeOrderDetails ? chargeOrderDetails.CardBrand : null,
        username: customerName,
        customerCaseNumber: determineIncidentResponse ? determineIncidentResponse.CustomerCaseNumber : "",
        fullAddress: determineIncidentResponse ? determineIncidentResponse.ReceiverAddress : "",
        selectedDefectOption: selectedDefectOption,
        chargeOrder: determineIncidentResponse ? determineIncidentResponse.ChargeOrder : null
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        resumeServiceRequestInit,
        savePayload
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeServiceRequest);