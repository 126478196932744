import gql from 'graphql-tag';

export default gql`
mutation createChatAudit($input:CreateChatAudit!){
    createChatAudit(input:$input){
        visitorId
      createdAt
      auditLog
      chatAuditId
      expertName
      requestId
    }
}`;

