import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import {
    serviceOrderInit,
    processIncidentInit,
    endInteractionInit,
    setTriageDisplayed,
    triageResolvedInit,
    savePayload,
    uploadDocument
} from '../../actions/Dashboard/doFindAgreements';

import CONSTANTS from "../../utils/Constants";
import { SET_TRIAGE_SUCCESSFUL_FLAG } from '../../actions/Dashboard/actionTypes';

import helpIcon from "../../images/ais-images/icon-reset-to-factory-default.png";
import simIcon from "../../images/ais-images/icon-sim-card.png";
import featuresIcon from "../../images/ais-images/icon-turn-off-essentials.png";
import deviceOffIcon from "../../images/ais-images/icon-turn-off-phone.png";
import resolved from "../../images/ais-images/iconIssueResolved.png";
import MESSAGE_CONSTANTS from "../../config/journey-messages";

class CustomerSupportChat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
        }
        this.handleYesClick = this.handleYesClick.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
    }

    handleTriageLogic() {
        let { cacheId, dataprops: { source }, triageQuestionsDisplayed, enrolledDeviceInWarranty, setTriageDisplayed, updateJourneyList, triageResolvedInit, imeiMismatch, filesList, holds } = this.props;
        if (source === CONSTANTS.TRIAGE_ISSUE_RESOLVED && triageQuestionsDisplayed > 2) {

            this.props.savePayload(SET_TRIAGE_SUCCESSFUL_FLAG, false);

            updateJourneyList(null, MESSAGE_CONSTANTS.NO_TEXT);

            if (imeiMismatch || !_.isEmpty(holds)) {
                this.props.uploadDocument(cacheId, filesList);
            } else {
            updateJourneyList( CONSTANTS.CAPTURE_IW_REMARKS, null);
            }

            //     let TriageParameters = {
            //         SessionId: cacheId,
            //         ResolutionType: "TECHTRGKPD",
            //         TriageReason: "AGNTERR",
            //         TriageSuccessful: false,
            //         Notes: ""
            //    }

            // triageResolvedInit({ TriageParameters }, !enrolledDeviceInWarranty)
            return false;
        } if (source === CONSTANTS.TRIAGE_QUESTION && triageQuestionsDisplayed >= 2) {
            this.props.savePayload(SET_TRIAGE_SUCCESSFUL_FLAG, false);

            updateJourneyList(null, MESSAGE_CONSTANTS.NO_TEXT);

            if (imeiMismatch || !_.isEmpty(holds)) {
                this.props.uploadDocument(cacheId, filesList);
            } else {
            updateJourneyList( CONSTANTS.CAPTURE_IW_REMARKS, null);
            }
            //     let TriageParameters = {
            //         SessionId: cacheId,
            //         ResolutionType: "TECHTRGKPD",
            //         TriageReason: "AGNTERR",
            //         TriageSuccessful: false,
            //         Notes: ""
            //    }

            // triageResolvedInit({ TriageParameters }, !enrolledDeviceInWarranty)
            return false;
        }
        else if (source === CONSTANTS.TRIAGE_QUESTION) {
            setTriageDisplayed(triageQuestionsDisplayed + 1);
        }
    }

    handleNoClick() {
        // if(this.props.triageQuestionsDisplayed <2){
        this.props.updateJourneyList(this.props.dataprops.source, "No");
        // }

        this.setState({
            disable: true,
            noButtonClassName: "ais_align-popup-button-selected"
        });

        let { cacheId, replacementDevice } = this.props;

        if (this.props.dataprops.source === CONSTANTS.SOURCE_USER_INPUT.SIM_CARD_CONFIRMATION) {
            let isSimCard = false;
            this.props.serviceOrderInit(cacheId, replacementDevice, isSimCard, "REPLACEMENT");
        }

        this.handleTriageLogic();
    }
    handleYesClick() {

        const { cacheId, replacementDevice } = this.props;
        let { dataprops: { source } } = this.props;
        let typedText = "Yes";

        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });

        if (source === CONSTANTS.SOURCE_USER_INPUT.SIM_CARD_CONFIRMATION) {
            let isSimCard = true;
            this.props.serviceOrderInit(cacheId, replacementDevice, isSimCard, "REPLACEMENT");
        }

        this.props.updateJourneyList(source, typedText);
        if (source === CONSTANTS.TRIAGE_ISSUE_RESOLVED) {
            this.props.savePayload(SET_TRIAGE_SUCCESSFUL_FLAG, true);
            // if (this.props.imeiMismatch || !_.isEmpty(this.props.holds)) {
                // this.props.uploadDocument(cacheId, this.props.filesList);
            // } else {
                this.props.endInteractionInit(cacheId);
            // this.props.updateJourneyList( CONSTANTS.CAPTURE_IW_REMARKS, null);
            // }
            //     let TriageParameters = {
            //         SessionId: cacheId,
            //         ResolutionType: "TECHTRGKPD",
            //         TriageReason: "AGNTERR",
            //         TriageSuccessful: true,
            //         Notes: ""
            //    }
            //    this.props.triageResolvedInit({ TriageParameters }, false)
        }

        if (source === CONSTANTS.TRIAGE_QUESTION) {
            this.props.setTriageDisplayed(this.props.triageQuestionsDisplayed + 1);
        }
    }

    getIcon() {
        let img;
        let { dataprops: { source } } = this.props;

        if (source === CONSTANTS.SOURCE_USER_INPUT.CHAT_CONFIRMATION) {
            img = <div>  <img alt="help-icn" className="ais_confirm-delivery-icn" src={helpIcon} /> </div>
        } else if (source === CONSTANTS.SOURCE_USER_INPUT.SIM_CARD_CONFIRMATION) {
            img = <div>  <img alt="sim-icn" className="ais_confirm-delivery-icn" src={simIcon} /> </div>
        } else if (source === CONSTANTS.TRIAGE_QUESTION) {
            img = <div>  <img alt="battery-icn" className="ais_confirm-delivery-icn" src={deviceOffIcon} /> </div>
        } else if (source === CONSTANTS.TRIAGE_ISSUE_RESOLVED) {
            img = <div>  <img alt="battery-icn" className="ais_confirm-delivery-icn" src={this.props.data.ICON === "deviceOffIcon" ? deviceOffIcon : this.props.data.ICON === "deviceOffIcon" ? deviceOffIcon : featuresIcon} /> </div>
        }
        return img;
    }

    render() {

        return (
            <div className="ais_current-form-device-div">
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
                    {this.getIcon()}
                    <div className="ais_outer-align-button">
                        <button disabled={this.state.disable} className={this.state.noButtonClassName} onClick={this.handleNoClick} >{this.props.data.BUTTONS[0]}</button>
                        <div className="ais_device-divider" />
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.handleYesClick} >{this.props.data.BUTTONS[1]}</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        savePayload,
        serviceOrderInit,
        endInteractionInit,
        setTriageDisplayed,
        processIncidentInit,
        triageResolvedInit,
        uploadDocument
    }, dispatch)
};


const mapStateToProps = state => {
    let { selectedBrand, deviceDetails, imeiMismatch, filesList, holds, triageQuestionsDisplayed, clientData, caseType, selectedDefectOption, warrantyType, serviceRequestType, LFLDeviceInStock, LFLDevice, selectedReplacementDevice, baseItemId, } = state.customerData;
    return {
        cacheId: clientData.CacheId,
        triageQuestionsDisplayed,
        caseType,
        selectedDefectOption,
        enrolledDeviceInWarranty: warrantyType === CONSTANTS.IN_WARRANTY,
        serviceRequestType: serviceRequestType,
        replacementDevice: LFLDeviceInStock ? LFLDevice : selectedReplacementDevice,
        baseItemId,
        deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
        imeiMismatch, filesList, holds
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupportChat);
