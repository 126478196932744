import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from 'react-rangeslider';

import { endInteractionInit, submitSurvey } from '../../actions/Dashboard/doFindAgreements';
import { isDeviceOrWeb } from '../../utils/utils';
import MessageConstants from '../../config/journey-messages';

import 'react-rangeslider/lib/index.css';
import './feedback.css';
import ic_poor_active_big from  '../../images/mobile/ic-poor_active_big.png';
import ic_sad_active_big from '../../images/mobile/ic-sad_active_big.png';
import ic_neutral_active_big from '../../images/mobile/ic-neutral_active_big.png';
import ic_happy_active_big from '../../images/mobile/ic-happy_active_big.png';
import ic_excellent_active_big from  '../../images/mobile/ic-excellent_active_big.png';
import ic_poor_active from '../../images/mobile/ic-poor_active.png';
import ic_poor_default from '../../images/mobile/ic-poor_default.png';
import ic_sad_active from '../../images/mobile/ic-sad_active.png';
import ic_sad_default from '../../images/mobile/ic-sad_default.png';
import ic_neutral_active from '../../images/mobile/ic-neutral_active.png';
import ic_neutral_default from '../../images/mobile/ic-neutral_default.png';
import ic_happy_active from  '../../images/mobile/ic-happy_active.png';
import ic_happy_default from '../../images/mobile/ic-happy_default.png';
import ic_excellent_active from '../../images/mobile/ic-excellent_active.png';
import ic_excellent_default from '../../images/mobile/ic-excellent_default.png';
class Feedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            volume: MessageConstants.FEEDBACK.NPS_DEFAULT_SELECTED,
            npsLabel: MessageConstants.FEEDBACK.NPS_LABEL.EXTREMELY_LIKELY,
            csat: MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT,
            csatValue: MessageConstants.FEEDBACK.CSAT.HAPPY.VALUE,
            reason: '',
            labels: {}
        }
    }

    handleOnChange = (value) => {
        let npsLabelValue = MessageConstants.FEEDBACK.NPS_LABEL.EXTREMELY_LIKELY;
        if (value < MessageConstants.FEEDBACK.EXTREMELY_LIKELY_VALUE && value > MessageConstants.FEEDBACK.NPS_MIN) {
            npsLabelValue = MessageConstants.FEEDBACK.NPS_LABEL.LIKELY
        } else if (value === MessageConstants.FEEDBACK.NPS_MIN) {
            npsLabelValue = MessageConstants.FEEDBACK.NPS_LABEL.NOT_LIKELY
        }
        this.setState({
            volume: value,
            npsLabel: npsLabelValue
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.endInteraction === true) {
            if (isDeviceOrWeb() === 'mobile') {
                this.props.deviceConversationEnd();
            } else {
                this.props.desktopConversationEnd();
            }
        }
    }

    handleOnClick = (event) => {

        let value = MessageConstants.FEEDBACK.CSAT.HAPPY.VALUE;
        if (event.target.name === MessageConstants.FEEDBACK.CSAT.POOR.TEXT) {
            value = MessageConstants.FEEDBACK.CSAT.POOR.VALUE
        } else if (event.target.name === MessageConstants.FEEDBACK.CSAT.SAD.TEXT) {
            value = MessageConstants.FEEDBACK.CSAT.SAD.VALUE
        } else if (event.target.name === MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT) {
            value = MessageConstants.FEEDBACK.CSAT.NEUTRAL.VALUE
        } else if (event.target.name === MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT) {
            value = MessageConstants.FEEDBACK.CSAT.EXCELLENT.VALUE
        }
        this.setState({
            csat: event.target.name,
            csatValue: value,
        });
    }

    handleTextareaChange = (event) => {
        this.setState({
            reason: event.target.value
        });
    }

    handleSubmit = () => {
        // if(isDeviceOrWeb() === 'mobile'){
        //     this.props.deviceConversationEnd();
        // }else{
        // this.props.endInteractionInit(this.props.cacheId);
        // }
        this.setState({
            disable: true
        });
        let { cacheId, visitorId, clientId, interactionLineId, caseNumber, currentRequestDetails } = this.props;
        let customerCaseNumber = '';
        if (caseNumber) {
            customerCaseNumber = caseNumber.toString();
        } else {
            if (currentRequestDetails && currentRequestDetails.CustomerCaseNumber)
                customerCaseNumber = currentRequestDetails.CustomerCaseNumber.toString();
        }
        let { volume, reason, csatValue } = this.state;
        let results = [
            {
                QuestionId: this.props.surveyQuestions[0].QuestionId,
                Answer: volume.toString()
            },
            {
                QuestionId: this.props.surveyQuestions[1].QuestionId,
                Answer: reason.toString()
            },
            {
                QuestionId: this.props.surveyQuestions[2].QuestionId,
                Answer: csatValue.toString()
            }
        ];

        let submitSurveyRequest = {
            SessionId: cacheId,
            VisitorId: visitorId,
            SurveyId: this.props.surveyQuestions[0].SurveyId,
            ClientId: clientId,
            InteractionLineId: interactionLineId,
            CustomerCaseNumber: customerCaseNumber,
            ServiceRequestId: this.props.serviceRequestId,
            Results: results
        }

        // MIXPANEL.track(EVENTLIST.SURVEY_SUBMITTED, { "NPS" : volume, "Remark" : reason, "CSAT": csatValue}, function(){
        //     MIXPANEL.track(EVENTLIST.JOURNEY_COMPLETED, {"End Reason":  CONSTANTS.SURVEY_SUBMITTED});
        // });

        this.props.submitSurvey(submitSurveyRequest, this.props.cacheId);
    }

    componentWillMount() {
        if (this.props.surveyQuestions) {
            this.props.surveyQuestions.sort(function (a, b) { return a.DisplayOrder - b.DisplayOrder });
        }

        let labels = {};
        for (let i = MessageConstants.FEEDBACK.NPS_MIN + 1; i <= MessageConstants.FEEDBACK.NPS_MAX; i++) {
            labels[i] = i.toString();
        }
        this.setState({
            labels: labels
        });
    }

    render() {
        let { volume, npsLabel, csat, reason, labels, disable } = this.state;

        let { surveyQuestions } = this.props;
        return (
            <Fragment>
                {surveyQuestions && <div className="ais_feedback-parent">
                    <div className="ais_feedback-parent-div">
                        <div className="col-md-12 ais_first-question">
                            <label className="ais_question-label">{surveyQuestions[0].QuestionDescription}</label>
                            <span className="ais_nps-span">{npsLabel}</span>
                        </div>
                        <div className="col-md-12 ais_slider-div">
                            <Slider
                                min={MessageConstants.FEEDBACK.NPS_MIN}
                                max={MessageConstants.FEEDBACK.NPS_MAX}
                                value={volume}
                                orientation="horizontal"
                                onChange={this.handleOnChange}
                                step={MessageConstants.FEEDBACK.NPS_STEP}
                                labels={labels}
                                tooltip={false}
                            />
                        </div>
                        <div className="col-md-12">
                            <label className="ais_question-label">{surveyQuestions[1].QuestionDescription}</label>
                            <textarea value={reason} className={reason ? "ais_feedback-reason text-color" : "ais_feedback-reason"} placeholder="Your comments here..." onChange={this.handleTextareaChange} />
                        </div>
                        <div className="col-md-12">
                            <label className="ais_question-label">{surveyQuestions[2].QuestionDescription}</label>
                            <div>
                                {
                                    csat === MessageConstants.FEEDBACK.CSAT.POOR.TEXT ?
                                        <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.POOR.TEXT} src={ic_poor_active_big} />
                                        : csat === MessageConstants.FEEDBACK.CSAT.SAD.TEXT ?
                                            <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.SAD.TEXT} src={ic_sad_active_big} />
                                            : csat === MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT ?
                                                <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT} src={ic_neutral_active_big} />
                                                : csat === MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT ?
                                                    <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT} src={ic_happy_active_big} />
                                                    : csat === MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT ?
                                                        <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT} src={ic_excellent_active_big} />
                                                        : null
                                }
                                <span className="ais_csat-text">{csat}</span>
                            </div>
                            <div className="ais_outer-image-div" >
                                <div className="ais_face-img" onClick={this.handleOnClick}>
                                    {csat === MessageConstants.FEEDBACK.CSAT.POOR.TEXT ?
                                        <img name={MessageConstants.FEEDBACK.CSAT.POOR.TEXT} alt={MessageConstants.FEEDBACK.CSAT.POOR} src={ic_poor_active} />
                                        : <img name={MessageConstants.FEEDBACK.CSAT.POOR.TEXT} alt={MessageConstants.FEEDBACK.CSAT.POOR} src={ic_poor_default} />}

                                </div>
                                <div className="ais_face-img" onClick={this.handleOnClick}>
                                    {csat === MessageConstants.FEEDBACK.CSAT.SAD.TEXT ?
                                        <img name={MessageConstants.FEEDBACK.CSAT.SAD.TEXT} alt={MessageConstants.FEEDBACK.CSAT.SAD} src={ic_sad_active} />
                                        : <img name={MessageConstants.FEEDBACK.CSAT.SAD.TEXT} alt={MessageConstants.FEEDBACK.CSAT.SAD} src={ic_sad_default} />}
                                </div>
                                <div className="ais_face-img" onClick={this.handleOnClick}>
                                    {csat === MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT ?
                                        <img name={MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT} alt={MessageConstants.FEEDBACK.CSAT.NEUTRAL} src={ic_neutral_active} />
                                        : <img name={MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT} alt={MessageConstants.FEEDBACK.CSAT.NEUTRAL} src={ic_neutral_default} />}
                                </div>
                                <div className="ais_face-img" onClick={this.handleOnClick}>
                                    {csat === MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT ?
                                        <img name={MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT} alt={MessageConstants.FEEDBACK.CSAT.HAPPY} src={ic_happy_active} />
                                        : <img name={MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT} alt={MessageConstants.FEEDBACK.CSAT.HAPPY} src={ic_happy_default} />}
                                </div>
                                <div className="ais_face-img" onClick={this.handleOnClick}>
                                    {csat === MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT ?
                                        <img name={MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT} alt={MessageConstants.FEEDBACK.CSAT.EXCELLENT} src={ic_excellent_active} />
                                        : <img name={MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT} alt={MessageConstants.FEEDBACK.CSAT.EXCELLENT} src={ic_excellent_default} />}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-12 finish-div">
                Finish
                </div> */}
                        <div className="col-md-12">
                            <button disabled={disable} className="ais_finish-div" onClick={this.handleSubmit}>{MessageConstants.FEEDBACK.BUTTONS[0]}</button>
                        </div>
                    </div>
                </div>}
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        endInteractionInit,
        submitSurvey
    }, dispatch)
};


const mapStateToProps = state => {
    const { customerCase, clientData, surveyQuestions, visitorId, endInteraction, InteractionLineId, determineIncidentResponse, serviceRequestId } = state.customerData;
    return {
        surveyQuestions: surveyQuestions,
        clientId: clientData.ClientId,
        cacheId: clientData.CacheId,
        interactionLineId: InteractionLineId,
        visitorId: visitorId,
        caseNumber: customerCase.CustomerCaseNumber,
        endInteraction: endInteraction,
        currentRequestDetails: determineIncidentResponse,
        serviceRequestId: serviceRequestId
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Feedback);