import React, { Fragment, Component } from "react";
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import people_mobile from '../../images/ais-images/img-end-screendesktop.jpg';
import people_mobile_mobile from '../../images/ais-images/img-end-screenmobile.jpg';
import logo from "../../images/ais-images/ais-mobile-care-logo.png";
import wbko_thanks_logo from "../../images/ais-images/wbko-thanks-logo.svg"

class ThankYouPage extends Component {
  renderMsg() {
    let { stayWithAIS,Platform, isWbkoSelected } = this.props;
    isWbkoSelected ? stayWithAIS = 'WBKO' : stayWithAIS = stayWithAIS;
    switch(stayWithAIS) {
      case'WBKO' :
        return <div>
            <div className= 'center wbkoThankYouImg'><img src={wbko_thanks_logo} /> </div>
            <div className= 'center wbkoThankYouText' dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_THANK_YOU_TEXT }}/>
          </div>
      case 'AGENT_CHAT':
        return <div className="agent-chatr-thanks">
          <p>{MESSAGE_CONSTANTS.AGENT_CHAT_THANKYOU_MSG.SUB_HEADING}</p>
          <h4>{MESSAGE_CONSTANTS.AGENT_CHAT_THANKYOU_MSG.HEADING}</h4>
        </div>
      case true:
        return <div>
        <div className="thankyou-label" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.THANK_YOU_LABEL }}></div>
        <div className="thankyou-text" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.THANK_YOU_TEXT }}></div>
      </div>
      default:
        if(Platform === 'SaveDesk' ){
          return <div>
            <div className="thankyou-label" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.SAVEDESK_THANK_YOU_MSG1 }}></div>
           <div className="thankyou-text" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.SAVEDESK_THANK_YOU_MSG2 }}></div>
          </div>
        }
        return <div className='center ThankYouContent' dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.THANK_YOU_TEXT_REFUND }}></div>
    }
  }

  render() {
    let { stayWithAIS } = this.props;
    return (
      <Fragment>
        <div>
          <div className="d-flex ais-wrap thankyou-header">
            <span className="ais_logo "><img src={logo} /></span>
            <div className="ais_app-header-text-cus">{MESSAGE_CONSTANTS.CHURN_HEADER}</div>
          </div>
          <div className='break-line' />
          <div className='OuterData'>
            {this.renderMsg()}
            <div className='center thankyou-btn'>
              <a className='effectiveLink' href="/" >{MESSAGE_CONSTANTS.THANK_YOU_BTN_CLOSE}</a>
            </div>
          </div>
          <div className=' center margin124 imageData'>
            <img alt="enroll-address-icn" className="stay-background desktop_img " src={people_mobile} />
            <img alt="enroll-address-icn" className="stay-background  mobile_img " src={people_mobile_mobile} />
            {/* <div className="mobile_desktop_img" ></div> */}
          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = state => {
  let { stayWithAIS,Platform ,isWbkoSelected} = state.customerData;
  return { stayWithAIS,Platform,isWbkoSelected }
};
export default connect(mapStateToProps, null)(ThankYouPage);

