import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import CONSTANTS from "../../utils/Constants";
import { bindActionCreators } from 'redux'
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import {savePayload,submitEnquiryDetails} from "../../actions/Dashboard/doFindAgreements";
import classNames from "classnames";

class WbkoCancelationReasonNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDiv: null,
      selectedDisplayTextReasons: [],
      selectedValueReasons: [],
      selectedIndexReasons: [],
      disabled: false,
      backButtonClassName: "ais_align-popup-button",
      submitButtonClassName: "ais_align-popup-button",
    };
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.backButtonClicked = this.backButtonClicked.bind(this);
    this.submitButtonClicked = this.submitButtonClicked.bind(this);
  }
  backButtonClicked() {
    this.setState({
      disabled: true,
      backButtonClassName: "ais_align-popup-button-selected"
    });

    this.props.updateJourneyList(CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS_NEW, MESSAGE_CONSTANTS.WBKO_RESCHEDULE.BackButton)
  }
  submitButtonClicked() {
    if (this.state.selectedIndexReasons.length > 0) {
      this.setState({
        disabled: true,
        submitButtonClassName: "ais_align-popup-button-selected"
      });

      let reasons = []
      let displayTextReasons = []

      for(let elem of this.state.selectedIndexReasons) {
        displayTextReasons.push(MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelReasons[elem].DisplayText)
        reasons.push(MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelReasons[elem].Value)
      }

      let desc = `${MESSAGE_CONSTANTS.WBKO_INQ_DESC} ${reasons.join(", ")}`;
      let displayDesc = `${MESSAGE_CONSTANTS.WBKO_INQ_DESC} ${displayTextReasons.join(", ")}`;
      // export function submitEnquiryDetails(
      //   enquiryDesc,
      //   selectedInquiryType,
      //   enquiryNote,
      //   inquirySubType,
      //   alternateContactNumber = '',
      //   UpdateDeliveryAddress = null,
      //   UpdateDeliverySchedule = null,
      //   files = []
      // )
      this.props.savePayload(CONSTANTS.WBKO_CANCELLATION_REASON, { name: reasons.join(", "), text: reasons.join(", ") });
      this.props.submitEnquiryDetails(desc, "Cancel my delivery", displayDesc, CONSTANTS.ENQUIRY_SUB_REASON.CANCEL_SR);
    }
  }
  handleClick = (event, data) => {
    try {
      document.querySelectorAll('.ais_cancel_reason_button').forEach(elem => {
        elem.disabled = true;
      });
    } catch (err) {
      console.log("error in CancelDeliveryEnquiry button click", err)
    }

    let source;
    let {
      updateJourneyList, selectedEnquiryOption
    } = this.props;
    this.props.savePayload(CONSTANTS.WBKO_CANCELLATION_REASON, data);
    if (data.name === "OTHER") {
      this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY, data.text);
    } else {
      let desc = `${MESSAGE_CONSTANTS.WBKO_INQ_DESC} ${data.text}`;
      this.props.submitEnquiryDetails(desc, selectedEnquiryOption, desc, CONSTANTS.ENQUIRY_SUB_REASON.CANCEL_SR);
    }
    
    if (!this.state.selectedDiv) {
      this.setState({
        selectedDiv: data.text
      });
      updateJourneyList(source, name);
    }
  };
  _renderOptions(option, i) {
    let btnClasses = classNames({
      "ais_cancel_reason_button": true,
      disabled: this.state.selectedDiv,
      "ais_options-replacement-selected-div":
        this.state.selectedDiv === option.text,
      "ais_options-replacement-div": this.state.selectedDiv !== option.text
    });

    return (
      <button
        name={option.text}
        key={option.text}
        disabled={this.state.selectedDiv}
        className={btnClasses}
        onClick={(event) => this.handleClick(event, option)}
        style={{
          display: "block",
          position: "relative"
        }}
      >
        <label
          className={
            this.state.selectedDiv === option.text
              ? "ais_options-selected-label"
              : "ais_options-label"
          }
          style={{
            position: "absolute",
            float: "none",
            top: "unset",
            left: "unset"
          }}
        >
          {option.text}
        </label>
      </button>
    );
  }

  handleCheckboxClick = (event, index) => {
    let copy = [...this.state.selectedIndexReasons]
    let filteredArray = null;

    if (this.state.selectedIndexReasons.indexOf(index) > -1) {
      filteredArray = copy.filter(e => e !== index)
    } else {
      filteredArray = [...copy, index]
    }

    if (filteredArray) {
      filteredArray.sort();
      // console.log("filteredArray", filteredArray)
      this.setState({
        selectedIndexReasons: [...filteredArray]
      })
    }
  }

  render() {
    let { serviceRequestNumber, shippingStatus } = this.props;
    return (
      <div className="ais_current-form-device-div" style={{border: "0"}}>
        <div className="">
          <div className="ais_header-txt-div">
            <p style={{fontWeight: "bold"}}>
              {MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelReasonsTitle}
            </p>
            {MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelReasons &&
            <div style={{}}>
              {MESSAGE_CONSTANTS.WBKO_RESCHEDULE.CancelReasons.map((e, i) => {
                return (
                  <>
                  <div style={{display: "flex", alignItems: "start", marginBottom: "10px"}}>
                  <span 
                    style={{display: "inline-block", marginRight: "10px", minWidth: "24px"}} 
                    className={`ais-checkbox ${this.state.selectedIndexReasons && this.state.selectedIndexReasons.indexOf(i) > -1 ? "selected" : ""} ${this.state.disabled ? "disabled" : ""}`}
                    onClick={(event => this.handleCheckboxClick(event, i))}></span>
                    <span style={{display: "inline-block", textAlign: "left"}}>{e.DisplayText}</span>
                  </div>
                    
                  </>
                )
              }
              )}
            </div>
            }

            <div className="ais_outer-confirm-delivery-align-button">
              <button disabled={this.state.disabled} className={this.state.backButtonClassName} onClick={this.backButtonClicked} >{MESSAGE_CONSTANTS.WBKO_RESCHEDULE.BackButton}</button>
              <div className="ais_device-divider" />
              <button disabled={this.state.disabled} className={this.state.submitButtonClassName} onClick={this.submitButtonClicked} >{MESSAGE_CONSTANTS.WBKO_RESCHEDULE.SubmitButton}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { selectedEnquiryOption} = state.customerData;

  return {
    selectedEnquiryOption,
  }
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ savePayload, submitEnquiryDetails }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(WbkoCancelationReasonNew);