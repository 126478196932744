import React from 'react'
import ais_mobile_care_logo_3x from '../../images/ais-images/ais-mobile-care-logo@3x.png';
export default function PrivacyPolicyTH() {
    document.title = "Privacy Policy"

    return (

        <div>
            <div className="row header">
                {/*<div class="headerTermsText">Privacy Policy</div>*/}
                <div className="headerTermsTime">1 May 2018 9:31 AM</div>
            </div>
            <div className="">
                <div className=" topSpace">
                    <div className="text">
                        <img src={ais_mobile_care_logo_3x} alt className="mobileSwop-logo" />

                    </div> <br />
                    <div className="headerTopSpace">
                        <div className="text">
                            <center>
                                {/*<div class="row">
                      <div class="col-sm-6 col-sm-offset-3 col-xs-12 acc-header">AIS Mobile Care Chat Support Privacy Policy</div>
                  </div>*/}
                                <div >
                                    <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                        {/* Asurion Privacy Statement */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="generalHeader">
                                                <h4 className="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#general" aria-expanded="true" aria-controls="general">
                                                        <span className="headings">Asurion Privacy Statement</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="general" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="generalHeader">
                                                <div className="panel-body">
                                                    <h5>ปรับปรุงล่าสุดวันที่ 3 กันยายน 2562</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  What kind of customer information do we collect, and where did we get it? */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="service-summary-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#service-summary" aria-expanded="false" aria-controls="service-summary">
                                                        <span className="headings"> เราเห็นคุณค่าความเป็นส่วนตัวของท่าน</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="service-summary" className="panel-collapse collapse" role="tabpanel" aria-labelledby="service-summary-header">
                                                <div className="panel-body">
                                                    <p>ที่อชัวเรียน (ตามนิยามด้านล่าง) เราปกป้องคุ้มครองความเป็นส่วนตัวของท่านอย่างจริงจัง
                                                    เพื่อให้เราสามารถให้บริการของเรา ("บริการ") ให้แก่ท่าน เราจำเป็นต้องเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของท่านบางรายการ
                                                    เราต้องการให้ท่านเข้าใจว่าข้อมูลส่วนบุคคลใดบ้างที่เราเก็บรวบรวมและนำไปใช้งานอย่างไร
                      </p>
                                                    <p>เมื่อท่านใช้บริการของเรา หรือเมื่อเข้าถึงเว็บไซต์อชัวเรียน (ตามที่ระบุในหัวข้อ
                        <strong> ประเทศ </strong> ด้านล่าง) ท่านรับทราบว่า ท่าน (1) ได้อ่านและยอมรับนโยบายนี้โดยปราศจากข้อยกเว้น
                        (2) ยินยอมตามหลักปฏิบัติเรื่องความเป็นส่วนตัวของเรา รวมถึงการที่เราเก็บรวบรวม
                        ใช้งาน และเปิดเผยข้อมูลส่วนบุคคลของท่าน ตามที่ระบุในนโยบายนี้ และ (3) ตกลงผูกพันตนตามนโยบายนี้</p>
                                                    <p>หากท่านไม่ยินยอมตามนโยบายนี้ โปรดอย่าใช้บริการของเราหรือให้ข้อมูลส่วนบุคคลใด
                        ๆ </p>
                                                    <p><br />ในนโยบายนี้<br /> (ก) "เรา" หมายถึง บริษัทอชัวเรียนซึ่งเสนอหรือให้บริการแก่ท่าน
                        (ตามที่ระบุในหัวข้อ <strong> ประเทศ </strong> ด้านล่างนี้) และ<br /> ในหัวข้อ
                        <strong> ประเทศ</strong> ด้านล่างนี้ เราระบุรายการเปลี่ยนแปลงนโยบายนี้ซึ่งจะเปลี่ยนไปตามประเทศที่ท่านให้ข้อมูลส่วนบุคคลของท่าน
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  What do we do with your information? */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="enrolling-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#enrolling" aria-expanded="false" aria-controls="enrolling">
                                                        <span className="headings"> นโยบายนี้ครอบคลุมเรื่องใดบ้าง</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="enrolling" className="panel-collapse collapse" role="tabpanel" aria-labelledby="enrolling-header">
                                                <div className="panel-body">
                                                    <p>บริการใด ๆ ที่เชื่อมโยงหรืออ้างถึงนโยบายนี้ และการรวบรวม จัดเก็บ ถ่ายโอน แบ่งปัน
                        เปิดเผย และจัดการข้อมูลส่วนบุคคลของท่านเมื่อท่านใช้บริการของเราซึ่งเรานิยามไว้ด้านล่างนี้</p>
                                                    <p>นโยบายนี้ไม่ครอบคลุมถึง และเราไม่รับผิดชอบต่อเว็บไซต์หรือแอปพลิเคชันของบุคคลอื่นที่อาจเชื่อมโยงจากเว็บไซต์ของเรา
                                                    การเชื่อมโยงไปยังเว็บไซต์หรือแอปพลิเคชันของบุคคลอื่นนี้ ไม่ได้แสดงนัยถึงการรับรอง
                                                    ท่านควรตรวจพิจารณานโยบายความเป็นส่วนตัวของเว็บไซต์หรือแอปพลิเคชันของบุคคลอื่นเสมอ
                      </p>
                                                    <p>โปรดดูรายการเปลี่ยนแปลงเฉพาะแต่ละประเทศที่ส่วนท้ายของนโยบายนี้ ซึ่งจะใช้กับท่านหากท่านรับบริการในประเทศที่ระบุในรายการดังกล่าว</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  Who do we share your information with? */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="fees-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#fees" aria-expanded="false" aria-controls="fees">
                                                        <span className="headings"> ข้อมูลส่วนบุคคลที่เราเก็บรวบรวม</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="fees" className="panel-collapse collapse" role="tabpanel" aria-labelledby="fees-header">
                                                <div className="panel-body">
                                                    <p>เมื่อท่านใช้บริการของเรา ท่านยินยอมให้เราเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน ตัวอย่างของข้อมูลส่วนบุคคลของท่านที่เราอาจเก็บรวบรวมได้แก่
                      </p>
                                                    <li>ชื่อ</li>
                                                    <li>ที่อยู่สำหรับจัดส่งไปรษณีย์</li>
                                                    <li>ที่อยู่อีเมล</li>
                                                    <li>หมายเลขโทรศัพท์เคลื่อนที่/มือถือ</li>
                                                    <li>ข้อมูลที่บ่งชี้เครื่องโทรศัพท์เคลื่อนที่ของท่าน เช่น หมายเลข ESN (Electronic
                        Serial Number) หรือข้อมูล IMEI (International Mobile Equipment Identity)</li>
                                                    <li>หมายเลขประจำเครื่อง และที่อยู่ MAC</li>
                                                    <li>ข้อมูลที่เก็บรวบรวมภายหลังจากได้รับอนุญาตจากท่านระหว่างที่ท่านใช้บริการ
                      </li>
                                                    <li>ข้อมูลใด ๆ ที่ท่านให้ไว้เป็นส่วนหนึ่งของการขอรับบริการของเรา ("คำขอใช้บริการ")
                      </li>
                                                    <li>ข้อมูลอื่น ๆ ที่บ่งชี้เฉพาะถึงตัวท่านเอง หรือเชื่อมโยงหรือสามารถเชื่อมโยงถึงท่าน
                        โดยได้รับมาในระหว่างที่ให้บริการแก่ท่าน เช่น วันที่ท่านซื้อบริการของเรา และข้อมูลคุกกี้เมื่อท่านเข้าถึงเว็บไซต์ของเรา</li>
                                                    <p>อชัวเรียนจะพยายามเก็บรวบรวมข้อมูลส่วนบุคคลจากท่านโดยตรงเมื่อใดก็ตามที่ทำได้ ข้อมูลส่วนบุคคลของท่านอาจถูกเก็บรวบรวมโดยตรงจากท่านในระหว่างที่ท่านสนทนาทางโทรศัพท์กับเรา
                                                    เมื่อท่านส่งแบบฟอร์มให้แก่เรา จากบริษัทในเครืออชัวเรียน จากบันทึกของเราเกี่ยวกับวิธีการที่ท่านใช้บริการของเรา
                                                    หรือเมื่อท่านกรอกข้อมูลในออนไลน์อินเทอร์เฟซ รวมถึงแอปพลิเคชันมือถือ และแหล่งอื่น
                        ๆ เมื่อท่านให้ข้อมูลส่วนบุคคลของท่าน ท่านยินยอมอย่างชัดแจ้งให้เราเก็บรวบรวมข้อมูลดังกล่าวได้</p>
                                                    <p>เพื่อเป็นส่วนหนึ่งของคำขอใช้บริการ อชัวเรียนหรือบริษัทในเครืออชัวเรียนอาจจำเป็นต้องตรวจสอบตัวตนท่านโดยขอให้ท่านแสดงใบอนุญาตขับขี่รถยนต์
                                                    หนังสือเดินทาง บัตรประจำตัวทหาร หรือบัตรประจำตัวประเภทอื่น ๆ นอกจากนี้ เพื่อประมวลผลคำขอใช้บริการของท่าน
                                                    อชัวเรียนหรือบริษัทในเครืออชัวเรียนอาจขอข้อมูลการจัดส่งและการเรียกเก็บเงิน
                        ข้อมูลบัตรเครดิต หรือวิธีการชำระเงินอื่น ๆ ที่ท่านเลือกด้วย</p>
                                                    <p>ขอเรียนให้ทราบว่า หากเราไม่มีข้อมูลส่วนบุคคลของท่าน เราอาจไม่อยู่ในสถานะที่จะดำเนินการต่อไปนี้ได้</p>
                                                    <li>ให้บริการของเราแก่ท่าน (หรือแก่บริษัทที่เราให้บริการซึ่งเป็นบริษัทที่ท่านทำสัญญาไว้)
                                                    ตามมาตรฐานเดิมหรือไม่ได้เลย
                      </li>
                                                    <li>ให้ข้อมูลแก่ท่าน เกี่ยวกับบริการที่ท่านอาจต้องการ รวมถึง ข้อมูลเกี่ยวกับรายการส่งเสริมการขายพิเศษ
                                                    และ/หรือ
                      </li>
                                                    <li>ปรับแต่งเนื้อหาเว็บไซต์ของเราให้ตรงตามความต้องการของท่าน และท่านอาจไม่ได้รับความเพลิดเพลินหรือประโยชน์อย่างเต็มที่ในการเยี่ยมชมเว็บไซต์ของเรา</li>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Access and Choice */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="termination-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#termination" aria-expanded="false" aria-controls="termination">
                                                        <span className="headings">วัตถุประสงค์ที่เราต้องเก็บรวบรวม ใช้งาน และเปิดเผยข้อมูลส่วนบุคคลของท่าน</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="termination" className="panel-collapse collapse" role="tabpanel" aria-labelledby="termination-header">
                                                <div className="panel-body">
                                                    <p>ในปัจจุบันหรือในอนาคต อชัวเรียนอาจเก็บรวบรวม ใช้งาน และเปิดเผยข้อมูลส่วนบุคคลของท่านตามที่กฎหมายอนุญาตหรือกำหนด
                        และ/หรือเพื่อวัตถุประสงค์ใด ๆ หรือทั้งหมดดังต่อไปนี้ ("วัตถุประสงค์")</p>
                                                    <li>ให้บริการแก่ท่านหรือดำเนินการตามคำขอใช้บริการของท่าน</li>
                                                    <li>ดำเนินการให้รายการสั่งซื้อของท่านเสร็จสมบูรณ์</li>
                                                    <li>ยืนยันและตรวจสอบตัวตนของท่าน</li>
                                                    <li>เปิดใช้และรักษาสถานะบัญชีผู้ใช้ของท่าน</li>
                                                    <li>จัดการและแก้ไขข้อขัดแย้งเรื่องการเรียกเก็บเงิน</li>
                                                    <li>ติดตามตรวจสอบและรักษาประสิทธิภาพและการทำงานของเครื่องโทรศัพท์เคลื่อนที่ให้สอดคล้องกับสิ่งที่ท่านเลือก</li>
                                                    <li>ให้การสนับสนุนด้านเทคนิคและฟังก์ชันคลิกทูแชท (Click-to-Chat)</li>
                                                    <li>คุ้มครองและปรับปรุงบริการของเรา</li>
                                                    <li>นำเสนอข่าวสารล่าสุด ประกาศ และข้อมูลอื่น ๆ เกี่ยวกับผลิตภัณฑ์หรือบริการจากอชัวเรียนหรือบริษัทในเครือของอชัวเรียนตามที่กฎหมายอนุญาต
                                                    (หมายเหตุ ท่านอาจเลือกเพิกถอนความยินยอมของท่านเพื่อมิให้อชัวเรียนใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ทางการตลาดหรือการส่งเสริมการขายเมื่อใดก็ได้
                                                    โดยติดต่ออชัวเรียนตามรายละเอียดที่ปรากฏในหัวข้อ หากมีข้อสงสัย ของนโยบายนี้
                      </li>
                                                    <li>ตอบข้อซักถาม ข้อสงสัย ข้อคิดเห็น และข้อชี้แนะของท่าน </li>
                                                    <li>ป้องกัน ตรวจจับ หรือสืบสวนอาชญกรรมและการกระทำอันมิชอบด้วยกฎหมายทั้งหลาย ซึ่งรวมถึง
                                                    การฉ้อโกง และการฟอกเงิน หรือการให้เงินทุนสนับสนุนผู้ก่อการร้าย ตลอดจนการวิเคราะห์และจัดการความเสี่ยงทางการค้า
                      </li>
                                                    <li>ปฏิบัติตามกฎหมาย ระเบียบ ประมวลหลักปฏิบัติ แนวทาง หรือข้อบังคับที่เกี่ยวข้อง
                        หรือช่วยเหลือในการบังคับใช้กฎหมาย และการสืบสวนที่ดำเนินการโดยหน่วยงานราชการและ/หรือหน่วยงานที่กำกับดูแล</li>
                                                    <li>ในส่วนที่เกี่ยวกับการเรียกร้อง การฟ้องร้อง หรือการดำเนินคดีใด ๆ (รวมถึง แต่ไม่จำกัดเพียง
                                                    การร่างและตรวจเอกสาร เอกสารเกี่ยวกับธุรกรรม การขอรับคำปรึกษาด้านกฎหมาย และการอำนวยความสะดวกในการระงับข้อพิพาท)
                                                    และ/หรือการคุ้มครองและบังคับตามสิทธิและหน้าที่ทางสัญญาและทางกฎหมาย
                      </li>
                                                    <li>ปรับปรุงบันทึกข้อมูลของเราและรายละเอียดการติดต่อของท่านให้เป็นข้อมูลล่าสุดตลอดเวลา</li>
                                                    <li>ติดตามและวิเคราะห์ข้อมูลที่ตัดตัวบ่งชี้ และให้ข้อมูลดังกล่าวแก่บุคคลภายนอกที่ให้บริการแก่อชัวเรียน</li>
                                                    <li>ในเชิงสถิติ เพื่อช่วยปรับปรุงบริการ</li>
                                                    <li>ป้องกันการฉ้อโกงและการกระทำที่มีแนวโน้มเป็นการฉ้อโกง</li>
                                                    <li>แจ้งท่านเกี่ยวกับการละเมิดข้อมูลส่วนบุคคลของท่านใด ๆ ที่ทราบ</li>
                                                    <li>รักษาความปลอดภัยและความสมบูรณ์ของระบบและบริการของอชัวเรียน</li>
                                                    <li>ทำตลาดสำหรับบริการที่เกี่ยวกับบริการที่ท่านสมัคร</li>
                                                    <li>ส่งข้อมูลให้แก่บริษัทในเครือของเราและหน่วยงานราชการและ/หรือหน่วยงานกำกับดูแลที่เกี่ยวข้อง
                        เพื่อวัตถุประสงค์ดังกล่าวข้างต้น และ</li>
                                                    <p>วัตถุประสงค์ที่เกี่ยวข้องอื่นใดที่อาจคาดหมายได้ตามสมควรจากการที่ท่านให้ข้อมูลดังกล่าว</p>
                                                    <p>วัตถุประสงค์เหล่านี้อาจยังคงใช้บังคับอยู่แม้เมื่อเกิดสถานการณ์ที่ความสัมพันธ์ระหว่างท่านกับเราได้สิ้นสุดลงแล้วหรือเปลี่ยนแปลงไปในลักษณะใดแล้วก็ตาม
                                                    ในกรณีเช่นว่านั้น เราอาจยังคงเก็บรักษาข้อมูลส่วนบุคคลเกี่ยวกับท่านไว้ และใช้งานหรือเปิดเผยข้อมูลดังกล่าวเพื่อวัตถุประสงค์ทางกฎหมายและ/หรือทางธุรกิจต่อไป
                                                    รวมถึงเพื่อปฏิบัติตามกฎหมายในประเทศที่ท่านรับบริการ
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Revising or updating this Privacy Statement */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="nochange-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#nochange" aria-expanded="false" aria-controls="nochange">
                                                        <span className="headings">เราแบ่งปันและถ่ายโอนข้อมูลส่วนบุคคลของท่านอย่างไร</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="nochange" className="panel-collapse collapse" role="tabpanel" aria-labelledby="nochange-header">
                                                <div className="panel-body">
                                                    <p>เราอาจแบ่งปันและถ่ายโอนข้อมูลส่วนบุคคลภายในกลุ่มบริษัทอชัวเรียน และผู้ให้บริการของอชัวเรียนที่เราเก็บรวบรวมข้อมูลเกี่ยวกับท่านนอกประเทศที่ท่านรับบริการ
                                                    แต่เพียงเพื่อวัตถุประสงค์ข้างต้นเท่านั้น เราจะไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกที่ไม่เกี่ยวข้อง
                                                    ยกเว้นตามที่เปิดเผยในนโยบายนี้
                      </p>
                                                    <p>อชัวเรียนอาจถ่ายโอนหรืออนุญาตบุคคลหรือบริษัทต่อไปนี้ให้ใช้ข้อมูลส่วนบุคคลของท่านได้</p>
                                                    <li>บริษัทที่ไม่ใช่บริษัทในเครือ เพื่อช่วยเราในการให้ รักษา และดำเนินการบริการและการตลาดของเรา
                        บริษัทเหล่านี้ต้องปฏิบัติตามหลักการที่กำหนดในนโยบายนี้ และต้องใช้ข้อมูลส่วนบุคคลเช่นว่านี้ตามวัตถุประสงค์ที่ให้ข้อมูลนั้นไปเท่านั้น</li>
                                                    <li>บุคคลภายนอก ในกรณีการซื้อ ขาย (รวมถึงการชำระบัญชี การจำหน่ายทรัพย์เป็นตัวเงิน
                                                    การยึดทรัพย์ที่จำนองไว้ การกลับเข้าครอบครอง) การให้เช่า การควบ การรวม หรือการได้มาโดยประเภทอื่นใด
                                                    การจำหน่าย การโอน การโอนสิทธิ หรือการให้เงินทุน สำหรับส่วนใด ๆ หรือทั้งหมดของธุรกิจหรือทรัพย์สินหรือหุ้นของธุรกิจของเรา
                                                    หรือการแบ่งแยกธุรกิจ ไม่ว่าจะเป็นการเสนอหรือเกิดขึ้นจริงก็ตาม เพื่อให้ท่านยังคงได้รับผลิตภัณฑ์และบริการเช่นเดียวกันหรือที่คล้ายคลึงกันจากบุคคลภายนอกนั้น
                                                    ในกรณีเช่นว่านั้น เราจะแจ้งให้ทราบถึงการเปลี่ยนแปลงที่มีนัยสำคัญต่อนโยบายนี้
                                                    หรือบริการของเรา ตามวิธีการที่ระบุในนโยบายนี้ ในกรณีเช่นนี้ อาจมีการแบ่งปันข้อมูลส่วนบุคคลให้แก่ผู้ซื้อหรือผู้รับโอน
                        (ตามจริงหรือในอนาคต) หรือธุรกิจที่ซื้อใหม่ด้วย</li>
                                                    <p>นอกจากนี้ อชัวเรียนอาจเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อ</p>
                                                    <li>ปฏิบัติตามกฎหมายและ/หรือกระบวนการทางกฎหมายที่มีการทำคำขออย่างเป็นทางการ (เช่น
                        คำขอจากหน่วยงานที่กำกับดูแลด้านปกครอง คดีแพ่ง หมายเรียก คำสั่งศาล หรือกระบวนพิจารณาคดียุติธรรมหรือคดีปกครอง)</li>
                                                    <li>ปกป้องสิทธิและ/หรือทรัพย์สินของเรา หรือสิทธิและทรัพย์สินของผู้อื่น</li>
                                                    <li>บังคับตามนโยบายนี้</li>
                                                    <li>แก้ข้อกล่าวหาที่ว่าเนื้อหาของการติดต่อสื่อสารมีการละเมิดสิทธิของอีกฝ่ายหนึ่ง</li>
                                                    <li>ตรวจสอบ ป้องกัน และแก้ไขโดยประการอื่นใด ซึ่งการฉ้อโกง การรักษาความปลอดภัย หรือปัญหาด้านเทคนิค</li>
                                                </div>
                                            </div>
                                        </div>
                                        {/* APEC Participation */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-" aria-expanded="false" aria-controls="data-privacy-">
                                                        <span className="headings">เราปกป้องข้อมูลส่วนบุคคลของท่านอย่างไร </span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>เราใช้หลักปฏิบัติที่ดีที่สุดในการควบคุมทางเทคนิค การบริหารจัดการ และทางกายภาพตามสมควรในเชิงพาณิชย์
                                                    เพื่อปกป้องข้อมูลส่วนบุคคลของท่าน ซึ่งรวมถึงการเข้ารหัสลับข้อมูลตามความเหมาะสม
                                                    อย่างไรก็ตาม เนื่องจากอินเทอร์เน็ตมิใช้สภาพแวดล้อมที่มีความปลอดภัย 100% เราจึงไม่สามารถดำเนินการรักษาความปลอดภัยข้อมูลส่วนบุคคลของท่านตลอดระยะเวลาที่มีการส่งข้อมูลระหว่างท่านกับเราได้
                                                    ด้วยเหตุนี้ ท่านจึงยอมรับความเสี่ยงเอง เมื่อท่านส่งข้อมูลส่วนบุคคล อย่างไรก็ตาม
                                                    เราพยายามปกป้องรักษาข้อมูลของท่าน รวมทั้งตรวจสอบและเพิ่มมาตรการรักษาความปลอดภัยข้อมูลอย่างสม่ำเสมอ
                                                    เราจะแจ้งให้ท่านทราบหากได้รับการยืนยันว่ามีการละเมิดการรักษาความปลอดภัยของข้อมูลส่วนบุคคลของท่านตามที่กฎหมายที่ใช้บังคับในประเทศที่ท่านรับบริการกำหนด
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* How long we retain your personal information */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-1" aria-expanded="false" aria-controls="data-privacy-1">
                                                        <span className="headings">เราจะเก็บข้อมูลส่วนบุคคลของท่านไว้นานเท่าใด</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>อชัวเรียนจะเก็บข้อมูลส่วนบุคคลของท่านไว้เป็นระยะเวลาเท่าที่จำเป็นเพื่อดำเนินการตามวัตถุประสงค์ที่ระบุในนโยบายนี้
                                                    และตามกฎหมายที่ใช้บังคับในประเทศที่ท่านรับบริการกำหนด
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* How you are notified if this Policy changes */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-2" aria-expanded="false" aria-controls="data-privacy-2">
                                                        <span className="headings">ท่านจะทราบได้อย่างไร หากนโยบายมีการเปลี่ยนแปลง</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>เราอาจเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว ซึ่งหากมีการเปลี่ยนแปลง เราจะลงประกาศนโยบายฉบับที่ปรับปรุงล่าสุดโดยระบุวันที่แก้ไขครั้งล่าสุดไว้ด้วย
                                                    การที่ท่านใช้ผลิตภัณฑ์หรือบริการอย่างต่อเนื่อง หมายความว่าท่านยินยอมตามนโยบายที่ปรับปรุงล่าสุดดังกล่าว
                                                    ท่านควรอ่านนโยบายนี้เป็นระยะ ๆ เพื่อทำความเข้าใจเรื่องหลักปฏิบัติความเป็นส่วนตัวของเรา
                      </p>
                                                    <p>หากเราเปลี่ยนแปลงนโยบายนี้ในสาระสำคัญ เราจะเผยแพร่ในเว็บไซต์อชัวเรียน หรืออีเมลถึงท่าน
                                                    เพื่อขอรับความยินยอมหากกฎหมายที่ใช้บังคับในประเทศที่ท่านรับบริการกำหนดไว้
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Communicating with you electronically */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-3" aria-expanded="false" aria-controls="data-privacy-3">
                                                        <span className="headings">การติดต่อสื่อสารกับท่านทางอิเล็กทรอนิกส์</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>เมื่อท่านใช้บริการของเรา ท่านตกลงว่าเราสามารถติดต่อสื่อสารกับท่านทางอิเล็กทรอนิกส์ได้
                                                    ทั้งนี้ รวมถึงการประกาศเรื่องใด ๆ ที่จำเป็น (เช่น ด้านกฎหมาย เทคนิค กฎระเบียบ
                                                    การรักษาความปลอดภัย ความเป็นส่วนตัว) ซึ่งเกี่ยวข้องการที่ท่านใช้บริการ
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Commercial messages */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-4" aria-expanded="false" aria-controls="data-privacy-4">
                                                        <span className="headings">ข้อความโฆษณา</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>หากจำเป็น เราจะขอความยินยอมอย่างชัดแจ้งจากท่านก่อนที่จะส่งข้อความดังกล่าว ท่านอาจเพิกถอนความยินยอมของท่านได้โดยใช้ลิงก์
                                                    "ยกเลิกรับข่าวสาร" ที่อยู่ด้านล่างข้อความของเรา ติดต่อเราตามที่อยู่ที่ปรากฏข้างต้นนี้
                        หรือส่งอีเมลไปที่ <a href="/" target="_blank" rel="noopener">privacy@asurion.com</a>.
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*Your rights related to correction and access */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-5" aria-expanded="false" aria-controls="data-privacy-5">
                                                        <span className="headings">สิทธิของท่านเกี่ยวกับการแก้ไขและการเข้าถึง</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>ขณะที่เราดำเนินการต่าง ๆ เพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลของท่านถูกต้องและสมบูรณ์
                                                    เรามีความเชื่อมั่นว่าท่านได้ให้ข้อมูลส่วนบุคคลที่ถูกต้องและสมบูรณ์ขณะโต้ตอบกับเรา
                      </p>
                                                    <p>เมื่อท่านขอและตามที่กฎหมายที่ใช้บังคับในประเทศที่ท่านรับบริการอนุญาตหรือกำหนด
                                                    และสอดคล้องตามสัญญาความร่วมมือที่เกี่ยวข้องของเรา ท่านอาจขอให้อชัวเรียนดำเนินการต่อไปนี้กับข้อมูลส่วนบุคคลของท่านได้
                                                    (1) จัดทำสำเนาให้แก่ท่าน (2) แก้ไขให้ถูกต้อง (3) ปรับปรุงให้เป็นข้อมูลล่าสุด
                                                    (4) ลบออก ในการทำคำขอดังกล่าว หรือหากท่านมีข้อร้องเรียนเกี่ยวกับความเป็นส่วนตัวของท่าน
                                                    โปรดติดต่ออชัวเรียนทางไปรษณีย์ หรืออีเมล ตามที่ระบุในหัวข้อ
                        <strong> หากมีข้อสงสัย </strong> ของนโยบายนี้ เราจะตอบกลับคำขอของท่านภายในกำหนดเวลาตามสมควร</p>
                                                    <p>หากท่านมีความกังวลใจที่ไม่ได้รับการแก้ไขเกี่ยวกับความเป็นส่วนตัวหรือการใช้ข้อมูล
                                                    ซึ่งเรายังไม่จัดการจนเป็นที่พึงพอใจ โปรดติดต่อผู้ให้บริการระงับข้อพิพาทของเราซึ่งเป็นบุคคลภายนอกและมีสำนักงานหลักอยู่ในประเทศสหรัฐอเมริกา
                        ที่ <a href="/" target="_blank"> https://feedback-form.truste.com/watchdog/request. </a>                                              </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Cookies and Web Beacons  */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-6" aria-expanded="false" aria-controls="data-privacy-6">
                                                        <span className="headings">คุกกี้และเว็บบีคอน</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>เมื่อท่านใช้เว็บไซต์ของเรา เราอาจเก็บรวบรวมข้อมูลบางรายการโดยอัตโนมัติด้วยเทคโนโลยีหลากหลาย
                                                    เช่น คุกกี้ (ไฟล์ข้อความขนาดเล็กที่สร้างขึ้นบนเครื่องคอมพิวเตอร์หรือโทรศัพท์เมื่อเว็บเบราว์เซอร์ของเครื่องคอมพิวเตอร์หรือโทรศัพท์โหลดเว็บไซต์หรือเว็บแอปพลิเคชัน)
                                                    หรือเว็บบีคอน (ไฟล์ภาพโปร่งใสที่ใช้ติดตามการนาวิเกตและพฤติกรรมของบุคคลผ่านเว็บไซต์)
                      </p>
                                                    <p>เราใช้เทคโนโลยีเหล่านี้ในเว็บไซต์ของเราเพื่อให้ท่านใช้งานเว็บไซต์ได้อย่างมีประสิทธิภาพมากขึ้น
                                                    เทคโนโลยีเหล่านี้ที่เราจัดไว้ในเครื่องโทรศัพท์เคลื่อนที่ของท่านอาจช่วยให้เราปรับแต่งเว็บไซต์ของเราให้ตรงตามความต้องการของท่านได้มากยิ่งขึ้นอีกด้วย
                                                    รวมทั้งจดจำท่านได้ว่าเคยเยี่ยมชมเว็บไซต์ของเราแล้ว บันทึกข้อมูลความชอบส่วนบุคคลของท่าน
                                                    และรักษาข้อมูลการลงบันทึกเข้า (Login) และรหัสผ่านในส่วนที่มีการรักษาความปลอดภัยภายในเว็บไซต์ของเรา
                                                    นอกจากนี้ เรายังใช้เทคโนโลยีเหล่านี้ในการวิเคราะห์ลักษณะการใช้เว็บไซต์ของผู้ใช้ทั้งหลาย
                                                    และติดตามตรวจสอบประสิทธิภาพการทำงานของเว็บไซต์ของเราอีกด้วย เพื่อหลีกเลี่ยงข้อสงสัย
                                                    เรามิได้นำข้อมูลทั่วไปที่เก็บรวบรวมผ่านเทคโนโลยีเหล่านี้มารวมกับข้อมูลส่วนบุคคลอื่น
                        ๆ เพื่อทำให้บ่งชี้ตัวตนของท่านได้ เมื่อท่านใช้งานเว็บไซต์ดังกล่าว ท่านตกลงว่าเราสามารถนำเทคโนโลยีเหล่านี้มาใช้กับเครื่องโทรศัพท์เคลื่อนที่ของท่านได้</p>
                                                    <p>ท่านมีสิทธิเลือกปรับการตั้งค่าเบราว์เซอร์หรือเครื่องโทรศัพท์เคลื่อนที่ของท่านให้จำกัดหรือลดขอบเขตการใช้เทคโนโลยีเหล่านี้ได้
                        อย่างไรก็ตาม หากท่านเลือกปรับการตั้งค่าเช่นว่านั้น อาจส่งผลกระทบต่อการทำงานของเว็บไซต์และการใช้งานเว็บไซต์ของท่านได้</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Children under 18 */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-7" aria-expanded="false" aria-controls="data-privacy-7">
                                                        <span className="headings">เด็กอายุต่ำกว่า 18 ปี</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>บริการนี้ไม่เหมาะสำหรับเด็กอายุต่ำกว่า 18 ปี เว้นแต่ว่ากฎหมายที่ใช้บังคับในประเทศที่ท่านรับบริการจะอนุญาต
                                                    หากเราทราบ เราจะไม่เก็บรวบรวมข้อมูลจากเด็กอายุต่ำกว่า 18 ปี หากท่านทราบว่าบุตรหลานของท่านเข้าถึงบริการและให้ข้อมูลส่วนบุคคลโดยท่านไม่ได้ให้ความยินยอมก่อน
                                                    ในประเทศที่กฎหมายไม่อนุญาต โปรดติดต่อเราตามข้อมูลการติดต่อที่ให้ไว้ด้านล่างนี้
                                                    เราจะดำเนินการลบข้อมูลส่วนบุคคลดังกล่าวออกจากระบบจัดเก็บข้อมูล และยกเลิกบัญชีผู้ใช้ดังกล่าวหากเด็กอายุต่ำกว่า
                                                    18 ปี เข้าถึงบริการในประเทศที่กฎหมายในประเทศนั้นไม่อนุญาต
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* APEC participation */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-8" aria-expanded="false" aria-controls="data-privacy-8">
                                                        <span className="headings">การเข้าร่วมเอเปค</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-8" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>หลักปฏิบัติด้านความเป็นส่วนตัวของอชัวเรียน ตามรายละเอียดในนโยบายนี้ เป็นไปตามระบบข้อบังคับความเป็นส่วนตัวข้ามพรมแดนของเอเปค
                                                    (APEC Cross Border Privacy Rules System (APEC CBPR)) ระบบ APEC CBPRกำหนดกรอบการทำงานสำหรับองค์กรเพื่อให้ความคุ้มครองข้อมูลส่วนบุคคลที่ถ่ายโอนกันระหว่างเขตเศรษฐกิจเอเปคที่เข้าร่วม
                        ท่านสามารถดูข้อมูลเพิ่มเติมเกี่ยวกับกรอบการทำงานของเอเปคได้ ที่นี่ </p>
                                                    <p>หากท่านต้องการศึกษารายละเอียดเพิ่มเติมเกี่ยวกับการรับรอง APEC CBPR ของเรา โปรดคลิก
                                                    ที่นี่
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Accountability and governance */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-9" aria-expanded="false" aria-controls="data-privacy-9">
                                                        <span className="headings">ความรับผิดชอบและการกำกับดูแล</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-9" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>อชัวเรียนได้วางมาตรการที่หลากหลายเพื่อติดตามตรวจสอบการปฏิบัติตามและบังคับใช้นโยบายนี้</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Where to turn with questions */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-10" aria-expanded="false" aria-controls="data-privacy-10">
                                                        <span className="headings">หากมีข้อสงสัย</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-10" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>หากท่านมีข้อสงสัยประการใดเกี่ยวกับนโยบายนี้ เกี่ยวกับผลิตภัณฑ์หรือบริการของเรา
                                                    หรือต้องการขอเข้าถึง แก้ไข หรือลบข้อมูลส่วนบุคคลของท่าน หรือมีข้อร้องเรียนเกี่ยวกับความเป็นส่วนตัว
                        หรือข้อซักถามใด ๆ โปรดติดต่อ</p>
                                                    <p>(ก) เจ้าหน้าที่ด้านความเป็นส่วนตัว หรือผู้ติดต่อด้านความเป็นส่วนตัวในประเทศที่ท่านอาศัยอยู่ตามรายละเอียดในหัวข้อ
                        ประเทศ ด้านล่างนี้ หรือ </p>
                                                    <p>(ข) (หากประเทศที่ท่านอาศัยอยู่ ไม่มีอยู่ในรายการในหัวข้อ <strong> ประเทศ</strong>                                                ด้านล่างนี้) เรา โดยอีเมลไปที่ privacy@asurion.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Asurion */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-11" aria-expanded="false" aria-controls="data-privacy-11">
                                                        <span className="headings">อชัวเรียน</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-11" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>เพื่อวัตถุประสงค์ตามนโยบายนี้ กลุ่มบริษัทอชัวเรียน หมายถึง บริษัทอชัวเรียนที่เสนอหรือให้บริการแก่ท่าน
                        และบริษัทในเครือ รวมถึงบริษัทใด ๆ ตามรายชื่อที่ปรากฏในหัวข้อ <strong> ประเทศ</strong>&gt;
                        ด้านล่างนี้ด้วย
                      </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Country */}
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="data-privacy-header">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#data-privacy-12" aria-expanded="false" aria-controls="data-privacy-12">
                                                        <span className="headings">ประเทศ</span>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="data-privacy-12" className="panel-collapse collapse" role="tabpanel" aria-labelledby="data-privacy-header">
                                                <div className="panel-body">
                                                    <p>หัวข้อนี้ระบุรายการเปลี่ยนแปลงนโยบายนี้เฉพาะของแต่ละประเทศ และรายละเอียดเพิ่มเติม
                        ตามที่กฎหมายของประเทศนั้น ๆ กำหนด</p>
                                                    <h4>(ก) สิงคโปร์ </h4>
                                                    <table className="terms-conditions-table">
                                                        <tbody>
                                                            <tr>
                                                                <td>ผู้ติดต่อด้านความเป็นส่วนตัว </td>
                                                                <td>เจ้าหน้าที่ด้านความเป็นส่วนตัวของอชัวเรียนโกลบอล ที่
                            </td>
                                                            </tr>
                                                            <tr>
                                                                <td>เว็บไซต์</td>
                                                                <td><a href="/" target="_blank"> www. privacy@asurion.com asurion.com.sg </a>                                                          </td>
                                                            </tr>
                                                            <tr>
                                                                <td>บริษัทอชัวเรียน</td>
                                                                <td>นิว อชัวเรียน สิงคโปร์ พีทีอี แอลทีดี </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                    <h4>(ข) มาเลเซีย </h4>
                                                    <table className="terms-conditions-table">
                                                        <tbody>
                                                            <tr>
                                                                <td>ผู้ติดต่อด้านความเป็นส่วนตัว </td>
                                                                <td>เจ้าหน้าที่ด้านความเป็นส่วนตัวของอชัวเรียนโกลบอล ที่ <a href="/" target="_blank"> privacy@asurion.com </a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>เว็บไซต์</td>
                                                                <td><a href="/" target="_blank"> www.asurion.com.my </a> </td>
                                                            </tr>
                                                            <tr>
                                                                <td>บริษัทอชัวเรียน</td>
                                                                <td>อชัวเรียน เทคโนโลยี มาเลเซีย เอสดีเอ็น บีเอชดี </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                    <h4>(ค) ประเทศไทย </h4>
                                                    <table className="terms-conditions-table">
                                                        <tbody>
                                                            <tr>
                                                                <td>ผู้ติดต่อด้านความเป็นส่วนตัว </td>
                                                                <td>เจ้าหน้าที่ด้านความเป็นส่วนตัวของอชัวเรียนโกลบอล ที่ <a href="/" target="_blank"> privacy@asurion.com </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>เว็บไซต์</td>
                                                                <td><a href="/" target="_blank"> www.asurion.com.th </a> </td>
                                                            </tr>
                                                            <tr>
                                                                <td>บริษัทอชัวเรียน</td>
                                                                <td>บริษัท อชัวเรียน (ประเทศไทย) จำกัด </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                    <div id="fees" className="panel-collapse collapse" role="tabpanel" aria-labelledby="fees-header">
                                                        <div className="panel-body">
                                                            <p>เพื่อวัตถุประสงค์ตามหัวข้อ วัตถุประสงค์ที่เราต้องเก็บรวบรวม ใช้งาน และเปิดเผยข้อมูลส่วนบุคคลของท่าน
                          </p>
                                                            <li>ความยินยอมของท่านที่ให้ไว้ตามนโยบายนี้จะถือว่ารวมถึงความยินยอมให้อชัวเรียนส่งข้อมูลดังกล่าวให้แก่บุคคลภายนอกที่ให้บริการแก่อชัวเรียนด้วย
                                                            และอชัวเรียนอาจจำเป็นต้องแบ่งปันข้อมูลส่วนบุคคลของท่านให้บุคคลภายนอกที่จะดำเนินการในฐานะผู้ประมวลผลข้อมูลภายใต้การควบคุมดูแลของอชัวเรียนด้วย
                          </li>
                                                            <li>อชัวเรียนจะจัดเก็บข้อมูลส่วนบุคคลของท่านก็ต่อเมื่อความยินยอมของท่านยังคงมีผลสมบูรณ์หลังจากสิ้นสุดหรือเปลี่ยนแปลงบริการ
                                                            เว้นแต่ว่าท่านเพิกถอนความยินยอมดังกล่าว
                          </li>
                                                            <li>เว้นแต่ว่าอชัวเรียนได้รับคำสั่งเป็นการเฉพาะเจาะจงประการอื่นจากท่าน อชัวเรียนจะเก็บรักษาข้อมูลส่วนบุคคลของท่านที่เกี่ยวกับท่าน
                                                            และจะใช้งานหรือเปิดเผยข้อมูลดังกล่าวเพื่อวัตถุประสงค์ทางกฎหมายและ/หรือทางธุรกิจ
                                                            รวมถึงเพื่อปฏิบัติตามกฎหมายในประเทศที่ท่านรับบริการ
                          </li>
                                                            <p> เพื่อวัตถุประสงค์ตามหัวข้อ
                            <strong>เราแบ่งปันและถ่ายโอนข้อมูลส่วนบุคคลของท่านอย่างไร </strong> อชัวเรียนจะถ่ายโอนข้อมูลส่วนบุคคลออกนอกประเทศไทยไปยังประเทศผู้รับที่มีมาตรฐานการคุ้มครองข้อมูลที่เทียบเท่าหรือดีกว่าพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลของไทยเท่านั้น
                          </p>
                                                            <p>เพื่อวัตถุประสงค์ตามหัวข้อ
                            <strong> เราปกป้องข้อมูลส่วนบุคคลของท่านอย่างไร </strong>อชัวเรียนจะแจ้งให้หน่วยงานด้านการคุ้มครองข้อมูลทราบเกี่ยวกับการละเมิดข้อมูลใด
                            ๆ ภายในเวลา 72 ชั่วโมงนับตั้งแต่เกิดการกระทำผิดเช่นว่านั้น</p>
                                                            <p>เพื่อวัตถุประสงค์ตามหัวข้อ เราจะเก็บข้อมูลส่วนบุคคลของท่านไว้นานเท่าใด
                                                            อชัวเรียนจะเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาเท่าที่จำเป็นเพื่อดำเนินการตามวัตถุประสงค์ที่ระบุในนโยบายนี้เท่านั้น
                                                            แม้ว่าความยินยอมของท่านจะยังมีผลอยู่ และเป็นไปตามกฎหมายที่ใช้บังคับในประเทศไทย
                          </p>
                                                        </div>
                                                    </div>
                                                    <div id="fees" className="panel-collapse collapse" role="tabpanel" aria-labelledby="fees-header">
                                                        <div className="panel-body">
                                                            <h4>หัวข้อ เด็กอายุต่ำกว่า 18 ปี จะไม่ใช้บังคับและไม่นำมาใช้แทนข้อความต่อไปนี้</h4>
                                                            <p>
                                                                <strong>ผู้เยาว์อายุต่ำกว่า 20 ปี </strong>
                                                            </p>
                                                            <p>บริการนี้ไม่เหมาะกับผู้เยาว์ที่มีอายุต่ำกว่า 20 ปี เว้นแต่ว่ากฎหมายที่ใช้บังคับในประเทศที่ท่านรับบริการจะอนุญาต
                                                            หากเราทราบ เราจะไม่เก็บรวบรวมข้อมูลจากผู้เยาว์ที่มีอายุต่ำกว่า 20
                                                            ปี หากท่านทราบว่าบุตรหลานของท่านเข้าถึงบริการและให้ข้อมูลส่วนบุคคลโดยท่านไม่ได้ให้ความยินยอมก่อน
                                                            ในประเทศที่กฎหมายไม่อนุญาต โปรดติดต่อเราตามข้อมูลการติดต่อที่ให้ไว้ด้านล่างนี้
                                                            เราจะดำเนินการลบข้อมูลส่วนบุคคลดังกล่าวออกจากระบบจัดเก็บข้อมูล และยกเลิกบัญชีผู้ใช้ดังกล่าวหากผู้เยาว์ที่มีอายุต่ำกว่า
                            18 ปี เข้าถึงบริการในประเทศที่กฎหมายในประเทศนั้นไม่อนุญาต </p>
                                                            <h4>(ง) ออสเตรเลีย </h4>
                                                            <table className="terms-conditions-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>ผู้ติดต่อด้านความเป็นส่วนตัว </td>
                                                                        <td>เจ้าหน้าที่ด้านความเป็นส่วนตัวของอชัวเรียนโกลบอล ที่ <a href="/" target="_blank"> privacy@asurion.com </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>เว็บไซต์</td>
                                                                        <td><a href="/" target="_blank"> http://www.asurion.com.au </a>                                                                  </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>บริษัทอชัวเรียน</td>
                                                                        <td>อชัวเรียน (ออสเตรเลีย) ลิมิเต็ด </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table><br />
                                                            <p> ในหัวข้อ
                            <strong>"เราแบ่งปันและถ่ายโอนข้อมูลส่วนบุคคลของท่านอย่างไร"</strong> ข้างต้นนี้ ประโยคแรกควรขึ้นต้นว่า "เราอาจแบ่งปันและถ่ายโอนข้อมูลส่วนบุคคลที่เราเก็บรวบรวมเกี่ยวกับท่านนอกประเทศออสเตรเลีย
                            รวมถึงภายในกลุ่มบริษัทอชัวเรียน (รวมถึงสมาชิกในกลุ่มบริษัทที่ตั้งอยู่ในประเทศสหรัฐอเมริกา
                            สหภาพยุโรป ฟิลิปปินส์ มาเลเซีย ประเทศไทย และสิงคโปร์) และผู้ให้บริการของอชัวเรียน
                            (รวมถึงผู้ให้บริการของอชัวเรียนที่ตั้งอยู่ในประเทศสหรัฐอเมริกา ฟิลิปปินส์
                            และมาเลเซีย) แต่ต้องเป็นไปตามวัตถุประสงค์เท่านั้น" </p>
                                                            <p>ในหัวข้อ
                            <strong> "หากมีข้อสงสัย"</strong> ควรเพิ่มข้อความต่อไปนี้ด้วย "หากท่านไม่พอใจกับคำตอบของเรา ท่านมีสิทธิร้องเรียนเราต่อสำนักงานคณะกรรมการกำกับดูแลข้อมูลออสเตรเลีย
                            (Office of the Australian Information Commissioner) เรื่องการเก็บรวบรวมและใช้งานข้อมูลส่วนบุคคลของท่านได้ที่"
                          </p>
                                                            <p>GPO Box 5218<br /> Sydney NSW 2001<br /> อีเมล enquiries@oaic.gov.au<br />                                                        โทร. 1300 363 992<br /> www.oaic.gov.au </p>
                                                            <h4>(จ) นิวซีแลนด์ </h4>
                                                            <p>เพื่อวัตถุประสงค์ตามหัวข้อ
                            <strong> สิทธิของท่านเกี่ยวกับการแก้ไขและการเข้าถึง</strong> เราจะตอบกลับคำขอเข้าถึงหรือแก้ไขข้อมูลส่วนบุคคลโดยเร็วที่สุดเท่าที่จะสามารถกระทำได้ตามสมควร
                            และไม่ว่ากรณีใด จะไม่เกินกว่า 20 วันทำการนับจากวันที่มีคำขอ </p>
                                                            <table className="terms-conditions-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>ผู้ติดต่อด้านความเป็นส่วนตัว</td>
                                                                        <td>เจ้าหน้าที่ด้านความเป็นส่วนตัวของอชัวเรียนโกลบอล ที่ <a href="/" target="_blank"> privacy@asurion.com </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>เว็บไซต์</td>
                                                                        <td><a href="/" target="_blank"> www.asurion.co.nz </a> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>บริษัทอชัวเรียน</td>
                                                                        <td>อชัวเรียน นิวซีแลนด์ ลิมิเต็ด</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table><br />
                                                            <p> ในหัวข้อ
                            <strong> "หากมีข้อสงสัย" </strong> ควรเพิ่มข้อความต่อไปนี้ด้วย "หากท่านไม่พอใจกับคำตอบของเรา ท่านมีสิทธิร้องเรียนเราต่อสำนักงานคณะกรรมการกำกับดูแลความเป็นส่วนตัว
                            (Office of the Privacy Commissioner) เรื่องการเก็บรวบรวมและใช้งานข้อมูลส่วนบุคคลของท่านได้ที่"</p>
                                                            <p>PO Box 10 094<br /> The Terrace<br /> Wellington 6143<br /> New Zealand<br />                                                        https://www.privacy.org.nz/about-us/contact/
                          </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}