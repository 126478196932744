import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';

import rootReducer from './rootReducer';

const store = createStore(
  rootReducer,
	undefined,
  compose(applyMiddleware(ReduxThunk)),
);

export default store;