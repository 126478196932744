import React, { Component, Fragment } from "react";
// import EndChatRequest from "./EndChatRequest";
import CONSTANTS from "./../../../utils/Constants";

class InactiveTimer extends Component {
  constructor(props) {
    super(props);
    //if(props.agentSource){
    this.state = {
      chatEnded: false,
      elapsed: 0,
      lastTimestamp: Date.parse(props.lastTimestamp),
      timer: setInterval(this.tick, 50),
      chatRequest: null
    };
    //}
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.agentSource &&
      JSON.stringify(this.props.lastTimestamp) !==
        JSON.stringify(nextProps.lastTimestamp)
    ) {
      clearInterval(this.state.timer);
      this.setState({
        chatEnded: false,
        elapsed: 0,
        lastTimestamp: Date.parse(nextProps.lastTimestamp),
        timer: setInterval(this.tick, 50),
        chatRequest: null
      });
    }
  }

  tick = () => {
    this.setState({ elapsed: new Date() - this.state.lastTimestamp });
  };

  render() {
    // let { actions, requestId } = this.props;

    var miliseconds = this.state.elapsed;
    var totalSeconds = Math.floor(miliseconds / 1000);
    var minutes = Math.floor(totalSeconds / 60);

    if (
      !this.state.chatEnded &&
      CONSTANTS.DEFAULT_INACTIVITY_TIMEOUT_MINUTES === minutes
    ) {
      this.setState({
        chatEnded: true
        //  chatRequest: {
        //      input: {
        //          requestId: requestId,
        //          requestStatus: CONSTANTS.CHAT_REQUEST_STATUS.AGENT_DISCONNECTED,
        //          comments: "Agent not responding",
        //          endTimestamp: new Date()
        //      }
        //  }
      });
    }

    return (
      <Fragment>
        {/* {this.state.chatEnded && this.state.chatRequest &&
                    <EndChatRequest chatRequest={this.state.chatRequest} actions={actions}/>} */}
      </Fragment>
    );
  }
}

export default InactiveTimer;
