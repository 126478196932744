import React, { Component } from "react";
import Slider from "react-rangeslider";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from 'classnames';

import { endInteractionInit, submitSurvey } from "../../actions/Dashboard/doFindAgreements";
import { MIXPANEL } from "../../mixpanel/Mixpanel";
import EVENTLIST from "../../mixpanel/Eventlist";
import CONSTANTS from "../../utils/Constants";
import MessageConstants from "../../config/journey-messages";

import "../Feedback/feedback.css";
import "react-rangeslider/lib/index.css";

import ic_poor_active_big from  '../../images/mobile/ic-poor_active_big.png';
import ic_sad_active_big from '../../images/mobile/ic-sad_active_big.png';
import ic_neutral_active_big from '../../images/mobile/ic-neutral_active_big.png';
import ic_happy_active_big from '../../images/mobile/ic-happy_active_big.png';
import ic_excellent_active_big from  '../../images/mobile/ic-excellent_active_big.png';
import ic_poor_active from '../../images/mobile/ic-poor_active.png';
import ic_poor_default from '../../images/mobile/ic-poor_default.png';
import ic_sad_active from '../../images/mobile/ic-sad_active.png';
import ic_sad_default from '../../images/mobile/ic-sad_default.png';
import ic_neutral_active from '../../images/mobile/ic-neutral_active.png';
import ic_neutral_default from '../../images/mobile/ic-neutral_default.png';
import ic_happy_active from  '../../images/mobile/ic-happy_active.png';
import ic_happy_default from '../../images/mobile/ic-happy_default.png';
import ic_excellent_active from '../../images/mobile/ic-excellent_active.png';
import ic_excellent_default from '../../images/mobile/ic-excellent_default.png';
class SurveyQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disable: false,
      disabledTextArea: false,
      highlightedButton: false,
      volume: MessageConstants.FEEDBACK.NPS_DEFAULT_SELECTED,
      npsLabel: MessageConstants.FEEDBACK.NPS_LABEL.EXTREMELY_LIKELY,
      csat: MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT,
      csatValue: MessageConstants.FEEDBACK.CSAT.EXCELLENT.VALUE,
      reason: "",
      labels: {}
    };
  }

  componentWillMount() {
    if (this.props.surveyQuestions) {
      this.props.surveyQuestions.sort(function(a, b) {
        return a.DisplayOrder - b.DisplayOrder;
      });
    }

    let labels = {};
    for (let i = MessageConstants.FEEDBACK.NPS_MIN; i <= MessageConstants.FEEDBACK.NPS_MAX; i++) {
      labels[i] = i.toString();
    }
    this.setState({
      labels: labels
    });
  }

  handleOnSlide = value => {
    let npsLabelValue = MessageConstants.FEEDBACK.NPS_LABEL.EXTREMELY_LIKELY;
    if (value > MessageConstants.FEEDBACK.EXTREMELY_LIKELY_VALUE) {
      npsLabelValue = MessageConstants.FEEDBACK.NPS_LABEL.EXTREMELY_LIKELY;
    }
    else if( value < MessageConstants.FEEDBACK.LIKELY_VALUE)
    {
      npsLabelValue = MessageConstants.FEEDBACK.NPS_LABEL.NOT_LIKELYNOT_LIKELY;
    }
     else if (value >= MessageConstants.FEEDBACK.LIKELY_VALUE &&
    value <= MessageConstants.FEEDBACK.EXTREMELY_LIKELY_VALUE) {
      npsLabelValue = MessageConstants.FEEDBACK.NPS_LABEL.LIKELY;
    }
    this.setState({
      volume: value,
      npsLabel: npsLabelValue
    });
  };

  handleOnEmojiClick = event => {
    let value = MessageConstants.FEEDBACK.CSAT.HAPPY.VALUE;
    if (event.target.name === MessageConstants.FEEDBACK.CSAT.POOR.TEXT) {
      value = MessageConstants.FEEDBACK.CSAT.POOR.VALUE;
    } else if (event.target.name === MessageConstants.FEEDBACK.CSAT.SAD.TEXT) {
      value = MessageConstants.FEEDBACK.CSAT.SAD.VALUE;
    } else if (
      event.target.name === MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT
    ) {
      value = MessageConstants.FEEDBACK.CSAT.NEUTRAL.VALUE;
    } else if (
      event.target.name === MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT
    ) {
      value = MessageConstants.FEEDBACK.CSAT.EXCELLENT.VALUE;
    }

    this.setState({
      csat: event.target.name,
      csatValue: value
    });
  };

  handleNext = () => {

    let { volume, reason, csatValue } = this.state;
    let {dataprops: {source}} = this.props;

    this.setState({
        highlightedButton: true
    })

    if(source === CONSTANTS.SURVEY_QUESTION_RATING) {

        MIXPANEL.track(
            EVENTLIST.NPS_SUBMITTED,
            { NPS: volume }
        );
        let ratingFeedback = [
            {
                QuestionId: this.props.surveyQuestions[0].QuestionId,
                Answer: volume.toString()
            }
        ]
        this.submitHandler(ratingFeedback, CONSTANTS.SURVEY_QUESTION_COMMENTS, `${volume} ${this.state.npsLabel}`, false);
    } else if (source === CONSTANTS.SURVEY_QUESTION_COMMENTS) {
        MIXPANEL.track(
            EVENTLIST.COMMENT_SUBMITTED,
            { Remark: reason }
        );

        this.setState({ disabledTextArea: true })

        let commentFeedback = [
            {
                QuestionId: this.props.surveyQuestions[1].QuestionId,
                Answer: reason.toString()
            }
        ];
        this.submitHandler(commentFeedback, CONSTANTS.SURVEY_QUESTION_EXPERIENCE, this.state.reason, false);
    } else if (source === CONSTANTS.SURVEY_QUESTION_EXPERIENCE) {

    this.props.updateJourneyList(CONSTANTS.EXPERIENCE_FEEDBACK_SUBMITTED, this.state.csat);

      MIXPANEL.track(
        EVENTLIST.CSAT_SUBMITTED,
        { CSAT: this.state.csatValue }
    );

        let experienceFeedback = [
          {
            QuestionId: this.props.surveyQuestions[2].QuestionId,
            Answer: csatValue.toString()
          }
        ];
        this.submitHandler(experienceFeedback, null, this.state.reason, true);
    }
  }

  handleTextareaChange = event => {
    this.setState({
      reason: event.target.value
    });
  };

  submitHandler = (results, source, displayValue, showThanksPage = false) => {
    this.setState({
      disable: true
    });

    let {
      cacheId,
      visitorId,
      clientId,
      interactionLineId,
      caseNumber,
      currentRequestDetails
    } = this.props;

    let customerCaseNumber = "";

    if (caseNumber) {
      customerCaseNumber = caseNumber.toString();
    } else {
      if (currentRequestDetails && currentRequestDetails.CustomerCaseNumber)
        customerCaseNumber = currentRequestDetails.CustomerCaseNumber.toString();
    }

    let submitSurveyRequest = {
      SessionId: cacheId,
      VisitorId: visitorId,
      SurveyId: this.props.surveyQuestions[0].SurveyId,
      ClientId: clientId,
      InteractionLineId: interactionLineId,
      CustomerCaseNumber: customerCaseNumber,
      ServiceRequestId: this.props.serviceRequestId,
      Results: results,
      mobileNumber:this.props.customerContact
    };

    this.props.submitSurvey(submitSurveyRequest, cacheId, source, displayValue, showThanksPage);
  };

  render() {
    let { surveyQuestions, dataprops: {source} } = this.props;
    let { volume, npsLabel, csat, reason, labels } = this.state;

    if(!surveyQuestions) {
      return <div></div>
    }

    let buttonClass = classNames({
        'ais_device-select-button': true,
        'ais_survey-question': true,
        'ais_device-select-button-highlighted': this.state.highlightedButton,
    })

    let feedbackContainerClass = classNames({
        'ais_feedback-parent-div': true,
        'ais_feedback-comment': source !== CONSTANTS.SURVEY_QUESTION_RATING
    })

    let submitButtonClass = classNames({
        'ais_outer-align-button': true,
        'ais_survey-question': true
    })

    return <div className="ais_feedback-container">
        <div className={feedbackContainerClass}>
          {source === CONSTANTS.SURVEY_QUESTION_RATING &&
            <div>
              <div className="ais_first-question">
                <label className="ais_question-label">
                  {surveyQuestions[0].QuestionDescription}
                </label>
                <span className="ais_nps-span">{npsLabel}</span>
              </div>
              <div className="ais_slider-div">
                <Slider min={MessageConstants.FEEDBACK.NPS_MIN} max={MessageConstants.FEEDBACK.NPS_MAX} value={volume} orientation="horizontal" onChange={this.handleOnSlide} step={MessageConstants.FEEDBACK.NPS_STEP} labels={labels} tooltip={false} />
              </div>
            </div>}
          {source === CONSTANTS.SURVEY_QUESTION_COMMENTS &&
            <div className="ais_second-question">
              <label className="ais_question-label">
                {surveyQuestions[1].QuestionDescription}
              </label>
              <textarea value={reason} className={reason ? "ais_feedback-reason text-color" : "ais_feedback-reason"} placeholder={MessageConstants.COMMENT_HERE_PLACEHOLDER} disabled={this.state.disabledTextArea} onChange={this.handleTextareaChange} />
            </div>}
          {source === CONSTANTS.SURVEY_QUESTION_EXPERIENCE &&
                <div>
                    <div className="ais_third-question">
                        <label className="ais_question-label">{surveyQuestions[2].QuestionDescription}</label>
                        <div>
                            {csat === MessageConstants.FEEDBACK.CSAT.POOR.TEXT ?
                                <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.POOR.TEXT} src={ic_poor_active_big} />
                                : csat === MessageConstants.FEEDBACK.CSAT.SAD.TEXT ?
                                    <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.SAD.TEXT} src={ic_sad_active_big} />
                                    : csat === MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT ?
                                        <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT} src={ic_neutral_active_big} />
                                        : csat === MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT ?
                                            <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT} src={ic_happy_active_big} />
                                            : csat === MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT ?
                                                <img className="ais_csat-img" alt={MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT} src={ic_excellent_active_big} />
                                                : null
                            }
                            <span className="ais_csat-text">{csat}</span>
                        </div>
                        <div className="ais_outer-image-div" >
                            <div className="ais_face-img" onClick={this.handleOnEmojiClick}>
                                {csat === MessageConstants.FEEDBACK.CSAT.POOR.TEXT ?
                                    <img name={MessageConstants.FEEDBACK.CSAT.POOR.TEXT} alt={MessageConstants.FEEDBACK.CSAT.POOR} src={ic_poor_active} />
                                    : <img name={MessageConstants.FEEDBACK.CSAT.POOR.TEXT} alt={MessageConstants.FEEDBACK.CSAT.POOR} src={ic_poor_default} />}

                            </div>
                            <div className="ais_face-img" onClick={this.handleOnEmojiClick}>
                                {csat === MessageConstants.FEEDBACK.CSAT.SAD.TEXT ?
                                    <img name={MessageConstants.FEEDBACK.CSAT.SAD.TEXT} alt={MessageConstants.FEEDBACK.CSAT.SAD} src={ic_sad_active} />
                                    : <img name={MessageConstants.FEEDBACK.CSAT.SAD.TEXT} alt={MessageConstants.FEEDBACK.CSAT.SAD} src={ic_sad_default} />}
                            </div>
                            <div className="ais_face-img" onClick={this.handleOnEmojiClick}>
                                {csat === MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT ?
                                    <img name={MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT} alt={MessageConstants.FEEDBACK.CSAT.NEUTRAL} src={ic_neutral_active} />
                                    : <img name={MessageConstants.FEEDBACK.CSAT.NEUTRAL.TEXT} alt={MessageConstants.FEEDBACK.CSAT.NEUTRAL} src={ic_neutral_default} />}
                            </div>
                            <div className="ais_face-img" onClick={this.handleOnEmojiClick}>
                                {csat === MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT ?
                                    <img name={MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT} alt={MessageConstants.FEEDBACK.CSAT.HAPPY} src={ic_happy_active} />
                                    : <img name={MessageConstants.FEEDBACK.CSAT.HAPPY.TEXT} alt={MessageConstants.FEEDBACK.CSAT.HAPPY} src={ic_happy_default} />}
                            </div>
                            <div className="ais_face-img" onClick={this.handleOnEmojiClick}>
                                {csat === MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT ?
                                    <img name={MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT} alt={MessageConstants.FEEDBACK.CSAT.EXCELLENT} src={ic_excellent_active} />
                                    : <img name={MessageConstants.FEEDBACK.CSAT.EXCELLENT.TEXT} alt={MessageConstants.FEEDBACK.CSAT.EXCELLENT} src={ic_excellent_default} />}
                            </div>
                        </div>
                    </div>
                </div>
          }
          <div className={submitButtonClass}>
              <button disabled={this.state.disable} onClick={this.handleNext} className={buttonClass}>
                  {this.props.data.BUTTON}
              </button>
          </div>
        </div>
      </div>;
  }
}

const mapStateToProps = state => {
  const {
    customerCase,
    clientData,
    surveyQuestions,
    visitorId,
    endInteraction,
    InteractionLineId,
    determineIncidentResponse,
    serviceRequestId,
    customerContact
  } = state.customerData;
  return {
    surveyQuestions,
    clientId: clientData.ClientId,
    cacheId: clientData.CacheId,
    interactionLineId: InteractionLineId,
    visitorId: visitorId,
    caseNumber: customerCase.CustomerCaseNumber,
    endInteraction: endInteraction,
    currentRequestDetails: determineIncidentResponse,
    serviceRequestId: serviceRequestId,
    customerContact:customerContact
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      endInteractionInit,
      submitSurvey
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyQuestion);