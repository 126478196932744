
import { getLang } from '../utils/utils';
import MESSAGE_CONSTANTS_EN from './journey-messages-en';
import MESSAGE_CONSTANTS_THAI from './journey-messages-thai';

//SingletonFactory Method for determining messages based on languages
const SingletonFactory = (function(){
  function getMessageConstantByLang() {
    const lang = getLang()
    
    return (lang === "th") ? MESSAGE_CONSTANTS_THAI : MESSAGE_CONSTANTS_EN;
    //return 
  }
  var instance;
  return {
      getInstance: function(){
          if (instance == null) {
              instance = new getMessageConstantByLang();
              // Hide the constructor so the returned object can't be new'd...
              instance.constructor = null;
          }
          return instance;
      }
 };
})();

export default SingletonFactory.getInstance();