import MESSAGE_CONSTANTS from '../config/journey-messages';
import CONSTANTS from './Constants';
import DOMPurify from 'dompurify';
import _, { isEmpty } from 'lodash';

import config from './../config/config';
import axios from 'axios';

import moment from 'moment-timezone';
import noImage from '../images/ais-images/icon-enrolled-device.png';

export function isDeviceOrWeb() {
  if (/iPad/.test(navigator.userAgent)) {
    return 'tablet';
  } else if (
    /Mobile|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  ) {
    return 'mobile';
  } else {
    return 'web';
  }
}

export function isIOS() {
  return !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function includeCustomerCaseInfoInParams(storeData, paramObj) {
  let { customerCaseId, serviceRequestId, selectedAsset } = storeData;
  return Object.assign({}, paramObj, {
    CustomerCaseId: customerCaseId,
    ServiceRequestId: serviceRequestId,
    AssetInstance: selectedAsset.AssetInstance,
  });
}

export function getClientParams() {
  return CONSTANTS.CLIENT_KEY;
}

export function sanitizeHTML(text) {
  return DOMPurify.sanitize(text, { ADD_ATTR: ['target'] });
}

export function currentTime() {
  var time = new Date();
  return time.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

export function getPlaceholder(source) {
  switch (source) {
    case CONSTANTS.SOURCE_USER_INPUT.ENQUIRY:
      return MESSAGE_CONSTANTS.PLACEHOLDERS.TYPE_YOUR_ENQUIRY;
    case CONSTANTS.SOURCE_USER_INPUT.MDN:
      return MESSAGE_CONSTANTS.PLACEHOLDERS.TYPE_YOUR_MOBILE_NUMBER;
    case CONSTANTS.SOURCE_USER_INPUT.NAME:
      return MESSAGE_CONSTANTS.PLACEHOLDERS.TYPE_YOUR_ENROLLED_NAME;
    case CONSTANTS.SOURCE_USER_INPUT.NRIC:
      return MESSAGE_CONSTANTS.PLACEHOLDERS.TYPE_YOUR_NRIC;
    case CONSTANTS.SOURCE_USER_INPUT.REQUEST:
      return MESSAGE_CONSTANTS.PLACEHOLDERS.TYPE_YOUR_ENQUIRY;
    case CONSTANTS.SOURCE_USER_INPUT.BOT_INPUT_SERVICE_FEE:
      return MESSAGE_CONSTANTS.PLACEHOLDERS.TYPE_YOUR_QUERY_FOR_SERVICE_FEE;
    default:
      return MESSAGE_CONSTANTS.PLACEHOLDERS.TYPE_YOUR_TEXT;
  }
}

export function replacePlaceholders(msg, original, replacevalue) {
  return msg.replace(original, replacevalue);
}

export function multipleReplacePlaceHolders(msg, placeholders, listOfValues) {
  let message = msg;
  for (let i = 0; i < placeholders.length; i++) {
    message = message.replace(placeholders[i], listOfValues[i]);
  }
  return message;
}

export function extractDeliverySlotFromDeliveryInstruction(deliveryInst = '') {
  let slotValue = deliveryInst.substr(0, deliveryInst.indexOf('|'));
  let slotArray = MESSAGE_CONSTANTS.SLOTS_MAPPING.filter((item) => item.VALUE === slotValue);
  return (
    slotArray.length > 0 && {
      humanReadableSlot: slotArray[0].DISPLAY_OPTION,
      deliverySlot: slotArray[0].LABEL,
    }
  );
}

export function replaceKeywordsInComponent(msgObj, originalKeyword, replaceKeyword) {
  let targetKeywords = ['HEADER'];

  let objKeys = Object.keys(msgObj);

  let key = _.intersection(targetKeywords, objKeys).join();

  switch (key) {
    case 'HEADER':
      msgObj[key] = replacePlaceholders(msgObj[key], originalKeyword, replaceKeyword);
      break;
    default:
    // replacePlaceholders(msgObj[key], originalKeyword, replaceKeyword)
  }

  return msgObj;
}
export function processRescheduleArray(dateArray, expectedDeliveryDate, isWbkoSelected) {
  let now = moment();
  expectedDeliveryDate = expectedDeliveryDate ? moment(expectedDeliveryDate) : moment();
  let filteredRescheduledArray = dateArray.filter(
    ({ EstimatedDeliveryDate, ShippingMethodType }) => {
      let currEstimatedDeliveryDate = moment(EstimatedDeliveryDate);
      if (ShippingMethodType && ShippingMethodType.toLowerCase() === "saturday" ||
      ShippingMethodType && ShippingMethodType.toLowerCase().indexOf("-e") > -1 ||
      ShippingMethodType && ShippingMethodType.toLowerCase().indexOf("sur") > -1) {
        console.log("ShippingMethodType invalid", ShippingMethodType)
        return false;
      } else {
        console.log("ShippingMethodType valid", ShippingMethodType)
        if (isWbkoSelected) {
          console.log("isWbkoSelected")
          if (ShippingMethodType === 'Same Day') {
            return (
              currEstimatedDeliveryDate.format('L') > expectedDeliveryDate.format('L') ||
              currEstimatedDeliveryDate.format('L') > now.format('L') ||
              currEstimatedDeliveryDate.isAfter(expectedDeliveryDate)
            );
          } else {
            return (
              currEstimatedDeliveryDate.format('L') >= expectedDeliveryDate.format('L') ||
              currEstimatedDeliveryDate.format('L') > now.format('L') ||
              currEstimatedDeliveryDate.isAfter(expectedDeliveryDate)
            );
          }
        } else {
          console.log("not isWbkoSelected")
          // console.log("currEstimatedDeliveryDate.format('L')", currEstimatedDeliveryDate.format('L'))
          // console.log("expectedDeliveryDate.format('L')", expectedDeliveryDate.format('L'))
          // console.log("currEstimatedDeliveryDate.isAfter(expectedDeliveryDate)", currEstimatedDeliveryDate.isAfter(expectedDeliveryDate))
          return (
            currEstimatedDeliveryDate.format('L') >= expectedDeliveryDate.format('L') ||
            currEstimatedDeliveryDate.isAfter(expectedDeliveryDate)
          );
        }
      }
    }
  );

  //Safe side check if expecteddelivery date is greater that current date then also filter should be greater that current date
  if (expectedDeliveryDate.format('L') < now.format('L')) {
    filteredRescheduledArray = filteredRescheduledArray.filter(({ EstimatedDeliveryDate }) =>
      moment(EstimatedDeliveryDate).isAfter(now)
    );
  }

  console.log("filteredRescheduledArray", filteredRescheduledArray)
  return filteredRescheduledArray;
}
export function toQueryParams(json) {
  return (
    '?' +
    Object.keys(json)
      .map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
      })
      .join('&')
  );
}

export function validateExpiryDate(expire) {
  let result = '';
  // console.log(expire);
  // if (!expire.match(/(0[1-9]|1[0-2])[-][0-9]{4}/)) {
  if (!expire.match(/[0|1][0-9][-][1-2][0-9]{3}/)) {
    // errMsg += "The expire date formate is not correct!\n";
    result = 'format';
  } else {
    // get current year and month
    var d = new Date();
    var currentYear = d.getFullYear();
    var currentMonth = d.getMonth() + 1;
    // get parts of the expiration date
    var parts = expire.split('-');

    var today, someday;
    var exMonth = parts[0];
    var exYear = parts[1];
    today = new Date();
    someday = new Date();
    someday.setFullYear(exYear, exMonth, 1);
    // compare the dates
    if (someday < today) {
      result = 'passed';
    }
    // if (( < currentYear) || (parts[1] === currentYear)) {
    //     // errMsg += "The expiry date has passed.\n";
    //     if(( < currentMonth)){

    //     }
    //     result = 'passed';
    // }
  }
  return result;
}

export function isNumber(evt) {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export function addHyphen(event) {
  let newVal = '';
  let targetVal = event.target.value;
  // console.log(targetVal);
  if (targetVal !== '') {
    if (targetVal.length === 2) {
      newVal = targetVal + '-';
      event.target.value = newVal;
      return true;
    }
    return false;
  } else {
    return false;
  }
}

export function hasNumbers(t) {
  var regex = /\d/g;
  return regex.test(t.target.value);
}

export function checkIfDeviceMakeModelExist(device) {
  return device.Make.Name.toUpperCase() === 'UNKNOWN';
}

export function isServiceOrderPreviouslyCalled(currentResumePoint) {
  let resumeLandingPointsArray = [
    'ConfirmShippingAddress',
    'CaptureShippingMethod',
    'CapturePaymentDetails',
  ];
  let isServiceOrderPreviouslyCalled =
    _.indexOf(resumeLandingPointsArray, currentResumePoint) > -1 ? true : false;
  return isServiceOrderPreviouslyCalled;
}

export function formatExp(e) {
  let inputChar = String.fromCharCode(e.keyCode);
  let code = e.keyCode;
  let allowedKeys = [8];
  if (allowedKeys.indexOf(code) !== -1) {
    return;
  }

  e.target.value = e.target.value
    .replace(
      /^([1-9]\-|[2-9])$/g,
      '0$1-' // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      '$1-' // 11 > 11/
    )
    .replace(
      /^([0-1])([3-9])$/g,
      '0$1-$2' // 13 > 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      '$1-$2' // 141 > 01/41
    )
    .replace(
      /^([0]+)\-|[0]+$/g,
      '0' // 0/ > 0 and 00 > 0
    )
    .replace(
      /[^\d\-]|^[\-]*$/g,
      '' // To allow only digits and `/`
    )
    .replace(
      /\-\-/g,
      '/' // Prevent entering more than 1 `/`
    );
}

export function getBangkokTime() {
  // let bangkokTime = new Date().toLocaleString("en-US", {timeZone: "asia/bangkok"});
  let bangkokTime = moment(new Date()).tz('Asia/Bangkok').format('MMMM Do YYYY, h:mm:ss a');
  return bangkokTime;
}

export function getFormattedTotalFee(totalFee) {
  totalFee = parseInt(totalFee, 10);
  totalFee = totalFee.toLocaleString();
  return totalFee;
}

export function getFormattedDeliveryDate(deliveryDate, slot) {
  var dateString = '';
  var d = new Date(deliveryDate);
  let claimWithinCutoff = isClaimWithinCutoff();
  if (slot === 'Same Day') {
    var expectedDate = new Date(d.getTime());
    // var expectedDate = new Date(d.getTime());
    // var expectedDate = new Date(d.getTime() + 86400000);
    if (expectedDate === new Date() && !claimWithinCutoff) {
      expectedDate = new Date(expectedDate.getTime() + 86400000);
    }
    if (expectedDate.getDay() === 0) {
      expectedDate = new Date(expectedDate.getTime() + 86400000);
    }
    dateString = expectedDate.toDateString();
  } else {
    var d = new Date(deliveryDate);
    if (d.getDay() === 0) {
      d = new Date(d.getTime() + 86400000);
    }
    dateString = d.toDateString();
  }
  return dateString;
}

export function getFormattedUserName(name = '') {
  name = name ? name.replace(/\s{2,}/g, ' ') : name;
  let isSpaceFound = name.indexOf(' ') > -1;
  name = isSpaceFound ? name.split(' ') : name;

  if (isSpaceFound) {
    var [firstName, ...rest] = name;
  }

  return {
    firstName: isSpaceFound ? firstName : name,
    lastName: isSpaceFound && rest ? rest.join(' ') : '',
    fullName: name.length > 0 && rest ? `${firstName} ${rest.join(' ')}` : name,
  };
}

export function validateNameRegex() {
  return new RegExp(/[^\u0E00-\u0E7Fa-zA-Z' ]|^'|'$|''/g);
}

export function validateAddressRegex() {
  return new RegExp(/[^\u0E00-\u0E7Fa-zA-Z0-9-,'. /#]/g);
}

export function isClaimWithinCutoff() {
  let hours = moment(new Date()).tz('Asia/Bangkok').format('HH');
  let minutes = moment(new Date()).tz('Asia/Bangkok').format('mm');
  console.log(hours, minutes);
  return hours < 13 || (hours == 13 && minutes <= 45);
}

export function getFormattedPopularName(deviceName) {
  if (deviceName) {
    return {
      makename: '',
      modelName: deviceName,
      specs: '',
      color: '',
    };
  }
  return {
    makename: '',
    modelName: '',
    specs: '',
    color: '',
  };
}

export function formatDate(dateString) {
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let d = new Date(dateString),
    dayName = days[d.getDay()],
    monthName = months[d.getMonth()],
    day = d.getDate(),
    year = d.getFullYear();
  if (day.length < 2) day = '0' + day;

  return dayName + ' ' + monthName + ' ' + day + ', ' + year;
}

export function todaysDate() {
  let today = '',
    curr = new Date(),
    first = curr.getDate();
  let next = new Date(curr.getTime());
  next.setDate(first + 1);
  if (next.getDay() !== 0) {
    today = formatDate(next.toString());
  } else {
    next.setDate(first + 2);
    today = formatDate(next.toString());
  }

  return today;
}

export function getMaskedstring(str) {
  let newStr = '';
  if (str.indexOf('@') !== -1) {
    newStr = str.split('@');
  }
  if (Array.isArray(newStr) && newStr[0] !== '') {
    str = newStr[0];
  }
  let finalStr = str,
    stringLen = str.length;

  if (stringLen > 2) {
    let strToMask = str.slice(1, stringLen - 1),
      maskstripLen = strToMask.length,
      maskStr = '';
    for (let i = 0; i < maskstripLen; i++) {
      // strToMask.charAt(i)
      maskStr += '*';
    }
    finalStr = replacePlaceholders(str, strToMask, maskStr);
  }

  if (Array.isArray(newStr) && newStr[1] !== '') {
    finalStr = finalStr + '@' + newStr[1];
  }
  return finalStr;
}

String.prototype.replaceAt = function (index, char) {
  return this.substr(0, index) + char + this.substr(index + char.length);
};

export function getMaskedCCNum(str) {
  if (str !== '') {
    str = new Array(str.length - 3).join('*') + str.substr(str.length - 4, 4);
  }
  return str;
}

export function getMaskedstr(str) {
  if (str.length > 3) {
    const REPLACEMENT_CHAR = '*';
    const MASK_END = str.length - 1;
    const MASK_START = 1;
    const MASK_THRESHOLD = 3;

    let strLen = str.length;
    let maskedCharacter = 0;
    for (let i = MASK_START; i < MASK_END; i++) {
      if (Math.random() > 0.5 && str.charAt(i) !== '.' && str.charAt(i) !== '@') {
        str = str.replaceAt(i, REPLACEMENT_CHAR);
        maskedCharacter++;
      }
    }

    if (maskedCharacter < strLen / MASK_THRESHOLD) {
      return getMaskedstr(str);
    } else {
      return str;
    }
  } else {
    return str;
  }
}

export function messageTime() {
  let currentTime = new Date();
  return currentTime;
}

export function calculateDeliverySlot() {
  let finalDeliveryDate = '';
  let finalDeliverySlot = '';
  let finalDeliveryWindow = '';
  let deliveryLabel = '';
  var isBetween = false;
  var currentTime = moment(moment().utcOffset('+08:00').format(' hh mm a'), 'HH:mm a');
  MESSAGE_CONSTANTS.DELIVERY_SLOT_MAPPING.forEach(function (value) {
    if (!isBetween) {
      var startTime = moment(value.START_TIME, 'HH:mm a');
      var endTime = moment(value.END_TIME, 'HH:mm a');
      if ((startTime.hour() >= 12 && endTime.hour() <= 12) || endTime.isBefore(startTime)) {
        endTime.add(1, 'days'); // handle spanning days endTime
        if (currentTime.hour() <= 12) {
          currentTime.add(1, 'days'); // handle spanning days currentTime
        }
      }
      isBetween = currentTime.isBetween(startTime, endTime);
      console.log('isBetween', isBetween);
      if (isBetween) {
        finalDeliverySlot = value.DISPLAY_OPTION;
        finalDeliveryWindow = value.VALUE;
        deliveryLabel = value.LABEL;
      }
    }
    //console.log("isBetween", currentTime);
  });
  return {
    finalDeliverySlot,
    finalDeliveryWindow,
    deliveryLabel,
  };
}

export function calculateDeliveryWindow(selectedDeliveryDate = '', selectedDeliverySlot = '') {
  let finalDeliveryDate = '';
  let finalDeliverySlot = '';
  let finalDeliveryWindow = '';
  if (selectedDeliveryDate && selectedDeliverySlot) {
    finalDeliveryDate = new Date(selectedDeliveryDate);
    let filteredArr = MESSAGE_CONSTANTS.SLOTS_MAPPING.filter(
      (item) => item.LABEL === selectedDeliverySlot
    );
    finalDeliverySlot = (filteredArr.length > 0 && filteredArr[0].DISPLAY_OPTION) || null;
    finalDeliveryWindow = (filteredArr.length > 0 && filteredArr[0].VALUE) || null;
  }
  return {
    finalDeliveryDate,
    finalDeliverySlot,
    finalDeliveryWindow,
  };
}
export function calculateDeliverySpan(deliverySpeed, deliverySlot, sameDayDeliverySlots = []) {
  // same day - 8 - 12 (single slot) - 12h (UIShippingMethod)
  // same day - multiple slots (selectedDeliverySlots length > 1) -
  // if customer selected 14 - 18 = 18h else 4h
  // same day - single slot (14-18) - 4h
  // next day - 8-12 = 12h else 18h
  let sameDayMultipleSlots = sameDayDeliverySlots.length > 1;
  switch (deliverySpeed) {
    case 'Same Day':
      if (deliverySlot === '08:00 - 12:00') return '12H';
      if (!sameDayMultipleSlots && deliverySlot === '14:00 - 18:00') return '4H';
      if (sameDayMultipleSlots) {
        if (deliverySlot === '14:00 - 18:00') return '18H';
        else return '4H';
      }
    case 'Second Day':
    case 'Next Business Day':
    case 'Next Day':
      if (deliverySlot === '08:00 - 12:00') return '12H';
      else return '18H';
    default:
  }
}

export function createDetermineIncidentObj(obj) {
  return {
    Type: obj.Type,
    EnrolledCustomerName: obj.EnrolledCustomerName,
    PopularName: obj.Servicerequests[0].ReplacedDevice.AssetCatalog.Name,
    ExpectedDeliveyDate: obj.Servicerequests[0].ShippingOrder.ExpectedDeliveryDate,
    PostalCode: obj.Servicerequests[0].ShippingOrder.Address.PostalCode,
    ServiceFee: obj.Servicerequests[0].ChargeOrder[0].TotalAmount,
    ShippingAddress: obj.Servicerequests[0].ShippingOrder.Address,
    ShippingOrderId: obj.Servicerequests[0].ShippingOrder.ShippingOrderId,
    IncidentType: obj.Servicerequests[0].IncidentType,
    ServiceRequestId: obj.Servicerequests[0].ServiceRequestId,
    CustomerCaseId: obj.Servicerequests[0].CustomerCaseId,
    CustomerCaseStatus: obj.CustomerCaseStatus,
    CustomerCaseNumber: obj.CustomerCaseNumber,
    ServiceRequestNumber: obj.Servicerequests[0].ServiceRequestNumber,
    ServiceRequestStatus: obj.Servicerequests[0].ServiceRequestStatus,
    ServiceRequestType: obj.Servicerequests[0].ServiceRequestType,
    ClaimedAsset: {
      ClaimedItemId: obj.Servicerequests[0].EnrolledDevice.AssetId,
      Make: obj.Servicerequests[0].EnrolledDevice.Make.Name,
      Model: obj.Servicerequests[0].EnrolledDevice.Model.Name,
      Color: '',
    },
    Holds: [],
    CustomerCaseResult: {
      ServiceRequestNumber: obj.Servicerequests[0].ServiceRequestNumber,
      ServiceRequestType: obj.Servicerequests[0].ServiceRequestType,
      ServiceRequestStatus: obj.Servicerequests[0].ServiceRequestStatus,
      IncidentType: obj.Servicerequests[0].IncidentType,
      CustomerCaseNumber: obj.CustomerCaseNumber,
      CustomerCaseStatus: obj.CustomerCaseStatus,
      Actions: '',
      SRAssetCatalogName: obj.Servicerequests[0].ReplacedDevice.AssetCatalog.Name,
      Servicefee: {
        FeeType: obj.Servicerequests[0].ChargeOrder.ChargeOrderType,
        PurchasePrice: 0,
        CurrencyCode: 'MYR',
        TaxPercentage: 7,
        FeeAmount: obj.Servicerequests[0].ChargeOrder.TotalAmount,
        TaxAmount: obj.Servicerequests[0].ChargeOrder.TaxAmount,
        TotalAmount: obj.Servicerequests[0].ChargeOrder.TotalAmount,
      },
      selectedReplacementDevice: {
        HorizonItemInformation: {
          Make: obj.Servicerequests[0].ReplacedDevice.Make.Name,
          Model: obj.Servicerequests[0].ReplacedDevice.Model.Name,
          AssetCatalogName: obj.Servicerequests[0].ReplacedDevice.AssetCatalog.Name,
        },
        Accessories: {
          Accessory: [],
        },
      },
      ServiceOrderStatus: obj.Servicerequests[0].ServiceOrder.ServiceOrderStatus,
      ServiceOrderId: obj.Servicerequests[0].ServiceOrder.ServiceOrderId,
      ShippingMethod: {
        ShippingMethodType: obj.Servicerequests[0].ShippingOrder.ShippingMethodType,
      },
    },
    ReceiverAddress: {
      Standardized: false,
    },
    ShippingStatus: obj.ShippingStatus,
    Servicerequests: obj.Servicerequests,
  };
}

export function determineUserIntent(defectOption, prevIntentState) {
  let userIntent = {
    isTrackOptionSelected: prevIntentState.isTrackOptionSelected,
    isSROptionSelected: prevIntentState.isSROptionSelected,
    isGEOptionSelected: prevIntentState.isGEOptionSelected,
  };
  switch (defectOption) {
    case CONSTANTS.ABOUT_MY_CURRENT_DELIVERY:
      userIntent.isTrackOptionSelected = false;
      userIntent.isSROptionSelected = false;
      userIntent.isGEOptionSelected = false;
      userIntent.isWbkoSelected = true;
      break;
    case CONSTANTS.CREATE_A_NEW_REQUEST:
    case CONSTANTS.RESUME_EXISTING_REQUEST:
      userIntent.isSROptionSelected = true;
      userIntent.isTrackOptionSelected = false;
      userIntent.isGEOptionSelected = false;
      userIntent.isWbkoSelected = false;
      break;
    case CONSTANTS.GENERAL_ENQUIRY:
    case CONSTANTS.KNOW_SERVICE_REQUEST_FEE:
      userIntent.isGEOptionSelected = true;
      userIntent.isTrackOptionSelected = false;
      userIntent.isSROptionSelected = false;
      userIntent.isWbkoSelected = false;
      break;
    case CONSTANTS.WBKO:
    case CONSTANTS.WBKO_RESCHEDULE:
      userIntent.isGEOptionSelected = false;
      userIntent.isTrackOptionSelected = false;
      userIntent.isSROptionSelected = false;
      userIntent.isWbkoSelected = true;
    default:
  }
  return userIntent;
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getLang() {
  return getParameterByName('lang') || 'th';
}
export function getDeviceName({ AssetsInformation, MakeName = '', ModelName = '' }) {
  const deviceMarketingName = !isEmpty(AssetsInformation)
    ? `${AssetsInformation?.Manufacturer} ${AssetsInformation?.MarketingName}`
    : '';
  if (deviceMarketingName) {
    return `${deviceMarketingName} (${ModelName})`;
  }
  return MakeName ? `${MakeName} ${ModelName}` : '';
}

export function getMdnWithoutUnderscore(mdn = '') {
  const strMdn = mdn.toString();
  const index = strMdn.indexOf('_');
  if (index > 0) {
    return strMdn.slice(0, index);
  }
  return strMdn;
}
export function agreementStatus(status) {
  return status === 'ACTV' ? 'ACTIVE' : status;
}
export function BaseImg({ make = '', model = '' }) {
  const deviceImgName = make.toUpperCase() + '_' + model.toUpperCase() + '_S';
  const deviceImg = 'https://consoleone-ui-prod.consoleone.asurion.com/' + deviceImgName + '.jpg';

  const handleError = (event) => {
    event.target.onerror = null;
    event.target.src = noImage;
  };

  return <img src={deviceImg} onError={handleError}></img>;
}

// twilio config change
export function getTwilioConfig() {
  let data;
  let carrier;
  let configChange;

  return new Promise((resolve, reject) => {
    getCachedStaticFile(config.TaskService.baseUrlSEA, 'client/twiliopercarrier.json', false)
      .then((result) => {
        data = JSON.parse(result);
        carrier = findObjectWithKey('client', 'ais', data.configs);
        configChange = findObjectWithKey('env', config.UIAPIServer.env.toLowerCase(), carrier.envs);
        resolve(configChange);
      })
      .catch((error) => {
        resolve(configChange);
      });
  });
}

export function getCachedStaticFile(url, name, getCached = true) {
  return new Promise((resolve, reject) => {
    let jsonString = sessionStorage.getItem(name);

    try {
      if (jsonString === null || !getCached) {
        //try SEA URL
        getStaticFile(`${url}/v1/static/file?name=${name}`)
          .then((result) => {
            jsonString = JSON.stringify(result.data);
            // cache in sessionStorage
            sessionStorage.setItem(name, jsonString);
            // resolve from static file (S3)
            resolve(jsonString);
          })
          .catch((error) => {
            // force resolve
            resolve(null);
          });
      } else {
        // resolve from sessionStorage
        resolve(jsonString);
      }
    } catch (error) {
      //
    }
  });
}

export async function getStaticFile(url) {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      headers: {},
      method: 'GET',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findObjectWithKey(key, value, objects, startsWith) {
  let object = null;
  let keyValue;

  if (objects != null) {
    for (let s = 0; s < objects.length; s++) {
      object = objects[s];
      keyValue = object[key];
      if (startsWith) {
        if (keyValue.indexOf(value) > -1) {
          break;
        }
      } else {
        if (keyValue != null && keyValue == value) {
          break;
        }
      }
      // reset to null
      object = null;
    }
  }
  return object;
}

export const isDev = () => process.env.NODE_ENV === 'development';
