import { assign, createMachine } from 'xstate';
import { raise } from 'xstate/lib/actions';
export const createSmartStockMachine = () => {
  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5SwLYEMBOAXAylg9gMYDWAdAJYB25W5aANgMQ4CiAKgPoAiLAagJIBhFjgDaABgC6iUAAd8sGuXyUZIAB6IA7AEZSADi0AWAJwA2MwFYdRnZYBMJ+wBoQAT0QBmT3pPiz9kaWFpaegSaWAL6RrqiYuAQkFNS0DMzsHPwAcvxs-ACCADLcfEIiEtJIIPKKtCpqmghGLu6I9p6WpBGe4p76djoRWvpm0bHo2HhEZFRKaayFLIKcPALCFWo1SvVVjZb6XfpGnlr2Olo+9uK9Zq4eCDqeJl0mr0ZmpzbNvWMgcZOJGYpOhMbK5DiCADyAFkAAqLNgsDZVLZ1VS7NqWZ5mIz+fQmHT2axaSxGO6IY72UgkkxGYz6Syk8QmTy-f4JaakCBgABu5EIYBwYHoYEIaPSnAAYvxCoiAEosLglNblKSbBTbdGgRpGfQHZpnExaC4+cQk8lNDrUx5nQL2EkXNkTDlJbl8gVCkVi5SUZgACUhAHUONLZSw5SHIXLoci5Bq0Q02sbSPYjv1XldrEaLaE9LoceJHpYTdYnfEpq7efzBcLReKFksVqV1mqUfGfYmENYzF1xJZxLqzDp9PbPBa+j3C2Fzt4zGECVEYn9nRWyLBa2LJeR6FgwBhBBgaHufRKQzL5RC5blw-xIVlY9V2zttZjsbizPjCcTSTn+qRbHO5hGLqxYmGWAKcuuXpYFuO57geR6HiojAKqwUrnuGD6oh2GIIBYKbmASFi0oEPSWDmtikH2tIjLo7w2PY4EumuG4wduu77oeHEnqGF6rGUYitnGtQ4S+XZaKQWJHMBRwnIM5qtE0BKkB0tJEe0Jy9KyvyUPg3LwFU7KruqInPhoiAALS3IpVlUdc9kOfZHSLuM5aAskcz0CZmqdspYQDjohI6FYFiGBalIGFiQQ+MMZhOAOTGrlyVYeqxomPqZWrmV2gwGBc+jiPYZyhCcOaeD2Fg4oMtLMrijFLkZ7lQXWsEcQh3FmdhZk6loObmJJWIONYWKOESiVNQAFvgADu+T0PQkKyGiBnCT5uH4a8oQWKYdjiGFil-qYryvD4gFGkY0TREAA */
      id: 'smartStock',
      initial: 'init',
      predictableActionArguments: true,
      context: {
        devices: [],
        selectedDevice: null,
        initialDevices: [],
        preSortedDevices: {
          popular: [],
          bestDeal: [],
          latest: [],
          recommended: [],
        },
        filteredDevices: [],
        filterOptions: {
          srfRange: [0, 0],
          colors: [],
          capacity: [],
        },
        filterBy: {
          serviceFee: '',
          color: '',
          capacity: '',
        },
        showAllOptions: false,
        filterActive: false,
      },
      states: {
        init: {
          tags: ['loading'],
          on: {
            SET_DEVICES: {
              actions: ['setDevices'],
            },
            SET_PRE_SORTED_DEVICES: {
              actions: ['setPreSortedDevices'],
            },
            SET_INITIAL_DEVICES: {
              actions: ['setInitialDevices'],
            },
            SET_FILTER_OPTIONS: {
              actions: ['setFilterOptions'],
            },
            SELECT_DEVICE: {
              actions: ['setSelectedDevice'],
            },
            INIT_COMPLETE: {
              target: 'deviceSelection',
            },
          },
        },
        deviceSelection: {
          on: {
            SHOW_FILTER_FORM: {
              target: 'selectFilterCriterion',
            },
            SHOW_ALL_OPTIONS: {
              target: 'showAllOptions',
              actions: ['setShowAllOptionsFlag', 'showAllOptions'],
            },
            SET_FILTERED_DEVICES: {
              actions: ['setFilteredDevices'],
            },
            SELECT_DEVICE: {
              target: 'deviceSelected',
              actions: ['setSelectedDevice'],
            },
          },
        },
        processingFilters: {
          invoke: {
            id: 'filterDevices',
            src: 'filterDevices',
          },
          on: {
            DONE: {
              target: 'deviceSelection',
              actions: ['setFilteredDevices'],
            },
          },
        },
        selectFilterCriterion: {
          on: {
            CLOSE: {
              target: 'deviceSelection',
            },
            RESET_FILTER: {},
            SET_FILTER_CRITERION: {
              target: 'processingFilters',
              actions: [
                'setFilterBy',
                assign({
                  showAllOptions: false,
                  filterActive: true,
                }),
              ],
            },
          },
        },
        showAllOptions: {
          on: {
            SHOW_FILTER_FORM: {
              target: 'selectFilterCriterion',
            },
            SELECT_DEVICE: {
              target: 'deviceSelected',
              actions: ['setSelectedDevice'],
            },
          },
        },
        deviceSelected: {
          invoke: {
            src: 'onDone',
          },
          type: 'final',
        },
      },
    },
    {
      actions: {
        setSelectedDevice: assign({
          selectedDevice: (_, event) => event?.id,
        }),
        setDevices: assign({
          devices: (_, event) => event?.devices,
        }),
        setInitialDevices: assign({
          initialDevices: (_, event) => event?.devices,
        }),
        setFilteredDevices: assign({
          filteredDevices: (_, event) => event?.devices,
        }),
        setFilterBy: assign({
          filterBy: (_, event) => event?.filterBy,
        }),
        setPreSortedDevices: assign({
          preSortedDevices: (context, event) => ({
            ...context.preSortedDevices,
            [event.criteria]: event.devices,
          }),
        }),
        setFilterOptions: assign({
          filterOptions: (context, event) => ({
            ...context.filterOptions,
            [event.option]: event.value,
          }),
        }),
        setShowAllOptionsFlag: assign({
          showAllOptions: (_, event) => event?.showAllOptions,
        }),
        showAllOptions: assign({
          filteredDevices: (context) => {
            return context.showAllOptions ? context.devices : [];
          },
        }),
      },
    }
  );
};

export default createSmartStockMachine;
