import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import MESSAGE_CONSTANTS_EN from '../../config/journey-messages';
import CONSTANTS from '../../utils/Constants';

class StartRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null
        }
    }

    handleClick = (text, name) => {
        this.setState({
            selectedOption: name
        });
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.GET_STARREQ, text);
    }
    _renderOptions(option) {

        let btnClasses = classNames({
            'disabled': this.state.selectedOption,
            "ais_options-replacement-selected-div": this.state.selectedOption === option.text,
            "ais_options-replacement-div": this.state.selectedOption !== option.text
        })

        return <div
            name={option.text}
            key={option.text}
            className={`${btnClasses} text-center ais_options_padding`}
            disabled={this.state.selectedOption}
            onClick={() => this.handleClick(option.text, option.name)}>
            <label className={this.state.selectedOption === option.text ? "ais_options-selected-label" : "ais_options-label"}>{option.text}</label>
        </div>
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="ais_info-container">
                        <label className="ais_choose-option-txt">{MESSAGE_CONSTANTS_EN.GET_STARREQ.title}</label>
                    </div>
                    {MESSAGE_CONSTANTS_EN.GET_STARREQ.buttons.map((e, i) => this._renderOptions(e, i))}
                </div>
            </Fragment>
        )
    }
}

export default StartRequest;
