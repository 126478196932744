import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './apps/fonts/fonts.css';
import { unregister } from './registerServiceWorker';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import PrivacyPolicy from './apps/pages/PrivacyPolicy/PrivacyPolicy';
import PrivacyPolicyTH from './apps/pages/PrivacyPolicy/PrivacyPolicyTH';
import './apps/styles/static-page.css';
import TermConditionsTH from './apps/pages/TermConditions/TermConditionsTH';
import TermConditions from './apps/pages/TermConditions/TermConditions';
import TermConditionsSwapUnlimitedTH from './apps/pages/TermConditions/TermConditionsSwapUnlimitedTH';
import TermConditionsSwapUnlimited from './apps/pages/TermConditions/TermConditionsSwapUnlimited';
import FAQ from './apps/pages/FAQ/FAQ';
import FAQSwapUnlimited from './apps/pages/FAQ/FAQSwapUnlimited';
import './i18n';

const AppRouter = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/privacy-policy-thai" component={PrivacyPolicyTH} />
      <Route exact path="/terms-conditions" component={TermConditions} />
      <Route exact path="/terms-conditions-thai" component={TermConditionsTH} />
      <Route
        exact
        path="/terms-conditions-swap-unlimited"
        component={TermConditionsSwapUnlimited}
      />
      <Route
        exact
        path="/terms-conditions-swap-unlimited-thai"
        component={TermConditionsSwapUnlimitedTH}
      />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/faq-swap-unlimited" component={FAQSwapUnlimited} />
      <Route exact path="*" component={App} />
    </Switch>
  </Router>
);

ReactDOM.render(<AppRouter />, document.getElementById('indexBody'));

/* ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('indexBody')
) */
unregister();
