import React from 'react';
import { Mutation } from 'react-apollo';
import CreateChatAuditMutation from './../../../graphql/mutation/CreateChatAudit';
import CallCreateChatAudit from './CallCreateChatAudit';

const CreateChatAudit = ({ visitorActivityLog }) => (
    <Mutation mutation={CreateChatAuditMutation}
        onCompleted={(data) => {
            console.log("CreateChatAudit mutation completed, record added ", data);
        }}>
        {(createChatAudit) => {
            return (
                <CallCreateChatAudit createChatAudit={createChatAudit} visitorActivityLog={visitorActivityLog} />
            );
        }}
    </Mutation>
);

export default CreateChatAudit;
