import { noop } from 'lodash';
import * as React from 'react';
import FilterForm from './FilterForm';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';

export const FormOverlay = ({
  isOpen = false,
  onClose = noop,
  initialValues = {},
  onSubmit = noop,
  onFormClose = noop,
  colors = [],
  range = [],
  capacities = [],
}) => {
  const showModalInstead = useBreakpointValue({ base: false, lg: true });
  const form = (
    <FilterForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onFormClose}
      colors={colors}
      range={range}
      capacities={capacities}
    />
  );

  if (showModalInstead) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={'20px'}>{form}</ModalContent>
      </Modal>
    );
  } else {
    return (
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent borderTopRadius={'20px'}>
          <DrawerBody p={0}>{form}</DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }
};

export default FormOverlay;
