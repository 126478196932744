import React from "react";

import msgIcon from "../../images/mobile/img-message_50.png";

class SentMessage extends React.Component {

    constructor(props) {

        super(props)
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(value) {
        // console.log("button clicked.");
    }

    render() {
        return (
            <div className="ais_current-form-device-div sentMessage">
                <div className="ais_header-txt-div">
                    <label className="ais_current-device-spec">Thank you for your message.
We’ll get back to you.</label>
                    <div>  <img alt="message-icon" className="ais_confirm-delivery-icn ais_sentMessage" src={msgIcon} /> </div>
                </div>
            </div>
        )
    }
}
export default SentMessage;