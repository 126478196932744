import React from "react";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _find from "lodash/find";
import { doSaveDeliveryDate, doSaveDeliverySlot, doSaveDeliverySpeed, savePayload, doSaveEstimatedShipmentDate } from '../../actions/Dashboard/doFindAgreements';
import CONSTANTS from '../../utils/Constants';
import { SAVE_ENQUIRY_REASON } from '../../actions/Dashboard/actionTypes';
import { formatDate, processRescheduleArray } from "../../utils/utils";
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import wbko_date from "../../images/ais-images/wbko-date.png";
import wbko_calendar_extra_small from "../../images/ais-images/wbko-calendar-extra-small.png";

import EVENTLIST from "../../mixpanel/Eventlist";

class WbkoChangeDeliveryScheduleNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      selectedOption: null,
      startDate: moment(),
      submitSch: "ais_device-select-button-custum margin-bottom20",
      deliveryDate: "",
      deliverySlot: "",
      deliveryWindow: "",
      showButton: "",
      disableButton: true,
      reasonText: "",
      disableInput: false
    };
    this.handleDeliveryDateChange = this.handleDeliveryDateChange.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.setReason = this.setReason.bind(this);
  }

  handleDeliveryDateChange(event) {
    let deliveryDate = event.target.value;
    if (event.target.value === "") {
      this.setState({
        deliveryDate: event.target.value,
        deliverySpeed: "",
        disableButton: true,
        deliverySlot: event.target.value
      });
    } else {
      let currentDeliveryObj = _find(this.props.shippingMethodsV2Result, {
        EstimatedDeliveryDate: moment(deliveryDate).format(
          CONSTANTS.ESTIMATED_DELIVERY_DATE_FORMAT
        )
      });
      this.setState({
        deliverySpeed:
          currentDeliveryObj && currentDeliveryObj.ShippingMethodType,
        deliveryDate: event.target.value,
        disableButton: !event.target.value, //!this.state.deliverySlot
        deliverySlot: event.target.value,
        estimatedShipmentDate:
          currentDeliveryObj && currentDeliveryObj.EstimatedShipmentDate
      });

    }
  }



  handleSubmitClick(value) {
    this.setState({
      disable: true,
      submitSch: "ais_device-select-button-submitted",
      disableButton: true,
      disableInput: true
    });
    //this.props.sendMixpanelEvent(EVENTLIST.CHANGE_DELIVERY_SCHEDULE);
    this.props.doSaveDeliveryDate(this.state.deliveryDate);
    this.props.doSaveDeliverySlot(this.state.deliverySlot);
    this.props.doSaveDeliverySpeed(this.state.deliverySpeed);
    this.props.doSaveEstimatedShipmentDate(this.state.estimatedShipmentDate);
    this.props.savePayload(SAVE_ENQUIRY_REASON, this.state.reasonText);
  }

  setReason(value) {
    this.setState({
      reasonText: value
    });
  }

  currentTime = () => {
    return moment(
      moment()
        .utcOffset("+08:00")
        .format("HH mm"),
      "HH:mm"
    );
  };

  createDateData = () => {
    let {
      shippingMethodsV2Result: originArr,
      EnquiryForChangeDeliveryDate: preSelectedDeliveryDate = "",
    } = this.props;

    let processedRescheduledArr = processRescheduleArray(
      originArr,
      preSelectedDeliveryDate,
      this.props.isWbkoSelected
    );

    let resArr = preSelectedDeliveryDate ? processedRescheduledArr : originArr;
    return resArr.map(({ EstimatedDeliveryDate }) => (
      <option>{formatDate(EstimatedDeliveryDate)}</option>
    ));
  }

  render() {
    const { selectedEnquiryOption, isWbkoSelected } = this.props;
    return <div className={`${isWbkoSelected ? " ais_current-form-device-div " : " ais_device-outer ais_deliverySchedule "} `}>
      {!isWbkoSelected && <label className="ais_current-mobile-device-label-header">
        {this.props.data.HEADER}
      </label>}
      <div className="ais_container-div-address deliverySchedule">
        {isWbkoSelected && <div>
          <img alt="schedule-delivery-icn" className="ais_confirm-delivery-icn" src={wbko_date} />
          <div className="wbkoChangeDeliveryScheduleText" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.WBKO_PREFFERED_DATE }} />
        </div>}
        {isWbkoSelected ? <div className="flex">
          <div className="wbkoChangeDeliveryDate">
            <select disabled={this.state.disable} name="selectDate" ref="selectDate" id="selectDate" className="ais_deliver-address-input wbkoRemoveExpandIcon" onChange={this.handleDeliveryDateChange}>
              <option value="">
                {this.props.data.PLACEHOLDERS.DATE}
              </option>
              {this.createDateData()}
            </select>
          </div>
          <div className="wbkoChangeDeliveryDateIcon">
            <img alt="schedule-delivery-icn" className="ais_confirm-delivery-icn" src={wbko_calendar_extra_small} />
          </div>
        </div> : <select disabled={this.state.disable} name="selectDate" ref="selectDate" id="selectDate" className="ais_deliver-address-input" onChange={this.handleDeliveryDateChange}>
          <option value="">
            {this.props.data.PLACEHOLDERS.DATE}
          </option>
          {this.createDateData()}
        </select>}
        <div> </div>
        <label>
          {this.state.deliveryWindow}
        </label>
        {/* <select disabled={this.state.disable} className="ais_deliver-address-input" onChange={this.handleDeliverySlotChange}>
                        <option value="">{this.props.data.PLACEHOLDERS.SLOT}</option>
                        <option value={CONSTANTS.SLOTS_MAPPING[1].LABEL}>{this.props.data.SLOT_OPTIONS[0]}</option>
                        <option value={CONSTANTS.SLOTS_MAPPING[2].LABEL}>{this.props.data.SLOT_OPTIONS[1]}</option>
                    </select> */}
        {selectedEnquiryOption && !isWbkoSelected ? <textarea className="ais_current-form-drop-Message-Input ais_textarea" value={this.state.reasonText} onChange={e => this.setReason(e.target.value)} disabled={this.state.disableInput} placeholder={MESSAGE_CONSTANTS.REASON_FOR_CHANGE} /> : ""}
        {/* <input className="deliver-address-input" type="text" onChange={(e) => this.setName(e)} placeholder="Select Time"/> */}
        <button disabled={this.state.disableButton} className={this.state.submitSch} onClick={e => this.handleSubmitClick(e)}>
          {this.props.data.BUTTONS[0]}
        </button>
      </div>
    </div>;
  }
}

const mapStateToProps = state => {
  const {
    shippingMethodsV2Result,
    shippingMethodsResult,
    selectedEnquiryOption,
    determineIncidentResponse,
    defaultDeliverySlot,
    isWbkoSelected
  } = state.customerData;
  return {
    isWbkoSelected,
    deliveryDate: shippingMethodsResult
      ? shippingMethodsResult.EstimatedDeliveryDate
      : null,
    slot: shippingMethodsResult
      ? shippingMethodsResult.ShippingMethodType
      : null,
    EnquiryForChangeDeliveryDate: determineIncidentResponse
      ? determineIncidentResponse.ExpectedDeliveyDate
      : "",
    selectedEnquiryOption,
    defaultDeliverySlot,
    shippingMethodsV2Result
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    doSaveDeliveryDate,
    doSaveDeliverySlot,
    savePayload,
    doSaveDeliverySpeed,
    doSaveEstimatedShipmentDate
  }, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(WbkoChangeDeliveryScheduleNew);