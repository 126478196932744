import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SelectAddressList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectBtnClassName: "ais_device-select-button",
        };
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick(id) {
        // console.log("button click", id);
        this.setState({
            selectBtnClassName: "ais_device-select-button selected"
        });

        // this.props.standardiseAddress(this.props.cacheId,id,this.props.warehouseId);
    }


    render() {
        const { address } = this.props;
        const { partialAddress, id } = address || {};
        // console.log("partialAddress", partialAddress);
        return (
            <div className="ais_current-form-device-div address-list">
                <div className="ais_header-txt-div">
                    <label className="ais_current-form-device-name ais_address-list">{partialAddress}</label>
                    <button className={this.state.selectBtnClassName} onClick={this.handleClick.bind(this, id)} >Select</button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        cacheId: state.customerData.clientData.CacheId
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        // standardiseAddress
    }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(SelectAddressList);

