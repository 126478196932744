import gql from 'graphql-tag';

export default gql`
subscription onCreateMessage($conversationId: ID!) {
  onCreateMessage(conversationId: $conversationId) {
			messageId
			visitorId
			conversationId
			messageType
			interactionType
			sender
			source
			content
			isSent
			recipient
			isActive
			createdAt
  }
}`;
