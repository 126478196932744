import { extendTheme } from '@chakra-ui/react';

const largeRadius = `${20 / 14}rem`;

const theme = extendTheme({
  fonts: {
    body: 'Helvetica, sans-serif',
  },
  fontSizes: {
    sm: `${12 / 14}rem`,
    lg: `${16 / 14}rem`,
  },
  lineHeights: {
    base: `${22 / 14}rem`,
  },
  shadows: {
    outline: 'none',
  },
  radii: {
    xl: `${10 / 14}rem`,
  },
  colors: {
    brand: {
      primary: '#6EA32D',
      highlight: '#BFD22B',
      error: '#DF070E',
    },
    gray: {
      150: '#E7E9EA',
      450: '#C0C2C4',
      550: '#8589A1',
      650: '#3D3D3D',
    },
  },
  components: {
    Text: {
      baseStyle: {
        fontFamily: 'Helvetica',
      },
    },
    Input: {
      sizes: {
        md: {
          addon: {
            borderRadius: largeRadius,
          },
          field: {
            borderRadius: largeRadius,
          },
        },
      },
    },
    Select: {
      sizes: {
        md: {
          field: {
            borderRadius: largeRadius,
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: largeRadius,
        px: largeRadius,
        py: `${10 / 14}rem`,
        minH: '40px',
        border: 'none',
        bg: 'transparent',
      },
      variants: {
        solid: {
          bgColor: 'brand.primary',
          color: 'white',
          _active: {
            bgColor: 'brand.highlight',
          },
          _hover: {
            bgColor: 'brand.highlight',
            _disabled: {
              bgColor: 'brand.primary',
            },
          },
        },
        outline: {
          color: 'gray.650',
          borderColor: 'gray.450',
          fontWeight: 400,
          bgColor: 'none',
        },
      },
    },
    Slider: {
      baseStyle: {
        thumb: {
          bg: 'brand.primary',
          borderColor: 'white',
          borderWidth: '1.5px',
        },
        track: {
          bg: 'gray.150',
        },
        filledTrack: {
          bg: 'brand.highlight',
        },
      },
      sizes: {
        md: {
          thumb: {
            w: 5,
            h: 5,
          },
          track: {
            h: '3px',
          },
        },
      },
    },
  },
});

export default theme;
