import React from "react";
import { Mutation } from "react-apollo";
import CreateChatRequestMutation from "./../../../graphql/mutation/CreateChatRequest";
import CallCreateChatRequest from "./CallCreateChatRequest";

const CreateChatRequest = ({ chatRequest, twilioTaskHandler, wrapUpCode }) => (
  <Mutation
    mutation={CreateChatRequestMutation}
    onCompleted={data => {
      if (!wrapUpCode) {
        console.log("chat request created, calling twilio handler", data);
        twilioTaskHandler();
      }
    }}
  >
    {createEncryptedChatRequest => {
      return (
        <CallCreateChatRequest
          createEncryptedChatRequest={createEncryptedChatRequest}
          chatRequest={chatRequest}
        />
      );
    }}
  </Mutation>
);

export default CreateChatRequest;
