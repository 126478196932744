import * as React from "react";
const DeviceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={50}
      height={50}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#C4C4C4" d="M0 0h50v50H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#6EA32D"
        fillRule="evenodd"
        d="M27.178 45.261H22.3a1.246 1.246 0 1 1 0-2.492h4.878a1.246 1.246 0 1 1 0 2.492Z"
        clipRule="evenodd"
      />
      <mask
        id="b"
        width={30}
        height={50}
        x={10}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M10 0h29.476v50H10V0Z"
          clipRule="evenodd"
        />
      </mask>
      <g fill="#000" mask="url(#b)">
        <mask
          id="c"
          width={30}
          height={49}
          x={9.737}
          y={0.25}
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M9.737.25h30v49h-30z" />
          <path
            fillRule="evenodd"
            d="M33.097 47.218H16.378a4.113 4.113 0 0 1-4.11-4.11v-3.327h24.938v3.328a4.113 4.113 0 0 1-4.109 4.109Zm-20.828-8.83h24.938V9.658H12.27v28.73Zm4.11-35.606h16.718a4.114 4.114 0 0 1 4.11 4.11v1.374H12.268V6.892a4.114 4.114 0 0 1 4.11-4.11ZM33.096 1.25H16.378a5.648 5.648 0 0 0-5.641 5.642v36.217c0 3.11 2.53 5.641 5.641 5.641h16.72a5.648 5.648 0 0 0 5.642-5.641V6.892a5.648 5.648 0 0 0-5.643-5.642Z"
            clipRule="evenodd"
          />
        </mask>
        <path
          fillRule="evenodd"
          d="M33.097 47.218H16.378a4.113 4.113 0 0 1-4.11-4.11v-3.327h24.938v3.328a4.113 4.113 0 0 1-4.109 4.109Zm-20.828-8.83h24.938V9.658H12.27v28.73Zm4.11-35.606h16.718a4.114 4.114 0 0 1 4.11 4.11v1.374H12.268V6.892a4.114 4.114 0 0 1 4.11-4.11ZM33.096 1.25H16.378a5.648 5.648 0 0 0-5.641 5.642v36.217c0 3.11 2.53 5.641 5.641 5.641h16.72a5.648 5.648 0 0 0 5.642-5.641V6.892a5.648 5.648 0 0 0-5.643-5.642Z"
          clipRule="evenodd"
        />
        <path
          d="M12.269 39.78v-.5h-.5v.5h.5Zm24.937 0h.5v-.5h-.5v.5ZM12.27 38.389h-.5v.5h.5v-.5Zm24.938 0v.5h.5v-.5h-.5Zm0-28.73h.5v-.5h-.5v.5Zm-24.938 0v-.5h-.5v.5h.5Zm24.937-1.392v.5h.5v-.5h-.5Zm-24.937 0h-.5v.5h.5v-.5Zm20.828 38.452H16.378v1h16.72v-1Zm-16.719 0a3.613 3.613 0 0 1-3.61-3.61h-1a4.613 4.613 0 0 0 4.61 4.61v-1Zm-3.61-3.61v-3.327h-1v3.328h1Zm-.5-2.827h24.938v-1H12.27v1Zm24.438-.5v3.328h1V39.78h-1Zm0 3.328c0 1.99-1.619 3.609-3.609 3.609v1a4.613 4.613 0 0 0 4.61-4.61h-1ZM12.27 38.888h24.938v-1H12.27v1Zm25.438-.5V9.658h-1v28.73h1Zm-.5-29.23H12.27v1h24.938v-1Zm-25.438.5v28.73h1V9.658h-1Zm4.61-6.376h16.718v-1H16.378v1Zm16.718 0c1.99 0 3.61 1.62 3.61 3.61h1a4.614 4.614 0 0 0-4.61-4.61v1Zm3.61 3.61v1.374h1V6.892h-1Zm.5.874H12.268v1h24.937v-1Zm-24.438.5V6.892h-1v1.374h1Zm0-1.374c0-1.99 1.619-3.61 3.61-3.61v-1a4.614 4.614 0 0 0-4.61 4.61h1ZM33.097.75H16.378v1h16.72v-1Zm-16.719 0a6.148 6.148 0 0 0-6.141 6.142h1a5.148 5.148 0 0 1 5.141-5.142v-1Zm-6.141 6.142v36.217h1V6.892h-1Zm0 36.217a6.148 6.148 0 0 0 6.141 6.141v-1a5.148 5.148 0 0 1-5.141-5.141h-1Zm6.141 6.141h16.72v-1h-16.72v1Zm16.72 0a6.148 6.148 0 0 0 6.142-6.141h-1a5.148 5.148 0 0 1-5.143 5.141v1Zm6.142-6.141V6.892h-1v36.217h1Zm0-36.217A6.148 6.148 0 0 0 33.097.75v1a5.148 5.148 0 0 1 5.143 5.142h1Z"
          mask="url(#c)"
        />
      </g>
    </g>
  </svg>
);
export default DeviceIcon;
