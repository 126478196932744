import React, { Fragment, Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CONSTANTS from '../../utils/Constants';
import {
    chargeOrderInit
} from '../../actions/Dashboard/doFindAgreements';
import { endInteractionInit, serviceOrderInit } from '../../actions/Dashboard/doFindAgreements';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import config from '../../config/config';
import { LeftArrowIcon } from "../../components/Icons/Icons";
import DOMPurify from "dompurify";
class CODForCreditCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
            yesButtonClassNameAlt: "ais_align-popup-button-alt",
            noButtonClassNameAlt: "ais_align-popup-button-alt",
            disable: false,
            selectFAQ: false
        }
        this.proceedClick = this.proceedClick.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
        this.closeClick = this.closeClick.bind(this);
        this.closeForMdn = this.closeForMdn.bind(this);
        this.handleBack = this.handleBack.bind(this)
    }

    proceedClick() {
        this.setState({
            disable: true,
            yesButtonClassNameAlt: "ais_align-popup-button-selected-alt"
        });
        if (this.props.source === CONSTANTS.ALTERNATE_DEVICE_NOTE) {
            let serviceOrderId = this.props.serviceOrderData ? this.props.serviceOrderData?.ServiceOrderId : null;
            if (!serviceOrderId) {
              this.props.serviceOrderInit(this.props.cacheId, this.props.selectedReplacementDevice, false, this.props.caseType);
            }
            this.props.updateJourneyList(CONSTANTS.ALTERNATE_DEVICE_NOTE, MESSAGE_CONSTANTS.PROCEED)
        } else {
            this.props.updateJourneyList(null, MESSAGE_CONSTANTS.PROCEED)
            this.props.chargeOrderInit({}, "", "cod")
        }
        //  this.props.updateJourneyList(CONSTANTS.COD_CONFIRMATION, "Proceed")
    }

    cancelClick() {
        this.setState({
            disable: true,
            noButtonClassNameAlt: "ais_align-popup-button-selected-alt"
        });
        if (this.props.source === CONSTANTS.ALTERNATE_DEVICE_NOTE) {
            this.props.updateJourneyList(CONSTANTS.ALTERNATE_DEVICE_SELECTED, MESSAGE_CONSTANTS.SELECT_DEVICE)
        } else {
            this.props.updateJourneyList(CONSTANTS.NOT_PROCEED_FURTHER, null)
        }
    }

    closeClick() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });
        this.props.endInteractionInit(this.props.cacheId);
    }

    closeForMdn() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });
        window.location.replace(config.ApplicationURL);
    }

    handleBack(message) {
        this.props.updateJourneyList(CONSTANTS.BACK_TO_PREV_STEP, message)
        this.setState({
            disable: true
        })
    }

    faqClick = () => {
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.GET_MOREINFO, MESSAGE_CONSTANTS.GET_MOREINFO.buttons[0].text);
        this.setState({
            disable: true,
            selectFAQ: true,
        })
    }
    chatWithUs = () => {
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.ASSIGN_TO_AGENT_FOR_CHAT);
        this.setState({
            disable: true
        })
    }

    render() {
        let { dataprops } = this.props;
        let { source } = dataprops || {};
        console.log("this.props", this.props);
        let { selectedDefectOption } = this.props;
        let excludeOptionsForBackToMainMenu = MESSAGE_CONSTANTS.EXCHANGE_OPTIONS.buttons;
        let filteredData = excludeOptionsForBackToMainMenu.filter(value => value.name === selectedDefectOption);
        let enableOrDisable = filteredData && filteredData.length > 0 ? true : false;
        if (source === CONSTANTS.ENROLLED_DEVICE_INQUIRY_SUCCESS) {
            return (
                <div className="ais_current-form-device-div ais_proceed_alt">
                    <div className="ais_header-txt-div">
                        <label className="ais_current-mobile-device-label script">{this.props.data.CONTENT}</label>
                    </div>
                    <div className="ais_outer-align-button-top-bottom">
                        <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width ${this.state.selectFAQ ? 'ais_selected' : ''}`} onClick={this.faqClick} >{MESSAGE_CONSTANTS.FAQ}</button>
                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.closeClick} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
                        <div style={{ margin: '20px 0' }}><span className={`backToMainMenu ${this.state.disable ? 'disabled' : ''}`} onClick={() => this.handleBack(MESSAGE_CONSTANTS.BackToMainMenu)}>{MESSAGE_CONSTANTS.BackToMainMenu}</span></div>
                    </div>
                </div>)
        }
        else if (source === CONSTANTS.ALTERNATE_DEVICE_NOTE) {
            return (
                <div className="ais_current-form-device-div ais_proceed_alt">
                    <div className="ais_header-txt-div">
                        <label className="ais_current-mobile-device-label script"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.data.CONTENT) }}></label>
                    </div>
                    <div className="ais_outer-align-button-top-bottom">
                        <button disabled={this.state.disable} className={this.state.noButtonClassNameAlt} onClick={this.cancelClick} >{this.props.data.BUTTONS[0]}</button>
                        <div class="ais_device-divider ais_button"> </div>
                        <button disabled={this.state.disable} className={this.state.yesButtonClassNameAlt} onClick={this.proceedClick} >{this.props.data.BUTTONS[1]}</button>
                    </div>
                </div>)
        }
        else {
            return (
                <div className="ais_current-form-device-div "> {/* ais_proceed  removed class for btn CSS*/}
                    <div className="ais_header-txt-div">
                        <label className="ais_current-mobile-device-label script"
                            dangerouslySetInnerHTML={{ __html: source === CONSTANTS.VOID_SCRIPT ? DOMPurify.sanitize(MESSAGE_CONSTANTS.VOID_SCRIPT) : DOMPurify.sanitize(this.props.data.CONTENT) }}></label>
                    </div>
                    {source === CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK ? (
                        <div className="ais_outer-align-button-top-bottom">
                            <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.closeClick} >{this.props.data.BUTTONS[0]}</button>
                        </div>) :
                        source === CONSTANTS.MDN_NRIC_CLOSE ? (
                            <div className="ais_outer-align-button-top-bottom">
                                <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.closeForMdn} >{this.props.data.BUTTONS[0]}</button>
                            </div>)
                            : source === CONSTANTS.GENERIC_NOT_RESOLVED ? (
                                <div className="ais_outer-align-button-top-bottom">
                                    <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.closeClick} >{this.props.data.BUTTONS[0]}</button>
                                </div>)
                                : source ===
                                    CONSTANTS.COD_FOR_CREDIT_CARD ? (
                                    <div className="ais_outer-align-button-top-bottom">
                                        <button disabled={this.state.disable} className={this.state.noButtonClassNameAlt} onClick={this.cancelClick} >{this.props.data.BUTTONS[0]}</button>
                                        <div className="ais_device-divider"></div>
                                        <button disabled={this.state.disable} className={this.state.yesButtonClassNameAlt} onClick={this.proceedClick} >{this.props.data.BUTTONS[1]}</button>
                                    </div>) : (source === CONSTANTS.CLOSE_SR || source === CONSTANTS.DEVICE_NOT_ELIGIBLE) ?
                                    <div className="ais_outer-align-button-top-bottom">
                                        <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width ${this.state.selectFAQ ? 'ais_selected' : ''}`} onClick={this.faqClick} >{MESSAGE_CONSTANTS.FAQ}</button>
                                        <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.closeClick} >{this.props.data.BUTTONS[0]}</button>
                                        {!enableOrDisable && <div className="text-left" style={{ marginLeft: '20px', marginTop: '20px' }}><span className={`ais_go_back ${this.state.disable ? 'disabled' : ''}`} onClick={() => this.handleBack(MESSAGE_CONSTANTS.BACK_TO_PREV_STEP)}><LeftArrowIcon />
                                            {MESSAGE_CONSTANTS.BACK_TO_PREV_STEP}</span></div>}
                                    </div> :
                                    (source === CONSTANTS.VOID_SCRIPT) ?
                                        <Fragment>
                                            <div className="ais_agent-busy-content">
                                                <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width margin-10 ${this.state.disable ? 'ais_selected' : ''}`} onClick={this.faqClick} >{MESSAGE_CONSTANTS.FAQ}</button>
                                                <button disabled={this.state.disable} className={`ais_device-select-button ais_device-btn-width ${this.state.disable ? 'ais_selected' : ''}`} onClick={this.chatWithUs} >{MESSAGE_CONSTANTS.CHAT_WITH_US}</button>
                                            </div></Fragment>
                                        : ""}
                </div>
            )

        }
    }
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        chargeOrderInit,
        endInteractionInit,
        serviceOrderInit
    }, dispatch)
}

const mapStateToProps = state => {
    const { clientData, selectedDefectOption, selectedReplacementDevice, serviceOrderData } = state.customerData;
    return {
        cacheId: clientData.CacheId, selectedDefectOption, selectedReplacementDevice, serviceOrderData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CODForCreditCard);
//export default UploadFileScript;