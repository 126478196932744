import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
class SelectedRequestOptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDiv: null
        }
    }

    _renderSelectedOptions(option, i) {
        let btnClasses = classNames({
            "ais_options-selected-div": this.props.data.selected_option.name === option.name,
            "ais_options-div": this.props.data.selected_option.name !== option.name
        })

        return <div name={option.text} key={i} className={btnClasses}>
            <label className={this.props.data.selected_option.name === option.name ? "ais_options-selected-label" : "ais_options-label"}>{option.text}</label></div>
    }

    _renderOptions(option, i) {
        return <div name={option.text} key={i} className="ais_options-div">
            <label className="ais_options-label">{option.text}</label></div>
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="ais_info-container">
                       {/*  <div className="ais_info-icon"></div> */}
                        <label className="ais_choose-option-txt">{this.props.data.options.title}</label>
                    </div>
                    {this.props.data && this.props.data.selected_option && this.props.data.options.buttons.map((e, i) => this._renderSelectedOptions(e, i))}
                    {this.props.data && !this.props.data.selected_option && this.props.data.options.buttons.map((e, i) => this._renderOptions(e, i))}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
  return {
    resumePoint: state.customerData.resumePoint
  }
};

export default connect(mapStateToProps, null)(SelectedRequestOptions);
//export default SelectedRequestOptions;
