import gql from 'graphql-tag';

export default gql`
subscription onCreateConversation($requestId: ID!) {
    onCreateConversation(requestId: $requestId){
        conversationId
        agentCognitoId
        requestId
        visitorId
        createdAt
        endTimestamp
    }
}`;