import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEmpty from 'lodash/isEmpty';
import { getFormattedPopularName, getFormattedTotalFee } from '../../utils/utils';
import { submitOrderInit, processPaymentInit, setRetryPaymentAttempt, savePayload, doSaveDeliveryDate,doSaveEstimatedDeliveryDate } from '../../actions/Dashboard/doFindAgreements';
import { SAVE_DEVICE_DETAILS, SAVE_OFFERED_DEVICE_IMG, EDIT_BUTTON_CLICKED } from '../../actions/Dashboard/actionTypes';
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from "../../config/journey-messages";
import noImage from '../../images/ais-images/icon-enrolled-device.png';
import editIcon from "../../images/ais-images/icon-edit-hover.png";


class ConfirmYourDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            srNo: "",
            type: "",
            sim: "",
            deviceInfo: "",
            address: "",
            deliveryDate: "",
            deliveryTime: "",
            fee: "",
            cardType: "",
            cardNo: "",
            imageMake: "",
            imageModel: "",
            buttonClassName: "ais_confirm-details-button",
            paymentRetryAttemptState: this.props.paymentRetryAttempt || false,
            disableButton: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleEditButton = this.handleEditButton.bind(this);
    }

    componentDidMount() {
        if (this.props.PaymentFailedAttempt > 1) {
            this.props.setRetryPaymentAttempt(false);
        }
    }

    handleClick() {
        this.setState({
            disable: true,
            buttonClassName: "ais_confirm-details-button-highlighted",
            disableButton: true
        });
        this.props.updateJourneyList(this.props.dataprops.source, "Confirmed");
        // this.props.submitOrderInit(this.props.cacheId);

        if (this.props.chargeOrder &&
          this.props.chargeOrder.ChargeOrderStatus &&
          this.props.chargeOrder.ChargeOrderStatus.toUpperCase() === "CHARGED" &&
          this.props.chargeOrder.PaymentMethodType &&
          this.props.chargeOrder.PaymentMethodType.toUpperCase() === "CRE") {
            this.props.submitOrderInit(this.props.cacheId);
        } else {
          this.props.processPaymentInit(this.props.chargeOrderDetails);
        }
    }

    componentWillMount() {
        let { selectedReplacementDevice: { HorizonItemInformation: { Make = '', Model = '', AssetCatalogName = '' } = {} } = {}, caseType, cardType, fee, username, selectedDeliveryDate: deliveryDate, selectedDeliverySlot, simRequired, ccmask, caseNumber, addressList, standardizedAddress, slectedMakeModel, deviceNotAvailable } = this.props;
        let { AssetCatalog } = this.props.selectedReplacementDevice;
        let deviceSpecs = AssetCatalogName ?
            getFormattedPopularName(AssetCatalogName) :
            AssetCatalog ? getFormattedPopularName(AssetCatalog.AssetCatalogName) : {
                modelName: `${Make} ${Model}`,
                color: "",
                specs: ""
            };

        if (!deviceNotAvailable && slectedMakeModel) {
            deviceSpecs = {
                modelName:`${slectedMakeModel.Make} ${slectedMakeModel.Model}` ,
                color: "",
                specs: ""
            };
        }
        let sim = simRequired ? CONSTANTS.WITH_SIM : CONSTANTS.WITHOUT_SIM;
        let cardNo = ccmask || "****";
        let selectedDeviceInfo = `${deviceSpecs.modelName} ${deviceSpecs.specs} ${deviceSpecs.color}`;
        let deviceImageJPG = `${this.props.imageUrl}${Make.toUpperCase()}_${Model.toUpperCase()}_S.jpg`;

        this.props.savePayload(SAVE_DEVICE_DETAILS, selectedDeviceInfo);
        this.props.savePayload(SAVE_OFFERED_DEVICE_IMG, deviceImageJPG);

        this.setState({
            username: username.fullName,
            srNo: caseNumber,
            type: caseType,
            deviceInfo: `${deviceSpecs.modelName} ${deviceSpecs.specs} ${deviceSpecs.color}`,
            sim,
            address: (addressList.AddressResult && addressList.AddressResult.CorrectedAddress) || standardizedAddress,
            deliveryDate,
            deliveryTime: selectedDeliverySlot,
            cardType,
            cardNo,
            fee,
            imageMake: Make,
            imageModel: Model
        });
    }

    handleEditButton = (option) => {
        this.props.savePayload(EDIT_BUTTON_CLICKED, false);
        this.props.doSaveDeliveryDate(null);
        if (!this.state.disableButton) {
            this.setState({
                disable: true,
                disableButton: true
            })
            if (option === CONSTANTS.API_NAMES.STANDARDISE_ADDRESS) {
                this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION, MESSAGE_CONSTANTS.EDIT_DELIVERY_ADDRESS);
            } else if (option === CONSTANTS.API_NAMES.SHIPPING_METHODS) {
                this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_FOR_PAYMENT, MESSAGE_CONSTANTS.EDIT_DELIVERY_SCHEDULE);
                this.props.doSaveEstimatedDeliveryDate(this.props.EstimatedDeliveryDate,this.props.EstimatedShipmentDate,this.props.ShippingMethodType)
            } else if (option === CONSTANTS.API_NAMES.REPLACEMENT) {
                this.props.updateJourneyList(CONSTANTS.CHECK_DEVICE_STOCK, "");
            }
            else {
                this.props.updateJourneyList(CONSTANTS.PAYMENT_FEE, MESSAGE_CONSTANTS.PAYMENT_NEW);
            }
        }
    }

    render() {
        let { srNo, deviceInfo, sim, address, deliveryDate, deliveryTime, cardType, cardNo, fee, buttonClassName, imageMake, imageModel } = this.state;
        let { noServiceFee, username, postalCode, NLFLDeviceInStock, chargeOrder } = this.props;
        let capsMake = imageMake.toUpperCase();
        let capsModel = imageModel.toUpperCase();
        let deviceImageUC = capsMake + "_" + capsModel + "_S";
        let deviceImageJPG = this.props.imageUrl + deviceImageUC + ".jpg";
        // let deviceImagePNG = this.props.imageUrl + deviceImageUC + ".png";
        // let deviceImageURL = <Img src={[deviceImageJPG,deviceImagePNG,noImage]} alt="selected-device" className="device-img" />;
        return (
            <div className="ais_confirm-details-div">
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
                </div>
                <div className="ais_confirm-form-details-header-div">
                    <label className="ais_confirm-details-blue-label">{this.props.data.SR_NO}</label>
                    <label className="ais_confirm-details-blue-label-custum">{this.props.data.TYPE}</label>
                    <label className="ais_confirm-details-dark-blue-label">{srNo}</label>
                    <label className="ais_confirm-details-dark-blue-label-custum">{this.props.caseType}</label>

                    <div className="ais_swop-device-header-div">
                        <label className="ais_confirm-details-blue-label">{this.props.data.DEVICE}
                            {NLFLDeviceInStock ? <img alt="cancel" disabled={this.state.disableButton} hspace="5" className="ais_cancel-icon" src={editIcon} onClick={(e) => this.handleEditButton(CONSTANTS.API_NAMES.REPLACEMENT)} /> : ""}
                        </label>
                        <div>
                            <img alt="" className="ais_current-form-device-icn" src={deviceImageJPG} onError={(e) => { e.target.src = noImage }} />
                            <div className="ais_swop-device-name">{deviceInfo}</div>
                        </div>
                    </div>

                    <div className="ais_swop-device-header-div">
                        {/* <div> */}
                        <label className="ais_confirm-details-blue-label">{this.props.data.SHIPPING_TO}
                            <img alt="cancel" disabled={this.state.disableButton} hspace="5" className="ais_cancel-icon" src={editIcon} onClick={(e) => this.handleEditButton(CONSTANTS.API_NAMES.STANDARDISE_ADDRESS)} />
                        </label>
                        {/* </div> */}
                        <div className="ais_other-details-desc">{username.fullName}</div>
                        {/* <div className="ais_other-details-desc">{this.props.data.PIN_CODE + " " + address}</div> */}
                        {(address.AddressLine1) ? <div><label className="ais_confirm-details-dark-label">{address.AddressLine1}</label></div> : ''}
                        {(address.AddressLine2) ? <div><label className="ais_confirm-details-dark-label">{address.AddressLine2}</label></div> : ''}
                        {(address.AddressLine3) ? <div><label className="ais_confirm-details-dark-label">{address.AddressLine3}</label></div> : ''}
                        {(postalCode) ? <div><label className="ais_confirm-details-dark-label">{postalCode}</label></div> : ''}
                        {(address.City) ? <div><label className="ais_confirm-details-dark-label">{address.City}</label></div> : ''}
                        {(address.StateProvinceCode) ? <div><label className="ais_confirm-details-dark-label">{address.StateProvinceCode}</label></div> : ''}
                        {(address.Country) ? <div><label className="ais_confirm-details-dark-label">{address.Country}</label></div> : ''}
                    </div>

                    {this.props.backOrderCase ? "" : <div className="ais_swop-device-header-div">
                        <label className="ais_confirm-details-blue-label">{this.props.data.DELIVERY_SCHEDULE}
                            <img alt="cancel" disabled={this.state.disableButton} hspace="5" className="ais_cancel-icon" src={editIcon} onClick={(e) => this.handleEditButton(CONSTANTS.API_NAMES.SHIPPING_METHODS)} />
                        </label>
                        <div className="ais_other-details-desc">{deliveryDate + ", " + deliveryTime}</div>
                    </div>}

                    {noServiceFee ? "" : <div className="ais_swop-device-header-div">
                        <label className="ais_confirm-details-blue-label">{this.props.data.PAID_WITH}
                            <img alt="cancel" disabled={this.state.disableButton} hspace="5" className="ais_cancel-icon" src={editIcon} onClick={(e) => this.handleEditButton(CONSTANTS.API_NAMES.PAYMENT)} /></label>
                        <label className="ais_confirm-details-blue-label">{this.props.data.FEE}</label>

                        {(chargeOrder &&
                          chargeOrder.ChargeOrderStatus &&
                          chargeOrder.ChargeOrderStatus.toUpperCase() === "CHARGED" &&
                          chargeOrder.PaymentMethodType &&
                          chargeOrder.PaymentMethodType.toUpperCase() === "CRE"
                        )
                          ? <label className="ais_confirm-details-dark-blue-label">{MESSAGE_CONSTANTS.CREDIT_CARD_TEXT}</label>
                          : this.props.paymentMethod === "cod" || !cardType
                            ?
                            <label className="ais_confirm-details-dark-blue-label">{this.props.data.CASH_ON_DELIVERY}</label>
                            :
                            <label className="ais_confirm-details-dark-blue-label">{cardType + " Card " + cardNo}</label>
                        }

                        <label className="ais_confirm-details-dark-blue-label">{fee}</label>
                    </div>}

                    <div className="ais_swop-device-header-div">
                        <button disabled={this.state.disable} className={buttonClassName} onClick={(e) => this.handleClick(e)} >{this.props.data.BUTTON}</button>
                    </div>
                </div>

            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        submitOrderInit,
        processPaymentInit,
        setRetryPaymentAttempt,
        savePayload,
        doSaveDeliveryDate,
        doSaveEstimatedDeliveryDate
    }, dispatch)
};


const mapStateToProps = state => {
    let { cardType, deviceNotAvailable, slectedMakeModel, customerName, postalCode, serviceFee, selectedReplacementDevice, selectedReplacementDeviceFromDetermine, NLFLDeviceInStock, 
        noServiceFee, chargeOrderDetails, paymentMethod, caseType, paymentRetryAttempt, processPaymentResults, selectedDeliveryDate,
         selectedDeliverySlot, shippingMethodsResult, processIncidentData, simRequired, ccmask, customerCase, resumeServiceRequestResult, 
         clientData, backOrderCase, addressList = {}, standardizedAddress = {},shippingMethodsV2Result, determineIncidentResponse } = state.customerData;

         console.log("paymentMethod", paymentMethod)
         console.log("cardType", cardType)
    let totalAmount = (paymentMethod === "cod" || !cardType)
      ? (serviceFee
        ? serviceFee.CurrencyCode + " " + getFormattedTotalFee(serviceFee.TotalAmount)
        : null)
      : (serviceFee 
        ? serviceFee.CurrencyCode + " " + getFormattedTotalFee(serviceFee.TotalAmount)
        : null)

    if (totalAmount) {
      if (!_.isEmpty(selectedReplacementDeviceFromDetermine) &&
        !_.isEmpty(selectedReplacementDeviceFromDetermine?.HorizonItemInformation) &&
        selectedReplacementDeviceFromDetermine?.HorizonItemInformation?.ServiceFee) {
        totalAmount = serviceFee.CurrencyCode + " " + getFormattedTotalFee(
          `${parseFloat(selectedReplacementDeviceFromDetermine.HorizonItemInformation.ServiceFee).toFixed(2)}`
        );
      }
    }

    return {
        cacheId: clientData.CacheId,
        paymentRetryAttempt,
        PaymentFailedAttempt: processPaymentResults ? processPaymentResults.PaymentFailedAttempt : {},
        username: customerName,
        selectedDeliveryDate: selectedDeliveryDate,
        selectedDeliverySlot: selectedDeliverySlot,
        slot: shippingMethodsResult ? shippingMethodsResult.ShippingMethodType : null,
        caseType: caseType,
        processIncidentData: processIncidentData,
        simRequired: simRequired,
        ccmask: ccmask,
        caseNumber: (Object.keys(customerCase).length !== 0) ? customerCase.CustomerCaseNumber : (Object.keys(resumeServiceRequestResult).length !== 0) ? resumeServiceRequestResult.CustomerCaseNumber : "NA",
        imageUrl: clientData.imageBaseUrl,
        backOrderCase: backOrderCase,
        paymentMethod: paymentMethod,
        chargeOrderDetails: chargeOrderDetails,
        cardType: cardType,
        noServiceFee: noServiceFee,
        postalCode: postalCode,
        addressList,
        standardizedAddress,
        NLFLDeviceInStock,
        fee: totalAmount,
        selectedReplacementDevice: selectedReplacementDevice,
        deviceNotAvailable: deviceNotAvailable,
        slectedMakeModel: slectedMakeModel,
        EstimatedDeliveryDate:
        shippingMethodsV2Result && shippingMethodsV2Result[0] && shippingMethodsV2Result[0].EstimatedDeliveryDate,
        EstimatedShipmentDate: 
        shippingMethodsV2Result && shippingMethodsV2Result[0] && shippingMethodsV2Result[0].EstimatedShipmentDate,
        ShippingMethodType : shippingMethodsV2Result && shippingMethodsV2Result[0] && shippingMethodsV2Result[0].ShippingMethodType,
        chargeOrder: determineIncidentResponse ? determineIncidentResponse.ChargeOrder : null
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmYourDetails);
