import React from "react";
import { Mutation } from "react-apollo";
import UpdateVisitorMutation from './../../../graphql/mutation/UpdateVisitor';
import CallUpdateVisitor from './CallUpdateVisitor';
//import CONSTANTS from '../../../utils/Constants'

const UpdateVisitor = ({
  visitorId,
  mdn,
  ipAddress,
  journeyCmpltd,
  journeyStatus,
  customerName,
  lastUpdatedTime,
  caseNumber,
  chatAssisted,
  interactionId,
  interactionType,
  lastActivity,
  perilType,
  chatReason,
}) => (
  <Mutation mutation={UpdateVisitorMutation}>
    {(updateVisitor) => {
      let visitor = {
        input: {
          visitorId: visitorId
        }
      }

      if (mdn) visitor.input.mdn = mdn;

      if (journeyCmpltd && journeyStatus) visitor.input.journeyStatus = journeyStatus;

      if (lastUpdatedTime) visitor.input.lastUpdatedTime = lastUpdatedTime;

      if (caseNumber) visitor.input.caseNumber = caseNumber;

      if (chatAssisted) visitor.input.chatAssisted = chatAssisted;

      if (interactionId) visitor.input.interactionId = interactionId;

      if (lastActivity) visitor.input.lastActivity = lastActivity;

      if (interactionType) visitor.input.interactionType = interactionType;

      if (perilType) visitor.input.perilType = perilType;

      if (chatReason) visitor.input.chatReason = chatReason;

      return (
        <CallUpdateVisitor updateVisitor={updateVisitor} visitor={visitor} />
      )
    }}
  </Mutation>
);

export default UpdateVisitor;