import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CONSTANTS from './../../utils/Constants';
import classnames from 'classnames';
import MESSAGE_CONSTANTS from './../../config/journey-messages';
import creditCardIcon from '../../images/ais-images/icon-credit-card-active.png';
import cashOnDeliveryIcon from '../../images/ais-images/icon-cash-on-delivery-active.png';
import creditIcon from '../../images/ais-images/icon-credit-card-normal (1).png';
import cashIcon from '../../images/ais-images/icon-cash-on-delivery-normal.png';
import { getFormattedTotalFee } from '../../utils/utils';
import invoiceIcon from '../../images/ais-images/icon-service-fee.png';
import paymentFeeIcon from '../../images/ais-images/icon-payment-fee.png';
import feedbackFeeIcon from './../../images/ais-images/icon-feedback.png';
import {
  getSurvey,
  getPCITokenInit,
  saveSelectedReplacementDevice,
  serviceOrderInit,
  chargeOrderInit,
  getShippingOrderInit,
} from './../../actions/Dashboard/doFindAgreements';

class ProceedServiceFee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightedClass: '',
      disable: false,
      cardButtonClicked: false,
      deliveryButtonClicked: false,
      creditCard: 'creditCard',
      onDelivery: 'cashOnDelivery',
      cancelButtonClass: '',
      expandFeePopup: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.backButtonClicked = this.backButtonClicked.bind(this);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.selectedReplacementDevice)) {
      this.props.saveSelectedReplacementDevice(this.props.LFLDevice);
    }
  }

  backButtonClicked() {
    let {
      dataprops: { source },
    } = this.props;
    this.setState({
      disable: true,
    });
    if (source === CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT) {
      this.props.updateJourneyList(
        CONSTANTS.PAYMENT_FEE,
        MESSAGE_CONSTANTS.PAYMENT_CANCELLED_PROCEED
      );
    } else {
      this.props.updateJourneyList(
        CONSTANTS.SOURCE_USER_INPUT.ENROLLED_EMAIL_CONFIRMATION,
        MESSAGE_CONSTANTS.SELECT_ADDRESS
      );
    }
  }
  handleCancelClick() {
    this.setState({
      cancelButtonClass: 'ais_device-select-button ais_credit-card ais_btnSel',
    });
    this.props.updateJourneyList(
      CONSTANTS.PAYMENT_FEE,
      MESSAGE_CONSTANTS.PAYMENT_CANCELLED_PROCEED
    );
  }

  getFeeDetails(e) {
    {
      this.state.expandFeePopup
        ? this.setState({
            expandFeePopup: false,
          })
        : this.setState({
            expandFeePopup: true,
          });
    }
  }

  handleClick(e, paymentMethod = this.props.noServiceFee ? 'cod' : '') {
    let {
      cacheId,
      LFLDevice,
      LFLDeviceInStock,
      noServiceFee,
      caseType,
      dataprops: { source },
    } = this.props;

    this.setState({
      highlightedClass: 'ais_device-select-button-highlighted',
      disable: true,
    });

    if (source === CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED) {
      if (LFLDeviceInStock && caseType !== CONSTANTS.SWAP) {
        this.props.serviceOrderInit(cacheId, LFLDevice, false, caseType);
      }
      this.props.updateJourneyList(CONSTANTS.CHECK_DEVICE_STOCK, '');
    }

    if (
      source === CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION ||
      source === CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF
    ) {
      // MIXPANEL.track(EVENTLIST.PAYMENT_FEE, null, function(){
      //         MIXPANEL.time_event(EVENTLIST.PAYMENT);
      // });

      if (!this.state.disable) {
        if (paymentMethod === 'creditcard') {
          this.setState({
            cardButtonClicked: true,
            deliveryButtonClicked: false,
            disable: true,
          });
          if (noServiceFee) {
            // if service fee is 0 , the call charge order but don't call BG.
            let noServiceFeeStandAloneCall = true;
            this.props.chargeOrderInit({}, noServiceFeeStandAloneCall, paymentMethod);
            // this.props.updateJourneyList(CONSTANTS.PAYMENT_VERIFIED, "Proceed");
          } else {
            this.props.updateJourneyList(
              CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT,
              MESSAGE_CONSTANTS.CREDIT_CARD_TEXT
            );
            //here we will not call for PCI token, but update journey message to confirm payment fee to the user. after payment fee, we will call pci token
            // this.props.getPCITokenInit(cacheId, paymentMethod);
          }
          // return false;
        } else {
          this.setState({
            cardButtonClicked: false,
            deliveryButtonClicked: true,
            disable: true,
          });
          if (!noServiceFee) {
            this.props.updateJourneyList(null, MESSAGE_CONSTANTS.COD_TEXT);
          } else {
            this.props.updateJourneyList(null, MESSAGE_CONSTANTS.PROCEED);
          }
          this.props.chargeOrderInit({}, '', paymentMethod);
        }
      }
    }

    if (source === CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION) {
      let { cacheId, clientId, getSurvey, serviceRequestId } = this.props;

      let getSurveyParams = {
        SessionId: cacheId,
        ClientId: clientId,
        TriggerPoint: CONSTANTS.SURVEY_TRIGGER_POINT,
        SurveyType: CONSTANTS.SURVEY_TYPE,
        ServiceRequestId: serviceRequestId || '',
      };

      getSurvey(getSurveyParams);
    }

    if (source === CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT) {
      this.props.getPCITokenInit(cacheId, paymentMethod, this.props.serviceFee.TotalAmount);
    }

    if (source === CONSTANTS.PAYMENT_VERIFIED) {
      // this.props.updateJourneyList(source, "Payment verified");
      const { cacheId, warehouseId, isEnrolledAddress } = this.props;
      this.props.updateJourneyList(null, MESSAGE_CONSTANTS.PROCEED);
      this.props.getShippingOrderInit(cacheId, warehouseId, isEnrolledAddress);
    }
  }

  replaceValues() {
    var { data, serviceFee, noServiceFee, caseType, selectedReplacementDeviceFromDetermine } = this.props;
    var messageContent = data.CONTENT;
    let componentMsgToBeReplaced = [
      CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED,
      CONSTANTS.DISABLED_PROCEED_SERVICE_FEE,
      CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF,
      CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION,
    ];
    var totalFee = 0;
    if (serviceFee) {
      totalFee = getFormattedTotalFee(serviceFee.TotalAmount);
    }
    if (!_.isEmpty(selectedReplacementDeviceFromDetermine) &&
      !_.isEmpty(selectedReplacementDeviceFromDetermine?.HorizonItemInformation) &&
      selectedReplacementDeviceFromDetermine?.HorizonItemInformation?.ServiceFee) {
      totalFee = getFormattedTotalFee(
        `${parseFloat(selectedReplacementDeviceFromDetermine.HorizonItemInformation.ServiceFee).toFixed(2)}`
      );
    }
    if (this.props.dataprops.source === CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT) {
      console.log('service fee', serviceFee);
      messageContent = messageContent
        .replace('{perilType}', caseType.toLowerCase())
        .replace('{TotalAmount}', totalFee);
      return messageContent;
    }
    if (this.props.dataprops.source === CONSTANTS.PAYMENT_VERIFIED) {
      console.log('service fee', serviceFee);
      messageContent = messageContent.replace('{TotalAmount}', totalFee);
      return messageContent;
    }

    if (_.indexOf(componentMsgToBeReplaced, this.props.dataprops.source) > -1) {
      if (noServiceFee) {
        messageContent = messageContent
          .replace('{perilType}', caseType.toLowerCase())
          .replace('{TotalAmount}', totalFee)
          .replace(MESSAGE_CONSTANTS.READY_CREDIT_CARD, '');
      } else {
        messageContent = messageContent
          .replace('{perilType}', caseType.toLowerCase())
          .replace('{TotalAmount}', totalFee);
      }
    }
    return messageContent;
  }

  render() {
    let { cardButtonClicked, deliveryButtonClicked, disable } = this.state;
    let {
      dataprops: { source },
      noServiceFee,
      serviceFee,
    } = this.props;

    let creditCardButton = classnames({
      'ais_btn-div': true,
      ais_selected: cardButtonClicked,
    });

    let cashOnDelivery = classnames({
      'ais_btn-div': true,
      ais_selected: deliveryButtonClicked,
    });
    return (
      <div className="ais_current-form-device-div ais_proceed COD">
        <div className="ais_header-txt-div proceedFee">
          <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
          {source === CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED ||
          source === CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF ? (
            <div>
              <img alt="invoice-icon" className="ais_confirm-delivery-icn" src={invoiceIcon} />
            </div>
          ) : source === CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION ? (
            <div>
              <img
                alt="payment-fee-icon"
                className="ais_confirm-delivery-icn"
                src={paymentFeeIcon}
              />
            </div>
          ) : source === CONSTANTS.SOURCE_USER_INPUT.FEEDBACK_CONFIRMATION ? (
            <div>
              <img alt="feedback-icon" className="ais_confirm-delivery-icn" src={feedbackFeeIcon} />
            </div>
          ) : source === CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT ? (
            <div>
              <img alt="feedback-icon" className="ais_confirm-delivery-icn" src={paymentFeeIcon} />
            </div>
          ) : source === CONSTANTS.COD_CONFIRMATION ? (
            <div>
              <img alt="feedback-icon" className="ais_confirm-delivery-icn" src={paymentFeeIcon} />
            </div>
          ) : (
            <div>
              <img
                alt="feedback-icon"
                className="ais_confirm-delivery-icn"
                src={cashOnDeliveryIcon}
              />
            </div>
          )}
          <label
            className="ais_current-device-spec font-weight"
            dangerouslySetInnerHTML={{ __html: this.replaceValues() }}
          />
          {!noServiceFee &&
          (source === CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED ||
            source === CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF ||
            source === CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION ||
            source === CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT ||
            source === CONSTANTS.COD_CONFIRMATION) ? (
            <div>
              <br />
              <a>
                <div style={{ marginBottom: 10 }} onClick={(e) => this.getFeeDetails(e)}>
                  {' '}
                  {MESSAGE_CONSTANTS.GET_FEE_DETAILS}
                </div>
              </a>
              {this.state.expandFeePopup ? (
                <div>
                  {/*<div className="ais_fee_calculation"
                  dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.FEE_CALCULATION }}>
                </div>*/}

                  <table className="terms-conditions-table">
                    <tbody>
                      <tr>
                        <th>{MESSAGE_CONSTANTS.PURCHASE_FEE}</th>
                        <td>{getFormattedTotalFee(serviceFee.PurchasePrice)}</td>
                      </tr>
                      <tr>
                        <th>{MESSAGE_CONSTANTS.FEE_CHARGES}</th>
                        <td>
                          {' '}
                          {serviceFee.FeePercentage} {'%'}
                        </td>
                      </tr>
                      <tr>
                        {' '}
                        <th> {MESSAGE_CONSTANTS.FEE_AMOUNT}</th>
                        <td>{getFormattedTotalFee(serviceFee.FeeAmount)}</td>
                      </tr>
                      <tr>
                        {' '}
                        <th>{MESSAGE_CONSTANTS.TAX_CHARGES}</th>
                        <td>
                          {serviceFee.TaxPercentage} {'%'}
                        </td>
                      </tr>
                      <tr>
                        {' '}
                        <th>{MESSAGE_CONSTANTS.TAX_AMMOUNT}</th>
                        <td>{getFormattedTotalFee(serviceFee.TaxAmount)}</td>
                      </tr>
                      <tr>
                        {' '}
                        <th>{MESSAGE_CONSTANTS.TOTAL_AMOUNT}</th>
                        <td>{getFormattedTotalFee(serviceFee.TotalAmount)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {source === CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION ? (
            <div>
              <div>
                <button
                  className={creditCardButton}
                  id="card-btn"
                  onClick={(e) => this.handleClick(e, 'creditcard')}
                  disabled={disable}
                >
                  <img
                    src={cardButtonClicked ? creditIcon : creditCardIcon}
                    className="ais_image-icon-div"
                  />
                  <label className="ais_btn-text">{this.props.data.BUTTON1}</label>
                </button>
                {/* // Only check COD condition when replacement */}
                {!(this.props.caseType === CONSTANTS.REPLACEMENT && !this.props.ISCOD) && (
                  <button
                    className={cashOnDelivery}
                    id="delivery-btn"
                    onClick={(e) => this.handleClick(e, 'cod')}
                    disabled={disable}
                  >
                    <img
                      src={deliveryButtonClicked ? cashIcon : cashOnDeliveryIcon}
                      className="ais_image-icon-div"
                    />
                    <label className="ais_btn-text">{this.props.data.BUTTON2}</label>
                  </button>
                )}
                {/* <button
                  className={cashOnDelivery}
                  id="delivery-btn"
                  onClick={(e) => this.handleClick(e, 'cod')}
                  disabled={disable}
                >
                  <img
                    src={deliveryButtonClicked ? cashIcon : cashOnDeliveryIcon}
                    className="ais_image-icon-div"
                  />
                  <label className="ais_btn-text">{this.props.data.BUTTON2}</label>
                </button> */}
                {/* cardButtonClicked === true ?
                  <div className="ais_discount-wrapper">
                    <span className="ais_mandatory-astrix-cus">{this.props.data.DISCOUNT}</span> <br />
                    <span className="ais_mandatory-astrix">{this.props.data.DISCOUNT1}</span>
                  </div>
                : null */}
              </div>
              {/* {cardButtonClicked === false ?  */}
              {/* (<span dangerouslySetInnerHTML={{ __html: this.props.data.NOTE }} /> */}
            </div>
          ) : source === CONSTANTS.PAYMENT_VERIFIED ? (
            <div className="ais_outer-confirm-delivery-align-button">
              <button
                disabled={this.state.disable}
                className={
                  'ais_device-select-button ais_credit-card ' + this.state.cancelButtonClass
                }
                onClick={this.handleCancelClick}
              >
                {this.props.data.BUTTONS[0]}
              </button>
              <div className="ais_device-divider" />
              <button
                disabled={this.state.disable}
                className={
                  'ais_device-select-button ais_credit-card ' + this.state.highlightedClass
                }
                onClick={this.handleClick}
              >
                {this.props.data.BUTTONS[1]}
              </button>
            </div>
          ) : (
            <div className="ais_outer-align-button">
              <button
                disabled={this.state.disable}
                className={'ais_device-select-button ais_proceed ' + this.state.highlightedClass}
                onClick={this.handleClick}
              >
                {this.props.data.BUTTON}
              </button>
            </div>
          )}
        </div>
        {(source === CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION ||
          source === CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT) &&
        this.props.backEnabled ? (
          <button
            disabled={this.state.disable}
            className="ais_go_back"
            onClick={this.backButtonClicked}
          >
            {' '}
            {MESSAGE_CONSTANTS.BACK_TO_PREV_STEP}{' '}
          </button>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    noServiceFee,
    clientData,
    warehouseId,
    backEnabled,
    isEnrolledAddress,
    LFLDevice,
    caseType,
    selectedReplacementDevice,
    selectedReplacementDeviceFromDetermine,
    LFLDeviceInStock,
    serviceFee,
    serviceRequestId,
    ISCOD,
  } = state.customerData;
  return {
    cacheId: clientData.CacheId,
    clientId: clientData.ClientId,
    noServiceFee,
    LFLDevice,
    caseType,
    selectedReplacementDevice,
    selectedReplacementDeviceFromDetermine,
    LFLDeviceInStock,
    serviceFee,
    serviceRequestId,
    warehouseId,
    isEnrolledAddress,
    backEnabled,
    ISCOD,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSurvey,
      getPCITokenInit,
      serviceOrderInit,
      chargeOrderInit,
      saveSelectedReplacementDevice,
      getShippingOrderInit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProceedServiceFee);
