import React, { Component, Fragment } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import _isEmpty from 'lodash/isEmpty';
import _extend from 'lodash/extend';
import {
    getMakeModelList,
    createNewServiceRequestInit,
    saveSelectedDeviceModel,
    processIncidentInit,
    setProgressBarValue,
    savePayload,
    updateTransitionPanel
} from '../../actions/Dashboard/doFindAgreements';

import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { SAVE_SELECTED_MAKE_MODEL } from '../../actions/Dashboard/actionTypes';
import { getFormattedPopularName } from '../../utils/utils';

import feedbackFeeIcon from "../../images/ais-images/icon-select-correct-device.png";
class CurrentDeviceOptions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            highlightedClass: "",
            disable: true,
            dropdownOpen: false,
            brandTitle: this.props.selectedBrand ? this.props.selectedBrand : MESSAGE_CONSTANTS.SELECT_BRAND,
            modelTitle: MESSAGE_CONSTANTS.SELECT_MODEL,
            catalogId: null,
            enteredImei: "",
            textboxDisable: false,
            displayError: false,
            inputClassName: "ais_deliver-address-input ais_mis",
            makeDisable:false,
            modelDisable:true
        };
        this.setIMEI = this.setIMEI.bind(this);
    }

    componentDidMount() {
        this.props.getMakeModelList()
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleClick = () => {

        if (this.state.enteredImei.length < 15) {
            this.setState({
                displayError: true,
                inputClassName: "ais_deliver-address-input ais_mismatch"
            });
        }else {
            this.setState({
                highlightedClass: "ais_device-select-button-highlighted",
                disable: true,
                textboxDisable: true,
                displayError: false,
                inputClassName: "ais_deliver-address-input ais_mis",
                makeDisable:true,
                modelDisable:true
            });
            //this.props.updateJourneyList(this.props.source, "Selected Device");

            let ProcessIncidentParameters = {
                SessionId: this.props.cacheId,
                Incident: {
                    FailureDescriptiveText: this.props.selectedDefectOption,
                    IncidentType: this.props.caseType
                },
                AssetDetails: {
                    AssetCatalogId: this.state.catalogId,
                    SerialNumber: this.state.enteredImei,
                }
            }

            let selectedDevice = {
                Make: this.state.brandTitle,
                Model: this.state.modelTitle,
                SerialNumber: this.state.enteredImei
            }

            this.props.savePayload(SAVE_SELECTED_MAKE_MODEL, selectedDevice);
            this.props.saveSelectedDeviceModel(this.state.modelTitle);
            //this.props.updateJourneyList(this.props.source, "Selected Device");
            let userSelectedMakeModel = true;
            const { brandTitle, modelTitle } = this.state;
            let deviceDetails = MESSAGE_CONSTANTS.SELECTED_DEVICE + `${brandTitle} ${modelTitle} <br><br/> IMEI: ${this.state.enteredImei}`;

            if(_isEmpty(this.props.customerCase)) {
                this.props.createNewServiceRequestInit(this.props.cacheId, ProcessIncidentParameters);
                this.props.updateJourneyList(this.props.source, deviceDetails);
            } else {
                this._selectedMakeModelCallProcessIncidents(userSelectedMakeModel, ProcessIncidentParameters)
                this.props.updateJourneyList(this.props.source, deviceDetails);
            }
            let selectedDeviceModel = MESSAGE_CONSTANTS.SELECTED_DEVICE + `${brandTitle} ${modelTitle}  IMEI: ${this.state.enteredImei}`;

            this.props.updateTransitionPanel(MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.percentage, MESSAGE_CONSTANTS.STAGE_NAME.OFFER.eligibility.label,'', selectedDeviceModel );
        }
    }

    _selectedMakeModelCallProcessIncidents(userSelectedMakeModel, ProcessIncidentParameters) {
        let { resumeCanceled, caseType, makeAndModelList, cacheId } = this.props;

        if(resumeCanceled && !userSelectedMakeModel) {
            if(caseType === CONSTANTS.MALFUNCTION){
            this.props.updateJourneyList(CONSTANTS.DEVICE_NOT_WORKING, "");
            } else if (caseType === CONSTANTS.SWAP) {
            this.props.updateJourneyList(CONSTANTS.DEVICE_IS_BROKEN, "");
            } else {
            this.props.updateJourneyList(CONSTANTS.CONFIRM_CURRENT_DEVICE, "CANCELED_OLD_SR");
            }
        } else {
            if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
            if (makeAndModelList) {
                this.props.updateJourneyList(CONSTANTS.IMEI_VERFIED, MESSAGE_CONSTANTS.SUB_STAGES.VERIFIED_IMEI);
                if (caseType === CONSTANTS.MALFUNCTION) {
                let Triage = Object.assign({}, {
                    Triage: {
                    TriageAccepted: true,
                    TriageRefusalReason: "Triage refused by customer",
                    Notes: ""
                    }
                });
                _extend(ProcessIncidentParameters.Incident, Triage);
                }
                this.props.processIncidentInit(ProcessIncidentParameters, cacheId);
            } else {
                this.props.updateJourneyList(CONSTANTS.SUBMIT_IMEI_NUMBER, null);
            }
            this.props.setProgressBarValue(100);
            } else {
                this.props.processIncidentInit(ProcessIncidentParameters, cacheId)
            }
        }
    }

    _renderBrandMenuOptions(option, i) {
        return (
          <Dropdown.Item
            active={this.state.brandTitle === option.Make}
            onClick={() => this.onBrandSelection(option.Make)}
          >
            {option.Make}
          </Dropdown.Item>
        );
      }

    _renderModelMenuOptions(option, i) {
        let formattedPopularName = getFormattedPopularName(option.AssetCatalogName);
        let deviceName = "";

        // changed to remove unwanted strings
        // if (formattedPopularName.modelName && formattedPopularName.color) {
        if (formattedPopularName.modelName) {
            deviceName = formattedPopularName.modelName + " " + formattedPopularName.specs + " " + formattedPopularName.color
        } else {
            deviceName = option.AssetCatalogName
        }

        return (
            <Dropdown.Item
                active={this.state.modelTitle === deviceName}
                onClick={() =>
                this.onModelSelection(deviceName, option.CatalogId)
                }
            >
              {deviceName}
            </Dropdown.Item>
        )
    }

    onBrandSelection(eventKey) {
        this.props.getMakeModelList(eventKey);
        this.setState({
            brandTitle: eventKey,
            modelDisable:false,
            modelTitle: null
        });
    }

    onModelSelection(eventKey, catalogId) {
        this.setState({
            modelTitle: eventKey,
            catalogId: catalogId,
            disable: false
        });
    }


    setIMEI(value) {
        value = value.replace(/[^0-9]/g, '');
        // value = value.replace(/[^\d\-]|^[\-]*$/g, '');
        if (value === "") {
            this.setState({
                enteredImei: ""
            });
        } else {
            this.setState({
                enteredImei: value,
            });
        }
        if (this.state.enteredImei.length === 14) {
            this.setState({
                displayError: false,
                inputClassName: "ais_deliver-address-input ais_mis"

            });
        }
    }

    render() {
        const { assetDetails, makeAndModelList } = this.props;
        return (
            <Fragment>
                <div className="ais_current-form-device-div ais_proceed">
                    <div className="ais_header-txt-div">
                        <label className="ais_current-mobile-device-label ais_header-width">{this.props.data.HEADER}</label>
                        <div>  <img alt="feedback-icon" className="ais_confirm-delivery-icn" src={feedbackFeeIcon} /> </div>
                        <div>
                            <DropdownButton
                                title={this.state.brandTitle}
                                id={`dropdown-size-medium`}
                                className={`device-mismatch-dropdown`}
                                disabled={this.state.makeDisable}
                            >
                                {assetDetails && assetDetails.map((e, i) => {
                                        return this._renderBrandMenuOptions(e, i)
                                        }
                                    )
                                }
                            </DropdownButton>
                        </div>
                        <div className="ais_margintop">
                            <DropdownButton
                                title={this.state.modelTitle}
                                id={`dropdown-size-medium`}
                                className={`device-mismatch-dropdown`}
                                disabled={this.state.modelDisable}
                            >

                                {makeAndModelList && makeAndModelList.map((e, i) => {
                                        return this._renderModelMenuOptions(e, i);
                                        }
                                    )
                                }
                            </DropdownButton>
                        </div>
                        {this.state.displayError && <div className="ais_error-div ais_imei"><label>{MESSAGE_CONSTANTS.ERROR_INCORRECT_IMEI}</label></div>}
                        <input disabled={this.state.textboxDisable} type="text" className={this.state.inputClassName} value={this.state.enteredImei} pattern="[0-9]{15}" maxLength="15" minLength="15" onChange={(e) => this.setIMEI(e.target.value)} placeholder={MESSAGE_CONSTANTS.ALL_DIGIT_IMEI_PLACEHOLDER} />
                        <div className="ais_outer-align-button">
                            <button disabled={this.state.disable} className={"ais_device-select-button ais_proceed " + this.state.highlightedClass} onClick={(e) => this.handleClick(e)} >{this.props.data.BUTTON}</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getMakeModelList,
        createNewServiceRequestInit,
        processIncidentInit,
        setProgressBarValue,
        saveSelectedDeviceModel,
        savePayload,
        updateTransitionPanel
    }, dispatch)
};


const mapStateToProps = state => {
  const {selectedDefectOption, clientData, customerCase,resumeCanceled, caseType, selectedBrand, makeAndModelList, assetDetails} = state.customerData;
    return {
        cacheId: clientData.CacheId,
        selectedDefectOption,
        customerCase,
        resumeCanceled,
        caseType,
        selectedBrand,
        makeAndModelList,
        assetDetails
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CurrentDeviceOptions);

