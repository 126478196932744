import React, { Component, Fragment } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import CONSTANTS from "../../utils/Constants";
import { SAVE_GENERAL_ENQUIRY_DETAILS } from '../../actions/Dashboard/actionTypes'
import { savePayload, updateTransitionPanel } from '../../actions/Dashboard/doFindAgreements';
import MESSAGE_CONSTANTS from "../../config/journey-messages";

class GeneralEnquiry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            highlightedClass: "",
            disable: true,
            dropdownOpen: false,
            textboxDisable: false,
            displayError: false,
            enquiryTypeDisable: false,
            enquirySubTypeDisable: true,
            enquiryTypeHeader: MESSAGE_CONSTANTS.SELECT_ENQUIRY_TYPE,
            enquirySubTypeHeader: MESSAGE_CONSTANTS.SELECT_ENQUIRY_SUBTYPE,
            generalEnquiryOptions: this.props.generalEnquiryOptions || {},
            enquirySubTypes: [],
            enquiryReason: '',
            inputClassName: "ais_deliver-address-input ais_mis",
            containerClassName: "ais_header-txt-div"
        };
        this.onEnquiryTypeSelection = this.onEnquiryTypeSelection.bind(this);
        this.onEnquirySubtypeSelction = this.onEnquirySubtypeSelction.bind(this);
    }

    dropdownSelected = () => {
        if (this.state.dropdownOpen) {
            this.setState({
                containerClassName: "ais_header-txt-div"
            })
        } else {
            this.setState({
                containerClassName: "ais_header-txt-div general_enquiry"
            })
        }
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleInputChange = (value) => {
        this.setState({
            enquiryReason: value,
            displayError: false,
            disable: !value
        });
    }

    handleSubmitEnquiryClick = () => {
        if (!this.state.enquiryReason) {
            this.setState({
                displayError: true
            })
            return false;
        }
        this.setState({
            highlightedClass: "ais_device-select-button-highlighted",
            disable: true,
            textboxDisable: true,
            enquirySubTypeDisable: true,
            enquiryTypeDisable: true,
            displayError: false,
            inputClassName: "ais_deliver-address-input ais_mis",
        });
        this.props.updateJourneyList(CONSTANTS.SUBMIT_CONTACT_FOR_ENQUIRY_CALLBACK, MESSAGE_CONSTANTS.ENQUIRY_DETAILS_SAVED);
        this.props.savePayload(SAVE_GENERAL_ENQUIRY_DETAILS, {
            enquiryReason: this.state.enquiryReason,
            enquiryType: this.state.enquiryTypeHeader,
            enquirySubtype: this.state.enquirySubTypeHeader,
        })
        this.props.updateTransitionPanel(MESSAGE_CONSTANTS.STAGE_NAME.GENERAL_ENQUIRY.percentage, MESSAGE_CONSTANTS.STAGE_NAME.GENERAL_ENQUIRY.label, MESSAGE_CONSTANTS.GENERAL_ENQUIRY.ENQUIRY_TYPE_HEADER, this.state.enquiryTypeHeader);
        this.props.updateTransitionPanel(MESSAGE_CONSTANTS.STAGE_NAME.GENERAL_ENQUIRY.percentage, MESSAGE_CONSTANTS.STAGE_NAME.GENERAL_ENQUIRY.label, MESSAGE_CONSTANTS.GENERAL_ENQUIRY.ENQUIRY_SUBTYPE_HEADER, this.state.enquirySubTypeHeader);
        // this.props.submitEnquiryDetails(MESSAGE_CONSTANTS.CREATE_GENERAL_ENQUIRY, CONSTANTS.GENERAL_ENQUIRY, this.state.enquiryReason, `${this.state.enquiryTypeHeader} - ${this.state.enquirySubTypeHeader}`)
    }

    _renderEnquiryTypeOptions(option, i) {
        return (
            <Dropdown.Item key={i} active={this.state.enquiryTypeHeader === option} onClick={() => this.onEnquiryTypeSelection(option)} >{option} </Dropdown.Item>
        )
    }

    // _renderEnquirySubtypeOptions(option, i) {
    //     return (
    //         <Dropdown.Item key={i} onSelect={(eventKey) => this.onEnquirySubtypeSelction(option)} >{option.InquirySubTypeName} </Dropdown.Item>
    //     )
    // }

    onEnquiryTypeSelection(option) {
        this.setState({
            enquirySubTypes: option,
            enquiryTypeHeader: option,
            enquirySubTypeHeader: option
            // enquirySubTypeDisable: option && !option.InquirySubTypes.InquirySubType.length
        });
    }

    onEnquirySubtypeSelction(option) {
        this.setState({
            enquirySubTypeHeader: option.InquirySubTypeName,
            disable: false
        });
    }


    render() {
        const { data } = this.props;
        const { generalEnquiryOptions, enquirySubTypes, enquiryTypeHeader, enquirySubTypeHeader, enquirySubTypeDisable, enquiryReason } = this.state;
        return (
            <Fragment>
                <div className="ais_current-form-device-div ais_proceed">
                    <div className={this.state.containerClassName}>
                        <label className="ais_current-mobile-device-label">{data.HEADER}</label>
                        <div>  {/* <img alt="feedback-icon" className="ais_confirm-delivery-icn" src={feedbackFeeIcon} /> */} </div>
                        <div className="text-left">
                            <label>{data.ENQUIRY_TYPE_HEADER}</label>
                        </div>
                        <div>
                            <DropdownButton
                                title={enquiryTypeHeader}
                                id={`dropdown-size-large`}
                                disabled={this.state.enquiryTypeDisable}
                                onToggle={this.dropdownSelected}
                            >
                                {MESSAGE_CONSTANTS.GENERAL_ENQUIRY_OPTIONS.map((option, i) => {
                                    return this._renderEnquiryTypeOptions(option, i)
                                }
                                )}
                            </DropdownButton>
                        </div>
                        {/*<div className="text-left ais_margintop">
                            <label>{data.ENQUIRY_SUBTYPE_HEADER}</label>
                        </div>*/}
                        {/*<div>
                            <DropdownButton
                                bsStyle="default"
                                title={enquirySubTypeHeader}
                                id={`dropdown-size-medium`}
                                disabled={enquirySubTypeDisable}
                            >
                                {enquirySubTypes && enquirySubTypes.map((option, i) => {
                                    return this._renderEnquirySubtypeOptions(option, i);
                                }
                                )}
                            </DropdownButton>
                        </div>*/}
                        <textarea disabled={this.state.textboxDisable} type="text" className={this.state.inputClassName} value={enquiryReason} minLength="15" onChange={(e) => this.handleInputChange(e.target.value)} placeholder={data.PLACEHOLDER} />
                        {this.state.displayError && <div className="ais_error-div ais_imei"><label>This field is required.</label></div>}
                        <div className="ais_outer-align-button">
                            <button disabled={this.state.disable} className={"ais_device-select-button ais_proceed " + this.state.highlightedClass} onClick={this.handleSubmitEnquiryClick} >{data.BUTTON}</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        savePayload,
        updateTransitionPanel
    }, dispatch)
};


const mapStateToProps = state => {
    let { generalEnquiryOptions, selectedEnquiryOption } = state.customerData;
    return {
        generalEnquiryOptions,
        selectedEnquiryOption
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GeneralEnquiry);

