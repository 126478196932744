
import React from 'react';
import TransitionBar from './TransitionBar';

const TransitionPanel = (props) => {
    return(
        <div id="appTransitions" className={`${props.hideTransitionPanelFlag ? 'display-none': ' '}  col-md-3 ais_transition-panel`}>
                <TransitionBar {...props.transition}/>
        </div>
    )
}

export default TransitionPanel;