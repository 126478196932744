import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { savePayload, submitEnquiryDetails, endInteractionInit } from '../../actions/Dashboard/doFindAgreements';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { SAVE_ENQUIRY_REASON } from '../../actions/Dashboard/actionTypes';

import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import { stat } from "fs";
import CONSTANTS from "../../utils/Constants";

class CancelDeliveryEnquiry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            highlightedClass: "",
            disable: false,
            reasonText: null,
            disableInput: false,
            maxChar: 100,
        };

        this.handleClick = this.handleClick.bind(this);
        this.setReason = this.setReason.bind(this);
    }

    handleClick(event) {
        try {
            event.target.disabled = true;
        } catch(err) {
            console.log("error in CancelDeliveryEnquiry button click", err)
        }
        
        MIXPANEL.track(EVENTLIST.CANCEL_DELIVERY);
        this.setState({
            highlightedClass: "ais_device-select-button-highlighted",
            disable: true,
            disableInput: true
        });
        const { selectedEnquiryOption, WbkoCancellationReason, isWbkoSelected } = this.props;
        this.props.savePayload(SAVE_ENQUIRY_REASON, this.state.reasonText);
        let filteredWBKOCancelReason = MESSAGE_CONSTANTS.WBKO_CANCELLATION_REASON.buttons.filter(data => {
            return data.name === WbkoCancellationReason;
        });

        let desc = `${MESSAGE_CONSTANTS.WBKO_INQ_DESC} ${this.state.reasonText}`;

        if (WbkoCancellationReason === 'OTHER' && isWbkoSelected) {
            this.props.submitEnquiryDetails(desc, selectedEnquiryOption, desc, CONSTANTS.ENQUIRY_SUB_REASON.CANCEL_SR);
        } else {
            this.props.submitEnquiryDetails(MESSAGE_CONSTANTS.CANCEL_DELIVERY_ENQUIRY, selectedEnquiryOption, this.state.reasonText, CONSTANTS.ENQUIRY_SUB_REASON.CANCEL_SR);
        }
        // this.props.endInteractionInit(this.props.cacheId);
    }

    setReason(value) {
        this.setState({
            reasonText: value
        })
    }

    render() {
        let { reasonText, maxChar } = this.state;
        let length = reasonText ? reasonText.length : 0;
        const { data, isWbkoSelected } = this.props;
        return (
            <div className="ais_confirm-delivery-icn">
                <div className="ais_current-form-device-div">
                    <div className="ais_current-form-device-header-div">
                        <div className="ais_container-div">
                            <label className="ais_cancel-header">{isWbkoSelected ? MESSAGE_CONSTANTS.WBKO_REASON_FOR_CANCEL_HEADER : data.HEADER}</label>
                        </div>
                        <textarea className={` ${isWbkoSelected ? 'ais_current-form-drop-Message-Input ais_textarea wbkoCancelDeliveryTextArea' : 'ais_current-form-drop-Message-Input ais_textarea'}`}
                            value={this.state.reasonText}
                            disabled={this.state.disableInput}
                            onChange={(e) => this.setReason(e.target.value)}
                            placeholder={isWbkoSelected ? MESSAGE_CONSTANTS.WBKO_REASON_FOR_CANCEL_PLACEHOLDER : MESSAGE_CONSTANTS.REASON_FOR_CANCEL}
                            length={reasonText ? reasonText.length : 0}
                            maxChar={isWbkoSelected ? 100 : ''}
                            maxLength={isWbkoSelected ? 100 : ''}
                        />
                        {isWbkoSelected && maxChar ? <span className="float-right text_area_limit"> {length}/{maxChar}</span> : null}
                        <div className={`ais_outer-align-button ${!this.state.reasonText ? 'disabled' : ''}`}>
                            <button disabled={this.state.disable} className={"ais_device-select-button ais_proceed " + this.state.highlightedClass} onClick={this.handleClick} >{isWbkoSelected ? data.BUTTONS[1] : data.BUTTONS[0]}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        savePayload,
        submitEnquiryDetails,
        endInteractionInit
    }, dispatch)
}

const mapStateToProps = state => {
    const { clientData, selectedEnquiryOption, isWbkoSelected, WbkoCancellationReason } = state.customerData;

    return {
        cacheId: clientData.CacheId,
        selectedEnquiryOption,
        isWbkoSelected,
        WbkoCancellationReason
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelDeliveryEnquiry);

