import React from "react";
import { Mutation } from "react-apollo";
import UpdateChatRequestMutation from "./../../../graphql/mutation/UpdateChatRequest";
import UpdateChatRequest from "./UpdateChatRequest";

const CallUpdateChatRequest = ({ chatRequest }) => (
  <Mutation
    mutation={UpdateChatRequestMutation}
    onCompleted={data => {
      console.log("task id updated to chat request", data);
    }}
  >
    {updateChatRequest => {
      return (
        <UpdateChatRequest
          updateChatRequest={updateChatRequest}
          chatRequest={chatRequest}
        />
      );
    }}
  </Mutation>
);

export default CallUpdateChatRequest;
