import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux';
import { TextBold } from './TextBold';
import Sub2 from "../../images/ais-images/Sub2.png";
import Sub5 from "../../images/ais-images/Sub5.png";
import CONSTANTS from '../../utils/Constants';
import { verifyAgreement } from '../../actions/Dashboard/doFindAgreements';
import { BaseImg, getFormattedUserName, getMdnWithoutUnderscore } from '../../utils/utils';
import { savePayload } from '../../actions/Dashboard/doFindAgreements';
import { SAVE_SHIPPING_ADDDRESS, SAVE_SELECTED_SR_ASSET_ID } from '../../actions/Dashboard/actionTypes';
import MESSAGE_CONSTANTS from "../../config/journey-messages"
import Carousel from 'react-bootstrap/Carousel'

const { SUBSCRIPTION_OPTION } = MESSAGE_CONSTANTS;
class VerifyDeviceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disable: false,
            yesButtonClassName: "ais_align-popup-button",
            noButtonClassName: "ais_align-popup-button",
            deviceName: "",
            deviceSpec: "",
            /**
             * Keeping state locally because when rendering same component again,
             * redux state will affect css for proceed button
             */
            selectedAsset: null,
            proceedBtnenabled: false,
            index: 0,
            isMobile: window.innerWidth < 768
        }
    }
    handleClick = () => {
        this.setState({
            disable: true
        });
        this.props.updateJourneyList(CONSTANTS.SOURCE_USER_INPUT.RESUME_REQUEST_OPTIONS, MESSAGE_CONSTANTS.BACK_TO_MAIN_MENU)
    }
    handlePrceedClick = async (CaseData, address) => {
        let { cacheId } = this.props;

        this.setState({
            proceedBtnenabled: true,
            disable: true
        });
        let AgreementId = CaseData.AgreementId;
        let VerifyAgreementsParameters = {
            SessionId: cacheId,
            AgreementId
        };
        await this.props.verifyAgreement(VerifyAgreementsParameters);
        await this.props.savePayload(SAVE_SELECTED_SR_ASSET_ID, CaseData.AssetID);
        await this.props.savePayload(SAVE_SHIPPING_ADDDRESS, address);
    }
    Slide = ({ CaseData, SrData }) => {
        let { proceedBtnenabled, disable } = this.state;
        let { selectedDefectOption, isWbkoSelected, selectedMakeName, selectedmodelName, AssetsInformation, programName } = this.props;
        const deviceName = SrData && SrData.ReplacedDevice && SrData.ReplacedDevice.AssetCatalog && SrData.ReplacedDevice.AssetCatalog.Description;
        const Model = SrData && SrData.ReplacedDevice && SrData.ReplacedDevice.Make && SrData.ReplacedDevice.Make.Name;
        const Make = SrData && SrData.ReplacedDevice && SrData.ReplacedDevice.AssetCatalog && SrData.ReplacedDevice.Model.Name;
        const showProceedBtn = selectedDefectOption === "SUBSCRIPTION" || selectedDefectOption === CONSTANTS.CHECK_SERVICE_FEE;

        let statuss = ['READY', 'SHIP', 'BORD'];
        let sts = SrData && SrData.ShippingOrder && SrData.ShippingOrder.ShippingStatus;
        let filterstatusexistornot = statuss && statuss.filter(data => data === sts);

        proceedBtnenabled = filterstatusexistornot && filterstatusexistornot.length > 0 ? true : false;

        let address = SrData && SrData.ShippingOrder && SrData.ShippingOrder.Address;
        let status = SrData && SrData.ShippingOrder && SrData.ShippingOrder.ShippingStatus;

        const disableBtnIfNoStatus = !status && 'non-selected-asset';

        const SUBSCRIPTION_OPTIONS_WBKO = [
            {
                icon: Sub2,
                label: SUBSCRIPTION_OPTION.SrNo,
                value: `<span class= "subscriptionItemDesc">${(CaseData && CaseData.CustomerCaseNumber) || 'No Data Available'
                    }</span > `,
            },
            {
                icon: Sub5,
                label: SUBSCRIPTION_OPTION.SrType,
                value: `<span class= "subscriptionItemDesc">${(SrData && SrData.IncidentType) || 'No Data Available'}</span > `, /* `< span class= "subscriptionItemDesc" > ${asset && asset.IncidentType}</span> `, */
            },
            {
                icon: Sub2,
                label: SUBSCRIPTION_OPTION.Status,
                value: '',
                status: (status) || 'No Data Available'
            }
        ];

        return (
            <article className="devicelist-slide item ">
                <div className="secSlider">
                    <div className="flex items-center justify-center pb-4">
                        <div className="deviceIcon"><BaseImg make={Make} model={Model} /></div>
                        <div className="flex flex-col pl-3">
                            <label className="subLabelnewData">{deviceName || 'Device Unknown'}</label>
                            <TextBold className="text-sm subLabelnewData">{getMdnWithoutUnderscore(SrData.MobileDeviceNumber)}</TextBold>
                            <p className="subLabelnew">{programName}</p>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div className="subscriptionLabel subLabelnewData">{SUBSCRIPTION_OPTION.TITLE} </div>
                        <div className="subscriptionItemsWrapper">
                            {isWbkoSelected && SUBSCRIPTION_OPTIONS_WBKO && SUBSCRIPTION_OPTIONS_WBKO.map((e, i) =>
                                <div className="flex m-15 subscriptionItems" key={e.label}>
                                    <div className="imageDist"><img src={e && e.icon} /></div>
                                    <div className="text-initial">
                                        <div className="subLabelnew">{e && e.label}</div>
                                        <div className="subLabelnewData subscriptionItemDesc"><span dangerouslySetInnerHTML={{ __html: e && e.value }}></span></div>
                                        {e.status && <div className=" statusBtn btnSub btn-darkgreenSub ">{e.status}</div>}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {!showProceedBtn && <div className={`proceed-btn ${disable ? 'disabled' : ''} `}>{/* ${proceedBtnenabled ? '' : 'disabled'} */}
                        <button disabled={!proceedBtnenabled} className={`${this.state.yesButtonClassName} ${disableBtnIfNoStatus}`} onClick={(e) => this.handlePrceedClick(CaseData, address)} >{SUBSCRIPTION_OPTION.Proceed}</button>
                    </div>}
                </div>
            </article >

        )
    };
    render() {
        const { Agreement, completedSr } = this.props;

        let { selectedDefectOption } = this.props;
        const flag = selectedDefectOption === "SUBSCRIPTION" || selectedDefectOption === CONSTANTS.CHECK_SERVICE_FEE;
        const name = getFormattedUserName(Agreement[0]?.EnrolledName || 'User');
        const disableClass = this.state.disable && 'disable-link';

        const items = completedSr && completedSr.map((CaseData, i) =>
            CaseData && CaseData.ServiceRequests && CaseData.ServiceRequests.map((SrData, j) =>
                <Carousel.Item bsPrefix={this.state.isMobile ? 'carousel-item' : 'non-carousel-item'}>
                    <this.Slide
                        CaseData={CaseData}
                        SrData={SrData}
                        slideIndex={i + 1}
                    >
                    </this.Slide>
                </Carousel.Item>
            )
        )
        return (
            <section className="max-w-5xl mx-auto disable">
                <p className="section-title">{SUBSCRIPTION_OPTION.SectionTitle}</p>
                <h3 className="enrolled-devices">{SUBSCRIPTION_OPTION.ServiceRequests}&nbsp;<span className="font-AvenirLTStd-Book font-normal">({completedSr.length})</span></h3>

                <div className="container horizontal-slider device-height-sr" >
                    {this.state.isMobile ?
                        <>
                            <Carousel activeIndex={this.state.index} onSelect={(selectedIndex) => this.setState({ index: selectedIndex })} interval={null}>{items}</Carousel>
                            <div className="subLabelnewData swipe-devices">{SUBSCRIPTION_OPTION.SwipeToSeeAllDevices}</div>
                        </>
                        :
                        <div className="non-carousel-wrapper">{items}</div>}
                </div>

                {flag && <div className={`subBackToMainMenu text - center ${this.state.disable && disableClass} `}>
                    <span onClick={this.handleClick} >{/* <LeftArrowIcon /> */}
                        {MESSAGE_CONSTANTS.BACK_TO_MAIN_MENU}
                    </span>
                </div>}
            </section >
        )
    }
}
const mapStateToProps = ({ customerData }) => {
    return {
        Agreement: customerData.Agreement,
        selectedDefectOption: customerData.selectedDefectOption,
        cacheId: customerData.clientData.CacheId,
        isWbkoSelected: customerData.isWbkoSelected,
        programName: customerData.programName,
        completedSr: customerData.completedSr
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        verifyAgreement,
        savePayload
    }, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyDeviceList)
