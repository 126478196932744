import React from 'react'
import ais_mobile_care_logo_3x from '../../images/ais-images/ais-mobile-care-logo@3x.png';
import './TermsAndConditonsNew.css'

export default function TermConditionsSwapUnlimited() {
  document.title = "Terms and Conditions - AIS Mobile Care Swap Unlimited";

  return (
    <div className="term-condition-page term-condition-new-page">
      <div className=" header">
        {/*<div class="headerText">Terms & Conditions</div>*/}
        {/*<div className="headerTime">&nbsp;</div>*/}
      </div>
      <div style={{ display: "flex", flexDirection: "column", height: "100vh", pading: "0" }}>
        <div className="topSpace" style={{ paddingBottom: "1rem" }}>
          <div className="">
            <div className="text">
              <img src={ais_mobile_care_logo_3x} alt className="mobileSwop-logo" />
            </div> <br />
          </div>
          <div className="headerTopSpace">
            <div className="text">
              <center>
                <div className="row">
                  <div className="acc-header" style={{ padding: "0 20px" }}>TERMS &amp; CONDITIONS FOR AIS MOBILE CARE SWAP UNLIMITED</div>
                </div>
              </center>
            </div>
          </div>
        </div>

        <div style={{ maxHeight: "100vh", overflowY: "auto" }}>
          <ol style={{ margin: "0 auto", padding: "0", maxWidth: "85%" }}>
            <li>
              GENERAL
              <ol>
                <li>These terms and conditions of services shall apply to and between Advanced Wireless Network Company Limited ("Provider") and a User of the AIS Mobile Care Swap Unlimited Services.</li>
                <li>By applying for the AIS Mobile Care Swap Unlimited Services, the User agrees to be bound by and act in full compliance herewith. Such enrolment is complete when the User receives SMS confirming his or her enrolment.</li>
                <li>Subject to the terms contained herein, the Provider may decline to provide all or part of the AIS Mobile Care Swap Unlimited Services to a User.</li>
                <li>The Provider has appointed Asurion (Thailand) Limited (the "Company") as its service provider to provide the Services (as defined in clause 2.1) and collect the applicable Swap Fee (such term as specified in clause 12.5). The User agrees that where the Provider has rights or obligations in relation to the Services or under these terms and conditions, the Company, as the Provider's service provider, may exercise these rights or discharge these obligations on the Provider's behalf.  </li>
              </ol>
            </li><li>
              DEFINITIONS
              <ol>
                <li><strong>Services</strong> means the AIS Mobile Care Swap Unlimited Services comprised of the Swap Program as defined in clause (as defined in clause 2.39).</li>
                <li><strong>Provider</strong> means Advanced Wireless Network Company Limited.</li>
                <li><strong>Company</strong> means Asurion (Thailand) Limited. </li>
                <li><strong>User</strong> means an applicant for enrolment for the Services and received an SMS confirming his or her enrolment.</li>
                <li><strong>Courier</strong> means a person who: (i) transports Like Mobile Devices; (ii) accepts Registered Devices; (iii) accepts payments of the applicable Swap Fees, or other Fees.</li>
                <li><strong>Phone Number</strong> means mobile phone number from the Provider's network, used by the User in connection with the Registered Device for his or her application for enrolment for the AIS Mobile Care Swap Unlimited Services.</li>
                <li><strong>Registered Device</strong> means a Device bought by the User from AIS Shop or Telewiz Shop or the Provider or other sources being of the brand and model specified by the Provider and registered for enrolment for the Services in accordance with the registering method as designated by the Provider, the IMEI of which shall be recorded by the AIS Mobile Care Swap Unlimited Providers as data for provision of the Services to the User, and includes a Device acquired by the User under the manufacturer's warranty for a Registered Device, subject to notice thereof being made to the AIS Mobile Care Swap Unlimited Providers by the User in accordance with the AIS Mobile Care Swap Unlimited Providers' requirements.</li>
                <li><strong>IMEI</strong> (International Mobile Station Equipment Identity) means the unique number assigned to each Device. If a Device does not have an IMEI, then all references to IMEI in these Terms and Conditions will be read as a reference to the serial number for that Device.</li>
                <li><strong>Like Mobile Device</strong> means a Device, new or otherwise, obtained by a Swap, and it shall be deemed the new Registered Device after being delivered to the User, but as compared to the original Registered Device, it: (i) is of similar kind, quality and functionality; (ii) may be new or refurbished (containing OEM's or non-OEM's parts); (iii) has same or greater memory; (iv) may be a different make, model or color; (v) has a different IMEI; (vi) is not provided in the OEM's packaging; and (vii) does not include any Device Accessories.</li>
                <li><strong>Device Accessories</strong> means any equipment or anything that is either provided by the OEM in the box with a Device or sold separately to be used with a Device. It includes: (i) batteries; (ii) SIM cards; (iii) memory cards; (iv) chargers; (v) ear buds; (vi) cases; (vii) boxes; (viii) cables; (ix) styli; and (x) mounts.</li>
                <li><strong>Swap</strong> means the ability to request an Like Mobile Device in exchange for the Registered Device if the Registered Device is in the User's possession. </li>
                <li><strong>Registered Device Price as of Buying Date</strong> means the full recommended marketing retail purchase price ("RRP") of the Registered Device (including VAT) on the Buying Date of the Registered Device, as determined and notified by the Provider.</li>
                <li><strong>Fee</strong> means the: (1) AIS Mobile Care Swap Unlimited Package Fee, (2) Swap Fee or  (3) Administration Fee (4) any other amount that might be charged to the User on account of use of any Service by the User.</li>
                <li><strong>Contact Center</strong> means (i) the call center dedicated to the Services as available at 098 098 1175 everyday (no day off), from 08:00 a.m. to 10:00 p.m and (ii) the Online Portal</li>
                <li><strong>Online Portal</strong> means a website offered for your use, through which you can access certain Services, information and assistance.</li>
                <li><strong>Greater Bangkok</strong> means the areas of Bangkok, Nonthaburi, Pathum Thani, Samut Prakan and Samut Sakhon Provinces.</li>
                <li><strong>Business Day</strong> means Monday to Saturday (excluding public holidays).</li>
                <li><strong>Website</strong> means the website www.ais.co.th/mobilecare showing the details of the Services, AIS Mobile Care Swap Unlimited Package Fees, Swap Fees, the Terms and Conditions for AIS Mobile Care Swap Unlimited and other details, request certain Service-related assistances, etc.</li>
                <li><strong>Inspection</strong> means an inspection of a Device carried out in the AIS Shop which involves testing Device to confirm that the Device is in good condition and operating normally to the satisfaction of the Provider. A device passes an Inspection where the Provider is satisfied that the device is in good condition and operating normally.</li>
                <li><strong>Freeze Period</strong> means 30 days period from the enrolment date the User shall not be able to make a Service Request for Swap including any other period of time that the AIS Mobile Care Swap Unlimited Providers shall designate and inform the User in advance.</li>
                <li><strong>National ID</strong> means a valid Thai national identity card.</li>
                <li><strong>Prohibited ID</strong> means a National ID or a Foreigner ID of the User for which the AIS Mobile Care Swap Unlimited Providers have terminated the Services or decided to deny enrolment in the Services.</li>
                <li><strong>OEM</strong> means original equipment manufacturer.</li>
                <li><strong>Software Modification</strong> means modification made to a Registered Device's operating system not undertaken or authorized by the OEM and includes software modification known as ‘jail-breaking' and ‘rooting'.</li>
                <li><strong>Hardware Modification</strong> means any repair and/or modification made to a Registered Device's hardware not undertaken or authorized by the OEM.</li>
                <li><strong>Modification means</strong> Software Modification or Hardware Modification or both.</li>
                <li><strong>Administration Fee</strong> means a service charge additionally collected from the User under the Swap Program equal to 25 percentage of the Registered Device Price as of Buying Date. . The Administration Fee shall be charged where the User has already paid the Swap Fee, then the Company finds later that the User is not entitled to use the Swap Service subject to the Terms and Conditions and the User shall pay the Administration Fee accordingly.</li>
                <li><strong>Acceptance Date</strong> means the day upon which the Service Request  is accepted.	</li>
                <li><strong>AIS Shop</strong> means a customer service center operated by the Provider, or its affiliate or the Provider's authorizations.</li>
                <li><strong>Buying Date</strong> means the date of purchase of the Registered Device.</li>
                <li><strong>Device</strong> means a mobile phone including a mobile wireless device that: (i) has a display screen; (ii) supports one or more wireless network connectivity options; and (iii) that is operated using voice, touch or a miniature keyboard. It does not include any Device Accessories.</li>
                <li><strong>Foreigner ID</strong> means a User's passport, Work Permit and Certificate of Residence of Foreigner (if any).</li>
                <li><strong>AIS Mobile Care Swap Unlimited Providers</strong> means the Provider, the Company, and the Company's affiliates and subcontractors. </li>
                <li><strong>AIS Mobile Care Swap Unlimited Package Fee</strong> has the meaning given to it in clause 12.1.</li>
                <li><strong>Package</strong> means a subscription to the AIS Mobile Care Swap Unlimited Service on either: (i) a monthly basis ("Monthly Package"); or (ii) a fixed 12 months basis ("Fixed Term Package"). </li>
                <li><strong>Program</strong> has the meaning given to it in clause 5.</li>
                <li><strong>VAT</strong> means value added tax charged at the prevailing rate at the time of the transaction.</li>
                <li><strong>Service Request</strong> means a request for the Services made in accordance with these  terms and conditions.</li>
                <li><strong>Swap Program</strong> means the program as described in clause 5.</li>
                <li><strong>Telewiz Shop</strong> means a shop which is authorized by the Provider or its affiliates to distribute goods and services.</li>
                <li><strong>Tier</strong> means the tier outlined for the Registered Device as advised at the time of application for enrolment for the AIS Mobile Care Swap Unlimited Services.</li>
                <li><strong>Acceptance Time</strong> has the meaning given to it in clause 13.213.3.</li>
              </ol>
            </li><li>
              PROVISION OF SERVICES
              <ol>
                <li>AIS Mobile Care Swap Unlimited is the provision of the Services to a User upon making a Service Request for a Swap under the Swap Program in accordance with these Terms and Conditions.</li>
              </ol>
            </li><li>
              ELIGIBILITY TO APPLY

              <ol>
                <li>To be eligible to apply for the Services, the User must:
                  <ol>
                    <li>be a subscriber of the Provider, registering under the name of or represented by a natural person only and not be in default of any mobile service payment obligations to the Provider.</li>
                    <li>buy a Device (of the brand and model as designated by the Provider only) from AIS Shops, Telewiz Shop, or other shops and distribution channels as designated from time to time by the Provider and use such Device with the Phone Number.</li>
                    <li>not have been previously rejected or terminated from the Services or service similar to the Services.</li>
                    <li>be domiciled in Thailand and holding either:
                      <p>(a)	a National ID; or </p>
                      <p>(b)	in the case the User is a foreigner, Foreigner ID and the User must be permitted to legally stay in Thailand, which is not a Prohibited ID.</p>
                    </li>
                  </ol>
                </li>

                <li>Open Enrolments  is a promotional campaign based offering with varying terms and conditions and notified by the Provider from time to time. Unless otherwise specified in each promotional campaign terms and conditions:
                  <ol>
                    <li>if a Device is enrolled without an Inspection, the User will be subject to a Freeze Period before the User may make a Service Request.</li>
                    <li>only Packages or Devices as indicated in specific campaigns are eligible for Open Enrolment.</li>
                    <li>the Device intended to be registered for the Service shall be purchased:
                      <p>(a)	from AIS Shop or Telewiz Shop or other shops and distribution channels as specified by the Provider in the relevant campaign; and </p>
                      <p>(b)	in the fixed time period as specified by the Provider in the relevant campaign and proof of purchase may be required to be provided to the Provider at the time of the enrolment.</p>
                    </li>
                  </ol>
                </li>
                <li>The following additional conditions shall apply if a User wishes to enroll post purchase (a "Post Purchase Enrolment") application:
                  <ol>
                    <li>the application for enrolment must be made: (i) at an AIS Shop or via the AIS' call center or other sales channel allowed by the Provider; (ii) within 30 days of the Device purchase date from AIS Shops, Telewiz Shop or other sales channel allowed by the Provider e.g. via the Website; and (iii) where required by the Provider, with proof of purchase of the Device. </li>
                    <li>if a Device is enrolled without an Inspection, the User will be subject to a Freeze Period before the User may make a Service Request. </li>
                  </ol>
                </li>
              </ol>

            </li><li>
              HOW TO ENROLL
              <p>A User may enrol in the AIS Mobile Care Swap Unlimited Service being the program providing the User with ability to request a Swap (the "Program") </p>
              <ol>
                <li>at all AIS Shops or Telewiz Shop unless otherwise indicated; or,
                  <li>by  pressing *567#  and call from the registered Phone Number on the day of buying the Device or within other specified period;  </li>
                  subject to Section 11, (Limits), of these Terms and Conditions.</li>
              </ol>
            </li><li>
              START DATE
              <p>The User will be enrolled for AIS Mobile Care Swap Unlimited from the date that the User receives SMS confirming enrolment.</p>
            </li><li>
              TERMS AND USE OF SERVICES
              <ol>
                <li>One User is eligible to enroll for the Services for up to 3 Registered Devices per Phone Number. A Registered Device may be enrolled for a maximum period of 48 continuous months or until the User inform of his or her intention to terminate the Service for Monthly Package whichever the earlier. </li>
                <li>Expiry of Fixed Term Package: Users who subscribe to a Fixed Term Package will automatically be renewed to a Monthly Package, unless the Users notify the AIS Mobile Care Swap Unlimited Providers that the Users do not desire to continue subscribing for the Services. Users who are automatically renewed to a Monthly Package this way will not be charged the AIS Mobile Care Swap Unlimited Package Fee for their first month of subscription.  </li>
                <li>User enrolled for a Fixed Term Package may change the Package within 30 days prior to the expiration of Service term to a Monthly Package. However, the balance of the subscription period for the original Fixed Term Package shall be immediately forfeited.</li>
                <ol>
                  <li>A Monthly Package cannot be changed to the Fixed Term Package.</li>
                  <li>If the User is post-paid user, the new Package will be effective upon the next bill. In the event the User is pre-paid user, the new Package will be in effect immediately.</li>
                </ol>
                <li>The User acknowledges that the User has to delete or backup all data from the Registered Device before making a Service Request. The AIS Mobile Care Swap Unlimited Providers are not responsible for any data which the User leaves on the Registered Device nor for transfer of any data or information between the Registered Device and the AIS Mobile Care Swap Unlimited Device. Such data left on the Registered Device or transfer of any data or information is done entirely at the User's risk. In the event there is any inconvenience, delay, loss, misappropriation of or damage to any data or information, the User agrees not to hold the AIS Mobile Care Swap Unlimited Providers responsible or liable for any such loss, delay or damage to the User.</li>
                <li>The User acknowledges that the User is responsible for the User's own Device passwords (including the User's AppleID). The AIS Mobile Care Swap Unlimited Providers are not responsible if the User loses or does not have access to any required passwords. </li>
                <li>The User agrees not to transfer the title (ownership) or possession/control of the Registered Device during the Service term. In case the User violates this condition, it shall be deemed that the User has illegal intention to use the Service. In the event of a Service Request it is a condition that the title to such original Registered Device will transfer to the Provider as at the time the Service Request is accepted. In addition, for a Service Request for a Swap, the Registered Device must be returned to the Courier. The Provider reserves the right to all civil and criminal claims in order to protect its property interests.</li>
                <li>If the Company reasonably believes that the User is using the Service in a manner which is: (i) fraudulent, illegal or related to any criminal activity; or (ii) intended to make a commercial gain, the Company reserves the right to reject to provide the Services to the User. </li>
              </ol>
            </li><li>
              SERVICE REQUEST
              <ol>
                <li>The User may make a Service Request  for a Swap via the Contact Center.</li>
                <li>The User agrees to provide the following information for verification and confirmation of a User: (i) Phone Number; (ii) Registered Device IMEI; (iii) first-last name; (iv) address; (v) Copy of National ID or the Foreigner's ID, as applicable, as proof of identity issued by competent authority; and (vi) any other information, including to arrange for a service request confirmation form, as requested by the Company. The Company reserves the right to reject to provide the Services if such User provides incorrect or incomplete information.</li>
                <li>In the case the User is a foreigner, such User must be in Thailand at the time of making a Service Request.</li>
                <li>When the User informs of its intention to make a Service Request, the IMEI of the original Registered Device may be restricted.</li>
                <li>While making a Service Request, the Registered Device cannot be the subject of another Service Request.</li>
                <li>The User shall have an active Phone Number (which is not subject to suspension) associated with the enrolled Phone Number on the Provider's network with no outstanding AIS Mobile Care Swap Unlimited Package Fee, mobile service bill or any payment with the Provider. The Registered Device must have been used in association with the Phone Number.</li>
                <li>The Company may ask the User about the condition of the Registered Device for informational purposes to better facilitate the Services.</li>
                <li>At the time of the Service Request for a Swap under the Swap Program which will be for a Like Mobile Device, the Company will indicate to the User the Device it will provide which will be the Like Mobile Device.</li>
                <li>The AIS Mobile Care Swap Unlimited Providers will be deemed to have discharged their obligation to provide an Like Mobile Device to the User as long as the Company offers the User a Device to be provided as an Like Mobile Device in accordance with these Terms and Conditions. </li>
                <li>In the event that the User does not wish to accept the Device offered, the Company in its sole discretion, may offer to the User the option to wait for up to 30 days for a Device of identical specifications as the Registered Device to become available ("Backorder"). If the User agrees to wait for a Backorder, the delivery timeframes specified in clause 13 do not apply until the Backorder is resolved in accordance with clause 8.11.1 below. </li>
                <li>The Company will contact the User before or upon the expiry of the Backorder to inform the User as to the availability of a Device to be provided as an Like Mobile Device. Upon being contacted, the User must:</li>
                <ol>
                  <li>accept the Device to be provided as a Like Mobile Device - The time when the User accepts such Like Mobile Device is the Acceptance Time for the purposes of clause 13 and such Device will become the Registered Device; or</li>
                  <li>decline the Device to be provided as a Like Mobile Device - the Service Request will be cancelled by the Company and any Swap Fee previously paid will be refunded to the User.</li>
                </ol>
                <li>If the Company offers and the User accepts a Device that does not have the same specifications as the Registered Device to be provided as a Like Mobile Device, the Company will provide subsequent Like Mobile Devices based on the specifications of the User's original Registered Device and the date upon which the User accepts the Device so offered will be the ‘Specification Date'.</li>
                <li>For a Service Request, the User shall receive only the Like Mobile Device and no Device Accessories. In case the battery of the Registered Device cannot be removed from its body, the User shall receive the Like Mobile Device including the battery.</li>
                <li>In case the User temporarily suspends the Phone Number (but for a period not exceeding two successive months) for whatever reason other than a lost SIM card, the User will be able to make a Service Request after 30 days from the date when temporary suspension of the Phone Number is reactivated. If the suspension exceeds 2 months the Services will be terminated by the Provider.</li>
              </ol>
            </li><li>
              TERMS FOR A SWAP
              <ol>
                <li>The User's ability to make a Service Request for a Swap requires the User to be compliant with these Terms and Conditions.</li>
                <li>The User is not required to prove the Registered Device is defective or damaged.</li>
                <li>If the Registered Device does not display any or the IMEI matching the Registered Device:
                  under the Swap Program, the User will not be able to request a Swap.</li>
                <li>The User must turn off any personal lock security feature (e.g. ‘Find My iPhone') on the Registered Device before returning such Registered Device to the Courier. </li>
                <li>If the User making a Service Request for a Swap ("Present Request") does not turn off the personal lock security feature the Company may: </li>
                <ol>
                  <li>cancel the Present Request; or </li>
                  <li>for the Swap Program, the Company may terminate the Services with respect to the relevant Registered Device. </li>
                </ol>
                <li>The User must deliver the Registered Device with the IMEI matching the Registered Device to the Courier and agrees that the title to the original Registered Device as well as all rights and benefits, if any, such as a right under the warranty of the OEM, shall be transferred to the Provider as from the Acceptance Date. The User agrees that the delivery of the Like Mobile Device is sufficient consideration for the transfer of title (ownership) and other rights in the original Registered Device to the Provider and (ii) the Provider, as the owner of the original Registered Device (or the Company as its service provider) can take any action consistent with the ownership of the original Registered Device including disposing of that device outside of Thailand. </li>
                <li>In case the Company (i) receives a Device which cannot display an IMEI or (ii) finds later that the User has returned a Device other than the Registered Device to the Courier, the User must return the Registered Device to the Company within seven days of the date of User's receipt of the Like Mobile Device from Courier. If the User fails to do so within such period:</li>
                <ol>
                  <li>if under the Swap Program, the Company may terminate the Services with respect to the relevant Registered Device.</li>
                </ol>
                <li>If the Company finds later that the Registered Device that the User tendered to the Courier or returned via the Courier has been subject to Modification, then the Company will at its sole discretion:</li>
                <ol>
                  <li>where returned via the Courier, (i) charge the User the Administration Fee or (ii) the Company may terminate the Services with respect to the relevant Registered Device if under the Swap Program; or</li>
                  <li>where returned via the Courier, return to the User at the User's cost the original Registered Device tendered to the Courier. The User must also return to the Company the Like Mobile Device provided as a Swap. The Swap request will be considered cancelled. The Company will refund to the User the Swap Fee paid upon receipt of the Like Mobile Device. </li>
                </ol>
                <li>Once the User gives the User's Device to the Company, either directly or via the Courier, the Company will not be able to guarantee the return of the User's Device or the return of any of the User's Device Accessories given with the Device. In the event that the User's Device or Device Accessories are given to the Company, and the Company is able to return the User's Device or Device Accessories, the User will bear all of the Company's costs of returning the User's Device or Device Accessories. </li>
              </ol>
            </li><li>
              [NOT USED]
            </li><li>
              LIMITS
              <ol>
                <li>The User is eligible to make Service Requests for a Swap </li>
                <ol>
                  <li>For Fixed Term Package: – during the subscription period.</li>
                  <li>For Monthly Package: during the subscription period.</li>
                </ol>
              </ol></li><li>
              FEES
              <ol>
                <li>The User agrees to pay the relevant fee as set out in the schedule below according to the Tier of the Device and the Package the User has enrolled in ("AIS Mobile Care Swap Unlimited Package Fee").
                  <div style={{ maxWidth: "1000px", margin: "20px auto" }}>
                    <table border="0" cellPadding="10px" style={{ width: "100%" }}>
                      <tr>
                        <td style={{ border: "solid 1px black", background: "#bfd22b" }}>{' '}</td>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} colSpan={5} align="center">
                          Swap Program
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "solid 1px black", background: "#bfd22b" }}>{' '}</td>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} align="center">1</td>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} align="center">2</td>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} align="center">3</td>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} align="center">4</td>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} align="center">5</td>
                      </tr>
                      <tr>
                        <td style={{ border: "solid 1px black" }}>Monthly Package</td>
                        <td style={{ border: "solid 1px black" }} align="center">39</td>
                        <td style={{ border: "solid 1px black" }} align="center">69</td>
                        <td style={{ border: "solid 1px black" }} align="center">99</td>
                        <td style={{ border: "solid 1px black" }} align="center">129</td>
                        <td style={{ border: "solid 1px black" }} align="center">159</td>
                      </tr>
                      <tr>
                        <td style={{ border: "solid 1px black" }}>Fixed Term Package</td>
                        <td style={{ border: "solid 1px black" }} align="center">429</td>
                        <td style={{ border: "solid 1px black" }} align="center">759</td>
                        <td style={{ border: "solid 1px black" }} align="center">1089</td>
                        <td style={{ border: "solid 1px black" }} align="center">1419</td>
                        <td style={{ border: "solid 1px black" }} align="center">1749</td>
                      </tr>
                    </table>
                    <p style={{ textAlign: "center", margin: "20px 0 0" }}>(all amounts above including VAT)</p>
                  </div>
                </li>
                <li>Post-paid User will be charged for the AIS Mobile Care Swap Unlimited Package Fee via mobile service bills.
                  Where the User's Package is the Monthly Package, if enrolment is made during the billing cycle, the Monthly Fee will be charged to the actual days of Service provision from the date the User receives an SMS confirming his or her enrolment until the end of the plan period or until his or her notice of termination of Monthly Package is given.</li>
                <li>As for pre-paid User, the AIS Mobile Care Swap Unlimited Package Fee will be charged from top up immediately upon enrolment.
                  In the case that the User chooses the Monthly Package, the AIS Mobile Care Swap Unlimited Package Fee will be charged every month. </li>
                <li>AIS Mobile Care Swap Unlimited Package Fee is not included in the mobile service credit limit.</li>
                <li>The User agrees to pay the Swap Fee at the rate as fixed and notified by the Provider in an introduction letter or other materials that the Provider provides to the User. Payment will be made in cash directly to the Courier or by credit card at the time the User makes a Service Request.
                  <ol>
                    <li>Swap Fee is at 20percentage of the Registered Device Price as of Buying Date.</li>
                    <li>The User acknowledges and agrees that Swap Fee will be non-refundable once the Like Mobile Device has been delivered to and accepted by the User. </li>
                  </ol></li>
                <li>The Company will directly collect from the User the Swap Fee .</li>
                <li>If the User pays the Swap Fee by credit card, the User must use a credit card issued in the User's name. If the User uses a credit card issued in another person's name, the Company reserves the right to reject to provide the Services. </li>
              </ol></li><li>
              DELIVERY OF LIKE MOBILE DEVICE
              <ol>
                <li>After the Company has verified the information from the User and found it correct, the Company will deliver the Like Mobile Device to the User via the Courier within the period of time specified but subject to exception on: (i) any extensions as may be required for force majeure events or otherwise events beyond the control of Company (including no stock available of applicable Like Mobile Devices); (ii) any additional verifications relating to User's Service Request which the Company deems necessary to perform; or (iii) any Backorder in accordance with clauses 8.10  and 8.11.</li>
                <li>As at the time of delivery of the Like Mobile Device to the User, the User accepts that the delivered Device shall become the Registered Device.</li>
                <li>The User agrees that the delivery period commences from the time when the Company has received the User's information completely, the User has agreed to pay the Swap Fee and the Company has accepted the Service Request ("Acceptance Time").</li>
                <li>In case of delivery of the Like Mobile Device in the Greater Bangkok:
                  If the Acceptance Time for a Service Request is on Monday to Saturday before 2:00 p.m., the Like Mobile Device will be delivered by Courier to the User within six hours upon the Acceptance Time.
                  If the Acceptance Time for a Service Request is on Monday to Saturday, after 2:00 p.m. until 9:59 p.m. or on Sunday or public holiday, the Like Mobile Device will delivered by Courier to the User by 2:00 p.m. of the next Business Day.</li>

                <li>In case of delivery of the Like Mobile Device in the areas outside the Greater Bangkok, Contact Center will directly advise the User of the delivery time of the Like Mobile Device as the delivery period depends on the distance of each province.
                  <div style={{ maxWidth: "1000px", margin: "20px auto" }}>
                    <table border="0" cellPadding="10px" style={{ width: "100%" }}>
                      <tr>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} align="center">Delivery Location</td>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} align="center">Acceptance Time</td>
                        <td style={{ border: "solid 1px black", background: "#bfd22b", color: "white" }} align="center">Delivery Date - Time#</td>
                      </tr>
                      <tr>
                        <td style={{ border: "solid 1px black" }} align="center">Greater Bangkok</td>
                        <td style={{ border: "solid 1px black" }} align="center">Monday to Saturday, 08:00 a.m. to 02:00 p.m.</td>
                        <td style={{ border: "solid 1px black" }} align="center">Within 6 hours from the Acceptance Time<br /><br />If such day is Sunday or public holiday, delivery will be made by 02:00 p.m. of the next Business Day.</td>
                      </tr>
                      <tr>
                        <td style={{ border: "solid 1px black" }} align="center">Greater Bangkok</td>
                        <td style={{ border: "solid 1px black" }} align="center">Monday to Saturday, 02:01 p.m. to 9:59 p.m.</td>
                        <td style={{ border: "solid 1px black" }} align="center">The next day before 02:00 p.m.</td>
                      </tr>
                      <tr>
                        <td style={{ border: "solid 1px black" }} align="center">Greater Bangkok</td>
                        <td style={{ border: "solid 1px black" }} align="center">Sunday</td>
                        <td style={{ border: "solid 1px black" }} align="center">The next day before 02:00 p.m.<br /><br />If such day is a public holiday, delivery will be made by 02:00 p.m. of the next Business Day.</td>
                      </tr>
                      <tr>
                        <td style={{ border: "solid 1px black" }} align="center">Other provinces</td>
                        <td style={{ border: "solid 1px black" }} align="center">Everyday</td>
                        <td style={{ border: "solid 1px black" }} align="center">Within 3 days excluding the following areas:  Maehongsorn, Tak (outside Muang district), Karchanaburi (Sungklaburi, Tongpaphoom), and Islands (Samui, Pa-Ngan) for which User will be directly advised of the delivery time.</td>
                      </tr>
                    </table>
                    <p style={{ textAlign: "center", margin: "20px 0 0" }}># Subject to the exceptions specified in clause 13.1.</p>
                  </div>
                </li>

                <li>The Courier will deliver the Like Mobile Device to the User only, provided that the User is required to present his or her National ID Card or Foreigner's ID, as the case may be, or any other evidence issued by government agency proving the User's identity. Before or at the time of delivery, the User agrees to sign on any service request confirmation form or other documents as requested by the Company.</li>
                <li>The Courier will deliver the Like Mobile Device to the User's residential address as registered with the Provider for the Services only. The Courier may also deliver the Like Mobile Device at other premises as specified by the User on the enrolment date or the premises as specified during the Service Request, provided that such premises shall not be a shopping centre, department store, public transportation terminal, car park, market, public park or other premises similar to these premises.</li>
                <li>The Like Mobile Device will not be delivered in its original packaging. </li>
                <li>The Company reserves its right not to deliver to the address specified by the User for delivery of the Like Mobile Device to the User as appropriate if the address requested is not the User's registered residential address.</li>
                <li>The Company agrees not to charge the User for the delivery of the Like Mobile Device for the first and the second attempts of delivery if: (i) there is no intended recipient present; or (ii) the recipient is present but the User's identity cannot be proved; or (iii) the User rejects delivery of the Like Mobile Device in accordance with clause 13.11. For the third or subsequent attempt of delivery, the User agrees to pay to the Company for the delivery of the Like Mobile Device in cash via the Courier or by credit card to the Contact Center at the rate of THB500 for each additional delivery.</li>
                <li>The User can only reject delivery of the Like Mobile Device if the User is not satisfied by indicating to the Courier on the spot and not accepting the delivered Like Mobile Device. The Company will then deliver another Like Mobile Device for the User. Such return of the Like Mobile Device will not count towards User's Limits and no Swap Fee will be due.</li>
              </ol></li><li>
              PRIVACY
              <ol>
                <li>The User consents to the AIS Mobile Care Swap Unlimited Providers collecting, storing, processing, arranging, accessing, transferring, using and disclosing the User's personal information on the Services, including to their affiliates, group companies and service providers, in Thailand or other countries, for the purposes of: (i) assessing the User's eligibility for enrollment (or continued enrollment) in the Program or use of the Services; (ii) providing the User with the Services; (iii) contacting the User (directly or indirectly) in connection with the Services; (iv) managing commercial risks and preventing, detecting and investigating suspected illegal activity, fraud or disputes; and (v) creation of an aggregated, non-personally identifiable database of information.</li>
              </ol></li><li>
              CHANGE OF REGISTERED DEVICE
              <ol>
                <li>The Registered Device may not change except for:
                  <ol>
                    <li>the change made following the User's receipt of Like Mobile Device obtained by a Swap; or</li>
                    <li>the exchange of the User's Registered Device in accordance with the conditions of the warranty provided by the OEM for a new Device which is identical to the Registered Device. The User must inform the AIS Mobile Care Swap Unlimited ] Providers of such change of the Registered Device immediately via: (i) the Contact Center or (ii) visiting the AIS Shop or Telewiz Shop to provide details of Device so provided by the OEM, and the User must provide proof of the exchange so that the Company can update its records with the IMEI of the new Device from which time the new Device is considered as a Registered Device.</li>
                  </ol>
                  In the event that the User fails to inform of the exchange of such Registered Device pursuant to OEM warranty claim, the User will not be able to make a Service Request until the Contact Center has received proof of the exchange.</li>
              </ol></li><li>
              WARRANTY FOR LIKE MOBILE DEVICE
              <ol>
                <li>The User is entitled to a warranty for the Like Mobile Device for manufacturer malfunctions and manufacturer defects. </li>
                <li>The User is not entitled to make warranty claims if the Like Mobile Device: (i) does not have manufacturer malfunctions or manufacturer defects; (ii) has physical damage, cracked screens, or water damage; (iii) has systems damage, or application malfunctions; or (iv) has non-manufacturer malfunctions or defects. The User is also not entitled to make warranty claims for damaged Device Accessories. All of the above warranty claims are invalid warranty claims.</li>
                <li>Where applicable, the User is entitled to make a warranty claim on the Like Mobile Device after the lapse of 15 days from the date the User has previously requested for the warranty claim. </li>

                <li>The warranty period for the Like Mobile Device:

                  <ol>
                    <li>for an Apple branded Device being iPhone 6s or 6s Plus (or later models) within six months of the Buying Date, the warranty period will be one year from the Buying Date; </li>
                    <li>for an Apple branded Device being iPhone 6s or 6s Plus (or later models) at any time after six months from the Buying Date, the warranty period will be 180 days from the Acceptance Date; </li>
                    <li>for a Tier 1 Device, Tier 2 Device, Tier 3 Device Tier 4 Device or a Tier 5 Device not being an Apple branded Device at any time, the warranty period will be 180 days from the Acceptance Date; or </li>
                    <li>for the Device provided pursuant to clause 8.13 above, the warranty period will be 180 days from the Specification Date.</li>
                  </ol>

                </li>

                <li>The User acknowledges that if it is determined that the User has made a warranty claim under clause 16.1 above with respect to the Like Mobile Device, there will not be any Swap t Fees due. The User acknowledges that if it is determined that the User has made an invalid warranty claim under clause 16.2 above or the returned Device has no malfunction or defect, either the Device will be returned to the User, or the User will be charged a Swap Fee. The User acknowledges that the User's future warranty claims may be rejected if the User has previously made any invalid warranty claims under clause 16.2 above. In any cases, the Device provided under a warranty claim will be based on the specifications of the Like Mobile Device.</li>
                <li>The User may exercise the right to make a warranty claim for the Like Mobile Device if the User has no outstanding Swap Fee and payment for the AIS Mobile Care Swap Unlimited Package Fee via the Contact   Center. The Company will deliver the Like Mobile Device to the User in the same manner as a Swap.</li>
              </ol></li><li>
              TERMINATION BY USER
              <ol>
                <li>The User on a Monthly Package is entitled to terminate the Service by giving notice of his or her intention at least 30 days in advance via Online Portal or at AIS Shops or Telewiz Shops. </li>
                <li>Once a User has so terminated, the User cannot re-apply for the Services with the same Registered Device with the corresponding matching IMEI.</li>
                <li>Despite the termination of the Services, the User is still obliged to pay the Fee incurred before the date of termination in full even if the Service has been terminated.</li>
              </ol></li><li>
              REJECTION OF SERVICE PROVISION
              <p>The AIS Mobile Care Swap Unlimited Providers are entitled to reject to provide the Services promptly for the following reasons:</p>
              <ol>
                <li>When it appears that the User's name is shown in the system as having outstanding payment for the AIS Mobile Care Swap Unlimited Package Fee and/or mobile service bill of the Provider. In the case of pre-paid Users, if the top up is not sufficient for Fixed Fee or Monthly Fees within 15 days when it falls due.</li>
                <li>When it appears that the User's Phone Number was or is suspended due to one of the reasons in Clause 19.</li>
                <li>When it appears that the User's Phone number was or is terminated by the Provider due to one of the reasons in Clause 19.</li>
                <li>When the Company reasonably believes that the User has: (i) illegal intention to enroll for or use the Services; (ii) intention to use the Services in a manner which is fraudulent or related to any criminal activity; or (iii) intention to make a commercial gain from the Services.</li>
                <li>When the User's qualifications or the use of Services are not in compliance with these Terms and Conditions.</li>
                <li>When one of the following information given by the User: (i) Phone Number; (ii) Registered Device IMEI; (iii) first-last name; (iv) address; (v) Copy of National ID or the Foreigner's ID, as applicable, as proof of identity issued by competent authority; or (vi) any other information, including service request confirmation forms, as requested by the Company, is either incorrect according to the AIS Mobile Care Swap Unlimited Providers' records or is not provided to the Company's satisfaction.</li>
                <li>When the Company found, upon verification, that IMEI of the Registered Device does not correspond to IMEI recorded by the Provider.</li>
                <li>When the Registered Device has not been active with the associated Phone Number.</li>
                <li>The Company reasonably believes that the User has transferred the title to the Registered Device or Phone Number to another person except for legacy.</li>
              </ol>
            </li><li>
              TERMINATION
              <p>The AIS Mobile Care Swap Unlimited Providers are entitled to terminate the Services in the following events:</p>
              <ol>
                <li>The User's death, bankruptcy, or inability to pay debts as they fall due;</li>
                <li>The User defaults on payment for AIS Mobile Care Swap Unlimited Package Fee by due date, or in the case of pre-paid Users, if the top up is not sufficient for Fixed Fees or Monthly Fees;</li>
                <li>The User defaults on payment for mobile service bill by due date as specified in a bill for two consecutive times;</li>
                <li>The Company has reasonable ground to believe that the User uses the Services or mobile service in a fraudulent manner, or in a way that may adversely impact the AIS Mobile Care Swap Unlimited Providers' reputation, or illegally uses the Services or mobile service or breaches these Terms and Conditions or any other terms of the AIS Mobile Care Swap Unlimited Providers or uses the Services for commercial gain;</li>
                <li>The AIS Mobile Care Swap Unlimited Providers cannot provide the Services for the cause beyond the AIS Mobile Care Swap Unlimited Providers' control;</li>
                <li>The Services are terminated by provisions of law; </li>
                <li>The User has breached any of these Terms and Conditions;</li>
                <li>The User has provided incorrect, false, or incomplete information;</li>
                <li>The User has ported their Phone Number from the Provider's network or terminated their Phone Number;</li>
                <li>The User's Phone Number has been suspended for more than 60 days as set out in clause 8.14 above;</li>
                <li>The User's Phone Number has been terminated by the User or the Provider;</li>
                <li>The Company reasonably believes that the User has transferred title to the Registered Device or Phone Number to another person;</li>
                <li>The Company finds later that the Registered Device has been subject to Modification;</li>
                <li>The User fails to return the Like Mobile Device in contravention of clauses 9.7 and 9.8;</li>
                <li>The User fails to provide any additional information reasonably requested by the Company including in the form of a signed declaration or acknowledgment;</li>
                <li>If the AIS Mobile Care Swap Unlimited Providers are required to comply with an order, instruction or request of regulatory authority, government authority or any other competent authority which resulted in the AIS Mobile Care Swap Unlimited Providers having to stop or terminate the Services; or</li>
                <li>The User changes from being a post-paid User to a pre-paid User;</li>
                <li>For any other reason at the AIS Mobile Care Swap Unlimited Providers' discretion.</li>
              </ol>
            </li><li>
              OTHERS<ol>
                <li>The Provider reserves its right to change and amend these Terms and Conditions, or withdraw the Services by giving the User a prior notice through this Website which the User will be deemed to accept.</li>
                <li>Any provision of these Terms and Conditions which becomes illegal or unenforceable shall be deemed void only on the illegal or unenforceable part without impact on other provisions which shall remain valid.</li>
                <li>If the Services or service similar to the Services where the Company is the service provider have been terminated for a Registered Device by the AIS Mobile Care Swap Unlimited Providers: (i) the Services cannot be reactivated for that Registered Device; and (ii) the User may not be allowed to enroll for the Services with any other Device.</li>
                <li>If Provider introduces new services and the User is on a Monthly Package, the User may be entitled to subscribe to such services (at an additional cost) by contacting the Contact  Center. The User shall pay the service charges subject to the new service accordingly. The Services in general are subject to events beyond Provider's reasonable control. If such events occur, the Services may be temporarily suspended and any pending Service Requests may be suspended by the Provider until the events do not exist provided that the Provider shall notify the User of such suspension in advance.	</li>
                <li>Apart from these Terms and Conditions, the AIS Mobile Care Swap Unlimited Providers expressly and to the fullest extent permitted by law disclaim all warranties of any kind, whether: (i) express; (ii) implied; or (iii) statutory, including, but not limited to the implied warranties of merchantability and fitness for a particular purpose. The AIS Mobile Care Swap Unlimited Providers will, to the fullest extent permitted by law, not be responsible for any loss or damage caused to User because of the actions of any employee or any person or representative of the AIS Mobile Care Swap Unlimited Providers who acts beyond their authority. The AIS Mobile Care Swap Unlimited Providers shall, to the fullest extent permitted by law, not be liable for any direct or indirect loss or damage caused to User in respect of any matter howsoever arising in connection with the provision or User's use of the Services. User agrees to defend, indemnify and hold faultless the AIS Mobile Care Swap Unlimited Providers, their associates and their directors, officers, successors and assigns, from and against any and all liabilities, damages, losses, costs and expenses caused by or arising out of User's use of the Services.</li>
                <li>Without prejudice to the generality of the preceding statements, under no circumstances will the AIS Mobile Care Swap Unlimited Providers be liable for any indirect, consequential, special damages arising out of or in connection with the provision or User's use of the Services. The AIS Mobile Care Swap Unlimited Providers' aggregate liability to the User (whether based in contract, negligence, strict liability, statute or other theory of liability) will not exceed: (i) if the User are paying a Monthly Fee, the Monthly Fees paid by the User during the two months prior to the event giving rise to the claim; or (ii) if the User is on a Fixed Term Package, one sixth of the User's AIS Mobile Care Swap Unlimited Package Fee.</li>
                <li>The Agreement will be governed by and construed in accordance with the laws of the Kingdom of Thailand. Most of User's concerns or questions about the Services can be addressed via the Contact  Center. For any dispute with the AIS Mobile Care Swap Unlimited Providers, User agrees to first contact Provider and attempt to resolve the dispute with Provider informally.</li>
                <li>The Provider may from time to time offer promotions relating to all or any of the Services. Any such promotions shall be governed by the terms and conditions attached thereto by the Provider, and by these Terms to the extent that the promotion's terms and conditions are silent. In the event of any conflicts between a promotion's terms and conditions and these Terms, the promotion's terms and conditions shall prevail.</li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>

  )
}