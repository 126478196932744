const config = {
    "AppSync": {
        "graphqlEndpoint": process.env.REACT_APP_GRAPHQL_ENDPOINT,
        "region": process.env.REACT_APP_GRAPHQL_REGION,
        "authenticationType": process.env.REACT_APP_GRAPHQL_AUTHENTICATION_TYPE
    },
    "UIAPIServer": {
        "baseUrl": process.env.REACT_APP_API_BASE_ENDPOINT,
        "imageBaseUrl": process.env.REACT_APP_DEVICE_IMAGE_BASE_ENDPOINT,
        "APIKey": process.env.REACT_APP_API_KEY,
        "isLocal": process.env.REACT_APP_IS_LOCAL_ENVIORNMENT,
        "env": process.env.REACT_APP_ENV,
        "bgUrl": process.env.REACT_APP_BG_API_ENDPOINT
    },
    "Amplify": {
        "Analytics": {
            // OPTIONAL - disable Analytics if true
            "disabled": true
        },
        "Auth": {
            "identityPoolId": process.env.REACT_APP_COGNITO_IDENTITY_POOLID,
            "region": process.env.REACT_APP_COGNITO_REGION,
            "userPoolId": process.env.REACT_APP_COGNITO_USERPOOLID,
            "userPoolWebClientId": process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID
        },
    },
    "Twilio": {
        "workflowSid": process.env.REACT_APP_WORKFLOWS_ID,
        "taskTimeout": process.env.REACT_APP_TASK_TIMEOUT,
        "taskchannelName": process.env.REACT_APP_TASK_CHANNEL_NAME
    },
    "Lex": {
        "region": process.env.REACT_APP_LEX_REGION,
        "identityPoolId": process.env.REACT_APP_LEX_IDENTITYPOOLID,
        "botName": process.env.REACT_APP_LEX_BOT_NAME,
        "botAlias": process.env.REACT_APP_LEX_BOT_ALIAS,
        "lexUserId": process.env.REACT_APP_LEX_USER_ID
    },
    "ApplicationURL": process.env.REACT_APP_ONLINE_LANDING_PAGE,
    "EnquiryMDN": process.env.REACT_APP_ENQUIRY_MDN,
    "Mixpanel": {
        "token": process.env.REACT_APP_TOKEN_ID
    },
    "WPAPIServer": {
        baseUrl: process.env.REACT_APP_WP_BASE_ENDPOINT,
        // apiUrl: process.env.REACT_APP_WP_API_ENDPOINT
    },
    "slsAPIs": {
        "baseURL": process.env.REACT_APP_SLS_BASE_ENDPOINT,
        "APIKey": process.env.REACT_APP_SLS_API_KEY,
    },
    "TaskService": {
        "baseUrlSEA": process.env.REACT_APP_TS_BASE_ENDPOINT_SEA,
        "apiKey": process.env.REACT_APP_API_KEY_ASURION,
    }
}
//console.log(config);
export default config;