import React, { Component } from "react";

import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import mdnErrorIcon from '../../images/ais-images/icon-error.png';
import nricErrorIcon from '../../images/ais-images/icon-error.png';
import paymentIcon from '../../images/ais-images/icon-payment-fee.png';
import addressIcon from '../../images/ais-images/icon-error.png';
import agentIcon from '../../images/icons/ic-support_offline.png';
import assignedAgentBusy from '../../images/icons/ic-assigned_busy.png';
import requestIcon from '../../images/mobile/img-request_mobile.png';
import { endInteractionInit } from '../../actions/Dashboard/doFindAgreements';

class InformativeMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            yesButtonClassName: "ais_align-popup-button",
            disable: false
        }
        this.closeClick = this.closeClick.bind(this);
    }

    closeClick() {
        this.setState({
            disable: true,
            yesButtonClassName: "ais_align-popup-button-selected"
        });
        if(this.props.dataprops.source === CONSTANTS.USER_DISCONNECTED){
        }else{
        this.props.endInteractionInit(this.props.cacheId);
        }
    }

    render() {
        let errorIcon = '';
        let contentClass = "ais_verificationMessage";
        switch (this.props.dataprops.source) {
            case CONSTANTS.SOURCE_USER_INPUT.MDN:
            case CONSTANTS.SOURCE_USER_INPUT.NAME:
                errorIcon = mdnErrorIcon;
                break;
            case CONSTANTS.SOURCE_USER_INPUT.NRIC:
                errorIcon = nricErrorIcon;
                break;
            case CONSTANTS.SOURCE_USER_INPUT.ASSIGNED_AGENT_BUSY:
                errorIcon = assignedAgentBusy;
                contentClass = "ais_agent-busy-content";
                break;
            case CONSTANTS.SOURCE_USER_INPUT.CC_ATTEMPT_ERROR:
                errorIcon = paymentIcon;
                contentClass = "ais_agent-busy-content";
                break;
            case CONSTANTS.SOURCE_USER_INPUT.ADDRESS_ATTEMPT_ERROR:
                errorIcon = addressIcon;
                contentClass = "ais_agent-busy-content";
                break;
            case CONSTANTS.NO_EXISTING_SR:
                errorIcon = requestIcon;
                break;
            case CONSTANTS.USER_DISCONNECTED:
            case CONSTANTS.AGENT_DISCONNECTED:
                errorIcon = agentIcon;
                break;
            default:
                break;
        }
        // if ((this.props.source === CONSTANTS.SOURCE_USER_INPUT.MDN) || (this.props.source === CONSTANTS.SOURCE_USER_INPUT.NAME)) {
        //     errorIcon = mdnErrorIcon;
        // } else if (this.props.source === CONSTANTS.SOURCE_USER_INPUT.NRIC) {
        //     errorIcon = nricErrorIcon;
        // } else if (this.props.source === CONSTANTS.SOURCE_USER_INPUT.ASSIGNED_AGENT_BUSY) {
        //     errorIcon = assignedAgentBusy;
        //     contentClass = "agent-busy-content";
        // }
        return (
            <div className="ais_current-form-device-div ais_mdnInformativeMessage">
                <div className="ais_verificationHeader">
                    <label className="ais_current-device-spec ais_primary-header ais_head-width">{this.props.data.HEADER}</label>
                    <div>  <img alt="error-icon" className="ais_verificationImage" src={errorIcon} /> </div>
                    <div className={contentClass}>
                        {this.props.data.CONTENT}
                    </div>
                    {this.props.dataprops.source === CONSTANTS.NO_EXISTING_SR || this.props.dataprops.source === CONSTANTS.USER_DISCONNECTED ?
                   <div className="ais_outer-align-button-top-bottom">
                   <button disabled={this.state.disable} className={this.state.yesButtonClassName} onClick={this.closeClick} >{MESSAGE_CONSTANTS.CLOSE_TEXT}</button>
               </div> :''}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        endInteractionInit
    }, dispatch)
}


export default connect(null, mapDispatchToProps)(InformativeMessage); 