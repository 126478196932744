import * as React from 'react';
import { Heading, Text, VStack, Grid, GridItem, chakra, useToken } from '@chakra-ui/react';
import _DeviceIcon from './assets/DeviceIcon';
import { noop, chain, orderBy, groupBy } from 'lodash';
import { formatCapacityFromDevice, formatColor, formatPhoneName } from './functions';
import { useTranslation } from 'react-i18next';
import { STATUS, useDevice } from './hooks/useDevice';

const DeviceIcon = chakra(_DeviceIcon);
const { useState, forwardRef } = React;

export const DeviceGrid = forwardRef(
  ({ devices, currency = 'THB', disabled = false, onSelect = noop }, ref) => {
    const handleClick = (id, _disabled = disabled) => {
      if (!_disabled) {
        setSelectedId(id);
        onSelect?.(id);
      }
    };
    const [selectedId, setSelectedId] = useState();
    const isSelected = (id) => selectedId === id;
    const highlight = useToken('colors', 'brand.highlight');
    const { t } = useTranslation('', { keyPrefix: 'SmartStock' });

    return (
      <Grid
        w={'full'}
        gridTemplateColumns={'repeat(auto-fill, 160px)'}
        gap={1}
        rowGap={4}
        justifyContent="space-between"
        ref={ref}
      >
        {chain(devices)
          .thru((devices) => {
            const grouped = groupBy(devices, 'flags.isLikeForLike');
            return [...grouped[true], ...orderBy(grouped[false], ['serviceFee'], ['asc'])];
          })
          .value()
          .map((device, i) => {
            const {
              device: { serviceFee, id },
              status,
              isDisabled,
            } = useDevice(device, disabled);
            //console.log("status", status)
            if (status === STATUS.NOT_CURRENTLY_AVAILABLE || status === STATUS.NO_LONGER_PRODUCED) {
              return (<></>)
            } else {
              return (
                <GridItem
                  key={`device-grid-item-${i}`}
                  onClick={() => handleClick(id)}
                  opacity={isDisabled ? '50%' : 1}
                >
                  <VStack
                    w={'full'}
                    borderRadius={'xl'}
                    borderWidth={isSelected(id) ? 2 : 1}
                    borderStyle={'solid'}
                    borderColor={isSelected(id) ? 'brand.primary' : 'gray.150'}
                    {...(isSelected(id) ? { boxShadow: `1px 1px 8px ${highlight}` } : {})}
                    spacing={4}
                    p={'10px'}
                    minH={'160px'}
                    // h={'100%'}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                    _hover={{
                      ...(!selectedId &&
                      [STATUS.IN_STOCK, STATUS.OUT_OF_STOCK_BACKORDER_AVAILABLE].includes(status)
                        ? {
                            boxShadow: `1px 1px 8px ${highlight}`,
                            border: 'brand.primary',
                            cursor: 'pointer',
                          }
                        : {
                            cursor: 'not-allowed',
                          }),
                    }}
                  >
                    <DeviceIcon alignSelf="center" />
                    <VStack alignItems={'flex-start'} justifyContent={'flex-end'} spacing={0}>
                      <Heading
                        as="h2"
                        fontSize={'lg'}
                        lineHeight={'normal'}
                        fontWeight={'normal'}
                        fontStyle={'normal'}
                      >
                        {formatPhoneName(device.rawDevice)}
                      </Heading>
                      <Text as="span">
                        {formatCapacityFromDevice(device.rawDevice)} {formatColor(device.rawDevice)}
                      </Text>
                    </VStack>
                    <VStack spacing={0} alignItems={'flex-start'}>
                      <Text fontWeight={'bold'}>
                        {t('paymentLabel', { currency, amount: serviceFee.toLocaleString() })}
                      </Text>
                      {status === STATUS.OUT_OF_STOCK_BACKORDER_AVAILABLE && (
                        <Text color={'brand.error'} lineHeight={'normal'} fontSize="xx-small">
                          {t('backOrderNotice')}
                        </Text>
                      )}
                    </VStack>
                  </VStack>
                </GridItem>
              );
            }
          })}
      </Grid>
    );
  }
);
