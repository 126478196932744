import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  savePayload,
  updateTransitionPanel
} from "../../actions/Dashboard/doFindAgreements";
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { getLang } from './../../utils/utils';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
import enJsonFile from "../../config/ais-leaving-reasons-en.json";
import thaiJsonFile from "../../config/ais-leaving-reasons-thai.json";
import { LOAD_THANK_YOU, SAVE_2, STAY_WITH_AIS } from '../../actions/Dashboard/actionTypes';
import DOMPurify from "dompurify";

class AISSubReasonPage extends Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false }
    this.stayWithAIS = this.stayWithAIS.bind(this);
    this.showAcknowledge = this.showAcknowledge.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createInquiryResponse) {
      setTimeout(() => {
        this.props.savePayload(LOAD_THANK_YOU, { showThankYou: true, stayWithAIS: true });
      }, 3500);
    }
  }
  showAcknowledge() {
    MIXPANEL.track(EVENTLIST.AIS_LEAVING_ACKNOWLEDGE);
    let { Platform } = this.props;
    MIXPANEL.track(EVENTLIST.ROUTE_TO_REFUND_OR_SAVEDESK, { 'Route To Refund Or Savedesk': Platform });
    this.props.savePayload(SAVE_2, 'save2');
    if (Platform === 'Digital') {
      this.props.updateJourneyList(CONSTANTS.ROUTE_TO_REFUND, 'ROUTE_TO_REFUND');
    } else if (Platform === 'SaveDesk') {
      this.props.updateJourneyList(CONSTANTS.ROUTE_TO_SAVEDESK, 'ROUTE_TO_SAVEDESK');
    }
    this.setState({ disabled: true })
  }
  stayWithAIS() {
    MIXPANEL.track(EVENTLIST.THANK_YOU, { 'Load Thank You': '' });
    this.props.savePayload(SAVE_2, 'save2');
    this.props.savePayload(STAY_WITH_AIS, { stayWithAIS: true });
    this.props.updateJourneyList(CONSTANTS.LOAD_THANK_YOU, { stayWithAIS: true, save2: 'save2' });
    this.setState({ disabled: true })
  }

  render() {
    let { disabled } = this.state;
    let { selectedLeavingAISReason, swapFee, replacementFee, customerContact } = this.props;
    let { CurrencyCode, TotalAmount, PurchasePrice } = swapFee && swapFee.Fees;
    let YouSaveForSwap = parseFloat(PurchasePrice - TotalAmount).toFixed(2);
    let CurrencyCodeForReplacement = replacementFee && replacementFee.Fees && replacementFee.Fees.CurrencyCode;
    let TotalAmountForReplacement = replacementFee && replacementFee.Fees && replacementFee.Fees.TotalAmount
    let PurchasePriceFOrReplacement = replacementFee && replacementFee.Fees && replacementFee.Fees.PurchasePrice;
    let YouSaveForReplacement = parseFloat(PurchasePriceFOrReplacement - TotalAmountForReplacement).toFixed(2);

    const lang = getLang()
    let jsonFile = (lang === "th") ? thaiJsonFile : enJsonFile;

    let finalizeArrayToMap, headerLabel, payLessSaveMore;
    if ((selectedLeavingAISReason === CONSTANTS.NOT_HELPFULL ||
      selectedLeavingAISReason === CONSTANTS.OTHER_REASON ||
      selectedLeavingAISReason === CONSTANTS.COST_TOO_MUCH ||
      selectedLeavingAISReason === CONSTANTS.NO_NEED_PACKAGE_AUTO_RENEWAL)) {
      payLessSaveMore = true;
    } else if ((selectedLeavingAISReason === CONSTANTS.BUYING_NEW_DEVICE)) {
      finalizeArrayToMap = jsonFile && jsonFile.buyingNewDeviceSubpage;
    } else if ((selectedLeavingAISReason === CONSTANTS.NOT_USING_IT_ENOUGH_OR_NOT_AT_ALL)) {
      finalizeArrayToMap = jsonFile && jsonFile.notUsingItOrNotEnoughAtAllSubpage
    }
    const sanitizer = DOMPurify.sanitize;

    return (
      <div>
        <div className="ais_current-form-device-div desktop-no-box-view">
          <div className="ais_header-txt-div">
            <div className="ais-label">{headerLabel}</div>

            {/* Code for after reason selection */}
            {finalizeArrayToMap && finalizeArrayToMap.map((data) => {
              return <div className="" key={data.text}>
                <div className='json-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.text) }}></div>
                <div className='center' >
                  <img className="background-image" alt="" src={data.backgroundImg} />
                  <img className='icon-image' icon alt="" src={data.imageUrl} />
                </div>
              </div>
            })
            }
            {/* Code for service fee and replacement fee */}
            {payLessSaveMore &&
              <div>
                <label className="ais-swap-replace-label" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(MESSAGE_CONSTANTS.PAY_LESS_SAVE_MORE) }}></label>
                <div className="swap_replacement_fee">
                  <div className='swap-fee'>
                    <div className='fee'>{MESSAGE_CONSTANTS.SWAP_FEE}</div>
                    <div className="amount">{`${CurrencyCode} ${TotalAmount}`}</div>
                  </div>
                  <div className='swap-fee'>
                    <div className='youSave'>{MESSAGE_CONSTANTS.YOU_SAVE}</div>
                    <div className="amt">{`${CurrencyCode} ${YouSaveForSwap}`}</div>
                  </div>
                </div>

                <div className="swap_replacement_fee">
                  <div className='swap-fee'>
                    <div className='fee'>{MESSAGE_CONSTANTS.REPLACEMENT_FEE}</div>
                    <div className="amount">{`${CurrencyCodeForReplacement} ${TotalAmountForReplacement}`}</div>
                  </div>
                  <div className='swap-fee'>
                    <div className='youSave'>{MESSAGE_CONSTANTS.YOU_SAVE}</div>
                    <div className="amt">{`${CurrencyCodeForReplacement} ${YouSaveForReplacement}`}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <img className="image_pay_only" src="/images/img-pay-only-amount-smaller.svg" alt="pay" />
                  </div>
                </div>
              </div>
            }
            <div >
              <button className="stay-with-ais-button" disabled={disabled} onClick={this.stayWithAIS}>{MESSAGE_CONSTANTS.STAY_WITH_AIS_MOBILE_CARE}</button>
            </div>
            <div className={`submit-text ${disabled ? ' disabled' : ' '}`} onClick={this.showAcknowledge}>{MESSAGE_CONSTANTS.STAY_WITH_AIS_MOBILE_CARE_TEXT}</div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  let { selectedLeavingAISReason, swapFee, replacementFee, customerContact, Platform, createInquiryResponse } = state.customerData;
  return {
    selectedLeavingAISReason, swapFee, replacementFee, customerContact, Platform, createInquiryResponse
  }
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePayload,
      updateTransitionPanel
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AISSubReasonPage);

