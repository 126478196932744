import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import './AjaxLoader.css';

class AjaxLoader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startLabel: this.props.startLabel || "PROCESSING...",
      completeLabel: this.props.completeLabel || "DONE!",
      failureLabel: this.props.failureLabel || "ERROR OCCURED!",
      trickle: this.props.trickle || false,
      progressValue: this.props.progressValue || 0,
      trickleStep: this.props.trickleStep || 10,
      shouldShowFailureLabel: this.props.shouldShowFailureLabel || false,
      shouldHideAfterCompletion: this.props.shouldHideAfterCompletion || false,
      trickleInterval: this.props.trickleInterval || 1500,
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.progressValue !== nextProps.progressValue) {
      this.setState({
        progressValue: nextProps.progressValue,
      })
    }
  }

  componentDidMount() {
    let { trickle, trickleInterval } = this.state;
    if (trickle) {
      let intervaledTrickleProgress = setInterval(this.trickleProgress.bind(this), trickleInterval)
      this.setState({
        intervaledTrickleProgress
      })
    }
  }

  trickleProgress() {
    let { progressValue, trickleStep } = this.state;

    if(progressValue < 90) {
      this.setState({
        progressValue: progressValue + trickleStep
      });
    } else {
      clearInterval(this.state.intervaledTrickleProgress);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervaledTrickleProgress);
  }

  returnLabel() {
    let { startLabel, progressValue, completeLabel, failureLabel, shouldShowFailureLabel } = this.state;
    return progressValue < 100 ? startLabel : shouldShowFailureLabel ? failureLabel : completeLabel;
  }

  render() {
    let { progressValue, shouldHideAfterCompletion } = this.state;
    var loaderClass = classNames({
      'ais_ajax-loader-wrapper': true,
      'ais_loading-completed': shouldHideAfterCompletion && progressValue === 100 ? true : false
    });

    return (
      <div className={loaderClass}>
        <label>{this.returnLabel()}</label>
        <div id="ais_ajax-loader">
          <div className="ais_stage-progress-div">
            <div className="ais_progress-bar-container">
              <div className="ais_progress-bar" style={{ width: `${progressValue}%` }}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AjaxLoader;