import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  savePayload,
  updateTransitionPanel
} from "../../actions/Dashboard/doFindAgreements";
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import CONSTANTS from '../../utils/Constants';
import { MIXPANEL } from '../../mixpanel/Mixpanel';
import EVENTLIST from '../../mixpanel/Eventlist';
class RouteToSaveDesk extends Component {
  constructor(props) {
    super(props);
     this.leaveFeedback = this.leaveFeedback.bind(this);
     this.handleChange= this.handleChange.bind(this);
  }
  leaveFeedback(){
    MIXPANEL.track(EVENTLIST.THANK_YOU);
    this.props.updateJourneyList(CONSTANTS.LOAD_THANK_YOU,'LOAD_THANK_YOU');
  }
  render(){
      return(
      <div>
        <div className="ais_current-form-device-div">
          <div className="ais_header-txt-div">
             <div>
                <div className="acknowledge-text left">{MESSAGE_CONSTANTS.ROUTE_TO_SAVEDESK}</div>
                <div className='acknowledge_comment_text left'>{MESSAGE_CONSTANTS.FEEDBACK_COMMENT}</div>
                <div className='acknowledge_comment'>
                    <textarea maxLength= "100" className= 'textarea_border' placeholder={MESSAGE_CONSTANTS.TEXT_AREA_PLACEHOLDER}/>
                </div>
                 <div>
                  <button className="stay-with-ais-button" onClick={this.leaveFeedback}>{MESSAGE_CONSTANTS.LEAVE_FEEDBACK}</button>
                </div>
            </div>
        </div>
        </div>
      </div>
      )
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      savePayload,
      updateTransitionPanel
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(RouteToSaveDesk);

