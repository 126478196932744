import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import "./enterCreditCardDetails.css";

import { postCCDetailsToBGInit, postCCDetailsToBGV3Init, savePayload } from '../../actions/Dashboard/doFindAgreements'
import { SAVE_CC_DETAILS } from '../../actions/Dashboard/actionTypes';
import { validateExpiryDate, formatExp, hasNumbers, getMaskedCCNum, isDeviceOrWeb, validateNameRegex } from '../../utils/utils';
import { do_encrypt } from '../../utils/xdr-encrypt';
import CONSTANTS from "./../../utils/Constants";
import MESSAGE_CONSTANTS from "../../config/journey-messages";

import visaIcon from "../../images/icons/img-visa.png";
import amexIcon from "../../images/icons/img-amex@2x.png";
import mastercardIcon from "../../images/icons/img-master@2x.png";
import closeIcon from '../../images/ais-images/icon-cross.png';

class EnterCreditCardDetails extends Component {

    constructor(props) {

        super(props);
        this.state = {
            cc_name: "",
            cc_number: "",
            cc_expDate: "",
            cc_cvc: "",
            hide_visa: true,
            hide_master: true,
            hide_amex: true,
            formErrors: { cc_name: '', cc_number: '', cc_expDate: '', cc_cvc: '' },
            cc_nameValid: '',
            cc_numberValid: '',
            cc_expDateValid: '',
            cc_cvcValid: '',
            formValid: false,
            disable: false,
            hideModalPopUp: false,
            clearBtnClass: 'ais_device-select-button ais_credit-card',
            cancelBtnClass: 'ais_device-select-button ais_credit-card',
            submitBtnClass: 'ais_device-select-button ais_credit-card'
        };
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.onModalCloseClick = this.onModalCloseClick.bind(this);
    }

    onModalCloseClick(e) {
        this.setState({ hideModalPopUp: true });
        document.querySelector('#chatWindow').style.overflow = 'auto';
        this.props.updateJourneyList(CONSTANTS.PAYMENT_FEE, MESSAGE_CONSTANTS.PAYMENT_CANCELLED_PROCEED);
    }

    handleUserInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        switch (e.target.name) {
            case "cc_cvc":
            case 'cc_number':
                value = e.target.value === " " ? e.target.value.trim() : e.target.value.replace(/[^0-9]/g, '')
                break;
            case "cc_name":
                // value = e.target.value === " " ? e.target.value.trim() : e.target.value.replace(/[^a-zA-Z ]/g, '')
                value = e.target.value === " " ? e.target.value.trim() : e.target.value.replace(validateNameRegex(), '')
                break;
        }
        this.setState({ [name]: value },
            () => { this.validateField(name, value) });
    }

    handleOnBlur = (e) => {
        if (e.target.name === "cc_name") {
            // e.target.value = e.target.value.replace(/\s+$/g, '');
            // this.setState({ "cc_name": e.target.value });
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let cc_nameValid = this.state.cc_nameValid;
        let cc_numberValid = this.state.cc_numberValid;
        let cc_expDateValid = this.state.cc_expDateValid;
        let cc_cvcValid = this.state.cc_cvcValid;
        let hide_visa = this.state.hide_visa;
        let hide_master = this.state.hide_master;
        let hide_amex = this.state.hide_amex;

        switch (fieldName) {
            case 'cc_name':
                let nameValue = value;
                nameValue = nameValue.replace(validateNameRegex(), '')
                // value = nameValue.replace(/^\s+/g, '');
                // this.refs['cc_name'].value = value;
                // let nameRegex = /^[a-zA-Z ]{2,30}$/;
                if (nameValue === "") {
                    cc_nameValid = false;
                    fieldValidationErrors.cc_name = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.NAME_MANDATORY;
                } else if (nameValue.length < 3) {
                    cc_nameValid = false;
                    fieldValidationErrors.cc_name = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.NAME;
                } else if (nameValue.trim().length >= 3) {
                    let nameArray = nameValue.trim().split(' ');
                    let filteredNameArray = nameArray.filter(function (el) {
                        return el != null;
                    });
                    
                    if (filteredNameArray.length < 2) {
                        cc_nameValid = false;
                        fieldValidationErrors.cc_name = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.NAME;
                    } else {
                        cc_nameValid = true;
                        fieldValidationErrors.cc_name = '';
                    }
                }
                //  else if (nameRegex.test(value) === false) {
                //     cc_nameValid = false;
                //     fieldValidationErrors.cc_name = 'Please insert valid name';
                // }
                else {
                    cc_nameValid = true;
                    fieldValidationErrors.cc_name = '';
                }
                break;
            case 'cc_number':
                let visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
                let mastercardPattern = /^(?:5[1-5][0-9]{14})$/;
                let amexPattern = /^(?:3[47][0-9]{13})$/;
                if (value.length === 0) {
                    cc_numberValid = false;
                    fieldValidationErrors.cc_number = MESSAGE_CONSTANTS.MANDATE_CREDIT_CARD;
                } else if (value.length < 16) {
                    cc_numberValid = false;
                    fieldValidationErrors.cc_number = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.CARD_NUMBER;
                }

                if ((value.match(visaPattern)) || (value.match(mastercardPattern)) || (value.match(amexPattern))) {

                    cc_numberValid = true;
                    if (cc_numberValid) {
                        if (value.match(visaPattern)) {
                            hide_visa = false;
                            hide_master = true;
                            hide_amex = true;
                        } else if (value.match(mastercardPattern)) {
                            hide_visa = true;
                            hide_master = false;
                            hide_amex = true;
                        } else if (value.match(amexPattern)) {
                            hide_visa = true;
                            hide_master = true;
                            hide_amex = false;
                        } else {
                            hide_visa = true;
                            hide_master = true;
                            hide_amex = true;
                        }
                    }
                    fieldValidationErrors.cc_number = '';
                } else {
                    cc_numberValid = false;
                    fieldValidationErrors.cc_number = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.CARD_NUMBER;
                    hide_visa = true;
                    hide_master = true;
                    hide_amex = true;
                }
                break;
            case 'cc_expDate':
                if (value === "") {
                    cc_expDateValid = false;
                    fieldValidationErrors.cc_expDate = MESSAGE_CONSTANTS.MANDATE_EXPIRY_DATE;
                } else {
                    if (validateExpiryDate(value) === "format") {
                        cc_expDateValid = false;
                        fieldValidationErrors.cc_expDate = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.EXPIRY;
                    } else if (validateExpiryDate(value) === "passed") {
                        cc_expDateValid = false;
                        fieldValidationErrors.cc_expDate = MESSAGE_CONSTANTS.EXPIRED_DATE;
                    } else {
                        cc_expDateValid = true;
                        fieldValidationErrors.cc_expDate = '';
                    }
                }
                break;
            case 'cc_cvc':
                const re = /^[0-9]*$/;
                if (value === "") {
                    cc_cvcValid = false;
                    fieldValidationErrors.cc_cvc = MESSAGE_CONSTANTS.MANDATE_CVC;
                } else if (value.length < 3) {
                    cc_cvcValid = false;
                    fieldValidationErrors.cc_cvc = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.CVC;
                } else if (re.test(value) === false) {
                    cc_cvcValid = false;
                    fieldValidationErrors.cc_cvc = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.CVC;
                } else {
                    cc_cvcValid = true;
                    fieldValidationErrors.cc_cvc = '';
                }
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            cc_nameValid: cc_nameValid,
            cc_numberValid: cc_numberValid,
            cc_expDateValid: cc_expDateValid,
            cc_cvcValid: cc_cvcValid,
            hide_visa: hide_visa,
            hide_master: hide_master,
            hide_amex: hide_amex,
        }, this.validateForm);
    }

    validateForm() {
        let formValid = false;
        if (this.state.cc_nameValid && this.state.cc_numberValid && this.state.cc_expDateValid && this.state.cc_cvcValid) {
            formValid = true;
            // this.setState({disable:false});
        }
        this.setState({ formValid: formValid });
    }

    validateFormOnSubmit() {
        let formValid = false;
        if (this.state.cc_nameValid && this.state.cc_numberValid && this.state.cc_expDateValid && this.state.cc_cvcValid) {
            formValid = true;
            // this.setState({disable:false});
        }
        this.setState({ formValid: formValid });

    }

    handleCCOnKeyPress(e) {
        hasNumbers(e);
    }

    handleChangeExpDate(e) {
        formatExp(e);
    }

    validateOnSubmit() {
        let fieldValidationErrors = this.state.formErrors;
        let cc_nameValid = this.state.cc_nameValid;
        let cc_numberValid = this.state.cc_numberValid;
        let cc_expDateValid = this.state.cc_expDateValid;
        let cc_cvcValid = this.state.cc_cvcValid;

        for (const data in this.refs) {
            // formData[data] = this.refs[data].value;
            switch (data) {
                case 'cc_name':
                    let nameValue = this.refs[data].value;

                    nameValue = nameValue.replace(validateNameRegex(), '')


                    // let nameRegex = /^[a-zA-Z ]{2,30}$/;
                    // let nameRegex = /[^\u0E00-\u0E7Fa-zA-Z' ]{2,30}/;
                    // this.refs['cc_name'].value = nameValue.replace(/^\s+/g, '');/\s+$/
                    this.refs['cc_name'].value = nameValue.trim();
                    if (this.refs[data].value === "") {
                        cc_nameValid = false;
                        fieldValidationErrors.cc_name = MESSAGE_CONSTANTS.PAYMENT_INVALID_LABEL.NAME_MANDATORY;
                    } else if (this.refs[data].value.length < 3) {
                        cc_nameValid = false;
                        fieldValidationErrors.cc_name = MESSAGE_CONSTANTS.INSERT_VALID_NAME;
                    }
                    //  else if (nameRegex.test(this.refs[data].value) === false) {
                    //     cc_nameValid = false;
                    //     fieldValidationErrors.cc_name = 'Please insert valid name';
                    // }
                    else {
                        cc_nameValid = true;
                        fieldValidationErrors.cc_name = '';
                    }
                    break;
                case 'cc_number':
                    let visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
                    let mastercardPattern = /^(?:5[1-5][0-9]{14})$/;
                    let amexPattern = /^(?:3[47][0-9]{13})$/;
                    if (this.refs[data].value.length === 0) {
                        cc_numberValid = false;
                        fieldValidationErrors.cc_number = MESSAGE_CONSTANTS.MANDATE_CREDIT_CARD;
                    } else if (this.refs[data].value.length < 16) {
                        cc_numberValid = false;
                        fieldValidationErrors.cc_number = MESSAGE_CONSTANTS.INSERT_VALID_NUMBER;
                    }

                    if ((this.refs[data].value.match(visaPattern)) || (this.refs[data].value.match(mastercardPattern)) || (this.refs[data].value.match(amexPattern))) {

                        cc_numberValid = true;

                        fieldValidationErrors.cc_number = '';
                    } else {
                        cc_numberValid = false;
                        fieldValidationErrors.cc_number = MESSAGE_CONSTANTS.INSERT_VALID_NUMBER;
                    }
                    break;
                case 'cc_expDate':
                    if (this.refs[data].value === "") {
                        cc_expDateValid = false;
                        fieldValidationErrors.cc_expDate = MESSAGE_CONSTANTS.MANDATE_EXPIRY_DATE;
                    } else {
                        if (validateExpiryDate(this.refs[data].value) === "format") {
                            cc_expDateValid = false;
                            fieldValidationErrors.cc_expDate = MESSAGE_CONSTANTS.INSERT_EXPIRY_FORMAT;
                        } else if (validateExpiryDate(this.refs[data].value) === "passed") {
                            cc_expDateValid = false;
                            fieldValidationErrors.cc_expDate = MESSAGE_CONSTANTS.EXPIRED_DATE;
                        } else {
                            cc_expDateValid = true;
                            fieldValidationErrors.cc_expDate = '';
                        }
                    }
                    break;
                case 'cc_cvc':
                    const re = /[^0-9]/g;
                    if (this.refs[data].value === "") {
                        cc_cvcValid = false;
                        fieldValidationErrors.cc_cvc = MESSAGE_CONSTANTS.MANDATE_CVC;
                    } else if (this.refs[data].value.length < 4) {
                        cc_cvcValid = false;
                        fieldValidationErrors.cc_cvc = MESSAGE_CONSTANTS.INSERT_VALID_CVC;
                    } else if (re.test(this.refs[data].value) === false) {
                        cc_cvcValid = false;
                        fieldValidationErrors.cc_cvc = MESSAGE_CONSTANTS.INSERT_VALID_CVC;
                    } else {
                        cc_cvcValid = true;
                        fieldValidationErrors.cc_cvc = '';
                    }
                    break;
                default:
                    break;
            }
        }
        this.setState({
            formErrors: fieldValidationErrors,
            cc_nameValid: cc_nameValid,
            cc_numberValid: cc_numberValid,
            cc_expDateValid: cc_expDateValid,
            cc_cvcValid: cc_cvcValid
        }, this.validateFormOnSubmit);

    }

    handleCancelClick() {
        this.setState({ hideModalPopUp: true });
        document.querySelector('#chatWindow').style.overflow = 'auto';
        this.setState({ cancelBtnClass: 'ais_device-select-button ais_credit-card ais_btnSel' });
        this.props.updateJourneyList(CONSTANTS.PAYMENT_FEE, MESSAGE_CONSTANTS.PAYMENT_CANCELLED_PROCEED);
    }

    handleClearClick() {
        // console.log("Clear button clicked");
        this.setState({ clearBtnClass: 'ais_device-select-button ais_credit-card' });
        this.setState({
            cc_name: "",
            cc_number: "",
            cc_cvc: "",
            cc_expDate: "",
            hide_visa: true,
            hide_master: true,
            hide_amex: true,
            formErrors: { cc_name: '', cc_number: '', cc_expDate: '', cc_cvc: '' },
            formValid: false,
            // disable:true,
            submitBtnClass: 'ais_device-select-button ais_credit-card'
        });
        for (const data in this.refs) {
            switch (data) {
                case 'cc_name':
                case 'cc_number':
                case 'cc_cvc':
                case 'cc_expDate':
                    this.refs[data].value = "";
                    break;
                default:
                    break;
            }
        }
    }

    handleSubmitClick() {
        // console.log("Submit button clicked:" + this.state.formValid);
        if (this.state.formValid === false) {
            this.validateOnSubmit();
        } else {

            let cardType = "";
            if (this.state.hide_visa === false) {
                cardType = "VISA";
            } else if (this.state.hide_amex === false) {
                cardType = "AMEX";
            } else if (this.state.hide_master === false) {
                cardType = "MASTER";
            }

            this.setState({
                submitBtnClass: 'ais_device-select-button ais_credit-card ais_btnSel',
                disable: true,
                hideModalPopUp: true
            });

            this.props.updateJourneyList(this.props.dataprops.source, MESSAGE_CONSTANTS.PAYMENT_SUBMITTED);
            let expDate = this.state.cc_expDate.split('-');

            let ccformask = getMaskedCCNum(this.state.cc_number);
            let ccmask = ccformask.replace(/(\w{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');

            this.props.savePayload(SAVE_CC_DETAILS, { cardType, ccmask });

            //this.props.postCCDetailsToBGInit({
            this.props.postCCDetailsToBGV3Init({
                cacheId: this.props.cacheId,
                name: this.state.cc_name,
                postalCode: this.props.postalCode,
                addressId: this.props.addressId,
                cvc: this.state.cc_number,
                cardType: cardType,
                expYear: expDate[1],
                expMonth: expDate[0],
                cvv: this.state.cc_cvc,
                pciToken: this.props.pciToken,
                referenceId: this.props.referenceId,
                encryptedPanData: window.encodeURIComponent(do_encrypt(
                  this.state.cc_number,
                  this.state.cc_cvc,
                  this.props.encryptionKey
                )),
                encKey: this.props.encryptionKey
              });
        }
    }

    render() {
        let ccNameClasses = "ais_enter-credit-input ais_web";
        let ccNumClasses = "ais_enter-credit-input ais_web";
        let ccExpClasses = "ais_enter-credit-expiry-input ais_web";
        let ccCvcClasses = "ais_enter-credit-cvc-input ais_web ais_cvvDisc";
        let visaClass = "ais_hide-visa";
        let masterClass = "ais_hide-mastercard";
        let amexClass = "ais_hide-amex";
        // let btnClass = "device-select-button credit-card";
        /* let submittedBtnClass = classNames('btn', {
          'ss_btn-field': isDeviceOrWeb() === 'web' || isDeviceOrWeb() === 'tablet',
          'ss_btn-field-sm': isDeviceOrWeb() === 'mobile',
          'ss_bluish-background-selected': this.state.submitClicked
        });

        let clearBtnClass = classNames('btn', {
          'ss_btn-field': isDeviceOrWeb() === 'web' || isDeviceOrWeb() === 'tablet',
          'ss_btn-field-sm': isDeviceOrWeb() === 'mobile',
          'ss_margin-right-10': true
        }); */

        if (this.state.cc_nameValid === false) {
            ccNameClasses = ccNameClasses.concat(" errorBorder");
        }

        if (this.state.cc_numberValid === false) {
            ccNumClasses = ccNumClasses.concat(" errorBorder");
        }

        if (this.state.cc_expDateValid === false) {
            ccExpClasses = ccExpClasses.concat(" errorBorder");
        }

        if (this.state.cc_cvcValid === false) {
            ccCvcClasses = ccCvcClasses.concat(" errorBorder");
        }

        if (this.state.hide_visa === false) {
            visaClass = "";
            masterClass = "ais_hide-mastercard";
            amexClass = "ais_hide-amex";
        } else if (this.state.hide_master === false) {
            visaClass = "ais_hide-visa";
            masterClass = "";
            amexClass = "ais_hide-amex";
        } else if (this.state.hide_amex === false) {
            visaClass = "ais_hide-visa";
            masterClass = "ais_hide-mastercard";
            amexClass = "";
        } else {
            visaClass = "ais_hide-visa";
            masterClass = "ais_hide-mastercard";
            amexClass = "ais_hide-amex";
        }
        let commonDiv = <form autoComplete="off">
            <div className="form-row row">
                <div className="col-md-6 ais_padding-right-5 ais_padding-left-5">
                    <label className="ais_credit_card_label" htmlFor="cc_name">{this.props.data.LABELS.NAME}</label>
                    <input type="text" ref="cc_name" name="cc_name" autoComplete="off" disabled={this.state.disable} className="form-control ais_cc-input-field" value={this.state.cc_name} maxLength="30" onChange={this.handleUserInput} onBlur={this.handleOnBlur} placeholder={this.props.data.PLACEHOLDERS.NAME} />
                    {(this.state.cc_nameValid) ? <label className="ais_cc-text-error"></label> : <label className="ais_cc-text-error">{this.state.formErrors.cc_name}</label>}
                </div>
                <div className="col-md-6 ais_padding-right-5 ais_padding-left-5">
                    <label className="ais_credit_card_label" htmlFor="cc_number">{this.props.data.LABELS.CARD_NO}</label>
                    <input type="text" ref="cc_number" name="cc_number" autoComplete="off" disabled={this.state.disable} className="form-control ais_cc-input-field" value={this.state.cc_number} pattern="[0-9]*" maxLength="16" onChange={this.handleUserInput} placeholder={this.props.data.PLACEHOLDERS.CARD_NO} />
                    {(this.state.cc_numberValid) ? <label className="ais_cc-text-error"></label> : <label className="ais_cc-text-error">{this.state.formErrors.cc_number}</label>}
                    <img alt="visa-icon" src={visaIcon} id="visa" className={visaClass} />
                    <img alt="mastercard-icon" src={mastercardIcon} id="mastercard" className={masterClass} />
                    <img alt="amex-icon" src={amexIcon} id="amex" className={amexClass} />
                </div>
                <div className="col-md-6 ais_padding-right-5 ais_padding-left-5">
                    <label className="ais_credit_card_label" htmlFor="cc_name">{this.props.data.LABELS.EXPIRY}</label>
                    <input type="text" ref="cc_expDate" name="cc_expDate" autoComplete="off" disabled={this.state.disable} className="form-control ais_cc-input-field" maxLength="7" type="text" onChange={(e) => this.handleChangeExpDate(e)} onBlur={this.handleUserInput} placeholder={this.props.data.PLACEHOLDERS.EXPIRY} />
                    {(this.state.cc_expDateValid === true) ? <label className="ais_cc-text-error"></label> : <label className="ais_cc-text-error">{this.state.formErrors.cc_expDate}</label>}
                </div>
                <div className="col-md-6 ais_padding-right-5 ais_padding-left-5">
                    <label className="ais_credit_card_label" htmlFor="cc_number">{this.props.data.LABELS.CVC}</label>
                    <input type="password" ref="cc_cvc" name="cc_cvc" autoComplete="off" disabled={this.state.disable} className="form-control ais_cc-input-field" maxLength="4" onChange={this.handleUserInput} value={this.state.cc_cvc} placeholder={this.props.data.PLACEHOLDERS.CVC} maxLength="4" />
                    {(this.state.cc_cvcValid === true) ? <label className="ais_cc-text-error"></label> : <label className="ais_cc-text-error">{this.state.formErrors.cc_cvc}</label>}
                </div>
            </div>
            <div className="ais_outer-align-button">
                <button type="button" disabled={this.state.disable} onClick={(e) => this.handleCancelClick(e)} className={this.state.cancelBtnClass} >{this.props.data.BUTTONS[0]}</button>
                <button type="button" disabled={this.state.disable} onClick={(e) => this.handleClearClick(e)} className={this.state.clearBtnClass} >{this.props.data.BUTTONS[1]}</button>
                <button type="button" disabled={this.state.disable} onClick={(e) => this.handleSubmitClick(e)} className={this.state.submitBtnClass} >{this.props.data.BUTTONS[2]}</button>
            </div>
        </form>
        let creditCardDiv;
        if (isDeviceOrWeb() === "web") {
            creditCardDiv = <div className="ais_device-outer ais_web">
                <div className="ais_container-div-address ais_web">
                    <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
                    {commonDiv}
                </div>
            </div>
        } else {
            let style = this.state.hideModalPopUp ? { 'display': 'none' } : {};
            creditCardDiv = <div id="modalAddressDetails" className="ais_modal" style={style}>
                <div className="ais_modal-content ais_modal-CC-wrapper">
                    <div className="ais_scroll-div-address">
                        <img alt="close-icn" className="ais_close-btn-add" src={closeIcon} onClick={this.onModalCloseClick} />
                        <div className="ais_container-div-CC-modal">
                            <div className="ais_container-wrapper-lw" style={{ 'border': 0, 'borderRadius': 0 }}>
                                <label className="ais_mobile-header-text">{this.props.data.HEADER}</label>
                                {commonDiv}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return (
            <div>
                {creditCardDiv}
            </div>
        )
    }
}
const mapStateToProps = state => {
    let { pciToken, addressList, selectedAddress, referenceId, encryptionKey, resumeServiceRequestResult, clientData, postalCode } = state.customerData;
    let addressId = addressList.AddressResult ? addressList.AddressResult.StandardisedFromAddressId : selectedAddress ? selectedAddress.StandardisedFromAddressId : resumeServiceRequestResult.AddressResult;
    return {
        cacheId: clientData.CacheId,
        pciToken,
        postalCode,
        addressId,
        referenceId,
        encryptionKey
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        postCCDetailsToBGInit,
        postCCDetailsToBGV3Init,
        savePayload
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterCreditCardDetails);