import React from 'react'
import { Link } from 'react-router-dom'
import MESSAGE_CONSTANTS from '../../config/journey-messages'

export default function MDNIncorrect() {
  return (
    <div className="ais_current-form-device-div subscription-details-wrap">
      <div className="ais_header-txt-div">
        <div className="ais_confirm-delivery-icn">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0)">
              <path d="M36.5621 0H14.1712C12.3825 0 10.9273 1.45522 10.9273 3.24392V5.12478H9.04654C7.25783 5.12478 5.80261 6.57969 5.80261 8.36792V10.3872H3.43705C1.6485 10.3872 0.193359 11.8424 0.193359 13.6309V31.8837C0.193359 33.6723 1.6485 35.1275 3.43705 35.1275H10.6605L13.6634 39.4909C14.1307 40.1699 15.1348 40.1693 15.6017 39.4909L18.6046 35.1275H25.828C27.6166 35.1275 29.0717 33.6723 29.0717 31.8837V29.8646H31.4373C33.226 29.8646 34.6812 28.4094 34.6812 26.6207V24.7398H36.5621C38.3508 24.7398 39.8061 23.2849 39.8061 21.4967V3.24392C39.8061 1.45522 38.3509 0 36.5621 0ZM26.7188 31.8838C26.7188 32.375 26.3192 32.7746 25.828 32.7746H17.986C17.5988 32.7746 17.2363 32.9652 17.0168 33.2841L14.6324 36.7488L12.248 33.2841C12.0285 32.9652 11.6661 32.7746 11.2789 32.7746H3.43705C2.94591 32.7746 2.5463 32.375 2.5463 31.8838V13.6309C2.5463 13.1398 2.94591 12.7402 3.43705 12.7402C3.89587 12.7402 23.9098 12.7405 25.8279 12.7405C26.325 12.7405 26.7189 13.1489 26.7189 13.6309L26.7188 31.8838ZM32.3283 26.6207C32.3283 27.112 31.9287 27.5117 31.4374 27.5117H29.0719V13.6307C29.0719 11.8436 27.6178 10.3872 25.8281 10.3872H8.15556V8.36792C8.15556 7.8771 8.55524 7.47773 9.04654 7.47773H31.4374C31.9287 7.47773 32.3283 7.8771 32.3283 8.36792V26.6207ZM37.4531 21.4967C37.4531 21.9875 37.0534 22.3869 36.5621 22.3869H34.6813V8.36792C34.6813 6.57969 33.2261 5.12478 31.4374 5.12478H13.2803V3.24392C13.2803 2.75263 13.6799 2.35294 14.1712 2.35294H36.5621C37.0534 2.35294 37.453 2.75263 37.453 3.24392L37.4531 21.4967Z" fill="#3D3D3D" />
              <path d="M13.9832 18.2041C12.2306 18.2041 10.8047 19.63 10.8047 21.3827V25.142C10.8047 25.7917 11.3314 26.3184 11.9812 26.3184C12.6309 26.3184 13.1576 25.7917 13.1576 25.142V24.1201H14.8088V25.142C14.8088 25.7917 15.3355 26.3184 15.9852 26.3184C16.635 26.3184 17.1617 25.7917 17.1617 25.142V21.3827C17.1618 19.63 15.7359 18.2041 13.9832 18.2041ZM13.1577 21.7672V21.3828C13.1577 20.9275 13.5281 20.5572 13.9833 20.5572C14.4385 20.5572 14.8088 20.9275 14.8088 21.3828V21.7672H13.1577Z" fill="#BFD22B" />
              <path d="M8.65497 20.557C9.3047 20.557 9.83145 20.0303 9.83145 19.3806C9.83145 18.7308 9.3047 18.2041 8.65497 18.2041H5.0378C4.38807 18.2041 3.86133 18.7308 3.86133 19.3806V25.142C3.86133 25.7917 4.38807 26.3185 5.0378 26.3185C5.68752 26.3185 6.21427 25.7917 6.21427 25.142V23.5671H8.6549C9.30462 23.5671 9.83137 23.0403 9.83137 22.3906C9.83137 21.7409 9.30462 21.2141 8.6549 21.2141H6.21435V20.5571H8.65497V20.557Z" fill="#BFD22B" />
              <path d="M25.0099 25.0757L24.2376 24.3894C24.4023 24.0056 24.4937 23.5834 24.4937 23.14V21.3827C24.4937 19.63 23.0679 18.2041 21.3152 18.2041C19.5626 18.2041 18.1367 19.63 18.1367 21.3827V23.14C18.1367 25.4173 20.4785 26.9649 22.5734 26.0583L23.4467 26.8345C23.9322 27.2661 24.6759 27.2222 25.1076 26.7367C25.5393 26.2509 25.4956 25.5073 25.0099 25.0757ZM20.4898 23.14V21.3827C20.4898 20.9274 20.8602 20.5571 21.3154 20.5571C21.7706 20.5571 22.141 20.9275 22.141 21.3827V23.14C22.141 23.5952 21.7706 23.9656 21.3154 23.9656C20.8601 23.9655 20.4898 23.5952 20.4898 23.14Z" fill="#BFD22B" />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <label>{MESSAGE_CONSTANTS.INCORRECT_MDN_SECOND_ATTEMPT.HEADER}</label>
        <Link to="/faq" target="_blank" className="btn ais_device-select-button ais_selected">{MESSAGE_CONSTANTS.INCORRECT_MDN_SECOND_ATTEMPT.BUTTON}</Link>
      </div>
    </div>
  )
}
