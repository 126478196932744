import React from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CONSTANTS from "../../utils/Constants";
import { getFormattedTotalFee } from '../../utils/utils';
import MESSAGE_CONSTANTS from "../../config/journey-messages";
// import paymentFeeIcon from "../../images/mobile/img-payment_50.png";
import paymentFeeIcon from "../../images/ais-images/icon-service-fee.png";

class GetServiceFee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            highlightedClass: "",
            disable: false,
            expandReplacementFeePopup: false,
            expandSwapFeePopup: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.replaceValues = this.replaceValues.bind(this);
    }

    handleClick() {
        this.setState({
            highlightedClass: "ais_device-select-button-highlighted",
            disable: true
        });
        this.props.updateJourneyList(CONSTANTS.GET_SERVICE_FEE_PROCEED, "Continue");
    }


    replaceValues(fee) {
        var { data } = this.props;
        var messageContent;
        if (fee.IncidentType === CONSTANTS.REPLACEMENT) {
            messageContent = data.CONTENT1;
        } else {
            messageContent = data.CONTENT2;
        }
        messageContent = messageContent.replace('{TotalAmount}', fee.Fees.TotalAmount);
        return messageContent;
    }

    getReplacementFeeDetails(e) {
        {
            this.state.expandReplacementFeePopup ?
                this.setState({
                    expandReplacementFeePopup: false
                }) :
                this.setState({
                    expandReplacementFeePopup: true
                })
        }
    }

    getSwapFeeDetails(e) {
        {
            this.state.expandSwapFeePopup ?
                this.setState({
                    expandSwapFeePopup: false
                }) :
                this.setState({
                    expandSwapFeePopup: true
                })
        }
    }

    renderFeeDetails(replacementFee) {
        return (
            <div>
                <div>
                    <table className="terms-conditions-table">
                        <tbody>
                            <tr>
                                <th>{MESSAGE_CONSTANTS.PURCHASE_FEE}</th>
                                <td>{getFormattedTotalFee(replacementFee.Fees.PurchasePrice)}</td>
                            </tr>
                            <tr>
                                <th>{MESSAGE_CONSTANTS.FEE_CHARGES}</th>
                                <td> {replacementFee.Fees.FeePercentage} {'%'}</td>
                            </tr>
                            <tr> <th> {MESSAGE_CONSTANTS.FEE_AMOUNT}</th>
                                <td>{getFormattedTotalFee(replacementFee.Fees.FeeAmount)}</td>
                            </tr>
                            <tr> <th>{MESSAGE_CONSTANTS.TAX_CHARGES}</th>
                                <td>{replacementFee.Fees.TaxPercentage} {'%'}</td>
                            </tr>
                            <tr> <th>{MESSAGE_CONSTANTS.TAX_AMMOUNT}</th>
                                <td>{getFormattedTotalFee(replacementFee.Fees.TaxAmount)}</td>
                            </tr>
                            <tr> <th>{MESSAGE_CONSTANTS.TOTAL_AMOUNT}</th>
                                <td>{getFormattedTotalFee(replacementFee.Fees.TotalAmount)}</td>
                            </tr>

                        </tbody>
                    </table>
                    <br />
                </div>
            </div>
        )
    }
    render() {
        let { replacementFee, swapFee } = this.props;
        return (
            <div className="ais_current-form-device-div SR">
                <div className="ais_header-txt-div">
                    <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
                    <div>  <img alt="invoice-icon" className="ais_confirm-delivery-icn" src={paymentFeeIcon} /> </div>
                    {replacementFee && <label className="ais_current-device-spec font-weight" dangerouslySetInnerHTML={{ __html: this.replaceValues(replacementFee) }}></label>}
                    <a>
                        <div style={{ marginBottom: 10 }} onClick={e => this.getReplacementFeeDetails(e)}> {MESSAGE_CONSTANTS.GET_FEE_DETAILS}</div>
                    </a>

                    <br />
                    {this.state.expandReplacementFeePopup && replacementFee ?
                        this.renderFeeDetails(replacementFee) : ""}
                    {swapFee && <label className="ais_current-device-spec font-weight" dangerouslySetInnerHTML={{ __html: this.replaceValues(swapFee) }}></label>}
                    <a>
                        <div style={{ marginBottom: 10 }} onClick={e => this.getSwapFeeDetails(e)}> {MESSAGE_CONSTANTS.GET_FEE_DETAILS}</div>
                    </a>
                    {this.state.expandSwapFeePopup && swapFee ?
                        this.renderFeeDetails(swapFee) : ""}
                    <div className="ais_outer-align-button">
                        <button disabled={this.state.disable} className={"ais_device-select-button proceed " + this.state.highlightedClass} onClick={this.handleClick} >{this.props.data.BUTTON}</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
};


const mapStateToProps = state => {
    let { replacementFee, swapFee } = state.customerData;
    return {
        replacementFee,
        swapFee
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetServiceFee);

