import gql from 'graphql-tag';

export default gql`
subscription onUpdateChatRequest($requestId: ID!) {
    onUpdateChatRequest(requestId: $requestId){
        requestId
        requestStatus
        endTimestamp
        isTransferred
        transferredRequestId
        wrapUpCode
    }
}`;