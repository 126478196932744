import React, { Fragment, Component } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import MESSAGE_CONSTANTS from '../../config/journey-messages';

class UploadFileScript extends Component {
    render() {
        const { hold, voidScript } = this.props;

        let script;
        if (voidScript) {
            script = voidScript;
        } if (hold) {
            script = hold.Script;
        }
        return (
            <Fragment>
                {script ? <div className="ais_current-form-device-div ais_display-script">
                    <div className="ais_display-script-container">
                        <div className="ais_current-device-spec ais_upload" dangerouslySetInnerHTML={{ __html: MESSAGE_CONSTANTS.DOWNLOAD_SCRIPT }}></div>
                    </div>
                </div> : ""
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
  const {voidScript, holds} = state.customerData;
  let hold = _.find(holds, { 'HoldType': 'DOCUMENT' });

  return {
    voidScript: voidScript, 
    hold : hold || []
  }
};

export default connect(mapStateToProps, null)(UploadFileScript);
//export default UploadFileScript;