import * as React from 'react';

const { useEffect, useState } = React;

export const STATUS = {
  IN_STOCK: Symbol('in stock'),
  OUT_OF_STOCK: Symbol('out of stock'),
  OUT_OF_STOCK_BACKORDER_AVAILABLE: Symbol('out of stock, backorder available'),
  NOT_CURRENTLY_AVAILABLE: Symbol('not currently available'),
  NO_LONGER_PRODUCED: Symbol('no longer produced'),
  UNKNOWN: Symbol('unknown'),
};

export const useDevice = (device, _isDisabled = false) => {
  const {
    flags: { isLikeForLike, isInStock, isAvailableForBackorder, isNoLongerProduced, isNotCurrentlyAvailableForOrder },
  } = device;
  const [isDisabled, setIsDisabled] = useState(_isDisabled);
  const [isBackorder, setIsBackorder] = useState(false);
  const [status, setStatus] = useState(STATUS.UNKNOWN);

  // console.log("isLikeForLike",isLikeForLike)
  // console.log("isInStock",isInStock)
  // console.log("isAvailableForBackorder",isAvailableForBackorder)
  // console.log("isNoLongerProduced",isNoLongerProduced)
  // console.log("isNotCurrentlyAvailableForOrder",isNotCurrentlyAvailableForOrder)

  useEffect(() => {
    if (isInStock) {
      setStatus(STATUS.IN_STOCK);
    } else {
      if (isLikeForLike) {
        if (isNoLongerProduced) {
          setIsDisabled(true);
          setStatus(STATUS.NO_LONGER_PRODUCED);
        } else if (isNotCurrentlyAvailableForOrder && !isNoLongerProduced) {
          setIsDisabled(true);
          setStatus(STATUS.NOT_CURRENTLY_AVAILABLE);
        } else if (isAvailableForBackorder) {
          setIsDisabled(isDisabled && false);
          setIsBackorder(true);
          setStatus(STATUS.OUT_OF_STOCK_BACKORDER_AVAILABLE);
        }  else {
          setIsDisabled(true);
          setStatus(STATUS.OUT_OF_STOCK);
        }
      } else {
        setIsDisabled(true);
        setStatus(STATUS.OUT_OF_STOCK);
      }
    }
  }, []);

  return {
    device,
    status,
    isLikeForLike,
    isBackorder,
    isDisabled,
  };
};
