import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import SelectCurrentDevice from "../SelectCurrentDevice/SelectCurrentDevice";

// import ConfirmSelectedDevice from '../ConfirmSelectedDevice/confirmSelectedDevice';
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';

class DisplayDeviceList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            btnClassName: "ais_align-popup-button selectNew"
        }
        this.disableSelection = this.disableSelection.bind(this);
        this.handleNoClick = this.handleNoClick.bind(this);
    }


    disableSelection() {
        this.setState({
            disabled: true,
        });
    }


    handleNoClick() {
        this.setState({
            disabled: true,
            btnClassName: "ais_align-popup-button-selected selectNew"
        });
        this.props.updateJourneyList(this.props.dataprops.source, MESSAGE_CONSTANTS.SELECT_DEVICE);
    }

    render() {

        let { deviceDetails, replacedAsset } = this.props;
        // const { deviceList } = this.props;
        return (
            <div className="ais_scrolling-wrapper">
                <div className="ais_card deviceList">
                    <div className="ais_current-mobile-device-label"> {MESSAGE_CONSTANTS.SELECT_DEVICE_CLAIM}</div>
                    <div>
                        <SelectCurrentDevice deviceDetails={deviceDetails} disableSelection={this.disableSelection.bind(this)} disable={this.state.disabled} dataprops={this.props.dataprops} data={MESSAGE_CONSTANTS.SELECT_YOUR_DEVICE[0]} updateJourneyList={this.props.updateJourneyList} />
                        <SelectCurrentDevice deviceDetails={replacedAsset} disableSelection={this.disableSelection.bind(this)} disable={this.state.disabled} dataprops={this.props.dataprops} data={MESSAGE_CONSTANTS.SELECT_YOUR_DEVICE[1]} updateJourneyList={this.props.updateJourneyList} />
                    </div>
                    <button className={this.state.btnClassName} disabled={this.state.disabled} onClick={(e) => this.handleNoClick(e)} >{MESSAGE_CONSTANTS.SELECT_OTHER_DEVICE}</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let { clientData, inputState, caseType, serviceRequestType, holds, makeAndModelList, selectedBrand, deviceDetails, replacedAsset, resumeCanceled, selectedDefectOption } = state.customerData;
    return {
        cacheId: clientData.CacheId,
        userInputState: inputState,
        deviceDetails,
        caseType,
        deviceURL: clientData.imageBaseUrl,
        serviceRequestType,
        isHoldApplied: _.find(holds, { 'HoldType': 'DOCUMENT' }),
        checkForDeviceMismatch: makeAndModelList,
        resumeCanceled,
        selectedDefectOption,
        replacedAsset,
        deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : "",
    };
};

export default connect(mapStateToProps, null)(DisplayDeviceList);
//export default SlideAlternativeDevices;