import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  serviceOrderInit,
  setBackOrderCase,
  saveSelectedReplacementDevice,
  doGetReplacementInit,
  cancelServiceRequestInit,
} from '../../actions/Dashboard/doFindAgreements';
import CONSTANTS from '../../utils/Constants';

import alternativeDevice from '../../images/ais-images/icon-device-unavailable.png';

class CheckAlternativeDevice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waitButtonClasses: 'ais_device-select-button-custum ais_checkDevice',
      checkAlternateDevicesButtonClasses: 'ais_device-select-button-custum ais_checkDevice',
      disabled: false,
    };

    this.handleCheckDeviceClick = this.handleCheckDeviceClick.bind(this);
    this.handleWaitForClick = this.handleWaitForClick.bind(this);
  }

  handleCheckDeviceClick() {
    this.setState({
      checkAlternateDevicesButtonClasses:
        'ais_device-select-button-custum ais_checkDevice ais_selected',
      disabled: true,
    });
    this.props.setBackOrderCase(false);
    if (this.props.caseType === CONSTANTS.SWAP) {
      this.props.updateJourneyList(null, CONSTANTS.ALTERNATIVE_DEVICES_MESSAGE);
      this.props.cancelServiceRequestInit(this.props.cacheId);
      this.props.doGetReplacementInit();
    } else {
      this.props.updateJourneyList(
        CONSTANTS.ALTERNATE_DEVICE_LIST,
        CONSTANTS.ALTERNATIVE_DEVICES_MESSAGE
      );
    }
  }

  handleWaitForClick() {
    let { cacheId, replacementDevice, caseType, serviceOrderData } = this.props;
    this.setState({
      waitButtonClasses: 'ais_device-select-button-custum ais_checkDevice ais_selected',
      disabled: true,
    });
    this.props.setBackOrderCase(true);
    this.props.saveSelectedReplacementDevice(replacementDevice);
    // this replacementDevice contains enrolled device
    // if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
    let serviceOrderId = serviceOrderData ? serviceOrderData.ServiceOrderId : null;
    if (!serviceOrderId) {
      this.props.serviceOrderInit(cacheId, replacementDevice, false, caseType);
    }
    this.props.updateJourneyList(
      CONSTANTS.ALTERNATE_DEVICE_LIST,
      CONSTANTS.WAIT_FOR_ENROLLED_DEVICE
    );
  }

  componentWillMount() {
    // console.log(this.props.data);
  }

  render() {
    const { LFLDeviceInStock, NLFLDeviceInStock } = this.props;
    return (
      <div className="ais_current-form-device-div">
        <div className="ais_header-txt-div">
          <label className="ais_current-mobile-device-label">{this.props.data.HEADER}</label>
          <div>
            {' '}
            <img
              alt="alternate-device-icn"
              className="ais_confirm-delivery-icn"
              src={alternativeDevice}
            />{' '}
          </div>
          {LFLDeviceInStock || NLFLDeviceInStock ? (
            <label
              className="ais_current-device-spec ais_font-weight"
              dangerouslySetInnerHTML={{ __html: this.props.data.CONTENT1 }}
            ></label>
          ) : (
            <label
              className="ais_current-device-spec ais_font-weight"
              dangerouslySetInnerHTML={{ __html: this.props.data.CONTENT2 }}
            ></label>
          )}
          <div className="ais_outer-align-button">
            {LFLDeviceInStock || NLFLDeviceInStock ? (
              <button
                className={this.state.checkAlternateDevicesButtonClasses}
                disabled={this.state.disabled}
                onClick={this.handleCheckDeviceClick}
              >
                {this.props.data.BUTTONS[0]}
              </button>
            ) : (
              ''
            )}
            <button
              className={this.state.waitButtonClasses}
              disabled={this.state.disabled}
              onClick={this.handleWaitForClick}
            >
              {this.props.data.BUTTONS[1]}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    LFLDeviceInStock,
    NLFLDeviceInStock,
    serviceOrderData,
    caseType,
    replacementDevices,
    clientData,
  } = state.customerData;
  let deviceList = replacementDevices ? replacementDevices.ReplacementItem : [];
  return {
    LFLDeviceInStock: LFLDeviceInStock || false,
    NLFLDeviceInStock: NLFLDeviceInStock || false,
    caseType: caseType,
    replacementDevice: deviceList[0],
    cacheId: clientData.CacheId,
    serviceOrderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      serviceOrderInit,
      setBackOrderCase,
      saveSelectedReplacementDevice,
      doGetReplacementInit,
      cancelServiceRequestInit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckAlternativeDevice);
