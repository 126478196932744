import * as React from 'react';
import Slider from 'react-slick';
import DeviceCard from './DeviceCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Center, HStack } from '@chakra-ui/layout';
import { noop } from 'lodash';
import { IconButton, useBreakpointValue } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

const { useRef, useState } = React;

const AppendDots = ({ dots }) => {
  return (
    <Center py={`${15 / 16}rem`}>
      <HStack gap={`${5 / 16}rem`}>
        {dots.map((dot, i) => (
          <Box
            key={`device-${i}`}
            transition={'width 0.15s ease-in-out'}
            h={2}
            w={dot.props.className === 'slick-active' ? 5 : 2}
            bg={dot.props.className === 'slick-active' ? 'brand.primary' : 'gray.450'}
            borderRadius={4}
            onClick={dot.props.children.props.onClick}
          ></Box>
        ))}
      </HStack>
    </Center>
  );
};

export const DeviceCarousel = ({ devices, disabled = false, onSelect = noop }) => {
  const centerMode = useBreakpointValue({ base: true, lg: false });
  const [currentIndex, setCurrentIndex] = useState(0);
  const slider = useRef(null);
  return (
    <Box
      pb={devices.length > 1 ? 0 : 6}
      overflow={'hidden'}
      w={'full'}
      opacity={disabled ? '75%' : 1}
      pos={'relative'}
    >
      <IconButton
        h={'40px'}
        w={'40px'}
        borderRadius={'40px'}
        borderWidth={2}
        borderStyle={'solid'}
        borderColor={'white'}
        icon={<ArrowBackIcon />}
        pos={'absolute'}
        top={'calc(50% - 40px)'}
        zIndex={9999}
        opacity={0.5}
        display={currentIndex === 0 ? 'none' : 'block'}
        onClick={() => slider.current.slickPrev()}
      />
      <IconButton
        h={'40px'}
        w={'40px'}
        borderRadius={'40px'}
        borderWidth={2}
        borderStyle={'solid'}
        borderColor={'white'}
        icon={<ArrowForwardIcon />}
        pos={'absolute'}
        top={'calc(50% - 40px)'}
        zIndex={9999}
        opacity={0.8}
        right={0}
        display={currentIndex >= devices.length - 1 ? 'none' : 'block'}
        onClick={() => slider.current.slickNext()}
      />
      <Slider
        ref={slider}
        dots={true}
        infinite={false}
        variableWidth={true}
        appendDots={(dots) => <AppendDots dots={dots} />}
        afterChange={setCurrentIndex}
      >
        {devices.map((props, i) => {
          //console.log("DeviceCarousel props.flags", props.flags)
          if (props.flags.isInStock || 
            (!props.flags.isInStock && (!props.flags.isNoLongerProduced && !props.flags.isNotCurrentlyAvailableForOrder))
            ) {
            return (
              <DeviceCard key={`device-${i}`} disabled={disabled} onSelect={onSelect} {...props} />
            )
          } else {
            return (<></>)
          } 
        })}
      </Slider>
    </Box>
  );
};

export default DeviceCarousel;
