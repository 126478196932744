import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  serviceOrderInit,
  setBackOrderCase,
  saveSelectedReplacementDevice,
  doGetReplacementInit,
  cancelServiceRequestInit,
} from '../../actions/Dashboard/doFindAgreements';

import {
  BaseImg,
} from '../../utils/utils';

class DeviceInStock extends React.Component {
  constructor(props) {
    super(props);
    
    this.replaceToken = this.replaceToken.bind(this);
  }
  componentWillMount() {
    // console.log(this.props.data);
  }

  replaceToken(stringConstant, tokenName, tokenValue) {
    let result = stringConstant.replace(
      `{${tokenName}}`,
      tokenValue
    );

    return result
  }

  render() {
    const { replacementDevice } = this.props;
    const DeviceName = replacementDevice?.HorizonItemInformation ? `${replacementDevice?.HorizonItemInformation?.Make} ${replacementDevice?.HorizonItemInformation?.Model}` : "Unknown Device"

    return (
      <div className="ais_current-form-device-div" style={{ marginBottom: "30px", marginTop: "30px" }}>
        <div style={{
          display: "flex"
        }}>
          <div className="deviceIcon" style={{ minWidth: "42px", marginTop: "30px", marginLeft: "20px", justifyContent: "flex-start", "alignItems": "unset" }}>
            <BaseImg make={""} model={""} />
          </div>
          <div className="ais_header-txt-div" style={{ textAlign: "left", marginLeft: "0", marginBottom: "10px" }}>
            <label className="ais_current-mobile-device-label" style={{ textAlign: "left", marginBottom: "10px" }}
              dangerouslySetInnerHTML={{ __html: this.replaceToken(this.props.data.scripts.header, "DeviceName", DeviceName) }}
            >
            </label>
            <p style={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: this.replaceToken(this.props.data.scripts.subHeader, "DeviceName", DeviceName) }}
            >
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    LFLDeviceInStock,
    NLFLDeviceInStock,
    serviceOrderData,
    caseType,
    replacementDevices,
    clientData,
  } = state.customerData;
  let deviceList = replacementDevices ? replacementDevices.ReplacementItem : [];
  return {
    LFLDeviceInStock: LFLDeviceInStock || false,
    NLFLDeviceInStock: NLFLDeviceInStock || false,
    caseType: caseType,
    replacementDevice: deviceList[0],
    cacheId: clientData.CacheId,
    serviceOrderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      serviceOrderInit,
      setBackOrderCase,
      saveSelectedReplacementDevice,
      doGetReplacementInit,
      cancelServiceRequestInit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInStock);
